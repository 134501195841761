import { FC } from "react";
import { Button, Modal, } from "antd";
import { SetupLanding, SetupLandingAction } from '@store/type-event/models';
import { PatchSetupLandingStatusType } from "@pages/EventRolePages/EventPage/setup/landing-page-templates/types.ts";

type ChangeStatusModalPropsType = {
    isOpen: boolean
    onClose: () => void
    data: {
        row: SetupLanding,
        status: SetupLandingAction
    }
    handlePatchLandingStatus: ({ landing, status, statusSlug }: PatchSetupLandingStatusType) => void
}

const ApproveModal: FC<ChangeStatusModalPropsType> = ({ isOpen, onClose, data, handlePatchLandingStatus }) => {
    const handleOk = () => {
        handlePatchLandingStatus({
            landing: data.row.id,
            status: data.status.id,
            statusSlug: data.status.slug
        })
        onClose()
    }

    return (
        <Modal
            title="Landing page approval"
            open={isOpen}
            onCancel={onClose}
            className="approval-modal"
            centered
            zIndex={1001}
            footer={(
                <div
                    className="approval-modal__footer"
                >
                    <Button
                        size="large"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleOk}
                    >
                        Approve
                    </Button>
                </div>
            )}
        >
            <span>Are you sure that you want to approve the landing page template?</span>
        </Modal>
    )
}

export default ApproveModal;
