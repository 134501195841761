import {SocialLink} from "@pages/PartnerRolePages/LandingPageConstructor/models.ts";

export const getLinksObject = (currentLinks: SocialLink[]) => {
    return currentLinks.reduce((acc, next) => {
        const {type, value} = next
        if (acc) {
            acc[type] = value
        }
        return acc
    }, {} as Record<string, string>)
}