const SaveIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.55556 2H4C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V5.55556L14.4444 2H13.1111M5.55556 2V5.88889C5.55556 6.44117 6.00327 6.88889 6.55556 6.88889H12.1111C12.6634 6.88889 13.1111 6.44117 13.1111 5.88889V2M5.55556 2H13.1111"
                stroke="#898A8C" strokeWidth="1.2" strokeLinejoin="round"/>
        </svg>
    )
}

export default SaveIcon