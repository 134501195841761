import EventsPageLayout from '@layouts/EventsPageLayout'
import { Tabs, type TabsProps } from "antd";
import React, {useCallback, useEffect, useMemo} from "react";
import { TabKeys, TabNames } from "./constants.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { audiences } from "@pages/routes.ts";
import AudiencesTable from "@pages/EventRolePages/AudiencesEventPage/components/AudiencesTable.tsx";
import './styles.scss'
import AudienceCatalogsTable from "@pages/EventRolePages/AudiencesEventPage/components/AudienceCatalogsTable.tsx";

const AudiencesEventPage = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const items: TabsProps['items'] = useMemo(() => [
        {
            key: TabKeys.Audiences,
            label: TabNames.Audiences,
            children: (<AudiencesTable />),
        },
        {
            key: TabKeys.AudienceCatalogs,
            label: TabNames.AudienceCatalogs,
            children: (<AudienceCatalogsTable />),
        },
    ], []);

    const activeKey = useMemo(() => {
        if (pathname.includes(`/${TabKeys.Audiences}`)) {
            return TabKeys.Audiences;
        }
        return TabKeys.AudienceCatalogs;
    }, [pathname]);

    const onChange = useCallback((value: string) => {
        navigate(`${audiences}/${value}`);
    }, [navigate]);

    useEffect(() => {
        if (pathname === audiences) {
            navigate(`${audiences}/${TabKeys.Audiences}`);
        }
    }, []);

    return (
        <EventsPageLayout>
            <div className="audiences-event-page">
                <Tabs
                    rootClassName="organizer-billing-page__tab"
                    activeKey={activeKey}
                    onChange={onChange}
                    items={items}
                />
            </div>
        </EventsPageLayout>
    )
}

export default AudiencesEventPage