const AlignCenterIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.46708 3.70675H15.5385C15.6367 3.70675 15.7171 3.6264 15.7171 3.52818V2.27818C15.7171 2.17997 15.6367 2.09961 15.5385 2.09961H4.46708C4.36886 2.09961 4.2885 2.17997 4.2885 2.27818V3.52818C4.2885 3.6264 4.36886 3.70675 4.46708 3.70675ZM15.5385 13.171C15.6367 13.171 15.7171 13.0907 15.7171 12.9925V11.7425C15.7171 11.6443 15.6367 11.5639 15.5385 11.5639H4.46708C4.36886 11.5639 4.2885 11.6443 4.2885 11.7425V12.9925C4.2885 13.0907 4.36886 13.171 4.46708 13.171H15.5385ZM18.7528 16.296H1.25279C1.15458 16.296 1.07422 16.3764 1.07422 16.4746V17.7246C1.07422 17.8228 1.15458 17.9032 1.25279 17.9032H18.7528C18.851 17.9032 18.9314 17.8228 18.9314 17.7246V16.4746C18.9314 16.3764 18.851 16.296 18.7528 16.296ZM18.7528 6.83175H1.25279C1.15458 6.83175 1.07422 6.91211 1.07422 7.01032V8.26032C1.07422 8.35854 1.15458 8.4389 1.25279 8.4389H18.7528C18.851 8.4389 18.9314 8.35854 18.9314 8.26032V7.01032C18.9314 6.91211 18.851 6.83175 18.7528 6.83175Z"
                fill="#57585A"/>
        </svg>
    )
}

export default AlignCenterIcon
