import {Jodit} from "jodit-react";
import {
    handleChangeBrushColor
} from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/helpers.ts";

let lastClickedElement: HTMLElement | null = null;

export const joditButtons = [
    // 'bold',
    // 'italic',
    // 'underline',
    // 'strikethrough',
    // 'ul',
    // 'ol',
    // 'outdent',
    // 'indent',
    'format',
    'left',
    'center',
    'right',
    'justify',
    'link',
    // 'image',
    // 'table',
    // 'hr',
    // 'eraser',
    'paragraph',
    'undo',
    'redo'
    // 'source'
]
export const joditControls = {
    paragraph: {
        list: {
            p: 'Normal',
            h1: 'Heading 1',
            h2: 'Heading 2',
            h3: 'Heading 3',
            h4: 'Heading 4',
            blockquote: 'Quote',
            pre: 'Code'
        }
    }
}

const colorsObject: Record<string, string> = {
    '#000000': '#000000',
    '#FFFFFF': '#FFFFFF',
    '#4A4A4A': '#4A4A4A',
    '#808080': '#808080',
    '#D3D3D3': '#D3D3D3',
    '#FF0000': '#FF0000',
    '#8B0000': '#8B0000',
    '#FFA07A': '#FFA07A',
    '#008000': '#008000',
    '#006400': '#006400',
    '#90EE90': '#90EE90',
    '#0000FF': '#0000FF',
    '#00008B': '#00008B',
    '#ADD8E6': '#ADD8E6',
    '#FFFF00': '#FFFF00',
    '#FFD700': '#FFD700',
    '#FFFFE0': '#FFFFE0',
    '#FFA500': '#FFA500',
    '#FF8C00': '#FF8C00',
    '#FFDAB9': '#FFDAB9',
    '#800080': '#800080',
    '#4B0082': '#4B0082',
    '#DDA0DD': '#DDA0DD',
    '#FFC0CB': '#FFC0CB',
    '#FF69B4': '#FF69B4',
    '#FFB6C1': '#FFB6C1',
    '#A52A2A': '#A52A2A',
    '#8B4513': '#8B4513',
    '#DEB887': '#DEB887',
    '#800000': '#800000',
    '#40E0D0': '#40E0D0',
    '#008B8B': '#008B8B',
    '#AFEEEE': '#AFEEEE',
    '#C0C0C0': '#C0C0C0',
    '#808000': '#808000',
}

export const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    editHTMLDocumentMode: true,
    showWordsCounter: false,
    showCharsCounter: false,
    beautifyHTML: false,
    hidePoweredByJodit: true,
    statusbar: false,
    width: 'auto',
    buttons: [
        {
            name: 'alignmentDropdown',
            tooltip: 'Alignment',
            list: {
                left: 'Align Left',
                center: 'Align Center',
                right: 'Align Right',
                justify: 'Justify',
            },
            template: () => '',
            exec: (editor: Jodit, _: string, alignment: string) => {
                switch (alignment) {
                    case 'left':
                        editor.execCommand('justifyLeft');
                        break;
                    case 'center':
                        editor.execCommand('justifyCenter');
                        break;
                    case 'right':
                        editor.execCommand('justifyRight');
                        break;
                    case 'justify':
                        editor.execCommand('justifyFull');
                        break;
                    default:
                        break;
                }
            },
        },
        '|',
        {
            name: 'customBrush',
            tooltip: 'Text color',
            list: colorsObject,
            childTemplate: (_: Jodit, color: string) => {
                return `<div class="editor-drawer__color-picker-item" style="background: ${color};"></div>`
            },
            exec: (editor: Jodit, _: string, alignment: { control: { text: string }}) => {
                const color = alignment.control.text
                editor.s.focus();
                handleChangeBrushColor(color)
                editor.execCommand('foreColor', false, color);
            },
        },
        '|',
        'undo',
        'link',
        '|',
        {
            name: 'resetButton',
            icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.60435 2.67794H6.42578C6.524 2.67794 6.60435 2.59759 6.60435 2.49937V2.67794H13.3901V2.49937C13.3901 2.59759 13.4704 2.67794 13.5686 2.67794H13.3901V4.28509H14.9972V2.49937C14.9972 1.71143 14.3566 1.0708 13.5686 1.0708H6.42578C5.63784 1.0708 4.99721 1.71143 4.99721 2.49937V4.28509H6.60435V2.67794ZM17.8544 4.28509H2.14007C1.74498 4.28509 1.42578 4.60428 1.42578 4.99937V5.71366C1.42578 5.81187 1.50614 5.89223 1.60435 5.89223H2.95257L3.50391 17.5663C3.53962 18.3275 4.16909 18.9279 4.93025 18.9279H15.0642C15.8276 18.9279 16.4548 18.3297 16.4905 17.5663L17.0419 5.89223H18.3901C18.4883 5.89223 18.5686 5.81187 18.5686 5.71366V4.99937C18.5686 4.60428 18.2494 4.28509 17.8544 4.28509ZM14.8923 17.3208H5.10212L4.56194 5.89223H15.4325L14.8923 17.3208Z" fill="#252628"/>
            </svg>`,
            exec: (editor: Jodit) => {
                editor.execCommand('delete');
            },
            tooltip: 'Delete text'
        },
    ],
    controls: joditControls,
    uploader: {
        insertImageAsBase64URI: true
    },
    image: {
        useImageEditor: false,
        editImage: false,
        editSize: false,
        editStyle: false,
    },
    toolbar: true,
    editImage: false,
    showBrowserErrors: false,
    allowResizeX: false,
    allowResizeY: false,
    contextmenu: {
        disableFor: ['img', 'a', 'button', 'video', 'embed', 'iframe', 'object']
    },
    resizer: {
        disableFor: ['img', 'a', 'button', 'video', 'embed', 'iframe', 'object']
    },
    toolbarAdaptive: false,
    disablePlugins: ['ImageProperties', 'FileBrowser', 'Video', 'image', 'imageProcessor', 'iframe'],
    events: {
        click: function (event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (lastClickedElement !== target) {
                handleChangeBrushColor(target?.style?.color)
                lastClickedElement = target;
            }
        },
    }
}

export const COPIED_LANDING_SECTION = 'copied-landing-section'

