//https://boost.allnetru.dev/api/documentation/organizer#/Sponsors
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GetOrganizerPartnersByEventFiltersResponse,
    GetOrganizerPartnersByEventResponse,
    GetOrganizerPartnersFiltersResponse,
    PatchOrganizerPartnersStatusByIdResponse
} from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const organizer_partners = `organizer_partners`;

export const organizerPartners = createApi({
    reducerPath: organizer_partners,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        organizer_partners
    ],
    endpoints: build => ({
        //https://boost.allnetru.dev/api/documentation/organizer#/Sponsors/get_api_organizer_events__event__partners
        getOrganizerPartnersByEvent: build.query<GetOrganizerPartnersByEventResponse, {
            eventId: string,
            page?: number,
            perpage?: number,
            filters?: {
                statuses?: string[];
            } | null;
        }>({
            query: ({ eventId, page, filters, perpage = 20 }) => {
                const params = new URLSearchParams();
                if (page) {
                    params.append('page', page.toString());
                }
                if (perpage) {
                    params.append('perpage', perpage.toString());
                }
                if (Array.isArray(filters?.statuses)) {
                    filters.statuses.forEach(id => {
                        params.append('filters[statuses][]', id.toString());
                    });
                }
                const queryString = params.toString();
                return ({
                    url: `organizer/events/${eventId}/partners${queryString ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [organizer_partners],
        }),
        patchOrganizerPartnersStatusById: build.mutation<PatchOrganizerPartnersStatusByIdResponse, { eventId: string, statusId: number, invite: number }>({
            query: ({ eventId, statusId, invite }) => ({
                method: 'PATCH',
                url: `organizer/events/${eventId}/invites/${invite}/status/${statusId}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                }
            }),
            invalidatesTags: [organizer_partners],
        }),
        getOrganizerPartnersByEventFilters: build.query<GetOrganizerPartnersByEventFiltersResponse, { eventId?: string, search?: string, page?: number }>({
            query: ({ eventId, page, search }) => {
                const params = new URLSearchParams();

                if (page) {
                    params.append('page', page.toString());
                }
                if (search) {
                    params.append('search', search);
                }
                const queryString = params.toString();

                return {
                    url: `organizer/events/${eventId!}/partners/filter${queryString ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [organizer_partners],
        }),

        getOrganizerPartnersFilters: build.query<GetOrganizerPartnersFiltersResponse, {
            filters?: {
                search: string;
                page: number;
            } | null;
        }>({
            query: ({ filters }) => {
                const params = new URLSearchParams();
                if (filters?.search) {
                    params.append('search', filters.search);
                }
                if (filters?.page) {
                    params.append('page', filters.page.toString());
                }
                const queryString = params.toString();
                return {
                    url: `/organizer/partners/filter${queryString ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [organizer_partners],
        }),

    }),
});

export const {
    useGetOrganizerPartnersByEventQuery,
    usePatchOrganizerPartnersStatusByIdMutation,
    useGetOrganizerPartnersByEventFiltersQuery,
    useGetOrganizerPartnersFiltersQuery
} = organizerPartners;