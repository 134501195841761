import { FilterByEnumItem } from '@store/main/enums/models';
import { Button, Dropdown } from 'antd';
import { ReactNode } from 'react'

interface Props {
    children: ReactNode;
    goal: FilterByEnumItem;
    onSubmit: (id: string) => void;
    subscription: FilterByEnumItem
}

const GoalDropdown = ({
    goal,
    onSubmit,
    children,
    subscription
}: Props) => {

    const items = [
        {
            key: '1',
            label: (
                <span className='radio-goal__boost-pro-tooltip'>
                    <span>
                        {goal.translate} can be specified for your campaigns with {subscription.translate} enabled
                    </span>
                    <Button
                        size="large"
                        className="boost-pro-gradient radio-goal__boost-pro-button"
                        onClick={() => {
                            onSubmit(subscription.id)
                        }}
                    >
                        Enable PRO
                    </Button>
                </span>
            ),
        },
    ];

    return (
        <Dropdown
            overlayClassName="radio-goal__boost-pro-tooltip"
            menu={{ items }}
            placement="top"
            trigger={['click']}
        >
            {children}
        </Dropdown>

    )
}

export default GoalDropdown