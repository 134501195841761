import { Modal } from "antd";
import React, { useContext, useEffect, useState, useMemo } from "react";
import HtmlRenderer from "@pages/PartnerRolePages/LandingPageConstructor/components/HtmlRenderer/HtmlRenderer";
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import {HERO, sectionsImages, sectionTitle} from '../../constants';
import { useGetLandingsLayoutsSectionsQuery } from '@store/type-partner/landings/landings.api';
import { GetLandingLayoutsDataItem } from '@store/type-partner/landings/models';
import { useParams } from 'react-router-dom';
import ExitCrossIcon from '@assets/ExitCrossIcon'
import TooltipComponent from "@components/TooltipComponent/TooltipComponent.tsx";

const AddSectionModal = () => {
    const {
        editorSections,
        isAddSectionModalOpen,
        setIsAddSectionModalOpen,
        setSelectedSectionIndex,
        onSectionAddToList,
    } = useContext(LandingEditorContext);
    const { eventId = '' } = useParams()

    const { data: layoutsResponse } = useGetLandingsLayoutsSectionsQuery({ id: eventId }, { skip: !eventId })
    const [layouts, setLayouts] = useState<GetLandingLayoutsDataItem[]>([])
    const [clickedCategoryData, setClickedCategoryData] = useState<GetLandingLayoutsDataItem | null>(null);

    const isHeroAdded = useMemo(() => {
        return editorSections.some(({ layout }) => layout.name.includes(HERO))
    }, [editorSections])

    useEffect(() => {
        if (layoutsResponse?.data) {
            setLayouts(layoutsResponse.data)
        }
    }, [layoutsResponse?.data])

    const onCategoryBtnClick = (item: GetLandingLayoutsDataItem) => {
        setClickedCategoryData(item)
    }

    const onClose = () => {
        setIsAddSectionModalOpen(false)
        setSelectedSectionIndex(null)
    }

    useEffect(() => {
        if (layouts) {
            setClickedCategoryData(layouts[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layouts])

    return (
        <Modal
            title='Add a section'
            wrapClassName="add-constructor-section-modal"
            open={isAddSectionModalOpen}
            onCancel={onClose}
            centered
            width={707}
            footer={null}
            closeIcon={<ExitCrossIcon width="20" height="20" />}
        >
            <div className='add-constructor-section__block'>
                <div className='add-constructor-section__left'>
                    {/* todo remove comments after demo */}
                    {/* <Button */}
                    {/*    className='ai-generator-button' */}
                    {/*    title='AI Generator' */}
                    {/*    type='text' */}
                    {/*    size='small' */}
                    {/* > */}
                    {/*    AI Generator */}
                    {/*    <div className='ml-6'> */}
                    {/*        <AIIcon /> */}
                    {/*    </div> */}
                    {/* </Button> */}
                    <div className='add-constructor-section__sections'>Sections</div>
                    {layouts?.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className={`add-constructor-section__label ${item.section === clickedCategoryData?.section ? 'add-constructor-section__label_active' : ''}`}
                                onClick={() => onCategoryBtnClick(item)}
                            >
                                {sectionTitle[item.section]}
                            </div>
                        )
                    })}
                </div>
                <div className='add-constructor-section__wrapper custom-scroll'>
                    {clickedCategoryData && clickedCategoryData.layouts?.map((item) => {
                        if (!sectionsImages[item.name]) {
                            return
                        }
                        const isHeroDisabled = item.name.includes(HERO) && isHeroAdded
                        return (
                            <TooltipComponent
                                isActive={isHeroDisabled}
                                key={item.name}
                                tooltipText="To add a new hero section, delete or change the current one"
                            >
                                <HtmlRenderer
                                    disabled={isHeroDisabled}
                                    key={item.name}
                                    title={item.name}
                                    layout={item}
                                    onLayoutSelect={onSectionAddToList}
                                />
                            </TooltipComponent>
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}

export default AddSectionModal
