import React from 'react'
import CreativesCarousel from './components/CreativesCarousel'
import CreativesHeader from './components/CreativesHeader'

const CreativesManager = () => {
    return (
        <div>
            <CreativesHeader />
            <CreativesCarousel />
        </div>
    )
}

export default CreativesManager