import { Progress, Typography } from 'antd';
import './styles.scss';
import { GetEventCheckListByEventIdItem } from '@store/type-event/events/models';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useBreakpoints } from "@hooks/browser";
import { checklistLinkHandler } from './helpers';

const { Text } = Typography;

interface Props {
    checkList: GetEventCheckListByEventIdItem[],
    eventId: string
}
const EventCardSetupProgressPlug = ({
    checkList,
    eventId
}: Props) => {
    const isDesktop = useBreakpoints().md;
    const navigate = useNavigate()

    const completedTasks = checkList.filter(task => task.completed).length;
    const uncompletedTasks = checkList.filter(task => !task.completed).length;
    const progressPercent = (completedTasks / checkList.length) * 100;

    const [isVisible, setIsVisible] = useState(false);

    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setIsVisible(false);
    };

    const visibleCheckList = isVisible ? checkList : checkList.slice(0, 1)

    return uncompletedTasks ? (
        <div
            onClick={(e) => {
                if (!isDesktop) {
                    setIsVisible(true)
                } else {
                    const target = e.target as HTMLElement;
                    if (target?.nodeName !== 'SPAN') {
                        navigate(`/events/info/${eventId}/general`)
                    }
                }
            }}
            onMouseEnter={() => {
                if (isDesktop) {
                    setIsVisible(true)
                }
            }}
            onMouseLeave={() => {
                if (isDesktop) {
                    setIsVisible(false)
                }
            }}
            className={`event-card-setup-progress ${isVisible ? 'event-card-setup-progress__visible' : ''}`}
        >
            <div className="progress-header">
                <Text strong>Complete event setup</Text>

                {!isDesktop && isVisible && (
                    <button
                        style={{ all: 'unset' }}
                        onClick={handleClose}
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_14870_218256)">
                                <path
                                    d="M12.171 8.88802H7.10708L7.10708 3.81812M7.54902 8.44608L14.0014 1.99373M6.61843 1.5H3.5C2.39543 1.5 1.5 2.39543 1.5 3.5V12.5C1.5 13.6046 2.39543 14.5 3.5 14.5H12.5C13.6046 14.5 14.5 13.6046 14.5 12.5V9.37816"
                                    stroke="#898A8C"
                                    strokeWidth="1.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_14870_218256">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                )}
            </div>
            <Progress percent={+progressPercent.toFixed()} showInfo />
            <div className="task-list">
                {visibleCheckList.map((item, index) => (
                    <div key={index} className="task-item">
                        {item.completed
                            ? (
                                <div className='flex items-center'>
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.9"
                                            d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                                            fill="#17CC80"
                                        />
                                    </svg>
                                    <Text
                                        delete={item.completed}
                                        type={item.completed ? 'secondary' : 'danger'}
                                        className={`${item.completed ? '' : 'not-completed'}`}
                                    >
                                        {item.item.translate}
                                    </Text>
                                </div>
                            )

                            : (
                                <div className='flex items-center'>
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.7"
                                            d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                                            fill="#C0C1C3"
                                        />
                                    </svg>
                                    <Link to={`/events/info/${eventId}/${checklistLinkHandler(item.item.name)}`}>
                                        <Text
                                            delete={item.completed}
                                            type={item.completed ? 'secondary' : 'danger'}
                                            className={`${item.completed ? '' : 'not-completed'}`}
                                        >
                                            {item.item.translate}
                                        </Text>
                                    </Link>
                                </div>
                            )}

                        {!isVisible && Boolean(uncompletedTasks) && (
                            <div
                                className="task-item__additional-tasks"
                            >
                                +
                                {/* TOFIX: wrong logic if first elem is successed  */}
                                {uncompletedTasks - 1}
                                {' '}
                                tasks
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    ) : null;
};

export default EventCardSetupProgressPlug;
