import React, { useEffect, useRef } from 'react';
import { PartnerLandingSectionLayout } from '../../models';

interface HtmlRendererProps {
    title: string;
    disabled?: boolean;
    layout: PartnerLandingSectionLayout;
    onLayoutSelect: (layout: PartnerLandingSectionLayout) => void
}

const HtmlRenderer: React.FC<HtmlRendererProps> = ({
    layout,
    title,
    onLayoutSelect,
    disabled
}) => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    useEffect(() => {
        if (layout?.html) {
            const renderIframeContent = () => {
                const iframe = iframeRef.current;
                if (iframe) {
                    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
                    if (iframeDoc) {
                        iframeDoc.open();
                        iframeDoc.write(layout.html);
                        iframeDoc.close();
                    }
                }
            };

            renderIframeContent();
        }
    }, [layout?.html]);

    return (
        <div
            className={`html-renderer__wrapper ${disabled ? 'html-renderer__wrapper-disabled' : ''}`}
            onClick={() => onLayoutSelect(layout)}
        >
            <iframe
                title={`HtmlRenderer_${title}`}
                className='html-renderer-iframe'
                ref={iframeRef}
            />
        </div>
    );
};

export default HtmlRenderer;
