import TableCellSettingsIcon from '@assets/TableCellSettingsIcon';
import { Button, Switch, message } from 'antd';
import { Link } from 'react-router-dom';
import StatusDescription from '@components/StatusDescription';
import DropDownSettings from './DropDownSettings';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CampaignStatuses } from '@shared/constants';
import { formatByCurrency, formatKNumber } from '@shared/utils';
import FooterCampaigns from './Footer';
import { CampaignDataItem } from '@store/type-partner/campaigns/models';
import {
    useGetPartnerCampaignsQuery,
    usePatchPartnerCampaignsStatusByIdMutation
} from '@store/type-partner/campaigns/campaigns.api';
import ErrorStatus from '@pages/PartnerRolePages/Campaigns/components/ErrorStatus';
import ErrorStatusModal from '@pages/PartnerRolePages/Campaigns/components/ErrorStatusModal';
import { partnerCampaignsActions } from "@store/type-partner/campaigns/campaigns.slice.ts";
import { useDispatch } from "react-redux";
import CreateCampaignModal from "@pages/PartnerRolePages/Campaigns/components/CreateCampaignModal.tsx";
import InfiniteScrollTable from "@components/InfiniteScrollTable/InfiniteScrollTable.tsx";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import type { ColumnsType } from "antd/es/table";
import { useGetUserBillingPaymentsQuery } from "@store/main/payment/payment.api.ts";
import AddPaymentDrawer from "@pages/PartnerRolePages/Billing/drawers/AddPayment";
import AddPaymentDrawerMobile from "@pages/PartnerRolePages/Billing/mobile/AddPayment";
import { useBreakpoints } from '@hooks/browser';

const MAX_SELECTIONS = 100;

interface Props {
    campaigns: CampaignDataItem[]
    setCampaignsList: React.Dispatch<React.SetStateAction<CampaignDataItem[]>>
    isLoading: boolean
    selectedRowKeys: string[]
    page: number
    onSelectChange: (newSelectedRowKeys: string[]) => void
    isLastPage: boolean
    onNextPage: () => void
}
const TableCampaignsMain = ({
    campaigns,
    setCampaignsList,
    isLoading,
    page,
    selectedRowKeys,
    onSelectChange,
    isLastPage,
    onNextPage
}: Props) => {
    const isDesktop = useBreakpoints().md

    const [errorStatusModalData, setErrorStatusModalData] = useState<{ logs: CampaignDataItem['logs'], campaignTitle: string } | null>(null)
    const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] = useState(false)
    const [patchPartnerCampaignsStatusById] = usePatchPartnerCampaignsStatusByIdMutation()
    const { data: paymentMethodsResponse } = useGetUserBillingPaymentsQuery()
    const [isIdStatusUpdating, setIsIdStatusUpdating] = useState<string | null>(null)
    const [isAddPaymentDrawerOpen, setIsAddPaymentDrawerOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState<CampaignDataItem | null>(null)
    const dispatch = useDispatch()

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record: CampaignDataItem) => ({
            disabled: (selectedRowKeys.length >= MAX_SELECTIONS && !selectedRowKeys.includes(record.id)),
        }),
        onSelect: (record: CampaignDataItem, selected: boolean) => {
            if (selected && selectedRowKeys.length >= MAX_SELECTIONS) {
                void message.warning(`You can select a maximum of ${MAX_SELECTIONS} items.`);
                return;
            }
            onSelectChange(
                selected
                    ? [...selectedRowKeys, record.id]
                    : selectedRowKeys.filter(key => key !== record.id)
            );
        },
        onSelectAll: (selected: boolean, selectedRows: CampaignDataItem[], changeRows: CampaignDataItem[]) => {
            let newSelectedKeys: string[];
            if (selected) {
                newSelectedKeys = [...new Set([...selectedRowKeys, ...changeRows.map(row => row.id)])];
                if (newSelectedKeys.length > MAX_SELECTIONS) {
                    newSelectedKeys = newSelectedKeys.slice(0, MAX_SELECTIONS);
                    void message.warning(`Selection limited to ${MAX_SELECTIONS} items.`);
                }
            }
            else {
                newSelectedKeys = selectedRowKeys.filter(key => !changeRows.map(row => row.id).includes(key));
            }
            onSelectChange(newSelectedKeys);
        },
    };

    const [activeCampaignsIds, setActiveCampaignsIds] = useState<string[]>([])


    const onErrorStatusModalOpen = ({ campaignTitle, logs }: { logs: CampaignDataItem['logs'], campaignTitle: string }) => {
        if (!logs) {
            return
        }
        const errorLogs = logs.filter(log => log.type.name !== 'success');
        setErrorStatusModalData({ campaignTitle, logs: errorLogs });
    };

    const onErrorStatusModalClose = () => {
        setErrorStatusModalData(null)
    }

    const onCampaignStatusChange = async ({ status, campaignId }: { status: number, campaignId: string }) => {
        const campaign = campaigns.find(c => c.id === campaignId)
        if (campaign?.status.id === 0) {
            return message.open({
                type: 'error',
                content: 'Cannot update status for draft campaign',
            });
        }
        setIsIdStatusUpdating(campaignId)
        if (!status) {
            return message.open({
                type: 'error',
                content: 'Status Update Error',
            });
        }
        try {
            const response = await patchPartnerCampaignsStatusById({
                campaignIds: [campaignId],
                status
            });

            if ('data' in response) {
                setCampaignsList((prev: CampaignDataItem[]) => prev.map(campaign =>
                    campaign.id === campaignId ? { ...campaign, status: { ...campaign.status, id: status } } : campaign
                ))
                return message.open({
                    type: 'success',
                    content: 'Status Updated',
                });
            }
            if ('error' in response) {
                if (status === 1) {
                    setActiveCampaignsIds(prev => prev.filter(id => id !== campaignId))
                } else {
                    setActiveCampaignsIds(prev => [...prev, campaignId])
                }
                return message.open({
                    type: 'error',
                    content: 'Status Update Error',
                });
            }
            void message.open({
                type: 'error',
                content: 'Status Update Error',
            });
        } catch (error) {
            void message.open({
                type: 'error',
                content: 'Status Update Error',
            });
        } finally {
            setIsIdStatusUpdating(null)
        }
    }

    const onCreateCampaign = () => {
        dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: null, selectedEvent: null, campaignId: '' }))
        setIsCreateCampaignModalOpen(true)
    }

    useEffect(() => {
        setActiveCampaignsIds(campaigns.filter((campaign) => campaign.status.id === 1).map((campaign) => campaign.id))
    }, [campaigns])

    const handleChangeStatus = (row?: CampaignDataItem) => {
        const currentRow = row || selectedRow
        setIsAddPaymentDrawerOpen(false)
        if (currentRow) {
            void onCampaignStatusChange({ status: currentRow.status.id === 1 ? 2 : 1, campaignId: currentRow.id })
        }
    }

    const handleOnSwitchClick = (row: CampaignDataItem) => {
        const isPaymentMethodAdded = paymentMethodsResponse?.data?.length
        if (!isPaymentMethodAdded) {
            setIsAddPaymentDrawerOpen(true)
            setSelectedRow(row)
        } else {
            handleChangeStatus(row)
        }
    }

    const handleCloseAddPaymentDrawer = () => {
        setIsAddPaymentDrawerOpen(false)
    }

    const columns: ColumnsType = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            width: 56,
            render: (_: number, row: CampaignDataItem) => {
                return (
                    <DropDownSettings
                        status={row.status.id}
                        campaignId={row.id}
                        eventId={row.event?.id || ''}
                        campaignsList={campaigns}
                        setCampaignsList={setCampaignsList}
                        onCampaignStatusChange={() => void onCampaignStatusChange({ status: row.status.id === 1 ? 2 : 1, campaignId: row.id })}
                    >
                        <div className='cursor-pointer'>
                            <TableCellSettingsIcon />
                        </div>
                    </DropDownSettings>
                )
            }
        },
        {
            title: 'Campaign name',
            dataIndex: 'name',
            key: 'name',
            width: 240,
            fixed: 'left',
            render: (name: string, row: CampaignDataItem) => {
                return (
                    <div className='flex items-center justify-space-between'>
                        {row.event?.id ? (
                            <div>
                                <Link
                                    className='no-style'
                                    to={`/campaigns/edit/${row.event.id}/campaign/${row.id}`}
                                >
                                    <span>{name}</span>
                                </Link>
                            </div>
                        ) : (
                            <div>
                                <span>{name}</span>
                            </div>
                        )}
                    </div>
                )
            }

        },
        {
            title: 'On/off',
            dataIndex: 'status',
            key: 'status',
            width: 77,
            render: (_: number, row: CampaignDataItem) => {
                return (
                    <>
                        <Switch
                            loading={isIdStatusUpdating === row.id}
                            disabled={row.status.id === 0}
                            checked={row.status.id === 1}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onChange={() => handleOnSwitchClick(row)}
                        />
                    </>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: (status: { id: number, name: string }, row: CampaignDataItem) => {
                const filteredWarnings = row?.warnings?.filter(({ translate }) => translate) || []
                const errorLogs = row?.logs?.filter(log => log.type.name !== 'success') || [];
                const statusName = errorLogs.length ? CampaignStatuses.error : status.name
                return (
                    <div className="flex">
                        <StatusDescription
                            status={statusName as CampaignStatuses}
                            warningTooltip={filteredWarnings.length && !errorLogs.length ? (
                                <div>{filteredWarnings.map(({ id, translate }) => <div key={id}>{translate}</div>)}</div>
                            ) : null}
                        />
                        {Boolean(errorLogs.length) && (
                            <ErrorStatus onClick={() => onErrorStatusModalOpen({ logs: row.logs, campaignTitle: row.name })} />
                        )}
                    </div>
                )
            }
        },
        {
            title: 'Spent',
            dataIndex: 'cost',
            key: 'cost',
            width: 200,
            render: (_: number, row: CampaignDataItem) => {
                return formatByCurrency(row.statistics?.cost || 0 || 0, row.statistics?.currency)
            }
        },
        {
            title: 'Impressions',
            dataIndex: 'impressions',
            key: 'impressions',
            width: 200,
            render: (_: number, row: CampaignDataItem) => formatKNumber(row.statistics?.impressions || 0)

        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            width: 200,
            render: (_: number, row: CampaignDataItem) => row?.statistics?.clicks || 0

        },
        // {
        //     title: 'Average CTR',
        //     dataIndex: 'ctr',
        //     key: 'ctr',
        //     width: 200,
        //     render: (_: number, row: CampaignDataItem) => `${(row.statistics?.average_ctr || 0).toFixed(2)}%`
        // },
        {
            title: 'Average CPM',
            dataIndex: 'cpm',
            key: 'cpm',
            width: 200,
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.average_cpm || 0, row.statistics?.currency)}</span>
        },
        {
            title: 'Average CPC',
            dataIndex: 'cpc',
            key: 'cpc',
            width: 200,
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.average_cpc || 0, row.statistics?.currency)}</span>
        },
        {
            title: 'Conversions',
            dataIndex: 'conversion',
            key: 'conversion',
            width: 200,
            render: (_: number, row: CampaignDataItem) => <span>{(row?.statistics?.conversions || 0).toFixed(2)}%</span>
        },
        {
            title: 'Cost Per Conversion',
            dataIndex: 'cpc1',
            key: 'cpc1',
            width: 200,
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.conversions || 0, row.statistics?.currency)}</span>
        },
    ];


    return (
        <>
            <div className="flex">
                <Button
                    className="ml-auto mb-24"
                    type="primary"
                    onClick={onCreateCampaign}
                >
                    + Create Campaign
                </Button>
            </div>

            <InfiniteScrollTable
                isLastPage={isLastPage}
                onNextPage={onNextPage}
                isFirstPage={page === INIT_PAGE}
                className='campaign-table'
                dataSource={campaigns}
                columns={columns}
                loading={isLoading}
                rowSelection={rowSelection}
                rowKey='id'
            />
            {selectedRowKeys.length > 0
                ? (
                    <FooterCampaigns
                        setCampaignsList={setCampaignsList}
                        selectedCount={selectedRowKeys.length}
                        selectedIds={selectedRowKeys}
                        resetSelected={() => onSelectChange([])}
                        campaigns={campaigns}
                    />
                )
                :
                null}
            <ErrorStatusModal
                isOpen={Boolean(errorStatusModalData)}
                onClose={onErrorStatusModalClose}
                campaignTitle={errorStatusModalData?.campaignTitle || ''}
                logs={errorStatusModalData?.logs}
            />
            <CreateCampaignModal
                isModalOpen={isCreateCampaignModalOpen}
                setIsModalOpen={setIsCreateCampaignModalOpen}
            />
            {isAddPaymentDrawerOpen && isDesktop
                ?
                <AddPaymentDrawer
                    isOpen={isAddPaymentDrawerOpen}
                    onClose={handleCloseAddPaymentDrawer}
                    isLoading={isLoading}
                    saveBtnText="Add payment & launch campaign"
                    onSuccessCallback={() => handleChangeStatus()}
                    isShowCancelBtn={false}
                    title="Add payment method"
                />
                :
                null}
            {isAddPaymentDrawerOpen && !isDesktop
                ?
                <AddPaymentDrawerMobile
                    isOpen={isAddPaymentDrawerOpen}
                    onClose={handleCloseAddPaymentDrawer}
                    isLoading={isLoading}
                    saveBtnText="Add payment & launch campaign"
                    onSuccessCallback={() => handleChangeStatus()}
                    isShowCancelBtn={false}
                    title="Add payment method"
                />
                :
                null}
        </>
    )
}

export default TableCampaignsMain
