import React, { FC, useEffect, useRef, useState } from "react";
import {Button, Modal, Segmented} from "antd";
import ArrowIcon from '@assets/ArrowIcon';
import StatusDescription from "@components/StatusDescription";
import { SetupLanding, SetupLandingAction } from '@store/type-event/models';
import {
    segmentedOptions,
    SegmentedValues, SetupLandingStatuses
} from "@pages/EventRolePages/EventPage/setup/landing-page-templates/constants.tsx";
import RejectIcon from "@assets/RejectIcon.tsx";
import {CheckOutlined} from "@ant-design/icons";

type ChangeStatusModalPropsType = {
    isOpen: boolean;
    isDrawerOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    drawerData: SetupLanding | null;
    handleChangeStatus: ({ row, status }: { row: SetupLanding, status: SetupLandingAction }) => void
}

const PreviewModal: FC<ChangeStatusModalPropsType> = ({
    isOpen, isDrawerOpen, onClose, drawerData,
    handleChangeStatus, isLoading
}) => {
    const [segmentedValue, setSegmentedValue] = useState(SegmentedValues.laptop)

    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    useEffect(() => {
        if (iframeRef.current) {
            const iframeDocument = iframeRef.current.contentDocument;
            if (iframeDocument && drawerData && 'preview' in drawerData) {
                iframeDocument.open();
                iframeDocument.write(drawerData.preview || '');
                iframeDocument.close();
            }
        }
    }, [drawerData, isOpen]);

    return (
        <Modal
            title="Landing page approval"
            open={isOpen}
            onCancel={onClose}
            className="preview-modal"
            centered
            closeIcon={false}
            width="100%"
            zIndex={1000}
            footer={(
                <>
                    <div className='preview-modal__back' onClick={onClose}>
                        <ArrowIcon fillColor='#C0C1C3' />
                        <span className='preview-modal__back-text'>
                            Landing Page Template
                        </span>
                    </div>
                    {!isDrawerOpen && (
                        <StatusDescription
                            status={drawerData?.status?.name}
                        />
                    )}
                    <Segmented
                        className="event-setup__segmented"
                        options={segmentedOptions}
                        value={segmentedValue}
                        defaultValue={SegmentedValues.laptop}
                        onChange={setSegmentedValue}
                    />
                    {drawerData?.status?.actions?.map((cta) => {
                        return (
                            <Button
                                className={cta.slug === SetupLandingStatuses.rejected ? 'reject-btn' : 'success-btn'}
                                icon={cta.slug === SetupLandingStatuses.rejected ? <RejectIcon /> : <CheckOutlined />}
                                danger={cta.slug === SetupLandingStatuses.rejected}
                                loading={isLoading}
                                key={cta.id}
                                onClick={() => handleChangeStatus({
                                    row: drawerData,
                                    status: cta
                                })}
                            >
                                {cta.transit}
                            </Button>
                        )
                    })}
                </>
            )}
        >
            {drawerData && drawerData.preview ? (
                <div>
                    <iframe
                        ref={iframeRef}
                        title={`moderation-table-preview_${drawerData.id}`}
                        className={`preview-modal__iframe preview-modal__iframe__${segmentedValue} ${isDrawerOpen ? 'preview-modal__iframe-width' : ''}`}
                    />
                </div>
            ) : null}
        </Modal>
    )
}

export default PreviewModal;
