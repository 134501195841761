import { useState, useRef } from 'react';

type TooltipPosition = {
    left: string;
    right: string;
    top: string;
};

const TOOLTIP_WIDTH = 190

const useTooltip = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({ left: 0, top: 0 });
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    const showTooltip = () => {
        setIsVisible(true);
    };

    const hideTooltip = () => {
        setIsVisible(false);
    };

    const updateTooltipPosition = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const windowWidth = window.innerWidth;
        const tooltipLeft = event.pageX + -8

        setTooltipPosition({
            left: tooltipLeft > windowWidth - TOOLTIP_WIDTH ? 'auto' : `${event.pageX + -8}px`,
            right: tooltipLeft > windowWidth - TOOLTIP_WIDTH ? `-${TOOLTIP_WIDTH / 2}px` : 'auto',
            top: `${event.pageY + 10}px`,
        });
    };

    return {
        tooltipRef,
        isVisible,
        tooltipPosition,
        showTooltip,
        hideTooltip,
        updateTooltipPosition,
    };
};

export default useTooltip;
