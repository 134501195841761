import React, { useState} from 'react'
import { useParams, Link } from 'react-router-dom';
import { message } from 'antd';
import EventCatalogTableZeroState from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCatalog/ZeroState/components/TableZeroState';
import StatusDescription from '@components/StatusDescription';
import { EventCatalogItemStatuses } from '@shared/constants';
import CustomButton from '@components/CustomButton';
import ZeroPartners from './ZeroState';
import { useGetOrganizerPartnersByEventQuery, usePatchOrganizerPartnersStatusByIdMutation } from '@store/type-event/partners/partners.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { GetOrganizerPartnersDataItem } from '@store/type-event/partners/models';
import { TabKeys } from '../../constants';
import InfiniteScrollTable from "@components/InfiniteScrollTable/InfiniteScrollTable.tsx";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";

interface SponsorsTableProps {
    isLoading: boolean
    onInviteClick: () => void
    isPublished?: boolean
    onChangeTab: (value: TabKeys) => void
    sponsorsList: GetOrganizerPartnersDataItem[]
    isLastPage: boolean
    onNextPage: () => void
    page: number
}
const SponsorsTable = ({
    isLoading,
    isPublished,
    onInviteClick,
    onChangeTab,
    sponsorsList,
    isLastPage,
    onNextPage,
    page
}: SponsorsTableProps) => {
    const { eventId: id } = useParams()
    const [patchEvent] = usePatchOrganizerPartnersStatusByIdMutation()
    const { refetch } = useGetOrganizerPartnersByEventQuery({ eventId: id?.toString() || '', page }, { skip: !id })

    const [isStatusLoading, setIsStatusLoading] = useState(false)

    const onStatusClick = async ({ eventId, statusId, invite, statusSlug }: { eventId: string, statusId: number, invite: number, statusSlug?: string }) => {
        setIsStatusLoading(true)
        try {
            const response = await patchEvent({ eventId, statusId, invite })
            if ('data' in response) {
                sendAnalytics(`${GTMEventName.inviteStatus}_${statusSlug || ''}`)
                await refetch()
                setIsStatusLoading(false)
                return message.open({
                    type: 'success',
                    content: 'Status is changed'
                });
            }
            if ('error' in response) {
                setIsStatusLoading(false)
                return message.open({
                    type: 'error',
                    content: 'Status update error',
                });
            }
        } catch (error) {
            setIsStatusLoading(false)
            return message.open({
                type: 'error',
                content: 'Status Update error',
            });
        }
        return setIsStatusLoading(false)
    }

    const columns = [
        {
            title: <span className='whitespace-nowrap'>Invitation status</span>,
            dataIndex: 'status',
            key: 'status',
            width: 187,
            render: (status: {
                id: number;
                name: EventCatalogItemStatuses,
                actions: {
                    id: number
                    slug: EventCatalogItemStatuses
                    transit: string
                }[]
            }) => {
                return (
                    <div>
                        <StatusDescription status={status.name} />
                    </div>
                )
            }
        },
        {
            title: 'Invite',
            dataIndex: 'status',
            key: 'status',
            width: 195,
            render: (status: {
                id: number;
                name: EventCatalogItemStatuses,
                actions: {
                    id: number
                    slug: EventCatalogItemStatuses
                    transit: string
                }[]
            }, row: { id: number }) => {
                return (
                    <div className='partners-tab__status-actions'>
                        {status?.actions
                            ?
                            status?.actions.map(cta => {
                                return (
                                    <CustomButton
                                        key={cta.id}
                                        title={cta.transit}
                                        size='small'
                                        block
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={() => onStatusClick({ eventId: id?.toString() || '', statusId: cta.id, invite: row.id, statusSlug: cta.slug })}
                                    />
                                )
                            })
                            :
                            null}
                    </div>
                )
            }
        },
        {
            title: <span className='whitespace-nowrap'>Sponsor name</span>,
            dataIndex: 'name',
            key: 'name',
            width: 200,
            render: (name: string) => (<span className='whitespace-nowrap'>{name || ''}</span>)
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            width: 200,
            render: (url: string) => url ? <Link className='no-style' to={url} target='_blank'>{url}</Link> : ''
        },

    ];

    if (isLoading || isStatusLoading) {
        return (
            <EventCatalogTableZeroState />
        )
    }
    return (
        <>
            {sponsorsList.length
                ?
                <InfiniteScrollTable
                    isLastPage={isLastPage}
                    onNextPage={onNextPage}
                    isFirstPage={page === INIT_PAGE}
                    loading={isLoading}
                    dataSource={sponsorsList}
                    columns={columns}
                    rowKey='id'
                />
                :
                <ZeroPartners
                    readonly
                    onCtaClick={onInviteClick}
                    isPublished={isPublished}
                    onChangeTab={onChangeTab}
                />
            }
        </>
    )
}

export default SponsorsTable
