import React from 'react'

const AdChannelIconRender = ({ channel }: { channel: string }) => {
    switch (channel) {
        case 'google_ads':
            return (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="white" />
                    <path d="M7.59236 19.0576L13.4402 9.04688C14.183 9.48416 17.9288 11.557 18.5337 11.9512L12.6859 21.9625C12.0463 22.8073 6.78212 20.3397 7.59236 19.057V19.0576Z" fill="#FBBC04" />
                    <path d="M24.3958 19.0568L18.548 9.0467C17.7304 7.68626 15.9696 7.19189 14.5235 7.9954C13.0773 8.7989 12.6369 10.5298 13.4545 11.951L19.3023 21.9623C20.1199 23.3222 21.8807 23.8165 23.3268 23.013C24.7098 22.2095 25.2134 20.4173 24.3958 19.058V19.0568Z" fill="#4285F4" />
                    <path d="M10.123 23.3996C11.7442 23.3996 13.0584 22.118 13.0584 20.5372C13.0584 18.9563 11.7442 17.6748 10.123 17.6748C8.50175 17.6748 7.1875 18.9563 7.1875 20.5372C7.1875 22.118 8.50175 23.3996 10.123 23.3996Z" fill="#34A853" />
                </svg>
            )

        case 'linkedin_ads':
            return (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="white" />
                    <path d="M7.20312 8.46416V8.46416C7.20312 7.76668 7.76949 7.20178 8.46697 7.20358L8.50387 7.20367H23.5024C24.2208 7.20367 24.8031 7.76841 24.8031 8.46416V23.5399C24.8031 24.2359 24.2208 24.8002 23.5024 24.8002H8.50387C7.78578 24.8002 7.20312 24.2359 7.20312 23.5401V8.46416Z" fill="#0E76A8" />
                    <path d="M8.50387 7.20367H23.5024C24.2208 7.20367 24.8031 7.76841 24.8031 8.46416V23.5399C24.8031 24.2359 24.2208 24.8002 23.5024 24.8002H8.50387C7.78578 24.8002 7.20312 24.2359 7.20312 23.5401V8.46416V8.46416C7.20312 7.76668 7.76949 7.20178 8.46697 7.20358L8.50387 7.20367Z" fill="#0E76A8" />
                    <path d="M12.8748 21.5613V13.6392H10.2412V21.5613H12.8751H12.8748ZM11.5586 12.5578C12.4768 12.5578 13.0484 11.9494 13.0484 11.1892C13.0313 10.4117 12.4768 9.82031 11.576 9.82031C10.6746 9.82031 10.0859 10.4117 10.0859 11.1891C10.0859 11.9494 10.6574 12.5577 11.5413 12.5577H11.5584L11.5586 12.5578ZM14.3326 21.5613H16.9661V17.1377C16.9661 16.9013 16.9833 16.6642 17.0529 16.4953C17.2432 16.022 17.6765 15.5322 18.4042 15.5322C19.3569 15.5322 19.7383 16.2586 19.7383 17.3237V21.5613H22.3717V17.019C22.3717 14.5858 21.0726 13.4535 19.3399 13.4535C17.9193 13.4535 17.2954 14.2473 16.9488 14.788H16.9663V13.6395H14.3328C14.3671 14.3827 14.3326 21.5616 14.3326 21.5616L14.3326 21.5613Z" fill="white" />
                </svg>
            )
        case 'x_ads':
            return (
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="white" />
                    <path d="M8.43542 8.7998L14.304 16.7422L8.39844 23.1998H9.72764L14.898 17.546L19.0754 23.1998H23.5984L17.3996 14.8107L22.8965 8.7998H21.5673L16.8058 14.0067L12.9584 8.7998H8.43542ZM10.3901 9.79074H12.4679L21.6435 22.2089H19.5657L10.3901 9.79074Z" fill="#252628" />
                </svg>
            )
        default:
            <div style={{
                width: '32px',
                height: '32px',
                borderRadius: '100%',
                backgroundColor: '#fff'
            }} />
    }
}

export default AdChannelIconRender