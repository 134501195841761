import { headerTitle } from './constants'

import './styles.scss'
const HeaderSimple = ({ title }: {
    title:
    'profile' |
    'billing' |
    'campaigns_create' |
    'campaigns_edit' |
    'campaigns_group' |
    'campaigns_ads' |
    'events' |
    'events_demo' |
    'events_catalog' |
    'partners' |
    'analytics' |
    'leads' |
    'moderation' |
    'ad_accounts' |
    'settings' |
    'notifications' |
    'my_events' |
    'campaigns' |
    'pro' |
    'events_setup' |
    'audiences'
    // 'dashboard'
}) => {
    return (
        <div className='header__content'>
            <p className='header__title text-title'>
                {headerTitle[title]}
            </p>
        </div>
    )
}

export default HeaderSimple