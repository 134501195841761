import React, { useState } from 'react'
import './styles.scss'
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps, message } from 'antd'
import StatusDescription from '@components/StatusDescription'
import { CampaignStatuses } from '@shared/constants'
import { useDispatch } from 'react-redux'
import { usePatchPartnerCampaignsStatusByIdMutation } from '@store/type-partner/campaigns/campaigns.api'
import { CampaignDataItem } from '@store/type-partner/campaigns/models'
interface FooterCampaignsProps {
    selectedCount: number;
    selectedIds: string[];
    setCampaignsList: React.Dispatch<React.SetStateAction<CampaignDataItem[]>>
    resetSelected: () => void
    campaigns: CampaignDataItem[]
}


const FooterCampaigns: React.FC<FooterCampaignsProps> = ({
    selectedCount,
    selectedIds,
    resetSelected,
    setCampaignsList,
    campaigns
}) => {

    const [isLoading, setLoading] = useState(false)
    const [onDelete] = usePatchPartnerCampaignsStatusByIdMutation()
    const [patchPartnerCampaignsStatusById] = usePatchPartnerCampaignsStatusByIdMutation()

    const onCampaignsListStatusChange = async ({ status }: { status: number }) => {
        // Get only the campaigns that don't have status.id === 0
        setLoading(true)
        const validCampaignIds = selectedIds.filter(id => {
            const campaign = campaigns.find(c => c.id === id);
            return campaign?.status?.id !== 0;
        });

        if (validCampaignIds.length === 0) {
            void message.open({
                type: 'warning',
                content: 'No eligible campaigns to update status',
            });
            return;
        }

        try {
            const response = await patchPartnerCampaignsStatusById({
                campaignIds: validCampaignIds,
                status
            });

            if ('data' in response) {
                setCampaignsList((prev: CampaignDataItem[]) => prev.map(campaign =>
                    validCampaignIds.includes(campaign.id) ? { ...campaign, status: { ...campaign.status, id: status } } : campaign
                ))

                void message.open({
                    type: 'success',
                    content: 'Status Updated',
                });
            } else if ('error' in response) {
                void message.open({
                    type: 'error',
                    content: 'Failed to update status',
                });
            }
        } catch (error) {
            void message.open({
                type: 'error',
                content: 'Failed to update status',
            });
        } finally {
            resetSelected();
            setLoading(false)
        }
    }

    const onDeleteClick = async () => {
        setLoading(true)
        try {
            const response = await onDelete({ campaignIds: selectedIds, status: 3 });

            if ('error' in response) {
                console.log('SMTH WRONG');
                // Handle the error here
            } else {
                setCampaignsList((prev: CampaignDataItem[]) => prev.filter(campaign => !selectedIds.includes(campaign.id)));
                // navigate('/campaigns');
                // login(values);
                resetSelected();
                void message.open({
                    type: 'success',
                    content: 'Campaigns replaced to archive',
                });
                console.log('Success:');
            }
        } catch (error) {
            console.log('SMTH WRONG');
            // Handle the error here
        } finally {
            setLoading(false)
        }
    }

    const itemsStatus: MenuProps['items'] = [
        {
            key: '1',
            label: (
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                <div
                    onClick={() => void onCampaignsListStatusChange({ status: 1 })}
                >
                    <StatusDescription
                        status={CampaignStatuses.enabled}
                    />
                </div>

            ),
        },
        {
            key: '2',
            label: (
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                <div
                    onClick={() => void onCampaignsListStatusChange({ status: 2 })}
                >
                    <StatusDescription
                        status={CampaignStatuses.paused}
                    />
                </div>

            )
        },
        // {
        //     key: '3',
        //     label: (
        //         <StatusDescription
        //             status={CampaignStatuses.remove}
        //         />

        //     )
        // }
    ];

    return (
        <footer className='footer-campaigns'>
            <div className='footer-campaigns__main-controls'>
                <p className='footer-campaigns__counter'>
                    {selectedCount} items
                </p>
                {/* <p className='footer-campaigns__btn'>
                    Budget
                </p>
                <p className='footer-campaigns__btn'>
                    Bit strategy
                </p> */}
                <Dropdown
                    menu={{ items: itemsStatus }}
                    trigger={['click']}
                >
                    <p className='footer-campaigns__btn'>
                        Status
                    </p>
                </Dropdown>

            </div>

            <p
                className='footer-campaigns__cancel-cta'
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onDeleteClick}
            >
                {isLoading
                    ? <LoadingOutlined style={{ color: '#FFF' }} />
                    : <DeleteOutlined style={{ color: '#FFF' }} />
                }
                <span>Delete</span>
            </p>

        </footer>
    )
}

export default FooterCampaigns