import { generateUniqueStringId } from "@shared/utils.ts";

export const isTextTag = (target: HTMLElement | null) => {
    return target &&
        ['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'SPAN', 'LABEL'].includes(target.tagName) ||
        (target?.tagName === 'A' && target?.textContent?.trim() !== '') ||
        (target?.tagName === 'SPAN' && target?.textContent?.trim() !== '') ||
        (target?.tagName === 'BUTTON' && target.textContent?.trim() !== '')
}

export const isTextTarget = (target: HTMLElement) => {
    const parentElement = target.parentElement
    const isParentText = isTextTag(parentElement)
    if (isParentText) {
        return false
    }

    return target &&
        ['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'SPAN', 'LABEL'].includes(target.tagName) ||
        (target.tagName === 'A' && target.textContent?.trim() !== '') ||
        (target.tagName === 'BUTTON' && target.textContent?.trim() !== '') ||
        (target.tagName === 'SPAN' && target.textContent?.trim() !== '')
}

export const TEXT_SELECTOR = 'p, h1, h2, h3, h4, h5, span, button, a, label'

export const filterTextElement = (el: Element) => {
    return (el.tagName !== 'A' || el.textContent?.trim() !== '') &&
    (el.tagName !== 'BUTTON' || el.textContent?.trim() !== '') &&
    (el.tagName !== 'SPAN' || el.textContent?.trim() !== '') &&
    (!isTextTag(el.parentElement))
}

export const filterTextElements = (elements: NodeListOf<Element>) => {
    return Array.from(elements).filter(filterTextElement);
}

export const cleanHtml = (html = '') => {
    return html
        .replace(/\s?data-element-id="[^"]*"/g, '')
        .replace(/\s?contenteditable="[^"]*"/g, '')
        .replace(/\s?data-selected="[^"]*"/g, '');
}

export const ensureHttpsUrl = (url: string): string => {
    if (!/^https?:\/\//i.test(url)) {
        return `https://${url}`;
    }
    return url;
};

export const cleanScript = (script?: string | null) => {
    if (!script) {
        return ''
    }

    const cleanedScript = script
        .trim()
        .replace(/document\.addEventListener\('DOMContentLoaded', \(\) => \{/, '')
        .replace(/\};$/, '')
        .replace(/\s*\}\);$/, '');
    return `
        (function() {
            ${cleanedScript}
        })();
    `
}

export const removeScriptsById = (ids: string[]) => {
    ids.forEach(id => {
        const scriptToRemove = document.querySelector(`script[data-script-id="${id}"]`);
        if (scriptToRemove?.parentNode) {
            scriptToRemove.parentNode.removeChild(scriptToRemove);
        }
    });
};

export const addScripts = (scriptTags: NodeListOf<HTMLScriptElement>): string[] => {
    const scriptIds: string[] = []
    scriptTags.forEach((script) => {
        const newScript = document.createElement('script');
        const scriptId = generateUniqueStringId()

        newScript.textContent = cleanScript(script.textContent)
        newScript.setAttribute('data-script-id', scriptId); // Добавляем атрибут
        document.body.appendChild(newScript);
        scriptIds.push(scriptId);
    });
    return scriptIds
}