import { Table } from "antd";
import React, { useEffect, useState } from 'react'
import { Statistics } from "@store/type-event/campaigns/models.ts";
import { formatByCurrency } from "@shared/utils.ts";
import { useAuth } from "@contexts/AuthContext.tsx";
import { formatNumberWithThousandsSeparator } from "@components/Analytics/helpers.ts";
import { useBreakpoints } from '@hooks/browser';
import { zeroAnalyticsStat } from '@pages/PartnerRolePages/Analytics/constants';

type Props = {
    data?: Statistics[]
    isLoading: boolean
}

const AnalyticsTable = ({ data, isLoading }: Props) => {
    const { user } = useAuth();
    const isDesktop = useBreakpoints().md

    const [tableData, setTableData] = useState<Statistics[]>([zeroAnalyticsStat])

    useEffect(() => {
        if (data && data.length > 0 && !isLoading) {
            setTableData(data)
        } else setTableData([zeroAnalyticsStat])
    }, [data, isLoading])

    const currency = user?.organization?.billing?.currency
    const columns = [
        {
            title: <span style={{ whiteSpace: 'pre' }}>Spent</span>,
            dataIndex: 'cost',
            key: 'cost',
            width: '240px',
            render: (cost: number) => <span style={{ minWidth: '120px', display: 'block' }}> {formatByCurrency(cost || 0, currency)}</span>
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Impressions</span>,
            dataIndex: 'impressions',
            key: 'impressions',
            width: '240px',
            render: (impressions: number) => <span style={{ minWidth: '120px', display: 'block' }}>{formatNumberWithThousandsSeparator(impressions || 0, true)}</span>
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Clicks</span>,
            dataIndex: 'clicks',
            key: 'clicks',
            width: 200,
            render: (clicks: number) => <span style={{ minWidth: '120px', display: 'block' }}>{formatNumberWithThousandsSeparator(clicks || 0, true)}</span>
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Average CPM</span>,
            dataIndex: 'average_cpm',
            key: 'average_cpm',
            width: 400,
            render: (average_cpm: number) => <span style={{ minWidth: '120px', display: 'block' }}>{formatByCurrency(average_cpm || 0, currency)}</span>
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Average CPC</span>,
            dataIndex: 'average_cpc',
            key: 'average_cpc',
            width: 400,
            render: (average_cpc: number) => <span style={{ minWidth: '120px', display: 'block' }}>{formatByCurrency(average_cpc || 0, currency)}</span>
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Conversions</span>,
            dataIndex: 'conversions',
            key: 'conversions',
            render: (conversions: number) => (
                <span style={{ minWidth: '120px', display: 'block' }}>
                    {formatNumberWithThousandsSeparator(conversions, true)}
                </span>
            )
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>CTR</span>,
            dataIndex: 'ctr',
            key: 'ctr',
            width: 200,
            render: (ctr: number) => (
                <span>
                    {formatNumberWithThousandsSeparator((ctr || 0) * 100)}
                    %
                </span>
            )
        },
    ];


    return (
        <Table
            dataSource={tableData}
            columns={columns}
            rowKey="currency"
            loading={isLoading}
            pagination={false}
            scroll={{ x: true, y: isDesktop ? 600 : 400 }}
        />
    )
}

export default AnalyticsTable
