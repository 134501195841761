import EventsPageLayout from "@layouts/EventsPageLayout";
import HeaderBreadcrumb from "@components/Header/HeaderBreadcrumb";
import { Card, Spin } from 'antd'
import React, { useMemo } from "react";
import { getCrumbs } from "@pages/PartnerRolePages/LandingPageConstructor/helpers";
import { Link, useLocation, useParams } from "react-router-dom";
import { useGetEventByIdFromCatalogQuery } from "@store/type-partner/events-catalog/partner-event-catalog.api";
import './styles.scss';
import { useAuth } from "@contexts/AuthContext.tsx";
import { Image, AiId } from "@pages/PartnerRolePages/LandingCreationMethod/constants.tsx";
import {
    useGetPartnerLandingTypesQuery
} from '@store/type-partner/landings/landings.api';
import TooltipComponent from "@components/TooltipComponent/TooltipComponent.tsx";

const { Meta } = Card;

const LandingCreationMethod = () => {
    const { eventId = '', campaignId = '' } = useParams()
    const { user } = useAuth();

    const { data: landingTypes, isLoading: isLandingTypesLoading } = useGetPartnerLandingTypesQuery()
    const { pathname, search } = useLocation()
    const campaignIdBySearch = search ? search.split('=')[1] : null

    const { data: eventData, isLoading } = useGetEventByIdFromCatalogQuery(
        { id: eventId?.toString() || '' },
        { skip: !eventId }
    );
    const breadCrumbs = useMemo(() => getCrumbs({ pathname, eventData: eventData?.data, campaignId }), [pathname, eventData])

    const filteredTypes = useMemo(() => {
        //ASAP - what is this?
        if (user?.id === 81) {
            return landingTypes?.data?.filter(({ id }) => id.toString() !== AiId)
        }
        const aiType = landingTypes?.data?.find(({ id }) => id.toString() === AiId)
        if (aiType) {
            return [...(landingTypes?.data?.filter(({ id }) => id.toString() !== AiId) || []), aiType]
        }
        return landingTypes?.data
    }, [landingTypes])

    return (
        <EventsPageLayout className='creation-methods-page'>
            <HeaderBreadcrumb
                crumbs={breadCrumbs}
            />
            <div className="main-page landing-creation-method__page">
                <div className='landing-creation-method__title'>Select a way to create your landing page</div>
                <div className='landing-creation-method__subtitle'>All ways are flexible. The templates are 100% customizable and you can change them at any time.</div>
                <div className='landing-creation-method__wrapper custom-scroll'>
                    {isLoading || isLandingTypesLoading
                        ?
                        <div className='flex flex-center landing-creation-method__spin'>
                            <Spin size="small" />
                        </div>
                        :
                        null}
                    {!isLoading && !isLandingTypesLoading && filteredTypes?.map(({
                        id,
                        title,
                        description,
                    }) => {
                        const isAi = id.toString() === AiId
                        return (
                            <TooltipComponent
                                isActive={isAi}
                                tooltipText="Coming soon"
                            >
                                <div key={id} className={isAi ? 'landing-creation-method__wrapper-disabled' : ''}>
                                    <Link
                                        className='no-style'
                                        to={`${pathname}/create/${id}${campaignIdBySearch ? `?campaignId=${campaignIdBySearch}` : ''}`}
                                        onClick={(e) => {
                                            if (isAi) {
                                                e.preventDefault()
                                            }
                                        }}
                                    >
                                        <Card
                                            onClick={() => null}
                                            hoverable
                                            cover={(
                                                <div className='landing-creation-method__cover'>
                                                    {Image[id]}
                                                </div>
                                            )}
                                        >
                                            <Meta
                                                title={title}
                                                description={description}
                                            />
                                        </Card>
                                    </Link>
                                </div>
                            </TooltipComponent>
                        )
                    })}
                </div>
            </div>
        </EventsPageLayout>
    )
}

export default LandingCreationMethod
