import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetOrganizerCredentialsByEventIdQuery, useGetOrganizerCredentialsQuery } from "@store/type-event/credentials/credentials.api.ts";
import { Spin, Tooltip, Typography } from "antd";
import InfoIcon from "@icons/header/InfoIcon.tsx";
import { useBreakpoints } from "@hooks/browser";
import ChannelsList from './ChannelsList';
import { OrganizerCredential } from '@store/type-event/credentials/models';
import AddAccountZeroState from './AddAccountZeroState';
import './styles.scss'

const EventAccounts = () => {
    const { eventId } = useParams()
    const isDesktop = useBreakpoints().md;

    const [eventCredentials, setEventCredentialsList] = useState<OrganizerCredential[]>([])
    const [credentialsList, setCredentialsList] = useState<OrganizerCredential[]>([])
    const { data: credentialsByEvent, isLoading: isCredentialsLoading } = useGetOrganizerCredentialsByEventIdQuery({ eventId: eventId || '' }, { skip: !eventId })
    const { data: credentialsRes } = useGetOrganizerCredentialsQuery()

    useEffect(() => {
        if (credentialsByEvent?.data) {
            setEventCredentialsList(credentialsByEvent.data)
        }
    }, [credentialsByEvent?.data])

    useEffect(() => {
        if (credentialsRes?.data) {
            setCredentialsList(credentialsRes.data)
        }
    }, [credentialsRes?.data])

    const isDataLoading = isCredentialsLoading

    return (
        <article className="ad-accounts-page">
            <div className='ad-accounts-page__header'>
                <div className='ad-accounts-page__header-title'>
                    <Typography.Title level={4}>
                        Ad accounts
                    </Typography.Title>
                    <Tooltip
                        overlayClassName='tooltip-general billing-tab-tooltip'
                        title={(
                            <div>
                                The same billing account can be used for multiple events
                            </div>
                        )}
                        placement={isDesktop ? "bottom" : "bottomRight"}
                        overlayStyle={{
                            width: '230px'
                        }}
                    >
                        <div
                            className='cursor-pointer'
                            style={{ marginTop: '.5em' }}
                        >
                            <InfoIcon fillColor="#252628" width={18} height={18} />
                        </div>
                    </Tooltip>

                </div>

            </div>
            <span className='text-regular-14 text-subdued'>
                Select a platform to add an account. You can use one account on each platform at a time.
            </span>
            <div className='mb-12'></div>

            {isDataLoading && (
                <div className='flex flex-center ad-accounts-page__select-spin'>
                    <Spin size="small" />
                </div>
            )}

            {eventCredentials.length
                ?
                <ChannelsList
                    accountData={eventCredentials}
                    credentialsList={credentialsList}
                />
                :
                <AddAccountZeroState />
            }
        </article>
    )
}

export default EventAccounts
