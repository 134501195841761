import React from 'react';
import './styles.scss';

const EventServiceAgreementPage: React.FC = () => {
    return (
        <div className="event-service-agreement-page">
            <h1>Service as a Software Agreement</h1>
            <p>Effective date: April 4, 2024</p>
            <p>
                The Boost Express platform simplifies launching and management of co-marketing advertising campaigns for
                the Organizer and Sponsor, making it more technological, transparent, and comfortable. This improves and
                develops the advertising industry and provides product information to interested consumers. This
                Agreement (“Agreement”) is an offer by Boost.Express to enter into an agreement with the Organizer to
                provide access to the Platform and use its functionality. Before using the Boost.Express Platform,
                please read the terms and conditions of this Agreement. By registering on the Boost.Express Platform as
                a Sponsor, you are deemed to have fully and unconditionally agreed to this Agreement on the terms and
                conditions set forth below.
            </p>

            <h2>1. DEFINITIONS</h2>
            <ol>
                <li>
                    <strong>“Campaign”</strong> visual and textual elements of an advertisement used by a Sponsor to
                    promote its product or services, available for publication and moderation by Boost.Express, and
                    Third Party Media.
                </li>
                <li>
                    <strong>“Bid”</strong> means the cost per click on a published Online Ad, payable by the Sponsor in
                    accordance with the rates defined by the Sponsor or, if Boost Pro is activated, by the
                    Boost.Express.
                </li>
                <li>
                    <strong>“Boost.Express”</strong> means Boost.Express Incorporated, located at 251 Little Falls
                    Drive, Wilmington, New Castle County, Delaware 19808.
                </li>
                <li>
                    <strong>“Click”</strong> means any visitor or third party who lands on Online Ads.
                </li>
                <li>
                    <strong>“Event”</strong> means a project created by the Organizer, with a corresponding page on the
                    Platform.
                </li>
                <li>
                    <strong>“Internet User”</strong> means an individual who gains access to resources, services, and
                    utilities provided through the Internet.
                </li>
                <li>
                    <strong>“Lead”</strong> means potential client who have expressed some level of interest or
                    engagement with the Sponsor, such as subscribing to a newsletter, filling out a contact form, or
                    attending a webinar.
                </li>
                <li>
                    <strong>“Online Ad”</strong> means Campaign placed by Sponsor using the Platform and made available
                    for publication and moderation by Boost.Express and Third Party Media.
                </li>
                <li>
                    <strong>“Organizer”</strong> means a legal entity or an individual, who creates an Event and, who
                    places Sponsor’s Online Ads using Organizer’s declared Third Party Media.
                </li>
                <li>
                    <strong>“Sponsor”</strong> means a legal entity or an individual, who determines the object and/or
                    content of Online Ads and offers to place it on the Third Party Media, as well as determines the
                    Campaign goals.
                </li>
                <li>
                    <strong>“Platform”</strong> means a software product that provides advertising services under CPC
                    (Cost Per Click) model, with payment for certain actions of people in Third Party Media (Clicks).
                </li>
                <li>
                    <strong>“Personal Account”</strong> means a secure part of the Platform created during registration
                    and authorization of the User on the Platform, allowing the User to use the functionality of the
                    Platform.
                </li>
                <li>
                    <strong>“Services”</strong> means the activities of Boost.Express performed by Boost.Express on
                    behalf of the Organizer. The Organizer authorizes Boost.Express to conclude an agreement with the
                    Sponsor on behalf of Boost.Express and in the interests of the Organizer in order to organize the
                    receipt of payments (“Fees”) by the Organizer from the Sponsor within the framework of the
                    implementation of Online Ads using the Platform (as well as other actions aimed at organizing
                    interaction between Platform Users).
                </li>
                <li>
                    <strong>“Sponsor’s Balance”</strong> means the deposit account in the Sponsor’s Personal Account
                    from which the Bid and other payments could be deducted.
                </li>
                <li>
                    <strong>“Report”</strong> means the actual statistics generated by Boost.Express in the Platform
                    about the Platform Services.
                </li>
                <li>
                    <strong>“Third Party Media”</strong> means social networks and online advertising platforms,
                    including but not limited to Google Ads, LinkedIn Ads, Facebook, Instagram, and others, where the
                    Organizer has an active account.
                </li>
                <li>
                    <strong>“Tracking Method”</strong> means the reporting and/or tracking procedures currently used by
                    Boost.Express to measure traffic, performance and related data.
                </li>
                <li>
                    <strong>“User”</strong> means the individual accessing or using the Platform, or the company, or
                    other legal entity on behalf of which such individual is accessing or using the Platform, as
                    applicable.
                </li>
            </ol>

            <h2>2. PLATFORM BASICS</h2>
            <ol>
                <li>
                    <strong>Subject of the Agreement.</strong> The Platform provides an organized advertising
                    interaction process whereby the Sponsor places Campaigns through its Personal Account in the
                    Platform and Boost.Express facilitates the placement of Online Ads via Organizer’s accounts in Third
                    Party Media.
                </li>
                <li>
                    Boost.Express provides the Organizer with access to the Platform and its functionality after the
                    Organizer has registered a Personal Account. Boost.Express pays fees to the Organizer in accordance
                    with the terms of the Agreement.
                </li>
                <li>
                    Boost.Express does not guarantee:
                    <ol>
                        <li>the placement, positioning or the timing of delivery of any Online Ads, or</li>
                        <li>the number (if any) of impressions, publications, conversions or clicks on any Online Ads.
                        </li>
                    </ol>
                </li>
                <li>
                    <strong>Registration of the Personal Account.</strong> To register a Personal Account, the Organizer
                    fills in the form with the requested data and selects the available Third Party Media on which the
                    Sponsor's Online Ads will be placed. At the same time, the Organizer may also choose to register the
                    Account by identification through the OAuth providers.
                </li>
                <li>
                    By identifying Third Party Media and linking them to the Personal Account, the Organizer warrants
                    that its Third Party Media accounts are owned and operated by the Organizer personally.
                    Additionally, the Organizer confirms that it has obtained and provided all necessary access
                    permissions for managing advertisements. This may include authorizing Boost.Express applications
                    and/or granting Boost.Express access to the Organizer's third-party media accounts.
                </li>
                <li>
                    Organizer sets up a login and password for the Personal Account and completes the Personal Account
                    registration process by clicking on the “Register” button or other similar term.
                </li>
                <li>
                    Successful registration of the Personal Account allows the Organizer to start using the
                    functionality of the Platform.
                </li>
            </ol>

            <h2>3. EVENT AND CAMPAIGNS</h2>
            <ol>
                <li>
                    The Organizer must act in good faith and comply with the terms and restrictions outlined in this
                    agreement.
                </li>
                <li>
                    The Organizer, using the Platform, can create an Event page. By providing the Sponsor with a link or
                    the Code to the Event, the Organizer enables the Sponsor to register its Personal Account and get
                    access to the said Event.
                </li>
                <li>
                    The Organizer undertakes to provide Boost.Express with any requested information necessary to verify
                    the Event details.
                </li>
                <li>
                    <strong>Campaign creatives Intellectual Property.</strong> The owner of the Campaign’s intellectual
                    property may be:
                    <ol>
                        <li>Boost.Express (if it created the creatives for the Campaign). If this is the case, the
                            exclusive rights to the Campaign creatives are fully owned by Boost.Express. In order for
                            the Organizer to use this Campaign creatives for the benefit of the Sponsor, both the
                            Organizer and the Sponsor are granted a non-exclusive (simple) license to use this Campaign
                            creatives for the duration of the Online Advertising Publication;
                        </li>
                        <li>Sponsor (if Sponsor provides the Campaign creatives);</li>
                    </ol>
                </li>
                <li>
                    Each party when creating the Campaign guarantees that it will be created without illegal borrowing,
                    plagiarism and unlawful quotation of other works, including well-known and publicly available works,
                    the creation will not use the results of intellectual activity created or created for others, unless
                    such use has been agreed in writing by the parties or does not violate the rights of third parties.
                </li>
                <li>
                    The Organizer is not allowed to make changes to the Online Ads on its own, as well as distortion of
                    any information given.
                </li>
                <li>
                    Upon entering into this Agreement, the Organizer is notified and agrees that Boost.Express monitors
                    and logs all activities of the Platform Users within the Platform to generate statistics and
                    calculate fees.
                </li>
                <li>
                    Campaigns launched through Boost.Express on Third Party Media platforms cannot be stopped in the
                    event of Account deletion and/or revocation of access to the Platform and/or Third Party Media
                    accounts. In the event of account deletion and/or access to Third Party Media accounts being
                    revoked, Boost.Express will not be able to stop Campaigns and online advertisements already launched
                    on Third Party Media. Therefore, Boost.Express is not responsible for their further dissemination
                    and/or any actions related to them.
                </li>
            </ol>

            <h2>4. FEES</h2>
            <ol>
                <li>
                    The Bid is determined solely by the Sponsor, unless launched a campaign with Boost Pro service. If
                    Boost Pro is activated, the Sponsor authorizes Boost.Express to determine the Bid.
                </li>
                <li>
                    The Bid is used to pay for advertising placement through Organizer’s active Third Party Media
                    accounts, as well as for Boost.Express and Organizer’s fees.
                </li>
                <li>
                    <strong>Boost.Express’ Fees.</strong> For Platform Services, Boost.Express receives fees in the form
                    of a commission on each transaction (Price per Click), for services rendered by Boost.Express to
                    Sponsor.
                </li>
                <li>
                    <strong>Organizer’s Fees.</strong> A fee is paid to the Organizer for the provision of services
                    through the use of the Platform if the Bid was deducted from the Sponsor’s Budget.
                </li>
                <li>
                    The Organizer’s fee is calculated depending on the number and price of Clicks by Internet Users
                    and/or Sponsor’s BoostPro Subscription. The fee is calculated as a percentage of the revenue from
                    Sponsors, which is agreed upon with Boost.Express and documented in the Organizer’s account in the
                    “Billing” section for each particular Event. In cases where the fee is not expressed as a
                    percentage, the calculation terms are also recorded in the Organizer’s account.
                </li>
                <li>
                    The Organizer’s fee is payable only in case of Clicks, which occurred as a result of Online Ads.
                    Given that Third Party Media may retroactively adjust and change the number of recorded clicks and
                    their costs, Boost.Express will make the commission payment and final calculations after a
                    reasonable period allowed for such adjustments by Third Party Media, but no later than 60 days after
                    the obligation to pay such commission arises.
                </li>
                <li>
                    In order to make payments, the Organizer must specify the details of the settlement account in their
                    Personal Account and choose one of the available methods of payment of remuneration. The Organizer
                    is responsible for the relevance and accuracy of the details specified in the Personal Account. If
                    the details were incorrect, or it turned out to be impossible to pay the reward, the Organizer
                    reimburses the Platform for the amount of commission, bank expenses, as well as other losses
                    incurred in connection with the failed payment, as well as repeated transfer of funds to the
                    corrected details of the Organizer.
                </li>
                <li>
                    Information on the number of completed Clicks is available to the Organizer in the Personal Account.
                </li>
                <li>
                    The amount of payment due to the Organizer is displayed in the Organizer’s Personal Account.
                </li>
                <li>
                    The Organizer agrees that the Organizer’s fee, determined in accordance with the provisions of this
                    section, is full and final. Remuneration costs shall be included in the amount of the fee payable.
                </li>
                <li>
                    If, after payment of the Organizer’s fees, it is determined that the Organizer was not entitled to
                    such fees, including as a result of the Organizer's breach of this Agreement, the Organizer agrees
                    to refund such fees promptly upon request. Such refundable remuneration may be offset against any
                    further remuneration to which the Organizer may be entitled in the future, or the amount of such
                    remuneration may be deducted from the Organizer’s balance.
                </li>
                <li>
                    Boost.Express has the right to suspend payments to the Organizer if there are objective suspicions
                    that the Organizer has violated this Agreement.
                </li>
            </ol>

            <h2>5. THIRD PARTY MEDIA TERMS</h2>
            <ol>
                <li>
                    In order to publish Online Ads, Third Party Media moderates the Online Ads to ensure that it meets
                    the requirements.
                </li>
                <li>
                    Boost.Express delivery of certain advertising services under this Agreement via third parties shall
                    be subject to such third parties’ applicable third party terms and conditions (“Third Party Terms”),
                    which may include, but not limited to: (i) Google, (ii) LinkedIn and Facebook and set forth herein
                    below. By using the Boost.Express Platform pursuant to this Agreement, Organizer agrees to be bound
                    by the applicable Third Party Terms.
                </li>
                <li>
                    The Third Party Terms include all applicable third party policies, documents, terms and conditions
                    referenced in the Third Party Terms, including without limitation, the applicable privacy policy,
                    terms of service, Ads policy guidelines, and trademark requirements. Organizer agrees and
                    acknowledges that the Third Party Terms may be modified from time to time by such third parties.
                    Organizer shall be responsible for regularly reviewing the Third Party Terms and becoming familiar
                    with any such modifications. Organizer’s use of the Boost.Express Platform under this Agreement
                    after the Third Party Terms have changed constitutes Organizer’s acceptance of the new Third Party
                    Terms. Organizer hereby agrees to be bound by the Third Party Terms. Organizer agrees to undertake
                    and agrees to all applicable obligations, representations and warranties contemplated by the Third
                    Party Terms in order to enable Boost.Express to facilitate the provision of the applicable services
                    for the benefit of Organizer via such third parties pursuant to the applicable Third Party Terms.
                    Organizer understands and agrees that to the extent it violates any Third Party Terms, it may be
                    liable to the applicable Third Party Media and/or to Boost.Express for any damages or harm arising
                    out of such violation.
                </li>
                <li>
                    Organizer understands, acknowledges and agrees to the following terms:
                    <ol>
                        <li>
                            Boost.Express does not have control over the policies of search engines or directories,
                            social media outlets or other third party websites (collectively, “Third Party Media”) with
                            respect to the type of sites and/or content that they accept now or in the future.
                            Organizer’s website(s) or content may be excluded from any Third Party Media at any time at
                            the sole discretion of the search engine or directory;
                        </li>
                        <li>
                            Boost.Express has no control over the indexing and ranking methodologies, policies for the
                            display and prioritization of content or changes thereto, of Third Party Media. Changes in
                            these methodologies can result in significant changes in Organizer’s page rankings and/or
                            the display and visibility of Ads;
                        </li>
                        <li>
                            Occasionally, Third Party Media will drop listings or content for no apparent reason. Often,
                            a listing or content will reappear without any additional submissions;
                        </li>
                        <li>
                            Boost.Express is not responsible for any changes made to Sponsor’s website(s) by Sponsor or
                            other parties that adversely affect the search engine or directory rankings of Sponsor’s
                            website(s) or the appearance of Sponsor’s content or links in Third Party Media;
                        </li>
                        <li>
                            Boost.Express may provide the services hereunder itself or through one or more third party
                            vendors and/or Boost.Express.
                        </li>
                    </ol>
                </li>
            </ol>

            <h2>6. INTELLECTUAL PROPERTY</h2>
            <ol>
                <li>
                    User owns all of the content they provide to Boost.Express. User grants Boost.Express a
                    nonexclusive, revocable, worldwide, limited, fully paid-up and royalty-free right to use, copy,
                    prepare derivative works of, distribute, publish, remove, retain, add, process, or analyze this
                    information for the sole purpose of providing the Platform to Users. User represents and warrants
                    that User is entitled to and authorized to submit the Content and that such Content User submits is
                    accurate and not in violation of any contractual restrictions or third party rights.
                </li>
                <li>
                    Boost.Express and its affiliates own and retain all rights, title, and interest in and to the
                    Platform along with all patents, inventions, copyrights, trademarks, domain names, trade secrets,
                    know-how, and any other intellectual property and/or proprietary rights (“Intellectual Property
                    Rights”) related to the Platform. Using the Platform under this Agreement does not give User
                    additional rights in the Platform or ownership of any Intellectual Property Rights associated with
                    the Platform. Subject to User’s compliance with and limitations set forth in this Agreement and upon
                    User’s subscription to the Platform, Boost.Express grants User a non-exclusive, non-transferable,
                    non-sublicensable, revocable right to access and use the Platform.
                </li>
                <li>
                    Elements of the Platform are also protected by trade dress, trade secret, unfair competition, and
                    other laws and may not be copied or imitated in whole or in part. All custom graphics, icons, and
                    other items that appear on the Platform are trademarks, service marks or trade dress (“Marks”) of
                    Boost.Express, its affiliates, or other entities that have granted Boost.Express the right and
                    license to use such Marks and may not be used or interfered with in any manner without the express
                    written consent of Boost.Express. Except as otherwise expressly authorized by these Terms, User may
                    not copy, reproduce, modify, lease, loan, sell, create derivative works from, upload, transmit, or
                    distribute the Intellectual Property of the Platform in any way without Boost.Express’ written
                    permission. Except as expressly provided herein, Boost.Express does not grant to User any expressed
                    or implied rights to Boost.Express’ or any third party’s Intellectual Property.
                </li>
            </ol>

            <h2>7. WARRANTY</h2>
            <ol>
                <li>
                    THE PLATFORM IS PROVIDED ON AN “AS IS” BASIS, WITHOUT ANY WARRANTIES, GUARANTEES, CONDITIONS, OR
                    REPRESENTATIONS OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, BOOST EXPRESS EXPRESSLY
                    DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED
                    TO, EXPRESS OR IMPLIED WARRANTIES OF MERCHANTABILITY, DESIGN, TITLE, QUALITY, FITNESS FOR A
                    PARTICULAR PURPOSE, AND NON-INFRINGEMENT ON BEHALF OF BOOST EXPRESS AND ITS AFFILIATES AND ITS AND
                    THEIR LICENSORS. BOOST EXPRESS AND ITS AFFILIATES RELY ON THIRD PARTY DATA SOURCES FOR INFORMATION
                    AND THEREFORE BOOST EXPRESS CANNOT AND DOES NOT WARRANT THAT THE USE OF PLATFORM WILL BE
                    UNINTERRUPTED, AVAILABLE, ACCESSIBLE, SECURE, TIMELY, ACCURATE, COMPLETE, FREE FROM VIRUSES, OR
                    ERROR-FREE. BOOST EXPRESS DISCLAIMS ALL LIABILITY FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS,
                    OR POOR USE CONDITIONS OF THE USE OF PLATFORM DUE TO INAPPROPRIATE EQUIPMENT, INTERRUPTIONS, OR
                    OTHER ISSUES RELATED TO INTERNET AND ELECTRONIC COMMUNICATIONS SERVICE PROVIDERS, OR ANY OTHER
                    DELAY, ERROR, OMISSION, INTERRUPTION, DELETION, THEFT, DESTRUCTION, UNAUTHORIZED ACCESS TO, OR LOSS
                    OF DATA ON THE PLATFORM, ALL OF WHICH ARE NOT WITHIN BOOST EXPRESS’ REASONABLE CONTROL. BOOST
                    EXPRESS DOES NOT PROVIDE ANY WARRANTIES, INDEMNITIES OR REMEDIES FOR ANY FREE TRIALS OR BETA USE OF
                    PLATFORM OR ANY FEATURE OF THE USE OF PLATFORM IN BETA OR IN A FREE TRIAL VERSION. BETA USE OF
                    PLATFORM AND FREE TRIALS ARE OPTIONAL AND ARE USED AT YOUR OWN RISK.
                </li>
                <li>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BOOST EXPRESS HEREBY DISCLAIMS ALL WARRANTIES OF
                    ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
                    WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, TITLE, AND FITNESS FOR A PARTICULAR PURPOSE. NO
                    STATEMENT OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM BOOST EXPRESS IN ANY MEANS OR
                    FASHION SHALL CREATE ANY WARRANTY NOT EXPRESSLY OR EXPLICITLY SET FORTH IN THIS AGREEMENT. THE
                    CONTENT ON THE PLATFORM MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS.
                </li>
            </ol>
            <h2>8. LIMITATION OF THE LIABILITY</h2>
            <ol>
                <li>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL BOOST EXPRESS, ITS AFFILIATES,
                    LICENSORS, AND SERVICE PROVIDERS, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, CONTRACTORS,
                    AGENTS, SUCCESSORS, AND ASSIGNS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                    CONSEQUENTIAL, EXEMPLARY, OR OTHER TYPE OF DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR COVER
                    OR LOSS OF USE, DATA, REVENUE OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
                    PERFORMANCE OF THE PLATFORM, WITH THE DELAY OR INABILITY TO USE THE PLATFORM, OR FOR ANY CONTENT OR
                    ITEM ON THE PLATFORM, OR OTHERWISE ARISING OUT OF THE USE OF THE PLATFORM (“DAMAGES”), WHETHER BASED
                    ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, FAILURE OF ANY LIMITED REMEDY TO ACHIEVE ITS
                    ESSENTIAL PURPOSE, OR OTHERWISE, EVEN IF BOOST EXPRESS OR ITS AFFILIATES OR OTHER RELATED ENTITIES
                    HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. USER UNDERSTANDS AND AGREES THAT USER ASSUMES ALL
                    RESPONSIBILITY FOR THEIR USE OF THE PLATFORM AND THAT USER WILL BE SOLELY RESPONSIBLE FOR ANY AND
                    ALL DAMAGES ARISING FROM THEIR USE OF THE PLATFORM. NOTWITHSTANDING THIS SECTION, IF BOOST EXPRESS
                    IS DETERMINED TO HAVE ANY LIABILITY TO USER OR ANY THIRD PARTY FOR ANY LOSS, HARM, OR DAMAGE, USER
                    AGREES THAT THE MAXIMUM AGGREGATE LIABILITY OF BOOST EXPRESS AND ITS AFFILIATES, OFFICERS,
                    DIRECTORS, EMPLOYEES, LICENSORS, SERVICE PROVIDERS, CONTRACTORS, OR AGENTS SHALL, IN ALL CASES, BE
                    LIMITED TO FIFTY ($50) U.S. DOLLARS.
                </li>
                <li>
                    THIS SECTION DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                    BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR
                    CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS THE ABOVE LIMITATIONS AND
                    EXCLUSIONS MAY NOT APPLY TO USER. IN SUCH STATES OR JURISDICTIONS, BOOST EXPRESS LIABILITY IS
                    LIMITED TO THE LESSER OF (A) FIFTY ($50) U.S. DOLLARS OR (B) THE EXTENT OF THE AMOUNT PERMITTED BY
                    LAW, THEREBY MINIMIZING OUR LIABILITY TO USER TO EITHER FIFTY ($50) U.S. DOLLARS OR THE LOWEST
                    AMOUNT PERMITTED BY APPLICABLE LAW.
                </li>
            </ol>

            <h2>9. INDEMNIFICATION</h2>
            <ol>
                <li>
                    To the fullest extent permitted by applicable law, User agrees to indemnify, defend, and hold
                    harmless Boost.Express and our affiliates, and each of our respective employees, officers,
                    directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent
                    companies, subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns
                    (individually and collectively, the “Boost.Express Parties”), from and against all actual or alleged
                    claims, damages, awards, judgments, losses, liabilities of every kind, and nature whatsoever,
                    whether known or unknown, that are caused by, arise out of, or are related to (a) using or misusing
                    the Platform, (b) User’s violation of this Agreement, and/or (c) User’s violation of any right(s) of
                    any third party. User agrees to promptly notify Boost.Express of any Claim(s) and shall cooperate
                    fully with the Boost.Express Parties in defending such Claims. User further agrees that the
                    Boost.Express Parties shall have control of the defense or settlement of any third party Claims.
                    THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN ANY WRITTEN
                    AGREEMENT(S) BETWEEN USER AND BOOST EXPRESS.
                </li>
            </ol>

            <h2>10. MISCELLANEOUS</h2>
            <ol>
                <li>
                    Entire Agreement. This Agreement constitutes the sole and entire agreement between User and
                    Boost.Express with respect to the subject matter hereof, and supersedes all prior and
                    contemporaneous understandings, agreements, representations, and warranties, both written and oral,
                    regarding the Platform.
                </li>
                <li>
                    Modification. Boost.Express reserves the right to modify this Agreement at any time by posting the
                    modified provisions on the Platform and without providing User with prior notice. User’s continued
                    use of the Platform shall be deemed to constitute acceptance by User of such modifications. The
                    updated Agreement will be effective as of the time of posting, or on such later date as may be
                    specified in the updated Agreement, and will apply to the use of the Platform from that point
                    forward. The Effective Date set forth above indicates the last date the Agreement was updated, so be
                    sure to check back for updates. Boost.Express may also change or discontinue any aspect, service, or
                    feature of the Platform at any time, including but not limited to content, availability, and
                    equipment needed for access and use, without notice.
                </li>
                <li>
                    Waiver. No waiver by Boost.Express of any breach or default hereunder shall be deemed to be a waiver
                    of any preceding or subsequent breach or default.
                </li>
                <li>
                    Severability. If any provision of this Agreement is held by a court or other tribunal of competent
                    jurisdiction to be invalid, unenforceable, or illegal for any reason, such provision shall be
                    limited to the minimum extent such that the remaining provisions of the Agreement will continue in
                    full force and effect.
                </li>
                <li>
                    Force Majeure. Neither party will be liable for or be considered to be in breach or default of this
                    Agreement on account of any delay or failure to perform as required by this Agreement (except for
                    User’s obligations to make payments to Boost.Express hereunder) as a result of any cause or
                    condition beyond its reasonable control, so long as that party uses commercially reasonable efforts
                    to avoid or remove the causes of non-performance.
                </li>
                <li>
                    Governing Law. This Agreement will be interpreted, construed, and enforced in all respects in
                    accordance with the local laws of the State of Delaware, U.S.A.
                </li>
                <li>
                    Assignability. This Agreement is personal to User, and is not assignable, transferable, or
                    sublicensable by User except with Boost.Express’ prior written consent.
                </li>
                <li>
                    Notices. All notices to Boost.Express under this Agreement must be in writing and sent
                    electronically to legal-request@boost.express with a subject line “Notice under Boost.Express
                    Organizer Agreement”, and will be deemed to have been duly given receipt is electronically
                    confirmed.
                </li>
            </ol>

            <h2>11. CONTACT US</h2>
            <ol>
                <li>
                    If you have any questions about our Platform or this Agreement, please email us at
                    {' '}
                    <a href="mailto:hello@boost.express">
                        hello@boost.express
                    </a>
                </li>
            </ol>
        </div>
    );
};

export default EventServiceAgreementPage;
