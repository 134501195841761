import React, { useContext, useEffect } from 'react'
import BillingStripeFormWrapper from '@components/BillingStripeForm';
import { StripeContext } from '@contexts/StripeContext';
import Loader from '@components/Loader';
import MobileDrawer from '@components/mobile/Drawer';
interface AddPaymentDrawerMobileProps {
    isOpen: boolean;
    onClose: () => void;
    isLoading?: boolean;
    onSuccessCallback?: () => void;
    saveBtnText?: string;
    isShowCancelBtn?: boolean
    title?: string
}

const AddPaymentDrawerMobile: React.FC<AddPaymentDrawerMobileProps> = ({
    isOpen,
    onClose,
    isLoading,
    onSuccessCallback,
    saveBtnText,
    isShowCancelBtn = true,
    title
}) => {

    const {
        onOpenStripeForm,
        loading,
        intent,
        setIntent,
        apiKey,
        onAddPaymentClick
    } = useContext(StripeContext)


    useEffect(() => {
        if (isOpen) {
            onOpenStripeForm()
        }
    }, [isOpen])

    const onModalClose = () => {
        onClose();
        setIntent(null)
    }

    const onSubmit = async () => {
        await onAddPaymentClick()
            .then(() => {
                onClose()
                if (onSuccessCallback) {
                    onSuccessCallback()
                }
            })
    }
    return (

        <MobileDrawer
            title={title || 'Add new card'}
            isOpen={isOpen}
            onClose={onModalClose}
            onCancel={onModalClose}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onOk={onSubmit}
            resetButtonText="Cancel"
            isShowResetBtn={isShowCancelBtn}
            applyButtonText={saveBtnText || "Save"}
            isLoading={loading || isLoading}
        >
            <>

                {loading && <Loader />}
                {intent
                    ?
                    <BillingStripeFormWrapper
                        intent={intent}
                        apiKey={apiKey}
                    />
                    : null
                }</>

        </MobileDrawer>
    )
}

export default AddPaymentDrawerMobile