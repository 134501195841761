import React from 'react';
import styles from './EventInfoFormFooter.module.css';
import {EventInfoFormFooterPropsType} from "@components/EventInfoForm";
import { Button, Tooltip } from "antd";

const EventInfoFormFooter: React.FC<EventInfoFormFooterPropsType> = (props) => {
    const {onSubmit, isSubmitDisabled, isLoading} = props;

    return (
        <div className={styles.EventInfoFormFooter}>
            <div className={styles.EventInfoFormFooter__content}>
                <Tooltip
                    open={!isSubmitDisabled ? false : undefined}
                    overlayClassName='tooltip-general tooltip-small registration-account-form__tooltip'
                    title="In order to continue the onboarding, please fill out all input fields"
                    placement="top"
                >
                    <Button
                        disabled={isSubmitDisabled}
                        onClick={onSubmit}
                        type={'primary'}
                        size={'large'}
                        className={styles.EventInfoFormFooter__submitButton}
                        loading={isLoading}
                    >
                        Continue
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
}

export default EventInfoFormFooter;