import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, Spin } from "antd";
import InfoIcon from "@icons/header/InfoIcon";
import { CheckCircleIcon } from "@icons/CheckCircleIcon";
import { useGetPartnerEventQuery } from "@store/type-partner/events/partner-event.api";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api";
import { EventCatalogItemStatuses } from "@shared/constants";

type Props = {
    isModalOpen: boolean
    setIsModalOpen: (isOpen: boolean) => void
}

const CreateCampaignModal = ({
    isModalOpen,
    setIsModalOpen
}: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_invite_status' })

    const availableStatusIds = statuses?.data?.filter(({ slug }) => slug === EventCatalogItemStatuses.available || slug === EventCatalogItemStatuses.active)?.map(({ id }) => id) || []
    const { data: myEventsRes, isLoading } = useGetPartnerEventQuery({ filters: { statuses: availableStatusIds } }, { refetchOnMountOrArgChange: true })

    const [selectedEventId, setSelectedEventId] = useState<number | null>(null)

    const onContinue = () => {
        navigate(`create/new/${selectedEventId || ''}`, { state: { from: location } })
    }

    const onCloseModal = () => {
        setIsModalOpen(false)
    }

    const onSelectEventId = (id: number) => {
        setSelectedEventId(id)
    }

    return (
        <Modal
            title="Create Campaign"
            open={isModalOpen}
            onCancel={onCloseModal}
            centered
            width={500}
            footer={(
                <div
                    className="flex justify-end"
                >
                    <Button
                        size="large"
                        onClick={onCloseModal}
                        className="mr-6"
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        disabled={!selectedEventId}
                        onClick={onContinue}
                    >
                        Continue
                    </Button>
                </div>
            )}
        >
            {Boolean(myEventsRes?.data?.length) && !isLoading && (
                <p className='text-regular-14 mb-4 text-neutral-8 flex'>
                    <span>Select event</span>
                    <InfoIcon className='mx-4' fillColor='#57585A' width={14} height={14} />
                </p>
            )}
            {isLoading ? (
                <div className='flex flex-center ad-accounts-page__select-spin'>
                    <Spin size="small" />
                </div>
            ) : (
                <div className='ad-accounts-page__select-wrapper header-campaigns__select-wrapper custom-scroll'>
                    {myEventsRes?.data?.map((item) => {
                        return (
                            <div className='ad-accounts-page__select-item header-campaigns__select-item' onClick={() => onSelectEventId(item.id)}>
                                <img src={item.logo} alt="campaign-logo" />
                                <span>{item.name}</span>
                                &nbsp;
                                {selectedEventId === item.id && (
                                    <CheckCircleIcon isActive={false} width='22' height='22' fillColor='#4E67EA' />
                                )}
                            </div>
                        )
                    })}
                    {!myEventsRes?.data?.length && !isLoading && (
                        <p className='text-regular-14 mb-4 text-neutral-8 flex'>
                            <span>There is no events</span>
                        </p>
                    )}
                </div>
            )}
        </Modal>
    )
}

export default CreateCampaignModal
