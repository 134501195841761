const DeleteIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.60435 2.67794H6.42578C6.524 2.67794 6.60435 2.59759 6.60435 2.49937V2.67794H13.3901V2.49937C13.3901 2.59759 13.4704 2.67794 13.5686 2.67794H13.3901V4.28509H14.9972V2.49937C14.9972 1.71143 14.3566 1.0708 13.5686 1.0708H6.42578C5.63784 1.0708 4.99721 1.71143 4.99721 2.49937V4.28509H6.60435V2.67794ZM17.8544 4.28509H2.14007C1.74498 4.28509 1.42578 4.60428 1.42578 4.99937V5.71366C1.42578 5.81187 1.50614 5.89223 1.60435 5.89223H2.95257L3.50391 17.5663C3.53962 18.3275 4.16909 18.9279 4.93025 18.9279H15.0642C15.8276 18.9279 16.4548 18.3297 16.4905 17.5663L17.0419 5.89223H18.3901C18.4883 5.89223 18.5686 5.81187 18.5686 5.71366V4.99937C18.5686 4.60428 18.2494 4.28509 17.8544 4.28509ZM14.8923 17.3208H5.10212L4.56194 5.89223H15.4325L14.8923 17.3208Z"
                fill="#252628"/>
        </svg>
    )
}

export default DeleteIcon
