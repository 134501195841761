import { useEffect, useState } from 'react';
import AdIconRender from './AdIconRender';
import './styles.scss';
import { Button, Divider, message, Modal, notification, Select, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useBreakpoints } from '@hooks/browser';
import { usePostOrganizerEventCredentialsByIdMutation } from '@store/type-event/credentials/credentials.api';
import { ErrorDataResponse } from '@shared/utils';
import { useParams } from 'react-router-dom';
import { useGetOrganizerEventByIdQuery } from '@store/type-event/events/events.api';

interface Props {
    id: string;
    name: string;
    slug: string;
    options: Option[];
    selectedId: string | null;

}

interface Option {
    label: string;
    value: number;
}

const AdChannelAccountCard = ({
    id,
    name,
    slug,
    options,
    selectedId
}: Props) => {
    const { eventId } = useParams()
    const { data: eventData } = useGetOrganizerEventByIdQuery({ eventId: eventId?.toString() || '' }, { skip: !eventId });

    const isDesktop = useBreakpoints().md

    const [selectedAccount, setSelectedAccount] = useState<{ value: string, label: string } | null>(null)
    const [accountToPost, setAccountToPost] = useState<{ id: string, name: string } | null>(null)

    const [postOrganizerEventCredentialsById] = usePostOrganizerEventCredentialsByIdMutation()

    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        if (selectedId) {
            const option = options.find(option => option.value === Number(selectedId));
            if (option) {
                setSelectedAccount({
                    value: option.value.toString(),
                    label: option.label
                });
            }
        }
    }, [selectedId, options]);

    const onModalCancel = () => {
        setAccountToPost(null)
    }

    const onSubmit = async () => {
        if (!accountToPost || !eventId) return
        setLoading(true)
        try {
            const res = await postOrganizerEventCredentialsById({
                eventId,
                credentialId: + accountToPost.id
            })
            if ('error' in res) {
                setLoading(false)
                return message.error((res as ErrorDataResponse)?.error?.data?.message || 'Withdrawal is not sent');
            }
            if (res) {
                void notification.success({
                    message: `${accountToPost.name} Account added`,
                });
                setLoading(false);
                setSelectedAccount({ value: accountToPost.id, label: accountToPost.name });
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            onModalCancel()

        }
    };

    const isDisabled = !eventData?.data?.change_status?.credential?.[slug]
    const selectComponent = (
        <Select
            style={{ width: '100%', maxWidth: '480px' }}
            options={options}
            disabled={isDisabled}
            onSelect={(value, option) => {
                setAccountToPost({ id: value.toString(), name: option.label })
            }}
            value={selectedAccount?.label}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div className='flex'>
                        <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => setAccountToPost({ id, name })}
                        >
                            Add Account
                        </Button>
                    </div>
                </>
            )}
        />
    );

    return (
        <>
            <div className='ad-channel-card'>
                <div className='ad-channel-card__icon-section'>
                    <AdIconRender slug={slug} />
                    <span className='whitespace-nowrap'>{name}</span>
                </div>
                {isDisabled ? (
                    <Tooltip
                        title="Ad account cannot be changed since sponsors have already launched ad campaigns."
                        color="#ffffff"
                        placement='bottom'
                        overlayInnerStyle={{ color: '#000000' }}
                        style={{ width: '200px', padding: '6px 8px' }}
                    >
                        {selectComponent}
                    </Tooltip>
                ) : (
                    selectComponent
                )}
            </div>
            <Modal
                title='Change ad account'
                open={!!accountToPost}
                onCancel={onModalCancel}
                centered
                width={isDesktop ? 440 : 'calc(100vw - 40px)'}
                maskClosable={false}
                footer={(
                    <div
                        className="flex justify-end"
                    >
                        <Button
                            size="large"
                            onClick={onModalCancel}
                            className='mr-6'
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={isLoading}
                            size="large"
                            type="primary"
                            onClick={() => void onSubmit()}
                        >
                            Change
                        </Button>
                    </div>
                )}
            >
                Are you sure you want to change the {name} account to
                {' '}
                <b>{accountToPost?.name || ''}</b>
                ?
            </Modal>
        </>
    );
};

export default AdChannelAccountCard;
