import { Statistics } from "@store/type-event/campaigns/models.ts";

export interface StatisticsMetrics {
    metric: string
    type: string
    translate: string
}

export enum StatisticsMetricTypes {
    countable = 'countable',
    percent = 'percent',
    money = 'money',
}

export interface GetStatisticsMetricsResponse {
    data: StatisticsMetrics[]
}

export interface GetStatisticsParams {
    campaigns?: string[]
    landings?: string[]
    events?: string[]
    partners?: string[]
    dateFrom?: string
    dateTo?: string
    gap?: string
}

export interface StatisticDate {
    statistics: Statistics
    date: string
}

export interface GetStatisticsResponse {
    data: {
        dates: StatisticDate[]
        sums: Statistics[]
    }
}

export enum FiltersKeys {
    campaigns = 'campaigns',
    landings = 'landings',
    events = 'events',
    partners = 'partners',
    dateFrom = 'dateFrom',
    dateTo = 'dateTo',
    gap = 'gap'
}

export interface Filters {
    campaigns: string[]
    landings: string[]
    events: string[]
    dateFrom?: string
    dateTo?: string
    gap?: string
    partners: string[]
}



