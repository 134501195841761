// https://boost.allnetru.dev/api/documentation/partner#/Campaigns
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CampaignEditorBody, CampaignEditorResponse, FilterPartnerCampaigns, GetPartnerCampaignsResponse } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const partner_campaigns = `partner_campaigns`;

export const partnerCampaigns = createApi({
    reducerPath: partner_campaigns,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        partner_campaigns
    ],
    endpoints: build => ({
        getPartnerCampaigns: build.query<GetPartnerCampaignsResponse, { filter?: FilterPartnerCampaigns }>({
            query: ({ filter }) => {
                const params = new URLSearchParams();
                if (filter?.search) {
                    params.append('filters[search]', filter?.search);
                }
                if (filter?.events) {
                    filter.events.forEach(id => params.append('filters[events][]', id));
                }
                if (filter?.page) {
                    params.append('page', filter.page.toString());
                }

                const queryString = params.toString();
                return {
                    url: `/partner/campaigns${queryString ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [partner_campaigns],
        }),
        postCreateCampaignByEventId: build.mutation<CampaignEditorResponse, CampaignEditorBody>({
            query: ({ event_id, ...body }) => {
                return {
                    method: 'POST',
                    url: `partner/events/${event_id!}/campaigns`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                    body
                };
            },
            invalidatesTags: [partner_campaigns],
        }),
        getCampaignEditorDataById: build.query<CampaignEditorResponse, { eventId: string, campaignId: string }>({
            query: ({ eventId, campaignId }) => ({
                url: `/partner/events/${eventId}/campaigns/${campaignId}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            providesTags: [partner_campaigns],
        }),
        patchCampaignDataById: build.mutation<CampaignEditorResponse, CampaignEditorBody>({
            query: ({ event_id, campaign_id, ...body }) => ({
                body,
                method: 'PATCH',
                url: `/partner/events/${event_id!}/campaigns/${campaign_id}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [partner_campaigns],
        }),
        // getPartnerCampaignStatuses: build.query<GetPartnerCampaignStatusesResponse, void>({
        //     query: () => ({
        //         url: `/partner/campaign/statuses`,
        //         headers: {
        //             Accept: 'application/json', // Specify JSON response
        //         },
        //     }),
        //     providesTags: [partner_campaigns],
        // }),

        patchPartnerCampaignsStatusById: build.mutation<CampaignEditorResponse,
            {
                campaignIds: string[],
                status: number
            }
        >({
            query: ({ campaignIds, status }) => {
                return ({
                    method: 'PATCH',
                    url: `/partner/campaigns/status/${status}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                    body: { ids: campaignIds },
                })
            },
            // invalidatesTags: [partner_campaigns],
        }),
    }),
});

export const {
    useGetPartnerCampaignsQuery,
    usePostCreateCampaignByEventIdMutation,
    useGetCampaignEditorDataByIdQuery,
    usePatchCampaignDataByIdMutation,
    usePatchPartnerCampaignsStatusByIdMutation,
} = partnerCampaigns;