import { useState } from 'react';
import { Button, Modal, } from 'antd';
import { useBreakpoints } from "@hooks/browser";
import { PartnerCredential } from '@store/type-partner/credentials/models';
import AccountCard from './AccountCard';

type Props = {
    onClose: () => void
    channels: PartnerCredential[];
}

const AddAdsAccountModal = ({
    onClose,
    channels,
}: Props) => {
    const isDesktop = useBreakpoints().md;
    const [selectedCard, setSelectedCard] = useState<PartnerCredential | null>(null)

    return (
        <Modal
            title='Add account'
            open={true}
            onCancel={onClose}
            centered
            width={isDesktop ? 440 : 'calc(100vw - 40px)'}
            maskClosable={false}
            footer={(
                <div
                    className="flex justify-end ad-accounts-page__modal__footer"
                >
                    <Button
                        size="large"
                        onClick={onClose}
                        className='mr-6'
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        disabled={!selectedCard}
                        onClick={() => selectedCard ? window.location.href = selectedCard.url : null}
                    >
                        Add account
                    </Button>
                </div>
            )}
        >
            <div>
                <span className=''>
                    For adding Ad Accounts, please select the necessary social platform
                </span>


                <div className='ad-add-channel-modal__cards'>
                    {channels.map(item => {
                        return (
                            <div
                                key={item.id}
                                onClick={() => window.location.href = item.url}
                            >
                                <AccountCard
                                    key={item.id}
                                    adItem={item}
                                    isSelected={selectedCard?.id === item.id}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}

export default AddAdsAccountModal;
