import { FC, useState } from "react";
import { Button, Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { SetupLanding, SetupLandingAction } from '@store/type-event/models';
import { PatchSetupLandingStatusType } from "@pages/EventRolePages/EventPage/setup/landing-page-templates/types.ts";

type ChangeStatusModalPropsType = {
    isOpen: boolean
    onClose: (isOpen: boolean) => void
    data: {
        row: SetupLanding,
        status: SetupLandingAction
    }
    handlePatchLandingStatus: ({ landing, status, comment, statusSlug }: PatchSetupLandingStatusType) => void
}

const RejectionModal: FC<ChangeStatusModalPropsType> = ({ isOpen, onClose, data, handlePatchLandingStatus }) => {
    const isLoadingChangeUserPassword = false

    const [comment, setComment] = useState('')

    const isDisabledRejectButton = !comment

    const onChangeFieldValue = (value: string) => {
        setComment(value)
    }

    const handleCancel = (): void => {
        onClose(false)
        setComment("")
    }

    const handleReject = () => {
        const { row, status } = data
        const rejectionData = {
            comment,
            landing: row.id,
            status: status.id,
            statusSlug: status.slug
        }
        handlePatchLandingStatus(rejectionData)
        setComment("")
        onClose(false)
    }

    return (
        <Modal
            zIndex={1001}
            title={(
                <span
                    className="rejection-modal__title"
                >
                    Rejection reason
                </span>
            )}
            mask
            open={isOpen}
            onCancel={handleCancel}
            confirmLoading={isLoadingChangeUserPassword}
            className="rejection-modal"
            footer={(
                <div
                    className="rejection-modal__footer"
                >
                    <Button
                        size="large"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        danger
                        onClick={handleReject}
                        disabled={isDisabledRejectButton}
                    >
                        Reject
                    </Button>
                </div>
            )}
        >
            <Form layout="vertical">
                <Form.Item
                    label={(
                        <span>
                            Please describe the reasons for the rejection of the Landing Page Template and how can we improve it?
                        </span>
                    )}
                    htmlFor="comment"
                >
                    <TextArea
                        id="comment"
                        placeholder='Describe the reason in more detail'
                        autoSize={{ minRows: 5, maxRows: 8 }}
                        value={comment}
                        onChange={(e) => onChangeFieldValue(e.target.value)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default RejectionModal;
