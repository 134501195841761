import React, { FC, useCallback, useState, useMemo } from 'react';
import { Button, Modal, Spin, } from 'antd';
import { useBreakpoints } from "@hooks/browser";
import { PartnerCredential } from "@store/type-partner/credentials/models.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { CredentialsIcons } from "@pages/EventRolePages/AdAccountsPage/constants.tsx";

type Props = {
    isOpen: boolean
    isCredentialsLoading: boolean
    onClose: () => void
    credentials: PartnerCredential[]
}

const AddAccountModal: FC<Props> = ({ isOpen, onClose, isCredentialsLoading, credentials }) => {
    const isDesktop = useBreakpoints().md;

    const [selectedCredential, setSelectedCredential] = useState<PartnerCredential | null>(null)

    const handleClose = useCallback(() => {
        setSelectedCredential(null)
        onClose()
    }, [onClose])

    return (
        <Modal
            title='Add account'
            open={isOpen}
            onCancel={handleClose}
            centered
            width={isDesktop ? 440 : 'calc(100vw - 40px)'}
            maskClosable={false}
            className="add-account-modal"
            footer={(
                <div
                    className="flex justify-end gap-10"
                >
                    <Button
                        size="large"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        disabled={!selectedCredential}
                        onClick={() => {
                            if (selectedCredential?.url) {
                                window.location.href = selectedCredential.url;
                            }
                        }}
                    >
                        Add account
                    </Button>
                </div>
            )}
        >
            <div>
                <div className='ad-accounts-page__header-title ad-accounts-page__select-title'>
                    To add Ad Accounts, please select the required social platform
                </div>
                {isCredentialsLoading ? (
                    <div className='flex flex-center ad-accounts-page__select-spin'>
                        <Spin size="small" />
                    </div>
                ) : (
                    <div>
                        <div className='add-account-modal__wrapper custom-scroll'>
                            {credentials?.map((item) => {
                                const { translate, id, slug } = item
                                return (
                                    <div
                                        className={`ad-accounts-page__credential-item ${id === selectedCredential?.id ? 'ad-accounts-page__credential-item__selected' : ''}`}
                                        key={id}
                                        onClick={() => setSelectedCredential(item)}
                                    >
                                        {CredentialsIcons[slug]}
                                        <div>{translate}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default AddAccountModal;
