import React, { useState } from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './styles.scss';

interface CarouselItem {
    id: number;
    src: string;
}

const carouselData: CarouselItem[] = [
    { id: 1, src: 'https://your-backend-server.com/html1' },
    { id: 2, src: 'https://your-backend-server.com/html2' },
    { id: 3, src: 'https://your-backend-server.com/html3' },
    { id: 4, src: 'https://your-backend-server.com/html4' },
    { id: 5, src: 'https://your-backend-server.com/html5' },
];

const CreativesCarousel: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleCarouselChange = (current: number) => {
        setCurrentIndex(current);
    };

    return (
        <div className="creatives-carousel-container">
            <Carousel
                afterChange={handleCarouselChange}
                arrows={true}
                prevArrow={<LeftOutlined />}
                nextArrow={<RightOutlined />}
                infinite={false}
            >
                {carouselData.map((item, index) => (
                    <div key={item.id} className="carousel-slide">
                        <iframe
                            src={item.src}
                            title={`iframe-${item.id}`}
                            width="250"
                            height="250"
                            style={{ display: 'block', margin: '0 auto', border: 'none' }}
                        />
                        {/* {index === currentIndex && (
                            <div className="approved-overlay">
                                <CheckCircleOutlined style={{ fontSize: '64px', color: '#52c41a' }} />
                                <p>Approved</p>
                            </div>
                        )} */}
                    </div>
                ))}
            </Carousel>

            {/* Thumbnails Section */}
            <div className="thumbnails-container">
                {carouselData.map((item, index) => (
                    <div
                        key={item.id}
                        className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    >
                        <iframe
                            src={item.src}
                            title={`thumbnail-iframe-${item.id}`}
                            width="50"
                            height="50"
                            style={{ opacity: index === currentIndex ? 1 : 0.5, border: 'none' }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CreativesCarousel;
