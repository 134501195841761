const TourComponentArrow = () => {
    return (
        <svg width="16" height="7" viewBox="0 0 16 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.928909 -0.0354233L6.58576 5.62143C7.36681 6.40248 8.63314 6.40248 9.41419 5.62143L15.071 -0.0354233L0.928909 -0.0354233Z"
                fill="#303953"
            />
        </svg>
    )
}

export default TourComponentArrow
