import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrganizerEventByIdQuery, usePatchOrganizerEventByIdMutation, usePatchOrganizerEventStatusByIdMutation } from "@store/type-event/events/events.api.ts";
import './styles.scss';
import HeaderBreadcrumb from "@components/Header/HeaderBreadcrumb.tsx";
import EventsPageLayout from '@layouts/EventsPageLayout';
import { useBreakpoints } from '@hooks/browser';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Menu, message, Modal, Skeleton, Spin, Typography } from 'antd';
import EventCampaigns from './campaigns';
import EventPartners from './partners';
import EventPartnersMobile from './partners/mobile';
import EventBilling from './billing';
import EventAccounts from './ad-accounts';
import EventSubdomain from './subdomain';
import SetupProgressMenu from '../components/SetupProgressMenu';
import EventTrack from './track';
import EventAudienceCatalog from './audience-catalog';
import CreativeTemplates from './creative-templates';
import EventInfoForm from '@components/EventInfoForm';
import { CreateOrganizerEventRequestBody, OrganizerEventDataFullItem, OrganizerEventDataItem } from '@store/type-event/events/models';
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';
import { EventFormErrors } from '@contexts/forms/event/models';
import { Footer } from '../components/Footer';
import StatusDescription from '@components/StatusDescription';
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';
import sendAnalytics from '@hooks/sendAnalytics/sendAnalytics';
import { GTMEventName } from '@hooks/sendAnalytics/constants';
import { EventEditorContext } from '@contexts/forms/event/EventEditorContext';
import LandingPageTemplates from "@pages/EventRolePages/EventPage/setup/landing-page-templates";
import EventInvitations from './invitations';
import EventInvitationsMobile from './invitations/mobile';
import { HELP_CENTER_LINK } from '@shared/constants';
import WARNING_IMAGE from "@assets/png/warning.png";
import ThreeDotsVButton from '@assets/ThreeDotsVButton';

type MenuItem = Required<MenuProps>['items'][number];



const EventPage = () => {
    const isDesktop = useBreakpoints().md;
    const { eventId, tabId } = useParams();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [selectedKey, setSelectedKey] = useState<string>(tabId || 'partners'); // Initialize selectedKey
    const [isLoadingStatus, setIsLoadingStatus] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [eventData, setEventData] = useState<OrganizerEventDataFullItem | null>(null)

    const [patchEventStatus] = usePatchOrganizerEventStatusByIdMutation()
    const { data: eventRes, refetch } = useGetOrganizerEventByIdQuery(
        { eventId: eventId?.toString() || '' },
        { skip: !eventId }
    );
    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_status' })
    const { setErrors } = useContext(EventEditorContext)


    const [patchEvent] = usePatchOrganizerEventByIdMutation();

    useEffect(() => {
        if (!eventId) return;
        setLoading(true);
        refetch().finally(() => setLoading(false));
    }, [eventId, refetch]);

    // Update selected key when tabId changes
    useEffect(() => {
        if (tabId) {
            setSelectedKey(tabId);
        }
    }, [tabId]);

    const breadCrumbs = useMemo(() => [
        {
            url: '/events',
            label: 'My events',
            isZeroLevel: true,
        },
        {
            label: eventRes?.data?.name || 'Event',
        },
    ], [eventRes]);

    useEffect(() => {
        if (eventRes?.data) {
            setEventData(eventRes.data)
        }
    }, [eventRes])

    const onMenuItemClick: MenuProps['onClick'] = (e) => {
        setSelectedKey(e.key); // Update the selected key
        navigate(`/events/info/${eventId || ''}/${e.key}`); // Navigate to the clicked tab
    };

    const onChange = useCallback((value: string) => {
        navigate(`/events/info/${eventId || ''}/${value}`);
    }, [eventId, navigate]);

    const onSubmit = async (eventData: CreateOrganizerEventRequestBody) => {
        if (!eventId) {
            return;
        }
        try {
            const response = await patchEvent({ eventId, event: eventData })
            if ('error' in response) {
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                setErrors(errors as unknown as EventFormErrors)

                if (!Object.values(errors || {})?.length) {
                    const err = response as unknown as ErrorDataResponse
                    void message.open({
                        type: 'error',
                        content: err?.error?.data?.message || 'OOOPS, something is wrong',
                    });
                }
            }

            if ('data' in response) {
                void message.open({
                    type: 'success',
                    content: eventId ? 'Event Updated' : 'Event Created',
                });
            }
        } catch (error) {
            console.log('SMTH WRONG');
        }
    };

    const onUpdateStatus = async () => {
        if (!eventId) {
            return message.open({
                type: 'error',
                content: 'Event update error',
            });
        }
        setIsLoadingStatus(true)
        try {
            const statusId = statuses?.data?.find(({ slug }) => (eventRes?.data?.status.name === 'published' ? slug === 'draft' : slug === 'published'))?.id
            if (!statusId) {
                return message.open({
                    type: 'error',
                    content: 'Event update error',
                });
            }
            const response = await patchEventStatus({ eventId: eventId.toString(), status: statusId })
            if ('data' in response) {
                sendAnalytics(GTMEventName.publishEvent)
                // eslint-disable-next-line @typescript-eslint/await-thenable
                await refetch()
                setIsLoadingStatus(false)
                return message.open({
                    type: 'success',
                    content: `Event is ${eventRes?.data?.status.name === 'published' ? 'unpublished' : 'published'} successfuly`,
                });
            }
            if ('error' in response) {
                setIsLoadingStatus(false)
                return message.open({
                    type: 'error',
                    content: 'Event update error',
                });
            }
        } catch (error) {
            setIsLoadingStatus(false)
            return message.open({
                type: 'error',
                content: 'Event Update error',
            });
        } finally {
            setIsLoadingStatus(false)
            setIsModalOpen(false)
            setIsWarningModalOpen(false)
        }
    }

    // Define the type for handlePreview
    const handlePreview = () => {
        if (!eventId) {
            return;
        }
        navigate(`/events/info/${eventId}/preview`);
    };

    const items: MenuItem[] = [

        {
            key: 'partners',
            label: 'Sponsors',
        },

        {
            key: 'campaigns',
            label: 'Campaigns',
        },
        {
            key: 'moderation',
            label: 'Moderation',
            disabled: eventRes?.data?.status.name !== 'published',
        },
        {
            type: 'divider',
        },
        {
            key: 'invitations',
            label: 'Invitations',
        },
    ];

    if (isLoading) {
        return (
            <EventsPageLayout>
                <Skeleton active />
            </EventsPageLayout>
        )
    }

    const ctaRender = () => {
        const statusActionsSlugs = eventRes?.data?.status?.actions?.map(({ slug }) => slug)

        if (statusActionsSlugs?.includes('publish')) {
            return (
                <Button
                    type='primary'
                    loading={isLoadingStatus}
                    onClick={() => void onUpdateStatus()}
                >
                    Publish
                </Button>
            )
        }
        if (statusActionsSlugs?.includes('draft') &&
            eventRes?.data?.status.name === 'published'
        ) {
            return (
                <Dropdown
                    className="dropdown"
                    menu={{
                        items: [
                            {
                                label: 'Unpublish',
                                key: '1',
                                onClick: () => {
                                    if (!!eventRes?.data?.campaigns && !!eventRes?.data?.landings) {
                                        setIsWarningModalOpen(true)
                                    } else {
                                        setIsModalOpen(true)
                                    }
                                },
                            }
                        ]
                    }}
                    trigger={['click']}
                    placement="bottomRight"
                >
                    <Button icon={<ThreeDotsVButton />} />
                </Dropdown>
            )
        }
        else {
            return (
                <Button
                    type='primary'
                    loading={isLoadingStatus}
                    // onClick={() => void onUpdateStatus()}
                    disabled
                >
                    Publish
                </Button>

            )
        }
    }

    // Return content based on the selected tab key
    const contentRender = () => {
        switch (selectedKey) {
            case 'general':
                return (
                    <EventInfoForm
                        Footer={Footer}
                        Header={<Typography.Title level={4} className='m-0'>General</Typography.Title>}
                        fetchedData={eventData!}
                        onSubmit={data => void onSubmit(data)}
                        onPreview={handlePreview} // Add the onPreview prop here
                    />
                )
            case 'partners':
                return (
                    isDesktop
                        ?
                        <div className='steps-extra-padding'><EventPartners onChangeTab={onChange} /></div  >
                        :
                        <EventPartnersMobile onChangeTab={onChange} />
                );


            case 'invitations':
                return (
                    isDesktop ?
                        <div className='steps-extra-padding'><EventInvitations onChangeTab={onChange} /></div>
                        :
                        <EventInvitationsMobile onChangeTab={onChange} />
                );

            case 'campaigns':
                return <div className='steps-extra-padding'><EventCampaigns /></div>;
            //Steps to publication
            case 'subdomain':
                return <EventSubdomain />;
            case 'ad-accounts':
                return <EventAccounts />;
            case 'audience':
                return <EventAudienceCatalog />;

            case 'landing-page':
                return <LandingPageTemplates />;
            case 'creative_templates':
                return <CreativeTemplates />

            case 'track':
                return <EventTrack />;
            case 'billing':
                return <EventBilling />;
            case 'moderation':
                navigate(`/moderation?eventId=${eventId!}`);
                return null;
            default:
                return <h1>Default Content</h1>;
        }
    };

    return (
        <EventsPageLayout
            customHeaderActions={
                <div className='flex items-center gap-20'>
                    <div style={{ width: '100%' }}>
                        <StatusDescription
                            status={eventRes?.data?.status.name || 'draft'}
                            size='small'
                        />
                    </div>
                    {ctaRender()}
                </div>}
        >
            <>
                {isDesktop && <HeaderBreadcrumb crumbs={breadCrumbs} />}
                <div className='event-role-event-page'>
                    <div className='event-role-event-page__menu'>
                        {eventRes?.data
                            ?
                            <SetupProgressMenu
                                onChange={onChange}
                                selectedKey={selectedKey}
                                event={eventRes.data}
                            />
                            :
                            null
                        }

                        <Menu
                            onClick={onMenuItemClick}
                            style={{
                                width: 256,
                                borderRight: '0px solid trasparent',
                            }}
                            mode="inline"
                            items={items}
                            selectedKeys={[selectedKey]} // Set the selected key for the controlled Menu
                        />
                    </div>

                    <div className="event-role-event-page__content">
                        {contentRender()}
                    </div>
                </div>

                {/* MODALS */}
                <Modal
                    title="Unpublish the event"
                    open={isModalOpen}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onOk={onUpdateStatus}
                    onCancel={() => setIsModalOpen(false)}
                    okButtonProps={{
                        loading: isLoadingStatus
                    }}
                    okText="Unpublish"
                    centered
                    width={440}
                    footer={[
                        <div className='flex justify-end gap-10 mt-20'>
                            <Button
                                key="cancel"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                key="unpublish"
                                type='primary'
                                loading={isLoadingStatus}
                                onClick={() => void onUpdateStatus()}
                            >
                                Unpublish
                            </Button>
                        </div>
                    ]}
                >
                    <span className='pt-4'>
                        Are you sure you want to unpublish the event?
                    </span>
                </Modal>

                <Modal
                    title=""
                    open={isWarningModalOpen}
                    onCancel={() => setIsWarningModalOpen(false)}
                    width={440}
                    centered
                    footer={[
                        <div className='flex justify-end gap-10 mt-20'>
                            <Button
                                key="cancel"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                key="unpublish"
                                type='primary'
                                loading={isLoadingStatus}
                                onClick={() => void onUpdateStatus()}
                            >
                                Unpublish
                            </Button>
                        </div>
                    ]}
                >
                    <div className='text-center'>
                        <img src={WARNING_IMAGE} alt="Warning" />
                        <p className='text-modal-title-18'>Unpublish the event</p>
                        <div >
                            <span className='text-modal-description-14 mb-10'>
                                Unpublishing the event will stop all ad campaigns <br /> and take  all partner landing pages off the
                                publication.
                            </span>
                            <div className='mb-10' />
                            <div className="text-modal-description-14">
                                To proceed, please
                                {' '}
                                <Link
                                    className='link-style'
                                    to={HELP_CENTER_LINK}
                                    target="_blank"
                                >
                                    contact our support team
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        </EventsPageLayout>
    );
};

export default EventPage;
