import React from "react";
import EventBillingForm from "@pages/EventRolePages/Billing/components/EventBillingForm.tsx";
import useEventBillingForm from "@pages/EventRolePages/Billing/useEventBillingForm.tsx";
import TotalNumbers from "@pages/EventRolePages/Billing/components/TotalNumbers.tsx";
import { Spin, Button } from "antd";

const BillingDetails = () => {
    const {
        form,
        validationErrors,
        onFormChange,
        isLoading,
        onBillingInfoUpdate,
        isBillingLoading,
        isFormChanged
    } = useEventBillingForm()

    return (
        <>
            <TotalNumbers />
            {(isBillingLoading || typeof form?.name !== "string") ? (
                <div className='flex flex-center ad-accounts-page__select-spin'>
                    <Spin size="small" />
                </div>
            ) : (
                <>
                    <div className="organizer-billing-page__billing-details">
                        <EventBillingForm
                            form={form}
                            validationErrors={validationErrors}
                            onFormChange={onFormChange}
                        />
                    </div>
                    <div className='organizer-billing-page__billing-details__footer'>
                        <Button
                            size='large'
                            type='primary'
                            loading={isLoading}
                            disabled={!isFormChanged}
                            onClick={onBillingInfoUpdate}
                        >
                            Save
                        </Button>
                    </div>
                </>
            )}
        </>
    )
}

export default BillingDetails
