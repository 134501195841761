import EventsPageLayout from '@layouts/EventsPageLayout'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { DatePicker, Spin, Badge, Select } from 'antd';
import dayjs from "dayjs";
import ContentLayout from "@layouts/ContentLayout.tsx";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import AnalyticsTable from "@components/Analytics/components/AnalyticsTable.tsx";
import "@components/Analytics/styles.scss";
import { Filters, StatisticsMetrics } from "@shared/statisticTypes.ts";
import { initialFilters } from "@components/Analytics/constants.tsx";
import { getResponsiveLineData } from "@components/Analytics/helpers.ts";
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import '../styles.scss'
import { GAP_INIT } from "@shared/constants.ts";
import CustomResponsiveLineMobile from '@components/Analytics/components/CustomResponsiveLineMobile';
import MobileDrawer from '@components/mobile/Drawer';
import FilterButtonMobileIcon from '@assets/buttons/mobile/FilterButtonMobileIcon';
import { getCurrencySymbol } from "@shared/utils.ts";
import { useGetPartnerEventQuery } from "@store/type-partner/events/partner-event.api.ts";
import { useGetPartnerLandingsByEventIdQuery } from "@store/type-partner/landings/landings.api.ts";
import { useGetPartnerCampaignsQuery } from "@store/type-partner/campaigns/campaigns.api.ts";
import {
    useGetPartnerStatisticsMetricsQuery,
    useGetPartnerStatisticsQuery
} from "@store/type-partner/statistics/statistics.api.ts";

const AnalyticsPageMobile = () => {
    const [statisticsLeftValue, setStatisticsLeftValue] = useState<StatisticsMetrics[]>([])
    const [statisticsRightValue, setStatisticsRightValue] = useState<StatisticsMetrics[]>([])

    const [eventsSearchValue, setEventsSearchValue] = useState<string>()
    const [landingsPage, setLandingsPage] = useState(INIT_PAGE);
    const [eventsPage, setEventsPage] = useState(INIT_PAGE);
    const [campaignsPage, setCampaignsPage] = useState(INIT_PAGE);
    const [currency, setCurrency] = useState<string>()
    const [campaignsSearchValue, setCampaignsSearchValue] = useState<string>()

    const [isShowFilters, setShowFilters] = useState(false)
    const [drawerFilters, setDrawerFilters] = useState<Partial<Filters>>(initialFilters)

    const { data: eventsRes, isLoading: isEventsLoading, isFetching: isEventsFetching } = useGetPartnerEventQuery({ filters: { search: eventsSearchValue, page: eventsPage } }, { refetchOnMountOrArgChange: true })
    const { data: landingsRes, isLoading: isLandingsLoading, isFetching: isLandingsFetching } = useGetPartnerLandingsByEventIdQuery({ id: Number(drawerFilters?.events?.[0]), page: landingsPage }, { skip: !drawerFilters?.events?.[0] })
    const { data: campaignsRes, isLoading: isCampaignsLoading, isFetching: isCampaignsFetching } = useGetPartnerCampaignsQuery({ filter: { events: drawerFilters?.events, search: campaignsSearchValue, page: campaignsPage } }, { skip: !drawerFilters?.events?.length, refetchOnMountOrArgChange: true })
    const { data: statisticsMetrics } = useGetPartnerStatisticsMetricsQuery()
    const { data: statistics, isLoading: isStatisticsLoading, isFetching: isStatisticsFetching } = useGetPartnerStatisticsQuery(drawerFilters, { skip: !drawerFilters?.dateFrom, refetchOnMountOrArgChange: true })

    const currenciesOptions = useMemo(() => {
        return statistics?.data?.sums?.map(({ currency: currencyValue }) => {
            return ({ value: currencyValue || '', label: `${getCurrencySymbol(currencyValue)} ${currencyValue ? currencyValue.toUpperCase() : ''}` })
        }) || []
    }, [statistics])

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (currenciesOptions && currenciesOptions[0]?.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            setCurrency(currenciesOptions[0].value)
        }
    }, [currenciesOptions]);

    useEffect(() => {
        const analyticsSideLeftValue = localStorage.getItem(`analytics_side_value_partner_left`);
        const analyticsSideRightValue = localStorage.getItem(`analytics_side_value_partner_right`);
        if (analyticsSideLeftValue) {
            setStatisticsLeftValue((JSON.parse(analyticsSideLeftValue) || []) as StatisticsMetrics[])
        }
        if (analyticsSideRightValue) {
            setStatisticsRightValue((JSON.parse(analyticsSideRightValue) || []) as StatisticsMetrics[])
        }
        if (!analyticsSideLeftValue) {
            const initialValue = statisticsMetrics?.data?.[0] ? [{
                ...(statisticsMetrics?.data?.[0] || {}),
                value: statisticsMetrics?.data?.[0]?.metric
            }] : []
            setStatisticsLeftValue(initialValue)
        }
    }, [statisticsMetrics?.data]);

    const setInitialFilters = useCallback(() => {
        const today = dayjs();
        const thirtyDaysAgo = dayjs().subtract(30, 'day');
        const todayFormatted = today.format('YYYY-MM-DD');
        const thirtyDaysAgoFormatted = thirtyDaysAgo.format('YYYY-MM-DD');
        setDrawerFilters({
            ...initialFilters,
            dateFrom: thirtyDaysAgoFormatted,
            dateTo: todayFormatted
        })
    }, [])

    useEffect(() => {
        setInitialFilters()
    }, []);

    const statisticsDates = useMemo(() => {
        return statistics?.data?.dates || []
    }, [statistics])

    const { leftData, rightData, leftMax, rightMax, leftMin, rightMin } = useMemo(() => getResponsiveLineData({ statisticsLeftValue, statisticsRightValue, statisticsDates, currency, maxLength: 18 }), [statisticsDates, statisticsLeftValue, statisticsRightValue, currency])

    const handleChangeDrawerFilter = (filters: Partial<Filters>) => {
        const next = { ...drawerFilters, ...filters }
        setDrawerFilters(next)
    }

    const tickRotation = useMemo(() => ([...leftData, ...rightData]?.[0]?.data?.length > 5 ? -45 : 0), [leftData, rightData])

    const onDrawerResetClick = () => {
        setDrawerFilters(initialFilters)
        setInitialFilters()
        setShowFilters(false)
    }

    const isFilterApplied = !!(
        (drawerFilters?.landings && drawerFilters.landings.length > 0) ||
        (drawerFilters?.campaigns && drawerFilters.campaigns.length > 0) ||
        (drawerFilters?.events && drawerFilters.events.length > 0) ||
        drawerFilters?.dateFrom ||
        drawerFilters?.dateTo ||
        (drawerFilters?.gap && drawerFilters.gap !== '1')
    );

    const disabledStartDate = (current: dayjs.Dayjs) => {
        return !!drawerFilters.dateTo && current > dayjs(drawerFilters.dateTo);
    };

    const disabledEndDate = (current: dayjs.Dayjs) => {
        return !!drawerFilters.dateFrom && current < dayjs(drawerFilters.dateFrom);
    };

    const onApplyFilters = () => {
        setShowFilters(false)
    }

    return (
        <EventsPageLayout className="analytics-page-mobile-layout">
            <ContentLayout>
                <div className='flex items-center justify-space-between'>
                    <h3>
                        Analytics
                    </h3>
                    <button
                        className='no-style'
                        onClick={() => setShowFilters(true)}
                    >
                        <Badge
                            dot={!!isFilterApplied}
                            status="processing"

                        >
                            <FilterButtonMobileIcon
                                isActive={isFilterApplied}
                            />
                        </Badge>
                    </button>

                </div>

                <div className="mb-24">
                    <AnalyticsTable
                        data={statistics?.data?.sums}
                        isLoading={isStatisticsLoading || isStatisticsFetching}
                    />
                </div>

                <CustomResponsiveLineMobile
                    leftData={leftData}
                    rightData={rightData}
                    leftMax={leftMax}
                    rightMax={rightMax}
                    leftMin={leftMin}
                    rightMin={rightMin}
                    tickRotation={tickRotation}
                    statisticsRightValue={statisticsRightValue}
                    statisticsLeftValue={statisticsLeftValue}
                    setStatisticsRightValue={setStatisticsRightValue}
                    setStatisticsLeftValue={setStatisticsLeftValue}
                    statisticsMetrics={statisticsMetrics}
                    gap={drawerFilters?.gap || GAP_INIT}
                    handleGapChange={(gap) => handleChangeDrawerFilter({ gap })}
                // currenciesOptions={currenciesOptions}
                // currency={currency}
                // setCurrency={setCurrency}
                />
            </ContentLayout>

            <MobileDrawer
                title='Filters'
                onClose={onDrawerResetClick}
                onCancel={onDrawerResetClick}
                isOpen={isShowFilters}
                onOk={onApplyFilters}
                applyButtonText="Apply"
                resetButtonText="Reset"
            >
                <div className='drawer-content w-full'>
                    <p>Currency</p>
                    <Select
                        disabled={!currenciesOptions.length}
                        style={{ width: '100%' }}
                        options={currenciesOptions}
                        value={currency}
                        onChange={setCurrency}
                        popupClassName='analytics__popup-select'
                        placeholder='Select currency'
                    />

                    <p>
                        Event
                    </p>
                    <InfiniteSelect
                        id="events-page__events"
                        maxTagCount={1}
                        style={{ width: '100%' }}
                        filterOption={false}
                        notFoundContent={isEventsLoading || isEventsFetching ? <Spin size="small" /> :
                            <div>There is no events</div>}
                        isLoading={isEventsLoading || isEventsFetching}
                        listHeight={160}
                        onChange={value => {
                            setCampaignsPage(INIT_PAGE)
                            setLandingsPage(INIT_PAGE)
                            handleChangeDrawerFilter({ events: [value] })
                        }}
                        page={eventsPage}
                        setPage={setEventsPage}
                        data={eventsRes}
                        value={drawerFilters?.events?.length ? drawerFilters.events : []}
                        allowClear
                        showSearch
                        setSearchValue={(val) => {
                            setEventsSearchValue(val)
                        }}
                        optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                        classNameProp="analytics-page__infinite-select__event"
                    />


                    <p>
                        Campaigns
                    </p>
                    <InfiniteSelect
                        id="events-page__partners"
                        disabled={!drawerFilters?.events?.length}
                        style={{ width: '100%' }}
                        maxTagCount="responsive"
                        mode="multiple"
                        filterOption={false}
                        notFoundContent={isCampaignsLoading || isCampaignsFetching ? <Spin size="small" /> :
                            <div>There is no campaigns for selected event</div>}
                        isLoading={isCampaignsLoading || isCampaignsFetching}
                        value={drawerFilters.campaigns || []}
                        onChange={(value: string[]) => {
                            setLandingsPage(INIT_PAGE)
                            setCampaignsPage(INIT_PAGE)
                            handleChangeDrawerFilter({ campaigns: value })
                        }}
                        optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                        data={campaignsRes}
                        listHeight={130}
                        page={campaignsPage}
                        setPage={setCampaignsPage}
                        allowClear
                        showSearch
                        setSearchValue={setCampaignsSearchValue}
                        classNameProp="analytics-page__infinite-select__sponsor"
                    />

                    <p>
                        Landings
                    </p>
                    <InfiniteSelect
                        id="events-page__landings"
                        disabled={!drawerFilters?.events?.length}
                        style={{ width: '100%' }}
                        maxTagCount="responsive"
                        mode="multiple"
                        allowClear
                        filterOption={false}
                        notFoundContent={isLandingsLoading || isLandingsFetching ? <Spin size="small" /> : <div>There is no landings for selected event</div>}
                        isLoading={isLandingsLoading || isLandingsFetching}
                        value={drawerFilters?.landings || []}
                        onChange={(value: string[]) => {
                            setCampaignsPage(INIT_PAGE)
                            handleChangeDrawerFilter({ landings: value })
                        }}
                        optionsMapper={({ id, title }) => ({ value: id.toString(), label: title })}
                        data={landingsRes}
                        listHeight={160}
                        page={landingsPage}
                        setPage={setLandingsPage}
                        classNameProp="analytics-page__infinite-select__landing"
                    />

                    <p>
                        Date Range
                    </p>
                    <div className='flex'>
                        <DatePicker
                            format="YYYY-MM-DD"
                            value={drawerFilters?.dateFrom ? dayjs(drawerFilters.dateFrom) : null}
                            className='campaign-create__budget-form-date'
                            onChange={(_, value) => handleChangeDrawerFilter({ dateFrom: value as string })}
                            placeholder='Start Date'
                            allowClear
                            disabledDate={disabledStartDate}
                        />
                        <DatePicker
                            format="YYYY-MM-DD"
                            value={drawerFilters?.dateTo ? dayjs(drawerFilters.dateTo) : null}
                            className='campaign-create__budget-form-date'
                            onChange={(_, value) => handleChangeDrawerFilter({ dateTo: value as string })}
                            placeholder='Finish Date'
                            allowClear
                            disabledDate={disabledEndDate}
                        />
                    </div>

                </div>
            </MobileDrawer>
        </EventsPageLayout>
    )
}

export default AnalyticsPageMobile
