const AIIcon = () => {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="72" height="72" rx="36" fill="#7749D9" fillOpacity="0.45" />
            <path
                d="M40.4647 35.5745L26.2998 49.7395C24.8197 51.2195 22.4201 51.2195 20.94 49.7395C19.46 48.2594 19.46 45.8598 20.9401 44.3797L35.105 30.2148M40.4647 35.5745L43.1446 32.8946C44.6246 31.4146 44.6246 29.015 43.1446 27.5349C41.6645 26.0549 39.2649 26.0549 37.7849 27.5349L35.105 30.2148M40.4647 35.5745L35.105 30.2148M47.2081 45.5533L44.501 45.5533M44.501 45.5533H41.7939M44.501 45.5533V48.2604M44.501 45.5533L44.501 42.8462M51.3454 21.5566L48.6384 21.5566M48.6384 21.5566L45.9313 21.5566M48.6384 21.5566V24.2636M48.6384 21.5566V18.8495M29.0068 25.4693H26.2998M26.2998 25.4693L23.5927 25.4693M26.2998 25.4693L26.2998 28.1763M26.2998 25.4693V22.7622"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default AIIcon
