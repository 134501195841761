import React, { useContext, useEffect, useState } from "react";
import LogoHeaderAuth from "@assets/LogoHeaderAuth.tsx";
import {
    useGetInviteByEventHashQuery,
    useLazyGetInviteByEventHashQuery,
    usePatchUserProfileMutation,
} from "@store/main/-user/user.api";
import { useAuth } from "@contexts/AuthContext.tsx";
import PartnerOnboardingLayout from '@layouts/PartnerOnboardingLayout';
import PartnerInvitationCodeForm from './steps/invitation-code';
import { Button, Steps, message } from 'antd';
import BillingInfo from './steps/billing-info';
import PartnerOnboardingErrorView from './steps/invitation-code/error-page';
import { ErrorDataResponse, errorsToFormAdapter, getCookie } from '@shared/utils';
import { EventByHashInfo, InviteInfoResponse } from '@store/models-to replace/auth';
import LoadingView from '@components/common/LoadingView';
import { FormData } from './models';
import { initialErrorForm, initialForm, steps } from './constants';
import { BillingErrorsValues } from '@components/BillingForm/models';
import { StripeContext } from '@contexts/StripeContext';

import { PatchBillingInfoBodyRequest } from '@store/main/billing/models';
import { usePatchBillingInfoMutation } from '@store/main/billing/billing.api';
import { useLazyGetOpenStripeQuery } from '@store/main/payment/payment.api';
import { Intent } from '@store/main/payment/models';
import PaymentMethodStep from './steps/payment-method';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { userActions } from "@store/main/-user/user.slice";
import { useDispatch } from "react-redux";
import { useBreakpoints } from '@hooks/browser';
import LogoutDropdown from "@pages/CommonPages/Authentification/Register/components/LogoutDropdown/LogoutDropdown.tsx";
import { eventsWithTour } from "@pages/routes.ts";

const PartnerOnboarding: React.FC = () => {
    const isDesktop = useBreakpoints().md;

    const eventHashFromCookie: string | null = getCookie('event_hash');
    const event_hash = eventHashFromCookie

    const [eventInfo, setEventInfo] = useState<InviteInfoResponse>()
    const [inviteHash, setInviteHash] = useState('')
    const [apiKey, setApiKey] = useState('');
    const [intent, setIntent] = useState<Intent | null>(null);
    const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState<boolean>(false);
    const {
        data: inviteInfo,
        isLoading: isInviteLoading,
    } = useGetInviteByEventHashQuery(inviteHash, { skip: !inviteHash });

    const [onOpenForm] = useLazyGetOpenStripeQuery()

    const onOpenStripeForm = async () => {
        setIsPaymentMethodLoading(true);

        try {
            const response = await onOpenForm();
            if (response?.data?.data?.intent) {
                setIsPaymentMethodLoading(false);
                setIntent(response.data.data.intent);
                setApiKey(response.data.data.api_key)
            }
        } catch (error) {
            console.error('rejected', error);
        } finally {
            setIsPaymentMethodLoading(false);
        }
    };

    useEffect(() => {
        if (inviteInfo?.data.expired_at) {
            const expiredAt = new Date(inviteInfo.data.expired_at)
            const currentDate = new Date()
            if (currentDate > expiredAt) {
                location.replace('/invite/not-valid')
                return message.error('The event code is expired');
            }
        }
        if (inviteInfo?.data) {
            setEventInfo(inviteInfo)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inviteInfo?.data])

    // const navigate = useNavigate();
    const { user } = useAuth();
    const dispatch = useDispatch()

    const [runUpdateUserRequest] = usePatchUserProfileMutation();
    const [patchBillingInfo] = usePatchBillingInfoMutation()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [getInviteInfoByCode] = useLazyGetInviteByEventHashQuery();

    const [isLoading, setLoading] = useState(false)
    const [isCodeLoading, setCodeLoading] = useState(false)
    const [fetchedEventInfo, setFetchedEventInfo] = useState<EventByHashInfo>()
    const [isSkipPayment, setIsSkipPayment] = useState(false)
    const [step, setStep] = useState(0)

    const [form, setForm] = useState<FormData>(initialForm);
    const [validationErrors, setValidationErrors] = useState<BillingErrorsValues>(initialErrorForm);

    const [codeIsValid, setCodeIsValid] = useState(false)

    const { onAddPaymentClick } = useContext(StripeContext)

    // FIRST STEP - INVITATION CODE
    const updateCode = async () => {
        setCodeLoading(true)

        try {
            const { event_hash } = form

            const response = await getInviteInfoByCode(event_hash)
            if ('data' in response) {
                sendAnalytics(GTMEventName.partnerOnboardingFirstStep)
                setCodeLoading(false)
                setCodeIsValid(true)
                setFetchedEventInfo(response.data?.data.event)
                setStep(1)
            }
            if ('error' in response) {
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                const msg = errors?.event_hash || 'The event code is invalid'
                setValidationErrors({ ...validationErrors, event_hash: 'The event code is invalid' })
                setCodeLoading(false)
                return message.error(msg);
            }
        } catch (error) {
            setCodeLoading(false)
            setValidationErrors({ ...validationErrors, event_hash: 'The event code is invalid' })
            return message.error('The event code is invalid');
        }
    }

    useEffect(() => {
        if (!intent) {
            void onOpenStripeForm()
        }
    }, [])

    // SECOND STEP - BILLING INFO
    const updateBillingData = async () => {
        setLoading(true);
        try {
            const { event_hash, ...rest } = form;
            const response = await patchBillingInfo(rest as PatchBillingInfoBodyRequest);
            if ('data' in response) {
                // if (!intent) {
                //     await onOpenStripeForm()
                // }
                // optional - if user entered by invitation link and inits from second step
                // is needed to get event info, especialy the event id to redirect user to Event Editor
                if (!fetchedEventInfo?.id) {
                    const eventResponse = await getInviteInfoByCode(event_hash)
                    if ('data' in eventResponse) {
                        setFetchedEventInfo(eventResponse.data?.data.event)
                    }
                }
                setStep(2)
            }
            if ('error' in response) {
                const status = (response as ErrorDataResponse)?.error?.status as unknown as number
                if (status === 500) {
                    return message.error('Stripe data update error');
                }
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                return setValidationErrors(errors as unknown as BillingErrorsValues);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            setLoading(false);
        }
    };

    // LAST STEP - PAYMENT METHODS
    const onUpdatePaymentMethod = async () => {
        setLoading(true)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        await onAddPaymentClick()
            .then(async () => {
                // patch to update the onboarding state to 'ready'
                await runUpdateUserRequest({
                    organization_type: user?.organization?.type || 'none',
                    event_hash: event_hash || form.event_hash,
                    site: user?.organization?.site || ''
                })
                    .then((res) => {
                        sendAnalytics(GTMEventName.partnerOnboardingThirdStep)
                        if ('data' in res) {
                            void message.success('Billing info updated successfully')
                            dispatch(userActions.setUserState(res?.data?.data))
                            dispatch(userActions.setUserError(''))
                            location.replace(eventsWithTour);
                            localStorage.removeItem('event_hash');
                        }
                    })
                    .catch(() => {
                        setLoading(false)
                        return message.error('Update user info error');
                    })
            })
            .catch(() => {
                setLoading(false)
            })
            .finally(() => setLoading(false))
    }

    const onSkipPayment = async () => {
        setIsSkipPayment(true)
        await runUpdateUserRequest({
            organization_type: user?.organization?.type || 'none',
            event_hash: event_hash || form.event_hash,
            site: user?.organization?.site || ''
        })
            .then((res) => {
                sendAnalytics(GTMEventName.partnerOnboardingThirdStep)
                if ('data' in res) {
                    void message.success('Billing info updated successfully')
                    dispatch(userActions.setUserState(res?.data?.data))
                    dispatch(userActions.setUserError(''))
                    localStorage.removeItem('event_hash');
                    setIsSkipPayment(false)
                    return location.replace(eventsWithTour);
                }
            })
            .catch(() => {
                setIsSkipPayment(false)
                return message.error('Update user info error');
            })
    }



    useEffect(() => {
        if (user) {
            const billing = user?.organization?.billing
            setForm(prev => (
                {
                    ...prev,
                    event_hash: event_hash || '',
                    name: billing?.name || user?.name || '',
                    company_name: billing?.company_name || user?.organization?.name || '',
                    city: billing?.city || null,
                    postal_code: billing?.postal_code || '',
                    email: billing?.email || user?.email || '',
                    country: billing?.country || null,
                    address: billing?.address || '',
                    tax_code: billing?.tax_code || ''
                }
            )
            )
        }
    }, [user])

    useEffect(() => {
        if (event_hash) {
            setInviteHash(event_hash)
            setForm(prev => (
                {
                    ...prev,
                    event_hash: event_hash,
                }
            )
            )
            setStep(1)
        }
    }, [event_hash])


    const onFieldChange = (field: Partial<FormData>): void => {
        setForm((prev) => ({ ...prev, ...field }))
        setValidationErrors(initialErrorForm)
    }

    const onPrev = () => {
        if (step === 0) {
            void updateCode()
            return null
        }
        setStep(prev => prev - 1)
    }


    const onNext = () => {
        switch (step) {
            case 0:
                return void updateCode()
            case 1:
                return updateBillingData()
            case 2:
                return onUpdatePaymentMethod()
            default:
                break;
        }
    }

    const onResetHashError = () => {
        setValidationErrors(initialErrorForm)
        if (user) {
            const billing = user?.organization?.billing
            setForm(prev => (
                {
                    ...prev,
                    event_hash: '',
                    name: billing?.name || user?.name || '',
                    company_name: billing?.company_name || user?.organization?.name || '',
                    city: billing?.city || null,
                    postal_code: billing?.postal_code || '',
                    email: billing?.email || user?.email || '',
                    country: billing?.country || null,
                    address: billing?.address || '',
                    tax_code: billing?.tax_code || ''
                }
            ))
        } else {
            setForm(initialForm)
        }
    }

    if (validationErrors.event_hash && form.event_hash) {
        return (
            <>
                <PartnerOnboardingErrorView
                    message={validationErrors?.event_hash}
                    closeErrorModal={onResetHashError}
                />
            </>
        )
    }
    const eventImage = eventInfo?.data?.event?.logo || ''

    if (isInviteLoading) {
        //TODO: check styles
        return <LoadingView />
    }

    return (
        <PartnerOnboardingLayout
            headerSlot={
                <header className='flex items-center'>
                    <LogoHeaderAuth isCompleteRegistration />
                    {eventImage
                        ?
                        <div className='additional-header-info'>
                            <img src={eventImage} />
                        </div>
                        :
                        null}
                    <LogoutDropdown />
                </header>
            }
            leftSlot={
                <div className='partner-invitation'>
                    {isDesktop
                        ?
                        <Steps
                            size="small"
                            current={step}
                            items={steps}
                        />
                        :
                        null}
                    {step === 0
                        &&
                        <PartnerInvitationCodeForm
                            onFormChange={onFieldChange}
                            form={form}
                            isLoading={isCodeLoading}
                            onResetHashError={onResetHashError}
                            codeIsValid={codeIsValid}
                        />
                    }
                    {step === 1
                        &&
                        <BillingInfo
                            form={form}
                            validationErrors={validationErrors}
                            onFormChange={onFieldChange}
                        />
                    }
                    <div className={`${step === 2 ? '' : 'partner-invitation--payment-method--hidden'}`}>
                        <PaymentMethodStep
                            apiKey={apiKey}
                            intent={intent}
                        />
                    </div>

                    <footer>
                        {
                            !isDesktop && step !== 2
                                ?
                                null
                                :
                                <Button
                                    size='large'
                                    style={{ width: '140px', visibility: step !== 2 ? 'hidden' : 'visible' }}
                                    onClick={onPrev}
                                >
                                    Back
                                </Button>
                        }
                        {step === 2
                            ?
                            <div className="flex items-center gap-8">
                                <Button
                                    size='large'
                                    type='text'
                                    style={{ width: '57px' }}
                                    disabled={isSkipPayment}
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onClick={() => void onSkipPayment()}
                                >
                                    Skip
                                </Button>
                                <Button
                                    size='large'
                                    type='primary'
                                    style={{ width: '140px' }}
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onClick={onNext}
                                    loading={isLoading}
                                >
                                    Submit
                                </Button>
                            </div>
                            :
                            <Button
                                size='large'
                                type='primary'
                                loading={isCodeLoading || isLoading}
                                style={{ width: isDesktop ? '140px' : '100%' }}
                                disabled={step === 0 && !form.event_hash.length}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={onNext}
                            >
                                Continue
                            </Button>
                        }
                    </footer>

                </div>
            }
            rightSlot={
                <div
                    className='partner-onboarding__aside'
                >
                </div>
            }
        />
    )
}

export default PartnerOnboarding;
