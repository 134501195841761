import './styles.scss'
import { PartnerCredential } from '@store/type-partner/credentials/models'
import AdIconRender from './components/AdIconRender'

interface AccountCardProps {
    adItem: PartnerCredential;
    isSelected?: boolean
}
const AccountCard = ({
    adItem,
    isSelected
}: AccountCardProps) => {
    return (
        <div className={`add-account__card ${isSelected ? 'selected' : ''}`}>

            <AdIconRender slug={adItem.slug} />
            <div>
                <span>{adItem.translate}</span>
            </div>
        </div>

    )
}

export default AccountCard

