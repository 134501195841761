import VisaLogo from '@assets/cards/Visa';
import { UserBillingPageContext } from '@contexts/UserBillingPageContext';
import { Button, Typography } from 'antd'
import React, { useContext, useMemo, useState } from 'react';
import MasterCardLogo from '@assets/cards/MasterCard';
import ModalDeleteCard from '../components/ModalDeleteCard';
import ModalDefaultCard from '../components/ModalDefaultCard';
import PaymentMethodRow from './PaymentMethodRow';
import Loader from '@components/Loader';
import { PaymentMethodItem } from '@store/models-to replace/paymentMethods';
import { useDeleteBillingMethodIdMutation, useGetUserBillingPaymentsQuery, usePostDefaultBillingMethodIdMutation } from '@store/main/payment/payment.api';
import { useBreakpoints } from '@hooks/browser';
import { PlusOutlined } from "@ant-design/icons";

export const CardLogo = ({ brand }: { brand: string }) => {
    switch (brand) {
        case 'mastercard':
            return <MasterCardLogo />;
        case 'visa':
            return <VisaLogo />;
        default:
            return <></>;
    }
}


const PaymentMethodsSection = () => {
    const { setSectionType } = useContext(UserBillingPageContext);
    const [deleteCard] = useDeleteBillingMethodIdMutation()
    const [postDefault] = usePostDefaultBillingMethodIdMutation()
    const { data: paymentMethodsResponse, isLoading: paymentsIsLoading } = useGetUserBillingPaymentsQuery()
    const isDesktop = useBreakpoints().md

    const [paymentToDelete, setPaymentToDelete] = useState<{ last4: string, brand: string, id: string } | null>(null)
    const [defaultPayment, setDefaultPayment] = useState<{ last4: string, brand: string, id: string } | null>(null)
    const [isDeleteLoading, setDeleteLoading] = useState(false)
    const [isDefaultLoading, setDefaultLoading] = useState(false)
    const [cards, setCards] = useState<PaymentMethodItem[] | []>([])

    const onDeleteCard = async () => {
        if (!paymentToDelete?.id) {
            return null
        }
        setDeleteLoading(true)
        try {
            const response = await deleteCard({ id: paymentToDelete.id });
            if ('data' in response) {
                setDeleteLoading(false)
                setPaymentToDelete(null)
            }

        } catch (error) {
            setDeleteLoading(false)
            setPaymentToDelete(null)
            console.error('rejected', error);
        }
    }
    const defaultHandler = async () => {
        if (!defaultPayment?.id) {
            return null
        }
        setDefaultLoading(true)
        try {
            const response = await postDefault({ id: defaultPayment.id })
            if (response) {
                setDefaultLoading(false)
                setDefaultPayment(null)
            }
        }
        catch (error) {
            setDefaultLoading(false)
            setDefaultPayment(null)
        }
    };

    useMemo(() => {
        if (paymentMethodsResponse?.data)
            setCards(paymentMethodsResponse.data)
    }
        , [paymentMethodsResponse?.data])

    return (
        <section className='billing-page-content__payment-method mt-24'>
            <Typography.Title level={5} className='billing-section-title'>
                Payment method
            </Typography.Title>
            {
                paymentsIsLoading && <Loader />
            }

            {cards && cards.map(item => {
                return (
                    <PaymentMethodRow
                        key={item.id}
                        isDefault={item.isDefault}
                        last4={item.last4}
                        brand={item.brand}
                        id={item.id}
                        onDeleteClick={setPaymentToDelete}
                        onDefaultClick={setDefaultPayment}
                    />
                )
            })}

            <div className='billing-page-content__payment-method__row'>
                <Button
                    type="primary"
                    style={{ padding: '0 12px' }}
                    onClick={() => setSectionType('add_payment_method')}
                >
                    <PlusOutlined />
                    Add card
                </Button>
                <div className='billing-page-content__payment-method__row-method'>
                </div>
                <div className='billing-page-content__payment-method__row-cta'>

                </div>
            </div>
            <ModalDeleteCard
                onCancel={() => setPaymentToDelete(null)}
                paymentInfo={paymentToDelete}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onOk={onDeleteCard}
                isLoading={isDeleteLoading}
            />
            <ModalDefaultCard
                onCancel={() => setDefaultPayment(null)}
                paymentInfo={defaultPayment}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onOk={defaultHandler}
                isLoading={isDefaultLoading}
            />

        </section>
    )
}

export default PaymentMethodsSection
