import { FC } from 'react'
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

type FooterPropsType = {
    onSubmit: () => void
    isSubmitDisabled: boolean;
    onPreview?: () => void;
    isLoading?: boolean
}

export const Footer: FC<FooterPropsType> = ({
    onSubmit,
    isSubmitDisabled,
    isLoading,
    onPreview
}) => {
    const navigate = useNavigate()

    return (
        <div className='event-editor-page__footer'>
            <div></div>
            <aside className='event-editor-page__footer-aside flex'>
                {onPreview ?
                    <Button
                        onClick={onPreview}
                        size={'large'}
                    >
                        Preview
                    </Button>
                    :
                    <Button
                        onClick={() => navigate(-1)}
                        size={'large'}
                    >
                        Cancel
                    </Button>
                }
                <Button
                    loading={isLoading}
                    disabled={isSubmitDisabled}
                    onClick={onSubmit}
                    type={'primary'}
                    size={'large'}
                >
                    Save
                </Button>
            </aside>
        </div>
    );
}