import { LandingAiGenerationSteps, LandingSectionCategory } from './models';
import hero1 from '@assets/lpEditor/sectionPreviews/hero1.png';
import hero2 from '@assets/lpEditor/sectionPreviews/hero2.png';
import hero3 from '@assets/lpEditor/sectionPreviews/hero3.png';
import hero4 from '@assets/lpEditor/sectionPreviews/hero4.png';
import about1 from '@assets/lpEditor/sectionPreviews/about1.png';
import about2 from '@assets/lpEditor/sectionPreviews/about2.png';
import about3 from '@assets/lpEditor/sectionPreviews/about3.png';
import about4 from '@assets/lpEditor/sectionPreviews/about4.png';
import cta1 from '@assets/lpEditor/sectionPreviews/cta1.png';
import cta2 from '@assets/lpEditor/sectionPreviews/cta2.png';
import cta3 from '@assets/lpEditor/sectionPreviews/cta3.png';
import cta4 from '@assets/lpEditor/sectionPreviews/cta4.png';
import form1 from '@assets/lpEditor/sectionPreviews/form1.png';
import form2 from '@assets/lpEditor/sectionPreviews/form2.png';
import gallery1 from '@assets/lpEditor/sectionPreviews/gallery1.png';
import gallery2 from '@assets/lpEditor/sectionPreviews/gallery2.png';
import gallery3 from '@assets/lpEditor/sectionPreviews/gallery3.png';
import services1 from '@assets/lpEditor/sectionPreviews/services1.png';
import services2 from '@assets/lpEditor/sectionPreviews/services2.png';
import services3 from '@assets/lpEditor/sectionPreviews/services3.png';
import services4 from '@assets/lpEditor/sectionPreviews/services4.png';
import team1 from '@assets/lpEditor/sectionPreviews/team1.png';
import team2 from '@assets/lpEditor/sectionPreviews/team2.png';
import team3 from '@assets/lpEditor/sectionPreviews/team3.png';
import team4 from '@assets/lpEditor/sectionPreviews/team4.png';
import text1 from '@assets/lpEditor/sectionPreviews/text1.png';
import text2 from '@assets/lpEditor/sectionPreviews/text2.png';
import text3 from '@assets/lpEditor/sectionPreviews/text3.png';
import video1 from '@assets/lpEditor/sectionPreviews/video1.png';
import video2 from '@assets/lpEditor/sectionPreviews/video2.png';

import {ReactNode} from "react";

const baseUrl = import.meta.env.VITE_API_URL as string;

export const imageAction = `${baseUrl}/partner/landings/image`

export const OptionName: Record<string, string> = {
    BackgroundComponent: 'Background',
    BackgroundColorComponent: 'Background',
    BackgroundImageComponent: 'Background',
    ImageComponent: 'Image',
    ImagesComponent: 'Images',
    VideoComponent: 'Video',
    PdfComponent: 'Pdf',
    ButtonComponent: 'Button',
    TextComponent: 'Text base',
    IconsComponent: 'Icons',
    PlayButtonComponent: 'Button “Play”',
    CornerImagesComponent: 'Images',
    CornerImageComponent: 'Image',
    FullBackgroundImageComponent: 'Image',
}

export enum OptionIds  {
    BackgroundComponent =  'BackgroundComponent',
    BackgroundColorComponent =  'BackgroundColorComponent',
    BackgroundImageComponent = 'BackgroundImageComponent',
    ImageComponent = 'ImageComponent',
    ImagesComponent = 'ImagesComponent',
    VideoComponent = 'VideoComponent',
    PdfComponent = 'PdfComponent',
    ButtonComponent = 'ButtonComponent',
    TextComponent = 'TextComponent',
    IconsComponent = 'IconsComponent',
    CardsComponent = 'CardsComponent',
    PlayButtonComponent = 'PlayButtonComponent',
    CornerImagesComponent = 'CornerImagesComponent',
    CornerImageComponent = 'CornerImageComponent',
    FullBackgroundImageComponent = 'FullBackgroundImageComponent',
    ButtonsComponent = 'ButtonsComponent',
}

export const sectionTitle: Record<LandingSectionCategory, string> = {
    hero: 'Hero',
    about: 'About',
    service: 'Services',
    services: 'Services',
    team: 'Team',
    cta: 'Call to Action',
    form: 'Form',
    text: 'Text',
    gallery: 'Images & Gallery',
    video: 'Video',
};

export const aiGenerationStepsText: Record<LandingAiGenerationSteps, string> = {
    title: 'Boost Ai Assistant is trying to get the company name...',
    description: 'The company description generation is in progress...',
    images: 'In a couple of seconds, Ai Assistant will generate the necessary visual content...',
    finish: "Great! Let's check out the result 🚀",
    video: ''
};

export const HERO = 'hero'

export const sectionsImages: Record<string, ReactNode> = {
    'landing.sections.person.hero1': <img src={hero1} alt="hero1" />,
    'landing.sections.person.hero2': <img src={hero2} alt="hero2" />,
    'landing.sections.person.hero3': <img src={hero3} alt="hero3" />,
    'landing.sections.person.hero4': <img src={hero4} alt="hero4" />,
    'landing.sections.company.about1': <img src={about1} alt="about1" />,
    'landing.sections.company.about2': <img src={about2} alt="about2" />,
    'landing.sections.company.about3': <img src={about3} alt="about3" />,
    'landing.sections.company.about4': <img src={about4} alt="about4" />,
    'landing.sections.cta.cta1': <img src={cta1} alt="cta1" />,
    'landing.sections.cta.cta2': <img src={cta2} alt="cta2" />,
    'landing.sections.cta.cta3': <img src={cta3} alt="cta3" />,
    'landing.sections.cta.cta4': <img src={cta4} alt="cta4" />,
    'landing.sections.form.form1': <img src={form1} alt="form1" />,
    'landing.sections.form.form2': <img src={form2} alt="form2" />,
    'landing.sections.gallery.gallery1': <img src={gallery1} alt="gallery1" />,
    'landing.sections.gallery.gallery2': <img src={gallery2} alt="gallery2" />,
    'landing.sections.gallery.gallery3': <img src={gallery3} alt="gallery3" />,
    'landing.sections.service.services1': <img src={services1} alt="services1" />,
    'landing.sections.service.services2': <img src={services2} alt="services2" />,
    'landing.sections.service.services3': <img src={services3} alt="services3" />,
    'landing.sections.service.services4': <img src={services4} alt="services4" />,
    'landing.sections.team.team1': <img src={team1} alt="team1" />,
    'landing.sections.team.team2': <img src={team2} alt="team2" />,
    'landing.sections.team.team3': <img src={team3} alt="team3" />,
    'landing.sections.team.team4': <img src={team4} alt="team4" />,
    'landing.sections.text.text1': <img src={text1} alt="text1" />,
    'landing.sections.text.text2': <img src={text2} alt="text2" />,
    'landing.sections.text.text3': <img src={text3} alt="text3" />,
    'landing.sections.video.video1': <img src={video1} alt="video1" />,
    'landing.sections.video.video2': <img src={video2} alt="video2" />,
}

export const sectionsNames: Record<string, string> = {
    'landing.sections.person.hero1': 'Cover with right-side image',
    'landing.sections.person.hero2': 'Cover with background image',
    'landing.sections.person.hero3': 'Cover with bottom image',
    'landing.sections.person.hero4': 'Cover with image & button at the bottom',
    'landing.sections.company.about1': 'Text content on colored background',
    'landing.sections.company.about2': 'Text content with background image',
    'landing.sections.company.about3': 'Text content with 2 images',
    'landing.sections.company.about4': 'Text content with 3 images',
    'landing.sections.cta.cta1': 'CTA on colored background',
    'landing.sections.cta.cta2': 'CTA on the background with image',
    'landing.sections.cta.cta3': 'Single button',
    'landing.sections.cta.cta4': 'Button set',
    'landing.sections.form.form1': 'Form and image in 2 columns',
    'landing.sections.form.form2': 'Form in 1 column',
    'landing.sections.gallery.gallery1': 'Combination of images with indents',
    'landing.sections.gallery.gallery2': 'Full screen gallery',
    'landing.sections.gallery.gallery3': 'Image combination',
    'landing.sections.service.services1': 'Cards with images and button',
    'landing.sections.service.services2': 'Cards with images',
    'landing.sections.service.services3': 'Cards with icons and background',
    'landing.sections.service.services4': 'Cards with icons',
    'landing.sections.team.team1': '2-column cards with social icons',
    'landing.sections.team.team2': '3-column cards with social icons',
    'landing.sections.team.team3': '3-column cards',
    'landing.sections.team.team4': '4-column cards',
    'landing.sections.text.text1': 'Header & description in 2 columns',
    'landing.sections.text.text2': 'Header & description side by side',
    'landing.sections.text.text3': 'Centered header & description',
    'landing.sections.video.video1': 'Full-screen video',
    'landing.sections.video.video2': 'Full-screen video with header',
}

export const sectionsTitles: Record<string, string> = {
    'landing.sections.person.hero1': 'Hero',
    'landing.sections.person.hero2': 'Hero',
    'landing.sections.person.hero3': 'Hero',
    'landing.sections.person.hero4': 'Hero',
    'landing.sections.company.about1': 'About',
    'landing.sections.company.about2': 'About',
    'landing.sections.company.about3': 'About',
    'landing.sections.company.about4': 'About',
    'landing.sections.cta.cta1': 'Call to action',
    'landing.sections.cta.cta2': 'Call to action',
    'landing.sections.cta.cta3': 'Call to action',
    'landing.sections.cta.cta4': 'Call to action',
    'landing.sections.form.form1': 'Form',
    'landing.sections.form.form2': 'Form',
    'landing.sections.gallery.gallery1': 'Images & Gallery',
    'landing.sections.gallery.gallery2': 'Images & Gallery',
    'landing.sections.gallery.gallery3': 'Images & Gallery',
    'landing.sections.service.services1': 'Services',
    'landing.sections.service.services2': 'Services',
    'landing.sections.service.services3': 'Services',
    'landing.sections.service.services4': 'Services',
    'landing.sections.team.team1': 'Team',
    'landing.sections.team.team2': 'Team',
    'landing.sections.team.team3': 'Team',
    'landing.sections.team.team4': 'Team',
    'landing.sections.text.text1': 'Text',
    'landing.sections.text.text2': 'Text',
    'landing.sections.text.text3': 'Text',
    'landing.sections.video.video1': 'Video',
    'landing.sections.video.video2': 'Video',
}

export const SERVICES3 = 'landing.sections.service.services3'
export const SERVICES4 = 'landing.sections.service.services4'

export const defaultActions = [
    {
        id: 1,
        transit: 'Publish'
    }
]