import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useBreakpoints } from '@hooks/browser';

const { RangePicker } = DatePicker;

interface DateRangeComponentProps {
    isDrawer?: boolean;
    filters: {
        dateFrom?: string;
        dateTo?: string;
    };
    onDateValueChange: (dates: { dateFrom?: string; dateTo?: string }) => void;
}

const DateRangeComponent: React.FC<DateRangeComponentProps> = ({
    isDrawer,
    filters,
    onDateValueChange
}) => {
    const isDesktop = useBreakpoints().md

    const disabledStartDate = (current: dayjs.Dayjs) => {
        return !!filters?.dateTo && current > dayjs(filters.dateTo);
    };

    const disabledEndDate = (current: dayjs.Dayjs) => {
        return !!filters?.dateFrom && current < dayjs(filters.dateFrom);
    };

    return (
        isDesktop ? (
            <RangePicker
                style={isDrawer ? { width: '100%', marginBottom: '16px' } : {}}
                className="analytics-page__range-picker"
                format="YYYY-MM-DD"
                value={[
                    filters?.dateFrom ? dayjs(filters.dateFrom) : null,
                    filters?.dateTo ? dayjs(filters.dateTo) : null
                ]}
                onChange={(_, dateStrings) => {
                    const [dateFrom, dateTo] = dateStrings as string[];
                    onDateValueChange({ dateFrom, dateTo });
                }}
            />
        ) : (
            <div className='flex w-full mb-12'>
                <DatePicker
                    format="YYYY-MM-DD"
                    value={filters?.dateFrom ? dayjs(filters.dateFrom) : null}
                    className='campaign-create__budget-form-date'
                    onChange={(_, from) => onDateValueChange({ dateFrom: from as string })}
                    placeholder='Start Date'
                    allowClear
                    disabledDate={disabledStartDate}
                />
                <DatePicker
                    format="YYYY-MM-DD"
                    value={filters?.dateTo ? dayjs(filters.dateTo) : null}
                    className='campaign-create__budget-form-date'
                    onChange={(_, to) => onDateValueChange({ dateTo: to as string })}
                    placeholder='Finish Date'
                    allowClear
                    disabledDate={disabledEndDate}
                />
            </div>
        )
    );
};

export default DateRangeComponent;
