import {
    analytics,
    audiences,
    events,
    moderation,
} from '../../pages/routes';

export const menuItemsEvent = {
    // dashboard: {
    //     path: dashboard,
    //     title: 'Dashboard'
    // },
    events: {
        path: events,
        title: 'My events'
    },
    audiences: {
        path: audiences,
        title: 'Audiences'
    },
    moderation: {
        path: moderation,
        title: 'Moderation'
    },
    // partners: {
    //     path: partners,
    //     title: 'Sponsors'
    // },
    analytics: {
        path: analytics,
        title: 'Analytics'
    },
}