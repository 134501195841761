import { useState } from 'react';
import { Button, Modal, Spin, Tooltip, } from 'antd';
import InfoIcon from "@icons/header/InfoIcon";
import credentialsInfo from '@assets/png/credentialsInfo.png'
import { AdAccountsData, OrganizerCredential } from "@store/type-event/credentials/models";
import { CheckCircleIcon } from "@icons/CheckCircleIcon";
import { useBreakpoints } from "@hooks/browser";
import { PartnerCredential } from '@store/type-partner/credentials/models';

type Props = {
    isLoading: boolean
    onClose: () => void

    clickedAdCard: PartnerCredential
    accounts: OrganizerCredential[];
    onSelectAccount: ({ id, name }: { id: number, name: string }) => void
    onSubmit: () => void
}

const CreateChannelModal = ({
    isLoading,
    onClose,

    clickedAdCard,
    accounts,

    onSelectAccount,
    onSubmit
}: Props) => {
    const isDesktop = useBreakpoints().md;

    const [selectedAccount, setSelectedAccount] = useState<{ id: number } | null>(null)

    //ASAP
    const onSelectAccountValue = (accountValue: { id: number }, name: string) => {
        setSelectedAccount(accountValue)
        onSelectAccount({ id: accountValue.id, name })
    }


    return (
        <Modal
            title={`${clickedAdCard?.translate} Account`}
            open={true}
            onCancel={onClose}
            centered
            width={isDesktop ? 440 : 'calc(100vw - 40px)'}
            maskClosable={false}
            footer={(
                <div
                    className="flex justify-end ad-accounts-page__modal__footer"
                >
                    <Button
                        size="large"
                        onClick={onClose}
                        className='mr-6'
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        disabled={!selectedAccount}
                        loading={isLoading}
                        onClick={onSubmit}
                    >
                        Add account
                    </Button>
                </div>
            )}
        >
            <div>
                <div className='ad-accounts-page__header-title ad-accounts-page__select-title'>
                    <div>Please select your {clickedAdCard?.translate || ''} Account</div>
                    <Tooltip
                        overlayClassName='tooltip-general ad-accounts-tooltip'
                        title={(
                            <div>
                                <span>You can easily find your {clickedAdCard?.translate || ''} account ID in the top right corner of the menu panel:</span>
                                <img src={credentialsInfo} alt='credentialsInfo' />
                            </div>
                        )}
                        placement={isDesktop ? "bottom" : "bottomRight"}
                    >
                        <div className='cursor-pointer'>
                            <InfoIcon fillColor="#57585A" width={18} height={18} />
                        </div>
                    </Tooltip>
                </div>
                {isLoading ? (
                    <div className='flex flex-center ad-accounts-page__select-spin'>
                        <Spin size="small" />
                    </div>
                ) : (
                    <div className='ad-accounts-page__select-wrapper custom-scroll'>
                        {accounts.map(({ name, id }, idx) => (
                            <div
                                key={idx}
                                className='ad-accounts-page__select-item'
                                onClick={() => onSelectAccountValue({ id }, name)}
                            >
                                <span className='ad-accounts-page__select-item-id'>{name}</span>
                                {(selectedAccount?.id && selectedAccount.id === id) && (
                                    <CheckCircleIcon isActive={false} width='22' height='22' fillColor='#4E67EA' />
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default CreateChannelModal;
