const BlankIcon = () => {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="72" height="72" rx="36" fill="#4E67EA" fillOpacity="0.45" />
            <path
                d="M35.999 21.999V49.999M21.999 35.999L49.999 35.999"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default BlankIcon
