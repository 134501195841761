import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import './styles.scss'
import TableZeroStateIcon from '@assets/TableZeroStateIcon';
import BrandedCardItem from '@pages/PartnerRolePages/Billing/components/BrandedCardItem';
import { formatCurrency, formatDate } from '@shared/utils';
import InfiniteScrollTable from '@components/InfiniteScrollTable/InfiniteScrollTable';
import DownloadButton from '@pages/PartnerRolePages/Billing/sections/components/DownloadButton';
import { useGetUserBillingQuery } from '@store/main/billing/billing.api';
import { GetUserBillingDataItem } from '@store/main/billing/models';
import DateRangeComponent from '@components/DateRangePicker';

interface Filters {
    dateFrom: string
    dateTo: string
    activity?: string
}
const initialFilters: Filters = {
    activity: '',
    dateFrom: '',
    dateTo: ''
}

const columns: ColumnsType<GetUserBillingDataItem> = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '120px',
        render: (date: string) => <span className='whitespace-nowrap'>{formatDate(date)}</span>
    },
    {
        title: 'Activity type',
        dataIndex: 'name',
        key: 'name',
        width: '120px',
        render: (name: string) => <span className='whitespace-nowrap'>{name}</span>
    },
    {
        title: 'Payment',
        dataIndex: 'payment_method',
        key: 'payment_method',
        width: '220px',

        render: (card) => (
            <div className='whitespace-nowrap'>
                <BrandedCardItem {...card} />
            </div>
        ),
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: GetUserBillingDataItem['amount']) => <span className='whitespace-nowrap'>{formatCurrency(amount?.sum.toString()) || 0}</span>,
        width: '120px',
    },
    {
        dataIndex: 'link',
        key: 'link',
        fixed: 'right',
        render: (link: string, billing) => {
            return (
                <DownloadButton link={link} name={billing?.name} className='billing-activity-tab__download' />
            );
        },
        width: '42px',
    },
];



const ActivityTab: React.FC = () => {
    const [filters, setFilters] = useState<Partial<Filters>>(initialFilters)
    // const [drawerFilters, setDrawerFilters] = useState<Partial<Filters>>(initialFilters)

    const [last, setLast] = useState('')
    // const [isShowFilters, setShowFilters] = useState(false)

    // const [dateFrom, setDateFrom] = useState('')
    // const [dateTo, setDateTo] = useState('')
    // const [isRangePickerOpen, setIsRangePickerOpen] = useState(false)

    const [userBillingList, setUserBillingList] = useState<GetUserBillingDataItem[]>([])
    const { data: userBilling, isLoading, isFetching } = useGetUserBillingQuery(
        {
            last,
            dateFrom: filters.dateFrom,
            dateTo: filters.dateTo
        }, { skip: !!(filters.dateFrom && !filters.dateTo) || !!(!filters.dateFrom && filters.dateTo) })

    useEffect(() => {
        setUserBillingList((prevUserBilling) => {
            const { data = [] } = userBilling || {}
            const newLastId = data[data.length - 1]?.id || 0
            if (last && newLastId !== last) {
                return [...(prevUserBilling || []), ...(userBilling?.data || [])]
            }
            return userBilling?.data || []
        })
    }, [userBilling])

    const onNextPage = () => {
        const { data = [] } = userBilling || {}
        const newLast = data[data.length - 1]?.id
        setLast(newLast)
    }

    // const onRangeChange = (_, range: string[]) => {
    //     const [newDateFrom, newDateTo] = range
    //     onFilterUpdate({ dateFrom: newDateFrom })
    //     onFilterUpdate({ dateTo: newDateTo })
    //     setLast('')
    // }

    const onFilterUpdate = (value: Partial<Filters>) => {
        setFilters((prev) => ({ ...prev, ...value }));
    };

    // const handleChangeFilter = (value: Partial<Filters>) => {
    //     setDrawerFilters((prev) => ({ ...prev, ...value }));
    // };

    // const onApplyFilters = () => {
    //     setFilters(drawerFilters)
    //     setShowFilters(false)
    // }

    // const onDrawerResetClick = () => {
    //     // onResetFilters();
    //     setDrawerFilters(initialFilters)
    //     setFilters(initialFilters)
    //     // setShowFilters(false)
    // }

    return (
        <div className='billing-activity-tab'>
            <DateRangeComponent
                filters={filters}
                onDateValueChange={values => {
                    onFilterUpdate(values)
                    setLast('')
                }}
            />


            <InfiniteScrollTable
                isLastPage={Boolean(!userBilling?.hasNext)}
                onNextPage={onNextPage}
                dataSource={userBillingList || []}
                columns={columns}
                rowKey='id'
                loading={isLoading || isFetching}
                className='billing-activity-table mt-12'
                isFirstPage
                locale={{
                    emptyText: (
                        <div className='billing-activity-tab__zero-state flex-col'>
                            <TableZeroStateIcon />
                            <p>Empty Billing Activity</p>
                        </div>
                    )
                }}
            />
        </div>
    )
}

export default ActivityTab
