import {Modal} from "antd";
import React from "react";
import './styles.scss'

interface Props {
    isOpen: boolean
    onClose: () => void
}

const SwitchToDesktopModal = ({ isOpen, onClose }: Props) => {
    return (
        <Modal
            centered
            title="Switch to Desktop"
            open={isOpen}
            onOk={onClose}
            onCancel={onClose}
            okText="Got it"
            className="switch-to-desktop-modal"
            width={440}
        >
            Please, switch to a desktop to create a landing page
        </Modal>
    )
}

export default SwitchToDesktopModal