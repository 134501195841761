import React, { useState } from "react";
import { Button, Form, Input, Modal, Popover } from "antd";
import styles from "./ChangingPasswordModal.module.css";
import PasswordPopover from '@pages/CommonPages/Authentification/Register/components/PasswordPopover';
import { CheckCircleSuccessIcon } from '@icons/CheckCircleSuccessIcon';
import { useForm } from 'laravel-precognition-react';
import './styles.scss'

type ChangingPasswordModalPropsType = {
    isOpen: boolean
    onClose: (isOpen: boolean) => void
    reFetchUser: () => Promise<any>
}
const formInitial = {
    password: "",
    old_password: "",
    password_confirmation: ""
}
const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const ChangingPasswordModal: React.FC<ChangingPasswordModalPropsType> = (props) => {
    const { isOpen, onClose } = props;
    const [isLoading, setLoading] = useState(false)

    const form = useForm('post', `${apiBaseUrl}/user/change-password`, {
        password: formInitial.password,
        password_confirmation: formInitial.password_confirmation,
        old_password: formInitial.old_password
    })
    const { errors, validating, processing, hasErrors } = form

    const onChangeFieldValue = (fieldName: keyof typeof form.data, value: string) => {
        form.setData(fieldName, value)
        form.setErrors({ ...form.errors, [fieldName]: '' });
    }

    const onModalClose = () => {
        onClose(false)
        form.reset();
        setIsSuccessStatus(false)
    }
    const handleOk = async () => {
        setLoading(true)
        try {
            form.submit()
                .then((response: any) => {
                    if ('error' in response) {
                        setLoading(false)
                    }

                    if ('data' in response) {
                        onModalClose()
                    }
                })
                .catch(() => {
                    setLoading(false)
                });
        } catch (error) {
            setLoading(false)
        }
    }
    const [isSuccessStatus, setIsSuccessStatus] = useState(false);
    const isSuccessStatusHandler = (statusState: boolean) => {
        setIsSuccessStatus(statusState)
    }

    return (
        <Modal
            title="Change password"
            open={isOpen}
            onCancel={onModalClose}
            confirmLoading={isLoading}
            className={`${styles.ChangingPasswordModal} user-changing-password-modal`}
            centered
            footer={
                <>
                    <div className={styles.ChangingPasswordModal__footer}>
                        <Button
                            size={'large'}
                            onClick={onModalClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            size={'large'}
                            type={'primary'}
                            className={styles.ChangingPasswordModalSaveBtn}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleOk}
                            disabled={!isSuccessStatus || validating || processing || hasErrors}
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </div>
                </>
            }
        >
            <Form layout={'vertical'} className={styles.ChangingPasswordModal__form}>
                <Form.Item
                    className={styles.ChangingPasswordModal__formItem}
                    label={<span style={{ marginTop: '12px' }} className={styles.ChangingPasswordModal__label}>Current password</span>}
                    htmlFor={'currentPasswordModal'}
                    help={<span className={styles.ChangingPasswordModalErrorSpan}>{errors.old_password}</span>}
                    validateStatus={errors.old_password ? 'error' : ''}
                >
                    <Input.Password
                        id={'currentPasswordModal'}
                        size={'large'}
                        value={form.data.old_password}
                        onChange={(e) => onChangeFieldValue('old_password', e.target.value)}
                        onBlur={() => {
                            form.validate('old_password')
                        }}
                    />
                </Form.Item>

                <Popover
                    content={() => (
                        <PasswordPopover
                            password={form.data.password}
                            isSuccessStatusHandler={isSuccessStatusHandler}
                        />
                    )}
                    placement="bottomLeft"
                    trigger={'focus'}
                >

                    <Form.Item
                        label={<span className={styles.ChangingPasswordModal__label}>New password</span>}
                        htmlFor={'newPasswordModal'}
                        className={styles.ChangingPasswordModal__formItem}
                        help={<span className={styles.ChangingPasswordModalErrorSpan}>{errors.password}</span>}
                        validateStatus={errors.password ? 'error' : ''}
                    >
                        <Input.Password
                            id={'newPasswordModal'}
                            size={'large'}
                            value={form.data.password}
                            onChange={(e) => onChangeFieldValue('password', e.target.value)}
                            onBlur={() => {
                                form.validate('password')
                            }}
                            prefix={isSuccessStatus ? <CheckCircleSuccessIcon /> : null}
                        />
                    </Form.Item>
                </Popover>
                <Form.Item
                    label={<span className={styles.ChangingPasswordModal__label}>Confirm password</span>}
                    htmlFor={'confirmPasswordModal'}
                    className={styles.ChangingPasswordModal__itemConfirm}
                    help={<span className={styles.ChangingPasswordModalErrorSpan}>{errors.password_confirmation}</span>}
                    validateStatus={errors.password_confirmation ? 'error' : ''}
                >
                    <Input.Password
                        id={'confirmPasswordModal'}
                        size={'large'}
                        type={'password'}
                        value={form.data.password_confirmation}
                        onChange={(e) => onChangeFieldValue('password_confirmation', e.target.value)}
                        onBlur={() => {
                            form.validate('password_confirmation')
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ChangingPasswordModal;
