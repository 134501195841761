export const TwitterColored = () => (

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.75 2.75C3.64543 2.75 2.75 3.64543 2.75 4.75V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H19.4065C20.5111 21.25 21.4065 20.3546 21.4065 19.25V4.75C21.4065 3.64543 20.5111 2.75 19.4065 2.75H4.75Z"
            fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.5104 6.74978C6.60443 6.56589 6.79355 6.45018 7.00009 6.45018H9.77787C9.95446 6.45018 10.1203 6.53498 10.2237 6.67813L12.8745 10.348L16.6112 6.61127C16.826 6.39648 17.1742 6.39648 17.389 6.61127C17.6038 6.82606 17.6038 7.1743 17.389 7.38909L13.5269 11.2512L17.446 16.6782C17.5669 16.8456 17.5838 17.0667 17.4898 17.2506C17.3958 17.4345 17.2066 17.5502 17.0001 17.5502H14.2223C14.0457 17.5502 13.8799 17.4654 13.7765 17.3222L11.1257 13.6524L7.389 17.3891C7.17421 17.6039 6.82597 17.6039 6.61118 17.3891C6.39639 17.1743 6.39639 16.8261 6.61118 16.6113L10.4733 12.7491L6.5542 7.32218C6.43329 7.15474 6.41637 6.93367 6.5104 6.74978ZM8.0757 7.55018L14.5035 16.4502H15.9245L9.49667 7.55018H8.0757Z"
              fill="white"/>
    </svg>

)