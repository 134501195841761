import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Spin, Tooltip, } from 'antd';
import InfoIcon from "@icons/header/InfoIcon";
import credentialsInfo from '@assets/png/credentialsInfo.png'
import { OrganizerCredential } from "@store/type-event/credentials/models";
import { CheckCircleIcon } from "@icons/CheckCircleIcon";
import { useBreakpoints } from "@hooks/browser";

type Props = {
    isOpen: boolean
    isLoading: boolean
    isCredentialsLoading: boolean
    onClose: () => void
    selectedData?: OrganizerCredential
    onSelectAccount: ({ accountValue, credentialId }: {
        accountValue: { id: number },
        credentialId: number,
        name: string | null,
        field: string,
        channel: string
    }) => void
}

const SelectModal: FC<Props> = ({ isOpen, isLoading, onClose, selectedData, isCredentialsLoading, onSelectAccount }) => {
    const isDesktop = useBreakpoints().md;

    const [selectedAccount, setSelectedAccount] = useState<{ id: number } | null>(null)
    const [selectedName, setSelectedName] = useState<string | null>(null)

    const onSelectAccountValue = (accountValue: { id: number }, name: string) => {
        setSelectedAccount(accountValue)
        setSelectedName(name)
    }

    useEffect(() => {
        const field = selectedData?.field as string
        if (!isLoading && !isCredentialsLoading && !selectedData?.data?.[field]?.list?.length) {
            onClose()
        }
    }, [isCredentialsLoading, isLoading, onClose, selectedData])

    return (
        <Modal
            title={`${selectedData?.channel?.translate || ''} Account`}
            open={isOpen}
            onCancel={onClose}
            centered
            width={isDesktop ? 440 : 'calc(100vw - 40px)'}
            maskClosable={false}
            footer={(
                <div
                    className="flex justify-end ad-accounts-page__modal__footer"
                >
                    <Button
                        size="large"
                        onClick={onClose}
                        className='mr-6'
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        disabled={!selectedAccount}
                        loading={isLoading}
                        onClick={() => {
                            if (selectedAccount && selectedData?.id) {
                                onSelectAccount({
                                    accountValue: selectedAccount,
                                    credentialId: selectedData?.id,
                                    name: selectedName,
                                    field: selectedData?.field || '',
                                    channel: selectedData?.channel?.translate || ''
                                })
                            }
                        }}
                    >
                        Add account
                    </Button>
                </div>
            )}
        >
            <div>
                <div className='ad-accounts-page__header-title ad-accounts-page__select-title'>
                    <div>Please select your {selectedData?.channel?.translate || ''} Account</div>
                    <Tooltip
                        overlayClassName='tooltip-general ad-accounts-tooltip'
                        title={(
                            <div>
                                <span>You can easily find your {selectedData?.channel?.translate || ''} account ID in the top right corner of the menu panel:</span>
                                <img src={credentialsInfo} alt='credentialsInfo' />
                            </div>
                        )}
                        placement={isDesktop ? "bottom" : "bottomRight"}
                    >
                        <div className='cursor-pointer'>
                            <InfoIcon fillColor="#57585A" width={18} height={18} />
                        </div>
                    </Tooltip>
                </div>
                {isCredentialsLoading ? (
                    <div className='flex flex-center ad-accounts-page__select-spin'>
                        <Spin size="small" />
                    </div>
                ) : (
                    <div className='ad-accounts-page__select-wrapper custom-scroll'>
                        {selectedData?.data?.[selectedData?.field || '']?.list?.map(({ name, value }) => {
                            return (
                                <div className='ad-accounts-page__select-item' onClick={() => onSelectAccountValue(value, name)}>
                                    <span>Account ID: </span>
                                    &nbsp;
                                    <span className='ad-accounts-page__select-item-id'>{name}</span>
                                    {(selectedAccount?.id && value?.id && selectedAccount.id === value.id) && (
                                        <CheckCircleIcon isActive={false} width='22' height='22' fillColor='#4E67EA' />
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default SelectModal;
