import {
    JoditOptionName
} from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/assets/models.tsx";
import {PartnerLandingSectionLayout} from "@store/type-partner/landings/models.ts";
import React, {useContext, useEffect, useState} from "react";
import {LandingEditorContext} from "@contexts/LandingEditorContext.tsx";
import {
    COPIED_LANDING_SECTION
} from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/constants.ts";
import {Dropdown, notification} from "antd";
import CloneIcon
    from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/assets/CloneIcon.tsx";
import HideEyeIcon from "@assets/lpEditor/HideEyeIcon.tsx";
import SettingsIcon
    from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/assets/SettingsIcon.tsx";
import DeleteRedIcon
    from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/assets/DeleteRedIcon.tsx";
import ThreeDotsIcon from "@assets/pro/ThreeDotsIcon.tsx";
import UpArrowIcon
    from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/assets/UpArrowIcon.tsx";
import DownArrowIcon
    from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/assets/DownArrowIcon.tsx";
import CustomTooltip from "@components/CustomTooltip/CustomTooltip.tsx";
import EyeIcon from "@assets/EyeIcon.tsx";

const CustomOptions = (
    {
        id,
        onOptionClick,
        layout,
        idx,
        isHidden,
    }
        :
        {
            id: string;
            idx: number;
            onOptionClick: ({ option, id }: { option: JoditOptionName, id: string }) => void;
            layout: PartnerLandingSectionLayout
            isHidden?: boolean
        }
) => {
    const [isPasteDisabled, setIsPasteDisabled] = useState(true)
    const [isOtherDropdownOpen, setIsOtherDropdownOpen] = useState(false)
    const {
        setLayoutSettingsData,
        onSectionAddToList
    } = useContext(LandingEditorContext);

    useEffect(() => {
        const copiedSection = localStorage.getItem(COPIED_LANDING_SECTION);
        if (copiedSection) {
            setIsPasteDisabled(false)
        }
    }, []);

    const onCopyClick = () => {
        localStorage.setItem(COPIED_LANDING_SECTION, JSON.stringify(layout));
        setIsPasteDisabled(false)
        notification.open({
            message: 'Section has been successfully copied to the clipboard',
            placement: 'bottomLeft',
            closeIcon: false,
            type: 'success'
        });
    }

    const onPasteClick = () => {
        const copiedSection = localStorage.getItem(COPIED_LANDING_SECTION);
        if (copiedSection) {
            onSectionAddToList(JSON.parse(copiedSection), idx + 1)
        }
    }

    const dropdownItems = [
        {
            label: (
                <div onClick={onCopyClick}>
                    Copy
                </div>
            ),
            key: 'Copy',
        },
        {
            label: (
                <div onClick={onPasteClick}>
                    Paste
                </div>
            ),
            key: 'Paste',
            disabled: isPasteDisabled
        },
    ];

    return (
        <aside className={`jodit-toolbar__box-aside ${isOtherDropdownOpen ? 'jodit-toolbar__box-aside-open' : ""}`}>
            <article
                className='jodit-toolbar__box-aside__settings'
            >
                {/* not active for the moment */}
                {/* <button
        onClick={() => onCustomOptionClick({ option: JoditOptionName.Clone, id })}
        className='jodit-toolbar__box-aside__settings__cta'>
        <CloneIcon />
    </button> */}

                <CustomTooltip
                    title="Duplicate"
                >
                    <button
                        onClick={() => {
                            onOptionClick({ option: JoditOptionName.Clone, id })
                        }}
                        className='jodit-toolbar__box-aside__settings__cta jodit-toolbar__box-aside__settings__cta__clone'
                    >
                        <CloneIcon width={20} height={20} />
                    </button>
                </CustomTooltip>
                {isHidden ? (
                    <CustomTooltip
                        title="Show"
                    >
                        <button
                            onClick={() => onOptionClick({ option: JoditOptionName.Show, id })}
                            className='jodit-toolbar__box-aside__settings__cta jodit-toolbar__box-aside__settings__cta__hide'
                        >
                            <EyeIcon fillColor="#57585A" withBorders={false} backgroundFillColor="transparent"/>
                        </button>
                    </CustomTooltip>
                ) : (
                    <CustomTooltip
                        title="Hide"
                    >
                        <button
                            onClick={() => onOptionClick({ option: JoditOptionName.Hide, id })}
                            className='jodit-toolbar__box-aside__settings__cta jodit-toolbar__box-aside__settings__cta__hide'
                        >
                            <HideEyeIcon />
                        </button>
                    </CustomTooltip>
                )}
                <CustomTooltip
                    title="Settings"
                >
                    <button
                        onClick={() => {
                            setLayoutSettingsData(layout)
                        }}
                        className='jodit-toolbar__box-aside__settings__cta'
                    >
                        <SettingsIcon width={20} height={20} />
                    </button>
                </CustomTooltip>

                <CustomTooltip
                    title="Delete"
                >
                    <button
                        onClick={() => onOptionClick({ option: JoditOptionName.Delete, id })}
                        className='jodit-toolbar__box-aside__settings__cta'
                    >
                        <DeleteRedIcon width={20} height={20} fillColor="#57585A" />
                    </button>
                </CustomTooltip>
                <CustomTooltip
                    title="Other"
                >
                    <Dropdown
                        open={isOtherDropdownOpen}
                        onOpenChange={setIsOtherDropdownOpen}
                        className="dropdown"
                        menu={{ items: dropdownItems }}
                        trigger={['hover']}
                        placement="bottomRight"
                    >
                        <button
                            className='jodit-toolbar__box-aside__settings__cta jodit-toolbar__box-aside__settings__cta__dropdown'
                        >
                            <ThreeDotsIcon fillColor="#57585A" />
                        </button>
                    </Dropdown>
                </CustomTooltip>

                {/* not active for the moment */}
                {/* <button
                    onClick={() => onOptionClick({ option: JoditOptionName.Menu, id })}
                    className='jodit-toolbar__box-aside__settings__cta'>
                    <MenuHorIcon />
                </button> */}
            </article>

            <article
                className='jodit-toolbar__box-aside__order-updater'
            >
                <CustomTooltip
                    title="Move up"
                >
                    <button
                        onClick={() => onOptionClick({ option: JoditOptionName.Up, id })}
                        className='jodit-toolbar__box-aside__order-updater__cta jodit-toolbar__box-aside__order-updater__up-arrow'
                    >
                        <UpArrowIcon width={20} height={20} />
                    </button>
                </CustomTooltip>
                <CustomTooltip
                    title="Move down"
                >
                    <button
                        onClick={() => onOptionClick({ option: JoditOptionName.Down, id })}
                        className='jodit-toolbar__box-aside__order-updater__cta jodit-toolbar__box-aside__order-updater__down-arrow'
                    >
                        <DownArrowIcon width={20} height={20} />
                    </button>
                </CustomTooltip>
            </article>
        </aside>
    )
}

export default CustomOptions