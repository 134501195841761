import Layout700Center from '@layouts/CardLayout'
import { Switch, Typography } from 'antd'
import TemplatesContentLayout from './components/TemplatesContentLayout'
import ZeroState from './components/ZeroState'
import './styles.scss'
import CreativesManager from './CreativesManager'

const CreativeTemplates = () => {
    const hasTemplates = true
    return (
        <div className='event-creative-templates'>
            <div className='event-creative-templates__header'>

                <Typography.Title level={4}>
                    Creatives Template
                </Typography.Title>

                {hasTemplates
                    ?
                    <div>
                        <span className='text-regular-14 mr-6'>
                            Example content
                        </span>
                        <Switch
                        />
                    </div>
                    :
                    null}
            </div>

            <TemplatesContentLayout>
                {
                    hasTemplates
                        ?
                        <CreativesManager />
                        :
                        <ZeroState />
                }
            </TemplatesContentLayout>
        </div>
    )
}

export default CreativeTemplates