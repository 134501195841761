import { CheckOutlined, CloseOutlined, DownOutlined, FullscreenOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import React from 'react'
import AdChannelIconRender from './AdChannelIconRender'

const CreativesHeader = () => {

    const accToApprove = [
        'google_ads',
        'linkedin_ads',
        'x_ads'
    ]

    const rejectMenu = (
        <Menu>
            <Menu.Item key="1">
                <span style={{ color: '#ff4d4f' }}>
                    Reject all сreatives
                </span>
            </Menu.Item>
        </Menu>
    );

    const approveMenu = (
        <Menu>
            <Menu.Item key="1">
                <span>
                    Approve all сreatives
                </span>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className='creatives-carousel-header'>
            {accToApprove.length
                ?
                <div className='creatives-carousel-header__status-section'>
                    <div className='flex-col'>
                        <div>
                            <span className="status-dot waiting" />
                            <span className='text-regular-14'>Waiting for approval</span>
                        </div>
                        <div className='flex gap-8 mt-16'>
                            {accToApprove.map(channel => {
                                return (
                                    <AdChannelIconRender
                                        key={channel}
                                        channel={channel}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <aside className='flex gap-8'>
                        <Button danger type='text' icon={
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_19745_176747)">
                                    <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 5.60938 1.67656 4.33125 2.49219 3.33125L10.6687 11.5078C9.66875 12.3234 8.39062 12.8125 7 12.8125ZM11.5078 10.6687L3.33125 2.49219C4.33125 1.67656 5.60938 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 8.39062 12.3234 9.66875 11.5078 10.6687Z" fill="#F5222D" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_19745_176747">
                                        <rect width="14" height="14" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        }
                            className="reject-button">
                            Reject creative

                            <Dropdown
                                overlay={rejectMenu}
                                trigger={['click']}
                                placement='bottomRight'
                            >
                                <DownOutlined
                                    style={{
                                        borderLeft: '1px solid #ff4d4f',
                                        paddingLeft: '8px'
                                    }}
                                />
                            </Dropdown>
                        </Button>

                        {/* Approve Button with Dropdown */}
                        <Button type="text" icon={<CheckOutlined />} className="approve-button">
                            Approve creative

                            <Dropdown
                                overlay={approveMenu}
                                trigger={['click']}
                                placement='bottomRight'
                            >
                                <DownOutlined
                                    style={{
                                        borderLeft: '1px solid #52c41a',
                                        paddingLeft: '8px'
                                    }}
                                />
                            </Dropdown>

                        </Button>
                    </aside>
                </div>
                :
                <div />}
            <Button icon={<FullscreenOutlined />} shape="circle" />
        </div>
    )
}

export default CreativesHeader