import React, {FC, useEffect, useMemo, useState} from 'react'

import './styles.scss'

import { TabKeys } from "@pages/EventRolePages/Billing/constants.ts";
import { useNavigate } from "react-router-dom";
import RevenueReportsTable from "@pages/EventRolePages/Billing/components/RevenueReportsTable.tsx";
import TransactionsTable from "@pages/EventRolePages/Billing/components/TransactionsTable.tsx";
import WithdrawalTable from "@pages/EventRolePages/Billing/components/WithdrawalTable.tsx";
import EventBillingDrawer from "@pages/EventRolePages/Billing/components/EventBillingDrawer.tsx";
import BillingDetails from "@pages/EventRolePages/Billing/components/BillingDetails.tsx";
import EventBillingHeader from "@components/Header/EventBillingHeader.tsx";
import RevShareCommissionsTable from "@pages/EventRolePages/Billing/components/RevShareCommissions.tsx";
import EmptyBilling from '../EventPage/setup/billing/EmptyBilling';
import { useAuth } from "@contexts/AuthContext.tsx";
import { events } from "@pages/routes.ts";

type Props = {
    tabId?: string
    billingId?: string
}

const BillingEvent: FC<Props> = ({ tabId = '', billingId }) => {
    const navigate = useNavigate()
    const { user } = useAuth();

    const [isEventBillingDrawerOpen, setIsEventBillingDrawerOpen] = useState(false)

    const onCreateBilling = () => {
        setIsEventBillingDrawerOpen(true)
    }

    useEffect(() => {
        const isUserAdmin = user?.roles?.includes('admin')
        if (!isUserAdmin) {
            navigate(events)
        }
    }, [user]);

    const currentTab = useMemo(() => {
        switch (tabId) {
            case TabKeys.RevenueReports:
                return <RevenueReportsTable />
            case TabKeys.RevShareCommissions:
                return <RevShareCommissionsTable />
            case TabKeys.Transactions:
                return <TransactionsTable />
            case TabKeys.WithdrawalRequests:
                return <WithdrawalTable />
            case TabKeys.BillingDetails:
                return <BillingDetails />
        }
    }, [tabId])

    const isEmpty = !billingId || billingId === 'undefined'

    return (
        <div className={`organizer-billing-page`}>
            <EventBillingHeader />
            {isEmpty ? <EmptyBilling onCreateBilling={onCreateBilling} /> : currentTab}

            {isEventBillingDrawerOpen
                ?
                <EventBillingDrawer
                    isOpen={isEventBillingDrawerOpen}
                    onClose={() => setIsEventBillingDrawerOpen(false)}
                />
                :
                null}
        </div>
    )
}

export default BillingEvent
