import { usePatchPartnerCampaignsStatusByIdMutation } from '@store/type-partner/campaigns/campaigns.api';
import { Dropdown, MenuProps, message } from 'antd';
import { Link } from 'react-router-dom'
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { CampaignDataItem } from '@store/type-partner/campaigns/models';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

interface DropDownSettingsProps {
    children: React.ReactNode,
    eventId: string
    campaignId: string
    status: number;
    onCampaignStatusChange: () => void
    campaignsList: CampaignDataItem[]
    setCampaignsList: (campaigns: CampaignDataItem[]) => void
}
const DropDownSettings = ({
    children,
    eventId,
    campaignId,
    status,
    onCampaignStatusChange,
    campaignsList,
    setCampaignsList
}: DropDownSettingsProps) => {
    const [isLoading, setLoading] = useState(false)
    const [onDelete] = usePatchPartnerCampaignsStatusByIdMutation()

    const onDeleteClick = async () => {
        // dispatch(campaignsActions.removeCampaignsById({ campaignIds: [campaignId] }))
        try {
            setLoading(true)
            const response = await onDelete({ campaignIds: [campaignId], status: 3 });

            if ('data' in response) {
                sendAnalytics(GTMEventName.deleteCampaign)
                setCampaignsList(campaignsList.filter(campaign => campaign.id !== campaignId))
                return message.open({
                    type: 'success',
                    content: 'Campaign replaced to archive',
                });
            }
            if ('error' in response) {
                console.log('SMTH WRONG');
                // Handle the error here
            } else {
                // navigate('/campaigns');
                // login(values);
                console.log('Success:');
            }
        } catch (error) {
            console.log('SMTH WRONG');
            // Handle the error here
        } finally {
            setLoading(false)
        }
    }

    const items: MenuProps['items'] = [
        {
            key: 'settings',
            label: (
                <Link to={`/campaigns/edit/${eventId}/campaign/${campaignId}`}>
                    Settings
                </Link>
            ),
        },
        status === 2 ?
            {
                key: 'start',
                label: (
                    <div onClick={onCampaignStatusChange}>
                        Start
                    </div>
                ),
            } : null,
        status === 1 ?
            {
                key: 'pause',
                label: (
                    <div onClick={onCampaignStatusChange}>
                        Pause
                    </div>
                ),
            } : null,
        {
            type: 'divider',
        },
        {
            danger: true,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            label: <span onClick={onDeleteClick}>
                {isLoading
                    ? <LoadingOutlined style={{ color: '#FFF' }} />
                    : 'Delete'
                }
            </span>
            ,
            key: 'delete',

        },
    ];
    const itemsDeleteOnly: MenuProps['items'] = [
        {
            danger: true,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            label: <span onClick={onDeleteClick}>
                Delete
            </span>
            ,
            key: 'delete',

        },
    ];

    return (
        <Dropdown
            menu={eventId ? { items } : { items: itemsDeleteOnly }}
        >
            {children}
        </Dropdown>
    )
}

export default DropDownSettings