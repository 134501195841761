import EventsPageLayout from '@layouts/EventsPageLayout'
// import CampaignsZeroState from './CampaignsZeroState'
import TableCampaignsMain from './components/Table'
import usePageAnalytics from "@hooks/sendAnalytics/usePageAnalytics";
import ContentLayout from "@layouts/ContentLayout.tsx";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { INIT_PAGE } from '@components/InfiniteScrollTable/constants';
import { useGetPartnerCampaignsQuery } from '@store/type-partner/campaigns/campaigns.api';
import { CampaignDataItem } from '@store/type-partner/campaigns/models';

const EventCampaignsPage = () => {
    usePageAnalytics('campaigns');

    const [page, setPage] = useState(INIT_PAGE)
    const [campaignsList, setCampaignsList] = useState<CampaignDataItem[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const { data, isLoading, isFetching } = useGetPartnerCampaignsQuery({ filter: { page } })

    useEffect(() => {
        setCampaignsList((prevLandings) => {
            const from = data?.meta?.from || 0
            if (prevLandings.length < from) {
                return [...(prevLandings || []), ...(data?.data || [])]
            }
            return data?.data || []
        })

    }, [data?.data])

    const onSelectChange = (newSelectedRowKeys: string[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const isLastPage = useMemo(() => {
        const lastPage = data?.meta?.last_page || INIT_PAGE
        return page >= lastPage
    }, [data?.meta?.last_page, page])

    const onNextPage = useCallback(() => {
        setPage(page + 1)
    }, [page])

    return (
        <EventsPageLayout>
            <ContentLayout>
                {/* <CampaignsZeroState /> */}
                <TableCampaignsMain
                    campaigns={campaignsList}
                    isLoading={isLoading || isFetching}
                    page={page}
                    selectedRowKeys={selectedRowKeys}
                    onSelectChange={onSelectChange}
                    onNextPage={onNextPage}
                    isLastPage={isLastPage}
                    setCampaignsList={setCampaignsList}
                />
            </ContentLayout>
        </EventsPageLayout>

    )
}

export default EventCampaignsPage