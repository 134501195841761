import React from 'react'
import { Button } from 'antd';
import RocketIcon from '@assets/pro/RocketIcon';
import cn from 'classnames';
import styles from './BoostProBanner.module.scss'
import CheckIcon from '@assets/CheckIcon';
import Image from '@assets/pro/boostProBannerImage.png'
import { useAuth } from "@contexts/AuthContext.tsx";

interface BoostProBannerProps {
    onClose: () => void
    onEnablePro: () => void
}
const BoostProBanner = ({ onClose, onEnablePro }: BoostProBannerProps) => {
    const {  proSubscriptionData: { proSubscription } } = useAuth()

    return (
        <div className={styles.Wrapper}>

            <button
                onClick={onClose}
                className={styles.CloseButton}
            >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.5867 1.5886C13.9121 1.26317 13.9121 0.73553 13.5867 0.410093C13.2612 0.0846564 12.7336 0.0846564 12.4081 0.410093L6.9974 5.82084L1.58665 0.410093C1.26121 0.0846564 0.733577 0.0846564 0.40814 0.410093C0.0827033 0.73553 0.0827033 1.26317 0.40814 1.5886L5.81888 6.99935L0.40814 12.4101C0.0827033 12.7355 0.0827033 13.2632 0.40814 13.5886C0.733577 13.914 1.26121 13.914 1.58665 13.5886L6.9974 8.17786L12.4081 13.5886C12.7336 13.914 13.2612 13.914 13.5867 13.5886C13.9121 13.2632 13.9121 12.7355 13.5867 12.4101L8.17591 6.99935L13.5867 1.5886Z" fill="#C0C1C3" />
                </svg>
            </button>

            <div>
                <Button
                    size="large"
                    className={cn(styles.Button, "boost-pro-gradient")}
                >
                    <RocketIcon fillColor="#504BD9" />
                    Boost PRO
                </Button>
                <span className={styles.Title}>
                    Get full campaign assistance from our team of experts with Boost PRO service
                </span>
                <div className={styles.List}>
                    <div className={styles.ListItem}>
                        <CheckIcon />
                        <span>Customized ad strategies tailored to your goals</span>
                    </div>
                    <div className={styles.ListItem}>
                        <CheckIcon />
                        <span>Continuous campaign monitoring and optimization</span>
                    </div>
                    <div className={styles.ListItem}>
                        <CheckIcon />
                        <span>Landing pages and creatives production</span>
                    </div>
                </div>
                <div className={styles.Footer}>
                    <Button
                        className={cn("gradient-pro", styles.EnableProButton)}
                        size="large"
                        onClick={onEnablePro}
                    >
                        Enable PRO
                    </Button>
                    <ul>
                        <li>Monthly fee – {proSubscription?.formattedPrice}</li>
                        <li>If monthly spend more than {proSubscription?.formattedSpendForFree} – free</li>
                    </ul>
                </div>
            </div>
            <div className={styles.Image}>
                <img src={Image} alt="image" />
            </div>
        </div>

    )
}

export default BoostProBanner
