import BodyPageLayout from '@layouts/BodyPageLayout';
import './styles.scss';
import { Typography } from 'antd';
import EventInfoForm from '@components/EventInfoForm';
import Layout700Center from '@layouts/CardLayout';
import { useParams } from 'react-router-dom';
import React, { useContext } from 'react';
import Loader from '@components/Loader';
import { EventEditorContext } from '@contexts/forms/event/EventEditorContext';
import { Footer } from '@pages/EventRolePages/EventPage/components/Footer.tsx';
import SkeletonEventsSetupPage from './skeleton.tsx';
import { useGetOrganizerEventByIdQuery } from '@store/type-event/events/events.api.ts';
import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb'
import { useBreakpoints } from '@hooks/browser/useBreakpoints.ts';
import EventsPageLayout from '@layouts/EventsPageLayout.tsx';

function EventsSetupPage() {
    const isDesktop = useBreakpoints().md
    const { eventId = '' } = useParams();
    const { data: fetchedEvent, isLoading } = useGetOrganizerEventByIdQuery({ eventId }, { skip: !eventId });
    const eventData = fetchedEvent?.data;

    const { isUpdating, onSubmit } = useContext(EventEditorContext);

    const editCrumbs = [
        {
            url: '/events',
            label: 'My events',
            isZeroLevel: true
        },
        {
            label: !eventData?.id ? 'New event' : eventData?.name
        },
    ]

    if (isLoading) {
        return (
            <EventsPageLayout>
                <div className="organizer-my-events-page-body">
                    <Layout700Center>
                        <SkeletonEventsSetupPage />
                    </Layout700Center>
                </div>
            </EventsPageLayout>
        );
    }

    return (
        <EventsPageLayout>
            <>
                {isDesktop
                    ?
                    <HeaderBreadcrumb crumbs={editCrumbs} />
                    :
                    null}
                <div className="organizer-my-events-page-body">
                    {isUpdating && <Loader />}

                    <Layout700Center>
                        <EventInfoForm
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            Footer={Footer}
                            Header={<Typography.Title level={4}>Event details</Typography.Title>}
                            fetchedData={eventData}
                            onSubmit={onSubmit}
                        />
                    </Layout700Center>

                </div>
            </>
        </EventsPageLayout>
    );
}

export default EventsSetupPage;
