import CardsBoostProImage from '@assets/billing/CardsBoostProImage'

import './styles.scss'
import SuccessBoostProIcon from '@assets/billing/SuccessBoostProIcon'
import { Button } from 'antd'
import { useContext } from 'react'
import { UserBillingPageContext } from '@contexts/UserBillingPageContext'
import { useMediaquery } from '@hooks/browser'
import { useAuth } from "@contexts/AuthContext.tsx";

const BannerBoostProNotActive = () => {
    const { setSectionType } = useContext(UserBillingPageContext);
    const { proSubscriptionData: { proSubscription } } = useAuth()
    const isDesktop = useMediaquery('(min-width: 768px)');

    if (!isDesktop) {
        return (
            <div
                className='banner-boost-pro-billing'
            >
                <div className='banner-boost-pro-billing__content'>
                    <div className='banner-boost-pro-billing__main'>
                        <div>
                            <p className='banner-section-title'>
                                Boost PRO subscription
                            </p>

                            <article className='flex-start'>
                                <SuccessBoostProIcon />
                                <span className='banner-section-desc-text'>
                                    Customized ad strategies tailored to your goals
                                </span>
                            </article>
                            <article className='flex-start'>
                                <SuccessBoostProIcon />
                                <span className='banner-section-desc-text'>
                                    Continuous campaign monitoring and optimization
                                </span>

                            </article>
                            <article className='flex-start'>
                                <SuccessBoostProIcon />
                                <span className='banner-section-desc-text'>
                                    Landing pages and creatives production
                                </span>
                            </article>
                        </div>
                    </div>
                </div>
                <div className='banner-boost-pro-billing__main-cta-section'>
                    <Button
                        className="btn-gradient-pro"
                        size="large"
                        block
                        onClick={() => setSectionType('enable_pro')}
                    >
                        Enable PRO
                    </Button>
                    <div className='banner-boost-pro-billing__plug'>
                        <ul>
                            <li>
                                <span>
                                    Monthly fee – {proSubscription?.formattedPrice}
                                </span>
                            </li>
                            <li>
                                <span>
                                    If monthly spend more than {proSubscription?.formattedSpendForFree} – free                                    </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div
            className='banner-boost-pro-billing'
        >
            <div className='flex banner-boost-pro-billing__content'>
                <div className='banner-boost-pro-billing__main'>
                    <div>
                        <p className='banner-section-title'>
                            Boost PRO subscription
                        </p>

                        <article className='flex items-center'>
                            <SuccessBoostProIcon />
                            <span className='banner-section-desc-text'>
                                Customized ad strategies tailored to your goals
                            </span>
                        </article>
                        <article className='flex items-center'>
                            <SuccessBoostProIcon />
                            <span className='banner-section-desc-text'>
                                Continuous campaign monitoring and optimization
                            </span>

                        </article>
                        <article className='flex items-center'>
                            <SuccessBoostProIcon />
                            <span className='banner-section-desc-text'>
                                Landing pages and creatives production
                            </span>
                        </article>
                    </div>

                    <div className='banner-boost-pro-billing__main-cta-section'>
                        <Button
                            className="btn-gradient-pro"
                            size="large"
                            onClick={() => setSectionType('enable_pro')}
                        >
                            Enable PRO
                        </Button>
                        <div className='banner-boost-pro-billing__plug'>
                            <ul>
                                <li>
                                    <span>
                                        Monthly fee – {proSubscription?.formattedPrice}
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        If monthly spend more than {proSubscription?.formattedSpendForFree} – free                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <aside className='banner-boost-pro-billing__aside'>
                <CardsBoostProImage />
            </aside>
        </div>
    )
}

export default BannerBoostProNotActive