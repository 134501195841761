import React, { useEffect, useMemo } from 'react';
import { ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import { GetStatisticsMetricsResponse, StatisticsMetrics } from "@shared/statisticTypes.ts";
import { colorByRowData, formatXValue, formatY, LineData } from "@components/Analytics/helpers.ts";
import {
    idMapping,
    metricsTypes, SideValueTypes,
    statusMenuItemSelectedIcon,
    tagRender
} from "@components/Analytics/constants.tsx";
import { Select, Segmented } from "antd";
import { formatDateByGap } from "@shared/utils.ts";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api.ts";

interface CustomResponsiveLineProps {
    leftData: LineData[];
    rightData: LineData[];
    leftMax: number;
    rightMax: number;
    rightMin: number;
    leftMin: number;
    isEvent?: boolean;
    statisticsLeftValue: StatisticsMetrics[];
    statisticsRightValue: StatisticsMetrics[];
    setStatisticsLeftValue: (value: StatisticsMetrics[] | ((value: StatisticsMetrics[]) => StatisticsMetrics[])) => void
    setStatisticsRightValue: (value: StatisticsMetrics[] | ((value: StatisticsMetrics[]) => StatisticsMetrics[])) => void
    tickRotation: number;
    statisticsMetrics?: GetStatisticsMetricsResponse
    gap: string
    handleGapChange: (value: string) => void
    currenciesOptions: {
        value?: string;
        label: string;
    }[]
    currency?: string;
    setCurrency: (value: string) => void
}

export const customSliceTooltip = ({ props, data, currency, gap }: { props: SliceTooltipProps, data: LineData[], currency?: string, gap?: string }) => {
    const { slice } = props
    const xValue = slice?.points?.[0]?.data?.x.toString()
    const mappedData = data.map(({ id, data: pointsData }) => {
        const y = pointsData?.find(({ x }: { x: string }) => x.toString() === xValue)?.prevY || 0
        return (
            <div className="analytics-page__slice-tooltip__list-item" key={id}>
                <div style={{ background: colorByRowData({ id: id?.toString() }) }} className="status-plug" />
                <div>
                    {idMapping[id] || id}
                    :
                </div>
                {formatY({ y, type: metricsTypes[id], currency })}
            </div>
        )
    })
    return (
        <div
            className="analytics-page__slice-tooltip"
        >
            <span className="analytics-page__slice-tooltip__date">{formatDateByGap(xValue, gap)}</span>
            {mappedData}
        </div>
    );
}

const CustomResponsiveLine: React.FC<CustomResponsiveLineProps> = ({
    leftData,
    rightData,
    leftMax,
    rightMax,
    leftMin, rightMin,
    statisticsLeftValue,
    statisticsRightValue,
    tickRotation,
    setStatisticsLeftValue,
    setStatisticsRightValue,
    statisticsMetrics,
    gap,
    handleGapChange,
    currenciesOptions,
    currency,
    setCurrency,
    isEvent
}) => {
    const { data: gapsRes } = useGetFiltersByEnumQuery({ name: 'gap' })

    useEffect(() => {
        const id = gapsRes?.data?.[0]?.id
        if (id) {
            handleGapChange(id)
        }
    }, [gapsRes]);

    const setSideValuesToLS = (value: StatisticsMetrics[], type: string) => {
        localStorage.setItem(`analytics_side_value_${isEvent ? 'event' : 'partner'}_${type}`, JSON.stringify(value));
    }

    const statisticsMetricsOptions = useMemo(() => {
        const mappedStatisticsMetrics = statisticsMetrics?.data?.map((item) => ({ ...item, value: item.metric, label: item.translate })) || []
        const groups = [...new Set(mappedStatisticsMetrics?.map(({ type }) => type) || [])]
        return groups.map((group) => {
            const label = `${group.slice(0, 1).toUpperCase()}${group.slice(1)}`
            return {
                key: label,
                label: <span>{label}</span>,
                title: label,
                options: mappedStatisticsMetrics?.filter(({ type }) => type === group)
            }
        })
    }, [statisticsMetrics])

    const segmentedOptions = gapsRes?.data?.map(({ translate, id }) => ({ label: translate, value: id.toString() })) || []

    return (
        <>
            <div className='flex align-end'>
                <div className='mr-6'>
                    <div className='mb-6 text-neutral-8'>Left:</div>
                    <Select
                        mode="tags"
                        allowClear
                        tagRender={tagRender}
                        maxTagCount="responsive"
                        style={{ width: '250px' }}
                        // @ts-ignore
                        options={statisticsMetricsOptions}
                        value={statisticsLeftValue}
                        onDeselect={(deselected) => {
                            setStatisticsLeftValue((prev) => {
                                const newValue = prev.filter(({ metric }) => metric !== deselected as unknown as string)
                                setSideValuesToLS(newValue, SideValueTypes.Left)
                                return newValue
                            })
                        }}
                        onClear={() => {
                            setSideValuesToLS([], SideValueTypes.Left)
                            setStatisticsLeftValue([])
                        }}
                        onSelect={(_, selected: StatisticsMetrics) => {
                            setStatisticsLeftValue((prev) => {
                                const newValue = [...prev, selected].filter(({ type }) => type === selected.type)
                                setSideValuesToLS(newValue, SideValueTypes.Left)
                                return newValue
                            })
                            setStatisticsRightValue((prev) => {
                                const newValue = prev.filter(({ metric }) => metric !== selected.metric)
                                setSideValuesToLS(newValue, SideValueTypes.Right)
                                return newValue
                            })
                        }}
                        menuItemSelectedIcon={statusMenuItemSelectedIcon}
                        popupClassName='analytics__popup-select'
                        placeholder='Select left side'
                        fieldNames={{ value: 'metric', label: 'translate' }}
                    />
                </div>
                <div>
                    <div className='mb-6 text-neutral-8'>Right:</div>
                    <Select
                        mode="tags"
                        tagRender={tagRender}
                        allowClear
                        maxTagCount="responsive"
                        style={{ width: '250px' }}
                        // @ts-ignore
                        options={statisticsMetricsOptions}
                        value={statisticsRightValue}
                        onDeselect={(deselected) => {
                            setStatisticsRightValue((prev) => {
                                const newValue = prev.filter(({ metric }) => metric !== deselected as unknown as string)
                                setSideValuesToLS(newValue, SideValueTypes.Right)
                                return newValue
                            })
                        }}
                        onClear={() => {
                            setSideValuesToLS([], SideValueTypes.Right)
                            setStatisticsRightValue([])
                        }}
                        onSelect={(_, selected: StatisticsMetrics) => {
                            setStatisticsRightValue((prev) => {
                                const newValue = [...prev, selected].filter(({ type }) => type === selected.type)
                                setSideValuesToLS(newValue, SideValueTypes.Right)
                                return newValue
                            })
                            setStatisticsLeftValue((prev) => {
                                const newValue = prev.filter(({ metric }) => metric !== selected.metric)
                                setSideValuesToLS(newValue, SideValueTypes.Left)
                                return newValue
                            })
                        }}
                        menuItemSelectedIcon={statusMenuItemSelectedIcon}
                        popupClassName='analytics__popup-select'
                        placeholder='Select right side'
                        fieldNames={{ value: 'metric', label: 'translate' }}
                    />
                </div>
                <div className="ml-12">
                    <div className='mb-6 text-neutral-8'>Currency:</div>
                    <Select
                        style={{ width: '100px' }}
                        options={currenciesOptions}
                        value={currency}
                        onChange={setCurrency}
                        popupClassName='analytics__popup-select'
                        placeholder='Select currency'
                        disabled={!currenciesOptions.length}
                    />
                </div>
                <div className="ml-auto">
                    <Segmented
                        value={gap.toString()}
                        onChange={handleGapChange}
                        options={segmentedOptions}
                    />
                </div>
            </div>
            <div
                className="analytics__responsive-line"
            >
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <ResponsiveLine
                        data={leftData}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: 'point' }}
                        yScale={{
                            type: 'linear',
                            stacked: true,
                            reverse: false,
                            min: statisticsLeftValue.length ? 0 : 'auto',
                            max: statisticsLeftValue.length ? 1 : 'auto',
                        }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        isInteractive
                        sliceTooltip={(props) => customSliceTooltip({ props, data: [...leftData, ...rightData], currency, gap })}
                        axisBottom={{
                            tickPadding: 14,
                            tickRotation,
                            legendOffset: 36,
                            legendPosition: 'middle',
                            format: formatXValue
                        }}
                        axisLeft={statisticsLeftValue.length ? {
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendOffset: -40,
                            legendPosition: 'middle',
                            format: (value) => formatY({ y: value, type: statisticsLeftValue?.[0]?.type, currency, max: leftMax, min: leftMin })
                        } : undefined}
                        axisRight={statisticsRightValue?.length ? null : {
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendOffset: 40,
                            legendPosition: 'middle',
                            format: (value: string) => formatY({ y: value, type: statisticsLeftValue?.[0]?.type, currency, max: leftMax, min: leftMin })
                        }}
                        pointSize={10}
                        pointBorderWidth={2}
                        pointLabel="data.yFormatted"
                        pointLabelYOffset={-12}
                        enableTouchCrosshair
                        useMesh
                        crosshairType="cross"
                        legends={[]}
                        enableSlices="x"
                        colors={colorByRowData}
                        curve="monotoneX"
                        theme={{
                            crosshair: {
                                line: {
                                    stroke: 'blue',
                                    strokeWidth: 1,
                                    strokeOpacity: 0.75,
                                }
                            }
                        }}
                    />
                </div>
                {Boolean(statisticsRightValue?.length) && (
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <ResponsiveLine
                            data={rightData}
                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                            xScale={{ type: 'point' }}
                            yScale={{
                                type: 'linear',
                                stacked: true,
                                reverse: false,
                                min: 0,
                                max: 1
                            }}
                            yFormat=" >-.2f"
                            isInteractive
                            sliceTooltip={(props) => customSliceTooltip({ props, data: [...leftData, ...rightData], currency, gap })}
                            axisTop={null}
                            axisRight={{
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendOffset: 40,
                                legendPosition: 'middle',
                                format: (value) => {
                                    return formatY({ y: value, type: statisticsRightValue?.[0]?.type, currency, max: rightMax, min: rightMin })
                                }
                            }}
                            axisBottom={statisticsLeftValue?.length ? null : {
                                tickPadding: 14,
                                tickRotation,
                                legendOffset: 36,
                                legendPosition: 'middle',
                                format: formatXValue
                            }}
                            axisLeft={statisticsLeftValue?.length ? null : {
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendOffset: -40,
                                legendPosition: 'middle',
                                format: (value) => formatY({ y: value, type: statisticsRightValue?.[0]?.type, currency, max: rightMax, min: rightMin })
                            }}
                            pointSize={10}
                            pointBorderWidth={2}
                            pointLabel="data.yFormatted"
                            pointLabelYOffset={-12}
                            enableTouchCrosshair
                            useMesh
                            crosshairType="cross"
                            legends={[]}
                            enableSlices="x"
                            colors={colorByRowData}
                            curve="monotoneX"
                            theme={{
                                crosshair: {
                                    line: {
                                        stroke: 'red',
                                        strokeWidth: 1,
                                        strokeOpacity: 0.75,
                                    }
                                }
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default CustomResponsiveLine;
