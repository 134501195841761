import React from 'react'

const ZeroStateImage = () => {
    return (
        <svg width="266" height="200" viewBox="0 0 266 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="265" height="199" rx="11.5" fill="white" stroke="#E5E6E7" />
            <rect opacity="0.7" y="24" width="266" height="1" fill="#D9DADC" />
            <ellipse cx="19" cy="13" rx="5" ry="5" fill="#F2F3F5" />
            <ellipse cx="34" cy="13" rx="5" ry="5" fill="#F2F3F5" />
            <ellipse cx="49" cy="13" rx="5" ry="5" fill="#F2F3F5" />
            <path d="M132.003 112.003L123.376 120.545C114.852 128.986 110.59 133.207 111.783 136.848C111.886 137.161 112.013 137.466 112.164 137.759C113.921 141.169 119.948 141.169 132.003 141.169C144.057 141.169 150.085 141.169 151.841 137.759C151.992 137.466 152.119 137.161 152.222 136.848C153.415 133.207 149.153 128.986 140.629 120.545L132.003 112.003ZM132.003 112.003L140.629 103.46C149.153 95.0189 153.415 90.7983 152.222 87.1569C152.119 86.8439 151.992 86.5394 151.841 86.2463C150.085 82.8359 144.057 82.8359 132.003 82.8359C119.948 82.8359 113.921 82.8359 112.164 86.2463C112.013 86.5394 111.886 86.8439 111.783 87.1569C110.59 90.7983 114.852 95.0189 123.376 103.46L132.003 112.003Z" stroke="#EFF0F4" strokeWidth="2.5" />
            <path d="M126.164 93.0391H137.831" stroke="#EFF0F4" strokeWidth="2.5" strokeLinecap="round" />
            <path d="M126.164 130.961H137.831" stroke="#EFF0F4" strokeWidth="2.5" strokeLinecap="round" />
        </svg>
    )
}

export default ZeroStateImage