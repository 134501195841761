import React, { ChangeEvent, FC, useState } from 'react';
import { Button, Input, Modal, } from 'antd';
import WarningIcon from '@assets/WarningIcon';
import { SubdomainModalSteps } from './constants';

type Props = {
    isOpen: boolean
    onClose: () => void
    errorMsg: string
    setErrorMsg: (msg: string) => void
    subdomain: string
    onSubdomainChange: (e: ChangeEvent<HTMLInputElement>) => void
    onVerify: () => void
    isLoading: boolean
    eventName?: string
}

const EditSubdomainModal: FC<Props> = ({ isOpen, isLoading, onClose, errorMsg, setErrorMsg, onSubdomainChange, subdomain, onVerify, eventName }) => {
    const [step, setStep] = useState(SubdomainModalSteps.first)

    const handleClose = () => {
        onClose()
        setStep(SubdomainModalSteps.first)
        setErrorMsg('')
    }

    const handleOk = () => {
        if (step === SubdomainModalSteps.first) {
            setStep(SubdomainModalSteps.second)
        } else {
            onVerify()
            setStep(SubdomainModalSteps.first)
        }
    }

    return (
        <Modal
            title={step === SubdomainModalSteps.first ? (
                "Edit subdomain"
            ) : (
                <div className='flex items-center'>
                    <WarningIcon width={'24'} height={'24'} fillColor='#FFA940' />
                    <span className='ml-6'>Subdomain edit confirmation</span>
                </div>
            )}
            open={isOpen}
            onCancel={handleClose}
            className="edit-subdomain-modal"
            centered
            width={500}
            zIndex={1001}
            footer={(
                <div
                    className="edit-subdomain-modal__footer flex"
                >
                    <Button
                        type={step === SubdomainModalSteps.first ? "default" : "primary"}
                        size="large"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        disabled={isLoading || step === SubdomainModalSteps.first ? !subdomain : false}
                        type={step === SubdomainModalSteps.first ? "primary" : "default"}
                        loading={isLoading}
                        onClick={handleOk}
                    >
                        {step === SubdomainModalSteps.first ? 'Save' : 'Agree'}
                    </Button>
                </div>
            )}
        >
            {step === SubdomainModalSteps.first ? (
                <>
                    <div className='text-regular-14 label text-neutral-8 mb-4'>New subdomain</div>
                    <Input
                        id="subdomain"
                        name="subdomain"
                        placeholder="subdomain.event.com"
                        value={subdomain}
                        onChange={onSubdomainChange}
                        status={errorMsg && 'error'}
                    />
                    {errorMsg && (
                        <div className='text-regular-14 label mb-4 error'>{errorMsg}</div>
                    )}
                </>
            ) : (
                <span>
                    Are you sure you want to edit your subdomain? By changing your subdomain, all
                    <b> {eventName || ''} </b>
                    campaigns will be stopped and sent for moderation
                </span>
            )}
        </Modal>
    )
}

export default EditSubdomainModal;
