
const ThreeDotsVButton = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#4E67EA" />
            <path d="M11.6457 17.1667C11.0013 17.1667 10.479 16.6443 10.479 16C10.479 15.3557 11.0013 14.8333 11.6457 14.8333C12.29 14.8333 12.8123 15.3557 12.8123 16C12.8123 16.6443 12.29 17.1667 11.6457 17.1667Z" fill="#4E67EA" />
            <path d="M16.0012 17.1667C15.3569 17.1667 14.8345 16.6443 14.8345 16C14.8345 15.3557 15.3569 14.8333 16.0012 14.8333C16.6455 14.8333 17.1679 15.3557 17.1679 16C17.1679 16.6443 16.6455 17.1667 16.0012 17.1667Z" fill="#4E67EA" />
            <path d="M20.3568 17.1667C19.7124 17.1667 19.1901 16.6443 19.1901 16C19.1901 15.3557 19.7124 14.8333 20.3568 14.8333C21.0011 14.8333 21.5234 15.3557 21.5234 16C21.5234 16.6443 21.0011 17.1667 20.3568 17.1667Z" fill="#4E67EA" />
        </svg>
    )
}

export default ThreeDotsVButton