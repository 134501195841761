import { message } from 'antd';
import { createContext, useState, ReactNode, useContext } from 'react';
import { campaignInitialAds, campaignInitialForm } from '@pages/PartnerRolePages/Campaigns/CampaignEditorPage/constants';
import { CampaignEditorBody, CampaignEditorResponse } from '@store/type-partner/campaigns/models';
import { ExitPageContext } from "@contexts/ExitPageContext.tsx";
import { ErrorDataResponse } from '@shared/utils';
import { AdAsset } from '@store/main/organization/models';
import { usePatchCampaignDataByIdMutation, usePostCreateCampaignByEventIdMutation } from '@store/type-partner/campaigns/campaigns.api';
import { errorsToFormAdapter } from '@shared/utils';
import { partnerCampaignsActions } from '@store/type-partner/campaigns/campaigns.slice';
import { GTMEventName } from '@hooks/sendAnalytics/constants';
import { events } from '@pages/routes';
import sendAnalytics from '@hooks/sendAnalytics/sendAnalytics';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const filterForValues = (list: { value?: string }[] | AdAsset[]): AdAsset[] => {
    if (!list || !list.length) return []
    return (list as AdAsset[]).filter(item => item.value !== undefined);
};

interface CampaignEditorContextType {
    successNotification: ({ text }: { text: string }) => void
    errorNotification: ({ text }: { text: string }) => void

    formErrors: Record<string, string>;
    setFormErrors: (errors: Record<string, string>) => void;

    formValues: CampaignEditorBody;
    setFormValues: React.Dispatch<React.SetStateAction<CampaignEditorBody>>;
    handleFormChange: (form: CampaignEditorBody) => void;
    isLoading: boolean;
    setLoading: (isLoading: boolean) => void;
    isLogoLoading: boolean;
    setLogoLoading: (isLoading: boolean) => void;
    isImagesLoading: boolean;
    setImagesLoading: (isLoading: boolean) => void;
    isSaving: boolean;
    setSaving: (isLoading: boolean) => void;

    updateInitialFormHandler: (response: CampaignEditorResponse) => void;
    onTitlesChange: ({ idx, value }: { idx: number, value: string }) => void;
    onAddTitle: () => void;
    onDeleteTitle: (idx: number) => void
    onTitlesShortChange: ({ idx, value }: { idx: number, value: string }) => void;
    onAddTitleShort: () => void;
    onDeleteTitleShort: (idx: number) => void;
    updateImagesListHandler: (url: string) => void;
    onDeleteImage: (idx: number) => void;
    updateLogosListHandler: (url: string) => void;
    onDeleteLogo: (idx: number) => void;
    onAssetSingleItemChange: (
        { key, value }
            :
            { key: 'business_name' | 'description', value: string }
    ) => void

    campaignStatus: { id: number, name: string };
    setCampaignStatus: (data: { id: number, name: string }) => void

    onSubmit: ({ strict_validation, eventId, campaignId, isIdToQuery }: { strict_validation: boolean, eventId: string, campaignId: string | undefined, isIdToQuery?: boolean, redirectUrl?: string }) => void
}

export const CampaignEditorContext = createContext<CampaignEditorContextType>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    successNotification: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    errorNotification: () => { },
    formErrors: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setFormErrors: () => { },
    formValues: campaignInitialForm,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setFormValues: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleFormChange: () => { },
    isLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLoading: () => { },
    isLogoLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLogoLoading: () => { },
    isImagesLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setImagesLoading: () => { },

    isSaving: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSaving: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateInitialFormHandler: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTitlesChange: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAddTitle: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDeleteTitle: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTitlesShortChange: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAddTitleShort: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDeleteTitleShort: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateImagesListHandler: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDeleteImage: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateLogosListHandler: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDeleteLogo: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAssetSingleItemChange: () => { },

    campaignStatus: {
        id: 0,
        name: ''
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCampaignStatus: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => { }
});

interface CampaignEditorProviderProps {
    children: ReactNode;
}

export const CampaignEditorProvider: React.FC<CampaignEditorProviderProps> = ({ children }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { eventId, campaignId } = useParams()

    const [messageApi, contextHolder] = message.useMessage();
    const [formValues, setFormValues] = useState<CampaignEditorBody>(campaignInitialForm)
    const [formErrors, setFormErrors] = useState({})
    // LOADERS
    const [isLoading, setLoading] = useState(false)
    const [isLogoLoading, setLogoLoading] = useState(false)
    const [isImagesLoading, setImagesLoading] = useState(false)
    const [isSaving, setSaving] = useState(false)

    // FORM DESTRUCTURE
    const titles = formValues.ads[0]?.assets?.title || []
    const shortTitles = formValues.ads[0]?.assets?.short_title || []
    // const imagesList = formValues.ads[0]?.assets?.image_original || []
    // const logosList = formValues.ads[0]?.assets?.logo_original || []
    // const businessNames = formValues.ads[0]?.assets?.business_name || []

    const [onCreateCampaign] = usePostCreateCampaignByEventIdMutation()
    const [onUpdateCampaign] = usePatchCampaignDataByIdMutation()


    const {
        isExitPageEnabled,
        setIsExitPageEnabled,
    } = useContext(ExitPageContext);

    const [campaignStatus, setCampaignStatus] = useState({
        id: 0,
        name: ''
    })
    // NOTIFICATION
    const successNotification = ({ text }: { text: string }) => {
        void messageApi.open({
            type: 'success',
            content: text,
        });
    };

    const errorNotification = ({ text }: { text: string }) => {
        void messageApi.open({
            type: 'error',
            content: text,
        });
    };
    //****/

    const handleFormChange = (fields: CampaignEditorBody, isInit?: boolean) => {
        if (!isExitPageEnabled && !isInit) {
            setIsExitPageEnabled(true)
        }
        setFormErrors({})
        setFormValues({ ...formValues, ...fields });
        dispatch(partnerCampaignsActions.setCampaignsEditorData({
            campaignEditor: {
                ...formValues, ...fields,
                event_id: eventId || '',
                campaign_id: campaignId || ''
            },
            selectedEvent: (formValues?.event_id as string) || eventId || '',
            campaignId: (formValues?.campaign_id as string) || campaignId || ''
        }))
    };


    const updateInitialFormHandler = (response: CampaignEditorResponse) => {

        const { data } = response;
        const { channels, ads, audiences } = data;
        setCampaignStatus({ id: +data.status.id, name: data.status.name })
        const {
            assets = {
                title: "",
                short_title: "",
                description: "",
                image_original: "",
                logo_original: "",
                business_name: ""
            }
        } = ads.length ? ads[0] : {};

        const {
            title: adTitle,
            short_title: adShortTitle,
            description: adDescription,
            image_original: adImageOriginal,
            logo_original: adLogoOriginal,
            business_name: adBusinessName,
        } = assets;

        const updatedChannels = channels.map(ch => ({
            id: ch.id || null,
            type: +ch.type.id,
            bid: +ch.bid,
            title: ch.title,
            budget: +ch.budget || 0,
            status: +ch.status?.id || 1
        }));

        const title = adTitle || campaignInitialAds[0].assets.title;
        const short_title = adShortTitle || campaignInitialAds[0].assets.short_title;
        const description = adDescription || campaignInitialAds[0].assets.description;
        const image_original = adImageOriginal || campaignInitialAds[0].assets.image_original;
        const logo_original = adLogoOriginal || campaignInitialAds[0].assets.logo_original;
        const business_name = adBusinessName || campaignInitialAds[0].assets.business_name;
        handleFormChange({
            ...campaignInitialForm,
            ...data,
            title: data?.title || 'Default Campaign Title',
            audiences: audiences ? audiences.map(item => +item.id) : [],
            goal: +data.goal.id,
            channels: updatedChannels,
            ads: [
                {
                    landing: ads[0]?.landing?.id ? ads[0].landing.id : '',
                    id: ads[0]?.id || '',
                    assets: {
                        title: filterForValues(title as AdAsset[]),
                        short_title: filterForValues(short_title as AdAsset[]),
                        description: filterForValues(description as AdAsset[]),
                        image_original: filterForValues(image_original as AdAsset[]),
                        logo_original: filterForValues(logo_original as AdAsset[]),
                        business_name: filterForValues(business_name as AdAsset[])
                    }
                }
            ]
        }, true);
    };

    // FORM HANDLERS
    const onTitlesChange = ({ idx, value }: { idx: number, value: string }) => {
        const titles = formValues.ads[0].assets.title;

        const actualItem = { ...titles[idx], value }; // Create a new object with the updated value
        const updatedTitles = [...titles.slice(0, idx), actualItem, ...titles.slice(idx + 1)]; // Update the array of titles

        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        title: updatedTitles
                    }
                }
            ]
        };
        handleFormChange(next);
    };


    const onAddTitle = () => {
        const titles = [...formValues.ads[0].assets.title]; // Create a new array to avoid mutating the original
        titles.push({ id: null, value: '' }); // Push a new empty AdAsset object
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        title: titles
                    }
                }
            ]
        };

        handleFormChange(next);
    };

    const onDeleteTitle = (idx: number) => {
        const titlesList = titles.filter((_, index) => index !== idx);
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        title: titlesList
                    }
                }
            ]
        };
        handleFormChange(next);
    }


    const onTitlesShortChange = ({ idx, value }: { idx: number, value: string }) => {
        const short_titles = formValues.ads[0].assets.short_title;

        const actualItem = { ...short_titles[idx], value }; // Create a new object with the updated value
        const updatedTitles = [...short_titles.slice(0, idx), actualItem, ...short_titles.slice(idx + 1)]; // Update the array of short_titles

        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        short_title: updatedTitles
                    }
                }
            ]
        };
        handleFormChange(next);

    }

    const onAddTitleShort = () => {
        const short_title = [...formValues.ads[0].assets.short_title]; // Create a new array to avoid mutating the original
        short_title.push({ value: '' }); // Push a new empty AdAsset object
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        short_title
                    }
                }
            ]
        };

        handleFormChange(next);

    }
    const onDeleteTitleShort = (idx: number) => {
        const titlesList = shortTitles.filter((_, index) => index !== idx);
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        short_title: titlesList
                    }
                }
            ]
        };
        handleFormChange(next);
    }

    const updateImagesListHandler = (url: string) => {
        const imagesList = formValues.ads[0].assets.image_original;
        const nextImages = [...imagesList, { id: null, value: url }]
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        image_original: nextImages
                    }
                }
            ]
        };
        handleFormChange(next);
    }

    const onDeleteImage = (idx: number) => {
        const imagesList = formValues.ads[0].assets.image_original;
        const imagesNext = imagesList.filter((_, index) => index !== idx);
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        image_original: imagesNext
                    }
                }
            ]
        };
        handleFormChange(next);
    }
    const updateLogosListHandler = (url: string) => {
        const logosList = formValues.ads[0].assets.logo_original;
        const nextLogos = [...logosList, { id: null, value: url }]
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        logo_original: nextLogos
                    }
                }
            ]
        };
        handleFormChange(next);
    }

    const onDeleteLogo = (idx: number) => {
        const logosList = formValues.ads[0].assets.logo_original;
        const logosNext = logosList.filter((_, index) => index !== idx);
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        logo_original: logosNext
                    }
                }
            ]
        };
        handleFormChange(next);
    }

    const onAssetSingleItemChange = (
        { key, value }
            :
            { key: 'business_name' | 'description', value: string }
    ) => {
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    assets: {
                        ...formValues.ads[0].assets,
                        [key]: [
                            {
                                ...formValues.ads[0].assets[key][0],
                                value: value
                            }
                        ]
                    }
                },
                ...formValues.ads.slice(1) // Keep other ads unchanged
            ]
        };
        handleFormChange(next);
    };

    const onSubmit = async ({
        strict_validation,
        eventId,
        campaignId,
        redirectUrl,
        isIdToQuery
    }: {
        strict_validation: boolean,
        eventId: string,
        campaignId: string | undefined,
        redirectUrl?: string,
        isIdToQuery?: boolean
    }) => {
        const { ads, ...rest } = formValues
        setSaving(true)

        const assets = {
            title: filterForValues(ads[0]?.assets.title),
            short_title: filterForValues(ads[0].assets.short_title),
            description: filterForValues(ads[0].assets.description),
            image_original: filterForValues(ads[0].assets.image_original),
            logo_original: filterForValues(ads[0].assets.logo_original),
            business_name: filterForValues(ads[0].assets.business_name),
        }

        const payload: CampaignEditorBody = {
            ...rest,
            campaign_id: campaignId || formValues.campaign_id,
            strict_validation,
            ads: [{
                landing: ads[0].landing,
                assets,
                id: ads[0]?.id || ''
            }],
            event_id: eventId as unknown as string
        }
        try {
            const response = payload.campaign_id ?
                await onUpdateCampaign({
                    ...formValues,
                    event_id: eventId as unknown as string,
                    campaign_id: payload.campaign_id,
                    strict_validation
                })
                :
                await onCreateCampaign(payload);

            if ('error' in response) {
                setSaving(false)
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                errors && setFormErrors(errors);
                void message.error((response as ErrorDataResponse).error.data.message)

            } else {
                dispatch(partnerCampaignsActions.setTemporaryEditorData(null))
                if (isExitPageEnabled) {
                    setIsExitPageEnabled(false)
                }
                sendAnalytics(GTMEventName.saveCampaign)
                setSaving(false)
                if (!campaignId) {
                    dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: null, selectedEvent: null, campaignId: '' }))
                }

                if (redirectUrl) {
                    if (isIdToQuery) {
                        return navigate(`${redirectUrl}?campaignId=${response.data.data.id}`)
                    }
                    return navigate(redirectUrl)
                }

                return navigate(`/campaigns`)
            }
        } catch (error) {
            if (!campaignId) {
                dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: formValues, selectedEvent: eventId as unknown as string, campaignId }))
            }
            setSaving(false)
            void message.error((error as ErrorDataResponse).error.data.message)
        }
    }


    return (
        <CampaignEditorContext.Provider value={
            {
                successNotification,
                errorNotification,
                formErrors,
                setFormErrors,

                handleFormChange,
                formValues,
                setFormValues,
                // LOADERS
                isLoading,
                setLoading,
                isSaving,
                setSaving,
                isLogoLoading,
                setLogoLoading,
                isImagesLoading,
                setImagesLoading,

                updateInitialFormHandler,
                // FORM
                onTitlesChange,
                onAddTitle,
                onDeleteTitle,
                onTitlesShortChange,
                onAddTitleShort,
                onDeleteTitleShort,
                updateImagesListHandler,
                onDeleteImage,
                updateLogosListHandler,
                onDeleteLogo,
                onAssetSingleItemChange,

                campaignStatus,
                setCampaignStatus,
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit
            }}>
            <>
                {contextHolder}
                {children}
            </>
        </CampaignEditorContext.Provider>
    );
};
