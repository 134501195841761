import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CampaignEditorEnum } from '@store/main/organization/models';
import { CampaignEditorBody } from './models';

interface CampaignsState {
    campaignEditorsData: Record<string, {
        campaignEditor: CampaignEditorBody | null;
        selectedEvent: string | null | undefined;
    }>
    temporaryEditorData: CampaignEditorBody | null
}

const initialState: CampaignsState = {
    campaignEditorsData: {},
    temporaryEditorData: null
};

export const partnerCampaignsSlice = createSlice({
    name: 'partner_campaigns',
    initialState,
    reducers: {

        setCampaignsEditorData: (
            state,
            action: PayloadAction<{ campaignEditor: CampaignEditorBody | null, selectedEvent?: string | null | undefined, campaignId?: string }>
        ) => {
            const { campaignEditor, selectedEvent } = action.payload
            const campaignId = action.payload?.campaignId || ''
            const key = campaignId || CampaignEditorEnum.new
            state.campaignEditorsData = { ...state.campaignEditorsData, [key]: { campaignEditor, selectedEvent } };
        },
        setTemporaryEditorData: (
            state,
            action: PayloadAction<CampaignEditorBody | null>
        ) => {
            state.temporaryEditorData = action.payload
        }
    },
});

export const partnerCampaignsActions = partnerCampaignsSlice.actions;
export const partnerCampaignsReducer = partnerCampaignsSlice.reducer;

export default partnerCampaignsSlice.reducer;
