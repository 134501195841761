import React from 'react'

const MetaAdsIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#F2F3F5" />
            <path d="M13.18 26.914C13.18 27.9646 13.4113 28.7678 13.7136 29.2549C13.8747 29.5322 14.1067 29.7621 14.386 29.9213C14.6653 30.0805 14.982 30.1633 15.3039 30.1613C16.0812 30.1613 16.7924 29.9703 18.1628 28.083C19.2607 26.5711 20.5544 24.4537 21.4258 23.1166L22.8999 20.8625C23.9239 19.2972 25.1072 17.557 26.468 16.3736C27.5765 15.4147 28.7732 14.8799 29.9777 14.8799C31.9998 14.8799 33.9259 16.046 35.4 18.2332C37.0132 20.6286 37.7992 23.6457 37.7992 26.7593C37.7992 28.6102 37.4326 29.9703 36.8088 31.0447C36.2071 32.0839 35.0286 33.122 33.0525 33.122V30.1613C34.7445 30.1613 35.1639 28.614 35.1639 26.8433C35.1639 24.32 34.5727 21.5196 33.2704 19.5187C32.3462 18.0995 31.1485 17.2323 29.8308 17.2323C28.4057 17.2323 27.2588 18.302 25.97 20.2093C25.2847 21.2226 24.5813 22.4575 23.7914 23.851L22.922 25.3792C21.1753 28.4612 20.7329 29.1632 19.8596 30.3217C18.3317 32.358 17.0246 33.122 15.3039 33.122C13.2626 33.122 11.9718 32.2424 11.1772 30.9168C10.5198 29.8365 10.1992 28.4192 10.1992 26.8041L13.18 26.914Z" fill="#0081FB" />
            <path d="M12.5508 18.4414C13.9174 16.345 15.8896 14.8789 18.1516 14.8789C19.4616 14.8789 20.7639 15.2609 22.1238 16.3698C23.6113 17.578 25.1948 19.5674 27.1747 22.8462L27.8839 24.0229C29.596 26.8614 30.5711 28.3208 31.1402 29.0104C31.8734 29.8948 32.3878 30.1565 33.0538 30.1565C34.7458 30.1565 35.1651 28.6092 35.1651 26.8385L37.7947 26.7564C37.7947 28.6073 37.4281 29.9674 36.8043 31.0418C36.2083 32.0829 35.0298 33.1211 33.0538 33.1211C31.8254 33.1211 30.7371 32.8556 29.5336 31.7257C28.6085 30.8585 27.5269 29.3179 26.6948 27.933L24.2198 23.8185C22.9779 21.7536 21.8388 20.214 21.1794 19.5206C20.4693 18.7671 19.5585 17.8616 18.1036 17.8616C16.9261 17.8616 15.926 18.684 15.0892 19.9418L12.5508 18.4414Z" fill="url(#paint0_linear_19781_68110)" />
            <path d="M18.1023 17.8626C16.9248 17.8626 15.9248 18.6849 15.0879 19.9428C13.9046 21.7202 13.18 24.3677 13.18 26.914C13.18 27.9646 13.4113 28.7678 13.7136 29.2549L11.1724 30.9167C10.5198 29.8365 10.1992 28.4192 10.1992 26.8041C10.1992 23.8673 11.0092 20.8062 12.5495 18.4424C13.9161 16.3459 15.8883 14.8799 18.1503 14.8799L18.1023 17.8626Z" fill="url(#paint1_linear_19781_68110)" />
            <defs>
                <linearGradient id="paint0_linear_19781_68110" x1="16.1832" y1="23.5272" x2="35.1848" y2="24.4915" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0064E1" />
                    <stop offset="0.4" stop-color="#0064E1" />
                    <stop offset="0.83" stop-color="#0073EE" />
                    <stop offset="1" stop-color="#0082FB" />
                </linearGradient>
                <linearGradient id="paint1_linear_19781_68110" x1="14.1743" y1="28.1556" x2="14.1743" y2="21.1835" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0082FB" />
                    <stop offset="1" stop-color="#0064E0" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default MetaAdsIcon