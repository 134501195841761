import React, { useState } from "react";
import { AudienceItem } from "@store/type-event/audiences/models.ts";
import { MAX_DESCRIPTION_LENGTH } from "@pages/EventRolePages/AudiencesEventPage/constants.ts";

const NameColumn = ({ title, description }: AudienceItem['name']) => {
    const [isHidden, setIsHidden] = useState(true)
    return (
        <>
            <div className="audiences-event-page__audiences__name">
                {title}
            </div>
            {description?.length > MAX_DESCRIPTION_LENGTH && isHidden ? (
                <>
                    <div className="audiences-event-page__audiences__descr">
                        {`${description.slice(0, MAX_DESCRIPTION_LENGTH)}...`}
                    </div>
                    {isHidden && (
                        <div onClick={() => setIsHidden(false)} className="audiences-event-page__audiences__link text-link cursor-pointer">
                            {' '}read more
                        </div>
                    )}
                </>
            ) : (
                <div className="audiences-event-page__audiences__descr">
                    {description}
                </div>
            )}
        </>

    )
}

export default NameColumn