import React, { useEffect, useState } from "react";
import { Button, Form, Input, Space, message, notification } from "antd";
import styles from "./UserProfile.module.scss";
import { PhoneInput } from "react-international-phone";
import ChangingPasswordModal from "@pages/CommonPages/UserProfile/components/ChangingPasswordModal";
import { usePatchUserProfileMutation } from "@store/main/-user/user.api";
import { UserProfileUpdate } from "@store/models-to replace/auth";
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';
import { userActions } from "@store/main/-user/user.slice";
import { useAppSelector } from "@hooks/redux";
import { useDispatch } from "react-redux";
import { useAuth } from "@contexts/AuthContext.tsx";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import usePageAnalytics from "@hooks/sendAnalytics/usePageAnalytics";
import { PhoneNumberUtil } from 'google-libphonenumber';
import './styles.scss'

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

const UserProfile: React.FC = () => {
    usePageAnalytics('profile');

    const { user, isUserLoading } = useAppSelector(state => state.userAuth)
    const { reFetchUser } = useAuth();
    const dispatch = useDispatch()
    const [patchUser] = usePatchUserProfileMutation();
    const [isLoading, setLoading] = useState(false)

    const [formState, setFormState] = useState<UserProfileUpdate>({
        name: user?.name || '',
        email: user?.email || '',
        phone: user?.phone || '',
    });
    const [errors, setErrors] = useState<UserProfileUpdate>({
        name: '',
        email: '',
        phone: '',
    });
    const isValidPhone = isPhoneValid(formState?.phone || '');

    useEffect(() => {
        setFormState({
            name: user?.name || '',
            email: user?.email || '',
            phone: user?.phone || '',
        })
    }, [user])

    const [openPasswordModal, setOpenPasswordModal] = useState(false);

    const onChangeFieldValue = (fieldName: keyof UserProfileUpdate, value: string) => {
        setErrors({})
        setFormState(prevState => ({ ...prevState, [fieldName]: value }))
    }

    const onSubmitForm = async () => {
        if (!isValidPhone) {
            setErrors(prevState => ({ ...prevState, phone: 'Invalid phone number' }))
            return
        }
        setLoading(true)
        try {
            const response = await patchUser({
                ...formState
            })
            if ('error' in response) {
                setLoading(false)
                const err = errorsToFormAdapter(response as ErrorDataResponse)
                setErrors(err as UserProfileUpdate)
                const errorValues = Object.values(err)
                if (!errorValues.length) {
                    return void message.open({
                        type: 'error',
                        content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Update error',
                    });
                }
                return
            }
            if (response) {
                sendAnalytics(GTMEventName.changeProfile)
                setLoading(false)
                dispatch(userActions.patchUserState({
                    ...user,
                    ...formState
                }))
                notification.open({
                    message: (
                        <span>
                            User settings updated
                        </span>
                    ),
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success',
                });
            }
        } catch (error) {
            setLoading(false)
            return void message.open({
                type: 'error',
                content: 'User update error',
            });
        }
    }

    return (
        <>
            <div className={`${styles.UserProfile} user-profile-settings`}>
                <div className={styles.UserProfile__content}>
                    <section>
                        <Form
                            layout="vertical"
                        >
                            <Form.Item
                                label={<span className={styles.UserProfile__fieldName}>Name</span>}
                                htmlFor="userProfileName"
                                className={styles.UserProfile__formItem}
                                help={<span>{errors?.name ? errors?.name : ''}</span>}
                                validateStatus={errors?.name ? 'error' : ''}
                            >
                                <Input
                                    id="userProfileName"
                                    value={formState.name}
                                    size="large"
                                    onChange={(e) => onChangeFieldValue('name', e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<span className={styles.UserProfile__fieldName}>E-mail</span>}
                                htmlFor="userProfileEmail"
                                className={styles.UserProfile__formItem}
                                help={<span>{errors?.email ? errors?.email : ''}</span>}
                                validateStatus={errors?.email ? 'error' : ''}
                            >
                                <Input
                                    id="userProfileEmail"
                                    type="email"
                                    size="large"
                                    value={formState.email}
                                    disabled
                                />
                            </Form.Item>
                            <Form.Item
                                label={<span className={styles.UserProfile__fieldName}>Password</span>}
                                htmlFor="userProfilePassword"
                                className={styles.UserProfile__formItem}
                            >
                                <Space.Compact style={{ width: '100%' }}>
                                    <Input
                                        id="userProfilePassword"
                                        type="password"
                                        size="large"
                                        value="****************"
                                        disabled
                                    />
                                    <Button
                                        size="large"
                                        onClick={() => setOpenPasswordModal(true)}
                                    >
                                        Change
                                    </Button>
                                </Space.Compact>
                            </Form.Item>
                            <Form.Item
                                label={<span className={styles.UserProfile__fieldName}>Phone</span>}
                                className={styles.UserProfile__phoneItem}
                                help={<span>{errors?.phone ? errors?.phone : ''}</span>}
                                validateStatus={errors?.phone ? 'error' : ''}
                            >
                                <PhoneInput
                                    value={formState.phone}
                                    style={{
                                        border: errors?.phone ? '1px solid #FF4D4F' : '1px solid #d9d9d9',
                                        borderRadius: '4px'
                                    }}
                                    inputClassName={styles.UserProfile__phone}
                                    countrySelectorStyleProps={{ buttonClassName: `${styles.UserProfile__phoneFlag}` }}
                                    defaultCountry="us"
                                    onChange={(value) => onChangeFieldValue('phone', value)}
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                size="large"
                                className={styles.UserProfile__submitButton}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={onSubmitForm}
                                disabled={isUserLoading || isLoading}
                                loading={isUserLoading || isLoading}
                            >
                                Save
                            </Button>
                        </Form>
                    </section>
                </div>
            </div>
            <ChangingPasswordModal
                isOpen={openPasswordModal}
                onClose={setOpenPasswordModal}
                reFetchUser={reFetchUser}
            />
        </>
    )
}

export default UserProfile;
