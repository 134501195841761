import React, {FC, PropsWithChildren, useContext, useEffect, useRef, useState} from 'react'
import SideBarMenu from '@components/SideBarMenu'
import SettingsHeader from '@components/SettingsHeader/SettingsHeader'

import './styles.scss'
import { useLocation, useParams } from 'react-router-dom'
import {
    analytics,
    audiences,
    campaigns,
    campaigns_create,
    events,
    events_create,
    events_setup,
    leads,
    moderation,
    notifications,
    partners,
    pro,
    profile,
    settings,
} from '@pages/routes'
import HeaderSimple from '@components/Header/HeaderSimple'
import {useAuth} from "@contexts/AuthContext.tsx";
import EmailConfirmationAlert from '@components/EmailConfirmation/EmailConfirmationAlert';
import {useBreakpoints} from '@hooks/browser'
import SideBarMenuDrawer from '@components/mobile/SideBarMenuDrawer'
import {HeaderMainMobile} from '@components/mobile/HeaderMainMobile'
import {TourContext} from "@contexts/TourContext.tsx";
import {descriptionsEventMobile, EventsTourStepsMobile, TourStages} from "@components/TourComponent/constants.tsx";
import NewTourComponent from "@components/TourComponent/NewTourComponent.tsx";
import {setMenuTooltipCoordinates} from "@components/TourComponent/helpers.ts";

interface Props extends PropsWithChildren {
    className?: string
    mobileHeaderSideBar?: React.ReactNode
    isProHidden?: boolean;
    customHeaderActions?: React.ReactNode;
}

const SettingsHeaderInner = () => {
    const { user } = useAuth()
    const { pathname } = useLocation()
    const { tabId, billingId = '', audienceId } = useParams()

    switch (pathname) {
        case profile:
            return <HeaderSimple title="profile" />
        // EVENT TYPE
        case campaigns_create:
            // case campaigns_create + `/${eventId}`:
            return <HeaderSimple title="campaigns_create" />

        // PARTNER TYPE
        case campaigns:
            return <HeaderSimple title='campaigns' />
        case leads:
            return <HeaderSimple title='leads' />

        case events_setup:
            return null
        case events:
            // todo remove after demo
            if (user?.id === 81) {
                return (
                    <HeaderSimple
                        title='events_demo'
                    />
                )
            }
            return <HeaderSimple title='my_events' />
        case events_create:
            return <HeaderSimple title='events' />

        case partners:
            return <HeaderSimple title='partners' />
        case analytics:
            return <HeaderSimple title='analytics' />
        case moderation:
            return <HeaderSimple title='moderation' />
        case audiences:
        case `${audiences}/${audienceId || ''}`:
            return <HeaderSimple title='audiences' />
        case notifications:
            return <HeaderSimple title='notifications' />
        case settings:
        case `${settings}/${tabId || ''}`:
        case `${settings}/${tabId || ''}/${billingId || ''}`:
            return <HeaderSimple title='settings' />
        case pro:
            return <HeaderSimple title='pro' />
        default:
            break;
    }
}
const EventsPageLayout: FC<Props> = ({
    children,
    className,
    mobileHeaderSideBar,
    isProHidden,
    customHeaderActions
}) => {
    const isDesktop = useBreakpoints().md;
    const [isOpenMenu, setOpenMenu] = useState(false)

    const {
        tourStage,
        onFinishTour,
        tourStep,
        setTourStep,
        isInfoTourOpen
    } = useContext(TourContext);

    useEffect(() => {
        if (isInfoTourOpen) {
            setOpenMenu(true)
        }
    }, [isInfoTourOpen]);

    const menuItemRef = useRef(null);

    const { user } = useAuth()
    const emailConfirmed = user?.email_verified_at

    useEffect(() => {
        if ((tourStep === EventsTourStepsMobile.menuItem) && tourStage === TourStages.event && !isDesktop) {
            setMenuTooltipCoordinates('header-main-tour-component', !isDesktop)
        }
    }, [tourStep, tourStage, isDesktop, menuItemRef]);

    if (!isDesktop) {
        return (
            <>
                <SideBarMenuDrawer
                    open={isOpenMenu}
                    onClose={() => setOpenMenu(false)}
                />
                <HeaderMainMobile
                    setOpenMenu={() => setOpenMenu(true)}
                    setOpenNotifications={() => null}
                    mobileHeaderSideBar={mobileHeaderSideBar}
                    menuItemRef={menuItemRef}
                />
                {!emailConfirmed
                    ?
                    <EmailConfirmationAlert />
                    :
                    null}
                <div className={`pagelayout-content-mobile ${className || ''}`}>
                    {children}
                </div>
                {(tourStep === EventsTourStepsMobile.menuItem) && tourStage === TourStages.event
                    ?
                    <NewTourComponent
                        id="header-main-tour-component"
                        isArrowTopLeft={isDesktop}
                        isArrowLeftTop={!isDesktop}
                        open={(tourStep === EventsTourStepsMobile.menuItem) && tourStage === TourStages.event}
                        onClose={() => {
                            onFinishTour()
                        }}
                        withBack
                        onBack={(step) => {
                            setTourStep(step)
                        }}
                        onChange={(step) => {
                            setTourStep(step)
                            setOpenMenu(true)
                        }}
                        current={tourStep}
                        totalCount={8}
                        description={descriptionsEventMobile[EventsTourStepsMobile.menuItem]}
                    />
                    :
                    null}
            </>
        )
    }
    return (
        <>
            <SideBarMenu />
            <SettingsHeader
                isProHidden={isProHidden}
                customHeaderActions={customHeaderActions}
            >
                <SettingsHeaderInner />
            </SettingsHeader>
            {!user?.email_verified_at
                ?
                <EmailConfirmationAlert />
                :
                null}
            <div className={`pagelayout-content events-page ${className || ''}`}>
                {children}
            </div>
        </>
    )
}

export default EventsPageLayout
