import React from 'react'
import ZeroStateImage from './ZeroStateImage'
import './styles.scss'

const ZeroState = () => {
    return (
        <div className='event-creative-template-content__zero-state'>
            <ZeroStateImage />
            <span className='text-regular-14'>
                <strong>Your template in progress</strong>
            </span>
            <span className='text-regular-14'>
                We will notify via e-mail when it is ready
            </span>
        </div>
    )
}

export default ZeroState