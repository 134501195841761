import EventsPageLayout from '@layouts/EventsPageLayout'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import CampaignForm from '../components/CampaignForm';
import CustomButton from '@components/CustomButton';
import Loader from '@components/Loader';
import { CampaignEditorContext } from '@contexts/CampaignEditorContext';
import './styles.scss'
import { Select, Modal, Button } from 'antd';
import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb';
import getCrumbs from '@pages/PartnerRolePages/Campaigns/CampaignEditorPage/helpers';
import { useGetEventByIdFromCatalogQuery } from '@store/type-partner/events-catalog/partner-event-catalog.api';
import { useGetPartnerEventQuery } from '@store/type-partner/events/partner-event.api';

import { useGetCampaignEditorDataByIdQuery } from '@store/type-partner/campaigns/campaigns.api';
import { CampaignEditorEnum } from '@store/main/organization/models';
import {
    CampaignDataItem,
} from '@store/type-partner/campaigns/models';
import { useAppSelector } from '@hooks/redux';
import { partnerCampaignsActions } from '@store/type-partner/campaigns/campaigns.slice';
import { useDispatch } from 'react-redux';
import EyeIcon from '../../../../assets/EyeIcon';
import ErrorStatusModal from '@pages/PartnerRolePages/Campaigns/components/ErrorStatusModal';
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api";
import { EventCatalogItemStatuses } from "@shared/constants";
import usePageAnalytics from "@hooks/sendAnalytics/usePageAnalytics";
import { enableSubmit } from '@contexts/utils/campaigEditorUtils';
import { useBreakpoints } from '@hooks/browser';
import { ExitPageContext } from "@contexts/ExitPageContext.tsx";
import usePopState from "@hooks/usePopState.ts";
import Avatar from "@components/Avatar";
import ArrowIcon from "@assets/ArrowIcon.tsx";
import { useGetUserBillingPaymentsQuery } from "@store/main/payment/payment.api.ts";
import AddPaymentDrawer from "@pages/PartnerRolePages/Billing/drawers/AddPayment";
import AddPaymentDrawerMobile from "@pages/PartnerRolePages/Billing/mobile/AddPayment";


const CampaignEditorPage = () => {
    usePopState()
    usePageAnalytics('campaign_editor');
    const isDesktop = useBreakpoints().md
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    const { campaignEditorsData } = useAppSelector(state => state.partnerCampaigns)

    const { eventId: eventIdFromParam, campaignId } = useParams()
    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_invite_status' })

    const availableStatusIds = statuses?.data?.filter(({ slug }) => slug === EventCatalogItemStatuses.available || slug === EventCatalogItemStatuses.active)?.map(({ id }) => id) || []
    const {
        formValues,
        setFormValues,
        formErrors,
        setFormErrors,
        isLoading,
        setLoading,
        isSaving,
        onSubmit,
        updateInitialFormHandler,
    } = useContext(CampaignEditorContext);

    const {
        isExitPageEnabled,
        setIsExitPageEnabled,
        isExitPageModalOpen,
        exitPageLink,
        onNavigate,
        onCancel
    } = useContext(ExitPageContext);

    const { data: myEventsRes, isLoading: eventsLoading } = useGetPartnerEventQuery(
        { filters: { statuses: availableStatusIds } },
        {
            skip: !availableStatusIds.length || !!eventIdFromParam,
            refetchOnMountOrArgChange: true
        }
    )

    const { data: eventData, isLoading: isEventDataLoading } = useGetEventByIdFromCatalogQuery(
        { id: eventIdFromParam?.toString() || '' },
        { skip: !eventIdFromParam }
    );
    const { data: paymentMethodsResponse } = useGetUserBillingPaymentsQuery()

    const [eventId, setEventId] = useState(eventIdFromParam ? Number(eventIdFromParam) : '')
    const [errorStatusModalData, setErrorStatusModalData] = useState<{ logs: CampaignDataItem['logs'], campaignTitle: string } | null>(null)
    const [isAddPaymentDrawerOpen, setIsAddPaymentDrawerOpen] = useState(false)
    const [isShowLaunchModal, setIsShowLaunchModal] = useState(false)
    // const [addLandingModalOpen, setAddLandingModalOpen] = useState(false)
    const [eventsOptions, setEventsOptions] = useState<any[]>([])
    const { isLoading: campaignIsLoading, refetch } = useGetCampaignEditorDataByIdQuery(
        { eventId: eventId as unknown as string, campaignId: campaignId! },
        { skip: !campaignId || !eventId }
    );

    const breadCrumbs = useMemo(() => getCrumbs(pathname, eventData?.data), [pathname, eventData])


    useEffect(() => {
        if (myEventsRes?.data) {
            const nextEventsOptions = myEventsRes.data.map(item => {
                return {
                    label: (
                        <div className='campaign-create__form-landing-option'>
                            <Avatar url={item.logo} bordered size="24" />
                            <span>{item.name}</span>
                        </div>
                    ),
                    value: item.id
                }
            })
            setEventsOptions(nextEventsOptions)
        }
        if (eventData) {
            const nextEventsOptions = [
                {
                    label: (
                        <div className='campaign-create__form-landing-option'>
                            <Avatar url={eventData.data.logo} bordered size="24" />
                            <span>{eventData.data.name}</span>
                        </div>
                    ),
                    value: eventData.data.id
                }
            ]
            setEventsOptions(nextEventsOptions)
        }
    }, [myEventsRes?.data, eventData])
    // CHECK IF UPDATE INITIAL FORM IS NECCESSARY
    useEffect(() => {
        if (isEventDataLoading) {
            return
        }
        if (!campaignId) {
            setLoading(false);
        } else {
            if (!campaignEditorsData[campaignId]?.campaignEditor) {
                setLoading(true);
                refetch()
                    .unwrap()
                    .then(response => {
                        if (response) {
                            updateInitialFormHandler(response);
                        }
                    })
                    .catch(error => {
                        console.error('Error refetching campaign:', error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    }, [campaignId, isEventDataLoading]);

    const onErrorStatusModalOpen = useCallback(() => {
        setErrorStatusModalData({ campaignTitle: formValues.title || '', logs: [] })
    }, [formValues?.title])

    const onErrorStatusModalClose = () => {
        setErrorStatusModalData(null)
    }

    const leftSide = useMemo(() => {
        return formValues?.logs ? (
            <div className='flex' onClick={onErrorStatusModalOpen}>
                <div className='vertical-divider' />
                <div className='campaign-create__view-error-button'>
                    <EyeIcon fillColor="#CF1322" withBorders={false} backgroundFillColor="transparent" />
                    View errors
                </div>
            </div>
        ) : null
    }, [formValues?.logs, onErrorStatusModalOpen])

    // FORCE UPDATE THE FORM VALUES

    useEffect(() => {
        const key = campaignId || CampaignEditorEnum.new
        if (campaignEditorsData[key]?.campaignEditor) {
            setFormValues(campaignEditorsData[key].campaignEditor || { ...formValues });
            if (!eventId) {
                setEventId(campaignEditorsData[key].selectedEvent || '')
            }
            dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: null, selectedEvent: null, campaignId }))
        }
    }, [campaignId, setFormValues, dispatch, eventId])

    useEffect(() => {
        return () => {
            setIsExitPageEnabled(false)
        }
    }, []);

    useEffect(() => {
        if (isExitPageEnabled) {
            window.history.pushState({}, '', `${pathname}`);
        }
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isExitPageEnabled) {
                event.preventDefault();
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [pathname, isExitPageEnabled]);

    const onLaunch = () => {
        setIsAddPaymentDrawerOpen(false)
        console.log('onLaunch', campaignId)
        void onSubmit({
            strict_validation: true,
            eventId: eventId as unknown as string,
            campaignId: campaignId as unknown as string,
            redirectUrl: `/events/edit/${eventId || ''}`
        })
    }

    const handleLaunchNowClick = () => {
        const isPaymentMethodAdded = paymentMethodsResponse?.data?.length
        if (!isPaymentMethodAdded) {
            setIsShowLaunchModal(false)
            setIsAddPaymentDrawerOpen(true)
        } else {
            onLaunch()
            dispatch(partnerCampaignsActions.setTemporaryEditorData(null))
        }
    }

    const handleCloseAddPaymentDrawer = () => {
        setIsAddPaymentDrawerOpen(false)
    }

    if (isLoading || campaignIsLoading || eventsLoading) {
        return (
            <EventsPageLayout>
                {isDesktop && <HeaderBreadcrumb
                    crumbs={breadCrumbs}
                    leftSide={leftSide}
                />}
                <Loader />
            </EventsPageLayout>
        )
    }

    return (
        <EventsPageLayout className='campaign-editor-page'>
            {isDesktop && <HeaderBreadcrumb
                crumbs={breadCrumbs}
                leftSide={leftSide}
            />}
            <div className='campaign-create max-w-l'>
                <p className={`text-regular-14 label text-neutral-8`}>
                    Event
                </p>
                <Select
                    style={{ width: '100%', height: eventId ? '40px' : 'auto' }}
                    options={eventsOptions}
                    value={eventId}

                    onSelect={setEventId}
                    className="campaign-editor-page__event-select"
                    placeholder='Select event to activate editor'
                    suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="20" height="20" className="common-select-icon" />}
                />
                {isSaving ? <Loader /> : null}
                <div className={`campaign-create__form ${!eventId ? 'blocked' : ''}`}>
                    <CampaignForm
                        eventData={eventData?.data}
                        eventId={eventId as unknown as string}
                    />
                </div>
                <div className='campaign-create__main-cta-wrapper'>
                    <div className='campaign-create__main-cta-buttons'>
                        <CustomButton
                            size='large'
                            title='Save and exit'
                            onClick={() => {
                                if (isExitPageEnabled) {
                                    setIsExitPageEnabled(false)
                                }
                                void onSubmit({
                                    strict_validation: false,
                                    eventId: eventId as unknown as string,
                                    campaignId: campaignId as unknown as string,
                                    redirectUrl: `/events/edit/${eventId || ''}`
                                })
                            }}
                        />
                        <CustomButton
                            size='large'
                            type='primary'
                            title='Continue'
                            loading={isLoading}
                            disabled={!eventId || !enableSubmit(formValues)}
                            onClick={() => {
                                if (!formValues.ads[0].landing) {
                                    setFormErrors({
                                        ...formErrors,
                                        'ads.0.landing': 'Landing is required'
                                    })
                                    return
                                }
                                setIsShowLaunchModal(true)
                            }}
                        />
                    </div>
                </div>
            </div>
            <ErrorStatusModal
                isOpen={Boolean(errorStatusModalData)}
                onClose={onErrorStatusModalClose}
                campaignTitle={errorStatusModalData?.campaignTitle || ''}
                logs={errorStatusModalData?.logs}
            />
            <Modal
                title="You have unsaved changes to your campaign"
                open={isExitPageModalOpen}
                onCancel={onCancel}
                centered
                onOk={() => {
                    dispatch(partnerCampaignsActions.setTemporaryEditorData(null))
                    if (exitPageLink) {
                        navigate(exitPageLink)
                    } else {
                        navigate(-2)
                    }
                    onNavigate()
                }}
                okButtonProps={{
                    type: 'default'
                }}
                cancelButtonProps={{
                    type: 'primary'
                }}
                className="campaign-editor-page__unsaved-changes-modal"
                cancelText="Continue editing"
                okText="Exit without saving"
                width={440}
            >
                Are you sure you want to exit?
            </Modal>

            <Modal
                open={isShowLaunchModal}
                width={320}
                footer={null}
                centered
                onCancel={() => setIsShowLaunchModal(false)}
            >
                <p
                    className='text-title text-center'
                >
                    Do you want to launch the сampaign now?
                </p>
                <div
                    className='flex justify-center gap-12'
                >
                    <Button
                        type='default'
                        title='Save & launch later'
                        onClick={() => {
                            void onSubmit({
                                strict_validation: false,
                                eventId: eventId as unknown as string,
                                campaignId: campaignId as unknown as string,
                                redirectUrl: `/events/edit/${eventId || ''}`
                            })
                            dispatch(partnerCampaignsActions.setTemporaryEditorData(null))
                        }}
                    >
                        Save & launch later
                    </Button>
                    <Button
                        type='primary'
                        title='Launch now'
                        onClick={handleLaunchNowClick}
                    >
                        Launch now
                    </Button>
                </div>
            </Modal>
            {/* <Modal
                open={addLandingModalOpen}
                width={320}
                footer={null}
                centered
                onCancel={() => setAddLandingModalOpen(false)}
            >
                <p
                    className='text-title text-center'
                >
                    Do you want to add a landing page?
                </p>
                <div
                    className='flex justify-center'
                >
                    <Button
                        type='primary'
                        title='Add landing page'
                        onClick={() => {
                            setAddLandingModalOpen(false)
                        }}
                    >
                        Add landing page
                    </Button>
                </div>
            </Modal> */}
            {isAddPaymentDrawerOpen && isDesktop
                ?
                <AddPaymentDrawer
                    isOpen={isAddPaymentDrawerOpen}
                    onClose={handleCloseAddPaymentDrawer}
                    isLoading={isLoading}
                    saveBtnText="Add payment & launch campaign"
                    onSuccessCallback={onLaunch}
                    isShowCancelBtn={false}
                    title="Add payment method"
                />
                :
                null}
            {isAddPaymentDrawerOpen && !isDesktop
                ?
                <AddPaymentDrawerMobile
                    isOpen={isAddPaymentDrawerOpen}
                    onClose={handleCloseAddPaymentDrawer}
                    isLoading={isLoading}
                    saveBtnText="Add payment & launch campaign"
                    onSuccessCallback={onLaunch}
                    isShowCancelBtn={false}
                    title="Add payment method"
                />
                :
                null}
        </EventsPageLayout>
    )
}

export default CampaignEditorPage