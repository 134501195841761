export interface Option {
    id: number;
    slug: string;
}
export type SelectOption = {
    value: string
    label: string
}
export interface IdNamePair {
    id: string;
    name: string;
}
export interface IdValuePair {
    id: number;
    value: string;
}

export type Options = Array<Option>

export interface Links {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
}

export interface Meta {
    current_page: number;
    from: number;
    last_page: number;
    links: {
        url: string | null;
        label: string;
        active: boolean;
    }[];
    path: string;
    per_page: number;
    to: number;
    total: number;
}
export enum ImageTypes {
    square = 'square',
    horizontal = 'horizontal',
    vertical = 'vertical'
}

export type Pagination = {
    pageSize: number
    total: number
}

export type StatusAction = {
    id: number
    slug: string
    transit: string
}

export type Status = {
    id: number;
    name: string,
    actions: StatusAction[]
}