const CollapseIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 9.25L15 4.375C15 3.87772 14.8025 3.40081 14.4508 3.04917C14.0992 2.69754 13.6223 2.5 13.125 2.5L4.375 2.5C3.87772 2.5 3.40081 2.69754 3.04918 3.04917C2.69754 3.4008 2.5 3.87772 2.5 4.375L2.5 13.125C2.5 13.6223 2.69754 14.0992 3.04917 14.4508C3.40081 14.8025 3.87772 15 4.375 15L9.25 15M9.25 9.25L17.5 17.5M17.5 17.5L17.5 13.125M17.5 17.5L13.125 17.5"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default CollapseIcon
