const InsertLinkIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.68849 13.3129L13.3136 7.68785M9.56351 4.87531L9.99758 4.3728C10.8768 3.49372 12.0692 2.99991 13.3125 3C14.5558 3.00009 15.7481 3.49407 16.6272 4.37327C17.5063 5.25248 18.0001 6.44488 18 7.68818C17.9999 8.93147 17.5059 10.1238 16.6267 11.0029L16.1261 11.4379M11.4385 16.1255L11.0663 16.6261C10.1766 17.5052 8.97628 17.9982 7.72552 17.9982C6.47475 17.9982 5.2744 17.5052 4.38469 16.6261C3.94605 16.1927 3.59778 15.6766 3.36009 15.1076C3.12239 14.5386 3 13.9281 3 13.3115C3 12.6949 3.12239 12.0844 3.36009 11.5154C3.59778 10.9465 3.94605 10.4303 4.38469 9.99694L4.87595 9.56287"
                stroke="#252628" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default InsertLinkIcon
