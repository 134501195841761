import React, { useEffect } from "react";
import '../PrivacyPolicyPage/styles.scss'

const TermsAndConditions = () => {
    useEffect(() => {
        const script = document.createElement('script');
        const tag = document.getElementsByTagName('script')[0];
        script.src = 'https://cdn.iubenda.com/iubenda.js';
        if (tag.parentNode) {
            tag.parentNode.insertBefore(script, tag);
        }
    }, []);
    return (
        <div>
            <article className="privacy-policy" style={{
                border: "1px solid #c4c4c4",
                borderRadius: "6px",
                marginTop: "24px",
            }}>
                <h1>Terms and Conditions Scope</h1>

                This Terms and Conditions applies to the following domains and services:

                <ul>
                    <li>boost.express</li>
                    <li>app.boost.express</li>
                    <li>OAuth Consent Screen Application for Google Ads Access (used to grant access to Google Ads services)</li>
                </ul>
                
                <p>By using any of the above domains or services, you agree to the terms outlined in this Terms and Conditions.</p>
            </article>
            <div className="privacy-policy__page">
                <a href="https://www.iubenda.com/terms-and-conditions/16978737"
                className="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
                title="Terms and Conditions">Terms and Conditions</a>
            </div>
        </div>
    );
}

export default TermsAndConditions;
