import React from 'react'

const EmptyIcon = () => {
    return (
        <div style={{
            width: '48px',
            height: '48px',
            borderRadius: '100%',
            background: '#f2f3f5'
        }}></div>
    )
}

export default EmptyIcon