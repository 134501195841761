export enum TabKeys {
    details = 'details',
    partners = 'partners',
    invitations = 'invitations',
    campaigns = 'campaigns',
    subdomain = 'subdomain',
    track = 'track',
    billing = 'billing',
    adAccounts = 'ad-accounts'
}

// export enum ListIds {
//     partners = 'partners-list',
//     invitations = 'invitations-list'
// }

export enum InvitationKeys {
    Sent = 'sent',
    Not_sent = 'not_sent'
}

export type EventChecklistName =
    'subdomain_verified' |
    'credential_granted' |
    'audience_configured' |
    'landing_template_configured' |
    'creative_template_configured' |
    'track_installed' |
    'billing_defined'

export const STATUSES = {
    Installed: 'installed',
    NotFound: 'Not found',
}
