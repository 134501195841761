import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { Badge, Button, Segmented, Spin, Switch } from "antd";
import { Filters, FiltersKeys } from "@pages/EventRolePages/Billing/types.ts";
import FilterButtonIcon from "@assets/FilterButtonIcon.tsx";
import FilterButtonMobileIcon from "@assets/buttons/mobile/FilterButtonMobileIcon.tsx";
import ExitCrossIcon from "@assets/ExitCrossIcon.tsx";
import { GAP_INIT } from "@shared/constants.ts";
import { useCallback, useState } from "react";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import { useGetOrganizerEventsQuery } from "@store/type-event/events/events.api.ts";
import { useGetOrganizerPartnersByEventQuery } from "@store/type-event/partners/partners.api.ts";
import { useBreakpoints } from "@hooks/browser";
import { initialFilters } from "@pages/EventRolePages/Billing/constants.ts";
import { menuItemSelectedIcon } from "@shared/menuItemSelectedIcon.tsx";
import DateRangeComponent from '@components/DateRangePicker';

type Props = {
    filters: Filters
    setFilters: (filters: (prev: Filters) => Filters) => void | Filters
    setOpenFilter: (isOpen: boolean) => void
    handleGapChange: (gap: string | number) => void
    setInitialFilters: () => void
    segmentedOptions: { label: string; value: string }[]
    isDrawer?: boolean
}

const RevenueReportsFilters = ({
    isDrawer,
    filters,
    setFilters,
    setOpenFilter,
    setInitialFilters,
    handleGapChange,
    segmentedOptions
}: Props) => {
    const isLg = useBreakpoints().lg
    const isXl = useBreakpoints().xl
    const isXxl = useBreakpoints().xxl

    const [eventsSearchValue, setEventsSearchValue] = useState<string>()
    const [eventsPage, setEventsPage] = useState(INIT_PAGE);
    const [partnersPage, setPartnersPage] = useState(INIT_PAGE);

    const { data: eventsRes, isLoading: isEventsLoading, isFetching: isEventsFetching } = useGetOrganizerEventsQuery({ filters: { search: eventsSearchValue || '' }, page: eventsPage }, { refetchOnMountOrArgChange: true })
    const { data: partnersRes, isLoading: isPartnersLoading, isFetching: isPartnersFetching } = useGetOrganizerPartnersByEventQuery({
        eventId: filters?.events?.[0]?.toString() || '', page: partnersPage
    }, { skip: !filters?.events?.length })

    const isFilterApplied =
        filters?.events?.length ||
        (filters?.dateFrom && filters.dateTo) ||
        filters?.group_by_partner ||
        filters?.gap ||
        filters?.partners?.length

    const onDateChange = (dates: {
        dateFrom?: string;
        dateTo?: string;
    }) => {
        setFilters((prev) => ({
            ...prev,
            ...dates
        }))
    }

    const handleChangeFilter = useCallback((field: FiltersKeys, value: string[] | boolean) => {
        if (field === FiltersKeys.events) {
            setPartnersPage(INIT_PAGE)
        }
        if (field === FiltersKeys.events && Array.isArray(value) && !value.length) {
            setFilters((prev) => ({ ...prev, [field]: value, partners: [] }))
        } else if (field === FiltersKeys.partners && Array.isArray(value) && !value.length) {
            setFilters((prev) => ({ ...prev, partners: [] }))
        } else {
            setFilters((prev) => ({ ...prev, [field]: value }))
        }
    }, [])

    const countChangedFilters = () => {
        const { events, gap, group_by_partner, ...currentFilters } = filters;
        let changedFiltersCount = 0;

        Object.entries(currentFilters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length) {
                    changedFiltersCount += 1;
                }
            } else {
                const initialValue = initialFilters[key as keyof Filters]
                if (value !== initialValue && key !== 'dateTo' && key !== 'dateFrom') {
                    changedFiltersCount += 1;
                }
            }
        });

        return changedFiltersCount;
    };

    return (
        <div className={`flex gap-8 mb-24 organizer-billing-page__reports__filters ${isDrawer ? 'organizer-billing-page__reports__filters-drawer' : 'organizer-billing-page__reports__filters-panel'}`}>
            <div className='flex gap-8 mr-auto'>
                {isDrawer && (
                    <div className="organizer-billing-page__reports__filters-label">
                        Events
                    </div>
                )}
                {(isDrawer || isXl) && (
                    <InfiniteSelect
                        id="organizer-billing-page__events"
                        placeholder='select'
                        maxTagCount={1}
                        style={isDrawer ? { width: '100%', marginBottom: '16px' } : { width: '237px' }}
                        filterOption={false}
                        notFoundContent={isEventsLoading || isEventsFetching ? <Spin size="small" /> : <div>There is no events</div>}
                        isLoading={isEventsLoading || isEventsFetching}
                        listHeight={160}
                        onChange={(value: string) => handleChangeFilter(FiltersKeys.events, value ? [value] : [])}
                        page={eventsPage}
                        setPage={setEventsPage}
                        data={eventsRes}
                        value={filters.events}
                        allowClear
                        showSearch
                        setSearchValue={(val) => {
                            setEventsSearchValue(val)
                        }}
                        optionsMapper={({ event, id }) => ({ value: id.toString(), label: event.name })}
                        classNameProp="revenue-reports__infinite-select__event"
                        popupClassName='organizer-billing-page__select-with-icon'
                        menuItemSelectedIcon={menuItemSelectedIcon}
                    />
                )}
                {isXl && !isXxl && !isDrawer && (
                    <div className='my-event-page__filter-container-filters-btn'>
                        <Badge  count={countChangedFilters()}>
                            <Button
                                className='all-filters-btn'
                                icon={<FilterButtonIcon />}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={() => setOpenFilter(true)}
                            >
                                All filters
                            </Button>
                        </Badge>
                    </div>
                )}
                {!isXl && !isDrawer && (
                    <button
                        className='no-style organizer-billing-page__reports__filter-button'
                        onClick={() => setOpenFilter(true)}
                    >
                        <Badge
                            dot={!!isFilterApplied}
                            status="processing"
                        >
                            <FilterButtonMobileIcon />
                        </Badge>
                    </button>
                )}
                {isDrawer && (
                    <div className="organizer-billing-page__reports__filters-label">
                        Sponsors
                    </div>
                )}
                {(isDrawer || (isXxl && !isDrawer)) && (
                    <InfiniteSelect
                        id="organizer-billing-page__partners"
                        disabled={!filters.events.length}
                        placeholder='select'
                        style={isDrawer ? { width: '100%', marginBottom: '16px' } : { width: '200px' }}
                        maxTagCount="responsive"
                        mode="multiple"
                        filterOption={false}
                        notFoundContent={isPartnersLoading || isPartnersFetching ? <Spin size="small" /> : <div>There is no sponsors for selected event</div>}
                        isLoading={isPartnersLoading || isPartnersFetching}
                        value={filters.partners || []}
                        onChange={(value: string[]) => handleChangeFilter(FiltersKeys.partners, value)}
                        optionsMapper={({ name, organization }) => ({ value: organization.id.toString(), label: name })}
                        data={partnersRes}
                        listHeight={130}
                        page={partnersPage}
                        setPage={setPartnersPage}
                        allowClear
                        classNameProp="revenue-reports__infinite-select__sponsor"
                        popupClassName='organizer-billing-page__select-with-icon'
                        menuItemSelectedIcon={menuItemSelectedIcon}
                    />
                )}
                {isDrawer && (
                    <div className="organizer-billing-page__reports__filters-label">
                        Time range
                    </div>
                )}
                {(isDrawer || (isXxl && !isDrawer)) && (
                    <DateRangeComponent
                        isDrawer={isDrawer}
                        filters={filters}
                        onDateValueChange={onDateChange}
                    />
                )}
                {isLg && (
                    <Button
                        size='small'
                        type="text"
                        onClick={setInitialFilters}
                        className="analytics-page__reset-button"
                    >
                        <ExitCrossIcon fill="#898A8C" />
                        Reset
                    </Button>
                )}
            </div>
            {!isDrawer && (
                <div className='flex gap-8 organizer-billing-page__reports__filters-right'>
                    {isXl && (
                        <div className="flex gap-8 organizer-billing-page__view-by-sponsors">
                            <div>View by sponsors</div>
                            <Switch
                                size="small"
                                checked={filters.group_by_partner}
                                onChange={(isChecked) => handleChangeFilter(FiltersKeys.group_by_partner, isChecked)}
                                id="viewBySponsors"
                            />
                        </div>
                    )}
                    <div>
                        <Segmented
                            value={filters?.gap?.toString() || GAP_INIT}
                            onChange={handleGapChange}
                            options={segmentedOptions}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default RevenueReportsFilters
