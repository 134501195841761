// https://boost.allnetru.dev/api/documentation/organizer#/
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    PatchOrganizerLandingStatusByIdResponse,
    GetOrganizerCredentialsResponse,
} from './models';
import { INIT_CURRENT_PAGE } from "@shared/constants.ts";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const organizerCredentials = `credentials`;

export const adOrganizerCredentials = createApi({
    reducerPath: organizerCredentials,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        organizerCredentials
    ],
    endpoints: build => ({
        getOrganizerCredentials: build.query<GetOrganizerCredentialsResponse, void>({
            query: () => {
                return {
                    url: '/credentials',
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    }
                };
            },
            providesTags: [organizerCredentials],
        }),
        patchOrganizerCredentialsById: build.mutation<PatchOrganizerLandingStatusByIdResponse, { credentialId: number, accountValue: { id: number; }, field: string }>({
            query: ({ credentialId, accountValue, field }) => {
                const body = {
                    [field]: {
                        "value": accountValue
                    }
                }
                return ({
                    url: `/credentials/${credentialId}`,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    providesTags: [organizerCredentials],
                    body
                })
            },
        }),
        postOrganizerEventCredentialsById: build.mutation<PatchOrganizerLandingStatusByIdResponse, { eventId: string, credentialId: number }>({
            query: ({ eventId, credentialId }) => {
                return ({
                    url: `/organizer/events/${eventId}/setup/credentials/${credentialId}`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    providesTags: [organizerCredentials],
                })
            },
        }),

        patchOrganizerCredentialsStatus: build.mutation<PatchOrganizerLandingStatusByIdResponse, { credentialId: number, statusId: number }>({
            query: ({ credentialId, statusId }) => {
                return ({
                    url: `/credentials/${credentialId}/status/${statusId}`,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    providesTags: [organizerCredentials],
                })
            },
        }),
        getOrganizerCredentialsByEventId: build.query<GetOrganizerCredentialsResponse, { eventId: string, page?: number }>({
            query: ({ eventId, page = INIT_CURRENT_PAGE }) => {
                return {
                    url: `/organizer/events/${eventId}/setup/credentials?page=${page}`,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    }
                };
            },
            providesTags: [organizerCredentials],
        }),
    }),
});

export const {
    useGetOrganizerCredentialsQuery,
    useGetOrganizerCredentialsByEventIdQuery,
    usePatchOrganizerCredentialsByIdMutation,
    usePostOrganizerEventCredentialsByIdMutation,
    usePatchOrganizerCredentialsStatusMutation,
} = adOrganizerCredentials;
