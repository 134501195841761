import { FormCompanyValues } from './models';

export const PRIVACY_LINK = "/privacy-policy";
export const TERMS_LINK = "/common-terms-and-conditions";
export const SPONSOR_SERVICE_AGREEMENT_LINK = "/sponsor-service-agreement";
export const EVENT_SERVICE_AGREEMENT_LINK = "/event-service-agreement";
export const TERMS_LINK_IUBENDA = "/terms-and-conditions";

export const FORM_ITEM_EMAIL = 'email';
export const FORM_ITEM_PASSWORD = 'password';
export const FORM_ITEM_PRIVACY = 'privacy';

export const VALIDATION_PASSWORD_LENGTH = 8;
export const REG_BY_GOOGLE_FLAG = 'isViaGoogle';

export const formAccountInitial = {
    email: '',
    username: '',
    password: '',
    remember: '',
    privacy: !!sessionStorage.getItem(REG_BY_GOOGLE_FLAG)
}
export const formCompanyInitial: FormCompanyValues = {
    name: '',
    company_name: '',
    site: '',
    phone: '',
    // privacy: !!sessionStorage.getItem(REG_BY_GOOGLE_FLAG),
    organization_type: 'none'

}
export const errorFormInitial = {
    email: '',
    password: '',
    username: '',
    name: '',
    company_name: '',
    site: '',
    phone: '',
}

export const GOOGLE_PROVIDER = 'google'
