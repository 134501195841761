import ZeroLandingsIcon from '@assets/zerostate/ZeroLandingsIcon'
import './styles.scss'
import CustomButton from '@components/CustomButton'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import {useBreakpoints} from "@hooks/browser";
import React, {useState} from "react";
import SwitchToDesktopModal
    from "@pages/PartnerRolePages/LandingPageConstructor/components/SwitchToDesktopModal/SwitchToDesktopModal.tsx";

interface ZeroLandingsProps {
    eventId: string;
    isActive?: boolean;
}
const ZeroLandings = ({
    eventId,
    isActive
}: ZeroLandingsProps) => {
    const isDesktop = useBreakpoints().md;
    const [isSwitchToDesktopModalOpen, setIsSwitchToDesktopModalOpen] = useState(false)

    return (
        <div className='campaign-editor__zero-island'>
            <ZeroLandingsIcon />
            <p className='campaign-editor__zero-island-title'>
                You have no landing pages
            </p>
            <p className='campaign-editor__zero-island-desc'>
                To add one of them, click on the button below
            </p>

            {eventId && isActive
                ?
                <Link
                    to={`/events/${eventId}/landing`}
                    className='no-style'
                    onClick={(e) => {
                        if (!isDesktop) {
                            e.preventDefault()
                            setIsSwitchToDesktopModalOpen(true)
                        }
                    }}
                >
                    <CustomButton
                        title='+ Create landing'
                        type='primary'
                    />
                </Link>
                :
                <Tooltip
                    title={'only for status "Active"'}
                >
                    <CustomButton
                        disabled
                        type="primary"
                        title='+ Create landing'
                    />
                </Tooltip>
            }
            <SwitchToDesktopModal isOpen={isSwitchToDesktopModalOpen} onClose={() => setIsSwitchToDesktopModalOpen(false)} />
        </div>
    )
}

export default ZeroLandings