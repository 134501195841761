import EventsPageLayout from '@layouts/EventsPageLayout'
import React, {useEffect, useRef, useState} from "react";
import './styles.scss'
import ExitCrossIcon from "@assets/ExitCrossIcon.tsx";
import {LOOM_LINK} from "@components/LoomPlayer/constants.ts";
import * as loom from "@loomhq/loom-embed";
import ArrowIcon from "@assets/ArrowIcon.tsx";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";

const VideoTourPage = () => {
    const [isSet, setIsSet] = useState(false)
    const [isFullWidth, setIsFullWidth] = useState(false)

    const divRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate()

    useEffect(() => {
        if (loom && !isSet) {
            loom.linkReplace("#video-page__loom-player")
            loom.linkReplace("#loom-player__fullwidth", { width: 335, height: 190 })
            setIsSet(true)
        }
    }, [loom, isSet]);

    const onFullWidthClick = () => {
        setIsFullWidth(true)
        document.body.style.pointerEvents = 'none'
    }

    const onClose = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        document.body.style.pointerEvents = 'all'
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (isFullWidth && divRef.current && !divRef.current.contains(event.target as Node)) {
            document.body.style.pointerEvents = 'all'
            setIsFullWidth(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isFullWidth]);

    return (
        <EventsPageLayout>
            <div className="video-tour-page">
                <Button
                    className="create-billing-drawer__back"
                    size='large'
                    type="text"
                    onClick={(e) => {
                        e.preventDefault()
                        navigate(-1)
                    }}
                    icon={<ArrowIcon width="24" height="24" fillColor="#C0C1C3"/>}
                >
                    Back
                </Button>
                <div
                    className={`loom-player video-page__loom-player loom-player__visible`}
                >
                    <div
                        className={`loom-player__video-wrapper ${isFullWidth ? "loom-player__video-wrapper-full" : ''}`}>
                        <a
                            id="video-page__loom-player"
                            className="loom-video"
                            href={LOOM_LINK}
                        >
                            Loom Video
                        </a>
                    </div>
                    {isFullWidth ? (
                            <div className="loom-player__exit-btn" onClick={onClose}>
                                <ExitCrossIcon width="18" height="18" fill="#FFFFFF"/>
                            </div>
                        )
                        : (
                            <div className="loom-player__header" onClick={onFullWidthClick}>
                                <div className="loom-player__title">
                                    First overview of the platform
                                </div>
                            </div>
                        )}
                </div>

                <div
                    ref={divRef}
                    className={`loom-player loom-player__fullwidth ${isFullWidth ? 'loom-player__full loom-player__visible' : ''}`}
                >
                    <div
                        className={`loom-player__video-wrapper ${isFullWidth ? "loom-player__video-wrapper-full" : ''}`}>
                        <a
                            id="loom-player__fullwidth"
                            className="loom-video"
                            href={LOOM_LINK}
                        >
                            Loom Video
                        </a>
                    </div>
                    <div className="loom-player__exit-btn" onClick={onClose}>
                        <ExitCrossIcon width="18" height="18" fill="#FFFFFF"/>
                    </div>
                </div>
            </div>
        </EventsPageLayout>
    )
}

export default VideoTourPage
