import React, { FC, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { ModalSteps } from "@pages/EventRolePages/Billing/constants.ts";
import Success from "@assets/png/success.png";
import { formatByCurrency, getCurrencySymbol } from "@shared/utils.ts";
import useEventBillingForm from "@pages/EventRolePages/Billing/useEventBillingForm.tsx";

type Props = {
    isOpen: boolean
    error: string
    onClose: () => void
    onSubmit: (amount: number) => void
    setError: (value: string) => void
    isLoading: boolean
    withdrawalStep: ModalSteps
    setWithdrawalStep: (step: ModalSteps) => void
}

const WithdrawalModal: FC<Props> = ({ isOpen, isLoading, onClose,
    onSubmit, withdrawalStep, setWithdrawalStep, error, setError }) => {
    const [amount, setAmount] = useState('')

    const {
        form,
    } = useEventBillingForm()
    const balance = form?.balance || 0
    const currency = form?.currency || ''
    const currencySign = getCurrencySymbol(currency)

    const handleClose = () => {
        onClose()
        setAmount('')
        setWithdrawalStep(ModalSteps.first)
    }
    const amountNumber = parseFloat(amount.replace(',', '.')) || 0

    const handleOk = () => {
        onSubmit(amountNumber)
    }

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        if (/^\d*[.,]?\d*$/.test(value) || value === '') {
            setAmount(value)
            setError('')
        }
    }

    return (
        <Modal
            title={withdrawalStep === ModalSteps.first ? (
                "Withdrawal request"
            ) : null}
            open={isOpen}
            onCancel={handleClose}
            className="organizer-billing-page__withdrawal__modal"
            centered
            width={440}
            zIndex={1001}
            footer={(
                <div
                    className={`${withdrawalStep === ModalSteps.first ? 'organizer-billing-page__withdrawal__modal__footer' : 'flex-center'}`}
                >
                    {withdrawalStep === ModalSteps.first ? (
                        <>
                            <Button
                                type="default"
                                size="large"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                size="large"
                                disabled={isLoading || amountNumber > balance || !amount}
                                type="primary"
                                loading={isLoading}
                                onClick={handleOk}
                            >
                                Submit
                            </Button>
                        </>
                    ) : (
                        <Button
                            size="large"
                            type="primary"
                            className="organizer-billing-page__withdrawal__modal__button"
                            onClick={handleClose}
                        >
                            Got it
                        </Button>
                    )}
                </div>
            )}
        >
            {withdrawalStep === ModalSteps.first ? (
                <>
                    <div className='text-regular-14 label text-neutral-8 mb-12'>Please specify the amount you wish to withdraw</div>
                    <Form.Item
                        className={error ? '' : "organizer-billing-page__withdrawal__form-item"}
                        help={error}
                        validateStatus={error ? 'error' : ''}
                    >
                        <Input
                            className={amount ? "" : "organizer-billing-page__withdrawal__amount-empty"}
                            id="amount"
                            name="amount"
                            prefix={currencySign}
                            value={amount}
                            onChange={handleAmountChange}
                        />
                    </Form.Item>
                    <div className='text-regular-14 label text-neutral-8 mt-6 organizer-billing-page__withdrawal__balance'>
                        Available balance:
                        {' '}
                        <span>
                            {formatByCurrency(balance, currency)}
                        </span>
                    </div>
                </>
            ) : (
                <div className="organizer-billing-page__withdrawal__success">
                    <img src={Success} alt="Success" />
                    <span className="organizer-billing-page__withdrawal__modal-title">Request successfully sent</span>
                    <span className="organizer-billing-page__withdrawal__modal-subtitle">
                        The withdrawal process usually takes 1 to 3 hours but may sometimes take up to 3 business days
                    </span>
                </div>
            )}
        </Modal>
    )
}

export default WithdrawalModal;
