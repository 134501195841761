import { useContext, useEffect, useState } from 'react';
import './styles.scss';
import ChannelCard from './ChannelCard';
import { CampaignEditorContext } from '@contexts/CampaignEditorContext';
import { Channel } from '@store/main/organization/models';
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';
import Loader from '@components/Loader';

const RadioChannel = () => {
    const {
        formValues: form,
        setFormValues,
        handleFormChange: onChange,
    } = useContext(CampaignEditorContext);
    const [channels, setChannels] = useState<{ id: string, slug: string, translate: string }[]>([])
    const { data: channelsRes, isLoading: channelsLoading, isFetching: channelsFetching } = useGetFiltersByEnumQuery({ name: 'channel' })

    useEffect(() => {
        if (channelsRes?.data) {
            setChannels(channelsRes.data)
            const formChannelsSlugs = form?.channels.map(ch => ch.title)
            const channelsToAdd = channelsRes.data.filter(ch => !formChannelsSlugs.includes(ch.slug))
            const channelsNext = channelsToAdd.map(ch => ({
                id: null,
                type: +ch.id,
                bid: 0,
                budget: 0,
                // 0: stop, 1: enabled, 2: paused
                status: 1,
                title: ch.slug
            }));
            const nextForm = { ...form, channels: [...form.channels, ...channelsNext] }
            setFormValues(nextForm)
        }
    }, [channelsRes?.data])

    const onRadioCardUpdate = (updatedChannel: Channel) => {
        const updatedChannels = form.channels.map(channel => {
            if (channel.title === updatedChannel.slug) {
                const { slug, ...rest } = updatedChannel
                return rest;
            }
            return channel;
        });
        const updatedForm = { ...form, channels: updatedChannels };
        onChange(updatedForm);
    }

    if (channelsLoading || channelsFetching) {
        return (
            <div className='radio-channel'>
                <Loader />
            </div>
        )
    }

    return (
        <div className='radio-channel'>
            {channels.map((item, idx) => {
                return (
                    <ChannelCard
                        key={idx}
                        channel={item}
                        onChange={onRadioCardUpdate}
                    />
                )
            })}
        </div>
    )
}

export default RadioChannel;
