import React, { ReactNode } from 'react'
import './styles.scss'
const TemplatesContentLayout = ({ children }: { children: ReactNode }) => {
    return (
        <div className='event-creative-template-content'>
            {children}
        </div>
    )
}

export default TemplatesContentLayout