import { format } from "date-fns";
import { UserBillingOrganizerItem } from "@store/type-event/billing/models.ts";

export const formatReportDate = (inputDate: string, gap?: string | number) => {
    if (!inputDate) {
        return ''
    }
    const gapNumber = gap ? Number(gap) : 1
    if (gapNumber === 1) {
        return format(new Date(inputDate), "MMM. d, yyyy");
    }
    if (gapNumber === 2) {
        return format(new Date(inputDate), "MMM., yyyy");
    }
    if (gapNumber === 3) {
        return format(new Date(inputDate), "yyyy");
    }
}

type FlatObject = {
    [key: string]: any;
};

type NestedObject = {
    [key: string]: any;
};


export const transformToNestedObject = (flatObject: FlatObject): NestedObject => {
    const result: NestedObject = {};

    for (const key in flatObject) {
        if (Object.prototype.hasOwnProperty.call(flatObject, key)) {
            const value = flatObject[key];
            if (key.startsWith('bank_details')) {
                const keys = key.split('.');
                let currentLevel = result;

                keys.forEach((k, index) => {
                    if (!currentLevel[k]) {
                        currentLevel[k] = {};
                    }
                    if (index === keys.length - 1) {
                        currentLevel[k] = value;
                    } else {
                        currentLevel = currentLevel[k];
                    }
                });
            } else {
                result[key] = value;
            }
        }
    }

    return result;
};

export const transformToFlatObject = (
    nestedObject: NestedObject,
    parentKey = '',
    result: FlatObject = {}
): FlatObject => {
    for (const key in nestedObject) {
        if (Object.prototype.hasOwnProperty.call(nestedObject, key)) {
            const value = nestedObject[key];
            const newKey = parentKey ? `${parentKey}.${key}` : key;

            if (parentKey.startsWith('bank_details') || key === 'bank_details') {
                if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    transformToFlatObject(value, newKey, result);
                } else if (value !== undefined) {
                    result[newKey] = value;
                }
            } else {
                result[key] = value;
            }
        }
    }

    return result;
};

export const sortData = (data: UserBillingOrganizerItem[], field: string, order: string): UserBillingOrganizerItem[] => {
    return data.sort((a, b) => {
        switch (field) {
            case 'event':
                return (order === 'ascend' ? a?.event?.name.localeCompare(b?.event?.name) : b?.event?.name.localeCompare(a?.event?.name)) || 0;
            case 'partner':
                return (order === 'ascend' ? a?.partner?.name.localeCompare(b?.partner?.name || '') : b?.partner?.name.localeCompare(a?.partner?.name || '')) || 0;
            case 'cost':
                return (order === 'ascend' ? a.reports.cost - b.reports.cost : b.reports.cost - a.reports.cost) || 0;
            case 'revshare':
                return order === 'ascend' ? a.reports.revshare - b.reports.revshare : b.reports.revshare - a.reports.revshare;
            case 'comission':
                return order === 'ascend' ? a.reports.comission - b.reports.comission : b.reports.comission - a.reports.comission;
            case 'date':
                if (field === 'date' && typeof a.date === 'string' && typeof b.date === 'string') {
                    return (order === 'ascend' ? new Date(a.date).getTime() - new Date(b.date).getTime() : new Date(b.date).getTime() - new Date(a.date).getTime()) || 0;
                }
                return 0
                break;
            default:
                return 0;
        }

        return 0;
    });
}
