// https://boost.allnetru.dev/api/documentation/organizer#/Landings
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GetOrganizerLandingResponse,
    GetOrganizerLandingFilterByIdResponse,
    PatchOrganizerLandingStatusByIdResponse,
    GetOrganizerLandingsParams,
} from './models';
import { getQueryStringFromParams } from "@shared/utils";
import { ModerationFiltersKeys } from "@pages/EventRolePages/ModerationPage/types";
import { SetupLanding } from "@store/type-event/models.ts";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const organizer_landings = `organizer_landings`;

export const organizerLandings = createApi({
    reducerPath: organizer_landings,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        organizer_landings
    ],
    endpoints: build => ({
        getOrganizerLandings: build.query<GetOrganizerLandingResponse, GetOrganizerLandingsParams>({
            query: ({ filters, page }) => {
                const statusesQueryString = getQueryStringFromParams(filters?.statuses, ModerationFiltersKeys.statuses)
                const partnersQueryString = getQueryStringFromParams(filters?.partners, ModerationFiltersKeys.partners)
                const eventsQueryString = getQueryStringFromParams(filters?.events, ModerationFiltersKeys.events)
                const pageQueryString = page ? `page=${page}` : ''

                const queryString = [
                    statusesQueryString,
                    partnersQueryString,
                    eventsQueryString,
                    pageQueryString
                ].filter(Boolean).join('&');

                const params = {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    'filters[search]': filters?.search || undefined,
                };
                return {
                    url: `/organizer/landings${queryString.length ? "?" + queryString : ''}`,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    },
                    params,
                };
            },
            providesTags: [organizer_landings],
        }),
        patchOrganizerLandingStatusById: build.mutation<PatchOrganizerLandingStatusByIdResponse, { landing: number, status: number, reason?: number, comment?: string }>({
            query: ({ landing, status, reason, comment }) => {
                const body = (reason || comment) ? {
                    ...(reason ? { reason } : {}),
                    ...(comment ? { comment } : {})
                } : undefined
                return ({
                    url: `/organizer/landings/${landing}/status/${status}`,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    providesTags: [organizer_landings],
                    body
                })
            },
        }),
        getOrganizerLandingFilterById: build.query<GetOrganizerLandingFilterByIdResponse, { search?: string, page?: number, eventId: string }>({
            query: ({ search, page, eventId }) => {
                const params = new URLSearchParams();

                if (search) {
                    params.append('filters[search]', search);
                }
                if (page) {
                    params.append('filters[page]', page.toString());
                }
                const queryString = params.toString();
                return {
                    url: `/organizer/events/${eventId}/landings/filter${queryString.length ? "?" + queryString : ''}`,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    },
                    params,
                };
            },
            providesTags: [organizer_landings],
        }),
        getOrganizerSetupLandings: build.query<{ data: SetupLanding[] }, { eventId: string }>({
            query: ({ eventId }) => {
                return {
                    url: `/organizer/events/${eventId}/setup/landings`,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    },
                };
            },
            providesTags: [organizer_landings],
        }),
        patchOrganizerSetupLandingStatusById: build.mutation<{ data: SetupLanding }, { landing: number, eventId: string, status: string, comment?: string }>({
            query: ({ landing, eventId, status, comment }) => {
                const body = comment ? {
                    ...(comment ? { comment } : {})
                } : undefined
                return ({
                    url: `/organizer/events/${eventId}/setup/landings/${landing}/status/${status}`,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    providesTags: [organizer_landings],
                    body
                })
            },
        }),

    }),
});

export const {
    useGetOrganizerLandingsQuery,
    useGetOrganizerSetupLandingsQuery,
    usePatchOrganizerLandingStatusByIdMutation,
    usePatchOrganizerSetupLandingStatusByIdMutation,
    useGetOrganizerLandingFilterByIdQuery
} = organizerLandings;