import emptyBilling from "@assets/png/emptyBilling.png";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";

type Props = {
    onCreateBilling: () => void
}

const EmptyBilling = ({ onCreateBilling }: Props) => {
    return (
        <>
            <div className='ad-accounts-page__header-title mb-24'>
                <div>Billing</div>
            </div>
            <div className='ad-accounts-page__empty-credentials event-billing__empty_billing'>
                <img src={emptyBilling} alt='emptyBilling'/>
                <span><b>You have no billings</b></span>
                <span>To add one of them, click on the button below</span>
                <Button
                    type='primary'
                    onClick={onCreateBilling}
                >
                    <PlusOutlined/>
                    Create billing
                </Button>
            </div>
        </>
    )
}

export default EmptyBilling