import type { MenuProps } from 'antd';
import { Dropdown, Space, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '@contexts/AuthContext';
import ArrowIcon from "@assets/ArrowIcon.tsx";
import React, { useContext, useState } from 'react'
import { SettingsIds, SettingsNames } from "@pages/PartnerRolePages/SettingsPage/constants.ts";
import { billing, settings } from "@pages/routes.ts";
import { useGetUserOrganizerBillingQuery } from "@store/type-event/billing/billing.api.ts";
import { INIT_CURRENT_PAGE } from "@shared/constants.ts";
import { ExitPageContext } from "@contexts/ExitPageContext.tsx";

const ProfileDropdown = () => {
    const { user, logout } = useAuth()

    const isEvent = user?.organization.type === 'event'
    const isAdmin = user?.organization.type === 'admin'
    const isUserAdmin = user?.roles?.includes('admin')

    const [isOpen, setIsOpen] = useState(false)

    const { data: billingsRes } = useGetUserOrganizerBillingQuery({ page: INIT_CURRENT_PAGE })

    const billingLink = billingsRes?.data?.[0]?.id ? `${settings}/revenue-reports/${billingsRes.data[0].id}` : `${settings}/revenue-reports`

    const {
        isExitPageEnabled,
        setIsExitPageModalOpen,
        setExitPageLink
    } = useContext(ExitPageContext);

    const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
        if (isExitPageEnabled) {
            e.stopPropagation()
            e.preventDefault()
            setIsExitPageModalOpen(true)
            setExitPageLink(path)
            return
        }
    }

    const itemsAdmin: MenuProps['items'] = [
        {
            label: <Link to={`${settings}/${SettingsIds.profile}`}>Profile</Link>,
            key: '0',
        },
        {
            label: <Link aria-disabled to={`${settings}/${SettingsIds.adAccounts}`}>Ad Accounts</Link>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={logout}>
                Sign Out
            </div>,
            key: '3',
        },
    ];
    const itemsEvent: MenuProps['items'] = [
        {
            label: <Link to={`${settings}/${SettingsIds.profile}`}>Profile</Link>,
            key: '0',
        },
        ...isUserAdmin ? [{
            label: <Link aria-disabled to={billingLink}>Billing</Link>,
            key: '4',
        }] : []
        ,
        {
            label: <Link aria-disabled to={settings}>Settings</Link>,
            key: '2',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={logout}>
                Sign Out
            </div>,
            key: '3',
        },
    ];
    const itemsPartner: MenuProps['items'] = [
        {
            label: <Link to={`${settings}/${SettingsIds.profile}`} onClick={(e) => onLinkClick(e, `${settings}/${SettingsIds.profile}`)}>Profile</Link>,
            key: '0',
        },
        ...isUserAdmin ?
        [{
            label: <Link aria-disabled to={billing} onClick={(e) => onLinkClick(e, billing)}>Billing</Link>,
            key: '2',
        }] : [],
        {
            label: <Link aria-disabled to={settings} onClick={(e) => onLinkClick(e, settings)}>Settings</Link>,
            key: '4',
        },
        {
            label: <Link aria-disabled to={`${settings}/${SettingsIds.usersRoles}`} onClick={(e) => onLinkClick(e, `${settings}/${SettingsIds.usersRoles}`)}>{SettingsNames.usersRoles}</Link>,
            key: '5',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={logout}>
                Sign Out
            </div>,
            key: '3',
        },
    ];

    const items = isEvent ? itemsEvent : isAdmin ? itemsAdmin : itemsPartner

    return (
        <Dropdown
            overlayClassName="profile-dropdown"
            overlayStyle={{ width: '159px' }}
            menu={{ items }}
            trigger={['hover']}
            onOpenChange={(isOpen) => {
                setIsOpen(isOpen)
            }}
        >
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <div className={`profile-dropdown-wrapper ${isOpen ? 'profile-dropdown-wrapper__is-open' : ''}`}>
                        <Avatar
                            size={28}
                            className="profile-dropdown-avatar"
                        >
                            {user?.name?.[0]}
                        </Avatar>
                        <ArrowIcon />
                    </div>
                </Space>
            </a>
        </Dropdown>
    )
};

export default ProfileDropdown;
