import '../styles.scss'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from "@contexts/AuthContext";
import { useEffect, useState } from 'react';
import { useCreateUserAccountMutation, useGetInviteByEventHashQuery, usePatchUserProfileMutation } from '@store/main/-user/user.api';
import { ErrorDataResponse, errorsToFormAdapter, getCookie } from '@shared/utils';
import CreateAccountStep from './steps/CreateAccountStep';
import LoadingView from '@components/common/LoadingView';
import { CreateCompanyStep } from './steps/CreateCompanyStep';
import { ErrorsValues, FormAccountValues, FormCompanyValues } from './models';
import { errorFormInitial, formAccountInitial, formCompanyInitial } from './constants';
import { CreateTypeStep } from './steps/CreateTypeStep';
import { InviteInfoResponse } from '@store/models-to replace/auth';
import { usePatchUserOragnizationTypeMutation } from '@store/main/organization/organization.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { useDispatch } from "react-redux";
import { userActions } from "@store/main/-user/user.slice";
import { message } from 'antd';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
// const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const RegistrationPage = () => {

  // invited user
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch()

  const eventHashFromCookie: string | null = getCookie('event_hash');

  const event_hash = eventHashFromCookie

  const [eventInfo, setEventInfo] = useState<InviteInfoResponse>()
  const [inviteHash, setInviteHash] = useState('')
  const {
    data: inviteInfo,
    isLoading: isInviteLoading,
  } = useGetInviteByEventHashQuery(inviteHash, { skip: !inviteHash });

  useEffect(() => {
    if (inviteInfo?.data) {
      setEventInfo(inviteInfo)
    }
  }, [inviteInfo?.data])

  useEffect(() => {
    if (event_hash) {
      onFormCompanyChange({ organization_type: "partner" })
      setInviteHash(event_hash)
    }
  }, [event_hash]);
  //

  const [formErrors, setFormErrors] = useState<ErrorsValues>(errorFormInitial)
  const [isLoading, setLoading] = useState(false)

  const [formAccount, setFormAccount] = useState<FormAccountValues>(formAccountInitial)
  const [formCompany, setFormCompany] = useState<FormCompanyValues>(formCompanyInitial)

  const navigate = useNavigate()
  const { user, reFetchUser, isUserLoading, isAuthenticated } = useAuth();

  const [createAccount] = useCreateUserAccountMutation()
  const [createCompany] = usePatchUserProfileMutation()
  const [patchOrganizationType] = usePatchUserOragnizationTypeMutation()

  // const [step, setStep] = useState<'account' | 'company' | 'type'>('account')


  const onFormAccountChange = (value: Record<string, string>) => {
    setFormErrors(errorFormInitial)
    setFormAccount(prev => ({ ...prev, ...value }))
  }
  const onFormCompanyChange = (value: Record<string, string>) => {
    setFormErrors(errorFormInitial)
    setFormCompany(prev => ({ ...prev, ...value }))
  }

  const onCreateAccountClick = async () => {
    setLoading(true)
    try {
      const response = await createAccount(formAccount)

      if ('error' in response) {
        setLoading(false)
        const errors = errorsToFormAdapter(response as ErrorDataResponse)
        setFormErrors(errors as unknown as ErrorsValues)
      }

      if ('data' in response) {
        dispatch(userActions.setUserState(response?.data?.data))
        dispatch(userActions.setUserError(''))
        sendAnalytics(GTMEventName.generalRegistration)
        setLoading(false)
        return navigate(`/registration`)
      }
      console.log('Success:');

    } catch (error) {
      setLoading(false)
      console.log('SMTH WRONG');
    }
  }

  const onCreateCompanyByInvite = async () => {
    const { name, company_name, phone } = formCompany
    const site = formCompany?.site || user?.organization?.site || ''

    const campaignData = { name, company_name, site, phone }
    const payload = campaignData

    const invitedPayload = { ...campaignData, organization_type: 'partner', site }

    if (!isPhoneValid(phone)) {
      setFormErrors(prevState => ({ ...prevState, phone: 'Invalid phone number' }))
      return
    }
    setLoading(true)
    try {
      const eventId = eventInfo?.data?.event?.id
      const body = eventId || inviteHash ? invitedPayload : payload
      const response = await createCompany(body)

      if ('error' in response) {
        setLoading(false)
        const errors = errorsToFormAdapter(response as ErrorDataResponse)
        setFormErrors(errors as unknown as ErrorsValues)

        if (!Object.values(errors || {})?.length) {
          const err = response as unknown as ErrorDataResponse
          void message.open({
            type: 'error',
            content: err?.error?.data?.message || 'OOOPS, something is wrong',
          });
        }
      }

      if ('data' in response) {
        await patchOrganizationType({ organization_type: 'partner' })
          .then(() => {
            sendAnalytics(GTMEventName.partnerRegistration)
            void reFetchUser().then(() => {
              void message.open({
                type: 'success',
                content: 'You have successfully registered as a sponsor',
              });
              return navigate('/onboarding/partner');
            })
          })
          .catch((error: ErrorDataResponse['error']) => {
            return message.open({
              type: 'error',
              content: error?.data?.message || 'OOOPS, something is wrong',
            });
          })
      }
      console.log('Success:');

    } catch (error) {
      setLoading(false)
      console.log('SMTH WRONG');
    }
  }

  const onCreateCompanyClick = async (isFinal?: boolean) => {

    const site = formCompany?.site || user?.organization?.site || ''
    const { name, company_name, phone, organization_type } = formCompany

    const campaignData = { name, company_name, site, phone }
    const payload = campaignData

    const invitedPayload = { ...campaignData, organization_type: 'partner', site }
    if (!isPhoneValid(phone) && !user?.organization?.name) {
      setFormErrors(prevState => ({ ...prevState, phone: 'Invalid phone number' }))
      return
    }
    setLoading(true)

    try {
      const eventId = eventInfo?.data?.event?.id
      const body = eventId || inviteHash ? invitedPayload : payload
      const response =
        !isFinal
          ?
          await createCompany(body)
          :
          await patchOrganizationType({ organization_type })

      if ('error' in response) {
        setLoading(false)
        const errors = errorsToFormAdapter(response as ErrorDataResponse)
        setFormErrors(errors as unknown as ErrorsValues)
        if (!Object.values(errors || {})?.length) {
          const err = response as unknown as ErrorDataResponse
          void message.open({
            type: 'error',
            content: err?.error?.data?.message || 'OOOPS, something is wrong',
          });
        }
      }

      if ('data' in response) {
        return await reFetchUser()
          .then(() => {
            if (isFinal) {
              if (organization_type === 'partner') {
                sendAnalytics(GTMEventName.partnerRegistration)
              } else {
                sendAnalytics(GTMEventName.eventRegistration)
              }
              return navigate('/onboarding/event');
            }
            sendAnalytics(GTMEventName.createCompanyStep)
            return navigate('/');
          })
          .catch((error: ErrorDataResponse['error']) => {
            console.log('SMTH WRONG');
            return message.open({
              type: 'error',
              content: error?.data?.message || 'OOOPS, something is wrong',
            });
          })
          .finally(() => {
            setLoading(false)
          })
      }
      console.log('Success:');

    } catch (error) {
      setLoading(false)
      console.log('SMTH WRONG');
    }
  }

  if (isInviteLoading || isUserLoading) {
    //TODO: check styles
    return <LoadingView />
  }

  if (!isAuthenticated || !user?.email) {
    return (
      <CreateAccountStep
        form={formAccount}
        formErrors={formErrors}
        onFormChange={onFormAccountChange}
        onSubmit={() => void onCreateAccountClick()}
        inviteInfo={eventInfo}
        isLoading={isLoading}
      />
    )
  }

  if (!user?.organization?.name) {
    return (
      <CreateCompanyStep
        form={formCompany}
        formErrors={formErrors}
        onFormChange={onFormCompanyChange}
        onSubmit={() => event_hash ? void onCreateCompanyByInvite() : void onCreateCompanyClick()}
        isLoading={isLoading}
        inviteInfo={eventInfo}
        provider={user?.provider}
      />
    )
  }
  if (!event_hash && (!user?.organization?.type || user?.organization?.type === 'none')) {
    return (
      <CreateTypeStep
        form={formCompany}
        onFormChange={onFormCompanyChange}
        onSubmit={() => void onCreateCompanyClick(true)}
        isLoading={isLoading}
        inviteInfo={eventInfo}
      />
    )
  }

  return <Navigate to={'/events'} />
}

export default RegistrationPage