import Avatar from '@components/Avatar'
import StatusDescription from '@components/StatusDescription'
import { CampaignStatuses } from '@shared/constants'
import { formatDateRangeToCommonFormat } from '@shared/utils'
import { OrganizerEventDataItem } from '@store/type-event/events/models'
import { Link } from 'react-router-dom'
import { formatToKPlus } from '@components/Analytics/helpers'
import InfiniteScrollTable from "@components/InfiniteScrollTable/InfiniteScrollTable.tsx";
import React from "react";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import ArrowIcon from '@assets/ArrowIcon';

interface Props {
    events: OrganizerEventDataItem[]
    isLastPage: boolean
    onNextPage: () => void
    page: number
    isLoading: boolean
}
const EventsTable = ({ events, isLastPage, onNextPage, page, isLoading }: Props) => {
    const formattedNumber = (number: number) => new Intl.NumberFormat('fr', {
        useGrouping: true
    }).format(number);

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 109,
            render: (status: { id: number, name: string }) => <StatusDescription status={status.name as CampaignStatuses} />
        },
        {
            title: 'Event name',
            dataIndex: 'event',
            key: 'name',
            width: 319,
            render: (event: OrganizerEventDataItem['event'], row: OrganizerEventDataItem) => {
                return (
                    <Link
                        to={`/events/info/${row.id}/general`}
                        className='no-style'
                    >
                        <div className='flex items-center'>
                            <Avatar
                                isCover
                                bordered
                                size='50'
                                url={event.logo || ''}
                            />
                            <div className="organizer-events-table__event-title">
                                {event.name}
                            </div>
                            <ArrowIcon width="20" height="20" fillColor="#C0C1C3" />
                        </div>
                    </Link>
                )
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 224,
            render: (date: OrganizerEventDataItem['date']) => {
                return (
                    <div className='flex items-center'>
                        <p
                            className='text-regular-14 m-0'
                        >
                            {formatDateRangeToCommonFormat(date.started_at, date.finished_at)}
                        </p>
                    </div>
                )
            }
        },
        {
            title: 'Sponsors',
            dataIndex: 'sponsors',
            key: 'sponsors',
            width: 166,
            render: (_: any, row: OrganizerEventDataItem) => {
                return (
                    <div className='flex items-center'>
                        {formatToKPlus(row?.partners || 0)}
                    </div>
                )
            }
        },
        {
            title: 'Campaigns',
            dataIndex: 'campaigns',
            key: 'campaigns',
            width: 166,
            render: (_: any, row: OrganizerEventDataItem) => {
                return (
                    <div className='flex items-center'>
                        {formatToKPlus(row?.campaigns || 0)}
                    </div>
                )
            }
        },
        {
            title: 'Landings',
            dataIndex: 'landings',
            key: 'landings',
            width: 166,
            render: (_: any, row: OrganizerEventDataItem) => {
                return (
                    <div className='flex items-center'>
                        {formatToKPlus(row?.landings || 0)}
                    </div>
                )
            }
        },
        {
            title: 'Leads',
            dataIndex: 'leads',
            key: 'leads',
            width: 166,
            render: (_: any, row: OrganizerEventDataItem) => {
                return (
                    <div className='flex items-center'>
                        {formatToKPlus(row?.leads || 0)}
                    </div>
                )
            }
        },

    ];

    return (
        <div className='mt-16'>
            <InfiniteScrollTable
                isLastPage={isLastPage}
                onNextPage={onNextPage}
                isFirstPage={page === INIT_PAGE}
                loading={isLoading}
                dataSource={events}
                columns={columns}
                rowKey="id"
                bordered
                scroll={{ x: 1200 }}
                className="bordered-table"
            />
        </div>
    )
}

export default EventsTable