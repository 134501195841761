import React from 'react';
import { Form, Input } from "antd";
import { BillingErrorsValues } from './models';
import SuggestSelectCities from '@components/SuggestSelectCities';
import SuggestSelectCountries from '@components/SuggestSelectCountries';
import { PatchBillingInfoUser } from '@store/main/billing/models';
import { useForm } from "laravel-precognition-react";

interface BillingInfoProps {
    form: PatchBillingInfoUser;
    validationErrors: BillingErrorsValues
    onFormChange: (field: Partial<PatchBillingInfoUser>) => void;
}

interface CityFieled {
    city: {
        country: {
            id: number;
            slug: string
        }

        id: number;
        slug: string
    }
}

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const BillingForm: React.FC<BillingInfoProps> = ({
    form,
    onFormChange,
    validationErrors
}) => {
    const billingForm = useForm('patch', `${apiBaseUrl}/billing`, form as any as Record<string, unknown>)
    const { errors } = billingForm

    const formValuesHadler = (field: Partial<PatchBillingInfoUser>) => {
        if ('city' in field) {
            const values = field as CityFieled
            onFormChange({ country: { ...values?.city?.country || {} } })
            onFormChange({ city: values.city })
            billingForm.setData('country', { ...values?.city?.country || {} })
            billingForm.setData('city', values.city)
            billingForm.setErrors({ ...billingForm.errors, city: '' });
            return
        }
        onFormChange(field)
        const entries = Object.entries(field);
        const [key, value] = entries?.[0];
        if (key) {
            billingForm.setData(key, value)
            billingForm.setErrors({ ...billingForm.errors, [key]: '' });
        }
    }

    return (
        <Form
            name="billingInfo"
            initialValues={form}
            autoComplete="off"
            layout="vertical"
            onValuesChange={(field: Partial<PatchBillingInfoUser>) => {
                formValuesHadler(field)
            }}
        >
            <Form.Item
                help={errors?.company_name || validationErrors?.company_name && validationErrors?.company_name}
                validateStatus={validationErrors?.company_name || errors?.company_name ? 'error' : ''}
                name='company_name'
                label="Company name">
                <Input placeholder='Your company name' onBlur={() => {
                    billingForm.validate('company_name')
                }} />
            </Form.Item>
            <Form.Item
                help={errors?.country || errors?.['country.id'] || validationErrors?.country || validationErrors?.['country.id']}
                validateStatus={errors?.country || errors?.['country.id'] || validationErrors?.country || validationErrors?.['country.id']
                    ? 'error' : ''}
                // name='country'
                label="Country"
            >
                <SuggestSelectCountries
                    value={form.country}
                    placeholder={form?.country?.slug || 'Сhoose country'}
                    onChange={(country) => {
                        onFormChange({ country })
                        onFormChange({ city: null })
                        billingForm.setData('country', country)
                        billingForm.setData('city', null)
                        billingForm.setErrors({ ...billingForm.errors, country: '' });
                    }}
                />
            </Form.Item>
            <Form.Item
                help={errors?.['city.id'] || validationErrors?.['city.id'] && validationErrors?.['city.id']}
                validateStatus={errors?.['city.id'] || validationErrors?.['city.id'] ? 'error' : ''}
                name="city"
                label="City">
                <SuggestSelectCities
                    value={form?.city || null}
                    countryValue={form.country}
                    placeholder={form?.city?.slug || 'Сhoose city'}
                    onChange={(city) => {
                        onFormChange({ city })
                        billingForm.setData('city', city)
                        billingForm.setErrors({ ...billingForm.errors, city: '' });
                    }}
                />
            </Form.Item>

            <Form.Item
                help={errors?.postal_code || validationErrors?.postal_code && validationErrors?.postal_code}
                validateStatus={errors?.postal_code || validationErrors?.postal_code ? 'error' : ''}
                name="postal_code"
                label="Postal code">
                <Input placeholder='Your code' onBlur={() => {
                    billingForm.validate('postal_code')
                }} />
            </Form.Item>

            <Form.Item
                help={errors?.email || validationErrors?.email && validationErrors?.email}
                validateStatus={errors?.email || validationErrors?.email ? 'error' : ''}
                name='email'
                label="Email" rules={[{ type: 'email' }]}>
                <Input placeholder='Your email' onBlur={() => {
                    billingForm.validate('email')
                }} />
            </Form.Item>

            <Form.Item
                help={errors?.address || validationErrors?.address && validationErrors?.address}
                validateStatus={errors?.address || validationErrors?.address ? 'error' : ''}
                name="address"
                label="Organisation address">
                <Input placeholder='Your address' onBlur={() => {
                    billingForm.validate('address')
                }} />
            </Form.Item>

            <Form.Item
                help={errors?.tax_code || validationErrors?.tax_code && validationErrors?.tax_code}
                validateStatus={errors?.tax_code || validationErrors?.tax_code ? 'error' : ''}
                name="tax_code"
                label="VAT number">
                <Input placeholder='VAT' type='numeric' onBlur={() => {
                    billingForm.validate('tax_code')
                }} />
            </Form.Item>
        </Form>
    );
};

export default BillingForm;
