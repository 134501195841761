import './styles.scss';
import ContentLayout from '@layouts/ContentLayout'
import CustomButton from '@components/CustomButton'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import OrganizerEventsCard from './components/EventCard';
import { Link } from 'react-router-dom';
import MyEventsSkeletonZeroState from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCardsPage/ZeroState';
import { useGetOrganizerEventsQuery } from '@store/type-event/events/events.api';
import ZeroTableIcon from "@assets/zerostate/ZeroTableIcon";
import { useAuth } from "@contexts/AuthContext";
import EventsCardsFilter from './components/EventsCardsFilter';
import { useBreakpoints } from '@hooks/browser';
import EventsCardsFilterMobile from './components/mobile/EventsCardsFilterMobile';
import EventsPageLayout from '@layouts/EventsPageLayout';
import { OrganizerEventDataItem } from '@store/type-event/events/models';
import MyEventsSkeletonMobileZeroState from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCardsPage/ZeroState/SkeletonMobile';
import EventsTable from './components/EventsTable';
import RadioTable from './components/RadioTable';
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, List, Skeleton } from "antd";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api.ts";
import { PlusOutlined } from "@ant-design/icons";


// eslint-disable-next-line @typescript-eslint/ban-types
const debounce = (fn: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            fn(...args);
        }, delay);
    };
};
export interface MyEventsFilters {
    search: string;
    date: {
        from: string,
        to: string
    };
    statuses: string[]

}
const initialFilters: MyEventsFilters = {
    search: '',
    date: {
        from: '',
        to: ''
    },
    statuses: []
}

const MyEventsCardsPage = () => {
    const isDesktop = useBreakpoints().md

    const [filters, setFilters] = useState<MyEventsFilters>(initialFilters)
    const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
    const [eventsList, setEventsList] = useState<OrganizerEventDataItem[]>([])
    const [isTableView, setTableView] = useState(false)
    const [page, setPage] = useState(INIT_PAGE)

    const { data, isLoading, isFetching, refetch } = useGetOrganizerEventsQuery({ filters, page }, { refetchOnMountOrArgChange: true })
    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_status' })
    const { user } = useAuth()

    const onResetFilters = () => {
        setFilters(initialFilters)
        onFilterUpdate(initialFilters)
        setPage(INIT_PAGE)
    }

    const onFilterUpdate = (value: Partial<MyEventsFilters>) => {
        setFilters((prev) => ({ ...prev, ...value }));
        setPage(INIT_PAGE)
    };

    const debouncedOnSearchUpdate = useCallback(
        debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            onFilterUpdate({ search: e.target.value });
        }, 500),
        [onFilterUpdate]
    );

    const isLastPage = useMemo(() => {
        const lastPage = data?.meta?.last_page || INIT_PAGE
        return page >= lastPage
    }, [data?.meta?.last_page, page])

    const onNextPage = useCallback(() => {
        setPage(page + 1)
    }, [setPage, page])

    const loadMoreData = () => {
        if (data?.meta?.last_page && (data?.meta?.last_page <= page || isLoading || isFetching)) {
            return;
        }
        setPage(page + 1)
    };

    useEffect(() => {
        // Check if filters are not equal to initialFilters
        const filtersAreEqual =
            filters.search === initialFilters.search &&
            filters.date.from === initialFilters.date.from &&
            filters.date.to === initialFilters.date.to &&
            JSON.stringify(filters.statuses) === JSON.stringify(initialFilters.statuses);

        setFiltersChanged(!filtersAreEqual);
    }, [filters]);

    useEffect(() => {
        setEventsList((prevLandings) => {
            const from = data?.meta?.from
            if (from && prevLandings.length < from) {
                return [...(prevLandings || []), ...(data?.data || [])]
            }
            return data?.data || []
        })
    }, [data])

    //TODO: remove this after invalidate tags fixed
    useEffect(() => {
        void refetch()
    }, [])

    if (isLoading) {
        return (
            <EventsPageLayout>
                <ContentLayout>
                    {isDesktop
                        ?
                        <MyEventsSkeletonZeroState />
                        :
                        <MyEventsSkeletonMobileZeroState />
                    }
                </ContentLayout>
            </EventsPageLayout>
        )
    }

    if (!isLoading && !eventsList.length && !filtersChanged) {
        return (
            <EventsPageLayout>
                <ContentLayout>
                    <div
                        className='my-events-cards-page__no-data'
                    >
                        <ZeroTableIcon />
                        {user?.name && (
                            <p className='my-events-cards-page__no-data-greet'>
                                Hi,
                                {user.name}
                                !
                            </p>
                        )}
                        <p className='my-events-cards-page__no-data-desc'>
                            There are no events on your desk yet. Please explore the event catalog to find something interesting
                        </p>

                        <Link to='setup' className='no-style'>
                            <CustomButton
                                type='primary'
                                size='large'
                                title='Explore events'
                            />
                        </Link>
                    </div>
                </ContentLayout>
            </EventsPageLayout>
        )
    }

    return (
        <EventsPageLayout>
            <ContentLayout>
                {isDesktop
                    ?
                    <div className='flex-center justify-space-between  mb-24'>
                        {/* EventsCardsFilter */}
                        <EventsCardsFilter
                            filters={filters}
                            statuses={statuses?.data || []}
                            onFilterUpdate={onFilterUpdate}
                            onResetFilters={onResetFilters}
                            debouncedOnSearchUpdate={debouncedOnSearchUpdate}
                        />
                        {isDesktop
                            ?
                            <div className='flex items-center'>
                                <div className='mx-16'>
                                    <RadioTable
                                        isTableView={isTableView}
                                        setTableView={setTableView}
                                    />
                                </div>
                                <Link to='setup' className='no-style'>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        className="my-event-page__add-event-btn"
                                    >
                                        Add event
                                    </Button>
                                </Link>
                            </div>
                            :
                            null}
                    </div>
                    :
                    <EventsCardsFilterMobile
                        filters={filters}
                        statuses={statuses?.data || []}
                        setTableView={setTableView}
                        isTableView={isTableView}
                        onFilterUpdate={onFilterUpdate}
                        onResetFilters={onResetFilters}
                        debouncedOnSearchUpdate={debouncedOnSearchUpdate}
                    />
                }

                {isTableView
                    ?
                    <div className='my-events-cards-page__table'>
                        <EventsTable
                            events={eventsList}
                            isLastPage={isLastPage}
                            onNextPage={onNextPage}
                            page={page}
                            isLoading={isLoading || isFetching}
                        />
                    </div>
                    :
                    (
                        <div
                            id="scrollableDiv"
                            style={{
                                height: 'calc(-200px + 100vh)',
                                overflow: 'auto',
                            }}
                        >
                            <InfiniteScroll
                                dataLength={eventsList?.length}
                                next={loadMoreData}
                                hasMore={(data?.meta?.last_page || 0) > (data?.meta?.current_page || 0)}
                                loader={<Skeleton paragraph={{ rows: 1 }} active />}
                                endMessage={null}
                                scrollableTarget="scrollableDiv"
                            >
                                <List
                                    className="my-events-cards-page__events-list"
                                    dataSource={eventsList}
                                    renderItem={(item: OrganizerEventDataItem) => (
                                        <List.Item key={item.id}
                                            style={{
                                                maxWidth: '420px'
                                            }}>
                                            <OrganizerEventsCard
                                                key={item.id}
                                                event={item}
                                            />
                                        </List.Item>
                                    )}
                                    bordered={false}
                                />
                            </InfiniteScroll>
                        </div>
                    )
                }
            </ContentLayout>
        </EventsPageLayout>
    )
}

export default MyEventsCardsPage
