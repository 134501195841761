import React, { useState, useMemo, useEffect } from 'react'

import { Spin } from 'antd';

import { useGetCitiesBySearchQuery } from '@store/main/cities/cities.api';
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { INIT_CURRENT_PAGE } from "@shared/constants.ts";

interface SuggestSelectCitiesProps {
    value: {
        id: number;
        slug: string;
        country?: {
            id: number;
            slug: string;
        }
    } | null;
    countryValue: {
        id: number;
        slug: string;
    } | null;
    onChange?: (value: {
        id: number;
        slug: string;
    } | null) => void;
    placeholder: string;
}

const SuggestSelectCities: React.FC<SuggestSelectCitiesProps> = ({
    value,
    countryValue,
    onChange,
    placeholder
}) => {
    const [countrySearchValue, setCountrySearchValue] = useState<string>()
    const [page, setPage] = useState(INIT_CURRENT_PAGE)

    const {
        data: countries,
        isLoading: countriesLoading,
        isFetching: isCountriesFetching
    } = useGetCitiesBySearchQuery(
        {
            search: countrySearchValue || '',
            countries: countryValue?.id
                ?
                countryValue.id.toString()
                :
                '',
            page
        }
    )

    useEffect(() => {
        setPage(INIT_CURRENT_PAGE)
    }, [countryValue]);

    const isLoading = countriesLoading || isCountriesFetching

    const handleChange = (newOption: {
        value: number;
        label: string;
    }) => {
        const newValue = newOption?.value ? {
            id: newOption.value,
            slug: newOption.label,
            ...newOption
        } : null
        onChange && onChange(newValue)
        setCountrySearchValue(undefined)
    }

    const currentValue = useMemo(() => {
        if (!value) {
            return null
        }
        if (value?.country?.id !== countryValue?.id) {
            return null
        }
        const { slug, id } = value || {}
        return { value: id, label: slug }
    }, [value, countryValue])

    return (
        <InfiniteSelect
            id="suggest-select-cities"
            placeholder={placeholder}
            style={{ width: '100%' }}
            filterOption={false}
            notFoundContent={isLoading ? <Spin size="small"/> : <div>There is no cities</div>}
            isLoading={isLoading}
            listHeight={190}
            onChange={(_, newOption) => {
                handleChange(newOption as {
                    value: number;
                    label: string;
                })
            }}
            page={page}
            setPage={setPage}
            data={countries}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={currentValue}
            allowClear
            showSearch
            setSearchValue={(val) => {
                setCountrySearchValue(val)
            }}
            optionsMapper={({ slug, id, ...rest }) => ({ value: id.toString(), label: slug, slug, id, ...rest })}
        />
    )
}

export default SuggestSelectCities
