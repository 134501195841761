import React, {useContext, useMemo} from 'react';
import './styles.scss'
import { JoditOptionName } from './assets/models';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import { PartnerLandingSectionLayout } from "@store/type-partner/landings/models.ts";
import ChangeSectionPopover
    from "@pages/PartnerRolePages/LandingPageConstructor/components/ChangeSectionPopover/ChangeSectionPopover.tsx";
import EditableHtml from "@pages/PartnerRolePages/LandingPageConstructor/components/EditableHtml/EditableHtml.tsx";
import CustomOptions from "@pages/PartnerRolePages/LandingPageConstructor/components/CustomOptions/CustomOptions.tsx";
import {sectionsNames, sectionsTitles} from "@pages/PartnerRolePages/LandingPageConstructor/constants.tsx";
import {Button} from "antd";
import EyeIcon from "@assets/EyeIcon.tsx";
import {POPOVER_IDS} from "@pages/PartnerRolePages/LandingPageConstructor/components/EditableHtml/constants.ts";
import { replaceMediaQueries } from "@pages/PartnerRolePages/LandingPageConstructor/helpers.ts";

interface HtmlEditorProps {
    id: string;
    idx: number;
    onCustomOptionClick: ({ option, id }: { option: JoditOptionName, id: string, selectedLayout?: PartnerLandingSectionLayout }) => void;
    layout: PartnerLandingSectionLayout
    isActive: boolean
    isHidden?: boolean
    popovers: Record<string, boolean>
    handleSetPopovers: (id: POPOVER_IDS, isOpen: boolean) => void
}

const HtmlEditor: React.FC<HtmlEditorProps> = ({
    id,
    onCustomOptionClick,
    layout,
    idx,
    isActive,
    isHidden,
    popovers,
    handleSetPopovers
}) => {
    const {
        updateSectionHtmlById,
        layoutSettingsData,
    } = useContext(LandingEditorContext);

    const replacedHtml = useMemo(() => replaceMediaQueries(layout.html), [layout.html])

    return (
        <div className="jodit-editor-wrapper" data-editor-id={id}>
            <ChangeSectionPopover
                id={id}
                layoutName={layout.name}
                onOptionClick={onCustomOptionClick}
            />

            {isHidden ? (
                <div className="hidden-section-component">
                    <div className="hidden-section-component__title">
                        {sectionsTitles[layout.name] || layout.name}
                    </div>
                    <div className='vertical-divider'/>
                    <div className="hidden-section-component__title">
                        {sectionsNames[layout.name] || layout.name}
                    </div>
                    <Button
                        className="hidden-section-component__btn"
                        icon={<EyeIcon fillColor="#57585A" withBorders={false} backgroundFillColor="transparent"/>}
                        type="text"
                        onClick={() => {
                            onCustomOptionClick({ option: JoditOptionName.Show, id })
                        }}
                    >
                        Show section
                    </Button>
                </div>
            ) : (
                <EditableHtml
                    id={id}
                    isActive={isActive && !layoutSettingsData}
                    initialHtml={replacedHtml}
                    onUpdate={(newContent: string) => {
                        updateSectionHtmlById({ id, html: newContent })
                    }}
                    popovers={popovers}
                    handleSetPopovers={handleSetPopovers}
                />
            )}

            <CustomOptions
                id={id}
                onOptionClick={onCustomOptionClick}
                layout={layout}
                idx={idx}
                isHidden={isHidden}
            />
        </div>
    );
};

export default HtmlEditor;

