// https://boost.allnetru.dev/api/documentation/partner#/Landings
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GetLandingLayoutsResponse,
    GetPartnerLandingByIdResponse,
    GetPartnerLandingTemplateResponse,
    LandingContentByIdResponse, LandingTypesResponse,
    PartnerLandingMainDataResponse,
    PartnerLandingRequest,
    PatchPartnerLandingRequest,
} from './models';


const baseUrl = import.meta.env.VITE_API_URL as string;
const partner_landings = `partner_landings`;

export const partnerLandings = createApi({
    reducerPath: partner_landings,
    baseQuery: fetchBaseQuery({
        baseUrl,
    }),
    tagTypes: [
        partner_landings
    ],
    //feature
    // refetchOnFocus: true,
    endpoints: (build) => ({
        getFilterPartnerLandings: build.query<any, { search?: string, page?: number }>({
            query: ({ search, page }) => {
                const params = new URLSearchParams();

                if (search && !(search.length > 0 && search.length < 3)) {
                    params.append('search', search.toString());
                }
                if (page) {
                    params.append('page', page.toString());
                }
                const queryString = params.toString();

                return {
                    url: `/partner/landings/filter/${queryString ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [partner_landings],
        }),
        getPartnerLandingTypes: build.query<LandingTypesResponse, void>({
            query: () => {
                return {
                    url: '/partner/landing-types',
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [partner_landings],
        }),
        getPartnerLandingContentById: build.query<LandingContentByIdResponse, { id: string }>({
            query: ({ id }) => {
                return {
                    url: `/partner/landing-types/${id}/content`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [partner_landings],
        }),

        //DETAILS or CARDS
        //for landings cards
        getPartnerLandingsByEventId: build.query<PartnerLandingMainDataResponse, { id: number, page?: number }>({
            query: ({ id, page }) => {
                const params = new URLSearchParams();
                if (page) {
                    params.append('page', page.toString());
                }
                const queryString = params.toString();
                return ({
                    url: `/partner/events/${id}/landings${queryString ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [partner_landings],
        }),
        deletePartnerLandingById: build.mutation<any, { event: string, landing: string }>({
            query: ({ event, landing }) => ({
                method: 'DELETE',
                url: `partner/events/${event}/landings/${landing}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            // invalidatesTags: [partnerLandings],
        }),

        // WYSIWYG EDITOR
        publishLanding: build.mutation<void, { eventId: string, landingId: string, status: number }>({
            query: ({ eventId, landingId, status }) => ({
                method: 'PATCH',
                url: `partner/events/${eventId}/landings/${landingId}/status/${status}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [partner_landings]
            // invalidatesTags: [billing],
        }),

        // for 'Add section' modal
        getLandingsLayoutsSections: build.query<GetLandingLayoutsResponse, { id: string }>({
            query: ({ id }) => {
                return ({
                    url: `/partner/events/${id}/landings/sections`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [partner_landings],
        }),

        // create new landing page
        postPartnerCreateLanding: build.mutation<GetPartnerLandingByIdResponse, PartnerLandingRequest>({
            query: ({ eventId, body }) => ({
                method: 'POST',
                url: `partner/events/${eventId}/landings`,
                headers: {
                    Accept: 'application/json',
                },
                body
            }),
        }),

        // edit or update landing page
        patchPartnerLandingById: build.mutation<GetPartnerLandingByIdResponse, PatchPartnerLandingRequest>({
            query: ({ eventId, landingId, body }) => ({
                method: 'PATCH',
                url: `partner/events/${eventId}/landings/${landingId}`,
                headers: {
                    Accept: 'application/json',
                },
                body
            }),
            invalidatesTags: [partner_landings]
        }),

        // fetch all data for editor
        getPartnerLandingById: build.query<GetPartnerLandingByIdResponse, { eventId: string, landingId: string }>({
            query: ({ eventId, landingId }) => ({
                url: `partner/events/${eventId}/landings/${landingId}`
            }),
            providesTags: [partner_landings]
        }),

        getPartnerLandingTemplateById: build.query<GetPartnerLandingTemplateResponse, { eventId: string }>({
            query: ({ eventId }) => ({
                url: `partner/events/${eventId}/landing-template`
            }),
            providesTags: [partner_landings]
        }),
    }),
});


//Lazy for clicks
export const {
    useGetFilterPartnerLandingsQuery,//TODO: check if BE is ready
    // new
    useDeletePartnerLandingByIdMutation,
    usePublishLandingMutation,//TODO: check if BE is ready

    // EDITOR
    useGetPartnerLandingsByEventIdQuery,
    useGetLandingsLayoutsSectionsQuery,
    usePostPartnerCreateLandingMutation,
    usePatchPartnerLandingByIdMutation,
    useGetPartnerLandingByIdQuery,
    useGetPartnerLandingTypesQuery,
    useGetPartnerLandingContentByIdQuery,
    useGetPartnerLandingTemplateByIdQuery,
} = partnerLandings;