import { FC } from 'react';
import { Button, Modal, } from 'antd';
import { useBreakpoints } from "@hooks/browser";

type Props = {
    isOpen: boolean
    onClose: () => void
    onRevoke: () => void
    isLoading: boolean
    credentialToRevoke: { id: number, translate: string }
}

const RevokeAccountModal: FC<Props> = ({
    isOpen,
    onClose,
    credentialToRevoke,
    onRevoke,
    isLoading
}) => {
    const isDesktop = useBreakpoints().md;

    return (
        <Modal
            title='Advertising Account Revocation'
            open={isOpen}
            onCancel={onClose}
            centered
            width={isDesktop ? 480 : 'calc(100vw - 40px)'}
            maskClosable={false}
            className="add-account-modal"
            footer={(
                <div
                    className="flex justify-end gap-10"
                >
                    <Button
                        size="large"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type='primary'
                        danger
                        disabled={!credentialToRevoke}
                        onClick={onRevoke}
                        loading={isLoading}
                    >
                        Revoke
                    </Button>
                </div>
            )}
        >
            <div className='mb-20'>
                <span className='text-modal-description-14'>Are you sure you want to revoke the “{credentialToRevoke?.translate}” account? </span>
                <div className='mt-8' />
                <span className='text-modal-description-14'>Campaigns launched on this account will be paused.</span>
            </div>
        </Modal>
    )
}

export default RevokeAccountModal;
