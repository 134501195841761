import React from 'react';
import './styles.scss';

const CommonTermsOfServicePage: React.FC = () => {
    return (
        <div className="terms-of-service">
            <header className="tos-header">
                <h1>Terms of Service</h1>
                <p>Effective date: April 27, 2024</p>
            </header>

            <section className="tos-intro">
                <p>
                    The Boost Express platform simplifies launching and management of co-marketing advertising campaigns for the Organizer and Sponsor, making it more technological, transparent, and comfortable. This improves and develops the advertising industry and provides product information to interested consumers.
                </p>
                <p>
                    The Terms of Service (“Agreement”) are a legally binding agreement between Boost.Express and Users of the Boost.Express Platform.
                </p>
                <p>
                    By using the Boost.Express Platform, User agrees to the terms and conditions outlined below. This agreement applies regardless of whether the use of the platform is intentional or unintentional.
                </p>
                <p>
                    User agrees to accept and abide by these Terms of Service as presented; changes, additions, or deletions are not agreed to by Boost.Express, and Boost.Express may deny access to the Platform for noncompliance with any part of these Terms of Service or for any other reason at Boost.Express' sole discretion.
                </p>
            </section>

            <section className="tos-section">
                <h2>1. DEFINITIONS</h2>
                <ol>
                    <li>
                        <strong>“Ad Creative”</strong> means visual and textual elements of an advertisement used by a Sponsor to promote its product or services, available for publication and moderation by Boost.Express, and Third Party Media.
                    </li>
                    <li>
                        <strong>“Campaign”</strong> means a strategic marketing initiative aimed at promoting a product, service, or brand, often employing various channels to achieve specific goals such as increasing sales, enhancing brand awareness, or driving customer engagement, consisting of an Ad Creative and a particular set of settings, such as targeting, strategy, bidding, optimizations, schedule, etc.
                    </li>
                    <li>
                        <strong>“Boost.Express”</strong> means Boost.Express Incorporated, located at 251 Little Falls Drive, Wilmington, New Castle County, Delaware 19808.
                    </li>
                    <li>
                        <strong>“Event”</strong> means a project created by the Organizer, with a corresponding page on the Platform.
                    </li>
                    <li>
                        <strong>“Internet User”</strong> means an individual who gains access to resources, services, and utilities provided through the Internet.
                    </li>
                    <li>
                        <strong>“Landing Page”</strong> is a single web page that is created specifically for the purposes of a marketing or advertising campaign. It serves as the destination page for visitors who click on a hyperlink, such as advertisements, search engine results, social media links, or email marketing links.
                    </li>
                    <li>
                        <strong>“Online Advertising Publishing”</strong> means the placement of the Sponsor's Ad Creative on Organizer’s Third Party Media. Boost.Express, Organizer and Third Party Media have a right to verify the advertising requirements before publishing the online ads.
                    </li>
                    <li>
                        <strong>“Organizer”</strong> means a legal entity or an individual, who creates an Event and, who places Sponsor’s Ad Creative using Organizer’s declared Third Party Media.
                    </li>
                    <li>
                        <strong>“Sponsor”</strong> means a legal entity or an individual, who determines the object and/or content of Ad Creative and offers to place it on the Third Party Media, as well as determines the Campaign goals.
                    </li>
                    <li>
                        <strong>“Personal Account”</strong> means a secure part of the Platform created during registration and authorization of the User on the Platform, allowing the User to use the functionality of the Platform.
                    </li>
                    <li>
                        <strong>“Platform”</strong> means a software product that provides advertising services under CPC (Cost Per Click) model, with payment for certain actions of Internet Users in Third Party Media (Clicks).
                    </li>
                    <li>
                        <strong>“Third Party Media”</strong> means social networks and online advertising platforms, including but not limited to Google Ads, LinkedIn Ads, Facebook, Instagram, and others, where the Organizer has an active account. The Organizer is solely responsible for any consequences resulting from the use of these selected Third Party Media.
                    </li>
                    <li>
                        <strong>“User”</strong> means the individual accessing or using the Platform, or the company, or other legal entity on behalf of which such individual is accessing or using the Platform, as applicable.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>2. FUNDAMENTALS OF USING THE PLATFORM</h2>
                <ol>
                    <li>
                        The Platform offers User the service of organizing advertising interactions. Sponsors can place the Ad Creative through their Personal Account. After moderation, Ads Creative are published on Organizer’s active accounts on Third Party Media.
                    </li>
                    <li>
                        This Terms of Service governs the relationship between Boost.Express and Users. It sets forth the terms and conditions under which the User may access and use the Platform's functionality and content.
                    </li>
                    <li>
                        Users may use the Platform only within the limits of those rights and in the ways provided for in this User Agreement, the Privacy Policy, and other separate agreements on providing access to the Platform.
                    </li>
                    <li>
                        The User is authorized to use the Platform and its functionality for their intended purpose. This includes accessing the Platform via personal computers or mobile devices and using the explicit functions of the Platform, Personal Account, and their features. The authorization is valid for the period during which the Platform and its functions remain available to the User.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>3. PERSONAL ACCOUNT</h2>
                <ol>
                    <li>
                        <strong>Registration.</strong> To use the Platform, the User shall register and create a unique Personal Account.
                    </li>
                    <li>
                        During registration, the User shall provide accurate and complete information as requested in the registration form and keep it up to date. At the same time, the User may also choose to register the Account by identification through OAuth providers.
                    </li>
                    <li>
                        If the User provides inaccurate information or Boost.Express has reason to believe that any information provided by a User is incomplete or unreliable, Boost.Express may, at its sole discretion, block or delete the User’s Personal Account or limit their access to any of Platform’s functionality.
                    </li>
                    <li>
                        Boost.Express reserves the right at any time to require the User to confirm the information provided in the Personal Account and the information containing information about the User, as well as other information related to the use of the Boost.Express Platform, which the User can access by logging into the Personal Account.
                    </li>
                    <li>
                        Boost.Express reserves the right to verify the information provided during registration and request supporting documents from the User, including identification documents, documents of the Company, and its representatives. Failure to provide these documents may be considered as providing unreliable information at the discretion of Boost.Express. If the information provided in the documents does not match the information provided during registration, or if the information provided during registration does not identify the User, Boost.Express reserves the right to deny access to the Platform and Personal Account.
                    </li>
                    <li>
                        Boost.Express stores and processes any User’s information contained in the Personal Account in accordance with the Privacy Policy.
                    </li>
                    <li>
                        The User shall promptly inform Boost.Express of any instances of unauthorized access to the Platform through their Personal Account and any suspected breaches of confidentiality regarding their chosen method of access.
                    </li>
                    <li>
                        <strong>Blocking of Personal Account.</strong> Boost.Express reserves the right to block or delete a Personal Account and restrict access to certain Platform functionality, without explanation, if the User violates the terms of this Agreement or any documents listed in Section 2.3.
                    </li>
                    <li>
                        <strong>Deletion of Personal Account.</strong> The User may delete their Personal Account at any time, which will result in the unavailability of Platform access. However, deleting the Personal Account does not release the User from any existing obligations or liability.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>4. INTELLECTUAL PROPERTY</h2>
                <ol>
                    <li>
                        Boost.Express and its affiliates own and retain all rights, title, and interest in and to the Platform along with all patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how, and any other intellectual property and/or proprietary rights (“Intellectual Property Rights”) related to the Platform. Using the Platform under this Agreement does not give User additional rights in the Platform or ownership of any Intellectual Property Rights associated with the Platform. Subject to User’s compliance with and limitations set forth in this Agreement and upon User’s subscription to the Platform, Boost.Express grants User a non-exclusive, non-transferable, non-sublicensable, revocable right to access and use Platform.
                    </li>
                    <li>
                        Elements of the Platform are also protected by trade dress, trade secret, unfair competition, and other laws and may not be copied or imitated in whole or in part. All custom graphics, icons, and other items that appear on the Platform are trademarks, service marks or trade dress (“Marks”) of Boost.Express, its affiliates or other entities that have granted Boost.Express the right and license to use such Marks and may not be used or interfered with in any manner without the express written consent of Boost.Express. Except as otherwise expressly authorized by these Terms, User may not copy, reproduce, modify, lease, loan, sell, create derivative works from, upload, transmit, or distribute the Intellectual Property of the Platform in any way without Boost.Express’ written permission. Except as expressly provided herein, Boost.Express does not grant to User any expressed or implied rights to Boost.Express’ or any third party’s Intellectual Property.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>5. ADS CREATIVE REQUIREMENTS</h2>
                <ol>
                    <li>
                        The User is solely responsible for the compliance of the content of the placed Ad Creative with the requirements of the applicable law, as well as the terms and conditions of Boost.Express, Third Party Media, including liability to third parties in cases where the User’s placement of particular Ad Creative or its content violates the rights and legitimate interests of third parties, including the intellectual rights of third parties.
                    </li>
                    <li>
                        <strong>Boost.Express Ad Creative Requirements.</strong> Ad Creative created using the Platform shall meet the specified requirements. The Ad Creative are not accepted, if Ad Creative:
                        <ol type="a">
                            <li>
                                uses profanity, obscene and insulting images, comparisons and expressions in relation to gender, race, nationality, profession, social categories, age, religious symbols, official state symbols;
                            </li>
                            <li>
                                contains dangerous content, promotes products or services that cause damage, harm or injury. In particular, but without limitation, examples of dangerous content include: recreational drugs (chemical or herbal); psychoactive substances; equipment to facilitate drug use; guns, ammunition, explosives and fireworks; instructions for making explosives or other harmful products; and tobacco products;
                            </li>
                            <li>
                                is unfair, unreliable, misleading to the consumer, including: contains misleading information about the product/service, contains misleading information about discounts/promotions/sales, their terms and rules, contains/imitates standard elements of the user graphic interface that do not have the appropriate functionality directly in the advertising material, does not give a clear idea of who is the source of information contained in the advertising message;
                            </li>
                            <li>
                                incites unlawful acts and/or calls for violence and cruelty;
                            </li>
                            <li>
                                has shocking content or promotes hatred, intolerance, discrimination, or violence. Examples of inappropriate or offensive content: bullying or intimidation of an individual or group, racial discrimination, hate group paraphernalia, graphic crime scene or accident images, cruelty to animals, murder, self-harm, extortion or blackmail, sale or trade of endangered species, ads using profane language;
                            </li>
                            <li>
                                is an advertisement of a product that is prohibited to be advertised in that manner, at that time or place;
                            </li>
                            <li>
                                is related to tragic events, if such announcements are aimed at attracting attention to relevant news/publications (including shocking content).
                            </li>
                        </ol>
                    </li>
                    <li>
                        Values shall be specified in US Dollars and, if necessary, in foreign currency.
                    </li>
                    <li>
                        The Campaign along with Ads Creative, shall correspond to the Landing page to which the advertising link leads. For instance, if the advertisement contains information about a discount, the advertising link should lead to the page where the discount is explicitly stated.
                    </li>
                    <li>
                        <strong>Third Party Media Ads Creative Requirements.</strong> Boost.Express is not responsible for any additional requirements for Ad Creatives and Ad Creative placed on Third Party Media platforms, including but not limited to GoogleAds, FacebookAds, LinkedIn Ads, Instagram, and other advertising platforms. The User is solely responsible for ensuring that their Ad Creative comply with such requirements. Boost.Express is not responsible for any refusal to place advertisements due to non-compliance with Third Party Media’s Ad Creative requirements.
                    </li>
                    <li>
                        <strong>Moderation.</strong> In order to publish Ad Creative, Boost.Express, Organizer, and Third Party Media shall moderate the Ad Creative to ensure that it meets the requirements.
                    </li>
                    <li>
                        Boost.Express on its part is not responsible for the Online Ad submitted by the User, is not obliged to check them, as well as the content of the Campaign, for accuracy and for violation of the rights of third parties. The credibility and legality of the material is presumed by Boost.Express, as well as the User’s good faith.
                    </li>
                    <li>
                        As part of the moderation process, Boost.Express verifies the compliance of the advertising material with the above requirements, after which the advertising material ready for publication is sent to the Third Party Media for verification with further publication, if successful.
                    </li>
                    <li>
                        By providing access to any third-party services - Third Party Media, User acknowledges that its use of such Third Party Media is governed solely by the terms of use and privacy policies of such third-party services and that it shall comply with all such terms and policies. Boost.Express has no control over the moderation of Third Party Media. So if the Third Party Media refuses to place Ad Creative for the reasons specified in their terms of use and/or if the Ads Creative do not comply with the terms of advertising placement by the Third Party Media, Boost.Express shall not be liable to the User for such refusal.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>6. PAYMENTS</h2>
                <ol>
                    <li>
                        All payments are made through the Platform functionality with the third party’s involvement.
                    </li>
                    <li>
                        The User himself/herself shall indicate the payment details of each User for the funds transfer. By indicating the payment information, the User confirms and guarantees that the information they have provided is true and complete.
                    </li>
                    <li>
                        The Subscription is made by recurring payments or through a one-time charge, depending on the service chosen by the User.
                    </li>
                    <li>
                        Sponsors are solely responsible for determining the pricing applicable to Clicks and the Ad Creative to which access may be granted.
                    </li>
                    <li>
                        All charges related to transfer of funds, including but not limited to wire transfer, letter of credit, and confirmation charges, will be borne by the User, unless otherwise agreed in writing.
                    </li>
                    <li>
                        Boost.Express can change the third-party payment providers used to process payments on the Platform.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>7. RESTRICTIONS ON THE USE</h2>
                <ol>
                    <li>
                        Except to the extent required by law or as expressly provided herein, none of the content and/or information may be reverse-engineered, modified, reproduced, republished, translated into any language or computer language, re-transmitted in any form or by any means, resold or redistributed without the prior written consent of Boost.Express. User may not make, sell, offer for sale, modify, reproduce, display, publicly perform, import, distribute, retransmit or otherwise use the content, Platform and Marks in any way, unless expressly permitted to do so by Boost.Express.
                    </li>
                    <li>
                        In addition to other restrictions set forth in these Terms, User agrees that:
                        <ol type="a">
                            <li>
                                User shall not disguise the origin of information transmitted through the Platform.
                            </li>
                            <li>
                                User will not place false or misleading information on the Platform.
                            </li>
                            <li>
                                User will not use or access any service, information, application, or software available via the Platform in a manner not expressly permitted by Boost.Express.
                            </li>
                            <li>
                                User will not input or upload to the Platform any information which contains viruses, trojan horses, worms, time bombs or other computer programming routines that are intended to damage, interfere with, intercept or expropriate any system, the Platform, or information or that infringes the Intellectual Property rights of another.
                            </li>
                            <li>
                                User may not use or access the Platform in any way that, in Boost.Express judgment, adversely affects the performance or function of the Platform or interferes with the ability of authorized parties to access the Platform.
                            </li>
                            <li>
                                User may not frame or utilize framing techniques to enclose any portion or aspect of the content or the information, without the express written consent of Platform.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Any use of automated inquiry devices, robots, or repetitive data gathering and extraction tools, routines, scripts or other mechanisms with similar functionality is expressly prohibited.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>8. TERMINATION</h2>
                <ol>
                    <li>
                        Boost.Express may terminate or suspend this Agreement at any time without notice to User. Without limiting the foregoing, Boost.Express may, in its sole discretion, terminate User’s access or use of the Platform and/or block User’s future access to the Platform for cause, which includes, but is not limited to: (1) Boost.Express determination that User violated this Agreement or other agreements or guidelines which may be associated with the use of the Platform, (2) discontinuance or a material modification to the Platform, or (3) unexpected technical issues or problems.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>9. WARRANTY</h2>
                <ol>
                    <li>
                        THE PLATFORM IS PROVIDED ON AN “AS IS” BASIS, WITHOUT ANY WARRANTIES, GUARANTEES, CONDITIONS, OR REPRESENTATIONS OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, BOOST EXPRESS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, EXPRESS OR IMPLIED WARRANTIES OF MERCHANTABILITY, DESIGN, TITLE, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT ON BEHALF OF BOOST EXPRESS AND ITS AFFILIATES AND ITS AND THEIR LICENSORS. BOOST EXPRESS AND ITS AFFILIATES RELY ON THIRD PARTY DATA SOURCES FOR INFORMATION AND THEREFORE BOOST EXPRESS CANNOT AND DOES NOT WARRANT THAT THE USE OF PLATFORM WILL BE UNINTERRUPTED, AVAILABLE, ACCESSIBLE, SECURE, TIMELY, ACCURATE, COMPLETE, FREE FROM VIRUSES, OR ERROR-FREE. BOOST EXPRESS DISCLAIMS ALL LIABILITY FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR POOR USE CONDITIONS OF THE USE OF PLATFORM DUE TO INAPPROPRIATE EQUIPMENT, INTERRUPTIONS, OR OTHER ISSUES RELATED TO INTERNET AND ELECTRONIC COMMUNICATIONS SERVICE PROVIDERS, OR ANY OTHER DELAY, ERROR, OMISSION, INTERRUPTION, DELETION, THEFT, DESTRUCTION, UNAUTHORIZED ACCESS TO, OR LOSS OF DATA ON THE PLATFORM, ALL OF WHICH ARE NOT WITHIN BOOST EXPRESS’ REASONABLE CONTROL. BOOST EXPRESS DOES NOT PROVIDE ANY WARRANTIES, INDEMNITIES OR REMEDIES FOR ANY FREE TRIALS OR BETA USE OF PLATFORM OR ANY FEATURE OF THE USE OF PLATFORM IN BETA OR IN A FREE TRIAL VERSION. BETA USE OF PLATFORM AND FREE TRIALS ARE OPTIONAL AND ARE USED AT YOUR OWN RISK.
                    </li>
                    <li>
                        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BOOST EXPRESS HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, TITLE, AND FITNESS FOR A PARTICULAR PURPOSE. NO STATEMENT OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM BOOST EXPRESS IN ANY MEANS OR FASHION SHALL CREATE ANY WARRANTY NOT EXPRESSLY OR EXPLICITLY SET FORTH IN THIS AGREEMENT. THE CONTENT ON THE PLATFORM MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>10. LIMITATION OF THE LIABILITY</h2>
                <ol>
                    <li>
                        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL BOOST EXPRESS, ITS AFFILIATES, LICENSORS, AND SERVICE PROVIDERS, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, CONTRACTORS, AGENTS, SUCCESSORS, AND ASSIGNS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR OTHER TYPE OF DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR COVER OR LOSS OF USE, DATA, REVENUE OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE PLATFORM, WITH THE DELAY OR INABILITY TO USE THE PLATFORM, OR FOR ANY CONTENT OR ITEM ON THE PLATFORM, OR OTHERWISE ARISING OUT OF THE USE OF THE PLATFORM (“DAMAGES”), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, FAILURE OF ANY LIMITED REMEDY TO ACHIEVE ITS ESSENTIAL PURPOSE, OR OTHERWISE, EVEN IF BOOST EXPRESS OR ITS AFFILIATES OR OTHER RELATED ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. USER UNDERSTANDS AND AGREES THAT USER ASSUMES ALL RESPONSIBILITY FOR THEIR USE OF THE PLATFORM AND THAT USER WILL BE SOLELY RESPONSIBLE FOR ANY AND ALL DAMAGES ARISING FROM THEIR USE OF THE PLATFORM. NOTWITHSTANDING THIS SECTION, IF BOOST EXPRESS IS DETERMINED TO HAVE ANY LIABILITY TO USER OR ANY THIRD PARTY FOR ANY LOSS, HARM, OR DAMAGE, USER AGREES THAT THE MAXIMUM AGGREGATE LIABILITY OF BOOST EXPRESS AND ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, LICENSORS, SERVICE PROVIDERS, CONTRACTORS, OR AGENTS SHALL, IN ALL CASES, BE LIMITED TO FIFTY ($50) U.S. DOLLARS.
                    </li>
                    <li>
                        THIS SECTION DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO USER. IN SUCH STATES OR JURISDICTIONS, BOOST EXPRESS LIABILITY IS LIMITED TO THE LESSER OF (A) FIFTY ($50) US DOLLARS USD OR (B) THE EXTENT OF THE AMOUNT PERMITTED BY LAW, THEREBY MINIMIZING OUR LIABILITY TO USER TO EITHER FIFTY ($50) US DOLLARS OR THE LOWEST AMOUNT PERMITTED BY APPLICABLE LAW.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>11. INDEMNIFICATION</h2>
                <ol>
                    <li>
                        To the fullest extent permitted by applicable law, User agrees to indemnify, defend, and hold harmless Boost.Express and our affiliates, and each of our respective employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns (individually and collectively, the “Boost.Express Parties”), from and against all actual or alleged claims, damages, awards, judgments, losses, liabilities of every kind, and nature whatsoever, whether known or unknown, that are caused by, arise out of, or are related to (a) using or misusing of the Platform, (b) User’s violation of this Agreement, and/or (c) User’s violation of any right(s) of any third party. User agrees to promptly notify Boost.Express of any Claim(s) and shall cooperate fully with the Boost.Express Parties in defending such Claims. User further agrees that the Boost.Express Parties shall have control of the defense or settlement of any third party Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN ANY WRITTEN AGREEMENT(S) BETWEEN USER AND BOOST EXPRESS.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>12. MISCELLANEOUS</h2>
                <ol>
                    <li>
                        <strong>Entire Agreement.</strong> This Agreement constitutes the sole and entire agreement between User and Boost.Express with respect to the subject matter hereof, and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Platform.
                    </li>
                    <li>
                        <strong>Modification.</strong> Boost.Express reserves the right to modify our Terms of Service at any time by posting the modified terms on the Platform and without providing User with prior notice. User’s continued use of the Platform shall be deemed to constitute acceptance by User of such modifications. The updated Terms of Service will be effective as of the time of posting, or on such later date as may be specified in the updated Terms of Service, and will apply to the use of the Platform from that point forward. The Effective Date set forth above indicates the last date the Terms of Service were updated, so be sure to check back for updates. Boost.Express may also change or discontinue any aspect, service, or feature of the Platform at any time, including but not limited to content, availability, and equipment needed for access and use, without notice.
                    </li>
                    <li>
                        <strong>Waiver.</strong> No waiver by Boost.Express of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default.
                    </li>
                    <li>
                        <strong>Severability.</strong> If any provision of this Agreement is held by a court or other tribunal of competent jurisdiction to be invalid, unenforceable, or illegal for any reason, such provision shall be limited to the minimum extent such that the remaining provisions of the Agreement will continue in full force and effect.
                    </li>
                    <li>
                        <strong>Force Majeure.</strong> Neither party will be liable for or be considered to be in breach or default of this Agreement on account of any delay or failure to perform as required by this Agreement (except for User’s obligations to make payments to Boost.Express hereunder) as a result of any cause or condition beyond its reasonable control, so long as that party uses commercially reasonable efforts to avoid or remove the causes of non-performance.
                    </li>
                    <li>
                        <strong>Governing Law.</strong> This Agreement will be interpreted, construed, and enforced in all respects in accordance with the local laws of the State of Delaware, U.S.A.
                    </li>
                    <li>
                        <strong>Assignability.</strong> This Agreement is personal to User, and is not assignable, transferable, or sublicensable by User except with Boost.Express’ prior written consent.
                    </li>
                    <li>
                        <strong>Notices.</strong> All notices to Boost.Express under this Agreement must be in writing and sent electronically to <a href="mailto:legal-request@boost.express">legal-request@boost.express</a> with a subject line “Notice under Boost.Express Terms of Service”, and will be deemed to have been duly given receipt is electronically confirmed.
                    </li>
                </ol>
            </section>

            <section className="tos-section">
                <h2>13. CONTACT US</h2>
                <ol>
                    <li>
                        If you have any questions about our Platform or these Terms of Service, please email us at <a href="mailto:hello@boost.express">hello@boost.express</a>.
                    </li>
                </ol>
            </section>
        </div>
    );
};

export default CommonTermsOfServicePage;
