const MobileIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_20451_37825)">
                <path
                    d="M12.1395 -0.0390625H3.85379C3.22344 -0.0390625 2.71094 0.473438 2.71094 1.10379V14.8181C2.71094 15.4484 3.22344 15.9609 3.85379 15.9609H12.1395C12.7699 15.9609 13.2824 15.4484 13.2824 14.8181V1.10379C13.2824 0.473438 12.7699 -0.0390625 12.1395 -0.0390625ZM11.9967 14.6752H3.99665V1.24665H11.9967V14.6752ZM7.28237 12.8538C7.28237 13.0432 7.35762 13.2249 7.49158 13.3589C7.62553 13.4928 7.80721 13.5681 7.99665 13.5681C8.18609 13.5681 8.36777 13.4928 8.50173 13.3589C8.63568 13.2249 8.71094 13.0432 8.71094 12.8538C8.71094 12.6644 8.63568 12.4827 8.50173 12.3487C8.36777 12.2148 8.18609 12.1395 7.99665 12.1395C7.80721 12.1395 7.62553 12.2148 7.49158 12.3487C7.35762 12.4827 7.28237 12.6644 7.28237 12.8538Z"
                    fill="#898A8C"
                />
            </g>
            <defs>
                <clipPath id="clip0_20451_37825">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MobileIcon
