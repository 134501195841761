import { campaigns, events, events_edit } from '@pages/routes';
import { GetEventByIdFromCatalogDataItem } from "@store/type-partner/events-catalog/models";
import { formatDateToCommonFormat } from "@shared/utils.ts";

const getCrumbs = (pathname: string, eventData?: GetEventByIdFromCatalogDataItem) => {
    const isEvents = pathname.includes(events)
    if (isEvents) {
        return ([
            {
                url: events,
                label: 'My events',
                idx: 1,
            },
            {
                url: eventData?.id ? `${events_edit}/${eventData.id}` : events,
                label: eventData?.name || 'Event',
                idx: 2
            },
            {
                label: 'Campaign Editor',
                url: pathname,
                idx: 3
            }
        ])
    }
    return ([
        {
            url: campaigns,
            label: 'Campaigns',
            idx: 1,
            isZeroLevel: true
        },
        {
            label: 'Campaign Editor',
            url: pathname,
            idx: 2
        }
    ])
}

export const getCampaignDefaultName = (eventData?: GetEventByIdFromCatalogDataItem) => {
    const name = (eventData?.name?.length || 0) > 31 ? `${eventData?.name.slice(0, 31)}...` : eventData?.name
    return `${name || 'Event name'} - ${formatDateToCommonFormat(new Date())}`
}

export default getCrumbs
