import IslandLayout from '@layouts/IslandLayout';
import { Input, Upload } from 'antd'
import React, { RefObject, useContext, useEffect, useState } from 'react'
import './styles.scss'
import CustomButton from '@components/CustomButton';
import { CloseOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import AssetImagesDrawer from './AssetImagesDrawer';
import { useParams } from 'react-router-dom';
import { CampaignEditorContext } from '@contexts/CampaignEditorContext';
import InfoIcon from '@icons/header/InfoIcon';
import { CheckCircleIcon } from '@icons/CheckCircleIcon';
import { getCheckTypeColor, getImageType, getImageTypes } from '../../helpers';
import { ImageTypes } from '@shared/types';
import {
    BUSINESS_NAME_MAX_COUNT,
    DESCRIPTION_MAX_COUNT,
    SHORT_TITLE_MAX_COUNT,
    TITLE_MAX_COUNT
} from "@pages/PartnerRolePages/Campaigns/CampaignEditorPage/constants";
import { useBreakpoints } from '@hooks/browser';

const baseUrl = import.meta.env.VITE_API_URL as string;
const action = baseUrl + '/partner/landings/image'

type Props = {
    adCreativesRef?: RefObject<HTMLDivElement>;
}

const AssertForm = ({ adCreativesRef }: Props) => {
    const {
        formValues: form,
        formErrors,
        setFormErrors,
        handleFormChange: onChange,
        errorNotification,
        //LOADERS
        isLoading,
        setLoading,
        isSaving,
        setSaving,
        isLogoLoading,
        setLogoLoading,
        isImagesLoading,
        setImagesLoading,
        // FORM
        onTitlesChange,
        onAddTitle,
        onDeleteTitle,
        onTitlesShortChange,
        onAddTitleShort,
        onDeleteTitleShort,
        updateImagesListHandler,
        onDeleteImage,
        updateLogosListHandler,
        onDeleteLogo,
        onAssetSingleItemChange
    } = useContext(CampaignEditorContext);
    const isDesktop = useBreakpoints().md
    const { eventId } = useParams()

    const titles = form.ads[0]?.assets?.title || []
    const shortTitles = form.ads[0]?.assets?.short_title || []
    const imagesList = form.ads[0]?.assets?.image_original || []
    const logosList = form.ads[0]?.assets?.logo_original || []
    const businessNames = form.ads[0]?.assets?.business_name || []

    const [isShowImagesDrawer, setShowImagesDrawer] = useState(false)
    const [imagesTypes, setImagesTypes] = useState<Record<string, string>>({})

    useEffect(() => {
        getImageTypes(imagesList)
            .then((res) => {
                setImagesTypes(res as unknown as Record<string, string>)
            })
            .catch((error) => console.error(error))
    }, [])

    const uploadLogosButton = (
        <div className={`campaign-create__img-uploader-btn`}
        >
            {isLogoLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div>Upload logo</div>
        </div>
    );

    const uploadImagesButton = (
        <div className={`campaign-create__img-uploader-btn`}
        >
            {isImagesLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div>Upload Creative Images</div>
        </div>
    );

    const handleImagesUpload: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>): void => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setFormErrors({})
        if (info.file.status === 'uploading') {
            setImagesLoading(true);
            return;
        }
        if (info.file.status === 'error') {
            setImagesLoading(false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            const text: string = info.file.response?.message || ''
            setFormErrors((prev) => ({ ...prev, images: 'Uploaded file is not a valid image. Only JPG, PNG and GIF files are allowed' }));

            errorNotification({ text })
            return;
        }
        if (info.file.status === 'done') {
            const imageType = await getImageType(info.file.originFileObj)
            setImagesLoading(false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (info.file?.response && info.file?.response?.data) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const url: string = info.file.response.data || ''
                url && updateImagesListHandler(url)
                if (url && imageType) {
                    setImagesTypes((prev) => ({ ...prev, [url]: imageType }))
                }
            }
        }
    };
    const handleLogoUpload: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setFormErrors({})
        if (info.file.status === 'uploading') {
            setLogoLoading(true);
            return;
        }
        if (info.file.status === 'error') {
            setLogoLoading(false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
            const text: string = info.file.response?.message || ''
            setFormErrors((prev: Record<string, string>) => ({ ...prev, 'logos': 'Uploaded file is not a valid image. Only JPG, PNG and GIF files are allowed' }));

            errorNotification({ text })
            return;
        }
        if (info.file.status === 'done') {
            setLogoLoading(false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (info.file?.response && info.file?.response?.data) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const url: string = info.file.response.data || ''

                url && updateLogosListHandler(url)
            }
        }
    };

    const handleDeleteImage = (idx: number, src: string) => {
        const newImagesTypes = { ...imagesTypes }
        delete newImagesTypes[src]
        setImagesTypes(newImagesTypes)
        onDeleteImage(idx)
    }

    return (
        <IslandLayout refProp={adCreativesRef}>
            <div>
                <p className='island-title'>
                    Ad Creatives
                </p>
                <p className={`text-regular-14 mb-4 text-neutral-8 flex ${formErrors['ads.0.assets.logo_original'] ? 'error' : ''}`}>
                    <span>Company logo</span>
                    <InfoIcon className='mx-4' fillColor='#57585A' width={14} height={14} />
                </p>
                <Upload
                    className={`campaign-create__img-uploader cursor-pointer ${formErrors.logos ? 'error' : ''}`}
                    name="image"
                    multiple
                    showUploadList={false}
                    action={action}
                    onChange={handleLogoUpload}
                >
                    {uploadLogosButton}
                </Upload>
                {formErrors['ads.0.assets.logo_original'] && (
                    <p className='text-subdued my-4 error'>
                        Please upload the campaign logo
                    </p>
                )}
                {formErrors['ads.0.assets.logo_original']
                    ?
                    null
                    :
                    <p className='text-subdued my-4 mb-12'>
                        Supports: .png, .jpg, .bmp. Max size: 1 MB. Image dimensions: 320×900 px
                    </p>
                }

                {logosList.length
                    ?
                    <div
                        className='assets-form__img-container'
                    >
                        {logosList.map((item, idx) => {
                            return (
                                <div
                                    key={idx.toString() + item.value}
                                    className='assets-form__img mr-6'
                                >
                                    <img
                                        src={item.value}
                                        className='logo'
                                    />
                                    <div className='delete-icon'
                                        onClick={() => onDeleteLogo(idx)}
                                    >
                                        <DeleteOutlined />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    null}

                <p className='text-regular-14 mb-4 text-neutral-8 flex'>
                    <span>Company name</span>
                    <InfoIcon className='mx-4' fillColor='#57585A' width={14} height={14} />
                </p>
                <div className='flex-center campaign-create__input-wrapper'>
                    <Input
                        count={{
                            show: true,
                            max: BUSINESS_NAME_MAX_COUNT,
                        }}
                        onChange={(e) => onAssetSingleItemChange({ key: 'business_name', value: e.target.value })}
                        value={form.ads[0]?.assets.business_name[0]?.value || ''}
                        status={formErrors['ads.0.assets.business_name.0.value'] ? 'error' : ''}
                    />
                </div>
                {formErrors['ads.0.assets.business_name.0.value'] && (
                    <p className='text-subdued error'>
                        Please provide the company name
                    </p>
                )}
                <p className='text-regular-14 mb-4 mt-12 text-neutral-8 flex'>
                    <span>Title</span>
                    <InfoIcon className='mx-4' fillColor='#57585A' width={14} height={14} />
                </p>
                {titles.map((title, idx) => {
                    return (
                        <div key={idx}>
                            <div className='campaign-create__input-wrapper mb-12 flex-center'>
                                <Input
                                    count={{
                                        show: true,
                                        max: TITLE_MAX_COUNT,
                                    }}
                                    value={title.value}
                                    onChange={(e) => onTitlesChange({ idx, value: e.target.value })}
                                    placeholder='Enter long title'
                                    status={formErrors['ads.0.assets.title.0.value'] ? 'error' : ''}
                                />
                                {titles.length > 1 ?
                                    <div
                                        className='ml-6 cursor-pointer'
                                        onClick={() => onDeleteTitle(idx)}
                                    >
                                        <CloseOutlined />
                                    </div>
                                    :
                                    null
                                }
                            </div>

                            {formErrors['ads.0.assets.title.0.value'] && (
                                <p className='text-subdued error'>
                                    Please provide the сreative’s title
                                </p>
                            )}

                            {idx + 1 === titles.length
                                ?
                                <div className='campaign-create__add-creative-cta'>
                                    <CustomButton
                                        title='+ Add New'
                                        onClick={() => onAddTitle()}
                                    />
                                </div>
                                :
                                null}
                        </div>

                    )
                })}

                <p className='text-regular-14 mb-4 mt-12 text-neutral-8 flex'>
                    <span>Short title</span>
                    <InfoIcon className='mx-4' fillColor='#57585A' width={14} height={14} />
                </p>
                {shortTitles.map((shortTitle, idx) => {
                    return (
                        <div key={idx}>
                            <div className='campaign-create__input-wrapper mb-12 flex-center'>
                                <Input
                                    count={{
                                        show: true,
                                        max: SHORT_TITLE_MAX_COUNT,
                                    }}
                                    value={shortTitle.value}
                                    onChange={(e) => onTitlesShortChange({ idx, value: e.target.value })}
                                    placeholder='Enter title'
                                    status={formErrors['ads.0.assets.short_title.0.value'] ? 'error' : ''}
                                />
                                {shortTitles.length > 1 ?
                                    <div
                                        className='ml-6 cursor-pointer'
                                        onClick={() => onDeleteTitleShort(idx)}
                                    >
                                        <CloseOutlined />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            {formErrors['ads.0.assets.short_title.0.value'] && (
                                <p className='text-subdued error'>
                                    Please provide the сreative’s short title
                                </p>
                            )}
                            {idx + 1 === shortTitles.length
                                ?
                                <div className='campaign-create__add-creative-cta'>
                                    <CustomButton
                                        title='+ Add New'
                                        onClick={() => onAddTitleShort()}
                                    />
                                </div>
                                :
                                null}
                        </div>

                    )
                })}
            </div>
            <p className='text-regular-14 label'>
                Description
            </p>
            <div className='campaign-create__input-wrapper mb-12 flex-center'>
                <Input
                    count={{
                        show: true,
                        max: DESCRIPTION_MAX_COUNT,
                    }}
                    onChange={(e) => onAssetSingleItemChange({ key: 'description', value: e.target.value })}
                    value={form.ads[0]?.assets.description[0]?.value || ''}
                    placeholder='My Ad Description'
                    status={formErrors['ads.0.assets.description.0.value'] ? 'error' : ''}
                />
            </div>
            {formErrors['ads.0.assets.description.0.value'] && (
                <p className='text-regular-14 label error campaign-create__input-error'>
                    Please provide description
                </p>
            )}

            <div className='divider campaign-create__divider' />

            <p className={`text-regular-14 mb-4 mt-12 text-neutral-8 flex ${formErrors['ads.0.assets.image_original'] ? 'error' : ''}`}>
                <span>Creative images</span>
                <InfoIcon className='mx-4' fillColor='#57585A' width={14} height={14} />
            </p>
            <div className='campaign-create__img-uploader-wrapper'>
                <Upload
                    className={`campaign-create__img-uploader cursor-pointer ${formErrors.images ? 'error' : ''}`}
                    name="image"
                    multiple
                    showUploadList={false}
                    action={action}
                    onChange={handleImagesUpload}
                >
                    {uploadImagesButton}
                </Upload>
                {!isDesktop && <p className={`text-subdued ${formErrors['ads.0.assets.image_original'] ? 'error' : ''} mt-8`}>
                    Supports: .png, .jpg, .bmp. Max size: 1 MB. Image dimensions: 320x900 px
                </p>}
                <div className={`campaign-create__img-uploader-tips text-regular-14 text-neutral-8 ${formErrors['ads.0.assets.image_original'] ? 'error' : ''}`}>
                    {isDesktop && <p className='mb-4'>To create an appealing ad campaign, provide the following image types:</p>}
                    <div className='flex'>
                        <CheckCircleIcon isActive={false} fillColor={getCheckTypeColor({ imagesTypes, type: ImageTypes.square })} />
                        <span className='mx-4'>at least one square image</span>
                    </div>
                    <div className='flex'>
                        <CheckCircleIcon isActive={false} fillColor={getCheckTypeColor({ imagesTypes, type: ImageTypes.horizontal })} />
                        <span className='mx-4'>at least one rectangular image</span>
                    </div>
                    <div className='flex'>
                        <CheckCircleIcon isActive={false} fillColor={getCheckTypeColor({ imagesTypes, type: ImageTypes.vertical })} />
                        <span className='mx-4'>at least one vertical image</span>
                    </div>
                </div>
            </div>
            {isDesktop && <p className={`text-subdued ${formErrors['ads.0.assets.image_original'] ? 'error' : ''}`}>
                Supports: .png, .jpg, .bmp. Max size: 1 MB.
            </p>}

            {imagesList.length
                ?
                <div
                    // onClick={() => setShowImagesDrawer(true)}
                    className='assets-form__img-container'
                >
                    {imagesList.map((item, idx) => {
                        return (
                            <div
                                key={idx.toString() + item.value}
                                className='assets-form__img mr-6'
                            >
                                <img
                                    src={item.value}
                                />
                                <div className='delete-icon'
                                    onClick={() => handleDeleteImage(idx, item.value)}                                >
                                    <DeleteOutlined />
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                null}

            {isShowImagesDrawer
                ?
                <AssetImagesDrawer
                    open={isShowImagesDrawer}
                    onClose={() => setShowImagesDrawer(false)}
                    images={imagesList}

                />
                :
                null}
        </IslandLayout>


    )
}

export default AssertForm