import { Button, Modal, Tooltip } from "antd";
import LogoutIcon from "@assets/LogoutIcon.tsx";
import React, { useState } from "react";
import { useAuth } from "@contexts/AuthContext.tsx";
import { registration } from "@pages/routes.ts";

const LogoutDropdown = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { logout } = useAuth()

    const onClose = () => {
        setIsModalOpen(false)
    }

    const onOk = () => {
        onClose()
        logout(registration)
    }

    return (
        <>
            <Tooltip
                overlayClassName='tooltip-general'
                title="Log out"
                placement="bottom"
            >
                <Button onClick={() => setIsModalOpen(true)} className="auth__sidebar" icon={<LogoutIcon />} />
            </Tooltip>
            <Modal
                className="logout-dropdown__modal"
                title="Log out"
                open={isModalOpen}
                onOk={onOk}
                width={440}
                centered
                onCancel={onClose}
                footer={[
                    <footer className='flex items-center justify-end mt-24'>
                        <Button
                            key="back"
                            onClick={onClose}
                            size='large'
                        >
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type='primary'
                            size='large'
                            onClick={onOk}
                        >
                            Log out
                        </Button>
                    </footer>
                ]}
            >
                Are you sure you want to log out? Your information will be saved
            </Modal>
        </>
    )
}

export default LogoutDropdown
