import EventsCard from '../../components/EventsCard'
import ContentLayout from '@layouts/ContentLayout'
import '../styles.scss'
import EventsPageLayout from '@layouts/EventsPageLayout'
import CustomButton from '@components/CustomButton'
import { Button, Pagination } from 'antd'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { INIT_CURRENT_PAGE, INIT_PAGE_SIZE, INIT_TOTAL } from '@shared/constants'
import { useGetPartnerEventQuery } from '@store/type-partner/events/partner-event.api'
import { GetPartnerEventDataItem } from '@store/type-partner/events/models'
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api'
import EventItemCardSkeleton
    from "@pages/PartnerRolePages/MyEvents/pages/MyEventsCardsPage/ZeroState/components/EventItemCardSkeleton";
import ZeroTableIcon from "@assets/zerostate/ZeroTableIcon";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@contexts/AuthContext";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics.tsx";
import { GTMEventName } from "@hooks/sendAnalytics/constants.ts";
import MyEventPageFilter from '../MyEventEditPage/components/MyEventsCardsFilter'
import { useBreakpoints } from '@hooks/browser'
import MyEventPageFilterMobile from '../MyEventEditPage/components/mobile/MyEventsCardsFilterMobile'
import { TourContext } from "@contexts/TourContext.tsx";
import { events_edit } from "@pages/routes.ts";
import NewTourComponent from "@components/TourComponent/NewTourComponent.tsx";
import { descriptionsEvent, EventsTourSteps, TourStages } from "@components/TourComponent/constants.tsx";
import {
    setTooltipCoordinatesPlacementBottom,
    setTooltipCoordinatesPlacementRight
} from "@components/TourComponent/helpers.ts";

// eslint-disable-next-line @typescript-eslint/ban-types
const debounce = (fn: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            fn(...args);
        }, delay);
    };
};
export interface MyEventsFilters {
    search: string;
    date: {
        from: string,
        to: string
    };
    statuses: string[]
    page: number
}
const initialFilters: MyEventsFilters = {
    search: '',
    date: {
        from: '',
        to: ''
    },
    statuses: [],
    page: INIT_CURRENT_PAGE
}

const MyEventsCardsPage = () => {
    const navigate = useNavigate();
    const isDesktop = useBreakpoints().md
    const isLg = useBreakpoints().lg
    const eventCardRef = useRef(null);
    const [searchParams] = useSearchParams();
    const isTourOpen = searchParams.get('is-tour');

    const {
        tourStep,
        setTourStep,
        setEventId,
        blockScroll,
        tourStage,
        setTourStage,
        setIsPlayerOpened,
        setIsInfoTourWasOpened,
        onFinishTour
    } = useContext(TourContext);

    const [filters, setFilters] = useState<MyEventsFilters>(initialFilters)
    const [searchValue, setSearchValue] = useState('')
    const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
    const { user } = useAuth()

    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_invite_status' })
    const { data: myEventsRes, isLoading, isFetching, refetch } = useGetPartnerEventQuery({
        filters: {
            ...filters,
            search: filters.search || undefined,
            date: {
                from: filters.date.from || undefined,
                to: filters.date.to || undefined,
            },
            statuses: filters.statuses.length > 0 ? filters.statuses : undefined,
        }
    }, {
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        if (isTourOpen) {
            setTourStage(TourStages.event)
            blockScroll()
            setIsPlayerOpened(true)
            setIsInfoTourWasOpened(false)
        }
    }, []);

    useEffect(() => {
        if (tourStage === TourStages.event && tourStep === EventsTourSteps.eventCard && !isLoading && !isFetching) {
            blockScroll()
            if (isLg) {
                setTooltipCoordinatesPlacementRight({ current: eventCardRef.current, id: "my-event-card-tour-component", config: { right: -10 } })
            } else {
                setTooltipCoordinatesPlacementBottom({ current: eventCardRef.current, id: "my-event-card-tour-component", isDesktop })
            }
        }
    }, [tourStage, isLg, myEventsRes, eventCardRef, tourStep, isLoading, isFetching]);

    const onResetFilters = () => {
        setFilters(initialFilters)
        onFilterUpdate(initialFilters)
        setSearchValue('')
    }

    const onFilterUpdate = (value: Partial<MyEventsFilters>) => {
        setFilters((prev) => ({ ...prev, ...value, page: INIT_CURRENT_PAGE }));
    };

    const debouncedOnSearchUpdate = useCallback(
        debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            onFilterUpdate({ search: e.target.value });
        }, 500),
        [onFilterUpdate]
    );

    useEffect(() => {
        void refetch()
    }, [])

    useEffect(() => {
        // Check if filters are not equal to initialFilters
        const filtersAreEqual =
            filters.search === initialFilters.search &&
            filters.date.from === initialFilters.date.from &&
            filters.date.to === initialFilters.date.to &&
            JSON.stringify(filters.statuses) === JSON.stringify(initialFilters.statuses);

        setFiltersChanged(!filtersAreEqual);
    }, [filters]);

    const onPaginationChange = (page: number) => {
        setFilters((prev) => ({ ...prev, page }))
    }

    const showPagination = (myEventsRes?.data?.length ?? 0) < (myEventsRes?.meta?.total ?? 0)
    return (
        <EventsPageLayout>
            <ContentLayout>
                {isDesktop
                    ? (
                        <div className='my-event-page__filter-container flex'>
                            <MyEventPageFilter
                                filters={filters}
                                statuses={statuses?.data}
                                searchValue={searchValue}
                                onFilterUpdate={onFilterUpdate}
                                onResetFilters={onResetFilters}
                                debouncedOnSearchUpdate={debouncedOnSearchUpdate}
                            />
                            <Button
                                type='primary'
                                className="ml-auto mb-auto"
                                onClick={() => {
                                    sendAnalytics(GTMEventName.eventCatalog)
                                    navigate('catalog')
                                }}
                            >
                                Events catalog
                            </Button>
                        </div>
                    )
                    : (
                        <MyEventPageFilterMobile
                            filters={filters}
                            statuses={statuses?.data}
                            searchValue={searchValue}
                            onFilterUpdate={onFilterUpdate}
                            onResetFilters={onResetFilters}
                            debouncedOnSearchUpdate={debouncedOnSearchUpdate}
                        />
                    )}

                <div
                    className='my-events-cards-page'
                >
                    {(isFetching || isLoading) ? (
                        <div className={`flex ${!isDesktop ? 'flex-col' : ''}`}>
                            <EventItemCardSkeleton />
                            <EventItemCardSkeleton />
                            <EventItemCardSkeleton />
                        </div>
                    ) : !myEventsRes?.data?.length ? (
                        <div
                            className='my-events-cards-page__no-data'
                        >
                            <ZeroTableIcon />
                            {user?.name && !filtersChanged && (
                                <p className='my-events-cards-page__no-data-greet'>
                                    Hi,
                                    {' '}
                                    {user.name}
                                    !
                                </p>
                            )}
                            <p className='my-events-cards-page__no-data-desc'>
                                {filtersChanged ?
                                    'There are no events with selected filters.'
                                    :
                                    'There are no events on your desk yet. Please explore the event catalog to find something interesting'}
                            </p>

                            {!filtersChanged && (
                                <Link to='catalog' className='no-style'>
                                    <CustomButton
                                        type='primary'
                                        size='large'
                                        title='Explore events'
                                    />
                                </Link>
                            )}

                        </div>
                    )
                        :
                        (
                            myEventsRes?.data && myEventsRes.data.map((event: GetPartnerEventDataItem, index) => {
                                return (
                                    <EventsCard
                                        refProp={index === 0 ? eventCardRef : undefined}
                                        key={event.id}
                                        event={event}
                                        // eslint-disable-next-line @typescript-eslint/require-await
                                        refetch={async () => void refetch()}
                                    />
                                );
                            })
                        )}
                </div>
                {showPagination ? (
                    <Pagination
                        className="my-event-page__pagination"
                        onChange={onPaginationChange}
                        defaultPageSize={INIT_PAGE_SIZE}
                        defaultCurrent={INIT_CURRENT_PAGE}
                        total={myEventsRes?.meta?.total || INIT_TOTAL}
                        pageSize={myEventsRes?.meta?.per_page || INIT_PAGE_SIZE}
                    />
                )
                    :
                    null}
                {!isFetching && !isLoading && tourStage === TourStages.event && tourStep === 0
                    ?
                    <NewTourComponent
                        isArrowTop={!isLg}
                        id="my-event-card-tour-component"
                        open={!isFetching && !isLoading && tourStage === TourStages.event && tourStep === 0}
                        onClose={() => {
                            onFinishTour()
                        }}
                        buttonText="Go to the event page"
                        current={tourStep}
                        onChange={() => {
                            const id = myEventsRes?.data?.[0]?.id
                            if (id) {
                                localStorage.setItem('first_event_id', id?.toString());
                                navigate(`${events_edit}/${id}`)
                                setTourStep(1)
                                setEventId(id)
                            }
                        }}
                        totalCount={isDesktop ? 7 : 8}
                        description={descriptionsEvent[tourStep]}
                    />
                    :
                    null}
            </ContentLayout>
        </EventsPageLayout>

    )
}

export default MyEventsCardsPage
