import { ChangeEvent, FC, useState, useMemo, useCallback } from 'react';
import { Button, Input, message, notification, Space, Typography, Skeleton } from 'antd';
import Layout700Center from '@layouts/CardLayout';
import InfoIcon from '@icons/header/InfoIcon';
import ArrowIcon from '@assets/ArrowIcon';
import { Link, useParams } from 'react-router-dom';
import { CheckCircleIcon } from '@icons/CheckCircleIcon.tsx';
import {
    useGetOrganizerEventByIdQuery,
    useGetOrganizerEventSubdomainByIdQuery,
    usePatchOrganizerEventSubdomainMutation
} from '@store/type-event/events/events.api';
import { ErrorDataResponse, getTrimmedUrl } from '@shared/utils';
import { HELP_SUBDOMAIN_LINK } from "@shared/constants.ts";
import StyledAlert from "@components/StyledAlert/StyledAlert.tsx";
import SendToEmailModal from '../track/SendToEmailModal';
import './styles.scss'
import EditSubdomainModal from './EditSubdomainModal';

const EventSubdomain: FC = () => {
    const { eventId } = useParams()
    const {
        data: subdomainsResponse,
        refetch: refetchSubdomains,
        isLoading: isSubdomainLoading,
        isFetching: isSubdomainFetching
    } = useGetOrganizerEventSubdomainByIdQuery({ eventId: eventId! }, { skip: !eventId })
    const { data: eventRes } = useGetOrganizerEventByIdQuery({ eventId: eventId?.toString() || '' }, { skip: !eventId })

    const [patchEvent] = usePatchOrganizerEventSubdomainMutation()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const subdomainsCname = subdomainsResponse?.cname || ''
    const subdomainsList = subdomainsResponse?.data || []
    const firstSubdomain = subdomainsList[0]

    const [subdomain, setSubdomain] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const onSubdomainChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (errorMsg) {
            setErrorMsg('')
        }
        setSubdomain(e.target.value)
    }, [errorMsg])

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false)
    }

    const onVerify = useCallback(async () => {
        try {
            setIsLoading(true)
            const response = await patchEvent({ eventId: eventId!, subdomain })

            if ('error' in response) {
                setIsLoading(false)
                const content = (response as ErrorDataResponse)?.error?.data?.message || 'Please enter a valid subdomain'
                setErrorMsg(content)
            } else {
                notification.open({
                    message: (
                        <span>
                            The domain <b>{subdomain}</b> has been successfully added
                        </span>
                    ),
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success',
                });
                setIsEditModalOpen(false)
                setSubdomain('')
                await refetchSubdomains()
            }
            return setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            return message.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
            });
        }
    }, [eventId, patchEvent, refetchSubdomains, subdomain])

    const onEdit = () => {
        setSubdomain('')
        setIsEditModalOpen(true)
    }

    const subDomainComponent = useMemo(() => {
        if ((isSubdomainLoading || isSubdomainFetching) && !subdomain) {
            return <Skeleton active title={false} />
        }
        if (firstSubdomain?.subdomain) {
            return (
                <Space.Compact className='event-subdomain__filled' style={{ width: '100%' }}>
                    <div className='flex-center gap-6'>
                        <div className='mt-3'>
                            {firstSubdomain && firstSubdomain?.status?.name === 'verified' && (
                                <CheckCircleIcon
                                    isActive={false}
                                    fillColor='#17CC80'
                                />
                            )}
                            {firstSubdomain && firstSubdomain?.status?.name === 'checking' && (
                                <CheckCircleIcon
                                    isActive={false}
                                    fillColor='#FAAE13'
                                />
                            )}
                            {firstSubdomain && firstSubdomain?.status?.name === 'failed' && (
                                <CheckCircleIcon
                                    isActive={false}
                                    fillColor='#FF4D4F'
                                />
                            )}
                        </div>
                        <span className='text-regular-14'>{getTrimmedUrl(firstSubdomain.subdomain)}</span>
                    </div>
                    <Button
                        type="link"
                        onClick={onEdit}
                        loading={isLoading}
                    >
                        Edit
                    </Button>
                </Space.Compact>
            )
        }
        return (
            <Space.Compact className='event-subdomain__input' style={{ width: '100%' }}>
                <Input
                    id="subdomain"
                    name="subdomain"
                    placeholder={getTrimmedUrl(firstSubdomain?.subdomain) || 'subdomain.event.com'}
                    value={subdomain}
                    onChange={onSubdomainChange}
                />
                <Button
                    type="primary"
                    disabled={isLoading || !subdomain}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onVerify}
                    loading={isLoading}
                >
                    Add domain
                </Button>
            </Space.Compact>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        firstSubdomain?.subdomain,
        isLoading,
        isSubdomainFetching,
        isSubdomainLoading,
        onSubdomainChange,
        onVerify,
        subdomain
    ])

    return (
        <Layout700Center>
            <div className='event-subdomain'>
                <div className='event-subdomain__header mb-12'>
                    <Typography.Title className='m-0' level={4}>
                        Subdomain
                    </Typography.Title>
                    <div>
                        <InfoIcon fillColor="#252628" width={16} height={16} />
                    </div>
                </div>
                <StyledAlert
                    message={<span>Add "CNAME" record to target <strong>{subdomainsCname ? subdomainsCname.join(', ') : ''} </strong>
                        with your domain records provider and click verify.
                        Note that changes may take 24 hours to propagate throughout the Internet.
                    </span>}
                    type="info"
                    showIcon
                />
                <ul className='event-subdomain__infoList'>
                    <li>
                        <span>
                            Read the detailed instructions on how to connect a subdomain
                        </span>
                        &nbsp;
                        <Link className='event-subdomain__infoLink' to={HELP_SUBDOMAIN_LINK} target="_blank">
                            <span className='text-link'>in our Help Center</span>
                            <ArrowIcon />
                        </Link>
                    </li>
                    <li>
                        <span
                            className='text-link cursor-pointer'
                            onClick={() => setIsModalOpen(true)}
                        >
                            Send the instructions
                        </span>
                        &nbsp;
                        <span>
                            to your development team
                        </span>
                    </li>
                </ul>
                <div className='event-subdomain__divider' />
                {Boolean(errorMsg) && !isEditModalOpen && (
                    <StyledAlert
                        isError
                        message={errorMsg}
                        type="warning"
                        showIcon
                    />
                )}
                <div className='event-subdomain__subdomain'>
                    <label htmlFor="subdomain">
                        Subdomain:
                    </label>
                    {subDomainComponent}
                </div>
                {firstSubdomain && firstSubdomain?.status?.name === 'verified' && (
                    <div className='event-subdomain__success'>
                        {/* <CheckCircleIcon isActive={false} fillColor='#17CC80' /> */}
                        <span className='text-regular-14'>
                            The domain is connected correctly, DNS records are configured correctly.
                            <br />
                            CNAME:
                            {' '}
                            {subdomainsCname ? subdomainsCname.join(', ') : ''}
                            . The site opens at
                            {' '}
                            {getTrimmedUrl(firstSubdomain?.subdomain)}.
                        </span>
                    </div>
                )}
            </div>
            <EditSubdomainModal
                isOpen={isEditModalOpen}
                onClose={handleCloseEditModal}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                subdomain={subdomain}
                onSubdomainChange={onSubdomainChange}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onVerify={onVerify}
                isLoading={isLoading}
                eventName={eventRes?.data?.name}
            />
            {isModalOpen
                ?
                <SendToEmailModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false)
                    }}
                    eventId={eventId || ''}
                    snippet={'https://help.boost.express/knowledge-base/set-up-subdomain-for-landing-pages'}
                />
                :
                null}
        </Layout700Center>
    )
}

export default EventSubdomain
