import {ReactNode} from "react";
import {Facebook} from "@assets/lpEditor/socials/Facebook.tsx";
import {Instagram} from "@assets/lpEditor/socials/Instagram.tsx";
import {Linkedin} from "@assets/lpEditor/socials/Linkedin.tsx";
import {Telegram} from "@assets/lpEditor/socials/Telegram.tsx";
import {Twitter} from "@assets/lpEditor/socials/Twitter.tsx";
import {Viber} from "@assets/lpEditor/socials/Viber.tsx";
import {Whatsapp} from "@assets/lpEditor/socials/Whatsapp.tsx";
import {FacebookColored} from "@assets/lpEditor/socials-colored/FacebookColored.tsx";
import {InstagramColored} from "@assets/lpEditor/socials-colored/InstagramColored.tsx";
import {LinkedinColored} from "@assets/lpEditor/socials-colored/LinkedinColored.tsx";
import {TelegramColored} from "@assets/lpEditor/socials-colored/TelegramColored.tsx";
import {TwitterColored} from "@assets/lpEditor/socials-colored/TwitterColored.tsx";
import {ViberColored} from "@assets/lpEditor/socials-colored/ViberColored.tsx";
import {WhatsappColored} from "@assets/lpEditor/socials-colored/WhatsappColored.tsx";

export const opacityOptions = [
    {
        value: '0%',
        label: '0%',
    },
    {
        value: '10%',
        label: '10%',
    },
    {
        value: '20%',
        label: '20%',
    },
    {
        value: '30%',
        label: '30%',
    },
    {
        value: '40%',
        label: '40%',
    },
    {
        value: '50%',
        label: '50%',
    },
    {
        value: '60%',
        label: '60%',
    },
    {
        value: '70%',
        label: '70%',
    },
    {
        value: '80%',
        label: '80%',
    },
    {
        value: '90%',
        label: '90%',
    },
    {
        value: '100%',
        label: '100%',
    }
]

export enum SegmentedValues {
    color = 'Color',
    image = 'Image'
}

export const segmentedOptions = [SegmentedValues.color, SegmentedValues.image]

export const socialLinksOptions = [
    'facebook',
    'instagram',
    'linkedin',
    'telegram',
    'twitter',
    'viber',
    'whatsapp',
]

export const socialIcons: Record<string, ReactNode> = {
    'facebook': <Facebook />,
    'instagram': <Instagram />,
    'linkedin': <Linkedin />,
    'telegram': <Telegram />,
    'twitter': <Twitter />,
    'viber': <Viber />,
    'whatsapp': <Whatsapp />,
}

export const socialIconsColored: Record<string, ReactNode> = {
    'facebook': <FacebookColored />,
    'instagram': <InstagramColored />,
    'linkedin': <LinkedinColored />,
    'telegram': <TelegramColored />,
    'twitter': <TwitterColored />,
    'viber': <ViberColored />,
    'whatsapp': <WhatsappColored />,
}

export const socialPlaceholder: Record<string, string> = {
    'facebook': 'https://www.facebook.com/',
    'instagram': 'https://www.instagram.com/',
    'linkedin': 'https://www.linkedin.com/',
    'telegram': 'https://www.telegram.com/',
    'twitter': 'https://www.twitter.com/',
    'viber': 'https://www.viber.com/',
    'whatsapp': 'Whatsapp',
}
