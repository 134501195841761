import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetOrganizerCampaignsResponse } from './models';
// import { Filters } from "@pages/EventRolePages/EventsPage/pages/info/campaigns-tab/types";
import { getQueryStringFromParams } from "@shared/utils.ts";
import { Filters } from '@pages/EventRolePages/EventPage/setup/campaigns/types';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const organizer_campaigns = `organizer_campaigns`;

export const organizerCampaigns = createApi({
    reducerPath: organizer_campaigns,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        organizer_campaigns
    ],
    endpoints: build => ({
        getOrganizerCampaignsByEvent: build.query<GetOrganizerCampaignsResponse, { eventId: string, filters: Filters }>({
            query: ({ eventId, filters }) => {
                const { page, statuses, search, partners, landings } = filters

                const params = new URLSearchParams();

                const partnersQueryString = getQueryStringFromParams(partners, 'partners')
                const statusesQueryString = getQueryStringFromParams(statuses, 'statuses')
                const landingsQueryString = getQueryStringFromParams(landings, 'landings')

                if (search && search.length > 2) {
                    params.append('filters[search]', `${search}`);
                }

                if (page) {
                    params.append('page', `${page}`);
                }

                const filterQueryString = params.toString();

                const queryString = [
                    partnersQueryString,
                    statusesQueryString,
                    landingsQueryString,
                    filterQueryString,
                ].filter(Boolean).join('&');

                return ({
                    url: `organizer/events/${eventId}/campaigns${queryString.length ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json',
                    },
                })
            },
            providesTags: [organizer_campaigns],
        }),

    }),
});

export const {
    useGetOrganizerCampaignsByEventQuery,
} = organizerCampaigns;
