import { FC, useState } from 'react'
import './styles.scss'
import StatusDescription from '@components/StatusDescription'
import MegaphoneIcon from '@assets/MegaphoneIcon'
import { Link } from 'react-router-dom'
import Avatar from '@components/Avatar'
import { formatDateRange } from '@shared/utils'
import { EventCatalogItemStatuses } from '@shared/constants'
import PartnersCardIcon from '@assets/PartnersCardIcon'
import TargetCardIcon from '@assets/TargetCardIcon'
import PersonZoomCardIcon from '@assets/PersonZoomCardIcon'
import { Tooltip } from 'antd'
import { OrganizerEventDataItem } from '@store/type-event/events/models'
import EventCardSetupProgressPlug from './EventCardSetupProgressPlug'
import { useBreakpoints } from '@hooks/browser'
import { TabKeys } from '../setup/constants'

interface OrganizerEventsCardProps {
    event: OrganizerEventDataItem
}

const OrganizerEventsCard: FC<OrganizerEventsCardProps> = ({ event }) => {
    const isDesktop = useBreakpoints().md

    const [isShowProgress, setShowProgress] = useState(event?.checklist && event.checklist.length)
    const isShowStatistics = true
    return (
        <div
            className='organizer-my-event-card'
        >
            <Link
                to={`/events/info/${event.id}/general`}
                className='no-style'
            >
                <div
                    className='organizer-my-event-card-link'
                >
                    <img src={event.event.image} alt="" />
                </div>

                <article
                    className='organizer-my-event-card-info'
                >
                    <header
                        className='organizer-my-event-card-info__header'
                    >
                        <div className='flex items-center'>
                            <Avatar
                                size='50'
                                url={event.event.logo || ''}
                            />
                            <div>
                                <h4>
                                    {event.event.name}
                                </h4>
                                <p
                                    className='text-regular-14 m-0'
                                >
                                    {formatDateRange({ started_at: event.date.started_at, finished_at: event?.date?.finished_at })}
                                </p>
                            </div>
                        </div>
                    </header>

                    <footer className='organizer-my-event-card-info__footer flex justify-space-between'>
                        <StatusDescription
                            status={event.status.name as EventCatalogItemStatuses}
                        />

                        {isShowStatistics
                            ?
                            <aside className='flex'>
                                <Tooltip title="Sponsors">
                                    <div className='organizer-my-event-card-info__statistics'>
                                        <PartnersCardIcon />
                                        <span>
                                            {event.partners || 0}
                                        </span>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Campaigns">
                                    <div className='organizer-my-event-card-info__statistics'>
                                        <MegaphoneIcon />
                                        <span>
                                            {event.campaigns || 0}
                                        </span>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Leads">
                                    <div className='organizer-my-event-card-info__statistics'>
                                        <TargetCardIcon />
                                        <span>
                                            {event.leads || 0}
                                        </span>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Landing Pages">
                                    <div className='organizer-my-event-card-info__statistics'>
                                        <PersonZoomCardIcon />
                                        <span>
                                            {event.landings || 0}
                                        </span>
                                    </div>
                                </Tooltip>
                            </aside>
                            :
                            null}
                    </footer>

                </article>
            </Link>
            {isShowProgress && event.checklist
                ?
                <EventCardSetupProgressPlug
                    eventId={event.id.toString()}
                    checkList={event.checklist}
                />
                :
                null}
        </div>
    )
}

export default OrganizerEventsCard