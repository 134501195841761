import './styles.scss'
import ProfileDropdown from '@components/Header/ProfileDropdown'
import {Breadcrumb} from 'antd'
import {Link, useLocation, useParams} from 'react-router-dom'
import {campaigns_ads, campaigns_create, campaigns_edit, campaigns_group} from '@pages/routes'
import NotificationsHeader from '@components/NotificationsHeader'
import ProHeader from '@components/ProHeader/ProHeader';
import InfoDropdown from '@components/Header/InfoDropdown';
import {StripeProvider} from '@contexts/StripeContext';
import React, {useContext, useEffect, useRef} from "react";
import {infoTourDescription, TourStages} from "@components/TourComponent/constants.tsx";
import NewTourComponent from "@components/TourComponent/NewTourComponent.tsx";
import {TourContext} from "@contexts/TourContext.tsx";
import {setInfoTooltipCoordinates,} from "@components/TourComponent/helpers.ts";

const menuItems = [
    {
        title: <Link to="/campaigns">Campaigns</Link>,
    },
    {
        title: <Link to="/campaigns">Campaigns Groups</Link>,
        // menu: { items: menuItems },
    },
    {
        title: 'Ads',
    },
];

const munuCampaignsCreate = [
    {
        title: <Link to="/campaigns">Campaigns</Link>,
    },
    {
        title: <span className='isActive'>
            Create New Campaign
        </span>,
    },
];
const CampaignBreadcrumb = () => {
    const { pathname } = useLocation()
    const { id } = useParams()

    switch (pathname) {
    case campaigns_create:
        return (
            <Breadcrumb
                items={munuCampaignsCreate}
            />
        )
    case `${campaigns_edit}/${id ?? ''}`:
        return (
            <Breadcrumb
                items={menuItems}
            />
        )
    case `${campaigns_ads}/${id ?? ''}`:
        return (
            <Breadcrumb
                items={menuItems}
            />
        )
    case `${campaigns_group}/${id ?? ''}`:
        return (
            <Breadcrumb
                items={menuItems}
            />
        )
    default:
        break;
    }
}

type Props = {
    children: React.ReactNode
    isProHidden?: boolean
    customHeaderActions?: React.ReactNode
}

export const SettingsHeader = ({
   children,
   isProHidden,
   customHeaderActions
}: Props) => {
    const infoTourRef = useRef(null);

    const {
        isInfoTourOpen,
        handleInfoTourClose,
        tourStage,
        onFinishTour
    } = useContext(TourContext);

    useEffect(() => {
        if (isInfoTourOpen) {
            setInfoTooltipCoordinates("info-tour-component", false)
        }
    }, [infoTourRef, isInfoTourOpen]);

    return (
        <header className='settings-header'>
            <div className='settings-header__main'>
                <CampaignBreadcrumb />
            </div>

            {children}

            <aside>
                <div
                    className='flex items-center gap-6'
                >
                    {customHeaderActions}
                    <div style={{
                        height: '28px',
                        width: '1px',
                        backgroundColor: '#E8E9EB',
                        margin: '0 20px'
                    }} />

                </div>
                {isProHidden ? (
                    <span className='settings-header__divider' />
                )
                    : (
                        <StripeProvider>
                            <ProHeader />
                        </StripeProvider>
                    )}
                <div
                    ref={infoTourRef}
                    className='settings-header__item'
                >
                    <InfoDropdown fillColor="#57585A" />
                </div>
                <div
                    className='settings-header__item'
                >
                    <NotificationsHeader fillColor="#57585A" />
                </div>
                <div
                    className='settings-header__item'
                >
                    <ProfileDropdown />
                </div>
            </aside>
            <NewTourComponent
                isArrowTopRight
                id="info-tour-component"
                open={isInfoTourOpen || tourStage === TourStages.video}
                onClose={() => {
                    if (tourStage === TourStages.video) {
                        onFinishTour()
                    }
                    handleInfoTourClose()
                }}
                buttonText="Got it!"
                current={null}
                onChange={() => {
                    if (tourStage === TourStages.video) {
                        onFinishTour()
                    }
                    handleInfoTourClose()
                }}
                totalCount={0}
                description={infoTourDescription}
            />
        </header>
    )
}

export default SettingsHeader
