import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { Badge, Button, Select, Spin } from "antd";
import { FiltersKeys, TransactionsFiltersType } from "@pages/EventRolePages/Billing/types.ts";
import CustomButton from "@components/CustomButton";
import FilterButtonIcon from "@assets/FilterButtonIcon.tsx";
import ExitCrossIcon from "@assets/ExitCrossIcon.tsx";
import { useCallback, useState } from "react";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import { useGetOrganizerEventsQuery } from "@store/type-event/events/events.api.ts";
import { useBreakpoints } from "@hooks/browser";
import { initialTransactionsFilters } from "@pages/EventRolePages/Billing/constants.ts";
import { FilterByEnumItem } from "@store/main/enums/models.ts";
import { INIT_CURRENT_PAGE } from "@shared/constants.ts";
import { menuItemSelectedIcon } from "@shared/menuItemSelectedIcon.tsx";
import DateRangeComponent from '@components/DateRangePicker';
import SelectWithAllOption from "@components/SelectWithAllOption/SelectWithAllOption.tsx";

type Props = {
    filters: TransactionsFiltersType
    setFilters: (filters: (prev: TransactionsFiltersType) => TransactionsFiltersType) => void | TransactionsFiltersType
    setOpenFilter: (isOpen: boolean) => void
    setInitialFilters: () => void
    isDrawer?: boolean
    isTypesLoading?: boolean
    typesOptions?: FilterByEnumItem[]
}

const TransactionsFilters = ({
    isDrawer,
    filters,
    setFilters,
    setOpenFilter,
    setInitialFilters,
    isTypesLoading,
    typesOptions,
}: Props) => {
    const isMd = useBreakpoints().md
    const isXl = useBreakpoints().xl

    const [eventsSearchValue, setEventsSearchValue] = useState<string>()
    const [eventsPage, setEventsPage] = useState(INIT_PAGE);

    const { data: eventsRes, isLoading: isEventsLoading, isFetching: isEventsFetching } = useGetOrganizerEventsQuery({ filters: { search: eventsSearchValue || '' }, page: eventsPage }, { refetchOnMountOrArgChange: true })

    const onDateValueChange = (dates: { dateFrom?: string; dateTo?: string }) => {
        setFilters((prev) => ({
            ...prev,
            page: INIT_CURRENT_PAGE,
            ...dates
        }))
    }

    const handleChangeFilter = useCallback((field: FiltersKeys, value: string[] | boolean) => {
        setFilters((prev) => ({ ...prev, [field]: value, page: INIT_CURRENT_PAGE }))
    }, [])

    const countChangedFilters = () => {
        const { events, ...currentFilters } = filters;
        let changedFiltersCount = 0;

        Object.entries(currentFilters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length) {
                    changedFiltersCount += 1;
                }
            } else {
                const initialValue = initialTransactionsFilters[key as keyof TransactionsFiltersType]
                if (value !== initialValue && key !== 'dateTo' && key !== 'dateFrom') {
                    changedFiltersCount += 1;
                }
            }
        });

        return changedFiltersCount;
    };

    return (
        <div className={`flex gap-8 mb-24 organizer-billing-page__reports__filters ${isDrawer ? 'organizer-billing-page__reports__filters-drawer' : ''}`}>
            <div className='flex gap-8 mr-auto'>
                {isDrawer && (
                    <div className="organizer-billing-page__reports__filters-label">
                        Events
                    </div>
                )}
                {(isMd || isDrawer) && (
                    <InfiniteSelect
                        withAllOption
                        id="organizer-billing-page__events"
                        placeholder='All'
                        maxTagCount={1}
                        style={isDrawer ? { width: '100%', marginBottom: '16px' } : { width: '200px' }}
                        filterOption={false}
                        notFoundContent={isEventsLoading || isEventsFetching ? <Spin size="small" /> : <div>There is no events</div>}
                        isLoading={isEventsLoading || isEventsFetching}
                        listHeight={160}
                        onChange={(value: string) => handleChangeFilter(FiltersKeys.events, value ? [value] : [])}
                        page={eventsPage}
                        setPage={setEventsPage}
                        data={eventsRes}
                        value={filters.events}
                        allowClear
                        showSearch
                        setSearchValue={(val) => {
                            setEventsSearchValue(val)
                        }}
                        optionsMapper={({ event, id }) => ({ value: id.toString(), label: event.name })}
                        classNameProp="revenue-reports__infinite-select__event"
                    />
                )}
                {isMd && !isXl && !isDrawer && (
                    <div className='my-event-page__filter-container-filters-btn'>
                        <Badge count={countChangedFilters()}>
                            <Button
                                className='all-filters-btn'
                                icon={<FilterButtonIcon />}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={() => setOpenFilter(true)}
                            >
                                All filters
                            </Button>
                        </Badge>
                    </div>
                )}
                {isDrawer && (
                    <div className="organizer-billing-page__reports__filters-label">
                        Transaction type
                    </div>
                )}
                {(isDrawer || (isXl && !isDrawer)) && (
                    <SelectWithAllOption
                        id="organizer-billing-page__partners"
                        style={isDrawer ? { width: '100%', marginBottom: '16px' } : { width: '330px' }}
                        placeholder='All'
                        maxTagCount="responsive"
                        mode="multiple"
                        showSearch={false}
                        allowClear
                        filterOption={false}
                        notFoundContent={isTypesLoading ? <Spin size="small" /> : <div>There is no types</div>}
                        loading={isTypesLoading}
                        value={filters.types}
                        onChange={(value: string[]) => handleChangeFilter(FiltersKeys.types, value)}
                        fieldNames={{ label: 'translate', value: 'id' }}
                        options={typesOptions}
                        className="organizer-billing-page__infinite-select__type infinite-select"
                        popupClassName='organizer-billing-page__select-with-icon'
                        menuItemSelectedIcon={menuItemSelectedIcon}
                    />
                )}
                {isDrawer && (
                    <div className="organizer-billing-page__reports__filters-label">
                        Time range
                    </div>
                )}
                {(isDrawer || (isXl && !isDrawer)) && (
                    <DateRangeComponent
                        filters={filters}
                        isDrawer={isDrawer}
                        onDateValueChange={onDateValueChange}
                    />
                )}
                {isMd && (
                    <Button
                        size='small'
                        type="text"
                        onClick={setInitialFilters}
                        className="analytics-page__reset-button"
                    >
                        <ExitCrossIcon fill="#898A8C" />
                        Reset
                    </Button>
                )}
            </div>
        </div>
    )
}

export default TransactionsFilters
