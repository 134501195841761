// https://boost.allnetru.dev/api/documentation/partner#/
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {GetPartnerCredentialsResponse} from "@store/type-partner/credentials/models.ts";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const partnerCredentials = `partner-credentials`;

export const adPartnerCredentials = createApi({
    reducerPath: partnerCredentials,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        partnerCredentials
    ],
    endpoints: build => ({
        getCredentialsChannels: build.query<GetPartnerCredentialsResponse, void>({
            query: () => {
                return {
                    url: '/credentials/channels',
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    }
                };
            },
            providesTags: [partnerCredentials],
        }),
    }),
});

export const {
    useGetCredentialsChannelsQuery
} = adPartnerCredentials;
