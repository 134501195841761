import React, { useContext } from 'react';
import EventInfoForm from "@components/EventInfoForm";
import CompanyInfoForm from "./components/CompanyInfoForm";
import { COMPANY_INFO_STEP, EVENT_INFO_STEP } from "@pages/Onboarding/EventOnboarding/constants";
import LoadingView from "@components/common/LoadingView";
import ErrorView from "@components/common/ErrorView";
import { UserType } from "@store/models-to replace/auth";
import { UpdateCompanyDataBodyType } from "@pages/Onboarding/EventOnboarding/onboarding.api.ts";
import EventInfoFormHeader from "./components/EventInfoFormParts/components/EventInfoFormHeader";
import EventInfoFormFooter from "./components/EventInfoFormParts/components/EventInfoFormFooter";
import { EventEditorContext } from '@contexts/forms/event/EventEditorContext';

type EventOnboardingViewManagerPropsType = {
    isLoading: boolean
    isError: boolean
    currentStep: string | null
    user?: UserType
    updateCompany: (companyInfo: UpdateCompanyDataBodyType) => void
}

const EventOnboardingViewManager: React.FC<EventOnboardingViewManagerPropsType> = props => {
    const { isLoading, isError, currentStep, user, updateCompany } = props;
    const { isUpdating, onSubmit } = useContext(EventEditorContext)

    if (isLoading) {
        return <LoadingView />;
    }

    if (isError || !currentStep) {
        return <ErrorView />;
    }

    /* CompanyInfo form */
    if (currentStep === COMPANY_INFO_STEP) {
        return (
            <CompanyInfoForm
                companyName={user?.organization?.name || null}
                updateCompany={updateCompany}
            />
        );
    }

    /* eventInfo form */
    if (currentStep === EVENT_INFO_STEP) {
        return (
            <EventInfoForm
                isEventInfoStep
                isLoading={isUpdating}
                onSubmit={onSubmit}
                Header={<EventInfoFormHeader/>}
                Footer={EventInfoFormFooter}
            />
        )
    }

    // default
    return null;
};

export default EventOnboardingViewManager;
