import React, { useEffect, useState } from 'react'
import { Select, Button, Divider, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './styles.scss'
import { useGetOrganizerAudienceCatalogsQuery } from '@store/type-event/audiences/audiences.api';
import ArrowIcon from '@assets/ArrowIcon';
import { useParams } from 'react-router-dom';
import EditCatalogModal from '@pages/EventRolePages/AudiencesEventPage/components/EditCatalogModal';
const { Option } = Select;
interface CatalogItem {
    id: number;
    name: string;
}

const EventAudienceCatalog = () => {
    const [catalog, setCatalog] = useState<CatalogItem[]>([]);
    const [isCatalogModalOpen, setIsCatalogModalOpen] = useState(false)
    const { eventId } = useParams()
    // State for selected catalog item
    const [selected, setSelected] = useState<number | undefined>();
    const { data: audienceCatalogsRes, isLoading: isAudienceCatalogsLoading, isFetching: isAudienceCatalogsFetching } = useGetOrganizerAudienceCatalogsQuery({})


    // const handleCreateCatalog = () => {
    //     const newCatalog: CatalogItem = { id: catalog.length + 1, name: 'New Catalog Item' };
    //     setCatalog([...catalog, newCatalog]);
    // };

    useEffect(() => {
        if (audienceCatalogsRes?.data) {
            const nextCatalog = audienceCatalogsRes.data.map((item) => ({ id: item.id, name: item.name.title }))
            setCatalog(nextCatalog)
        }
    }, [audienceCatalogsRes])
    return (
        <div className='background'>

            <article className='flex flex-col items-center max-w-md'>
                <div className='w-full'>
                    <Typography.Title level={4}>
                        Audience Catalog
                    </Typography.Title>
                </div>
                <span className='text-regular-14 text-neutral-8 mr-auto mb-4'>Choose audience catalog</span>
                <Select
                    className="audiences-event-page__audiences__audiences-select"
                    mode="tags"
                    maxTagCount="responsive"
                    allowClear
                    filterOption={false}

                    style={{
                        width: '100%',
                        maxWidth: '740px'
                    }}
                    loading={isAudienceCatalogsLoading || isAudienceCatalogsFetching}
                    placeholder="Choose audience catalog"
                    value={selected}
                    onChange={(value: number) => setSelected(value)}
                    suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="20" height="20" className="common-select-icon" />}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <div className='flex'>
                                <Button
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={() => setIsCatalogModalOpen(true)}
                                // style={{ width: '100%' }}
                                >
                                    Create Catalog
                                </Button>
                            </div>
                        </>
                    )}
                >
                    {catalog.map((item) => (
                        <Option key={item.id} value={item.id}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
                <Button
                    type="text"
                    style={{ border: '1px solid #D9DADC', marginTop: '12px', marginRight: 'auto' }}
                    icon={<PlusOutlined />}
                    onClick={() => setIsCatalogModalOpen(true)}
                // style={{ width: '100%' }}
                >
                    Create Catalog
                </Button>

            </article>
            <EditCatalogModal
                isOpen={isCatalogModalOpen}
                onClose={() => setIsCatalogModalOpen(false)}
                refetch={() => console.log('refetch')}
                eventId={eventId}
                selectedAudience={null}
            />
        </div>
    )
}

export default EventAudienceCatalog