const LinkedinIcon = ({ disabled }: { disabled?: boolean }) => {
    const fillColor = disabled ? "#D1D5DB" : undefined;

    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" rx="21"
                fill={"#F2F3F5"}
            />
            <path
                d="M9.45312 11.1082V11.1082C9.45312 10.1927 10.1965 9.45129 11.1119 9.45366L11.1604 9.45378H30.8459C31.7888 9.45378 32.5531 10.195 32.5531 11.1082V30.8951C32.5531 31.8085 31.7888 32.5492 30.8459 32.5492H11.1604C10.2179 32.5492 9.45312 31.8086 9.45312 30.8954V11.1082Z"
                fill={fillColor || "#0E76A8"}
            />
            <path
                d="M11.1604 9.45378H30.8459C31.7888 9.45378 32.5531 10.195 32.5531 11.1082V30.8951C32.5531 31.8085 31.7888 32.5492 30.8459 32.5492H11.1604C10.2179 32.5492 9.45312 31.8086 9.45312 30.8954V11.1082V11.1082C9.45312 10.1927 10.1965 9.45129 11.1119 9.45366L11.1604 9.45378Z"
                fill={fillColor || "#0E76A8"}
            />
            <path
                d="M16.8948 28.2987V17.901H13.4381V28.2987H16.8952H16.8948ZM15.1672 16.4816C16.3724 16.4816 17.1227 15.6832 17.1227 14.6854C17.1001 13.6648 16.3724 12.8887 15.1901 12.8887C14.0071 12.8887 13.2344 13.6648 13.2344 14.6853C13.2344 15.6831 13.9844 16.4815 15.1445 16.4815H15.1669L15.1672 16.4816ZM18.8082 28.2987H22.2646V22.4928C22.2646 22.1824 22.2871 21.8713 22.3785 21.6496C22.6282 21.0285 23.197 20.3855 24.1521 20.3855C25.4026 20.3855 25.9031 21.3389 25.9031 22.7368V28.2987H29.3594V22.337C29.3594 19.1434 27.6544 17.6572 25.3802 17.6572C23.5157 17.6572 22.6968 18.6991 22.2418 19.4088H22.2649V17.9013H18.8083C18.8535 18.8768 18.8081 28.2991 18.8081 28.2991L18.8082 28.2987Z"
                fill="white"
            />
        </svg>
    )
}

export default LinkedinIcon
