import { CampaignStatuses, EventCatalogItemStatuses, ModerationStatuses, statusesFormatted } from '@shared/constants'
import { statusPlugColor } from '@components/StatusDescription/helpers';
import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import InfoCircleIcon from '@assets/InfoCircleIcon';
import './styles.scss'

const StatusDescription = ({
    status,
    size,
    formattedStatusData = statusesFormatted,
    warningTooltip }: {
        status: CampaignStatuses |
        EventCatalogItemStatuses |
        ModerationStatuses | string,
        size?: 'small',
        formattedStatusData?: Record<string, string>
        warningTooltip?: ReactNode | string
    },) => {
    return (
        <div
            className='flex items-center'
            style={size === 'small' ? { fontSize: '14px' } : undefined}
        >
            <div>
                <div className={`status-plug status-plug--${statusPlugColor(status)}`} />
            </div>
            <span>
                {formattedStatusData[status]}
            </span>
            {warningTooltip && (
                <Tooltip
                    overlayClassName='tooltip-general'
                    title={warningTooltip}
                    placement="bottom"
                >
                    <div className='status-plug--warning-icon'>
                        <InfoCircleIcon fillColor="#FFA940" />
                    </div>
                </Tooltip>
            )}
        </div>
    )
}

export default StatusDescription
