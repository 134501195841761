import { Button, Popover } from "antd";
import ArrowIcon from "@assets/ArrowIcon.tsx";
import React, {useContext, useState, useMemo, useEffect} from "react";
import { LandingEditorContext } from "@contexts/LandingEditorContext.tsx";
import { PartnerLandingSectionLayout } from "@store/type-partner/landings/models.ts";
import { JoditOptionName } from '../LandingPageWYSIWYGconstructor/assets/models.tsx';
import {sectionsImages, sectionsNames} from "@pages/PartnerRolePages/LandingPageConstructor/constants.tsx";

type Props = {
    id: string
    layoutName: string
    onOptionClick: ({ option, id, selectedLayout }: { option: JoditOptionName, id: string, selectedLayout?: PartnerLandingSectionLayout }) => void;
}

const ChangeSectionPopover = ({
    id, layoutName, onOptionClick
}: Props) => {
    const {
        getLayoutsByLayoutName,
    } = useContext(LandingEditorContext);

    const [isSelectDropdownOpen, setIsSelectDropdownOpen] = useState(false)
    const [selectedLayout, setSelectedLayout] = useState<PartnerLandingSectionLayout | null>(null)

    const { layouts, selectedLayout: currentLayout} = useMemo(() => getLayoutsByLayoutName(layoutName), [layoutName])

    useEffect(() => {
        setSelectedLayout(currentLayout || layouts[0] || null)
    }, [layouts, currentLayout]);

    return layouts.length ? (
        <Popover
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            overlayClassName="jodit-toolbar__change-section"
            arrow={false}
            placement="bottomRight"
            key={id}
            open={isSelectDropdownOpen}
            onOpenChange={(value) => {
                if (!value) {
                    setSelectedLayout(currentLayout || layouts[0] || null)
                }
                setIsSelectDropdownOpen(value)
            }}
            content={(
                <div className='add-constructor-section__block'>
                    <div className='add-constructor-section__left'>
                        {layouts?.map((item) => {
                            const layoutImage = sectionsImages[item.name]

                            if (!layoutImage) return

                            return (
                                <div
                                    key={item.name}
                                    onMouseEnter={() => {
                                        setSelectedLayout(item)
                                    }}
                                    onClick={() => {
                                        onOptionClick({ option: JoditOptionName.Select, id, selectedLayout })
                                    }}
                                    className={`add-constructor-section__label ${item.name === selectedLayout?.name ? 'add-constructor-section__label_active' : ''}`}
                                >
                                    {sectionsNames[item.name] || item.translate || item.name}
                                </div>
                            )
                        })}
                    </div>
                    <div className='add-constructor-section__wrapper'>
                        {selectedLayout && (
                            <div
                                key={selectedLayout.name}
                                onClick={() => {
                                    onOptionClick({option: JoditOptionName.Select, id, selectedLayout})
                                }}
                                className={`section-renderer__wrapper`}
                            >
                                {sectionsImages[selectedLayout.name]}
                            </div>
                        )}
                    </div>
                </div>

            )}
            title=""
            trigger="click"
        >
            <Button
                className={`jodit-toolbar__change-section__button ${isSelectDropdownOpen ? 'jodit-toolbar__change-section__button-open' : ''}`}
            >
                Change section
                <ArrowIcon fillColor="#C0C1C3" width="20" height="20" />
            </Button>
        </Popover>
    ) : null
}

export default ChangeSectionPopover
