export const TelegramColored = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.25 2.75C20.3546 2.75 21.25 3.64543 21.25 4.75V19.25C21.25 20.3546 20.3546 21.25 19.25 21.25H4.75C3.64543 21.25 2.75 20.3546 2.75 19.25V4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H19.25ZM6.05599 11.0696L16.6874 6.94864C17.482 6.62801 17.7936 6.99673 17.6109 7.83356L15.8506 16.2118C15.7165 16.8916 15.3773 17.131 14.754 16.7601L12.0703 14.7401L10.5033 16.2304C10.3108 16.3978 10.0091 16.3963 9.84431 16.0594L10.0222 13.878L15.6861 8.72325C16.0033 8.44034 15.6346 8.37176 15.2746 8.62894L8.68 12.7256L5.95258 11.8543C5.48346 11.6458 5.62816 11.2615 6.05599 11.0696Z"
              fill="url(#paint0_linear_19551_42615)"/>
        <defs>
            <linearGradient id="paint0_linear_19551_42615" x1="927.75" y1="2.75" x2="927.75" y2="1852.75"
                            gradientUnits="userSpaceOnUse">
                <stop stop-color="#2AABEE"/>
                <stop offset="1" stop-color="#229ED9"/>
            </linearGradient>
        </defs>
    </svg>

)