import { Dropdown } from 'antd';
import React from "react";

interface Props {
    children: React.ReactNode,
    onDelete: () => void
    onEdit: () => void
}

const RowDropdown = ({
    children,
    onDelete, onEdit
}: Props) => {
    const dropdownItems = [
        {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            label: (
                <div onClick={onEdit}>
                    Edit
                </div>
            ),
            key: 'edit',
        },
        {
            type: 'divider',
        },
        {
            danger: true,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            label: (
                <div onClick={onDelete}>
                    Delete
                </div>
            ),
            key: 'delete',

        },
    ];

    return (
        <Dropdown
            menu={{ items: dropdownItems }}
        >
            {children}
        </Dropdown>
    )
}

export default RowDropdown
