import React, { useState } from "react";
import {AudienceCatalogItem} from "@store/type-event/audiences/models.ts";
import { MAX_AUDIENCES_LENGTH } from "@pages/EventRolePages/AudiencesEventPage/constants.ts";
import { Tag } from "antd";

const AudiencesColumn = ({ audiences }: { audiences: AudienceCatalogItem['audiences'] }) => {
    const [isHidden, setIsHidden] = useState(true)
    return (
        <div className="audiences-event-page__audiences__tags">
            {audiences?.length > MAX_AUDIENCES_LENGTH && isHidden ? (
                <>
                    {audiences
                        ?.slice(0, MAX_AUDIENCES_LENGTH)
                        ?.map((audience) => <Tag key={audience.id}>{audience.title}</Tag>)}
                    {isHidden && (
                        <Tag className="audiences-event-page__audiences__tag-count" onClick={() => setIsHidden(false)}>+{audiences.slice(MAX_AUDIENCES_LENGTH).length}</Tag>
                    )}
                </>
            ) : (
                <>
                    {audiences?.map((audience) =>  <Tag key={audience.id}>{audience.title}</Tag>)}
                </>
            )}
        </div>
    )
}

export default AudiencesColumn