const ResetIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17976_285845)">
                <path
                    d="M7.32354 18.0372L2.52069 13.2344C2.14176 12.8554 2.14176 12.2411 2.52069 11.8621L5.95129 8.43152M7.32354 18.0372L11.4403 13.9205M7.32354 18.0372L16.3754 18.0516M5.95129 8.43152L12.1264 2.25643C12.5053 1.8775 13.1197 1.8775 13.4986 2.25643L17.6153 6.37316C17.9943 6.75209 17.9943 7.36646 17.6153 7.7454L11.4403 13.9205M5.95129 8.43152L11.4403 13.9205"
                    stroke="#252628" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_17976_285845">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default ResetIcon
