import {LOOM_LINK} from "@components/LoomPlayer/constants.ts";
import React, {useContext, useEffect, useState, useRef} from "react";
import * as loom from '@loomhq/loom-embed';
import LoomIcon from "@assets/loom/LoomIcon.tsx";
import ExitCrossIcon from '@assets/ExitCrossIcon';
import './styles.scss'
import {TourContext} from "@contexts/TourContext.tsx";
import loomMobile from "@assets/loom/loomMobile.png";
import {useBreakpoints} from "@hooks/browser";
import {useAuth} from "@contexts/AuthContext.tsx";
import {TourStages} from "@components/TourComponent/constants.tsx";
import CollapseIcon from "@assets/loom/CollapseIcon.tsx";

const LoomPlayer = () => {
    const [isSet, setIsSet] = useState(false)
    const [isFullWidth, setIsFullWidth] = useState(false)
    const [isShowPlayer, setIsShowPlayer] = useState(false)
    const isDesktop = useBreakpoints().md;
    const { user } = useAuth();
    const isRegistered = user && user.email && user?.organization?.onboarding === 'ready' && user?.organization?.type === 'partner'

    const divRef = useRef<HTMLDivElement | null>(null);

    const {
        isPlayerOpened,
        setIsPlayerOpened,
        blockScroll,
        unblockScroll,
        setIsInfoTourOpen,
        tourStage
    } = useContext(TourContext);

    useEffect(() => {
        if (isPlayerOpened) {
            setIsShowPlayer(true)
        }
    }, [isPlayerOpened]);

    const isTourOpen = tourStage === TourStages.event || tourStage === TourStages.campaign

    useEffect(() => {
        if (loom && !isSet) {
            loom.linkReplace("#loom-video")
            setIsSet(true)
        }
    }, [loom, isSet]);

    useEffect(() => {
        if (isTourOpen && isRegistered) {
            setIsShowPlayer(true)
        } else {
            setIsShowPlayer(false)
        }
    }, [isTourOpen, isRegistered]);

    const onFullWidthClick = () => {
        blockScroll()
        setIsFullWidth(true)
        document.body.style.pointerEvents = 'none'
    }

    const onExitClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (isFullWidth) {
            document.body.style.pointerEvents = 'all'
            setIsFullWidth(false)
            unblockScroll()
        } else {
            const isInfoTourClosed = localStorage.getItem('is_info_tour_closed')
            if ((tourStage === TourStages.event || tourStage === TourStages.campaign) && !isInfoTourClosed) {
                setIsInfoTourOpen(true)
            }
            setIsPlayerOpened(false)
            setIsShowPlayer(false)
        }
    }

    const onClose = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        document.body.style.pointerEvents = 'all'
        const isInfoTourClosed = localStorage.getItem('is_info_tour_closed')
        if ((tourStage === TourStages.event || tourStage === TourStages.campaign) && !isInfoTourClosed) {
            setIsInfoTourOpen(true)
        }
        setIsPlayerOpened(false)
        setIsShowPlayer(false)
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (isFullWidth && divRef.current && !divRef.current.contains(event.target as Node)) {
            document.body.style.pointerEvents = 'all'
            setIsFullWidth(false)
            unblockScroll()
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isFullWidth]);

    return (
        <div
            ref={divRef}
            className={`loom-player ${isFullWidth ? 'loom-player__full' : ''} ${isShowPlayer ? 'loom-player__visible' : ''}`}
        >
            {isFullWidth ? (
                    <>
                        <div className="loom-player__exit-btn" onClick={onClose}>
                            <ExitCrossIcon width="18" height="18" fill="#FFFFFF"/>
                        </div>
                        {isDesktop && (
                            <div className="loom-player__collapse-btn" onClick={onExitClick}>
                                <CollapseIcon/>
                            </div>
                        )}
                    </>
                )
                : (
                    <div className="loom-player__header" onClick={onFullWidthClick}>
                    {isDesktop && (
                            <div onClick={onFullWidthClick}>
                                <LoomIcon/>
                            </div>
                        )}
                        {!isDesktop && (
                            <img src={loomMobile} alt='loomMobile'/>
                        )}
                        <div className="loom-player__title">
                            First overview of the platform
                        </div>
                        <div onClick={onExitClick}>
                            <ExitCrossIcon width="18" height="18"/>
                        </div>
                    </div>
                )}
            <div className={`loom-player__video-wrapper ${isFullWidth ? "loom-player__video-wrapper-full" : ''}`}>
                <a
                    id="loom-video"
                    className="loom-video"
                    href={LOOM_LINK}
                >
                    Loom Video
                </a>
            </div>
        </div>
    )
}

export default LoomPlayer
