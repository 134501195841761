import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import {TourStages} from "@components/TourComponent/constants.tsx";
import { blockScroll, unblockScroll } from "@components/TourComponent/helpers.ts";

interface TourContextType {
    tourStep: number;
    setTourStep: (value: number) => void;
    eventId: number | null;
    setEventId: (value: number | null) => void;
    isCampaignTourOpen: boolean;
    setIsCampaignTourOpen: (value: boolean) => void;
    campaignTourStep: number;
    setCampaignTourStep: (value: number) => void;
    unblockScroll: () => void;
    blockScroll: () => void;
    isInfoTourOpen: boolean;
    isInfoTourWasOpened: boolean;
    setIsInfoTourOpen: (value: boolean) => void;
    handleInfoTourClose: () => void;
    isPlayerOpened: boolean;
    setIsPlayerOpened: (value: boolean) => void;
    tourStage: TourStages;
    setTourStage: (value: TourStages) => void;
    setIsInfoTourWasOpened: (value: boolean) => void;
    onFinishTour: () => void;
}

export const TourContext = createContext<TourContextType>({} as TourContextType);

export const TourProvider: React.FC<PropsWithChildren> = (props) => {
    const [isInfoTourOpen, setIsInfoTourOpen] = useState(false)
    const [tourStep, setTourStep] = useState(0);
    const [eventId, setEventId] = useState<number | null>(null);
    const [tourStage, setTourStage] = useState(TourStages.finish)
    const [isCampaignTourOpen, setIsCampaignTourOpen] = useState<boolean>(false);
    const [campaignTourStep, setCampaignTourStep] = useState(0);
    const [isInfoTourWasOpened, setIsInfoTourWasOpened] = useState(true);
    const [isPlayerOpened, setIsPlayerOpened] = useState(false);

    const onFinishTour = () => {
        unblockScroll()
        setIsPlayerOpened(false)
        setTourStage(TourStages.finish)
    }

    useEffect(() => {
        const handleBeforeUnload = () => {
            // onFinishTour()
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleInfoTourClose = () => {
        setIsInfoTourOpen(false)
        setIsInfoTourWasOpened(true)
        localStorage.setItem('is_info_tour_closed', 'true')
    }

    const { children } = props

    return (
        <TourContext.Provider value={{
            tourStep,
            setTourStep,
            isCampaignTourOpen,
            setIsCampaignTourOpen,
            campaignTourStep,
            setCampaignTourStep,
            eventId,
            setEventId,
            unblockScroll: unblockScroll,
            blockScroll: blockScroll,
            isInfoTourOpen,
            setIsInfoTourOpen,
            handleInfoTourClose,
            isInfoTourWasOpened,
            isPlayerOpened,
            setIsPlayerOpened,
            tourStage,
            setTourStage,
            onFinishTour,
            setIsInfoTourWasOpened
        }}
        >
            {children}
        </TourContext.Provider>
    );
}
