const CornerIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6667 12.6637H14V13.9971H12.6667V12.6637ZM12.6667 11.3304H14V9.99707H12.6667V11.3304ZM2 8.66374H3.33333V7.3304H2V8.66374ZM2 11.3304H3.33333V9.99707H2V11.3304ZM2 5.99707H3.33333V4.66374H2V5.99707ZM2 3.3304H3.33333V1.99707H2V3.3304ZM4.66667 3.3304H6V1.99707H4.66667V3.3304ZM10 13.9971H11.3333V12.6637H10V13.9971ZM7.33333 13.9971H8.66667V12.6637H7.33333V13.9971ZM10 13.9971H11.3333V12.6637H10V13.9971ZM4.66667 13.9971H6V12.6637H4.66667V13.9971ZM2 13.9971H3.33333V12.6637H2V13.9971ZM14 5.3304C14 3.4904 12.5067 1.99707 10.6667 1.99707H7.33333V3.3304H10.6667C11.7667 3.3304 12.6667 4.2304 12.6667 5.3304V8.66374H14V5.3304Z"
                fill="#898A8C"
            />
        </svg>
    )
}

export default CornerIcon
