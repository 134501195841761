const TabletIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_20451_37821)">
                <path
                    d="M13.1395 0H2.85379C2.22344 0 1.71094 0.5125 1.71094 1.14286V14.8571C1.71094 15.4875 2.22344 16 2.85379 16H13.1395C13.7699 16 14.2824 15.4875 14.2824 14.8571V1.14286C14.2824 0.5125 13.7699 0 13.1395 0ZM12.9967 14.7143H2.99665V1.28571H12.9967V14.7143ZM7.28237 12.8571C7.28237 13.0466 7.35762 13.2283 7.49158 13.3622C7.62553 13.4962 7.80721 13.5714 7.99665 13.5714C8.18609 13.5714 8.36778 13.4962 8.50173 13.3622C8.63568 13.2283 8.71094 13.0466 8.71094 12.8571C8.71094 12.6677 8.63568 12.486 8.50173 12.3521C8.36778 12.2181 8.18609 12.1429 7.99665 12.1429C7.80721 12.1429 7.62553 12.2181 7.49158 12.3521C7.35762 12.486 7.28237 12.6677 7.28237 12.8571Z"
                    fill="#898A8C"
                />
            </g>
            <defs>
                <clipPath id="clip0_20451_37821">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TabletIcon
