export interface Filters {
    dateFrom?: string;
    dateTo?: string;
    events: string[];
    partners?: string[];
    group_by_partner?: boolean;
    gap?: string | number;
    page?: number;
}

export enum FiltersKeys {
    events = 'events',
    partners = 'partners',
    group_by_partner = 'group_by_partner',
    gap = 'gap',
    types = 'types',
    status = 'status'
}

export interface TransactionsFiltersType {
    dateFrom?: string;
    dateTo?: string;
    events: string[];
    types?: string[];
    page?: number;
}

export interface RevShareFiltersType {
    events: string[];
    page?: number;
}

export interface WithdrawalFiltersType {
    dateFrom?: string;
    dateTo?: string;
    status?: string[];
    page?: number;
    sortField?: string;
    sortDirection?: string;
}

export type EventBillingFormErrors = Record<string, string>

export interface CityField {
    city: {
        country: {
            id: number;
            slug: string
        }
        id: number;
        slug: string
    }
}