import React, { useState, useRef } from 'react';
import './styles.scss'
import {DEFAULT_MARGIN} from "@components/CustomTooltip/constants.ts";

type Props = {
    title: string;
    children: React.ReactNode;
    margin?: number
};

const CustomTooltip: React.FC<Props> = ({ title, children, margin = DEFAULT_MARGIN }) => {
    const [visible, setVisible] = useState(true);
    const [tooltipStyle, setTooltipStyle] = useState({ top: 0 });
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    const handleMouseEnter = () => {
        if (wrapperRef.current && tooltipRef.current) {
            const wrapperRect = wrapperRef.current.getBoundingClientRect();
            const top = wrapperRect.height + margin;
            setTooltipStyle({ top });
            setVisible(true);
        }
    };

    const handleMouseLeave = () => {
        setVisible(false);
    };

    return (
        <div
            className="custom-tooltip-wrapper"
            ref={wrapperRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            <div className={`custom-tooltip ${visible ? '' : 'custom-tooltip__hidden'}`} ref={tooltipRef} style={tooltipStyle}>
                <div className="custom-tooltip-arrow" />
                {title}
            </div>
        </div>
    );
};

export default CustomTooltip;
