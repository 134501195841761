import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '@contexts/AuthContext';
import Routes from './Routes';
import { LoaderMainProvider } from '@contexts/LoaderMainContext';
import { ConfigProvider } from 'antd'
import { theme } from "@shared/theme.ts";
import './index.scss'
import HubSpotButton from "@components/HubSpotButton/HubSpotButton.tsx";
import { TourProvider } from "@contexts/TourContext.tsx";
import LoomPlayer from "@components/LoomPlayer/LoomPlayer.tsx";
import { ExitPageProvider } from "@contexts/ExitPageContext.tsx";

function App() {
    return (
        <AuthProvider>
            <ExitPageProvider>
                <TourProvider>
                    <BrowserRouter>
                        <LoaderMainProvider>
                            <ConfigProvider
                                theme={theme}
                            >
                                <Routes />
                                <HubSpotButton />
                                <LoomPlayer />
                            </ConfigProvider>
                        </LoaderMainProvider>
                    </BrowserRouter>
                </TourProvider>
            </ExitPageProvider>
        </AuthProvider>
    );
}

export default App;
