export enum JoditOptionName {
    Clone = 'clone',
    Settings = 'settings',
    Delete = 'delete',
    Menu = 'menu',
    Up = 'up',
    Down = 'down',
    Select = 'select',
    Hide = 'hide',
    Show = 'show',
}