import { Dropdown, MenuProps, Progress, Space } from 'antd'
import React from 'react'
import cn from 'classnames';
import BoostProLogo from '@assets/pro/BoostProLogo.svg';
import { twoColors } from '@pages/PartnerRolePages/BoostPro/constants';
import ThreeDotsIcon from "@assets/pro/ThreeDotsIcon";
import '../styles.scss'
import {ProSubscriptionData} from "@pages/PartnerRolePages/BoostPro/helpers.ts";
type Props = {
    proSubscription: ProSubscriptionData['proSubscription']
    onCancel: () => void
}

const MySubscription = ({ proSubscription, onCancel }: Props) => {
    const { money, percent, statusName, formattedDate, limit, formattedPrice } = proSubscription || {}
    const { spend } = money || {}

    const dropdownItems: MenuProps['items'] = [
        {
            label: <div onClick={onCancel}>Cancel subscription</div>,
            key: '1',
        },
    ];

    return (
        <div className="my-subscription-header header-pro">
            <img src={BoostProLogo} alt="BoostProLogo" />
            <span className="my-subscription-title">Manage subscription</span>

            <div className="progress-bar">
                <div className={cn('progress-bar-status', 'flex items-center')}>
                    <div>
                        <div className="status-plug status-plug--green" />
                    </div>
                    <span>{statusName}</span>
                </div>
                <div className="progress-bar-price-block">
                    <span className="progress-bar-price">{formattedPrice} </span>
                    <span className="progress-bar-month">/ month</span>
                </div>
                <div className="progress-bar-date-block">
                    <span className="progress-bar-month">Next payment date: </span>
                    <span className={cn('progress-bar-month', 'progress-bar-date')}>{formattedDate}</span>
                </div>
                <div className="progress-bar-block">
                    <span>Spend to get Boost PRO for free:</span>
                </div>

                <div className="progress-bar-line">
                    <Progress
                        className="max-w-md"
                        strokeColor={twoColors}
                        showInfo={false}
                        percent={percent}
                    />
                    <span>
                        ${spend} of ${limit}
                    </span>
                </div>
                <Dropdown
                    className="dropdown"
                    menu={{ items: dropdownItems }}
                    trigger={['click']}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <ThreeDotsIcon />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </div>
    )
}

export default MySubscription
