import { useEffect, useState } from 'react'
import AccountCard from './AccountCard';
import { useGetCredentialsChannelsQuery } from '@store/type-partner/credentials/credentials.api';
import { PartnerCredential } from '@store/type-partner/credentials/models';
import CreateChannelModal from './CreateChannelModal';
import { useGetOrganizerCredentialsQuery, usePostOrganizerEventCredentialsByIdMutation } from '@store/type-event/credentials/credentials.api';
import { OrganizerCredential } from '@store/type-event/credentials/models';
import { message, notification, Tooltip } from 'antd';
import { ErrorDataResponse } from '@shared/utils';
import './styles.scss'
import { useParams } from 'react-router-dom';
import { useGetOrganizerEventByIdQuery } from '@store/type-event/events/events.api';
const AddAccountZeroState = () => {
    const { eventId } = useParams()
    const { data: channelsRes, isLoading: isCredentialsChannelLoading } = useGetCredentialsChannelsQuery()
    const { data: eventData } = useGetOrganizerEventByIdQuery({ eventId: eventId?.toString() || '' }, { skip: !eventId });

    const { data: credentialsRes } = useGetOrganizerCredentialsQuery()
    const [postOrganizerEventCredentialsById] = usePostOrganizerEventCredentialsByIdMutation()

    const [channels, setChannels] = useState<PartnerCredential[]>([])
    const [clickedAdCard, setClickedAdCard] = useState<PartnerCredential | null>(null)
    const [accounts, setAccounts] = useState<OrganizerCredential[] | null>(null)
    const [isLoading, setLoading] = useState(false)
    const [credentialsList, setCredentialsList] = useState<Record<string, OrganizerCredential[]>[]>([])
    const [accountToPost, setAccountToPost] = useState<{ id: number, name: string } | null>(null)

    useEffect(() => {
        if (credentialsRes?.data) {
            optionsHandler()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentialsRes?.data])


    const optionsHandler = () => {
        if (!credentialsRes?.data) return
        const result = credentialsRes.data.reduce((acc, item) => {
            const channelName = item.channel.name;
            if (!acc[channelName]) {
                acc[channelName] = [];
            }
            acc[channelName].push(item);
            return acc;
        }, {} as Record<string, OrganizerCredential[]>);

        const formattedResult = Object.entries(result).map(([key, value]) => ({ [key]: value }));
        setCredentialsList(formattedResult)
    };

    useEffect(() => {
        if (channelsRes?.data) {
            const next = [
                ...channelsRes.data,
                {
                    id: 3,
                    slug: 'meta_ads',
                    url: '',
                    translate: 'Meta Ads ',
                },
                {
                    id: 4,
                    slug: 'x_ads',
                    url: '',
                    translate: 'X Ads',
                }
            ]
            setChannels(next)
        }
    }, [channelsRes?.data])

    const onAdModalClose = () => {
        setClickedAdCard(null)
        setAccounts(null)
    }

    const onAdCardClick = (card: PartnerCredential) => {
        setClickedAdCard(card);
        const foundAccounts = credentialsList.find(item => item[card.slug]);
        setAccounts(foundAccounts ? foundAccounts[card.slug] : null);
        const foundData = credentialsList.find(item => Object.keys(item)[0] === card.slug)?.[card.slug][0] || null;

        if (!foundData) {
            window.location.href = card.url;
            return;
        }
    };

    const onSubmit = async () => {
        if (!accountToPost || !eventId) return
        setLoading(true)
        try {
            const res = await postOrganizerEventCredentialsById({
                eventId: eventId || '',
                credentialId: +accountToPost?.id
            })
            if ('error' in res) {
                setLoading(false)
                return message.error((res as ErrorDataResponse)?.error?.data?.message || 'Withdrawal is not sent');
            }
            if (res) {
                void notification.open({
                    type: 'success',
                    message: `${accountToPost.name} Account added`,
                });
                setLoading(false);
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            setAccountToPost(null)
        }
    }


    // if (isCredentialsChannelLoading || isLoading || isCredentialsLoading) {
    //     return <Spin />
    // }

    const isEventActive = eventData?.data?.status.id.toString() === '2'

    if (isEventActive) {
        return (
            <div className='flex gap-12'>
                {channels.map(item => {
                    return (
                        <Tooltip
                            title="Ad account cannot be changed since sponsors have already launched ad campaigns."
                            color="#ffffff"
                            placement='bottom'
                            overlayInnerStyle={{ color: '#000000' }}
                            style={{ width: '200px', padding: '6px 8px' }}
                        >
                            <div
                                key={item.id}
                                className={['x_ads', 'meta_ads'].includes(item.slug) || isEventActive ? 'blocked' : ''}
                            >
                                <AccountCard
                                    adItem={item}
                                />
                            </div>
                        </Tooltip>
                    )
                })}
            </div>

        )
    }
    return (
        <>
            <div className='flex gap-12'>
                {channels.map(item => {
                    return (
                        <div
                            key={item.id}
                            onClick={() => onAdCardClick(item)}
                            className={['x_ads', 'meta_ads'].includes(item.slug) || isEventActive ? 'disabled' : ''}
                        >
                            <AccountCard
                                adItem={item}
                            />
                        </div>
                    )
                })}
            </div>

            {accountToPost && clickedAdCard && accounts
                ?
                <CreateChannelModal
                    isLoading={isLoading}
                    onClose={onAdModalClose}
                    clickedAdCard={clickedAdCard}
                    accounts={accounts}
                    onSelectAccount={({ id, name }) => setAccountToPost({ id, name })}
                    onSubmit={() => void onSubmit()}
                />
                :
                null
            }
        </>
    )
}

export default AddAccountZeroState