import InfoIcon from "@icons/header/InfoIcon.tsx";
import React from "react";
import WarningIcon from "@assets/WarningIcon.tsx";
import { HELP_CENTER_LINK } from "@shared/constants.ts";
import { Link } from "react-router-dom";
import { formatByCurrency } from "@shared/utils.ts";
import { formatNumberWithThousandsSeparator } from "@components/Analytics/helpers.ts";
import { Tooltip } from "antd";

type Props = {
    currency?: string
}

const TotalNumbers = ({ currency }: Props) => {
    // todo add when back is ready
    return (
        <div className="revenue-reports-numbers__wrapper">
            <div className="revenue-reports-numbers">
                <div className="revenue-reports-numbers__item">
                    <div className="revenue-reports-numbers__title-wrapper">
                        <div className="revenue-reports-numbers__title">Revenue</div>
                        <InfoIcon fillColor="#57585A" width={14} height={14} className='info-icon' />
                    </div>

                    <div className="revenue-reports-numbers__amount">
                        {formatByCurrency(0, currency)}
                    </div>
                    <div className="revenue-reports-numbers__date">
                        Last 30 days
                    </div>
                </div>

                <div className="revenue-reports-numbers__item">
                    <div className="revenue-reports-numbers__title-wrapper">
                        <div className="revenue-reports-numbers__title">Revshare</div>
                        <InfoIcon fillColor="#57585A" width={14} height={14} className="info-icon" />
                    </div>

                    <div className="revenue-reports-numbers__amount">
                        {formatNumberWithThousandsSeparator(0)}
                    </div>
                    <div className="revenue-reports-numbers__date">
                        Last 30 days
                    </div>
                </div>

                <div className="revenue-reports-numbers__item">
                    <div className="revenue-reports-numbers__title-wrapper">
                        <div className="revenue-reports-numbers__title">Commission</div>
                        <InfoIcon fillColor="#57585A" width={14} height={14} className="info-icon" />
                    </div>

                    <div className="revenue-reports-numbers__amount">
                        {formatByCurrency(0, currency)}
                    </div>
                    <div className="revenue-reports-numbers__date">
                        Last 30 days
                    </div>
                </div>

                <Tooltip
                    overlayClassName="tooltip-general tooltip-small"
                    placement="bottom"
                    title={(
                        <Link
                            className="revenue-reports-numbers__link"
                            to={HELP_CENTER_LINK}
                            target="_blank"
                        >
                            <span className='text-link'>Learn more</span>
                        </Link>
                    )}
                >
                    <div className="revenue-reports-numbers__item flex-2">
                        <div className="revenue-reports-numbers__title-wrapper">
                            <div className="revenue-reports-numbers__title">Balance</div>
                        </div>
                        <div className="revenue-reports-numbers__amount">
                            {/* TOFIX: ADD BALANCE */}
                            {formatByCurrency(0, currency)}
                        </div>
                        <div className="revenue-reports-numbers__date">
                            <WarningIcon />
                            Withdrawal limit:
                            {' '}
                            <b>{formatByCurrency(0, currency)}</b>
                        </div>
                    </div>
                </Tooltip>
            </div>
        </div>
    )
}

export default TotalNumbers
