import './styles.scss'

interface AvatarProps {
    url: string;
    size?: 'medium' | '50' | '68' | '38' | '32' | '24'
    bordered?: boolean
    isCover?: boolean
}

const Avatar = ({ url, size = 'medium', bordered, isCover }: AvatarProps) => {
    return (
        url
            ?
            <img
                className={`avatar-img__${size} ${bordered ? 'avatar-img__bordered' : ''} ${isCover ? 'avatar-img__cover' : ''}`}
                src={url}
                alt="."
            />
            :
            <div
                className={`avatar-img__${size} ${bordered ? 'avatar-img__bordered' : ''}`}
            />
    )
}

export default Avatar