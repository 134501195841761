import React from 'react'
import EmptyIcon from './EmptyIcon'
import GoogleAdsIcon from './GoogleAdsIcon'
import LinkedInAdsIcon from './LinkedInAdsIcon'
import MetaAdsIcon from './MetaAdsIcon'
import XAdsIcon from './XAdsIcon'

const AdIconRender = ({ slug }: { slug: string }) => {
    switch (slug) {
        case 'google_ads':
            return <GoogleAdsIcon />
        case 'linkedin_ads':
            return <LinkedInAdsIcon />
        case 'x_ads':
            return <XAdsIcon />
        case 'meta_ads':
            return <MetaAdsIcon />
        default:
            return <EmptyIcon />
    }
}

export default AdIconRender