import React from "react";

const RejectIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_19745_176747)">
                <path
                    d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 5.60938 1.67656 4.33125 2.49219 3.33125L10.6687 11.5078C9.66875 12.3234 8.39062 12.8125 7 12.8125ZM11.5078 10.6687L3.33125 2.49219C4.33125 1.67656 5.60938 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 8.39062 12.3234 9.66875 11.5078 10.6687Z"
                    fill="#F5222D"
                />
            </g>
            <defs>
                <clipPath id="clip0_19745_176747">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default RejectIcon
