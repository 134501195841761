import React, { useEffect, useState } from 'react';
import './styles.scss';
import { DatePicker, Divider, Form, Input } from "antd";
import { initEventInfoFormValues } from './utils';
import { CreateOrganizerEventRequestBody, OrganizerEventDataFullItem } from '@store/type-event/events/models';
import { useForm } from 'laravel-precognition-react';
import AvatarUpload from "@components/EventInfoForm/components/AvatarUpload";
import { getRangePickerPlaceholder } from "@shared/utils.ts";
import dayjs, { Dayjs } from "dayjs";
import InfoTooltipIcon from "@icons/InfoTooltipIcon.tsx";
import HeroImage from "@components/EventInfoForm/components/HeroImage";
import Locations from "@components/EventInfoForm/components/Locations";
import Categories from "@components/EventInfoForm/components/Categories";
import Layout700Center from '@layouts/CardLayout';

export type EventInfoFormFooterPropsType = {
    onSubmit: () => void
    isSubmitDisabled: boolean
    isLoading?: boolean
    onPreview?: () => void
}

type EventInfoFormPropsType = {
    Header?: React.ReactNode
    Footer?: React.FC<EventInfoFormFooterPropsType>
    fetchedData?: OrganizerEventDataFullItem;
    onSubmit: (eventData: CreateOrganizerEventRequestBody, isEventInfoStep?: boolean) => void
    isLoading?: boolean;
    isEventInfoStep?: boolean;
    onPreview?: () => void;
}
const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const EventInfoForm: React.FC<EventInfoFormPropsType> = ({
    Header,
    Footer,
    fetchedData,
    onSubmit,
    isLoading,
    isEventInfoStep,
    onPreview
}) => {
    const initialFormValues = initEventInfoFormValues(fetchedData);
    const [hasEmptyFields, setHasEmptyFields] = useState(false);

    const form = useForm('post', `${apiBaseUrl}/organizer/events`, initialFormValues)

    const { errors, validating, processing, hasErrors } = form

    Object.values(form.data).some((value) => value === '') || !form?.data?.schedule?.finished_at

    const disabledStartDate = (current: dayjs.Dayjs) => {
        return !!form.data.schedule?.finished_at && current > form.data.schedule.finished_at
    };

    const onSubmitClick = () => {
        const formattedEventData = {
            ...form.data,
            category: (form.data?.category?.id ? form.data.category.id : form.data?.category) as number,
            location: (form.data?.location?.id ? form.data.location.id : form.data?.location) as number,
            schedule: {
                finished_at: form.data?.schedule?.finished_at ? dayjs(form.data.schedule.finished_at).format('YYYY-MM-DD') : '',
                started_at: form.data?.schedule?.started_at ? dayjs(form.data.schedule.started_at).format('YYYY-MM-DD') : '',
            }
        }
        onSubmit(formattedEventData, isEventInfoStep);
    };

    const onValidate = (name: keyof typeof form.data) => {
        setTimeout(() => {
            form.validate(name)
        }, 300)
    }

    const onValueChange = (name: keyof typeof form.data, value: string | number) => {
        form.setData(name, value)
        form.forgetError(name)
    }

    useEffect(() => {
        setHasEmptyFields(Object.values(form.data).some((value) => value === '') || !form?.data?.schedule?.finished_at)
    }, [form.data])

    const defaultValueRangeValue: [Dayjs, Dayjs] | undefined = form.data.schedule?.finished_at ? [form.data.schedule.started_at, form.data.schedule.finished_at] : undefined

    return (
        <Layout700Center>
            <div className="event-info-form">
                {Header || null}
                <Form
                    layout={'vertical'}
                    initialValues={form.data}
                >
                    <Form.Item
                        name={"logo"}
                        className='mt-20'
                        help={form.errors.logo}
                        validateStatus={form.errors.logo ? "error" : ""}
                    >
                        <div className='flex items-center gap-4'>
                            <AvatarUpload
                                onChangeFormState={(_, value) => {
                                    if (value !== undefined) {
                                        onValueChange('logo', value)
                                        onValidate('logo')
                                    }
                                }}
                                url={form.data.logo}
                            />
                            <article>
                                <div className='flex items-center gap-4 ml-12 event-info-form__input-title'>
                                    <span className="">Event logo</span>
                                    <InfoTooltipIcon />
                                </div>
                                <span className='text-regular-12 text-neutral-7 ml-12'>Supports: .jpg, .png. Max size: 10 MB. Ratio: 1:1</span>
                            </article>
                        </div>
                    </Form.Item>
                    <Divider style={{ margin: '20px 0 16px' }} />
                    <Form.Item
                        name={'name'}
                        htmlFor={'EventNameField'}
                        help={form.errors.name}
                        validateStatus={form.errors.name ? "error" : ""}
                    >
                        <div className='flex items-center gap-4 event-info-form__input-label'>
                            <span className="">Event name</span>
                            {/* <InfoTooltipIcon /> */}
                        </div>
                        <Input
                            defaultValue={form?.data?.name}
                            placeholder="The Summit 2024"
                            id={"EventNameField"}
                            size={'large'}
                            onChange={(e) => {
                                onValueChange('name', e.target.value)
                            }}
                            onBlur={() => {
                                onValidate('name')
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        // name={'schedule'}
                        htmlFor={'DatesField'}
                        help={form.errors?.['schedule.finished_at' as keyof typeof form.errors] || form.errors?.['schedule.started_at' as keyof typeof form.errors] ? 'The date must be valid.' : ''}
                        validateStatus={form.errors?.['schedule.finished_at' as keyof typeof form.errors] || form.errors?.['schedule.started_at' as keyof typeof form.errors] ? "error" : ""}
                    >
                        <span className="event-info-form__input-label">Dates</span>
                        <DatePicker.RangePicker
                            placeholder={getRangePickerPlaceholder()}
                            id={"DatesField"}
                            size={'large'}
                            style={{ width: '100%' }}
                            defaultValue={defaultValueRangeValue}
                            onChange={(_, info) => {
                                form.setData("schedule", {
                                    started_at: dayjs(info[0]).format('YYYY-MM-DD') as unknown as string,
                                    finished_at: dayjs(info[1]).format('YYYY-MM-DD') as unknown as string
                                })
                                onValidate('schedule.started_at' as keyof typeof form.data)
                                onValidate('schedule.finished_at' as keyof typeof form.data)
                            }}
                            disabledDate={disabledStartDate}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"image"}
                        help={errors.image}
                        validateStatus={errors.image ? "error" : ""}
                    >
                        <div className='flex items-center gap-4 event-info-form__input-label'>
                            <span className="">Hero image</span>
                            <InfoTooltipIcon />
                        </div>
                        <HeroImage
                            onChangeFormState={(_, value) => {
                                if (value !== undefined) {
                                    onValueChange('image', value)
                                    onValidate('image')
                                }
                            }}
                            url={form.data.image}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'description'}
                        htmlFor={'OverviewField'}
                        help={errors.description}
                        validateStatus={errors.description ? "error" : ""}
                    >
                        <div className='flex items-center gap-4 event-info-form__input-label'>
                            <span className="">Overview</span>
                            {/* <InfoTooltipIcon /> */}
                        </div>
                        <Input.TextArea
                            id={"OverviewField"}
                            defaultValue={form?.data?.description}
                            rows={4}
                            placeholder="e.g. The Summit 2024 is the premier global tech conference, bringing together industry leaders, innovators, and entrepreneurs to explore the latest trends and technologies shaping the digital landscape"
                            className="event-info-form__overview"
                            onChange={(e) => onValueChange('description', e.target.value)}
                            onBlur={() => onValidate('description')}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'url'}
                        htmlFor={'UrlField'}
                        help={errors.url}
                        validateStatus={errors.url ? "error" : ""}
                    >
                        <div className='flex items-center gap-4 event-info-form__input-label'>
                            <span className="">URL</span>
                            {/* <InfoTooltipIcon /> */}
                        </div>
                        <Input
                            id={"UrlField"}
                            size={'large'}
                            type={'url'}
                            defaultValue={form?.data?.url}

                            placeholder="www.thesummit.com"
                            onChange={(e) => onValueChange('url', e.target.value)}
                            onBlur={() => onValidate('url')}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'location'}
                        htmlFor={'LocationField'}
                        help={errors.location}
                        validateStatus={errors.location ? "error" : ""}
                    >
                        <div className='flex items-center gap-4 event-info-form__input-label'>
                            <span className="">Location</span>
                            {/* <InfoTooltipIcon /> */}
                        </div>
                        <Locations
                            defaultValue={form.data.location.id ? { label: form.data.location.slug, value: form.data.location.id } : undefined}
                            className="event-info-form__location"
                            initialPlaceholder={form.data.location?.slug || 'Lisbon, Portugal'}
                            onLocationChange={(_, value) => {
                                onValueChange('location', value.id)
                                onValidate('location')
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<span className="event-info-form__input-label">Category</span>}
                        help={errors.category}
                        validateStatus={errors.category ? "error" : ""}
                    >
                        <Categories
                            defaultValue={form.data.category.name ? { label: form.data.category.name, value: form.data.category.id } : undefined}
                            onCategoryChange={(_, value) => {
                                onValueChange('category', value.id)
                            }}
                            onClear={() => {
                                onValueChange('category', '')
                                onValidate('category')
                            }}
                            initialPlaceholder={form.data.category?.name || 'Education & EdTech'}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'participants'}
                        htmlFor={'ParticipantsField'}
                        help={errors.participants}
                        validateStatus={errors.participants ? "error" : ""}
                    >
                        <div className='flex items-center gap-4 event-info-form__input-label'>
                            <span className="">Number of participants</span>
                            <InfoTooltipIcon />
                        </div>
                        <Input
                            defaultValue={form?.data?.participants}
                            id={"ParticipantsField"}
                            size={'large'}
                            type={'number'}
                            placeholder="500"
                            onChange={(e) => onValueChange('participants', e.target.value)}
                            onBlur={() => onValidate('participants')}
                        />
                    </Form.Item>
                </Form>
                {Footer ? (
                    <Footer
                        isSubmitDisabled={validating || hasErrors || processing || hasEmptyFields}
                        onSubmit={onSubmitClick}
                        isLoading={isLoading || processing || validating}
                        onPreview={onPreview}
                    />
                ) : null}
            </div>
        </Layout700Center>
    )
};

export default EventInfoForm;
