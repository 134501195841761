import { Statistics } from "@store/type-event/campaigns/models";
import { StatisticDate, StatisticsMetrics, StatisticsMetricTypes } from "@shared/statisticTypes.ts";
import { colors, colorsByNames, idMapping, initStatistics } from "@components/Analytics/constants.tsx";
import { formatByCurrency, formatDateToCommonFormat } from "@shared/utils.ts";

export const transformData = (statisticDates: StatisticDate[], set: string[]) => {
    return set.map(metric => ({
        id: idMapping[metric],
        data: statisticDates.map(item => ({
            x: item?.date,
            y: item?.statistics?.[metric as keyof Statistics]
        }))
    }));
}

interface DataPoint {
    x: string;
    y: number;
    prevY: number;
}

export interface LineData {
    id: string;
    data: DataPoint[];
}

const MAX_LENGTH = 32;

const denormalizePoint = (result: number, min = 0, max = 0): number => {
    if (max === min) {
        return min;
    }
    return result * (max - min) + min;
};


export const formatY = ({ y, type, currency, max, min }: { y: string | number, type: string, currency?: string, max?: number, min?: number }) => {
    const countedY = max ? denormalizePoint(Number(y || 0), min, max) : Number(y || 0)
    if (type === StatisticsMetricTypes.countable) {
        return formatNumberWithThousandsSeparator(countedY, true)
    }
    if (type === StatisticsMetricTypes.percent) {
        return `${formatNumberWithThousandsSeparator(countedY * 100)}%`
    }
    if (type === StatisticsMetricTypes.money) {
        return formatByCurrency(countedY, currency)
    }
    return formatNumberWithThousandsSeparator(countedY, true)
}

const getFirstSerie = (s: LineData, maxLength = MAX_LENGTH): LineData => {
    let filteredData = s.data.filter(point => point.y !== 0);
    const firstPoint = s.data[0] || { x: '', y: 0 };
    const lastPoint = s.data[s.data.length - 1] || { x: '', y: 0 };

    if (!filteredData.find(point => point.x === firstPoint.x)) {
        filteredData.unshift(firstPoint);
    }
    if (!filteredData.find(point => point.x === lastPoint.x)) {
        filteredData.push(lastPoint);
    }

    const minPoint = filteredData.reduce((min, p) => (p.y < min.y ? p : min), filteredData[0]);
    const maxPoint = filteredData.reduce((max, p) => (p.y > max.y ? p : max), filteredData[0]);

    if (!filteredData.find(point => point.x === minPoint.x)) {
        filteredData.push(minPoint);
    }
    if (!filteredData.find(point => point.x === maxPoint.x)) {
        filteredData.push(maxPoint);
    }

    filteredData.sort((a, b) => (a.x > b.x ? 1 : -1));

    if (filteredData.length > maxLength) {
        const step = Math.ceil(filteredData.length / maxLength);
        filteredData = filteredData.filter((_, index) => index % step === 0);
    }

    if (filteredData.length < maxLength) {
        const remainingData = s.data.filter(point => !filteredData.find(p => p.x === point.x));
        const step = Math.ceil(remainingData.length / (maxLength - filteredData.length));
        const additionalPoints = remainingData.filter((_, index) => index % step === 0);

        filteredData = [...filteredData, ...additionalPoints].slice(0, maxLength);
    }

    return {
        ...s,
        data: filteredData.sort((a, b) => (a.x > b.x ? 1 : -1)),
    };
};

export const filterData = (series: LineData[], maxLength = MAX_LENGTH): LineData[] => {
    let serieXArray: string[] = []
    return series.map((s, index) => {
        if (index === 0) {
            const firstSerie = getFirstSerie(s, maxLength)
            serieXArray = firstSerie.data.map(({ x }) => x)
            return {
                id: firstSerie.id,
                data: firstSerie.data.map(point => ({
                    x: point.x,
                    y: point.y
                })),
            }
        }
        const filteredData = s.data.filter(({ x }) => serieXArray.includes(x))
        return {
            id: s.id,
            data: filteredData.sort((a, b) => (a.x > b.x ? 1 : -1)).map(point => {
                return ({
                    x: point.x,
                    y: point.y,
                })
            }),
        }
    });
};

export const formatXValue = (value: string) => {
    const formattedDate = formatDateToCommonFormat(value)
    return formattedDate?.substring(0, formattedDate.length - 6) || '';
}

export function formatNumberWithThousandsSeparator(string: string | number, isRound = false): string {
    const number = Number(string)
    if (number === 0 || !number) {
        return isRound ? '0' : '0.00';
    }

    const fractionDigits = isRound ? 0 : 2
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(number);
}

export const formatToKPlus = (number: number) => {
    if (number >= 1000) {
        const formattedNumber = (number / 1000).toFixed(number % 1000 >= 500 ? 1 : 0);
        return `${formattedNumber}k+`;
    }
    return number.toString();
};

const normalizePoint = (pointY: number, min: number, max: number) => {
    if (max === min) {
        return 0;
    }

    if (pointY < min) {
        return 0;
    }

    if (pointY > max) {
        return 1;
    }

    return (pointY - min) / (max - min)
};

const normalizeData = (data: LineData[], min: number, max: number): LineData[] => {
    return data.map(serie => ({
        ...serie,
        data: serie.data.map(point => {
            return ({
                ...point,
                y: normalizePoint(point.y, min, max),
                prevY: point.y
            })
        })
    }));
};

export const getResponsiveLineData = ({
    statisticsDates, statisticsLeftValue, statisticsRightValue, currency, maxLength
}: {
    statisticsDates: StatisticDate[]
    statisticsLeftValue: StatisticsMetrics[]
    statisticsRightValue: StatisticsMetrics[]
    currency?: string
    maxLength?: number
}) => {
    const statisticsSelectedValuesLeft = [
        ...(Array.isArray(statisticsLeftValue) ? statisticsLeftValue : [statisticsLeftValue])]
    const statisticsIdsLeft = statisticsSelectedValuesLeft.map(({ metric }) => metric)
    const statisticsIdsSetLeft = new Set(statisticsIdsLeft)
    const statisticsSelectedValuesRight = [
        ...(Array.isArray(statisticsRightValue) ? statisticsRightValue : [statisticsRightValue])]
    const statisticsIdsRight = statisticsSelectedValuesRight.map(({ metric }) => metric)
    const statisticsIdsSetRight = new Set(statisticsIdsRight)
    const filteredStatisticsDates = [] as StatisticDate[]
    statisticsDates.forEach(({ statistics, date }) => {
        const withSameDate = filteredStatisticsDates.find(({ date: filteredDate }) => filteredDate === date)
        if (!withSameDate) {
            filteredStatisticsDates.push({
                statistics: statistics.currency === currency ? statistics : initStatistics,
                date
            })
        }
    })
    const filteredLeftData = filterData(transformData(filteredStatisticsDates, [...statisticsIdsSetLeft]), maxLength)
    const filteredRightData = filterData(transformData(filteredStatisticsDates, [...statisticsIdsSetRight]), maxLength)

    const leftMinMax = findMinMax(filteredLeftData);
    const rightMinMax = findMinMax(filteredRightData);

    const normalizedLeftData = normalizeData(filteredLeftData, leftMinMax.min, leftMinMax.max);
    const normalizedRightData = normalizeData(filteredRightData, rightMinMax.min, rightMinMax.max);

    return {
        leftData: normalizedLeftData,
        rightData: normalizedRightData,
        leftMax: leftMinMax.max,
        rightMax: rightMinMax.max,
        leftMin: leftMinMax.min,
        rightMin: rightMinMax.min,
    }
}

export const colorById = (id: string) => colors[id] || '#000000';

export const colorByRowData = (rowData: { data?: { x: string, y: number }, id: string }) => {
    const { id } = rowData
    return colorsByNames[id] || '#000000';
};

export const roundUpToSignificant = (value: number): number => {
    if (value <= 0) {
        return value
    }
    const magnitude = Math.pow(10, Math.floor(Math.log10(value)));
    return Math.ceil(value / magnitude) * magnitude;
};

export const roundDownToSignificant = (value: number): number => {
    if (value <= 0) {
        return value
    }
    const magnitude = Math.pow(10, Math.floor(Math.log10(value)));
    return Math.floor(value / magnitude) * magnitude;
};


export const findMinMax = (data: LineData[]): { min: number; max: number } => {
    const allValues = data.flatMap((serie) => serie.data.map((d) => d.y)).filter((val) => val > 0);
    let min = allValues.length ? Math.min(...allValues) : 0;
    let max = allValues.length ? Math.max(...allValues) : 0;
    min = roundDownToSignificant(min)
    max = roundUpToSignificant(max)
    return { min, max };
};
