import { Button } from 'antd';
import React from 'react';
import Bulb from '@assets/pro/Bulb.svg';
import FileSearch from '@assets/pro/FileSearch.svg';
import Layout from '@assets/pro/Layout.svg';
import LineChart from '@assets/pro/LineChart.svg';
import DividerIcon from '@assets/DividerIcon';

import cn from 'classnames';
import RocketIcon from '@assets/pro/RocketIcon';
import BoostProLogo from '@assets/pro/BoostProLogo.svg';
import '../../styles.scss';
import { useAuth } from "@contexts/AuthContext.tsx";

const AboutProMobile = ({ isProEnabled, onEnablePro }: { isProEnabled?: boolean, onEnablePro: () => void }) => {
    const { proSubscriptionData: { proSubscription } } = useAuth()
    return (
        <div className="pro-page">
            <div className="header-pro">
                <img className="header-pro-img" src={BoostProLogo} alt="BoostProLogo" />
                <span className="title">Get full campaign assistance from our team of experts with Boost PRO service</span>
                <span className="subtitle">Our expert team will handle your ad campaign management, freeing up your time to focus on growing your business</span>
                {!isProEnabled && (
                    <Button
                        className="gradient-pro"
                        size="large"
                        onClick={onEnablePro}
                    >
                        Enable PRO
                    </Button>
                )}
            </div>
            {!isProEnabled && (
                <div className="subheader">
                    <span className="subheader-text">Monthly fee – {proSubscription?.formattedPrice}</span>
                    <DividerIcon />
                    <span className="subheader-text">If monthly spend more than {proSubscription?.formattedSpendForFree} – free</span>
                </div>
            )}
            <div className="services">
                <span className="services-title">Our services</span>
                <span className="services-subtitle">Boost PRO team of digital advertising specialists is here to enhance or augment your current resources. Our full campaign management service includes:</span>

                <div className="services-cards">
                    <div className="services-card">
                        <div className="services-card-image-wrapper">
                            <img className="services-card-image" src={Bulb} alt="Bulb" />
                        </div>
                        <div className="services-card-right">
                            <span className="services-card-title">Customized ad strategies</span>
                            <span className="services-card-text">Create ad strategies according to your goals and provide customized solutions.</span>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="services-card-image-wrapper">
                            <img className="services-card-image" src={LineChart} alt="LineChart" />
                        </div>
                        <div className="services-card-right">
                            <span className="services-card-title">Campaign monitoring & optimization</span>
                            <span className="services-card-text">Regular campaign monitoring and optimization for maximum effectiveness and results.</span>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="services-card-image-wrapper">
                            <img className="services-card-image" src={Layout} alt="Layout" />
                        </div>
                        <div className="services-card-right">
                            <span className="services-card-title">Landings & creatives production</span>
                            <span className="services-card-text">Creating engaging landing pages and creatives that drive conversions and engagement.</span>
                        </div>
                    </div>
                    <div className="services-card">
                        <div className="services-card-image-wrapper">
                            <img className="services-card-image" src={FileSearch} alt="FileSearch" />
                        </div>
                        <div className="services-card-right">
                            <span className="services-card-title">Reports for tracking success</span>
                            <span className="services-card-text">Providing detailed performance reports to effectively track your success.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="how-it-works">
                <span className="how-it-works-title">How it works</span>

                <div className='how-it-works-card__steps'>
                    <div className='how-it-works-card__step'>
                        <div className='how-it-works-card__counter'>
                            01
                        </div>
                        <div className="how-it-works-card">
                            <span className="how-it-works-card-title">Briefing</span>
                            <span className="how-it-works-card-text">We’ll take the time to learn the unique aspects of your business and your specific goals.</span>
                        </div>
                    </div>
                    <div className='how-it-works-card__step'>
                        <div className='how-it-works-card__counter'>
                            02
                        </div>
                        <div className="how-it-works-card">
                            <span className="how-it-works-card-title">Strategy development</span>
                            <span className="how-it-works-card-text">Our expert team will create an effective advertising campaign strategy.</span>
                        </div>
                    </div>
                    <div className='how-it-works-card__step'>
                        <div className='how-it-works-card__counter'>
                            03
                        </div>
                        <div className="how-it-works-card">
                            <span className="how-it-works-card-title">Implementation</span>
                            <span className="how-it-works-card-text">We setup and launch campaigns, providing a smooth start and generating your first leads.</span>
                        </div>
                    </div>
                    <div className='how-it-works-card__step'>
                        <div className='how-it-works-card__counter'>
                            04
                        </div>
                        <div className="how-it-works-card">
                            <span className="how-it-works-card-title">Optimization</span>
                            <span className="how-it-works-card-text">We will fine-tune your ad campaigns for maximum effectiveness.</span>
                        </div>
                    </div>
                    <div className='how-it-works-card__step'>
                        <div className='how-it-works-card__counter'>
                            05
                        </div>
                        <div className="how-it-works-card">
                            <span className="how-it-works-card-title">Reporting</span>
                            <span className="how-it-works-card-text">Regular insights on how your ad campaigns are performing.</span>
                        </div>
                    </div>
                </div>

            </div>
            {!isProEnabled && (
                <div className="close-block">
                    <span className="close-block-title">
                        Close more deals with higher ROI and less marketing effort
                    </span>
                    <span className="close-block-subtitle">
                        Our expert team is dedicated to helping you close more deals while maximizing your return on investment for your ad campaigns
                    </span>
                    <Button
                        className={cn("get-started-btn", 'gradient-pro')}
                        size="large"
                        onClick={onEnablePro}
                    >
                        <RocketIcon />
                        Get started
                    </Button>
                </div>
            )}
        </div>
    )
}

export default AboutProMobile;
