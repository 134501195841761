import { Button, message, notification, Table } from "antd";
import type { ColumnsType } from 'antd/es/table';
import React, { useState } from "react";
import { ErrorDataResponse, formatDateToCommonFormat } from "@shared/utils.ts";
import { useGetOrganizerAudiencesQuery, useGetOrganizerSegmentsQuery, usePatchAudienceStatusByIdMutation } from '@store/type-event/audiences/audiences.api.ts';
import { TabNames } from "../constants.ts";
import emptyAudience from "@assets/png/emptyAudience.png";
import {PlusOutlined} from "@ant-design/icons";
import {AudienceItem} from "@store/type-event/audiences/models.ts";
import MenuDotsHor from "@assets/billing/MenuDotsHor.tsx";
import AudienceDropdown from "@pages/EventRolePages/AudiencesEventPage/components/AudienceDropdown.tsx";
import EditAudienceModal from "@pages/EventRolePages/AudiencesEventPage/components/EditAudienceModal.tsx";
import NameColumn from "@pages/EventRolePages/AudiencesEventPage/components/NameColumn.tsx";
import SegmentsColumn from "@pages/EventRolePages/AudiencesEventPage/components/SegmentsColumn.tsx";
import { StatusAction } from "@shared/types.ts";

const AudiencesTable = () => {
    const [isEditAudienceModalOpen, setIsEditAudienceModalOpen] = useState(false)
    const [selectedAudience, setSelectedAudience] = useState<AudienceItem | null>(null)

    const { data: audiencesRes, isLoading: isAudiencesLoading, isFetching: isAudiencesFetching, refetch } = useGetOrganizerAudiencesQuery({})
    const { data: segmentsRes } = useGetOrganizerSegmentsQuery()

    const [patchAudienceStatus, { isLoading: isPatchStatusLoading }] = usePatchAudienceStatusByIdMutation()

    const onCreateAudience = () => {
        setIsEditAudienceModalOpen(true)
        setSelectedAudience(null)
    }

    const onChangeStatus = async (action: StatusAction, item: AudienceItem) => {
        try {
            const response = await patchAudienceStatus({ statusId: action.id, id: item.id })
            if ('data' in response) {
                refetch()
                return notification.open({
                    message: `The audience status has been successfully changed to "${action.transit}"`,
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });
            }
            if ((response as ErrorDataResponse)?.error?.data?.errors) {
                return  message.open({
                    type: 'error',
                    content: (response as ErrorDataResponse)?.error?.data?.message as string || 'Error updating audience status',
                });
            }
            return message.open({
                type: 'error',
                content: `Error updating audience status`,
            });
        } catch (error) {
            return message.open({
                type: 'error',
                content: `Error updating audience status`,
            });
        }
    }

    const onEdit = (item: AudienceItem) => {
        setSelectedAudience(item)
        setIsEditAudienceModalOpen(true)
    }

    const handleEditModalClose = () => {
        setSelectedAudience(null)
        setIsEditAudienceModalOpen(false)
    }

    const columns: ColumnsType = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 200,
            render: (date) => {
                return (
                    <span
                        className="organizer-billing-page__reports__date"
                    >
                        {formatDateToCommonFormat(date)}
                    </span>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400,
            render: (name: AudienceItem['name']) => <NameColumn title={name?.title} description={name?.description} />
        },
        {
            title: 'Segments',
            dataIndex: 'segments',
            key: 'segments',
            width: 238,
            render: (segments: AudienceItem['segments']) => <SegmentsColumn segments={segments} />
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'dropdown',
            width: '8%',
            fixed: 'right',
            render: (_, item: AudienceItem) => {
                return (
                        <div>
                            <AudienceDropdown item={item} onChangeStatus={onChangeStatus} onEdit={() => onEdit(item)}>
                                <div onClick={(e) => e.preventDefault()}>
                                    <MenuDotsHor />
                                </div>
                            </AudienceDropdown>
                        </div>
                    )
            }
        },
    ];

    return (
        <div
            className={`organizer-billing-page__tab-inner ${!audiencesRes?.data?.length ? 'organizer-billing-page__tab-inner-empty' : ''}`}>
            {audiencesRes?.data?.length ? (
                <div className="organizer-billing-page__revenue-reports-title">
                    <div className="organizer-billing-page__title">{TabNames.Audiences}</div>
                    <Button
                        className="organizer-billing-page__create-request-btn"
                        type="primary"
                        onClick={onCreateAudience}
                    >
                        <PlusOutlined />
                        Create audience
                    </Button>
                </div>
            ) : (
                <div className="organizer-billing-page__title">{TabNames.Audiences}</div>
            )}
            {!(isAudiencesLoading || isAudiencesFetching || isPatchStatusLoading) && !audiencesRes?.data.length ? (
                <div className='ad-accounts-page__empty-credentials'>
                    <img src={emptyAudience} alt='emptyAudience'/>
                    <span><b>You have no audiences</b></span>
                    <span>To add one, click on the button below.</span>
                    <Button
                        type='primary'
                        className="mt-16"
                        onClick={onCreateAudience}
                    >
                        <PlusOutlined />
                        Create audience
                    </Button>
                </div>
            ) : (
                <div className="mb-24">
                    <Table
                        bordered
                        dataSource={audiencesRes?.data}
                        columns={columns}
                        rowKey="id"
                        loading={isAudiencesLoading || isAudiencesFetching || isPatchStatusLoading}
                        pagination={false}
                        scroll={{x: true}}
                    />
                </div>
            )}
            <EditAudienceModal
                isOpen={isEditAudienceModalOpen}
                selectedAudience={selectedAudience}
                segments={segmentsRes?.data || []}
                onClose={handleEditModalClose}
                refetch={refetch}
            />
        </div>
    )
}

export default AudiencesTable
