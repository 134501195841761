import { CloseOutlined } from '@ant-design/icons';
import {Button, ConfigProvider, Drawer, Modal} from 'antd';
import React, { useCallback, useContext, useState } from 'react'
import './styles.scss'
import SettingsItem from './SettingsItem';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import { PartnerLandingSectionLayout } from '@store/type-partner/landings/models';
import { OptionIds } from "@pages/PartnerRolePages/LandingPageConstructor/constants.tsx";

interface Props {
    isOpen: boolean;
    layoutData: PartnerLandingSectionLayout
}

const SectionSettingsDrawer: React.FC<Props> = ({
    isOpen,
    layoutData
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const {
        isLoading,
        setLayoutSettingsData,
        onSaveSectionSettingsClick,
        isSaveDisabled,
        clickedSectionId
    } = useContext(LandingEditorContext);

    const handleSaveClick = useCallback(() => {
        onSaveSectionSettingsClick()
    }, [onSaveSectionSettingsClick])

    const optionsList = Object.entries(layoutData.options)
        .map(([key]) => (key as OptionIds));

    const onClose = () => {
        if (isSaveDisabled) {
            setLayoutSettingsData(null)
        } else {
            setIsModalOpen(true)
        }
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#4E67EA',
                        colorPrimaryHover: '#4E67EA',
                        colorPrimaryActive: '#4E67EA',
                    },
                }}
            >
                <Drawer
                    title="Section settings"
                    closeIcon={false}
                    placement="right"
                    open={isOpen}
                    autoFocus={false}
                    mask
                    maskClosable
                    width={452}
                    className='editor-drawer'
                    onClose={onClose}
                    bodyStyle={{
                        'display': 'flex',
                        'flexDirection': 'column',
                        'justifyContent': 'space-between',
                    }}
                    maskStyle={{ 'opacity': 0, 'top': '62px' }}
                    rootStyle={{ 'top': '62px', background: 'rgba(26, 36, 64, 0.05)' }}
                    contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
                    extra={(
                        <CloseOutlined
                            onClick={onClose}
                        />
                    )}
                    footer={(
                        <>
                            <Button
                                onClick={onClose}
                                size="large"
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSaveClick}
                                className="editor-drawer__save-btn"
                                type="primary"
                                size="large"
                                loading={isLoading}
                                disabled={isLoading || isSaveDisabled}
                            >
                                Save
                            </Button>
                        </>
                    )}
                >
                    <div className='editor-book__content'>
                        {optionsList.map(((id) => {
                            return (
                                <SettingsItem
                                    key={id}
                                    id={id}
                                    layout={layoutData}
                                />
                            )
                        }))}
                    </div>
                </Drawer>
            </ConfigProvider>
            <Modal
                className="section-settings__confirm-modal"
                centered
                title="Unsaved сhanges"
                open={isModalOpen}
                okText="Exit without saving"
                okButtonProps={{
                    size: 'large'
                }}
                cancelButtonProps={{
                    size: 'large'
                }}
                onOk={() => {
                    setLayoutSettingsData(null)
                    if (clickedSectionId) {
                        localStorage.setItem('closedSectionId', clickedSectionId)
                    }
                }}
                onCancel={() => setIsModalOpen(false)}
                width={440}
            >
                You have unsaved changes. Are you sure you want<br /> to close this panel?
            </Modal>
        </>

    )
}

export default SectionSettingsDrawer
