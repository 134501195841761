const DefaultIcon = () => {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="72" height="72" rx="36" fill="#17CC80" fillOpacity="0.45" />
            <path
                d="M41.5 43H52M41.5 38H52M41.5 48H52M21.8824 28.7273H50.1176C51.1572 28.7273 52 27.859 52 26.7879V21.9394C52 20.8683 51.1572 20 50.1176 20H21.8824C20.8428 20 20 20.8683 20 21.9394V26.7879C20 27.859 20.8428 28.7273 21.8824 28.7273ZM21.8824 52H33.1176C34.1572 52 35 51.1317 35 50.0606V35.5152C35 34.4441 34.1572 33.5758 33.1176 33.5758H21.8824C20.8428 33.5758 20 34.4441 20 35.5152V50.0606C20 51.1317 20.8428 52 21.8824 52Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default DefaultIcon
