import './styles.scss'
import NotificationsHeader from '@components/NotificationsHeader'
import ProHeader from '@components/ProHeader/ProHeader';
import { StripeProvider } from '@contexts/StripeContext';
import './styles.scss'
import React, { RefObject, useContext } from "react";
import { ExitPageContext } from "@contexts/ExitPageContext.tsx";

interface HeaderMainMobileProps {
    setOpenMenu: () => void;
    setOpenNotifications: () => void
    mobileHeaderSideBar?: React.ReactNode
    menuItemRef?: RefObject<HTMLDivElement>;
}
export const HeaderMainMobile = ({
    setOpenMenu,
    setOpenNotifications,
    mobileHeaderSideBar,
    menuItemRef
}: HeaderMainMobileProps) => {
    const {
        isExitPageEnabled,
        setIsExitPageModalOpen,
    } = useContext(ExitPageContext);
    return (
        <header className='header-main-mobile'>
            <div
                ref={menuItemRef}
                onClick={() => {
                    if (isExitPageEnabled) {
                        setIsExitPageModalOpen(true)
                        return
                    }
                    setOpenMenu()
                }}
                className='header-main-mobile__burger'
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 8L22 8M2 16H22" stroke="#57585A" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
            </div>
            <aside>
                {mobileHeaderSideBar}
                <StripeProvider>
                    <ProHeader />
                </StripeProvider>
                <div
                    className='header-main-mobile__item'
                >
                    <NotificationsHeader fillColor="#57585A" />
                </div>
            </aside>

        </header>
    )
}
