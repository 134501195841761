import React, { useCallback, useEffect } from 'react'
import { Button, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import EventBillingForm from "@pages/EventRolePages/Billing/components/EventBillingForm.tsx";
import { useNavigate } from "react-router-dom";
import useEventBillingForm from "@pages/EventRolePages/Billing/useEventBillingForm.tsx";
import { useBreakpoints } from "@hooks/browser";
import ArrowIcon from "@assets/ArrowIcon.tsx";
import { settings } from "@pages/routes.ts";

interface BillingInfoDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccessCallback?: (newBillingId: number, newBillingName?: string) => void
}

const EventBillingDrawer: React.FC<BillingInfoDrawerProps> = ({
    isOpen,
    onClose,
    onSuccessCallback
}) => {
    const navigate = useNavigate()
    const isDesktop = useBreakpoints().md;

    const onSuccess = useCallback((newBillingId: number, newBillingName?: string) => {
        onClose()
        if (newBillingId) {
            if (onSuccessCallback) {
                onSuccessCallback(newBillingId, newBillingName)
            } else {
                navigate(`${settings}/revenue-reports/${newBillingId}`)
            }
        }
    }, [navigate, onClose])

    const {
        form,
        validationErrors,
        onFormChange,
        isLoading,
        onBillingInfoUpdate,
        onClearForm,
        isFormChanged
    } = useEventBillingForm({ onSuccess, isCreate: true })

    const handleCloseDrawer = () => {
        onClose()
        onClearForm()
    }

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        return () => {
            document.body.classList.remove('no-scroll');
        }
    }, [isOpen]);

    return (
        <Drawer
            prefixCls="create-billing-drawer"
            title={isDesktop ? "Create billing account" : (
                <div>
                    <Button
                        className="create-billing-drawer__back"
                        size='large'
                        type="text"
                        onClick={onClose}
                        icon={<ArrowIcon width="24" height="24" fillColor="#C0C1C3" />}
                    >
                        Back
                    </Button>
                    <div className="create-billing-drawer__title">
                        Create billing account
                    </div>
                </div>
            )}
            closeIcon={false}
            placement="right"
            open={isOpen}
            autoFocus={false}
            width={isDesktop ? 450 : 'calc(100vw - 10px)'}
            mask
            classNames={{
                body: 'event-billing-drawer',
                header: 'event-billing-drawer__header',
            }}
            maskClosable={false}
            onClose={handleCloseDrawer}
            rootStyle={{ 'top': '62px' }}
            contentWrapperStyle={{
                boxShadow: 'none',
                borderLeft: '2px solid #f7f7f7',
                width: isDesktop ? 450 : 'calc(100vw - 10px)',
            }}
            extra={(
                <CloseOutlined
                    onClick={handleCloseDrawer}
                />
            )}
        >
            {isOpen
                ? (
                    <div className='drawer-content'>
                        <EventBillingForm
                            form={form}
                            validationErrors={validationErrors}
                            onFormChange={onFormChange}
                        />
                    </div>
                )
                :
                null}
            <footer className='drawer-footer'>
                <Button
                    size='large'
                    block
                    onClick={handleCloseDrawer}
                >
                    Cancel
                </Button>
                <div style={{ minWidth: '14px' }} />
                <Button
                    size='large'
                    block
                    type='primary'
                    onClick={onBillingInfoUpdate}
                    disabled={!isFormChanged}
                    loading={isLoading}
                >
                    Create
                </Button>
            </footer>
        </Drawer>
    )
}

export default EventBillingDrawer
