import {ColorPicker, ColorPickerProps} from "antd";
import React from "react";
import './styles.scss'

type Props = Omit<ColorPickerProps, 'onChangeComplete'> & {
    onChangeComplete: (color: string) => void
}

const CustomColorPicker = ({
   defaultValue, className, value, onChangeComplete, ...props
}: Props) => {
    return (
        <ColorPicker
            className={`${value ? "color-picker" : 'color-picker-empty'} ${className || ''}`}
            defaultValue={value || 'rgb(0, 0, 0, 100%)'}
            onChangeComplete={(color) => {
                onChangeComplete(color.toHexString())
            }}
            {...props}
        />
    )
}

export default CustomColorPicker
