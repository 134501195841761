import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterByEnumItem } from './models'; // Ensure you import the correct types

interface FilterEnumsState {
    enums: Record<string, FilterByEnumItem[]>; // Correct type for enums
}

const initialState: FilterEnumsState = {
    enums: {},
};

const filterEnumsSlice = createSlice({
    name: 'filterEnums',
    initialState,
    reducers: {
        setEnums(state, action: PayloadAction<{ name: string; data: FilterByEnumItem[] }>) {
            const { name, data } = action.payload;
            state.enums[name] = data;
        },
    },
});

export const filterEnumsActions = filterEnumsSlice.actions;
export const filterEnumsSliceReducer = filterEnumsSlice.reducer;

export default filterEnumsSlice.reducer;
