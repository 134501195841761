import React from "react";
import LeadsZeroState from "@pages/PartnerRolePages/Leads/components/LeadsTableViewManager/components/LeadsZeroState";
import { LeadsTableItem } from "@store/models-to replace/leads";
import LeadsTable from "@pages/PartnerRolePages/Leads/components/LeadsTableViewManager/components/LeadsTable";
import LeadsErrorView from "@pages/PartnerRolePages/Leads/components/LeadsTableViewManager/components/LeadsErrorView";

type LeadsTableViewManagerPropsType = {
    data: LeadsTableItem[] | undefined;
    error: object | null;
    isLastPage: boolean
    page: number
    onNextPage: () => void
    loading?: boolean
}

const LeadsTableViewManager: React.FC<LeadsTableViewManagerPropsType> = (props) => {
    const { data, error, loading, isLastPage, onNextPage, page } = props;

    if (error) {
        return <LeadsErrorView />
    }

    if (data) {
        return <LeadsTable data={data} loading={loading} onNextPage={onNextPage} isLastPage={isLastPage} page={page} />
    }

    if (!data && !error && !loading) {
        return <LeadsZeroState />
    }

    return null;
}

export default LeadsTableViewManager;
