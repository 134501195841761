import React from "react";

export enum EventsTourSteps {
    eventCard,
    landingPages,
    myCampaigns,
    menuItemCampaigns,
    menuItemLeads,
    menuItemAnalytics,
    menuItemBoostPro,
}

export enum EventsTourStepsMobile {
    eventCard,
    landingPages,
    myCampaigns,
    menuItem,
    menuItemCampaigns,
    menuItemLeads,
    menuItemAnalytics,
    menuItemBoostPro,
}

export enum CampaignsTourSteps {
    campaignGoal,
    audience,
    schedule,
    adPlatform,
    adCreatives,
    landingPage,
}

export const descriptionsEvent: Record<number, React.ReactNode> = {
    [EventsTourSteps.eventCard]: (
        <div>
            On this page you can find all your
            events. Click
            {' '}
            <b>Event Card</b>
            {' '}
            for more
            details.
        </div>
    ),
    [EventsTourSteps.landingPages]: (
        <div className="mr-28">
            Here you can see and create landing
            <br/>
            pages for the event.
        </div>
    ),
    [EventsTourSteps.myCampaigns]: (
        <div>
            Here you can manage event’s campaigns,
            <br/>
            track statistics and create new ones.
        </div>
    ),
    [EventsTourSteps.menuItemCampaigns]: (
        <div>
            On the “Campaigns” tab you can find and
            <br/>
            manage all your campaigns.
        </div>
    ),
    [EventsTourSteps.menuItemLeads]: (
        <div>
            You can find all the information of your
            <br/>
            leads inside the “Leads” tab.
        </div>
    ),
    [EventsTourSteps.menuItemAnalytics]: (
        <div>
            The analytics tab contains information
            <br/>
            about spent, impressions, clicks, CPM,
            <br/>
            CPC, conversions and CTR of your
            <br/>
            campaigns and landing pages.
        </div>
    ),
    [EventsTourSteps.menuItemBoostPro]: (
        <div>
            Get full campaign assistance from our
            <br/>
            team of experts. Open
            {' '}
            <b>Boost PRO tab</b>
            {' '}
            to
            <br/>
            finish the tour.
        </div>
    )
}

export const descriptionsEventMobile: Record<number, React.ReactNode> = {
    [EventsTourStepsMobile.eventCard]: (
        <div>
            On this page you can find all your
            events. Click
            {' '}
            <b>Event Card</b>
            {' '}
            for more
            details.
        </div>
    ),
    [EventsTourStepsMobile.landingPages]: (
        <div className="mr-28">
            Here you can see and create landing
            <br/>
            pages for the event.
        </div>
    ),
    [EventsTourStepsMobile.myCampaigns]: (
        <div>
            Here you can manage event’s campaigns,
            <br/>
            track statistics and create new ones.
        </div>
    ),
    [EventsTourStepsMobile.menuItem]: (
        <div>
            In the menu you will find all the tabs to work with
        </div>
    ),
    [EventsTourStepsMobile.menuItemCampaigns]: (
        <div>
            On the “Campaigns” tab you can find and
            <br/>
            manage all your campaigns.
        </div>
    ),
    [EventsTourStepsMobile.menuItemLeads]: (
        <div>
            You can find all the information of your
            <br/>
            leads inside the “Leads” tab.
        </div>
    ),
    [EventsTourStepsMobile.menuItemAnalytics]: (
        <div>
            The analytics tab contains information
            <br/>
            about spent, impressions, clicks, CPM,
            <br/>
            CPC, conversions and CTR of your
            <br/>
            campaigns and landing pages.
        </div>
    ),
    [EventsTourStepsMobile.menuItemBoostPro]: (
        <div>
            Get full campaign assistance from our
            <br/>
            team of experts. Open
            {' '}
            <b>Boost PRO tab</b>
            {' '}
            to
            <br/>
            finish the tour.
        </div>
    )
}

export const descriptionCampaign: Record<number, React.ReactNode> = {
    [CampaignsTourSteps.campaignGoal]: (
        <div>
            Pick a goal to guide your campaign's strategy.
        </div>
    ),
    [CampaignsTourSteps.audience]: (
        <div>
            Define who should see your ads.
        </div>
    ),
    [CampaignsTourSteps.schedule]: (
        <div>
            Choose when your ads will be live.
        </div>
    ),
    [CampaignsTourSteps.adPlatform]: (
        <div>
            Select the platform aligned with your goals.
        </div>
    ),
    [CampaignsTourSteps.adCreatives]: (
        <div>
            Enter details to generate your ad creatives.
        </div>
    ),
    [CampaignsTourSteps.landingPage]: (
        <div>
            Select the page where your campaign traffic will be directed.
        </div>
    ),
}

export const infoTourDescription = (
    <div>
        In the help center you will find answers to all your questions about the platform, video tutorials and chat with support
    </div>
)

export enum TourStages {
    event = 'event',
    campaign = 'campaign',
    video = 'video',
    finish = 'finish',
}
