export const headerBtnsTitles = {
    publish: 'Publish',
    previev: 'Preview',
}
export const headerTitle = {
    profile: 'User Profile',
    billing: 'Billing',
    campaigns_create: 'Create New Campaign',
    campaigns_edit: 'Edit Campaign',
    campaigns_group: 'Campaigns Groups',
    campaigns: 'Campaigns',
    campaigns_ads: 'Ads',

    events: 'My events > ',
    events_demo: 'My events',
    my_events: 'My events',
    events_setup: 'New event',
    events_catalog: 'My events > Events catalog',
    partners: 'Sponsors',
    analytics: 'Analytics',
    leads: 'Leads',
    moderation: 'Moderation',
    ad_accounts: 'Ad Accounts',
    notifications: 'Notifications',
    settings: 'Settings',
    pro: 'Boost PRO Service',
    audiences: 'Audiences'
    // dashboard: 'Dashboard'
}
