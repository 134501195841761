import React from 'react'

const LinkedInAdsIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#F2F3F5" />
            <path d="M10.8008 12.6958V12.6958C10.8008 11.6495 11.6503 10.8022 12.6965 10.8049L12.7519 10.805H35.2497C36.3273 10.805 37.2008 11.6521 37.2008 12.6958V35.3094C37.2008 36.3533 36.3273 37.1998 35.2497 37.1998H12.7519C11.6748 37.1998 10.8008 36.3534 10.8008 35.3097V12.6958Z" fill="#0E76A8" />
            <path d="M12.7519 10.805H35.2497C36.3273 10.805 37.2008 11.6521 37.2008 12.6958V35.3094C37.2008 36.3533 36.3273 37.1998 35.2497 37.1998H12.7519C11.6748 37.1998 10.8008 36.3534 10.8008 35.3097V12.6958V12.6958C10.8008 11.6495 11.6503 10.8022 12.6965 10.8049L12.7519 10.805Z" fill="#0E76A8" />
            <path d="M19.3045 32.341V20.4578H15.3539V32.341H19.3049H19.3045ZM17.33 18.8357C18.7074 18.8357 19.5649 17.9232 19.5649 16.7828C19.5391 15.6165 18.7074 14.7295 17.3562 14.7295C16.0042 14.7295 15.1211 15.6165 15.1211 16.7827C15.1211 17.9231 15.9783 18.8356 17.3041 18.8356H17.3297L17.33 18.8357ZM21.4911 32.341H25.4413V25.7056C25.4413 25.3509 25.4671 24.9953 25.5715 24.742C25.8569 24.0321 26.5069 23.2973 27.5985 23.2973C29.0276 23.2973 29.5996 24.3869 29.5996 25.9845V32.341H33.5497V25.5275C33.5497 21.8777 31.6011 20.1792 29.002 20.1792C26.8712 20.1792 25.9353 21.37 25.4153 22.181H25.4416V20.4582H21.4913C21.5429 21.573 21.491 32.3414 21.491 32.3414L21.4911 32.341Z" fill="white" />
        </svg>
    )
}

export default LinkedInAdsIcon