import EventsPageLayout from '@layouts/EventsPageLayout'
import ModerationTable from "@pages/EventRolePages/ModerationPage/components/ModerationTable";
import { useCallback, useState, useMemo, useEffect } from "react";
import { Select, Spin } from 'antd';
import {
    initialFilters,
    statusMenuItemSelectedIcon
} from '@pages/EventRolePages/ModerationPage/constants';
import { INIT_PAGE } from '@components/InfiniteScrollTable/constants';
import { debounce, getSearchValue } from "@shared/utils";
import './styles.scss'
import { useGetOrganizerEventsFilterOptionsQuery } from '@store/type-event/events/events.api';
import { useGetOrganizerLandingsQuery } from '@store/type-event/landings/landings.api';
import { useGetOrganizerPartnersFiltersQuery } from '@store/type-event/partners/partners.api';
import { useGetOrganizerLandingReasonsQuery } from '@store/main/moderation-reasons/moderation-reasons.api';
import { ModerationStatus, ModerationStatusesFormatted } from '@shared/constants';
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';
import { FilterByEnumItem } from '@store/main/enums/models';
import { Filters, OrganizerLanding } from '@store/type-event/models';
import { useBreakpoints } from '@hooks/browser';
import FilterDrawer from './components/mobile/FilterDrawer';
import ContentLayout from '@layouts/ContentLayout';
import { menuItemSelectedIcon } from "@shared/menuItemSelectedIcon.tsx";
import { useParams } from 'react-router-dom';

type ModerationStatusKeys = keyof typeof ModerationStatusesFormatted;

const ModerationPage = () => {
    const isDesktop = useBreakpoints().md
    const { eventId } = useParams<{ eventId: string }>();

    const [filters, setFilters] = useState<Filters>(initialFilters)
    const [page, setPage] = useState(INIT_PAGE)
    const [landingsList, setLandingsList] = useState<OrganizerLanding[]>([])
    const [eventsSearchValue, setEventsSearchValue] = useState<string>('')
    const [partnersSearchValue, setPartnersSearchValue] = useState<string>('')

    const { data: events, isLoading: isEventsLoading } = useGetOrganizerEventsFilterOptionsQuery({ search: eventsSearchValue })
    const { data: partners, isLoading: isPartnersLoading } = useGetOrganizerPartnersFiltersQuery({ filters: { search: partnersSearchValue || '', page: 0 } }, { refetchOnMountOrArgChange: true })
    const { data: statuses, isLoading: isStatusesLoading } = useGetFiltersByEnumQuery({ name: 'landing_status' })

    const { data: reasons } = useGetOrganizerLandingReasonsQuery()
    const { data: landings, isLoading: isLandingsLoading, isFetching: isLandingsFetching, refetch: refetchLandings } = useGetOrganizerLandingsQuery({ search: '', page, filters })

    const mappedStatusesData = useMemo(() => {
        return statuses?.data?.map((item: FilterByEnumItem) => ({
            ...item,
            slug: (ModerationStatusesFormatted[item.slug as unknown as ModerationStatusKeys] as unknown as ModerationStatus) || item.translate
        })) || [];
    }, [statuses]);

    const debouncedEventsUpdate = useCallback(
        debounce((search: string) => {
            const searchValue = getSearchValue(search)
            setEventsSearchValue(searchValue || '');
        }, 500),
        [setEventsSearchValue]
    );

    const debouncedPartnersUpdate = useCallback(
        debounce((search: string) => {
            const searchValue = getSearchValue(search)
            setPartnersSearchValue(searchValue || '');
        }, 500),
        [setPartnersSearchValue]
    );

    const onPartnersDropdownVisibleChange = useCallback((isOpen: boolean) => {
        if (!isOpen) {
            setPartnersSearchValue('');
        }
    }, [])

    const onEventsDropdownVisibleChange = useCallback((isOpen: boolean) => {
        if (!isOpen) {
            setEventsSearchValue('');
        }
    }, [])

    useEffect(() => {
        setLandingsList((prevLandings) => {
            const from = landings?.meta?.from
            if (from && prevLandings.length < from) {
                return [...(prevLandings || []), ...(landings?.data || [])]
            }
            return landings?.data || []
        })
    }, [landings])

    const handleChangeFilter = useCallback((value: Partial<Filters>) => {
        setFilters((prev) => ({ ...prev, ...value }));
        setPage(INIT_PAGE)
    }, [])

    const handleRefetchLandings = () => {
        if (page !== INIT_PAGE) {
            setPage(INIT_PAGE)
        } else {
            void refetchLandings()
        }
    }

    const isLastPage = useMemo(() => {
        const lastPage = landings?.meta?.last_page || INIT_PAGE
        return page >= lastPage
    }, [landings?.meta?.last_page, page])

    const onResetFilters = () => {
        setFilters(initialFilters)
    }

    const onNextPage = useCallback(() => {
        setPage(page + 1)
    }, [setPage, page])

    useEffect(() => {
        if (eventId) {
            handleChangeFilter({ events: [eventId] });
        }
    }, [eventId, handleChangeFilter]);

    return (
        <EventsPageLayout>
            <ContentLayout>
                <article className='moderation-page'>
                    {isDesktop
                        ? (
                            <div className='moderation-page__filter'>

                                <div className='moderation-page__filter-select'>
                                    <span>Event</span>
                                    <Select
                                        placeholder='All events'
                                        mode="multiple"
                                        maxTagCount={1}
                                        style={{ width: '100%' }}
                                        allowClear
                                        showSearch
                                        filterOption={false}
                                        notFoundContent={isEventsLoading ? <Spin size="small" /> : null}
                                        onSearch={debouncedEventsUpdate}
                                        onDropdownVisibleChange={onEventsDropdownVisibleChange}
                                        loading={isEventsLoading}
                                        value={filters.events}
                                        onChange={(events) => handleChangeFilter({ events })}
                                        fieldNames={{ label: 'slug', value: 'id' }}
                                        options={events?.data || []}
                                        popupClassName='moderation-table__popup-select'
                                        menuItemSelectedIcon={menuItemSelectedIcon}
                                    />
                                </div>

                                <div className='moderation-page__filter-select'>
                                    <span>Sponsor</span>
                                    <Select
                                        placeholder='All partners'
                                        style={{ width: '100%' }}
                                        maxTagCount={1}
                                        mode="multiple"
                                        allowClear
                                        showSearch
                                        filterOption={false}
                                        notFoundContent={isPartnersLoading ? <Spin size="small" /> : null}
                                        onSearch={debouncedPartnersUpdate}
                                        onDropdownVisibleChange={onPartnersDropdownVisibleChange}
                                        loading={isPartnersLoading}
                                        value={filters.partners}
                                        onChange={(partners) => handleChangeFilter({ partners })}
                                        fieldNames={{ label: 'slug', value: 'id' }}
                                        options={partners?.data ? partners.data : []}
                                        popupClassName='moderation-table__popup-select'
                                        menuItemSelectedIcon={menuItemSelectedIcon}
                                    />
                                </div>
                                <div className='moderation-page__filter-select'>
                                    <span>Status</span>
                                    <Select
                                        placeholder='All statuses'
                                        maxTagCount={1}
                                        mode="multiple"
                                        showSearch={false}
                                        style={{ width: '100%' }}
                                        allowClear
                                        filterOption={false}
                                        notFoundContent={isStatusesLoading ? <Spin size="small" /> : null}
                                        loading={isStatusesLoading}
                                        value={filters.statuses}
                                        onChange={(statuses) => handleChangeFilter({ statuses })}
                                        fieldNames={{ label: 'slug', value: 'id' }}
                                        options={mappedStatusesData}
                                        popupClassName='moderation-table__popup-select moderation-table__popup-select__status'
                                        menuItemSelectedIcon={statusMenuItemSelectedIcon}
                                    />
                                </div>
                            </div>
                        )
                        : (
                            <FilterDrawer
                                filters={filters}
                                onFilterUpdate={handleChangeFilter}
                                onResetFilters={onResetFilters}
                                eventsSearchValue={eventsSearchValue}
                                partnersSearchValue={partnersSearchValue}
                                debouncedEventsUpdate={debouncedEventsUpdate}
                                debouncedPartnersUpdate={debouncedPartnersUpdate}
                                onEventsDropdownVisibleChange={onEventsDropdownVisibleChange}
                                statusMenuItemSelectedIcon={statusMenuItemSelectedIcon}
                                onPartnersDropdownVisibleChange={onPartnersDropdownVisibleChange}
                            />
                        )}

                    <div className='moderation-page__table'>
                        <ModerationTable
                            landings={landingsList}
                            setLandingsList={setLandingsList}
                            isLoading={isLandingsLoading || isLandingsFetching}
                            refetchLandings={handleRefetchLandings}
                            reasonsData={reasons?.data || []}
                            isLastPage={isLastPage}
                            onNextPage={onNextPage}
                            page={page}
                        />
                    </div>
                </article>
            </ContentLayout>
        </EventsPageLayout>
    )
}

export default ModerationPage
