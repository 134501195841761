import ZeroCampaignsIcon from '@assets/zerostate/ZeroCampaignsIcon'
import '../../styles.scss'
import CustomButton from '@components/CustomButton';
import { TabKeys } from '../../constants';

interface ZeroSponsorsProps {
    readonly?: boolean;
    onCtaClick: () => void;
    isPublished?: boolean;
    onChangeTab: (value: TabKeys) => void;
}

const ZeroSponsors = ({
    readonly,
    onCtaClick,
    isPublished,
    onChangeTab
}: ZeroSponsorsProps) => {
    const onEventDetails = () => {
        onChangeTab(TabKeys.details)
    }
    return (
        <div className='campaign-editor__zero-island'>
            <ZeroCampaignsIcon />
            <p className='campaign-editor__zero-island-title'>
                The event does not have any sponsors yet            </p>
            {isPublished ? (
                <p className='campaign-editor__zero-island-desc'>
                    To invite a pertner to the event, create invitations by clicking the button <strong>Add Invitation</strong> above
                </p>
            ) : (
                <p className='campaign-editor__zero-island-desc'>
                    To invite sponsors, you should first publish the event on the <span onClick={onEventDetails} className='text-link cursor-pointer'>Event details</span> page
                </p>
            )}
            {readonly
                ?
                null
                :
                <CustomButton
                    type='primary'
                    title='+ Add Invitations'
                    onClick={onCtaClick}
                />
            }
        </div>
    )
}

export default ZeroSponsors