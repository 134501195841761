import {useContext, useEffect} from 'react';
import {ExitPageContext} from "@contexts/ExitPageContext.tsx";

const usePopState = () => {
    const {
        isExitPageEnabled,
        setIsExitPageModalOpen
    } = useContext(ExitPageContext);

    useEffect(() => {
        const handlePopState = () => {
            if (isExitPageEnabled) {
                setIsExitPageModalOpen(true)
            }
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isExitPageEnabled]);
};

export default usePopState;
