import React from 'react';
import EventOnboardingViewManager from "./components/EventOnboardingViewManager";
import HeaderPanel from "./components/HeaderPanel";
import { useAuth } from "@contexts/AuthContext.tsx";
import { UpdateCompanyDataBodyType, useUpdateCompanyData } from "./onboarding.api.ts";
import OnboardingLayout from "@pages/Onboarding/layout";
import { usePostCreateOrganizerEventMutation } from '@store/type-event/events/events.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { COMPANY_INFO_STEP } from "@pages/Onboarding/EventOnboarding/constants";

const EventOnboarding: React.FC = () => {
    const { user, isUserLoading, userLoadingError, reFetchUser } = useAuth();
    const { isLoading: isCompanyDataLoading, error: companyDataError, run: runUpdateCompanyDataRequest } = useUpdateCompanyData();
    const { isLoading: isCreateEventLoading, error: eventError } = usePostCreateOrganizerEventMutation();

    const currentStep = user?.organization?.onboarding || '';

    const updateCompanyInfo = async (companyInfo: UpdateCompanyDataBodyType) => {
        if (currentStep === COMPANY_INFO_STEP) {
            sendAnalytics(GTMEventName.eventOnboardingFirstStep)
        }
        await runUpdateCompanyDataRequest(companyInfo);
        await reFetchUser();
    };

    return (
        <OnboardingLayout
            headerSlot={<HeaderPanel currentStep={currentStep} />}
            leftSlot={
                <EventOnboardingViewManager
                    isLoading={Boolean(isUserLoading || isCompanyDataLoading || isCreateEventLoading)}
                    isError={Boolean(userLoadingError || companyDataError || eventError)}
                    currentStep={currentStep}
                    user={user}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    updateCompany={updateCompanyInfo}
                />
            }
            rightSlot={<div style={{ backgroundColor: "#ECF0F4", height: "100%", width: "100%" }}></div>}
        />

    );
};

export default EventOnboarding;
