import AnalyticsMobileIcon from '@assets/sidemenu-mobile/AnalyticsMobileIcon'
import BillingMobileIcon from '@assets/sidemenu-mobile/BillingMobileIcon'
import BoostProMobileIcon from '@assets/sidemenu-mobile/BoostProMobileIcon'
import CampaignsMobileIcon from '@assets/sidemenu-mobile/CampaignsMobileIcon'
import HelpMobileIcon from '@assets/sidemenu-mobile/HelpMobileIcon'
import LogoutMobileIcon from '@assets/sidemenu-mobile/LogoutMobileIcon'
import ModerationMobileIcon from '@assets/sidemenu-mobile/ModerationMobileIcon'
import MyEventsmobileIcon from '@assets/sidemenu-mobile/MyEventsMobileIcon'
import ProfileMobileIcon from '@assets/sidemenu-mobile/ProfileMobileIcon'
import UserAddMobileIcon from '@assets/sidemenu-mobile/UserAddMobileIcon'
import AudiencesIcon from '@assets/sidemenu/AudiencesIcon'
import DashboardIcon from '@assets/sidemenu/DashboardIcon'
import ModerationIcon from '@assets/sidemenu/ModerationIcon'
import PartnersIcon from '@assets/sidemenu/PartnersIcon'
import { GTMEventName } from '@hooks/sendAnalytics/constants'
import sendAnalytics from '@hooks/sendAnalytics/sendAnalytics'
import {
    home,
    events,
    campaigns,
    leads,
    analytics,
    // partners,
    dashboard,
    audiences,
    moderation,
    partners,
    pro,
    constructor,
    profile,
    billing,
    help,
    logout, settings
} from '@pages/routes'
import React, { ReactNode, useContext, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { SettingsIds } from "@pages/PartnerRolePages/SettingsPage/constants.ts";
import ArrowIcon from '@assets/ArrowIcon';
import { TourContext } from "@contexts/TourContext.tsx";

export type MenuPath =
  '/' |
  '/events' |
  '/campaigns' |
  '/leads' |
  '/analytics' |
  '/audiences' |
  '/analytics' |
  '/moderation' |
  '/partners' |
  '/dashboard' |
  '/pro' |
  '/constructor' |

  '/profile' |
  '/billing' |
  '/help' |
  '/logout' |
  '/settings' |
    '/video-tour'

const MenuItemIcon = ({ path }: { path: MenuPath }) => {
    switch (path) {
    case home:
    case events:
        return (
            <MyEventsmobileIcon
                isActive={false}
            />
        )

    case campaigns:
        return (
            <CampaignsMobileIcon
                isActive={false}
            />
        )
    case leads:
        return (
            <UserAddMobileIcon
                isActive={false}
            />
        )

    case analytics:
        return (
            <AnalyticsMobileIcon
                isActive={false}
            />
        )
    case pro:
        return (
            <BoostProMobileIcon
                isActive={false}
            />
        )
        // TEMPORARY

    // EVENTS
    case dashboard:
        return (
            <DashboardIcon
                isActive={false}
            />
        )
    case audiences:
        return (
            <AudiencesIcon
                isActive={false}
            />
        )
    case moderation:
        return (
            <ModerationMobileIcon
                isActive={false}
            />
        )
    case partners:
        return (
            <PartnersIcon
                isActive={false}
            />
        )
    // USER
    case settings:
    case `${settings}/${SettingsIds.profile}`:
        return (
            <ProfileMobileIcon
                isActive={false}
            />
        )
    case billing:
        return (
            <BillingMobileIcon
                isActive={false}
            />
        )
    case help:
        return (
            <HelpMobileIcon
                isActive={false}
            />
        )
    case logout:
        return (
            <LogoutMobileIcon
                isActive={false}
            />
        )
    default:
        break;
    }
}

export type Children = {
    path: MenuPath;
    title: string | ReactNode;
}[]

type Props = {
  path: MenuPath;
  title: string | ReactNode;
  onItemClick: () => void;
    childrenProp?: Children
}

export const MenuItem = ({ path, title, onItemClick, childrenProp }: Props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const { pathname } = useLocation()

    const {
        setIsPlayerOpened,
    } = useContext(TourContext);

    const formattedPath = path === '/' ? '/' : `${path}`;

    const onClick = () => {
        onItemClick()
        sendAnalytics(`${GTMEventName.menuItemClick}_${path.replace('/', '')}`)
    }

    const onSubItemClick = (subItemPath: MenuPath) => {
        onItemClick()
        sendAnalytics(`${GTMEventName.menuItemClick}_${subItemPath.replace('/', '')}`)
    }


    const className = `sidebar-menu-drawer__item ${pathname === formattedPath || formattedPath !== '/' && pathname.includes(formattedPath) ? 'isActive' : ''}`
    return childrenProp?.length ? (
        <>
        <div
            className={className}
            onClick={() => setIsMenuOpen((prev) => !prev)}
        >
            <div className='sidebar-menu-drawer__item-icon'>
                <MenuItemIcon path={path} />
            </div>
            {title}
            <div
                className={`sidebar-menu-drawer__item__down-icon ${isMenuOpen ? 'sidebar-menu-drawer__item__down-icon-open' : ''}`}
            >
                <ArrowIcon fillColor="#898A8C" width="20" height="20" />
            </div>
        </div>
            {isMenuOpen && (
                <div className="sidebar-menu-drawer__subitems">
                    {childrenProp?.map(({ path: subItemPath, title: subItemTitle }) => {
                        return (
                            <Link key={subItemPath} onClick={() => onSubItemClick(subItemPath)} to={subItemPath}>
                                <div className="sidebar-menu-drawer__subitem">
                                    {subItemTitle}
                                </div>
                            </Link>
                        )
                    })}
                </div>
            )}
        </>

    ) : (
        <Link onClick={onClick} className={className} to={path}>
            <div className='sidebar-menu-drawer__item-icon'>
                <MenuItemIcon path={path} />
            </div>
            {title}
        </Link>
    )
};
