import dayjs from 'dayjs'
import { OrganizerEventDataFullItem } from '@store/type-event/events/models';

export const initEventInfoFormValues = (fetchedEvent: OrganizerEventDataFullItem | undefined) => {
    return {
        logo: fetchedEvent?.image_logo || '',
        name: fetchedEvent?.name || '',
        schedule: fetchedEvent?.schedule.finished_at && fetchedEvent?.schedule.started_at ? {
            started_at: dayjs(fetchedEvent.schedule.started_at ?? '', 'YYYY/MM/DD'),
            finished_at:  dayjs(fetchedEvent.schedule.finished_at ?? '', 'YYYY/MM/DD'),
        } : undefined,
        image: fetchedEvent?.image_bg || '',
        description: fetchedEvent?.description || '',
        url: fetchedEvent?.url || '',
        location: fetchedEvent?.location || { id: 0, slug: '' },
        category: fetchedEvent?.category || { id: 0, name: '' },
        participants: fetchedEvent?.participants || '',
    }
}
