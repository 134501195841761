import { Select, type SelectProps, SelectProps as AntdSelectProps, Tag } from "antd";
import React, { UIEvent, useCallback, useEffect, useState } from "react";
import { debounce, getSearchValue } from "@shared/utils";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants";
import { Meta } from "@shared/types";
import './styles.scss'
import ArrowIcon from "@assets/ArrowIcon.tsx";
import { ALL } from "@components/InfiniteSelect/constants.ts";

interface Option {
    label: string;
    value: string;
}

interface DataWrapper<T> {
    meta: Meta;
    data: T[];
}

type Props<T> = Omit<AntdSelectProps, 'value'> & {
    id: string;
    setSearchValue?: (value?: string) => void;
    page: number;
    setPage: (page: number | ((prevPage: number) => number)) => void;
    isLoading: boolean;
    data?: DataWrapper<T>
    value: { value: number; label: string }[] | string[] | string | number | null;
    optionsMapper?: (value: T) => Option
    classNameProp?: string
    withAllOption?: boolean
};

type TagRender = SelectProps['tagRender'];

const tagRender: TagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    if (label === ALL) {
        return <div>{label}</div>
    }
    return (
        <Tag
            className="select-with-all-option__tag"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
        >
            {label}
        </Tag>
    );
};

const InfiniteSelect = <T, >({
    id,
    showSearch,
    setSearchValue,
    page,
    setPage,
    data,
    isLoading,
    optionsMapper,
    classNameProp,
    withAllOption,
    onChange: onChangeFromProps,
    value,
    allowClear,
    mode,
    tagRender: tagRenderProps,
    ...restProps
}: Props<T>) => {
    const [currentValue, setCurrentValue] = useState<{ value: number; label: string }[] | string[] | string | number | null | undefined>(withAllOption && !(value as string[])?.length ? [ALL] : value)
    const [isSelectOpen, setSelectOpen] = useState(false)
    const [options, setOptions] = useState<Option[]>([]);
    const debouncedEventsUpdate = useCallback(
        debounce((search: string) => {
            const searchValue = getSearchValue(search);
            if (setSearchValue) {
                setSearchValue(searchValue || "");
            }
            setPage(INIT_PAGE);
        }, 200),
        [setSearchValue, setPage]
    );

    useEffect(() => {
        const isWithAllOption = withAllOption && (mode === 'tags' || mode === 'multiple')
        if (isWithAllOption && !(value as string[])?.length) {
            setCurrentValue([ALL])
            return
        }
        if (!isWithAllOption) {
            setCurrentValue(value)
        }
    }, [value]);

    const handleScroll = debounce((event: UIEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement;
        const scrolled = target.scrollTop + target.clientHeight >= target.scrollHeight;
        if (page < (data?.meta?.last_page || 0) && !isLoading && scrolled) {
            setPage((prevPage: number) => prevPage + 1);
        }
    }, 200);

    useEffect(() => {
        setOptions((prev: Option[]) => {
            let mappedOptions
            if (optionsMapper) {
                mappedOptions = data?.data?.map(optionsMapper) || []
            } else {
                mappedOptions = data?.data || []
            }
            const from = data?.meta?.from;
            const allOption = withAllOption ? [{ value: ALL, label: ALL }] : []
            if (typeof from === 'number' && prev.length < from) {
                return ([...prev, ...mappedOptions, ...(prev.length ? [] : allOption)]) as Option[]
            }
            return [...mappedOptions, ...allOption] as Option[];
        });
    }, [data, withAllOption]);

    const handleClear = useCallback(() => {
        const inputElement = document.querySelector<HTMLInputElement>(`#infinite-select-${id}`);
        if (inputElement) {
            const parentElement = inputElement.closest('.ant-select-selector');
            if (parentElement) {
                parentElement.classList.remove('filled');
            }
        }
    }, [id])

    const onDropdownVisibleChange = useCallback((isOpen: boolean) => {
        if (!isOpen && setSearchValue) {
            setSearchValue(undefined);
        }
        if (!isOpen) {
            handleClear()
        }
    }, [handleClear, setSearchValue]);

    useEffect(() => {
        const inputElement = document.querySelector<HTMLInputElement>(`#infinite-select-${id}`);

        const handleInputEvent = (event: Event) => {
            const target = event.target as HTMLInputElement;
            const parentElement = target.closest('.ant-select-selector');
            if (parentElement) {
                if (target.value) {
                    parentElement.classList.add('filled');
                } else {
                    parentElement.classList.remove('filled');
                }
            }
        };

        inputElement?.addEventListener('input', handleInputEvent);
        inputElement?.addEventListener('blur', handleClear);


        return () => {
            inputElement?.removeEventListener('input', handleInputEvent);
            inputElement?.removeEventListener('blur', handleClear);
        };
    }, [handleClear, id]);

    const isAllowClear = Boolean(allowClear && currentValue && Array.isArray(currentValue) && !(currentValue as string[])?.includes(ALL))

    return (
        <Select
            id={`infinite-select-${id}`}
            listHeight={160}
            showSearch={showSearch}
            onSearch={showSearch ? debouncedEventsUpdate : undefined}
            onDropdownVisibleChange={(isOpen) => {
                setSelectOpen(isOpen)
                if (onDropdownVisibleChange) {
                    onDropdownVisibleChange(isOpen)
                }
            }}
            open={isSelectOpen}
            options={options}
            onPopupScroll={handleScroll}
            loading={isLoading}
            className={`${classNameProp || ''} infinite-select`}
            onClear={handleClear}
            onBlur={handleClear}
            onFocus={handleClear}
            value={currentValue}
            allowClear={isAllowClear}
            mode={mode}
            tagRender={withAllOption ? tagRender : tagRenderProps}
            onChange={(value, option) => {
                const isWithAllOption = withAllOption && (mode === 'tags' || mode === 'multiple')
                if (isWithAllOption) {
                    if (value[value.length - 1] === ALL || !value.length) {
                        onChangeFromProps([])
                        setCurrentValue([ALL])
                        setSelectOpen(false)
                    } else {
                        const newValue = value.filter((el) => el !== ALL)
                        setCurrentValue(newValue)
                        onChangeFromProps(newValue)
                    }
                } else {
                    onChangeFromProps(value, option)
                    setCurrentValue(value)
                    setSelectOpen(false)
                }
            }}
            suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="20" height="20" className="common-select-icon" />}
            {...restProps}
        />
    );
};

export default InfiniteSelect;
