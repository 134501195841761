import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Dropdown, Input, Popover} from 'antd'
import './styles.scss'
import AlignCenterIcon from "@assets/lpEditor/AlignCenterIcon.tsx";
import MenuDotsHor from "@assets/billing/MenuDotsHor.tsx";
import {
    BUTTON_TOOLTIP_WIDTH,
    DATA_ELEMENT_ID,
    dotsItems,
    POPOVER_IDS,
    SCRIPT_TYPES,
    textAlignItems, TOOLTIP_HEIGHT, TOOLTIP_WIDTH
} from "@pages/PartnerRolePages/LandingPageConstructor/components/EditableHtml/constants.ts";
import ArrowIcon from "@assets/ArrowIcon.tsx";
import CustomColorPicker
    from "@pages/PartnerRolePages/LandingPageConstructor/components/CustomColorPicker/CustomColorPicker.tsx";
import {
    convertPercentToDecimal,
    setCursorToEnd
} from "@pages/PartnerRolePages/LandingPageConstructor/helpers.ts";
import ResetIcon from "@assets/lpEditor/ResetIcon.tsx";
import InsertLinkIcon from "@assets/lpEditor/InsertLinkIcon.tsx";
import DeleteIcon from "@assets/lpEditor/DeleteIcon.tsx";
import {generateUniqueStringId} from "@shared/utils.ts";
import CustomTooltip from "@components/CustomTooltip/CustomTooltip.tsx";
import {
    addScripts,
    cleanHtml,
    ensureHttpsUrl,
    filterTextElements,
    isTextTarget,
    removeScriptsById,
    TEXT_SELECTOR
} from "@pages/PartnerRolePages/LandingPageConstructor/components/EditableHtml/helpers.ts";
import {scrollToElementCenter} from "@components/TourComponent/helpers.ts";

type Props = {
    initialHtml: string
    onUpdate: (newContent: string) => void
    isActive: boolean
    id: string
    popovers: Record<string, boolean>
    handleSetPopovers: (id: POPOVER_IDS, isOpen: boolean) => void
}

const EditableHtml: React.FC<Props> = ({ initialHtml, onUpdate, isActive, id, popovers, handleSetPopovers }) => {
    const [selectedElement, setSelectedElement] = useState<HTMLElement | null>(null);
    const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number } | null>(null);
    const [selectedElementColor, setSelectedElementColor] = useState('')
    const [selectedElementMaxLength, setSelectedElementMaxLength] = useState(0)
    const [changedElementLength, setChangedElementLength] = useState(0)
    const [isSelectedElementButton, setIsSelectedElementButton] = useState(false)
    const editableRef = useRef<HTMLDivElement | null>(null);
    const initHtmlRef = useRef(initialHtml)
    const [defaultValues, setDefaultValues] = useState<Record<string, Record<string, string>>>({})
    const [url, setUrl] = useState('');
    const [selectedRange, setSelectedRange] = useState<Range | null>(null);
    const scriptIds = useRef<string[]>([]);

    const handleUpdate = () => {
        const cleanedHtml = cleanHtml(editableRef.current?.innerHTML || initialHtml)
        onUpdate(cleanedHtml);
    }
    const handleResetSelectedElement = useCallback(() => {
        setSelectedElement(null);
        setSelectedElementColor('');
        setSelectedElementMaxLength(0)
        setTooltipPosition(null);
        setIsSelectedElementButton(false)
        const previouslySelectedElements = editableRef.current?.querySelectorAll('[data-selected="true"]');
        previouslySelectedElements?.forEach((element) => {
            element.removeAttribute('data-selected');
        });
    }, [])

    useEffect(() => {
        if (!isActive) {
            handleResetSelectedElement()
        }
    }, [isActive]);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        const target = e.currentTarget;
        e.preventDefault()
        if (isTextTarget(target)) {
            const isButton = target.className.includes('ButtonComponent-')
            setIsSelectedElementButton(isButton)
            setSelectedElement(target as HTMLElement);
            const color = convertPercentToDecimal(getComputedStyle(target).color || '')
            setSelectedElementColor(color);
            const previouslySelectedElements = editableRef.current?.querySelectorAll('[data-selected="true"]');
            previouslySelectedElements?.forEach((element) => {
                element.removeAttribute('data-selected');
            });
            target.setAttribute('data-selected', 'true');
            const innerTextLength = target.innerText.length
            setChangedElementLength(innerTextLength)
            const containerRect = (editableRef.current as HTMLElement)?.getBoundingClientRect();
            const targetRect = target.getBoundingClientRect();
            const relativeTop = targetRect.top - containerRect.top - TOOLTIP_HEIGHT;
            const relativeLeft = targetRect.left - containerRect.left;
            const tooltipWidth = isButton ? BUTTON_TOOLTIP_WIDTH : TOOLTIP_WIDTH
            const relativeCenterLeft = relativeLeft + targetRect.width / 2 - tooltipWidth;
            setTooltipPosition({
                top: relativeTop,
                left: relativeCenterLeft
            });
            const maxLength = target.getAttribute('data-max-length');
            setSelectedElementMaxLength(Number(maxLength))
        } else {
            handleResetSelectedElement()
        }
    };

    const handleTextChange = () => {
        handleUpdate()
    };

    const handleColorChange = (color: string) => {
        if (selectedElement) {
            if (selectedRange?.toString()) {
                const selection = window.getSelection();
                const anchor = document.createElement('span');
                anchor.style.color = color;
                anchor.textContent = selectedRange.toString();
                selectedRange.deleteContents();
                selectedRange.insertNode(anchor);
                setSelectedRange(null);
                setUrl('');
                handleUpdate();
                if (selection) {
                    selection.removeAllRanges();
                    const newRange = document.createRange();
                    newRange.selectNodeContents(anchor);
                    selection.addRange(newRange);
                }
            } else {
                const elements = editableRef.current?.querySelectorAll(`[data-selected="true"]`) as NodeListOf<HTMLElement>;
                const elementToReset = Array.from(elements)?.[0] as HTMLElement | undefined;
                if (elementToReset) {
                    elementToReset.style.color = color;
                    setSelectedElementColor(color)
                    handleUpdate()
                }
            }
        }
    };

    const handleSetToDefault = (): void => {
        const elements = editableRef.current?.querySelectorAll(`[data-selected="true"]`) as NodeListOf<HTMLElement>;
        const elementToReset = Array.from(elements)?.[0] as HTMLElement | undefined;

        if (elementToReset) {
            const id = elementToReset.getAttribute(DATA_ELEMENT_ID) || ''
            const defaultColor = defaultValues?.[id]?.color
            elementToReset.innerHTML = defaultValues?.[id]?.innerHTML;
            elementToReset.style.color = defaultColor;
            setSelectedElementColor(defaultColor)
            handleUpdate()
        }
    };

    const onDeleteText = () => {
        if (selectedElement) {
            if (selectedRange?.toString()) {
                selectedRange.deleteContents();
                setSelectedRange(null);
                handleUpdate()
            } else {
                const elements = editableRef.current?.querySelectorAll(`[data-selected="true"]`) as NodeListOf<HTMLElement>;
                const elementToReset = Array.from(elements)?.[0] as HTMLElement | undefined;
                if (elementToReset) {
                    elementToReset.innerText = '';
                    handleUpdate()
                }
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
        const newLength = e.target.textContent?.length || 0
        const maxLength = Number(e.target.getAttribute('data-max-length')) || 0;
        if (!maxLength || (newLength <= maxLength)) {
            setChangedElementLength(newLength)
        } else {
            e.target.textContent = e.target.textContent?.slice(0, maxLength) || ''
            const target = e.target as HTMLElement;
            setCursorToEnd(target)
        }
    }

    useEffect(() => {
        if (editableRef.current) {
            const elements = editableRef.current.querySelectorAll(TEXT_SELECTOR);
            const filteredElements = filterTextElements(elements)
            const initDefaultValues = {} as Record<string, Record<string, string>>
            filteredElements.forEach((element) => {
                element.addEventListener('click', handleClick);
                element.setAttribute('contentEditable', 'true');
                const uniqId = generateUniqueStringId()

                const color = convertPercentToDecimal(getComputedStyle(element).color || '')
                initDefaultValues[uniqId] = {
                    color,
                    innerHTML: (element as HTMLElement)?.innerHTML
                }
                setDefaultValues(initDefaultValues)
                element.setAttribute(DATA_ELEMENT_ID, uniqId);
                element.addEventListener('blur', handleTextChange);
                element.addEventListener('input', handleInputChange);
            });

            const scriptTags = editableRef.current?.querySelectorAll('script');

            if (scriptTags?.length) {
                scriptIds.current = addScripts(scriptTags)
            }

            return () => {
                filteredElements.forEach((element) => {
                    element.removeEventListener('click', handleClick);
                    element.removeAttribute('contentEditable');
                    element.removeEventListener('blur', handleTextChange);
                    element.removeEventListener('input', handleInputChange);
                });
                removeScriptsById(scriptIds.current)
                scriptIds.current = []
            };
        }
    }, []);

    const handleTextAlignChange = useCallback(({ key }: { key: string }) => {
        if (selectedElement) {
            const elements = editableRef.current?.querySelectorAll(`[data-selected="true"]`) as NodeListOf<HTMLElement>;
            const elementToReset = Array.from(elements)?.[0] as HTMLElement | undefined;

            if (elementToReset) {
                elementToReset.style.textAlign = key;
                handleUpdate()
            }
        }
    }, [selectedElement])

    const handleScriptChange = ({ key }: { key: string }) => {
        if (selectedElement) {
            if (selectedRange?.toString()) {
                const anchor = document.createElement(key);
                anchor.textContent = selectedRange.toString();
                selectedRange.deleteContents();
                selectedRange.insertNode(anchor);
                setSelectedRange(null);
                handleUpdate()
                handleSetPopovers(POPOVER_IDS.dots, false)
                restoreSelection()
            } else {
                const text = selectedElement.innerText;
                selectedElement.innerHTML = key === SCRIPT_TYPES.sup ? `<sup>${text}</sup>` : `<sub>${text}</sub>`;
                handleUpdate()
            }
        }
    };

    const closedSectionId = localStorage.getItem('closedSectionId')
    const newSectionIdAdded = localStorage.getItem('newSectionIdAdded')
    useEffect(() => {
        if (closedSectionId === id) {
            if (editableRef.current) {
                editableRef.current.innerHTML = initialHtml
                const elements = editableRef.current.querySelectorAll(TEXT_SELECTOR);
                const filteredElements = filterTextElements(elements)
                const initDefaultValues = {} as Record<string, Record<string, string>>
                filteredElements.forEach((element) => {
                    element.addEventListener('click', handleClick);
                    element.setAttribute('contentEditable', 'true');
                    const uniqId = generateUniqueStringId()

                    const color = convertPercentToDecimal(getComputedStyle(element).color || '')
                    initDefaultValues[uniqId] = {
                        color,
                        innerHTML: (element as HTMLElement)?.innerHTML
                    }
                    setDefaultValues(initDefaultValues)
                    element.setAttribute(DATA_ELEMENT_ID, uniqId);
                    element.addEventListener('blur', handleTextChange);
                    element.addEventListener('input', handleInputChange);
                });
                const scriptTags = editableRef.current?.querySelectorAll('script');

                if (scriptTags?.length) {
                    scriptIds.current = addScripts(scriptTags)
                }
            }
            localStorage.setItem('closedSectionId', '')
        }
        return () => {
            removeScriptsById(scriptIds.current)
            scriptIds.current = []
        }
    }, [closedSectionId, initialHtml, id]);
    useEffect(() => {
        if (newSectionIdAdded === id) {
            scrollToElementCenter({ element: editableRef.current, minDistanceFromTop: 100, isBlockScroll: false })
            localStorage.removeItem('newSectionIdAdded')
        }
    }, [newSectionIdAdded, id]);

    const handleSelectionChange = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            if (editableRef.current?.contains(range.commonAncestorContainer)) {
                setSelectedRange(range);
            }
        }
    };

    const restoreSelection = () => {
        const selection = window.getSelection();
        if (selection && selectedRange) {
            selection.removeAllRanges();
            selection.addRange(selectedRange);
        }
    };

    const replaceSelectedTextWithLink = () => {
        if (selectedRange?.toString() && url) {
            const anchor = document.createElement('a');
            anchor.href = ensureHttpsUrl(url);
            anchor.style.color = 'inherit'
            anchor.target = '_blank';
            anchor.textContent = selectedRange.toString();
            selectedRange.deleteContents();
            selectedRange.insertNode(anchor);
            setSelectedRange(null);
            setUrl('');
            handleUpdate()
            restoreSelection()
        }
        handleSetPopovers(POPOVER_IDS.link, false)
    };

    React.useEffect(() => {
        document.addEventListener('selectionchange', handleSelectionChange);
        return () => {
            document.removeEventListener('selectionchange', handleSelectionChange);
        };
    }, []);

    return (
        <div className={`editable-html ${isActive ? '' : 'editable-html__disabled'}`}>
            <div
                className="editable-content"
                ref={editableRef}
                dangerouslySetInnerHTML={{__html: initHtmlRef.current}}
            />
            {tooltipPosition && selectedElement && isActive && (
                <div
                    className="editable-html__tooltip"
                    style={{top: tooltipPosition.top, left: tooltipPosition.left}}
                >
                    <Dropdown
                        menu={{items: textAlignItems, onClick: handleTextAlignChange}}
                        open={popovers[POPOVER_IDS.align]}
                        onOpenChange={(isOpen) => {
                            restoreSelection()
                            handleSetPopovers(POPOVER_IDS.align, isOpen)
                        }}
                    >
                        <div onClick={(e) => e.preventDefault()} className="editable-html__alignment">
                            <AlignCenterIcon/>
                            <div className="editable-html__alignment-icon">
                                <ArrowIcon fillColor="#C0C1C3" width="20" height="20"/>
                            </div>
                        </div>
                    </Dropdown>

                    <div className="editable-html__divider"/>
                    {!isSelectedElementButton && (
                        <>
                            <CustomTooltip
                                title="Text color"
                            >
                                <CustomColorPicker
                                    className="editable-html__color-picker"
                                    style={{
                                        // @ts-ignore
                                        '--custom-bg-color': selectedElementColor,
                                    }}
                                    showText={false}
                                    format="rgb"
                                    open={popovers[POPOVER_IDS.colorPicker]}
                                    onOpenChange={(isOpen) => {
                                        restoreSelection()
                                        handleSetPopovers(POPOVER_IDS.colorPicker, isOpen)
                                    }}
                                    value={selectedElementColor}
                                    onChangeComplete={handleColorChange}
                                />
                            </CustomTooltip>
                            <div className="editable-html__divider"/>
                        </>
                    )}
                    <CustomTooltip
                        title="Reset to default"
                        margin={18}
                    >
                        <div
                            className="editable-html__tooltip__icon editable-html__tooltip__stroke-icon"
                            onClick={handleSetToDefault}
                        >
                            <ResetIcon/>
                        </div>
                    </CustomTooltip>
                    {!isSelectedElementButton && (
                        <CustomTooltip
                            margin={18}
                            title="Insert link"
                        >
                            <Popover
                                open={popovers[POPOVER_IDS.link]}
                                onOpenChange={(isOpen) => {
                                    restoreSelection()
                                    handleSetPopovers(POPOVER_IDS.link, isOpen)
                                }}
                                overlayClassName="editable-html__popover"
                                arrow={false}
                                placement="bottomLeft"
                                key={id}
                                title=""
                                trigger="click"
                                content={(
                                    <>
                                        <div className="editable-html__popover__title">
                                            Add link
                                        </div>
                                        <div className='editable-html__popover__label'>
                                            URL
                                        </div>
                                        <div className="flex-center gap-8">
                                            <Input
                                                placeholder="Enter URL"
                                                style={{ width: '237px' }}
                                                value={url}
                                                onChange={e => {
                                                    setUrl(e.target.value)
                                                }}
                                            />
                                            <Button
                                                className="ml-auto"
                                                type="primary"
                                                disabled={!url}
                                                onClick={replaceSelectedTextWithLink}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </>
                                )}
                            >
                                <div className="editable-html__tooltip__icon editable-html__tooltip__stroke-icon">
                                    <InsertLinkIcon/>
                                </div>
                            </Popover>
                        </CustomTooltip>
                    )}
                    <div className="editable-html__divider"/>

                    <CustomTooltip
                        title="Delete text"
                        margin={18}
                    >
                        <div
                            className="editable-html__tooltip__icon editable-html__tooltip__fill-icon"
                            onClick={onDeleteText}
                        >
                            <DeleteIcon/>
                        </div>
                    </CustomTooltip>

                    <Dropdown
                        menu={{items: dotsItems, onClick: handleScriptChange}}
                        placement="bottomRight"
                        open={popovers[POPOVER_IDS.dots]}
                        onOpenChange={(isOpen) => {
                            handleSetPopovers(POPOVER_IDS.dots, isOpen)
                        }}
                    >
                        <div onClick={(e) => e.preventDefault()}>
                            <MenuDotsHor/>
                        </div>
                    </Dropdown>
                    {Boolean(selectedElementMaxLength) && (
                        <>
                            <div className="editable-html__divider"/>
                            <div className="editable-html__text-amount">
                                {changedElementLength}/{selectedElementMaxLength}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default EditableHtml;
