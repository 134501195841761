// REQUEST
export interface AdAsset {
    id?: number | null;
    value: string;
}
interface Ad {
    id?: number | null;
    landing?: AdAsset | null;
    assets: {
        title: AdAsset[];
        short_title: AdAsset[];
        description: AdAsset[];
        image_original: AdAsset[];
        logo_original: AdAsset[];
        business_name: AdAsset[];
    };
}
export interface Channel {
    id: number | null;
    type: number;
    bid: number;
    budget: number;
    title: string;
    //status: 0: stop, 1: enabled, 2: paused
    status: number;
    slug?: string;
}
interface Schedule {
    started_at: string;
    finished_at: string;
}

export interface Campaign {
    eventId?: string;
    campaignId?: string;
    title: string;
    audiences: number[];
    goal: number;
    schedule: Schedule;
    channels: Channel[];
    ads: {
        id?: number | null;
        landing: number | null;
        assets: {
            title: AdAsset[];
            short_title: AdAsset[];
            description: AdAsset[];
            image_original: AdAsset[];
            logo_original: AdAsset[];
            business_name: AdAsset[];
        }
    }[];
}

// RESPONSE

interface AdResponse {
    id: number;
    landing: {
        id: number;
        title: string;
        url: string;
        status: number;
    };
    assets: {
        title: AdAsset[];
        short_title: AdAsset[];
        description: AdAsset[];
        image_original: AdAsset[];
        logo_original: AdAsset[];
        business_name: AdAsset[];
    };
}

interface ChannelResponse {
    id: number;
    title: string;
    type: {
        id?: 1 | 2;
        name: string;
    };
    status: {
        id: 0 | 1;
        name: string;
    };
    budget: number;
    bid: number;
}





export interface CreateCampaignResponse {
    id: number;
    user_id: number;
    organization_id: number;
    event_id: number;
    landing_id: number;
    invite_id: number;
    title: string;
    audiences: number[],
    goal: {
        id: number;
        name: string;
        status: 0 | 1;
    };
    status: {
        id: number;
        name: string;
    };
    schedule: {
        started_at: string;
        finished_at: string;
    };
    channels: ChannelResponse[];
    ads: AdResponse[];
}
export interface GetCampaignResponse {
    data: {
        id: number;
        user_id: number;
        organization_id: number;
        event_id: number;
        landing_id: number;
        invite_id: number;
        title: string;
        audiences: {
            id: number;
            name: string;
        }[];
        goal: {
            id: number;
            name: string;
            status: 0 | 1;
        };
        status: {
            id: number;
            name: string;
        };
        schedule: {
            started_at: string;
            finished_at: string;
        };
        channels: ChannelResponse[];
        ads: AdResponse[];
    }
}

export interface GetCampaigsnResponse {
    data: {
        id: number;
        name: string;
        // is_active: boolean;
        status: {
            id: number;
            name: string;
        };
        landing: string;
        channels: string[];
    }

}
export interface GetCampaigByIdnResponse {
    data: {
        title: string;
        goal: {
            id: number,
            name: string
        };
        schedule: {
            started_at: string;
            finished_at: string;
        };
        channels: Channel[];
        ads: Ad[];
    }

}






export interface UpdateCampaignRequest {
    id: string;
    title: string;
    goal: "target_cpc" | "target_cpl" | "max_leads" | "max_clicks";
    budget: number;
    budget_daily?: number;
    channel: string[];
    // channel: "google_ads" | "linkedin";
    audiences: number[];
    data?: any;
    started_at: string;
    finished_at: string;
}
export interface UpdateCampaignStatusRequest {
    campaign_id: string;
    status: "in_progress" | "paused"
}
export interface UpdateCampaignStatusResponse {
    id: number
    user_id: number
    organization_id: number
    event_id: any
    landing_id: any
    title: string
    goal: string
    budget: string
    budget_daily: string
    channel: any
    audiences: number[]
    data: any
    stat: any
    status: "in_progress" | "paused"
    started_at: any
    finished_at: any
    created_at: string
    updated_at: string
}
export interface UpdateCampaignsListStatusRequest {
    campaign_ids: string[];
    status: "in_progress" | "paused"
}

export interface CampaignsDataStat {
    spent: number;
    impressions: number;
    clicks: number;
    average_ctr: number;
    average_cpm: number;
    average_cpc: number;
    conversions: number;
    cost_per_conversion: number;
    leads: number;
    cost_per_lead: number
}
export interface CampaignsDataItemData {
    bid: number
}


// RESPONSE
export interface CampaignsData {
    key?: string;
    id: string;
    user_id: string;
    organization_id: string;
    event_id: string;
    landing_id: string;
    title: string;
    status: "in_progress" | "paused";
    goal: string;
    budget: string;
    budget_daily: string;
    channel: string | null;
    audience: any | null; // Change the type to the actual type if known
    data: CampaignsDataItemData | null; // Change the type to the actual type if known
    stat: CampaignsDataStat | null; // Change the type to the actual type if known
    started_at: string | null;
    finished_at: string | null;
    created_at: string | null;
    updated_at: string | null;
}


// export interface GetCampaignResponse {
//     current_page: number;
//     data: CampaignsData[];
//     first_page_url: string;
//     from: number;
//     last_page: number;
//     last_page_url: string;
//     links: {
//         url: string | null;
//         label: string;
//         active: boolean;
//     }[];
//     next_page_url: string | null;
//     path: string;
//     per_page: number;
//     prev_page_url: string | null;
//     to: number;
//     total: number;
// }


//dp
interface CampaignAsset {
    id: number;
    user_id: number;
    organization_id: number;
    event_id: number;
    landing_id: number;
    campaign_id: number;
    title: string;
    url: string;
    images: string[] | null;
    data: any[] | null;
    created_at: string | null;
    updated_at: string | null;
}
export interface GetAssetsResponse {
    current_page: number;
    data: CampaignAsset[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: {
        url: string | null;
        label: string;
        active: boolean;
    }[];
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
}

export interface CreateCampaignAssetRequest {
    id: string;
    title?: string;
    url?: string;
    data: {
        titles: string[];
        short_titles: string[];
        urls: string[];
        images: string[];
    }
}
export interface CreateCampaignAssetResponse {
    user_id: number
    organization_id: number
    event_id: string
    landing_id: string
    campaign_id: number
    title: string
    url: string
    data: {
        titles: string[]
        short_titles: string[]
        urls: string[]
        images: string[]
    }
    updated_at: string
    created_at: string
    id: number
}

export interface PatchCampaignAssetByIdRequest {
    id: string;
    title: string[];
    url: string[];
    data: {
        titles: string[];
        short_titles: string[];
        urls: string[];
        images: string[];
    }
}

export enum CampaignEditorEnum {
    new = 'new'
}
