const LaptopIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.9483 13.9516L14.868 10.1462V1.86049C14.868 1.54442 14.6126 1.28906 14.2966 1.28906H1.72513C1.40906 1.28906 1.1537 1.54442 1.1537 1.86049V10.1462L0.0733458 13.9516C-0.0605827 14.3248 0.214417 14.7176 0.610846 14.7176H15.4108C15.8073 14.7176 16.0823 14.3248 15.9483 13.9516ZM2.43942 2.57478H13.5823V9.62835H2.43942V2.57478V2.57478ZM6.5162 13.4319L6.66085 12.7712H9.34477L9.48942 13.4319H6.5162V13.4319ZM10.5162 13.4319L10.1751 11.8837C10.1608 11.8176 10.1019 11.7712 10.0358 11.7712H5.97156C5.9037 11.7712 5.84656 11.8176 5.83227 11.8837L5.4912 13.4319H1.55727L2.31263 10.7712H13.7091L14.4644 13.4319H10.5162Z"
                fill="#252628"/>
        </svg>
    )
}

export default LaptopIcon