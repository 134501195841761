import {
    Filters,
    RevShareFiltersType,
    TransactionsFiltersType,
    WithdrawalFiltersType
} from "@pages/EventRolePages/Billing/types.ts";

export enum TabNames {
    RevenueReports = 'Revenue reports',
    Transactions = 'Transactions',
    WithdrawalRequests = 'Withdrawal requests',
    BillingDetails = 'Billing details',
    Invoices = 'Invoices',
    RevShareCommissions = 'Rev share commissions',
}

export enum TabKeys {
    RevenueReports = 'revenue-reports',
    Transactions = 'transactions',
    WithdrawalRequests = 'withdrawal-requests',
    BillingDetails = 'billing-details',
    Invoices = 'invoices',
    RevShareCommissions = 'rev-share-commissions',
    Billing = 'billing',
}

export const initialFilters: Filters = {
    group_by_partner: false,
    page: 1,
    events: [],
    partners: [],
    gap: 1,
    dateFrom: '',
    dateTo: ''
}

export const initialTransactionsFilters: TransactionsFiltersType = {
    page: 1,
    events: [],
    types: [],
    dateFrom: '',
    dateTo: ''
}

export const initialRevShareFilters: RevShareFiltersType = {
    page: 1,
    events: [],
}

export const initialWithdrawalFilters: WithdrawalFiltersType = {
    page: 1,
    status: [],
    dateFrom: '',
    dateTo: ''
}

export const PLUS_SIGN = '+'
export const MINUS_SIGN = '-'

export enum ModalSteps {
    first = 'first',
    second = 'second'
}

export const initialEventBillingValues = {
    company_name: '',
    email: '',
    address: '',
    postal_code: '',
    tax_code: '',
    currency: null,
    city: null,
    country: null,
    bank_details: {
        bank_name: '',
        bank_code: '',
        account_number: '',
        iban: '',
    }
}

export const initialEventBillingErrorsValues = {
    name: '',
    company_name: '',
    email: '',
    address: '',
    postal_code: '',
    tax_code: '',
    currency: '',
    'city.id': '',
    'city': '',
    'country.id': '',
    'country': '',
    bank_details: '',
    'bank_details.bank_name': '',
    'bank_details.bank_code': '',
    'bank_details.account_number': '',
    'bank_details.iban': '',
}

export const transactionsSortFields: Record<string, string> = {
    type: 'type',
    cost: 'amount'
}
