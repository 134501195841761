import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Avatar, notification, TableProps } from 'antd';
import emptyCredentialsPng from '@assets/png/emptyCredentials.png'
import '../styles.scss'
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import MenuDotsHor from "@assets/billing/MenuDotsHor";
import { UserEditErrorsType, UserEditType } from "@pages/PartnerRolePages/SettingsPage/types.ts";
import RowDropdown from "@pages/PartnerRolePages/SettingsPage/components/RowDropdown.tsx";
import UserModal from "@pages/PartnerRolePages/SettingsPage/components/UserModal.tsx";
import {
    InitialUserEdit,
    InitialUserEditErrors
} from "@pages/PartnerRolePages/SettingsPage/constants.ts";
import ConfirmModal from "@pages/PartnerRolePages/SettingsPage/components/ConfirmModal.tsx";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api.ts";
import { useGetUsersQuery, usePostUserMutation, useDeleteUserByIdMutation, usePatchUserMutation } from "@store/main/users-roles/users.api.ts";
import { PostUser, UserAndRole } from "@store/main/users-roles/models.ts";
import { ErrorDataResponse, errorsToFormAdapter } from "@shared/utils.ts";
import InfiniteScrollTable from "@components/InfiniteScrollTable/InfiniteScrollTable.tsx";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import { CampaignDataItem } from "@store/type-partner/campaigns/models.ts";
import { UserBillingOrganizerItem } from "@store/type-event/billing/models.ts";
import { Orders } from "@shared/constants.ts";

type Props = {
    isEvent?: boolean;
}

const UsersAndRoles = ({ isEvent }: Props) => {
    const [isUserModalOpened, setIsUserModalOpened] = useState(false)
    const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [formState, setFormState] = useState<UserAndRole>(InitialUserEdit);
    const [errors, setErrors] = useState<UserEditErrorsType>(InitialUserEditErrors);
    const [page, setPage] = useState(INIT_PAGE)
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('')

    const { data: roles } = useGetFiltersByEnumQuery({ name: 'role' })
    const { data: usersRes, isLoading: isUsersLoading, isFetching: isUsersFetching } = useGetUsersQuery({ isEvent, page, sortField, sortDirection })
    const [postUser] = usePostUserMutation()
    const [patchUser] = usePatchUserMutation()
    const [deleteUser] = useDeleteUserByIdMutation()

    const [usersList, setUsersList] = useState<UserAndRole[]>(usersRes?.data || [])

    useEffect(() => {
        setUsersList((prevLandings) => {
            const from = usersRes?.meta?.from
            if (from && prevLandings.length < from) {
                return [...(prevLandings || []), ...(usersRes?.data || [])]
            }
            return usersRes?.data || [] as CampaignDataItem[]
        })
    }, [usersRes])

    const onNextPage = useCallback(() => {
        setPage(page + 1)
    }, [setPage, page])

    const isLastPage = useMemo(() => {
        const lastPage = usersRes?.meta?.last_page || INIT_PAGE
        return page >= lastPage
    }, [usersRes?.meta?.last_page, page])

    const handleDelete = async () => {
        setIsLoading(true)
        try {
            const response = await deleteUser({ id: Number(formState?.id), isEvent })

            if ('error' in response) {
                const errorsFromBack = errorsToFormAdapter(response as ErrorDataResponse)
                setErrors(errorsFromBack as unknown as UserEditErrorsType)
            }

            if ('data' in response) {
                notification.open({
                    message: `The user ${formState?.name} has been successfully deleted`,
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });
                setIsConfirmModalOpened(false)
                setFormState(InitialUserEdit)
            }
        } catch (e) {
            console.error(e)
        }
        setIsLoading(false)
    }

    const onCloseConfirmModal = () => {
        setIsConfirmModalOpened(false)
        setFormState(InitialUserEdit)
    }

    const onOpenConfirmModal = (user: UserAndRole) => {
        setIsConfirmModalOpened(true)
        setFormState(user)
    }

    const columns = [
        {
            title: 'User name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            render: (name: UserAndRole['name'], row: UserAndRole) => {
                return (
                    <div className='flex items-center'>
                        {row?.avatar ? (
                            <Avatar size={28} src={row.avatar} />
                        ) : (
                            <Avatar size={28} icon={<UserOutlined />} />
                        )}
                        <span className='ml-6'>{name}</span>
                    </div>
                )
            },
            sorter: true
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            width: '30%',
            sorter: true
        },
        {
            title: 'Role',
            dataIndex: 'roles',
            key: 'roles',
            width: '30%',
            render: (items: UserAndRole['roles']) => {
                return items?.map((role) => <div className="ad-accounts-page__role">{role}</div>)
            }
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'dropdown',
            width: '8%',
            fixed: 'right',
            render: (_: number, row: UserAndRole) => {
                return (
                    <div className="dots-dropdown">
                        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                        <RowDropdown
                            onDelete={() => {
                                onOpenConfirmModal(row)
                            }}
                            onEdit={() => {
                                setFormState({
                                    // @ts-ignore
                                    password: '',
                                    new_password: '',
                                    ...row
                                })
                                setIsUserModalOpened(true)
                            }}
                        >
                            <div onClick={(e) => e.preventDefault()}>
                                <MenuDotsHor />
                            </div>
                        </RowDropdown>
                    </div>
                )
            }
        },
    ];

    const onCreateUser = useCallback(() => {
        setFormState(InitialUserEdit)
        setIsUserModalOpened(true)
    }, [])

    const onCloseUserModal = useCallback(() => {
        setIsUserModalOpened(false)
        setFormState(InitialUserEdit)
        setErrors(InitialUserEditErrors)
    }, [])

    const onChangeFieldValue = (fieldName: keyof UserEditType, value: string | boolean | string[]) => {
        setErrors(InitialUserEditErrors)
        setFormState(prevState => ({ ...prevState, [fieldName]: value }))
    }

    const onSubmitForm = async (isChangePassword: boolean) => {
        setIsLoading(true)
        const isNew = !formState.id
        const message = isNew ? `The user ${formState.name} has been successfully created. Credits are sent to the user's email credentials` :
            `Changes for the ${formState.name} user saved successfully`
        const { id, name, email, roles: userRoles, password, isGeneratePassword, new_password } = formState

        try {
            const newUser: PostUser & { isEvent?: boolean } = {
                name,
                email,
                roles: userRoles,
                isEvent
            }
            if (isChangePassword) {
                newUser.password_generate = Boolean(isGeneratePassword)
                if (!isGeneratePassword) {
                    newUser.password = new_password || password
                }
            }
            if (!isNew) {
                newUser.id = id
            }
            const response = isNew ? await postUser(newUser) : await patchUser(newUser)

            if ('error' in response) {
                const errorsFromBack = errorsToFormAdapter(response as ErrorDataResponse)
                setErrors(errorsFromBack as unknown as UserEditErrorsType)
            }

            if ('data' in response) {
                notification.open({
                    message,
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });
                onCloseUserModal()
            }
        } catch (e) {
            console.error(e)
        }
        setIsLoading(false)
    }

    const handleChangeTable: TableProps<UserBillingOrganizerItem>['onChange'] = useCallback((pagination, _, sorter) => {
        const { columnKey, order } = sorter as { columnKey: string, order: string }
        const newOrder = Orders[order]
        if (order) {
            setSortField(columnKey)
            setSortDirection(newOrder)
        } else {
            setSortField('')
            setSortDirection('')
        }
    }, []);

    return (
        <>
            <article className={`ad-accounts-page ${usersRes?.data?.length ? '' : 'ad-accounts-page__empty'}`}>
                <div className='ad-accounts-page__header'>
                    <div className='ad-accounts-page__header-title'>
                        <div>Users & Roles</div>
                    </div>
                    {!!usersRes?.data?.length && (
                        <Button
                            type='primary'
                            onClick={onCreateUser}
                            icon={<PlusOutlined />}
                            className="ad-accounts-page__create-user-btn"
                        >
                            Create user
                        </Button>
                    )}
                </div>
                <InfiniteScrollTable
                    bordered
                    isLastPage={isLastPage}
                    onNextPage={onNextPage}
                    isFirstPage={page === INIT_PAGE}
                    loading={isLoading || isUsersFetching || isUsersLoading}
                    dataSource={usersList || []}
                    columns={usersList?.length ? columns : []}
                    onChange={handleChangeTable}
                    rowKey="id"
                    scroll={{
                        x: true,
                    }}
                    locale={{
                        emptyText: isUsersLoading || isUsersFetching ? ' ' : (
                            <div className='ad-accounts-page__empty-credentials'>
                                <img src={emptyCredentialsPng} alt='emptyCredentialsPng' />
                                <span><b>You have no users</b></span>
                                <span>To create one of them, click on the button below</span>
                                <Button
                                    onClick={onCreateUser}
                                    type='primary'
                                    className="ad-accounts-page__create-user-btn"
                                    icon={<PlusOutlined />}
                                >
                                    Create user
                                </Button>
                            </div>
                        )
                    }}
                    className="bordered-table"
                />
            </article>
            <UserModal
                isLoading={isUsersLoading || isUsersFetching || isLoading}
                formState={formState}
                errors={errors}
                isOpen={isUserModalOpened}
                onClose={onCloseUserModal}
                onChangeFieldValue={onChangeFieldValue}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmitForm={onSubmitForm}
                roles={roles?.data || []}
            />
            <ConfirmModal
                isOpen={isConfirmModalOpened}
                onClose={onCloseConfirmModal}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onDelete={handleDelete}
                userName={formState?.name}
            />
        </>
    )
}

export default UsersAndRoles
