export const Twitter = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.75 0.75C1.64543 0.75 0.75 1.64543 0.75 2.75V17.25C0.75 18.3546 1.64543 19.25 2.75 19.25H17.4065C18.5111 19.25 19.4065 18.3546 19.4065 17.25V2.75C19.4065 1.64543 18.5111 0.75 17.4065 0.75H2.75Z"
            fill="#C0C1C3"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.46588 4.72702C4.56846 4.52641 4.77478 4.40018 5.00009 4.40018H7.77787C7.97052 4.40018 8.15145 4.49269 8.26426 4.64886L10.8806 8.2711L14.5758 4.57592C14.8101 4.3416 15.19 4.3416 15.4244 4.57592C15.6587 4.81023 15.6587 5.19013 15.4244 5.42444L11.5923 9.25648L15.4865 14.6489C15.6184 14.8316 15.6369 15.0727 15.5343 15.2733C15.4317 15.474 15.2254 15.6002 15.0001 15.6002H12.2223C12.0297 15.6002 11.8487 15.5077 11.7359 15.3515L9.11954 11.7293L5.42436 15.4244C5.19004 15.6588 4.81014 15.6588 4.57583 15.4244C4.34151 15.1901 4.34151 14.8102 4.57583 14.5759L8.40786 10.7439L4.51367 5.35145C4.38176 5.16879 4.3633 4.92763 4.46588 4.72702ZM12.5291 14.4002H13.8267L7.4711 5.60018H6.17348L12.5291 14.4002Z"
              fill="white"/>
    </svg>

)