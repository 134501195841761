import React from 'react'

const GoogleAdsIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#F2F3F5" />
            <path d="M11.3964 28.5869L20.1681 13.5708C21.2824 14.2267 26.9009 17.336 27.8084 17.9272L19.0366 32.9442C18.0772 34.2114 10.181 30.51 11.3964 28.586V28.5869Z" fill="#FBBC04" />
            <path d="M36.6015 28.5857L27.8298 13.5705C26.6035 11.5299 23.9623 10.7883 21.793 11.9936C19.6238 13.1988 18.9632 15.7952 20.1895 17.927L28.9613 32.944C30.1876 34.9837 32.8288 35.7253 34.9981 34.52C37.0726 33.3148 37.8278 30.6264 36.6015 28.5875V28.5857Z" fill="#4285F4" />
            <path d="M15.1962 35.1003C17.628 35.1003 19.5994 33.178 19.5994 30.8068C19.5994 28.4355 17.628 26.5132 15.1962 26.5132C12.7643 26.5132 10.793 28.4355 10.793 30.8068C10.793 33.178 12.7643 35.1003 15.1962 35.1003Z" fill="#34A853" />
        </svg>
    )
}

export default GoogleAdsIcon