import Loader from '@components/Loader'
import { useAuth } from '@contexts/AuthContext'
import { message, Tabs, TabsProps } from 'antd'
import { useState, useContext, useEffect, useRef } from 'react'
import AboutPro from '@pages/PartnerRolePages/BoostPro/components/AboutPro';
import MySubscription from '@pages/PartnerRolePages/BoostPro/components/MySubscription';
import EventsPageLayout from "@layouts/EventsPageLayout";
import EnableBoostProDrawer from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawer';
import SuccessModal from '@components/Subscriptions/BoostPro/SuccessModal';
import CancelModal from '@components/Subscriptions/BoostPro/modals/CancelModal';
import { ProSubscription } from '@store/models-to replace/auth';
import { StripeContext } from '@contexts/StripeContext';
import { usePostDefaultBillingMethodIdMutation } from '@store/main/payment/payment.api';
import { useDeleteSubscriptionMutation, usePostSubscriptionMutation } from '@store/main/subscription/subscription.api';
import { userActions } from "@store/main/-user/user.slice";
import { SubscriptionName } from "@store/main/subscription/models";
import { useDispatch } from "react-redux";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import './styles.scss'
import { useBreakpoints } from '@hooks/browser';
import MySubscriptionMobile from './mobile/components/MySubscriptionMobile';
import AboutProMobile from './mobile/components/AboutProMobile';
import EnableBoostProDrawerMobile from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawerMobile';
import TourModal from "@pages/PartnerRolePages/BoostPro/components/TourModal.tsx";
import { TourContext } from "@contexts/TourContext.tsx";
import { useNavigate, useLocation } from "react-router-dom";
import {TourStages} from "@components/TourComponent/constants.tsx";
import { ExitPageContext } from "@contexts/ExitPageContext.tsx";
import usePopState from "@hooks/usePopState.ts";

const BoostProPage = () => {
    usePopState()
    const { reFetchUser, isUserLoading, proSubscriptionData: { isProEnabled, proSubscription } } = useAuth()
    const [onSubscribe] = usePostSubscriptionMutation()
    const [postDefault] = usePostDefaultBillingMethodIdMutation()
    const dispatch = useDispatch()
    const isDesktop = useBreakpoints().md
    const proPageRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [onDeleteSubscription] = useDeleteSubscriptionMutation()

    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
    const [isEnableProDrawerOpen, setIsEnableProDrawerOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [successModalDate, setSuccessModalDate] = useState<string | boolean>('')
    const [isTourModalOpen, setIsTourModalOpen] = useState(false);

    const { selectedCardId } = useContext(StripeContext);

    const {
        eventId,
        tourStage,
        onFinishTour
    } = useContext(TourContext);
    const {
        isExitPageEnabled,
        setIsExitPageEnabled,
        isExitPageModalOpen,
        exitPageLink,
        onNavigate,
        onCancel
    } = useContext(ExitPageContext);

    const onTourModalOpen = () => {
        setIsTourModalOpen(true)
        onCancel()
    }

    const handleMouseLeave = (event: MouseEvent) => {
        if (event.clientY <= 120) {
            onTourModalOpen()
            if (proPageRef.current) {
                proPageRef.current.removeEventListener('mouseleave', handleMouseLeave);
            }
        }
    };

    useEffect(() => {
        scrollTo({
            top: 0
        })
    }, []);

    useEffect(() => {
        if (proPageRef.current && tourStage === TourStages.campaign) {
            proPageRef.current.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (proPageRef.current) {
                proPageRef.current.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [tourStage]);

    useEffect(() => {
        if (tourStage === TourStages.campaign && isExitPageEnabled) {
            window.history.pushState({}, '', `${pathname}`);
        }
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (tourStage === TourStages.campaign && isExitPageEnabled) {
                event.preventDefault();
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [pathname, isExitPageEnabled, tourStage]);

    const onCloseTourModal = (isFinishTour?: boolean) => {
        setIsTourModalOpen(false);
        if (isFinishTour) {
            onFinishTour()
        }
        if (proPageRef.current) {
            proPageRef.current.removeEventListener('mouseleave', handleMouseLeave);
        }
    }

    const onCancelModalOpen = () => {
        setIsCancelModalOpen(true)
    }

    const onCancelModalClose = () => {
        setIsCancelModalOpen(false)
    }

    const onCancelSubscription = async () => {
        setIsLoading(true)
        try {
            const response = await onDeleteSubscription()
            if ('error' in response) {
                return void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Subscription error',
                });
            }
            if (response) {
                sendAnalytics(GTMEventName.cancelProSubscription)
                dispatch(userActions.cancelSubscription(SubscriptionName.boost_pro))
                setIsCancelModalOpen(false)
            }
        } catch (error) {
            return void message.open({
                type: 'error',
                content: 'Subscription error',
            });
        }
        return setIsLoading(false)
    }

    const onOpenEnableProDrawer = () => {
        setIsEnableProDrawerOpen(true)
    }

    const onCloseEnableProDrawer = () => {
        setIsEnableProDrawerOpen(false)
    }

    const onSuccessModalClose = () => {
        setSuccessModalDate('')
        setIsEnableProDrawerOpen(false)
        void reFetchUser()
    }

    const onEnablePro = async () => {
        setIsLoading(true)
        await postDefault({ id: selectedCardId })

        try {
            const response = await onSubscribe()
            if ('error' in response) {
                return void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Subscription error',
                });
            }
            if (response) {
                sendAnalytics(GTMEventName.enableProSubscription)
                setSuccessModalDate(response?.data?.data?.date || true)
            }
        } catch (error) {
            return void message.open({
                type: 'error',
                content: 'Subscription error',
            });
        }
        return setIsLoading(false)
    }

    const onCreateCampaign = () => {
        onCloseTourModal()
        navigate(eventId ? `/events/create/${eventId}` : '/events')
    }

    const onTourEnablePro = async () => {
        onCloseTourModal(true)
        await onEnablePro()
    }

    useEffect(() => {
        if (tourStage === TourStages.campaign) {
            if (!isExitPageEnabled) {
                setIsExitPageEnabled(true)
            }
            window.addEventListener('popstate', function() {
                onTourModalOpen()
            });
        }
        return () => {
            setIsExitPageEnabled(false)
        }
    }, [tourStage]);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'My subscription',
            children: isDesktop
                ?
                <MySubscription onCancel={onCancelModalOpen} proSubscription={proSubscription} />
                :
                <MySubscriptionMobile onCancel={onCancelModalOpen} proSubscription={proSubscription} />
            ,
        },
        {
            key: '2',
            label: 'About PRO',
            children: isDesktop
                ?
                <AboutPro isProEnabled={isProEnabled} onEnablePro={onOpenEnableProDrawer} />
                :
                <AboutProMobile isProEnabled={isProEnabled} onEnablePro={onOpenEnableProDrawer} />
            ,
        }
    ];

    return (
        <EventsPageLayout className='pro-page'>
            {isUserLoading && <Loader />}
            <div ref={proPageRef}>
                {isProEnabled ? (
                    <Tabs defaultActiveKey="1" items={items} />
                ) : (
                    isDesktop
                        ?
                        <AboutPro isProEnabled={isProEnabled} onEnablePro={onOpenEnableProDrawer} />
                        :
                        <AboutProMobile isProEnabled={isProEnabled} onEnablePro={onOpenEnableProDrawer} />
                )}
            </div>
            <CancelModal
                isOpen={isCancelModalOpen}
                onClose={onCancelModalClose}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onCancelSubscription={onCancelSubscription}
                isLoading={isLoading}
                date={proSubscription?.formattedDate || ''}
            />
            {isEnableProDrawerOpen && isDesktop
                ? (
                    <EnableBoostProDrawer
                        isOpen={isEnableProDrawerOpen}
                        onClose={onCloseEnableProDrawer}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onConfirm={onEnablePro}
                        isLoading={isLoading}
                    />
                )
                :
                null}
            {isEnableProDrawerOpen && !isDesktop
                ? (
                    <EnableBoostProDrawerMobile
                        isOpen={isEnableProDrawerOpen}
                        onClose={onCloseEnableProDrawer}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onConfirm={onEnablePro}
                        isLoading={isLoading}
                    />
                )
                :
                null}

            <SuccessModal
                isOpen={Boolean(successModalDate)}
                onClose={onSuccessModalClose}
                date={successModalDate}
            />
            {isDesktop ? (
                <TourModal
                    isOpen={isTourModalOpen || isExitPageModalOpen}
                    onClose={() => {
                        onCloseTourModal(true)
                        if (exitPageLink) {
                            navigate(exitPageLink)
                        }
                        onNavigate()
                        onCloseTourModal()
                    }}
                    onOpen={onTourModalOpen}
                    onEnablePro={() => {
                        onCancel()
                        onTourEnablePro()
                    }}
                    onCreateCampaign={() => {
                        onCancel()
                        onCreateCampaign()
                    }}
                />
            ) : (
                <TourModal
                    isOpen={isExitPageModalOpen}
                    onClose={() => {
                        if (exitPageLink) {
                            navigate(exitPageLink)
                        }
                        onNavigate()
                        onCloseTourModal()
                    }}
                    onOpen={onTourModalOpen}
                    onEnablePro={() => {
                        onCancel()
                        onTourEnablePro()
                    }}
                    onCreateCampaign={() => {
                        onCancel()
                        onCreateCampaign()
                    }}
                />
            )}
        </EventsPageLayout>
    )
}

export default BoostProPage
