import { Button } from 'antd'
import AdChannelAccountCard from './components/AdChannelAccountCard'
import InfoBanner from './components/InfoBanner'
import { OrganizerCredential } from '@store/type-event/credentials/models'
import { useEffect, useState } from 'react'
import { useGetCredentialsChannelsQuery } from '@store/type-partner/credentials/credentials.api'
import AddAdsAccountModal from './AddAdsAccountModal'
import { PartnerCredential } from '@store/type-partner/credentials/models'

interface Props {
    accountData: OrganizerCredential[]
    credentialsList: OrganizerCredential[]
}
const ChannelsList = ({
    accountData,
    credentialsList
}: Props) => {
    const { data: channelsRes } = useGetCredentialsChannelsQuery()

    const [channelsToAddList, setChannelsAddList] = useState<PartnerCredential[]>([])
    const [isOpenAddModal, setOpenAddModal] = useState(false)
    const [cardList, setCardList] = useState<Record<string, OrganizerCredential[]>[]>([])

    const activeChannels = accountData.map(channel => channel.channel.name)

    const optionsHandler = () => {
        const result = credentialsList.reduce((acc, item) => {
            const channelName = item.channel.name;
            if (!acc[channelName]) {
                acc[channelName] = [];
            }
            acc[channelName].push(item);
            return acc;
        }, {} as Record<string, OrganizerCredential[]>);

        const formattedResult = Object.entries(result).map(([key, value]) => ({ [key]: value }));
        setCardList(formattedResult)
    };

    useEffect(() => {
        if (credentialsList.length > 0) {
            optionsHandler()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentialsList])

    const selectedId = (name: string): string | null =>
        accountData.find(item => item.channel.name === name)?.id.toString() || null;

    useEffect(() => {
        if (channelsRes?.data) {
            const filteredChannels = channelsRes.data.filter(item => !activeChannels.includes(item.slug))
            setChannelsAddList(filteredChannels)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelsRes?.data])

    return (
        <div>
            <InfoBanner />
            {cardList.map((card, idx) => {
                const channelData = Object.values(card)[0][0];
                return (
                    <AdChannelAccountCard
                        key={idx}
                        id={idx.toString()}
                        name={channelData.channel.translate}
                        slug={channelData.channel.name}
                        options={Object.values(card)[0].map(cred => ({
                            label: cred.name,
                            value: cred.id
                        }))}
                        selectedId={selectedId(channelData.channel.name)}
                    />
                )
            })}

            {channelsToAddList.length
                ?
                <Button
                    type='text'
                    size="large"
                    style={{ border: '1px solid #D9DADC', margin: '16px 0' }}

                    onClick={() => setOpenAddModal(true)}
                >
                    + Add account
                </Button>
                :
                null
            }

            {isOpenAddModal
                ?
                <AddAdsAccountModal
                    onClose={() => setOpenAddModal(false)}
                    channels={channelsToAddList}
                />
                :
                null
            }

        </div>
    )
}

export default ChannelsList

