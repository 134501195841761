import { campaigns_create_new, events, events_edit } from "@pages/routes";
import { GetEventByIdFromCatalogResponse } from "@store/type-partner/events-catalog/models";
import { Option } from "@contexts/LandingEditorContext.tsx";
import { OptionIds } from "@pages/PartnerRolePages/LandingPageConstructor/constants.tsx";
import { ButtonType } from "@pages/PartnerRolePages/LandingPageConstructor/models.ts";

export const getCrumbs = ({ pathname, eventData, campaignId }: { pathname: string, eventData: GetEventByIdFromCatalogResponse['data'] | undefined, campaignId: string }) => {
    const id = eventData?.id || ''
    const firstItem = pathname.includes(events) ? (
        {
            url: id ? `${events_edit}/${id}` : events,
            label: eventData?.name || 'My events',
            idx: 1
        }
    ) : (
        {
            label: 'Campaign Editor',
            url: pathname?.includes(campaigns_create_new) ? `${campaigns_create_new}/${id}` : pathname.split('/landing')[0],
            idx: 1,
        }
    )
    const middleItem = pathname.includes(events) && campaignId ? [{
        label: 'Campaign Editor',
        url: pathname.split('/landing')[0],
        idx: 3,
    }] : []
    return ([
        firstItem,
        ...middleItem,
        {
            label: 'Add Landing Page',
            url: pathname,
            idx: 2
        }
    ])
}

export const getLandingConstructorCrumbs = (pathname: string, isLg?: boolean) => {
    return ([
        {
            label: 'Landing Page Editor',
            url: pathname,
            isPrev: true,
            idx: 1,
            isZeroLevel: true,
        },
        {
            label: isLg ? 'Landing Page Editor' : 'LP Editor',
            url: pathname,
            idx: 2,
            className: 'landing-editor-page__breadcrumb-title'
        }
    ])
}

export const changeVideoUrl = (url?: string) => (url && url?.includes('youtube') ? url.replace('watch?v=', 'embed/') : url)

export const convertPercentToDecimal = (rgbString: string): string => {
    return rgbString.replace(/(\d+)%/g, (_, percent) => {
        const decimal = parseInt(percent, 10) / 100;
        return decimal.toString();
    });
};

export const setCursorToEnd = (element: HTMLElement) => {
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(element);
    range.collapse(false);

    selection?.removeAllRanges();
    selection?.addRange(range);
};

const socialSuffixes = ['facebook', 'instagram', 'linkedin', 'telegram', 'twitter', 'viber', 'whatsapp'];

export const setNewEditorStyles = ({ clickedSectionId, option }: { clickedSectionId: string | null | undefined, option: Option }) => {
    if (!clickedSectionId) {
        return
    }
    const editor = document.querySelector(`[data-editor-id="${clickedSectionId}"]`)
    if (option.name === OptionIds.CardsComponent) {
        const imageElements = editor?.querySelectorAll(`img[class*="${option.name}-"]`) || [];
        imageElements.forEach((imageElement, index) => {
            // @ts-ignore
            const imageElementValue = option.value?.card?.[index];
            (imageElement as HTMLImageElement).src = imageElementValue?.image || '';
            (imageElement as HTMLImageElement).style.borderRadius = imageElementValue?.cornerRadius;
        })

        // @ts-ignore
        option.value?.card?.forEach((card, index) => {
            // @ts-ignore
            const cardElementValue = option.value?.card?.[index]?.socialLinks
            let socialLinks: NodeListOf<Element> | [] | Element[] = [];
            socialSuffixes.forEach((suffix) => {
                const foundLinks = editor?.querySelectorAll(`[class*="${option.name}-${index}"][class*="-${suffix}"]`) || [];
                socialLinks = [...socialLinks, ...Array.from(foundLinks)];
            });
            socialLinks.forEach((link) => {
                const className = link.className;
                const socialType = Object.keys(cardElementValue).find((type) => className.includes(`-${type}`));
                if (socialType && cardElementValue[socialType] && cardElementValue[socialType] !== 'null') {
                    (link as HTMLElement).classList.add('social-link-visible-class');
                } else {
                    (link as HTMLElement).classList.add('social-link-hidden-class');
                }
            });
        })
        return
    }

    let optionName = option.name
    if (optionName === OptionIds.ButtonsComponent) {
        optionName = OptionIds.ButtonComponent
    }
    const elements = editor?.querySelectorAll(`[class*="${optionName}-"]:not([class$="-wrapper"]):not([class$="-overlay"])`) || [];
    elements.forEach((element, index) => {
        let newValue = typeof option.value === 'string' ? option.value : '';
        if (Array.isArray(option.value)) {
            const arrayValue = option.value[index]
            if (typeof arrayValue === 'string') {
                newValue = arrayValue
            } else {
                newValue = (arrayValue as Record<string, string>)?.[option.key || '']
            }
        }

        if (option.name === OptionIds.ButtonsComponent) {
            const buttonValue = (option.value as { button: ButtonType[] })?.button?.[index] as any as ButtonType
            newValue = buttonValue?.[(option.key || '') as keyof ButtonType] as string
        }

        switch (option.key) {
            case 'imageOpacity':
            case 'opacity': {
                const nextElementSibling = element.nextElementSibling as HTMLElement
                if (nextElementSibling) {
                    nextElementSibling.style.opacity = newValue;
                }
                (element as HTMLElement).style.opacity = newValue;
                break
            }
            case 'backgroundColor': {
                (element as HTMLElement).style.backgroundColor = newValue;
                if (option.name === OptionIds.BackgroundComponent) {
                    (element as HTMLElement).style.backgroundImage = 'none';
                }
                break
            }
            case 'backgroundImage': {
                (element as HTMLElement).style.backgroundImage = `url('${newValue}')`;
                if (option.name === OptionIds.BackgroundComponent) {
                    (element as HTMLElement).style.backgroundColor = '';
                }
                break
            }
            case 'textColor': {
                (element as HTMLElement).style.color = newValue;
                break
            }
            case 'strokeColor': {
                (element as HTMLElement).style.border = `1px solid ${newValue}`;
                break
            }
            // todo ImageComponent
            case 'image': {
                (element as HTMLImageElement).src = newValue || '';
                break
            }
            case 'video': {
                const fixedVideoUrl = changeVideoUrl(newValue)
                const iframe = element?.querySelector('iframe');
                if (iframe) {
                    iframe.src = fixedVideoUrl || '';
                }
                break
            }
            case 'cornerRadius': {
                (element as HTMLImageElement).style.borderRadius = newValue;
                break
            }
            case 'imageOverlay':
                (element as HTMLElement).parentElement?.style.setProperty('--custom-image-overlay-color', newValue);
                (element as HTMLElement).parentElement?.classList.add('editor-image-overlay-wrapper-class');
                (element as HTMLElement).nextElementSibling?.classList.add('editor-image-overlay-class');
                break
            case 'overlay': {
                (element as HTMLElement).parentElement?.style.setProperty('--custom-image-overlay-color', newValue);
                (element as HTMLElement).parentElement?.classList.add('editor-image-overlay-wrapper-class');
                (element as HTMLElement).nextElementSibling?.classList.add('editor-image-overlay-class');
                const overlay = document.createElement('div');
                overlay.className = 'editor-image-overlay-class';
                element.parentElement?.appendChild(overlay);
                (element as HTMLElement).style.setProperty('--custom-overlay-color', newValue);
                (element as HTMLElement).classList.add('editor-overlay-class');
                break
            }
            case 'backgroundOpacity': {
                (element as HTMLElement).style.setProperty('--custom-background-opacity', newValue);
                (element as HTMLElement).classList.add('editor-background-opacity-class');
                break
            }
            case 'videoCover': {
                (element as HTMLElement).style.setProperty('--custom-video-cover', `url('${newValue}')`);
                (element as HTMLElement).classList.add('editor-video-cover-class');
                break
            }
            case 'videoOverlay': {
                (element as HTMLElement).style.setProperty('--custom-video-overlay', newValue);
                const childElement = element.querySelector('[class$="-iframe-overlay"]');
                if (childElement) {
                    (childElement as HTMLElement).classList.add('editor-video-overlay-class');
                }

                break
            }
            case 'videoOpacity': {
                (element as HTMLElement).style.setProperty('--custom-video-opacity', newValue);
                (element as HTMLElement).classList.add('editor-video-opacity-class');
                break
            }
            case 'textColorOnHover': {
                (element as HTMLElement).style.setProperty('--custom-text-color-on-hover', newValue);
                (element as HTMLElement).classList.add('editor-text-color-on-hover-class');
                break
            }
            case 'backgroundColorOnHover': {
                (element as HTMLElement).style.setProperty('--custom-background-color-on-hover', newValue);
                (element as HTMLElement).classList.add('editor-background-color-on-hover-class');
                break
            }
            case 'strokeColorOnHover': {
                (element as HTMLElement).style.setProperty('--custom-stroke-color-on-hover', newValue);
                (element as HTMLElement).classList.add('editor-stroke-color-on-hover-class');
                break
            }
            case 'iconBackgroundColor': {
                (element as HTMLElement).style.setProperty('--custom-icon-background-color', newValue);
                (element as HTMLElement).classList.add('editor-icon-background-color-class');
                break
            }
            case 'iconBackgroundColorOnHover': {
                (element as HTMLElement).style.setProperty('--custom-icon-background-color-on-hover', newValue);
                (element as HTMLElement).classList.add('editor-icon-background-color-on-hover-class');
                break
            }
            case 'iconColor': {
                (element as HTMLElement).style.setProperty('--custom-icon-color', newValue);
                (element as HTMLElement).classList.add('editor-icon-color-class');
                break
            }
            case 'iconColorOnHover': {
                (element as HTMLElement).style.setProperty('--custom-icon-color-on-hover', newValue);
                (element as HTMLElement).classList.add('editor-icon-color-on-hover-class');
                break
            }
            case 'value': {
                if (option.name === 'BackgroundImageComponent') {
                    (element as HTMLElement).style.backgroundImage = `url('${newValue}')`;
                }
                if (option.name === 'IconsComponent') {
                    (element as HTMLImageElement).src = newValue;
                }
                break
            }
        }
    });
}

export function replaceMediaQueries(html: string): string {
    const replacements: { [key: string]: string } = {
        '1919px': '2057px',
        '1439px': '1577px',
        '1023px': '1161px',
        '639px': '777px',
    };

    Object.keys(replacements).forEach(oldQuery => {
        const newQuery = replacements[oldQuery];
        html = html.replace(new RegExp(oldQuery, 'g'), newQuery);
    });
    return html;
}

export function replaceMediaQueriesBack(html: string): string {
    const replacements: { [key: string]: string } = {
        '2057px': '1919px',
        '1577px': '1439px',
        '1161px': '1023px',
        '777px': '639px',
    };

    Object.keys(replacements).forEach(oldQuery => {
        const newQuery = replacements[oldQuery];
        html = html.replace(new RegExp(oldQuery, 'g'), newQuery);
    });
    return html;
}
