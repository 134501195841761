import LaptopIcon from "@assets/event-setup/LaptopIcon.tsx";
import TabletIcon from "@assets/event-setup/TabletIcon.tsx";
import MobileIcon from "@assets/event-setup/MobileIcon.tsx";
import React from "react";

export const segmentedOptions = [
    {
        label: (
            <div className="flex-center">
                <LaptopIcon />
            </div>
        ),
        value: 'laptop',
    },
    {
        label: (
            <div className="flex-center">
                <TabletIcon />
            </div>
        ),
        value: 'tablet',
    },
    {
        label: (
            <div className="flex-center">
                <MobileIcon />
            </div>
        ),
        value: 'mobile',
    },
]

export const SegmentedValues = {
    laptop: 'laptop',
    tablet: 'tablet',
    mobile: 'mobile',
}

export enum SetupLandingStatuses {
    generated = 'generated',
    enabled = 'enabled',
    rejected = 'rejected',
    none = 'none',
    created = 'created',
    waiting_for_approval = 'waiting_for_approval'
}