// import './styles.scss'
import EventCampaignsTable from './components/EventCampaignsTable'
import CustomButton from '@components/CustomButton'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'antd';
import ZeroCampaigns from '../../ZeroState/ZeroCampaigns';
import { useAuth } from '@contexts/AuthContext';
import { CampaignDataItem } from '@store/type-partner/campaigns/models';
import { partnerCampaignsActions } from '@store/type-partner/campaigns/campaigns.slice';
import { useDispatch } from 'react-redux';
import { RefObject } from "react";

interface EventCampaignsTableSectionProps {
    campaigns: CampaignDataItem[];
    setCampaignsList: React.Dispatch<React.SetStateAction<CampaignDataItem[]>>
    eventId: string;
    isActive: boolean
    myCampaignsRef: RefObject<HTMLAnchorElement>;
    isLastPage: boolean
    onNextPage: () => void
    page: number
    isLoading: boolean
}
const EventCampaignsTableSection = ({ campaigns, setCampaignsList, eventId, isActive, myCampaignsRef, isLastPage, page, isLoading, onNextPage }: EventCampaignsTableSectionProps) => {
    const { user } = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isPartner = user?.organization.type === 'partner'

    if (!campaigns || (campaigns && !campaigns.length)) {
        return (
            <article className='event-info mb-24' ref={myCampaignsRef}>
                <header className='event-info__header flex items-center justify-space-between'>
                    <h3>
                        My campaigns
                    </h3>
                </header>
                <ZeroCampaigns
                    eventId={eventId}
                    isActive={isActive}
                />
            </article>
        )
    }
    return (
        <article className='event-info mb-24' ref={myCampaignsRef}>
            <header className='event-info__header flex items-center justify-space-between'>
                <h3>
                    My campaigns
                </h3>
                {eventId && isActive && isPartner
                    ?
                    <CustomButton
                        type='primary'
                        title='+ Create Campaign'
                        onClick={() => {
                            dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: null, selectedEvent: null, campaignId: '' }))
                            navigate(`/events/create/${eventId}`)
                        }}
                    />
                    :
                    <Tooltip
                        title={'only for status "Active"'}
                    >
                        <CustomButton
                            disabled
                            title='+ Create Campaign'
                        />
                    </Tooltip>
                }
            </header>

            <EventCampaignsTable
                setCampaignsList={setCampaignsList}
                campaigns={campaigns}
                isLastPage={isLastPage}
                onNextPage={onNextPage}
                page={page}
                isLoading={isLoading}
            />
        </article>)
}

export default EventCampaignsTableSection