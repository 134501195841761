import React from 'react';
import './styles.scss'
import useTooltip from "@hooks/useTooltip.ts";

interface TooltipComponentProps {
    tooltipText: React.ReactNode;
    children: React.ReactNode;
    isActive?: boolean
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({ children, tooltipText, isActive = true }) => {
    const {
        tooltipRef,
        isVisible,
        tooltipPosition,
        showTooltip,
        hideTooltip,
        updateTooltipPosition,
    } = useTooltip();

    if (!isActive) {
        return children
    }

    return (
        <div
            className="custom-tooltip-component-target"
            onMouseOver={showTooltip}
            onMouseOut={hideTooltip}
            onMouseMove={updateTooltipPosition}
        >
            {children}
            {isVisible && (
                <div
                    className="custom-tooltip-component"
                    ref={tooltipRef}
                    style={{
                        left: tooltipPosition.left,
                        right: tooltipPosition.right,
                        top: tooltipPosition.top,
                        visibility: isVisible ? 'visible' : 'hidden',
                    }}
                >
                    <div className="custom-tooltip-triangle" />
                    {tooltipText}
                </div>
            )}
        </div>
    );
};

export default TooltipComponent;
