import { CampaignEditorContext } from '@contexts/CampaignEditorContext';
import { Checkbox, InputNumber, Tooltip } from 'antd';
import { useContext, useEffect } from 'react';
import InfoIcon from "@icons/header/InfoIcon";
import GoogleIcon from "@assets/campaign-settings/GoogleIcon.tsx";
import LinkedinIcon from "@assets/campaign-settings/LinkedinIcon.tsx";
import { Channel } from '@store/main/organization/models';

interface ChannelBase { id: string, slug: string, translate: string }
interface ChannelCardProps {
    channel: ChannelBase;
    onChange: (channel: Channel) => void;
}

const cardIconHandler = (slug: string, disabled: boolean) => {
    switch (slug) {
        case 'google_ads':
            return <GoogleIcon />
        case 'linkedin_ads':
            return <LinkedinIcon />
        default:
            break;
    }
}

const ChannelCard = ({
    channel,
    onChange,
}: ChannelCardProps) => {
    const {
        formErrors,
        formValues
    } = useContext(CampaignEditorContext);
    const channels = formValues?.channels || []
    const values = channels.find(item => item.title === channel.slug)
    // const { enums } = useAppSelector(state => state.filterEnums)
    return (
        <div
            className={`radio-channel__item ${values?.status === 1 ? 'selected' : ''}`}
        >
            <div
                className="flex justify-space-between cursor-pointer"
                onClick={() => onChange({
                    ...values,
                    slug: channel.slug,
                    id: values?.id || null,
                    type: values?.type || +channel.id,
                    budget: values?.budget || 0,
                    //0: stop, 1: enabled, 2: paused
                    bid: values?.bid || 0,
                    status: values?.status === 1 ? 2 : 1,

                })}
            >
                <div className='flex gap-8'>
                    {cardIconHandler(channel.slug, values?.status === 2)}
                    <div>
                        {channel.translate}
                    </div>
                </div>
                <Checkbox
                    checked={values?.status === 1}
                />
            </div>

            <div className='radio-channel__item-budget mt-14'>
                <div className='flex'>
                    <span className="radio-channel__item-budget__label">
                        {formValues?.goal === 2
                            ?
                            'Target cost per lead'
                            :
                            'Maximum CPC, €'
                        }
                    </span>
                    <Tooltip
                        title={`The highest amount that you're willing to pay for a click on your ad`}
                        overlayClassName='tooltip-general tooltip-small'
                        placement='top'
                    >
                        <div>
                            <InfoIcon fillColor='#57585A' width={14} height={14} />
                        </div>
                    </Tooltip>
                </div>
                <InputNumber
                    status={formErrors['channels.0.bid'] ? 'error' : ''}
                    prefix="€"
                    style={{ width: '100%' }}
                    value={values?.bid || 0}
                    parser={(value) => value?.replace(',', '.') as unknown as number}
                    step={'0.05'}
                    max={100}
                    controls
                    onChange={value => onChange({
                        ...values,
                        slug: channel.slug,
                        id: values?.id || null,
                        type: values?.type || +channel.id,
                        budget: values?.budget || 0,
                        //0: stop, 1: enabled, 2: paused
                        status: values?.status || 2,
                        bid: value as number
                    })}
                    disabled={values?.status === 2}
                />

                {formValues?.goal !== 2
                    ?
                    <>
                        <div className='flex mt-12'>
                            <span className="radio-channel__item-budget__label">Daily spending limit, €</span>
                            <Tooltip
                                title={`The daily spending limit is the maximum amount you can be billed for a campaign per day`}
                                overlayClassName='tooltip-general tooltip-small'
                                placement='right'
                            >
                                <div>
                                    <InfoIcon fillColor='#57585A' width={14} height={14} />
                                </div>
                            </Tooltip>
                        </div>
                        <InputNumber
                            status={formErrors['channels.0.budget'] ? 'error' : ''}
                            prefix="€"
                            style={{ width: '100%' }}
                            value={values?.budget || 0}
                            step={10}
                            parser={(value) => value?.replace(',', '.') as unknown as number}
                            controls
                            onChange={value => onChange({
                                ...values,
                                slug: channel.slug,
                                id: values?.id || null,
                                type: values?.type || +channel.id,
                                //0: stop, 1: enabled, 2: paused
                                status: values?.status || 2,
                                bid: values?.bid || 0,
                                budget: value as number,
                            })}
                            disabled={values?.status === 2}
                        />
                    </>
                    :
                    null
                }
                <div className='mt-12' />
            </div>
        </div>)
}

export default ChannelCard