import React, { useMemo, useEffect, useContext, useState, useCallback } from 'react';
import './styles.scss'
import EventsPageLayout from '@layouts/EventsPageLayout';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb';
import { useGetEventByIdFromCatalogQuery } from '@store/type-partner/events-catalog/partner-event-catalog.api';
import NonEditableSection
    from "@pages/PartnerRolePages/LandingPageConstructor/components/NonEditableSection /NonEditableSection";
import EmptySectionsBlock
    from "@pages/PartnerRolePages/LandingPageConstructor/components/EmptySectionsBlock/EmptySectionsBlock";
import { getLandingConstructorCrumbs } from "@pages/PartnerRolePages/LandingPageConstructor/helpers";
import { JoditOptionName } from './components/LandingPageWYSIWYGconstructor/assets/models';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import SectionSettingsDrawer from './components/drawers/settings/SectionSettingsDrawer';
import AddSectionModal from './components/modals/AddSectionModal';
import { OrganizerLanding } from "@store/type-event/models";
import StatusDescription from "@components/StatusDescription";
import { ModerationStatuses, ModerationStatusesFormatted } from "@shared/constants";
import InfoIcon from "@icons/header/InfoIcon";
import { Button, Input, Modal, Space, Spin, Typography } from "antd";
import RejectionReasonModal
    from "@pages/PartnerRolePages/MyEvents/pages/MyEventEditPage/components/EventLandingPages/components/RejectionReasonModal";
import LoaderAi from './components/loaders/LoaderAi';
import { LandingAiGenerationSteps } from './models';
import { useLazyGetUserQuery } from '@store/main/-user/user.api';
import { useGetPartnerEventByIdQuery } from "@store/type-partner/events/partner-event.api";
import {
    useGetPartnerLandingByIdQuery
} from '@store/type-partner/landings/landings.api';
import usePageAnalytics from '@hooks/sendAnalytics/usePageAnalytics';
import PublishedModal from './components/modals/PublishedModal';
import SectionComponent
    from "@pages/PartnerRolePages/LandingPageConstructor/components/SectionComponent/SectionComponent.tsx";
import { defaultActions, HERO } from "@pages/PartnerRolePages/LandingPageConstructor/constants.tsx";
import { generateUniqueStringId } from "@shared/utils.ts";
import { PartnerLandingSectionLayout } from "@store/type-partner/landings/models.ts";
import {
    useGetPartnerLandingContentByIdQuery, useGetPartnerLandingTemplateByIdQuery
} from '@store/type-partner/landings/landings.api';
import { useBreakpoints } from "@hooks/browser";
import SaveIcon from "@assets/lpEditor/SaveIcon.tsx";
import EyeIcon from "@assets/lpEditor/EyeIcon.tsx";
import { AiId } from "@pages/PartnerRolePages/LandingCreationMethod/constants.tsx";
import { useAppSelector } from '@hooks/redux';
import { useDispatch } from 'react-redux';
import { partnerCampaignsActions } from '@store/type-partner/campaigns/campaigns.slice';
const { Paragraph } = Typography;

const LandingPageConstructor = () => {
    usePageAnalytics('lp_editor');
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        landingFetchedId,
        clickedSectionId,
        setClickedSectionId,
        editorSections,
        setEditorSections,
        layoutSettingsData,
        onDeleteSectionFromList,
        setLandingErrors,
        landingErrors,
        landingTitle,
        setLandingTitle,
        landingUrl,
        setLandingUrl,
        updateLandingHandler,
        createLandingHandler,
        isLoading,
        // isLandingLoading,
        isPublishedModalOpen,
        onClosePublishedModal,
        onPublishClick,
        isPublishLoading,
        isAiLoading,
        setAiLoading,
        onPreviewClick,
        isLandingSavedModalOpen,
        setLandingSavedModalOpen,
    } = useContext(LandingEditorContext);
    const { eventId = '', landingId = '', type = '' } = useParams()

    const isLg = useBreakpoints().lg

    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false)
    const [loadedHeader, setLoadedHeader] = useState('')
    const [loadedFooter, setLoadedFooter] = useState('')
    const [loadedStyles, setLoadedStyles] = useState('')
    const [isLandingDataLoading, setIsLandingDataLoading] = useState(Boolean(landingId))
    const [isLandingSectionsLoading, setIsLandingSectionsLoading] = useState(false)

    const { data: eventData } = useGetEventByIdFromCatalogQuery(
        { id: eventId?.toString() || '' },
        { skip: !eventId }
    );
    const { data: eventsRes, isLoading: isEventsResLoading } = useGetPartnerEventByIdQuery({ id: eventId || '' }, { skip: !eventId })
    const { data: fetchedLandingData, isLoading: isLandingLoading, isFetching: isLandingsFetching, refetch: refetchLandingData } = useGetPartnerLandingByIdQuery({ landingId, eventId }, { skip: !landingId })

    const { data: landingContent, isLoading: isLandingContentLoading } = useGetPartnerLandingContentByIdQuery({ id: type }, { skip: !type || !!landingId })
    const { data: landingTemplate } = useGetPartnerLandingTemplateByIdQuery({ eventId }, { skip: !eventId })
    const [dumbGetUser] = useLazyGetUserQuery()

    const { pathname } = useLocation()
    const temporaryEditorData = useAppSelector(state => state.partnerCampaigns.temporaryEditorData)

    const breadCrumbs = useMemo(() => getLandingConstructorCrumbs(pathname, isLg), [pathname, eventData, isLg])

    useEffect(() => {
        if (type && !landingId) {
            setIsLandingSectionsLoading(true)
        }
    }, [type, landingId]);

    useEffect(() => {
        const template = landingTemplate?.data?.template
        if (template) {
            const { footer, header, styles } = template
            setLoadedHeader(header)
            setLoadedFooter(footer)
            setLoadedStyles(styles)
        }
    }, [landingTemplate]);

    // WYSIWYG editor options
    const onOptionClick = useCallback(({ option, id, selectedLayout }: { option: JoditOptionName, id: string, selectedLayout?: PartnerLandingSectionLayout }) => {
        switch (option) {
            case JoditOptionName.Delete:
                onDeleteSectionFromList(id)
                break;
            case JoditOptionName.Clone:
                // eslint-disable-next-line no-case-declarations
                const copyItem = editorSections.find(item => item.id === id);
                if (copyItem) {
                    const copyIndex = editorSections.findIndex(item => item.id === id);
                    const newList = [...editorSections.slice(0, copyIndex), { ...copyItem, id: generateUniqueStringId() }, ...editorSections.slice(copyIndex)]
                    setEditorSections(newList)
                }
                break;
            case JoditOptionName.Select: {
                if (selectedLayout) {
                    const selectedIndex = editorSections.findIndex(item => item.id === id);
                    const newList = editorSections.map((section, index) => {
                        return index === selectedIndex ? { layout: selectedLayout, id: generateUniqueStringId() } : section
                    })
                    setEditorSections(newList)
                }
                break;
            }
            case JoditOptionName.Up:
                // eslint-disable-next-line no-case-declarations
                const indexUp = editorSections.findIndex(item => item.id === id);
                if (indexUp > 0) {
                    const newList = [...editorSections];
                    const temp = newList[indexUp - 1];
                    newList[indexUp - 1] = newList[indexUp];
                    newList[indexUp] = temp;
                    setEditorSections(newList);
                }
                break;
            case JoditOptionName.Down:
                // eslint-disable-next-line no-case-declarations
                const indexDown = editorSections.findIndex(item => item.id === id);
                if (indexDown < editorSections.length - 1) {
                    const newList = [...editorSections];
                    const temp = newList[indexDown + 1];
                    newList[indexDown + 1] = newList[indexDown];
                    newList[indexDown] = temp;
                    setEditorSections(newList);
                }
                break;
            case JoditOptionName.Hide: {
                const newList = editorSections.map((item) => {
                    return item.id === id ? { ...item, hidden: true } : item
                })
                setEditorSections(newList);
                break;
            }
            case JoditOptionName.Show: {
                const newList = editorSections.map((item) => {
                    return item.id === id ? { ...item, hidden: false } : item
                })
                setEditorSections(newList);
                break;
            }
            default:
                break;
        }
    }, [editorSections, onDeleteSectionFromList, setEditorSections])

    const [aiLoaderStep, setAiLoaderStep] = useState<LandingAiGenerationSteps>('title')
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setClickedSectionId('')
            }
        };
        document.addEventListener('keydown', handleEsc);
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, []);

    useEffect(() => {
        if (landingContent?.data?.content?.length) {
            setIsLandingSectionsLoading(true)
            const sections = landingContent.data.content.map((item, idx) => ({ id: item.name + idx.toString(), layout: item }))
            setEditorSections(sections)
            setIsLandingSectionsLoading(false)
        }
        if (!isLandingContentLoading) {
            setIsLandingSectionsLoading(false)
        }
    }, [landingContent, isLandingContentLoading]);

    useEffect(() => {
        if (fetchedLandingData?.data) {
            setIsLandingDataLoading(true)
            const { data } = fetchedLandingData
            const content = fetchedLandingData.data.content || []
            const sections = content.map((item, idx) => ({ id: item.name + idx.toString(), layout: item }))
            setEditorSections(sections)
            setLandingTitle(data.title)
            setLandingUrl(data.url.path)
            setTimeout(() => {
                setIsLandingDataLoading(false)
            }, 700)
        } else {
            setLandingTitle('Project title')
            setLandingUrl('')
            if (!landingContent?.data?.content?.length) {
                setEditorSections([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedLandingData, landingContent])

    //    cb hell for testing
    useEffect(() => {
        if (type === AiId) {
            const timer = 500
            const steps: LandingAiGenerationSteps[] = ['title', 'description', 'images', 'finish'];
            setAiLoading(true);
            void dumbGetUser().then(() => {
                setAiLoaderStep(steps[0])
                setTimeout(() => {
                    void dumbGetUser().then(() => {
                        setAiLoaderStep(steps[1])
                        setTimeout(() => {
                            void dumbGetUser().then(() => {
                                setAiLoaderStep(steps[3])
                                setTimeout(() => {
                                    // setEditorSections(mockedAiSections)
                                    setAiLoading(false);
                                }, timer);
                            });
                        }, timer);
                    });
                }, timer);
            })
        } else {
            setAiLoading(false);
            setAiLoaderStep('title');
        }
    }, [type]);

    const onInfoIconClick = () => {
        setIsRejectionModalOpen(true)
    }

    const onCloseRejectionModal = () => {
        setIsRejectionModalOpen(false)
    }

    const onSaveClick = () => {
        if (landingId || landingFetchedId) {
            return updateLandingHandler({ isCampaignCtx: !!temporaryEditorData })
        }
        return createLandingHandler({ isCampaignCtx: !!temporaryEditorData })
    }

    const renderedEditorSections = useMemo(
        () => {
            const isFirstHero = editorSections?.[0]?.layout?.name?.includes(HERO)
            return editorSections.map((section, idx, array) => {
                const isActive = section.id === clickedSectionId

                return (
                    <SectionComponent
                        isActive={isActive}
                        isUpBtnDisabled={idx === 1 && isFirstHero}
                        isDownBtnDisabled={idx === array.length - 1}
                        key={section.id}
                        section={section}
                        idx={idx}
                        onOptionClick={onOptionClick}
                        isLoading={isLandingDataLoading}
                    />
                )
            })
        },
        [editorSections, clickedSectionId, onOptionClick, isLandingDataLoading]
    );

    const isLPLoading = isLandingSectionsLoading || isLandingContentLoading || isLandingLoading || isEventsResLoading || isLandingDataLoading

    useEffect(() => {
        if (layoutSettingsData && clickedSectionId) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [layoutSettingsData, clickedSectionId]);

    const statusActions = useMemo(() => {
        const isDraft = fetchedLandingData?.data?.status?.name === ModerationStatuses.draft
        return isDraft ? defaultActions : (fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.actions
    }, [fetchedLandingData?.data?.status])

    const valueStart = `${eventsRes?.data?.subdomain || ''}/`

    return (
        <EventsPageLayout className='landing-editor-page' isProHidden>
            <HeaderBreadcrumb
                className='landing-editor-page__breadcrumbs'
                crumbs={breadCrumbs}
                rightSide={(
                    <div className='flex mr-auto w-full'>
                        <div className='vertical-divider' />
                        <div>
                            <div className='landing-constructor__project-title'>
                                <Paragraph
                                    style={{ width: 250 }}
                                    ellipsis={{ rows: 1, expandable: false }}
                                    editable={{
                                        onChange: (value) => {
                                            if (value.trim()) {
                                                setLandingTitle(value)
                                            }
                                        },
                                        onStart: () => setLandingErrors({}),
                                    }}
                                >
                                    {landingTitle}
                                </Paragraph>
                            </div>
                            {landingErrors?.title && (
                                <div className='text-regular-14 label ml-6 error'>{landingErrors.title}</div>
                            )}
                        </div>
                        {/*<div className='vertical-divider mr-16 ml-16' />*/}
                        {/* <Button disabled className="landing-editor-page__do-button" type="text" icon={<UndoIcon />} /> */}
                        {/* <Button className="landing-editor-page__do-button mr-auto" type="text" icon={<RedoIcon />} /> */}
                        <aside
                            className="flex-center ml-auto"
                        >
                            {landingId && (
                                <>
                                    <div className='my-events-landing-pages-card__status whitespace-nowrap mx-8'>
                                        <StatusDescription
                                            status={(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.name}
                                            formattedStatusData={ModerationStatusesFormatted}
                                            size="small"
                                        />
                                        {(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.comment && (
                                            <div onClick={onInfoIconClick}>
                                                <InfoIcon fillColor="#252628" width={16} height={16} />
                                            </div>
                                        )}
                                    </div>
                                    <div className='vertical-divider mr-6 ml-12' />
                                </>
                            )}
                            {isLg ? (
                                <Button
                                    className="landing-constructor__save-btn"
                                    loading={isLoading}
                                    disabled={!editorSections.length}
                                    type="text"
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onClick={onSaveClick}
                                >
                                    Save
                                </Button>
                            ) : (
                                <Button
                                    className="landing-constructor__save-btn landing-constructor__save-icon-btn"
                                    disabled={!editorSections.length}
                                    icon={<SaveIcon />}
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onClick={onSaveClick}
                                />
                            )}
                            {landingId && isLg ? (
                                <Button
                                    className="ml-0"
                                    shape="round"
                                    onClick={onPreviewClick}
                                >
                                    Preview
                                </Button>
                            )
                                :
                                null}
                            {landingId && !isLg ? (
                                <Button
                                    className="landing-constructor__save-icon-btn landing-constructor__preview-icon-btn"
                                    icon={<EyeIcon />}
                                    onClick={onPreviewClick}
                                />
                            )
                                :
                                null}
                            {
                                statusActions?.map(({
                                    id,
                                    transit
                                }) => {
                                    return (
                                        <Button
                                            key={id}
                                            disabled={!editorSections.length || !landingUrl}
                                            loading={isPublishLoading || isLandingLoading}
                                            type="primary"
                                            className="landing-editor-page__publish-btn"
                                            onClick={() => {
                                                if (id) {
                                                    onPublishClick(id, transit)
                                                }
                                            }}
                                        >
                                            {transit}
                                        </Button>
                                    )
                                })}
                        </aside>
                    </div>
                )}
            />
            <div className="landing-editor-page__subheader">
                <div className="landing-editor-page__subheader-wrapper">
                    <div className='landing-editor-page__subheader-label'>
                        Page URL
                    </div>
                    <span className={`landing-editor-page__subheader-placeholder ${landingUrl ? 'landing-editor-page__subheader-placeholder-hidden' : ''}`}>
                        <span>{valueStart}</span>
                        <span>sophix</span>
                    </span>
                    <Space.Compact
                        className={`landing-editor-page__landing-url-input
                            ${landingErrors?.['url.path'] ? 'landing-editor-page__landing-url-input-error' : ""}
                        `}>
                        <Input
                            style={{ width: '415px' }}
                            value={`${valueStart}${landingUrl || ''}`}
                            status={landingErrors?.['url.path'] ? 'error' : ''}
                            onChange={e => {
                                if (!e.target.value.startsWith(valueStart)) {
                                    return
                                }
                                const newValue = e.target.value.replace(valueStart, '')
                                setLandingErrors({})
                                setLandingUrl(newValue)
                            }}
                            placeholder="sophix"
                        />
                    </Space.Compact>
                </div>
                {landingErrors?.['url.path'] && (
                    <div
                        className='text-regular-14 label my-8 error'
                    >
                        {landingErrors['url.path']}
                    </div>
                )}
            </div>

            <div className={`landing-constructor__wrapper
                ${!isLPLoading && !editorSections.length ? 'landing-constructor__wrapper-empty' : ''}
                ${isLPLoading ? 'landing-constructor__wrapper-loading' : ''}
            `}>
                <div
                    className={`main-page landing-constructor__page ${!isLPLoading && !editorSections.length ? 'landing-constructor__page-empty' : ''}`}>
                    {/* TOFIX: get header from BE  */}
                    <NonEditableSection html={loadedHeader} />

                    {renderedEditorSections}

                    {!isLPLoading && !editorSections.length && (
                        <EmptySectionsBlock />
                    )}
                    {isLPLoading && (
                        <div className='flex flex-center landing-constructor__spin'>
                            <Spin />
                        </div>
                    )}

                    <NonEditableSection html={loadedFooter} />
                </div>
            </div>

            {/* MODAL SECTION  */}
            <AddSectionModal />
            {layoutSettingsData && clickedSectionId
                ? (
                    <SectionSettingsDrawer
                        isOpen={!!layoutSettingsData}
                        layoutData={layoutSettingsData}
                    />
                )
                :
                null}

            <RejectionReasonModal
                isOpen={Boolean(isRejectionModalOpen)}
                onClose={onCloseRejectionModal}
                comment={(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.comment || ''}
                reason={(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.reason || ''}
            />
            <PublishedModal
                isOpen={Boolean(isPublishedModalOpen)}
                onClose={onClosePublishedModal}
            />
            <LoaderAi
                isOpen={isAiLoading}
                step={aiLoaderStep}
            />

            <Modal
                title='Landing page saved'
                open={isLandingSavedModalOpen && !!temporaryEditorData}
                onCancel={() => setLandingSavedModalOpen(false)}
                footer={(
                    <div
                        className="flex justify-end gap-10"
                    >
                        <Button
                            size="large"
                            onClick={() => {
                                temporaryEditorData && dispatch(partnerCampaignsActions.setTemporaryEditorData({
                                    ...temporaryEditorData,
                                    ads: [
                                        {
                                            ...temporaryEditorData.ads[0],
                                            landing: String(landingId || landingFetchedId)
                                        }
                                    ]
                                }))
                                temporaryEditorData && temporaryEditorData?.campaign_id
                                    ?
                                    navigate(`/events/edit/${eventId}/campaign/${temporaryEditorData.campaign_id as string}`)
                                    :
                                    navigate(`/events/create/${eventId}`)

                                setLandingSavedModalOpen(false)
                            }}
                        >
                            Go to campaign
                        </Button>
                        <Button
                            size="large"
                            type='primary'
                            onClick={() => {
                                setLandingSavedModalOpen(false)
                                navigate(`/events/${eventId}/landing/${landingFetchedId || landingId}/edit`)
                            }}
                        >
                            Continue editing
                        </Button>
                    </div>
                )}
            >
                <div className='mb-20'>
                    <span className='text-modal-description-14'>Would you like to continue editing the landing page or <br />return to the ad campaign editor? </span>
                </div>
            </Modal>
        </EventsPageLayout>
    )
}

export default LandingPageConstructor
