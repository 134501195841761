import React from "react";
import { Divider, PopoverProps, Progress, Typography } from "antd";
import './styles.scss'
import { CheckCircleIcon } from "@icons/CheckCircleIcon.tsx";
interface PasswordPopoverPropsType extends PopoverProps {
    password: string;
    isSuccessStatusHandler: (statusState: boolean) => void;
}


const PasswordPopover: React.FC<PasswordPopoverPropsType> = (props) => {
    const { password, isSuccessStatusHandler } = props;

    const validatePassword = (password: string): object => {
        const containsUpperCase = () => /[A-Z]/.test(password);
        const containsDigit = () => /\d/.test(password);

        return {
            minChars: password?.length >= 8 || false,
            hasDigit: containsDigit() || false,
            hasUpperLetter: containsUpperCase() || false,
        }
    }

    const calcProgressPercent = () => {
        let amountTrueValues = 0;
        const resultOfValidate = validatePassword(password);
        const values = Object.values(resultOfValidate);
        values.forEach((value) => {
            if (value) {
                amountTrueValues += 1
            }
        })
        return amountTrueValues;
    }

    const handleProgressStatus = () => {
        if (calcProgressPercent() === 0 || calcProgressPercent() === 1) {
            isSuccessStatusHandler(false);
            return {
                percent: 40,
                statusColor: "rgba(255, 77, 79, 1)",
                passwordStrength: <span style={{ color: "rgba(255, 77, 79, 1)" }}>Week</span>
            }
        }

        if (calcProgressPercent() === 2) {
            isSuccessStatusHandler(false);
            return {
                percent: 65,
                statusColor: "rgba(255, 169, 64, 1)",
                passwordStrength: <span style={{ color: "rgba(255, 169, 64, 1)" }}>Average</span>
            }
        }

        if (calcProgressPercent() === 3) {
            isSuccessStatusHandler(true);
            return {
                percent: 100,
                statusColor: "rgba(82, 196, 26, 1)",
                passwordStrength: <span style={{ color: "rgba(82, 196, 26, 1)" }}>Strong</span>
            }
        }
    }


    return (
        <>
            <section className='password-popover'>
                <article className='password-popover__validation-rules'>
                    <article className='password-popover__rule'>
                        <CheckCircleIcon
                            isActive={validatePassword(password).minChars}
                        />
                        <Typography.Text>
                            Minimum 8 characters
                        </Typography.Text>
                    </article>
                    <article className='password-popover__rule'>
                        <CheckCircleIcon isActive={validatePassword(password).hasUpperLetter} />
                        <Typography.Text>
                            At least 1 uppercase letter
                        </Typography.Text>
                    </article>
                    <article className='password-popover__rule'>
                        <CheckCircleIcon isActive={validatePassword(password).hasDigit} />
                        <Typography.Text>
                            At least 1 digit
                        </Typography.Text>
                    </article>
                </article>
                <Divider
                    style={{ margin: "8px 0" }}
                />
                <article className='password-popover__progress-info'
                    onClick={calcProgressPercent}
                >
                    <Typography.Text style={{
                        color: "rgba(87, 88, 90, 1)",
                        fontSize: "12px",
                        lineHeight: "20px",
                    }}>
                        Password strength
                    </Typography.Text>
                    <span className='password-popover__progress-bar'>
                        <Progress
                            percent={handleProgressStatus()?.percent}
                            showInfo={false}
                            type={'line'}
                            strokeColor={handleProgressStatus()?.statusColor}
                            size="small"
                        />
                        <div className="ml-6">
                            {handleProgressStatus().passwordStrength}
                        </div>
                    </span>
                </article>
            </section>
        </>
    )
}

export default PasswordPopover;
