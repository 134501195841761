export enum TabNames {
    Audiences = 'Audiences',
    AudienceCatalogs = 'Audience catalogs',
}

export enum TabKeys {
    Audiences = 'audiences-tab',
    AudienceCatalogs = 'audience-catalogs-tab',
}

export const MAX_DESCRIPTION_LENGTH = 120
export const MAX_SEGMENTS_LENGTH = 5
export const MAX_AUDIENCES_LENGTH = 5
export const MAX_EVENTS_LENGTH = 2
