import React, { useEffect, useState } from "react";
import { message, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadProps } from "antd";
import styles from "./AvatarUpload.module.css";
import {
    EventInfoFormStateType
} from "@components/EventInfoForm/types.ts";
import { RcFile } from "antd/es/upload/interface";

type AvatarUploadPropsType = {
    url?: string
    onChangeFormState: (fieldName: keyof EventInfoFormStateType, value: string) => void
}

const baseUrl = import.meta.env.VITE_API_URL as string;
const imageUploadUrl = baseUrl + '/partner/landings/image'

const AvatarUpload: React.FC<AvatarUploadPropsType> = (props) => {
    const { onChangeFormState } = props;
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
        if (props?.url) {
            setImageUrl(props.url)
        }
    }, [props?.url])

    // todo: check if it's needed
    const getBase64 = (img: RcFile | undefined, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        // @ts-ignore
        reader.readAsDataURL(img);
    };
    const handleChange: UploadProps['onChange'] = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            onChangeFormState('avatarUrl', info.file?.response?.data)
            // make component controllable after tests
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url); // todo: delete after tests
            });
        }

        if (info.file.status === 'removed') {
            onChangeFormState('avatarUrl', '')
            setImageUrl('');
        }
    };

    const beforeUpload = (file: File) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            void message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            void message.error('Image must smaller than 10MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    return (
        <>
            <div className={styles.AvatarUpload}>
                <Upload
                    accept=".jpg,.jpeg,.png,.webp,.svg"
                    name="image"
                    listType="picture-circle"
                    showUploadList={false}
                    action={`${imageUploadUrl}?folder=event&resize=1000`}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    maxCount={1}
                >
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '100px',
                                height: '100px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                            }}
                        />
                    ) : uploadButton}
                </Upload>
            </div>
        </>
    )
}

export default AvatarUpload;
