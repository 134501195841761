import { useState } from 'react';
import { message, Typography } from 'antd';
import CustomButton from '@components/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import EventCatalogTableZeroState
    from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCatalog/ZeroState/components/TableZeroState';
import './styles.scss'
import InvitationsTable from './table';
import { EventStatuses } from '@shared/constants';
import {
    useGetOrganizerEventByIdQuery,
    usePostCreateOrganizerEventInvitationsMutation
} from '@store/type-event/events/events.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { useBreakpoints } from '@hooks/browser';
import InvitationsModal from '../../components/InvitationsModal';
import { TabKeys } from '../constants';

type Props = {
    onChangeTab: (value: TabKeys) => void
}

const EventInvitations = ({ onChangeTab }: Props) => {
    const { eventId: id, tabId } = useParams()
    const isDesktop = useBreakpoints().md
    const navigate = useNavigate()

    const { data: res } = useGetOrganizerEventByIdQuery({ eventId: id?.toString() || '' }, { skip: !id })
    const isPublished = res?.data?.status?.name === EventStatuses.published
    // const { isLoading: isExportLoading, run: runExport } = useGetInvitationsExported(id?.toString() || '');

    const [count, setCount] = useState(5)
    const [isLoading, setLoading] = useState(false)
    // const [patchPartnerStatus] = usePatchPartnerStatusPartnerMutation()

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [onInviteClick] = usePostCreateOrganizerEventInvitationsMutation()

    const handleOk = async () => {
        setLoading(true)
        try {
            const response = await onInviteClick({ eventId: id!, count })
            if ('data' in response) {
                sendAnalytics(GTMEventName.addInvitations)
                setLoading(false)
                setIsModalOpen(false);
                navigate(`/events/info/${id || ''}/${TabKeys.invitations}`)
                return message.open({
                    type: 'success',
                    content: 'Invitations added',
                });
            }
            if ('error' in response) {
                setLoading(false)
                setIsModalOpen(false);
                return message.open({
                    type: 'error',
                    content: 'Request error',
                });
            }
        } catch (error) {
            setLoading(false)
            setIsModalOpen(false);
            return message.open({
                type: 'error',
                content: 'Request error',
            });

        }
        setIsModalOpen(false);

    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setCount(5)
    };
    if (isLoading) {
        return (
            <EventCatalogTableZeroState />
        )
    }

    return (
        <div className='px-24'>
            <div className='flex items-center justify-space-between w-full'>

                <div className='w-full'>
                    <Typography.Title level={4}>
                        Invitations list
                    </Typography.Title>
                </div>
                {!isDesktop && <CustomButton
                    disabled={!isPublished}
                    title=' +Add invitation'
                    type='primary'
                    onClick={() => setIsModalOpen(true)}
                />}
                {/* <div className='flex'>
                    <ExportToCsvButton
                        className='mr-6 export-button'
                        runExportReq={runExport}
                        isLoading={isExportLoading}
                        fileName='Invitations.csv'
                        type='default'
                    >
                        <DownloadIcon />
                        Export CSV
                    </ExportToCsvButton>

                </div> */}
            </div>

            <InvitationsTable onInviteClick={() => setIsModalOpen(true)} isPublished={isPublished} />

            {isModalOpen
                ?
                <InvitationsModal
                    isModalOpen={isModalOpen}
                    handleCancel={handleCancel}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    handleOk={handleOk}
                    count={count}
                    setCount={setCount}

                />
                :
                null}
        </div>
    )
}

export default EventInvitations