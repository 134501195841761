import { Drawer, message, Button, TableProps } from "antd";
import type { ColumnsType } from 'antd/es/table';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useGetUserOrganizerBillingWithdrawalsQuery, usePostWithdrawalMutation } from '@store/type-event/billing/billing.api.ts';
import { WithdrawalFiltersType } from "@pages/EventRolePages/Billing/types.ts";
import {
    initialWithdrawalFilters,
    ModalSteps,
    TabNames,
} from "@pages/EventRolePages/Billing/constants.ts";
import {
    UserBillingOrganizerItem,
    WithdrawalItem
} from "@store/type-event/billing/models.ts";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useBreakpoints } from "@hooks/browser";
import WithdrawalFilters from "@pages/EventRolePages/Billing/components/WithdrawalFilters.tsx";
import { formatDateToCommonFormat, formatByCurrency, ErrorDataResponse, errorsToFormAdapter } from "@shared/utils.ts";
import StatusDescription from "@components/StatusDescription";
import WithdrawalModal from "@pages/EventRolePages/Billing/components/WithdrawalModal.tsx";
import { useParams } from "react-router-dom";
import TotalNumbers from "@pages/EventRolePages/Billing/components/TotalNumbers.tsx";
import emptyWithdrawal from "@assets/png/emptyWithdrawal.png";
import MobileDrawer from "@components/mobile/Drawer";
import InfiniteScrollTable from "@components/InfiniteScrollTable/InfiniteScrollTable.tsx";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import { Orders } from "@shared/constants.ts";

const WithdrawalTable = () => {
    const isMd = useBreakpoints().md
    const isXxl = useBreakpoints().xxl

    const { billingId = '' } = useParams()

    const [filters, setFilters] = useState<WithdrawalFiltersType>(initialWithdrawalFilters)
    const [isOpenFilter, setOpenFilter] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isWithdrawalSending, setIsWithdrawalSending] = useState(false)
    const [withdrawalStep, setWithdrawalStep] = useState(ModalSteps.first)
    const [error, setError] = useState('')
    const [withdrawalData, setWithdrawalData] = useState([])

    const { data: withdrawalRes, isLoading: isWithdrawalLoading, isFetching: isWithdrawalFetching, refetch } = useGetUserOrganizerBillingWithdrawalsQuery({ ...filters, billingId }, { skip: !filters?.dateFrom })
    const [postWithdrawal] = usePostWithdrawalMutation()

    useEffect(() => {
        setWithdrawalData((prevWithdrawalData) => {
            const from = withdrawalRes?.meta?.from
            if (from && prevWithdrawalData.length < from) {
                return [...(prevWithdrawalData || []), ...(withdrawalRes?.data || [])]
            }
            return withdrawalRes?.data || []
        })
    }, [withdrawalRes])

    const setInitialFilters = useCallback(() => {
        const today = dayjs();
        const thirtyDaysAgo = dayjs().subtract(30, 'day');
        const todayFormatted = today.format('YYYY-MM-DD');
        const thirtyDaysAgoFormatted = thirtyDaysAgo.format('YYYY-MM-DD');
        setFilters({
            ...initialWithdrawalFilters,
            dateFrom: thirtyDaysAgoFormatted,
            dateTo: todayFormatted
        })
    }, [])

    useEffect(() => {
        setInitialFilters()
    }, []);

    const columns: ColumnsType = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 300,
            render: (date: WithdrawalItem['date']) => {
                return (
                    <span
                        className="organizer-billing-page__reports__date"
                    >
                        {formatDateToCommonFormat(date?.created_at)}
                    </span>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 300,
            align: 'right',
            render: (amount: WithdrawalItem['amount'], row: WithdrawalItem) => {
                return (
                    <span className="organizer-billing-page__reports__number">
                        {formatByCurrency(amount || 0, row.currency)}
                    </span>
                )
            },
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 300,
            render: (status: WithdrawalItem['status']) => (
                <StatusDescription status={status?.name} />
            )
        },
    ];

    const handleModalSubmit = async (amount: number) => {
        setIsWithdrawalSending(true)
        try {
            const response = await postWithdrawal({
                amount,
                billingId
            });
            if ('error' in response) {
                setIsWithdrawalSending(false)
                const errorsData = errorsToFormAdapter(response as unknown as ErrorDataResponse)
                if (errorsData?.amount) {
                    return setError(errorsData?.amount)
                }
                return message.error((response as ErrorDataResponse)?.error?.data?.message || 'Withdrawal is not sent');
            }
            setWithdrawalStep(ModalSteps.second)
            await refetch()
            return setIsWithdrawalSending(false)
        } catch (errorMsg) {
            setIsWithdrawalSending(false)
            return message.error('Withdrawal is not sent');
        }
    }

    const isLoading = isWithdrawalLoading || isWithdrawalFetching

    const onNextPage = useCallback(() => {
        setFilters((prev) => {
            return ({ ...prev, page: (withdrawalRes?.meta?.current_page || INIT_PAGE) + 1 })
        })
    }, [withdrawalRes])

    const handleChangeTable: TableProps<UserBillingOrganizerItem>['onChange'] = useCallback((pagination, _, sorter) => {
        const { columnKey, order } = sorter as { columnKey: string, order: string }
        const newOrder = Orders[order]
        if (order) {
            setFilters((prev) => {
                return ({ ...prev, sortField: columnKey, sortDirection: newOrder })
            })
        } else {
            setFilters((prev) => {
                return ({ ...prev, sortField: undefined, sortDirection: undefined })
            })
        }
    }, []);

    return (
        <div className={`organizer-billing-page__tab-inner ${!withdrawalRes?.data?.length ? 'organizer-billing-page__tab-inner-empty' : ''}`}>
            <TotalNumbers />
            {isXxl ? (
                <div className="organizer-billing-page__title">{TabNames.WithdrawalRequests}</div>
            ) : (
                <div className="organizer-billing-page__revenue-reports-title">
                    <div className="organizer-billing-page__title">{TabNames.WithdrawalRequests}</div>
                    <Button
                        className="organizer-billing-page__create-request-btn"
                        type="primary"
                        onClick={() => setIsModalOpen(true)}
                    >
                        <PlusOutlined />
                        Create request
                    </Button>
                </div>
            )}
            <WithdrawalFilters
                filters={filters}
                setInitialFilters={setInitialFilters}
                setFilters={setFilters}
                setOpenFilter={setOpenFilter}
                onModalOpen={() => setIsModalOpen(true)}
            />
            {!isLoading && !withdrawalRes?.data?.length ? (
                <div className='ad-accounts-page__empty-credentials'>
                    <img src={emptyWithdrawal} alt=' emptyWithdrawal' />
                    <span><b>You have no withdrawal requests</b></span>
                    <span>It will be displayed here when you have your first withdrawal requests</span>
                </div>
            ) : (
                <div className="mb-24">
                    <InfiniteScrollTable
                        isLastPage={withdrawalRes?.meta?.last_page === withdrawalRes?.meta?.current_page}
                        onNextPage={onNextPage}
                        isFirstPage
                        bordered
                        className={`${withdrawalRes?.data?.length ? '' : 'organizer-billing-page__table-empty'} bordered-table`}
                        dataSource={withdrawalData}
                        columns={columns}
                        rowKey="id"
                        loading={isWithdrawalLoading || isWithdrawalFetching}
                        onChange={handleChangeTable}
                        locale={{
                            emptyText: isWithdrawalLoading || isWithdrawalFetching ? ' ' : (
                                <div className='ad-accounts-page__empty-credentials'>
                                    <img src={emptyWithdrawal} alt=' emptyWithdrawal'/>
                                    <span><b>You have no withdrawal requests</b></span>
                                    <span>It will be displayed here when you have your first withdrawal requests</span>
                                </div>
                            )
                        }}
                    />
                </div>
            )}
            {isMd ? (
                <Drawer
                    title="Filters"
                    closeIcon={false}
                    placement={isMd ? "right" : 'bottom'}
                    open={isOpenFilter}
                    autoFocus={false}
                    mask={false}
                    onClose={() => setOpenFilter(false)}
                    maskStyle={{'opacity': 0, 'top': '62px'}}
                    rootStyle={{ 'top': '62px', background: 'rgba(26, 36, 64, 0.05)' }}
                    rootClassName="organizer-billing-page__drawer"
                    contentWrapperStyle={{boxShadow: 'none', borderLeft: '2px solid #f7f7f7'}}
                    extra={(
                        <CloseOutlined
                            onClick={() => setOpenFilter(false)}
                        />
                    )}
                >
                    <WithdrawalFilters
                        isDrawer
                        filters={filters}
                        setInitialFilters={setInitialFilters}
                        setFilters={setFilters}
                        setOpenFilter={setOpenFilter}
                        onModalOpen={() => setIsModalOpen(true)}
                    />
                </Drawer>
            ) : (
                <MobileDrawer
                    title='Filters'
                    onClose={() => setOpenFilter(false)}
                    onCancel={setInitialFilters}
                    isOpen={isOpenFilter}
                    onOk={() => setOpenFilter(false)}
                    applyButtonText="Apply"
                    resetButtonText="Reset"
                    propClassNames={{
                        footer: 'organizer-billing-page__filter-drawer-footer'
                    }}
                >
                    <WithdrawalFilters
                        isDrawer
                        filters={filters}
                        setInitialFilters={setInitialFilters}
                        setFilters={setFilters}
                        setOpenFilter={setOpenFilter}
                        onModalOpen={() => setIsModalOpen(true)}
                    />
                </MobileDrawer>
            )}
            <WithdrawalModal
                error={error}
                setError={setError}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleModalSubmit}
                isLoading={isWithdrawalSending}
                withdrawalStep={withdrawalStep}
                setWithdrawalStep={setWithdrawalStep}
            />
        </div>
    )
}

export default WithdrawalTable
