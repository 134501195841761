import { useContext } from 'react'
import { Button } from "antd";
import { LandingEditorContext } from '@contexts/LandingEditorContext';


const EmptySectionsBlock = () => {
    const {
        setClickedSectionId,
        setIsAddSectionModalOpen,
    } = useContext(LandingEditorContext);

    const onAddSection = () => {
        setClickedSectionId('')
        setIsAddSectionModalOpen(true)
    }

    return (
        <div className='empty-constructor-sections'>
            <div>
                <div className='empty-constructor-sections__title'>Start creating your landing page</div>
                <div className='empty-constructor-sections__subtitle'>Add the sections from the library</div>
                <Button size='large' onClick={onAddSection} type='primary'>
                    + Add section
                </Button>
            </div>
        </div>
    )
}

export default EmptySectionsBlock
