import { Spin, Table, Tooltip } from "antd";
import type { ColumnsType } from 'antd/es/table';
import React, { useCallback, useMemo, useState } from "react";
import { formatByCurrency } from "@shared/utils.ts";
import { useGetRevShareCommissionsByEventIdQuery } from '@store/type-event/billing/billing.api.ts';
import {
    initialRevShareFilters,
    TabNames,
} from "@pages/EventRolePages/Billing/constants.ts";
import TotalNumbers from './TotalNumbers.tsx';
import emptyTransactions from "@assets/png/emptyTransactions.png";
import { formatNumberWithThousandsSeparator } from "@components/Analytics/helpers.ts";
import InfoIcon from "@icons/header/InfoIcon.tsx";
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { FiltersKeys, RevShareFiltersType } from "@pages/EventRolePages/Billing/types.ts";
import { useGetOrganizerEventsFilterOptionsQuery } from "@store/type-event/events/events.api.ts";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import { INIT_CURRENT_PAGE } from "@shared/constants.ts";
import { menuItemSelectedIcon } from "@shared/menuItemSelectedIcon.tsx";
import { useParams } from "react-router-dom";
import { RevShareItem, UserBillingOrganizerEvent } from "@store/type-event/billing/models.ts";
import Avatar from "@components/Avatar";

const RevShareCommissionsTable = () => {
    const [eventsSearchValue, setEventsSearchValue] = useState<string>()
    const [eventsPage, setEventsPage] = useState(INIT_PAGE);
    // TOFIX: TS errors
    const [filters, setFilters] = useState<RevShareFiltersType>(initialRevShareFilters)
    const { billingId = '' } = useParams()

    const { data: revShareRes, isLoading: isRevShareLoading, isFetching: isRevShareFetching } = useGetRevShareCommissionsByEventIdQuery({ events: filters.events, billingId }, { refetchOnMountOrArgChange: true })
    const { data: eventsRes, isLoading: isEventsLoading, isFetching: isEventsFetching } = useGetOrganizerEventsFilterOptionsQuery({ search: eventsSearchValue || '', billings: [billingId], page: eventsPage }, { refetchOnMountOrArgChange: true })

    const columns: ColumnsType = [
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
            render: (event: UserBillingOrganizerEvent) => {
                return (
                    event ? (
                        <div className="flex items-center gap-6">
                            <Avatar
                                size='24'
                                url={event?.logo || ''}
                                bordered
                            />
                            <div className="organizer-billing-page__reports__event">
                                {event?.name}
                            </div>
                        </div>
                    ) : null
                )
            },
            sorter: (a, b) => a.event.name.localeCompare(b.event.name)
        },
        {
            title: 'Revshare',
            dataIndex: 'commissions',
            key: 'commissions',
            align: 'right',
            sorter: (a, b) => a.revshare - b.revshare,
            render: (commissions: RevShareItem[]) => {
                const revshare = commissions?.[0]?.revshare
                return (
                    <span className="organizer-billing-page__reports__number">
                        {formatNumberWithThousandsSeparator((revshare || 0) * 100)}
                        %
                    </span>
                )
            }
        },
    ];

    const isLoading = isRevShareLoading || isRevShareFetching

    const handleChangeFilter = useCallback((field: FiltersKeys, value: string[] | boolean) => {
        setFilters((prev) => ({ ...prev, [field]: value, page: INIT_CURRENT_PAGE }))
    }, [])

    return (
        <div className={`organizer-billing-page__tab-inner ${!revShareRes?.data?.length ? 'organizer-billing-page__tab-inner-empty' : ''}`}>
            <TotalNumbers />
            <div className="flex gap-6 organizer-billing-page__rev-share-title">
                <div className="organizer-billing-page__title">{TabNames.RevShareCommissions}</div>
                <Tooltip
                    overlayClassName='tooltip-general event-billing-form__tooltip'
                    title={(
                        <div>
                            Rev share commissions can differ depending on the event. The table below displays the values for
                            the selected event.
                        </div>
                    )}
                    placement="bottom"
                >
                    <div className='cursor-pointer'>
                        <InfoIcon fillColor="#57585A" width={18} height={18} className="info-icon" />
                    </div>
                </Tooltip>
            </div>
            <InfiniteSelect
                withAllOption
                id="organizer-billing-page__events"
                placeholder='All'
                maxTagCount={1}
                mode="multiple"
                style={{ width: '270px', marginBottom: '24px' }}
                filterOption={false}
                notFoundContent={isEventsLoading || isEventsFetching ? <Spin size="small" /> : <div>There is no events</div>}
                isLoading={isEventsLoading || isEventsFetching}
                listHeight={160}
                onChange={(value: string) => handleChangeFilter(FiltersKeys.events, value ? [value] : [])}
                page={eventsPage}
                setPage={setEventsPage}
                data={eventsRes}
                value={filters.events}
                allowClear
                showSearch
                setSearchValue={(val) => {
                    setEventsSearchValue(val)
                }}
                optionsMapper={({ slug, id }) => ({ value: id.toString(), label: slug })}
                menuItemSelectedIcon={menuItemSelectedIcon}
                classNameProp="rev-share__infinite-select__event"
                popupClassName='organizer-billing-page__select-with-icon'
            />
            {!isLoading && !revShareRes?.data?.length ? (
                <div className='ad-accounts-page__empty-credentials'>
                    <img src={emptyTransactions} alt=' emptyTransactions' />
                    <span><b>You have no rev share commissions</b></span>
                    <span>It will be displayed here when you have your first transactions</span>
                </div>
            ) : (
                <div className="mb-24">
                    <Table
                        bordered
                        className={`organizer-billing-page__transactions__table ${revShareRes?.data?.length ? '' : 'organizer-billing-page__table-empty'} bordered-table`}
                        dataSource={revShareRes?.data || []}
                        columns={columns}
                        rowKey="id"
                        loading={isRevShareLoading || isRevShareFetching}
                        pagination={false}
                        locale={{
                            emptyText: isRevShareLoading || isRevShareFetching ? ' ' : (
                                <div className='ad-accounts-page__empty-credentials'>
                                    <img src={emptyTransactions} alt=' emptyTransactions' />
                                    <span><b>You have no rev share commissions</b></span>
                                    <span>It will be displayed here when you have your first transactions</span>
                                </div>
                            )
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default RevShareCommissionsTable
