import './styles.scss'
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { Button, Spin } from 'antd';
import { useGetUserOrganizerBillingQuery } from "@store/type-event/billing/billing.api.ts";
import React, { useEffect, useState } from "react";
import { INIT_CURRENT_PAGE } from "@shared/constants.ts";
import { useNavigate, useParams } from "react-router-dom";
import { settings } from "@pages/routes.ts";
import ArrowIcon from '@assets/ArrowIcon';
import { PlusOutlined } from "@ant-design/icons";
import EventBillingDrawer from "@pages/EventRolePages/Billing/components/EventBillingDrawer.tsx";
import { useAuth } from '@contexts/AuthContext';

const EventBillingHeader = () => {
    const { billingId, tabId } = useParams()
    const { user } = useAuth()
    const isEvent = user?.organization.type === 'event'
    const isEmpty = !billingId || billingId === 'undefined'

    const navigate = useNavigate()

    const [billingsPage, setBillingsPage] = useState(INIT_CURRENT_PAGE)
    const [isEventBillingDrawerOpen, setIsEventBillingDrawerOpen] = useState(false)

    const { data: billingsRes, isLoading: isBillingsResLoading, isFetching: isBillingsResFetching } = useGetUserOrganizerBillingQuery({ page: billingsPage })

    useEffect(() => {
        if (!billingId || billingId === 'undefined') {
            const firstBillingId = billingsRes?.data?.[0]?.id
            if (firstBillingId && isEvent) {
                navigate(`${settings}/${tabId}/${firstBillingId}`)
            }
            if (!firstBillingId) {
                navigate(`${settings}/billing`)
            }
        }
    }, [billingId, billingsRes?.data, navigate, isEvent, tabId]);

    const onCreateBilling = () => {
        setIsEventBillingDrawerOpen(true)
    }

    return (
        <div className={isEmpty ? '' : 'header__content event-billing-header__content'}>
            {Boolean(isEvent && billingId && billingId !== 'undefined') && (
                <InfiniteSelect
                    suffixIcon={(
                        <ArrowIcon fillColor="#C0C1C3" width="24" height="24" />
                    )}
                    id="event-billing-header__selector"
                    className="event-billing-header__selector"
                    placeholder='Select from the existing billings'
                    filterOption={false}
                    notFoundContent={isBillingsResLoading || isBillingsResFetching ? <Spin size="small" /> :
                        <div>There is no billings</div>}
                    isLoading={isBillingsResLoading || isBillingsResFetching}
                    listHeight={160}
                    onChange={(value: string) => {
                        navigate(`${settings}/${tabId}/${value}`)
                    }}
                    page={billingsPage}
                    setPage={setBillingsPage}
                    data={billingsRes}
                    value={!billingId || billingId === 'undefined' ? null : billingId}
                    showSearch={false}
                    optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                    popupClassName="event-billing-header__selector__popup"
                />
            )}
            {isEvent && !isEmpty && (
                <Button
                    type='primary'
                    className="event-billing-header__create-button"
                    onClick={onCreateBilling}
                >
                    <PlusOutlined />
                    Create billing
                </Button>
            )}
            {isEventBillingDrawerOpen && isEvent
                ?
                <EventBillingDrawer
                    isOpen={isEventBillingDrawerOpen}
                    onClose={() => setIsEventBillingDrawerOpen(false)}
                />
                :
                null}
        </div>
    )
}

export default EventBillingHeader
