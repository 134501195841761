import './styles.scss'
import NoEventsDataFound from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCardsPage/ZeroState/NoDataFound'
import { EventCatalogItemStatuses } from '@shared/constants'
import SkeletonEventDetailsState from './skeleton'
import { useEffect, useState } from 'react'
import { InlineWidget } from 'react-calendly'
import { Button, Divider, message } from 'antd'
import { Typography } from 'antd';
import { GetEventCheckListByEventIdItem, OrganizerEventDataFullItem } from '@store/type-event/events/models';
import EventItemHeaderInfo from '../../components/EventItemHeaderInfo'
import { EventChecklistName } from '../constants'
import EventItemHeaderInfoMobile from '../../components/EventItemHeaderInfoMobile'
import { useBreakpoints } from '@hooks/browser'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetOrganizerEventByIdQuery, usePatchOrganizerEventStatusByIdMutation } from '@store/type-event/events/events.api'
import InfoDropdown from '@components/Header/InfoDropdown'
import NotificationsHeader from '@components/NotificationsHeader'
import ProfileDropdown from '@components/Header/ProfileDropdown'
import StatusDescription from '@components/StatusDescription'
import sendAnalytics from '@hooks/sendAnalytics/sendAnalytics'
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api'
import { GTMEventName } from '@hooks/sendAnalytics/constants'

const { Title } = Typography;

const EventPreview = () => {
    const isDesktop = useBreakpoints().md
    const { eventId } = useParams()
    const navigate = useNavigate()

    const [isViewMore, setViewMore] = useState(false)
    const [event, setEvent] = useState<OrganizerEventDataFullItem | null>(null)
    const [bannerCheckList, setBannerCheckList] = useState<GetEventCheckListByEventIdItem[]>([])
    const [tooltipList, setTooltipList] = useState<{ id: string, name: EventChecklistName }[]>([])
    const [isLoadingStatus, setIsLoadingStatus] = useState(false)

    const [patchEventStatus] = usePatchOrganizerEventStatusByIdMutation()
    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_status' })
    const { data: eventRes, isLoading, refetch } = useGetOrganizerEventByIdQuery(
        { eventId: eventId?.toString() || '' },
        { skip: !eventId }
    );

    useEffect(() => {
        if (eventRes?.data) {
            setEvent(eventRes.data)
            setBannerCheckList(eventRes.data?.checklist ?? [])
            const mappedList = eventRes.data?.checklist
                ?.filter(item => !item.completed)
                .map(item => item.item) ?? [];

            setTooltipList(mappedList);

        }
    }, [eventRes?.data])

    const onUpdateStatus = async () => {
        if (!eventId) {
            return message.open({
                type: 'error',
                content: 'Event update error',
            });
        }
        setIsLoadingStatus(true)
        try {
            const statusId = statuses?.data?.find(({ slug }) => (event?.status.name === 'published' ? slug === 'draft' : slug === 'published'))?.id
            if (!statusId) {
                return message.open({
                    type: 'error',
                    content: 'Event update error',
                });
            }
            const response = await patchEventStatus({ eventId: eventId.toString(), status: statusId })
            if ('data' in response) {
                sendAnalytics(GTMEventName.publishEvent)
                // eslint-disable-next-line @typescript-eslint/await-thenable
                await refetch()
                setIsLoadingStatus(false)
                return message.open({
                    type: 'success',
                    content: 'Event is published successfuly',
                });
            }
            if ('error' in response) {
                setIsLoadingStatus(false)
                navigate(`/events/info/${eventId}/general`)
                return message.open({
                    type: 'error',
                    content: 'Event update error',
                });
            }
        } catch (error) {
            setIsLoadingStatus(false)
            return message.open({
                type: 'error',
                content: 'Event Update error',
            });
        }
    }

    if (isLoading) {
        return (
            <div className='event-preview-content'>
                <SkeletonEventDetailsState />
            </div>
        )
    }
    if (!event) {
        return <NoEventsDataFound />
    }

    return (
        <div className='event-preview-content'>
            <div className='event-preview-content__actions-header'>
                <Link to={`/events/info/${event.id}/general`} className='event-preview-content__actions-header__back-link'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 6L9 12L15 18" stroke="#C0C1C3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>
                        Back to general
                    </span>
                </Link>

                <aside className='flex mr-24'>
                    <div
                        className='flex items-center gap-6'
                    >
                        <div className='flex items-center gap-20'>
                            <div style={{ width: '100%' }}>
                                <StatusDescription
                                    status={event?.status.name || 'draft'}
                                    size='small'
                                />
                            </div>
                            <Button
                                type='primary'
                                loading={isLoadingStatus}
                                onClick={() => void onUpdateStatus()}
                                disabled={event?.status.name === 'published'}
                            >
                                Publish
                            </Button>
                        </div>
                        <div style={{
                            height: '28px',
                            width: '1px',
                            backgroundColor: '#E8E9EB',
                            margin: '0 20px'
                        }} />

                    </div>

                    <div
                        className='settings-header__item'
                    >
                        <InfoDropdown fillColor="#57585A" />
                    </div>
                    <div
                        className='settings-header__item'
                    >
                        <NotificationsHeader fillColor="#57585A" />
                    </div>
                    <div
                        className='settings-header__item'
                    >
                        <ProfileDropdown />
                    </div>
                </aside>

            </div>
            <header className='event-preview-content__header'>
                <img src={event.image_bg} />
            </header>

            <article className='event-preview-content__body'>
                {isDesktop
                    ?
                    <EventItemHeaderInfo
                        info={
                            {
                                id: event.id,
                                actions: event.status.actions,
                                logo: event.image_logo,
                                title: event.name,
                                status: event.status.name as EventCatalogItemStatuses,
                                date: {
                                    started_at: event.schedule?.started_at || '',
                                    finished_at: event.schedule?.finished_at || '',
                                },
                                location: event.location?.slug || '',
                                participants: (event.participants).toString(),
                                category: event.category?.name || '',
                                organization_url: event.url,
                            }
                        }
                        checkList={bannerCheckList || []}
                        tooltipList={tooltipList || []}
                        refetch={() => void refetch()}
                    />
                    :
                    <EventItemHeaderInfoMobile
                        info={
                            {
                                id: event.id,
                                actions: event.status.actions,
                                logo: event.image_logo,
                                title: event.name,
                                status: event.status.name as EventCatalogItemStatuses,
                                date: {
                                    started_at: event.schedule?.started_at || '',
                                    finished_at: event.schedule?.finished_at || '',
                                },
                                location: event.location?.slug || '',
                                participants: (event.participants).toString(),
                                category: event.category?.name || '',
                                organization_url: event.url,
                            }
                        }
                        checkList={bannerCheckList || []}
                        tooltipList={tooltipList || []}
                        refetch={() => void refetch()}
                    />
                }
                <p className={`event-preview-content__body-description ${isViewMore ? '' : 'isShort'}`}>
                    {event?.description || ''}
                </p>

                {
                    isViewMore
                        ?
                        <button onClick={() => setViewMore(false)} className='more-btn' >
                            Show less
                        </button>
                        :
                        <button onClick={() => setViewMore(true)} className='more-btn'>
                            View more
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.08882 0.837358C0.861017 1.06516 0.861017 1.43451 1.08882 1.66232L4.58882 5.16232C4.81663 5.39012 5.18598 5.39012 5.41378 5.16232L8.91378 1.66232C9.14159 1.43451 9.14159 1.06516 8.91378 0.837358C8.68598 0.609552 8.31663 0.609552 8.08882 0.837358L5.0013 3.92488L1.91378 0.837358C1.68598 0.609552 1.31663 0.609552 1.08882 0.837358Z" fill="#4E67EA" />
                            </svg>
                        </button>
                }
                <Divider />
                <article className='event-preview-content__calendly'>
                    <header>
                        <Title level={4}>
                            Meet with your success manager
                        </Title>
                        <p className='event-preview-content__calendly-desc'>
                            Meet with your success manager to complete your event setup
                        </p>
                    </header>
                    <InlineWidget url="https://calendly.com/boost-express/event-onboarding?hide_event_type_details=1&hide_gdpr_banner=1" />
                </article>
            </article>
        </div>
    )
}

export default EventPreview