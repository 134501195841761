import { Button, InputNumber, Modal } from 'antd'
import React from 'react'

interface Props {
    isModalOpen: boolean;
    handleCancel: () => void;
    handleOk: () => void;
    count: number;
    setCount: (count: number) => void
}
const InvitationsModal = ({
    isModalOpen,
    handleCancel,
    handleOk,
    count,
    setCount
}: Props) => {
    return (
        <Modal
            title=""
            className='modal-footer-flex'
            open={isModalOpen}
            onCancel={handleCancel}
            width={430}
            footer={[
                <Button
                    type="primary"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={handleOk}
                >
                    Add Invitations
                </Button>
            ]}
        >
            {/* <p>Enter partner e-mail</p> */}
            <div className='organizer-event-tab__partners-invitation-modal-body'>
                <p>
                    Нow many invitations do you want to add?
                </p>
                <InputNumber
                    min={1}
                    max={1000}
                    changeOnWheel
                    placeholder='max. 10 000'
                    style={{ width: '100%' }}
                    value={count}
                    onChange={(value: unknown) => setCount(value as number)}
                />
            </div>
        </Modal>
    )
}

export default InvitationsModal