import { useCallback, useEffect, useMemo, useState } from 'react';
import { message, Select, Spin, Typography } from 'antd';
import CustomButton from '@components/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import EventCatalogTableZeroState
    from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCatalog/ZeroState/components/TableZeroState';
import SponsorsTable from './table';
import { EventStatuses } from '@shared/constants';
import {
    useGetOrganizerEventByIdQuery,
    usePostCreateOrganizerEventInvitationsMutation
} from '@store/type-event/events/events.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';
import { FilterByEnumItem } from '@store/main/enums/models';
import { useGetOrganizerPartnersByEventQuery } from '@store/type-event/partners/partners.api';
import { statusMenuItemSelectedIcon } from '@pages/EventRolePages/ModerationPage/constants';
import { useBreakpoints } from '@hooks/browser';
import InvitationsModal from '../../components/InvitationsModal';
import { TabKeys } from '../constants';
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";

type Props = {
    onChangeTab: (value: TabKeys) => void
}

const EventPartners = ({ onChangeTab }: Props) => {
    const isDesktop = useBreakpoints().md
    const { eventId: id } = useParams()

    const [page, setPage] = useState(INIT_PAGE)
    const [filters, setFilters] = useState<{ statuses: string[] }>({ statuses: [] })

    const navigate = useNavigate()

    const { data: res } = useGetOrganizerEventByIdQuery({ eventId: id?.toString() || '' }, { skip: !id })
    const isPublished = res?.data?.status?.name === EventStatuses.published
    const { data: statuses, isLoading: isStatusesLoading } = useGetFiltersByEnumQuery({ name: 'event_invite_status' })
    const { data: sponsors, isLoading: isFetching } = useGetOrganizerPartnersByEventQuery({ eventId: id?.toString() || '', page, filters }, { skip: !id })

    const [sponsorsList, setSponsorsList] = useState(sponsors?.data || []);

    const [count, setCount] = useState(5)
    const [isLoading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [onInviteClick] = usePostCreateOrganizerEventInvitationsMutation()

    const mappedStatusesData = useMemo(() => {
        return statuses?.data?.map((item: FilterByEnumItem) => ({
            ...item,
            slug: item.translate
        })) || [];
    }, [statuses]);

    //*TABLE
    useEffect(() => {
        setSponsorsList((prevLandings) => {
            const from = sponsors?.meta?.from
            if (from && prevLandings.length < from) {
                return [...(prevLandings || []), ...(sponsors?.data || [])]
            }
            return sponsors?.data || []
        })
    }, [sponsors])
    const isLastPage = useMemo(() => {
        const lastPage = sponsors?.meta?.last_page || INIT_PAGE
        return page >= lastPage
    }, [sponsors?.meta?.last_page, page])

    const onNextPage = useCallback(() => {
        setPage(page + 1)
    }, [setPage, page])

    useEffect(() => {
        setPage(INIT_PAGE)
    }, [filters]);
    //*

    const handleOk = async () => {
        setLoading(true)
        try {
            const response = await onInviteClick({ eventId: id!, count })
            if ('data' in response) {
                sendAnalytics(GTMEventName.addInvitations)
                setLoading(false)
                setIsModalOpen(false);
                navigate(`/events/info/${id || ''}/${TabKeys.invitations}`)
                return message.open({
                    type: 'success',
                    content: 'Invitations added',
                });
            }
            if ('error' in response) {
                setLoading(false)
                setIsModalOpen(false);
                return message.open({
                    type: 'error',
                    content: 'Request error',
                });
            }
        } catch (error) {
            setLoading(false)
            setIsModalOpen(false);
            return message.open({
                type: 'error',
                content: 'Request error',
            });

        }
        setIsModalOpen(false);

    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setCount(5)
    };
    if (isLoading) {
        return (
            <EventCatalogTableZeroState />
        )
    }

    return (
        <article className='px-24 w-full'>
            <div className='w-full'>
                <Typography.Title level={4}>
                    Sponsors
                </Typography.Title>
            </div>

            <div className='my-16 flex items-center justify-space-between w-full'>
                <Select
                    placeholder='All statuses'
                    maxTagCount={1}
                    mode={isDesktop ? "multiple" : undefined}
                    showSearch={false}
                    style={{ width: '340px', marginRight: '8px' }}
                    allowClear
                    filterOption={false}
                    notFoundContent={isStatusesLoading ? <Spin size="small" /> : null}
                    loading={isStatusesLoading}
                    value={filters.statuses}
                    onChange={(statuses) => setFilters({ statuses })}
                    fieldNames={{ label: 'slug', value: 'id' }}
                    options={mappedStatusesData}
                    popupClassName='moderation-table__popup-select moderation-table__popup-select__status'
                    menuItemSelectedIcon={isDesktop ? statusMenuItemSelectedIcon : null}
                />

                <div className='flex'>
                    <CustomButton
                        disabled={!isPublished}
                        title=' +Add invitation'
                        type='primary'
                        onClick={() => setIsModalOpen(true)}
                    />
                </div>
            </div>

            <SponsorsTable
                isLoading={isFetching}
                onInviteClick={() => setIsModalOpen(true)}
                isPublished={isPublished}
                onChangeTab={onChangeTab}
                sponsorsList={sponsorsList}
                isLastPage={isLastPage}
                page={page}
                onNextPage={onNextPage}
            />

            {isModalOpen
                ?
                <InvitationsModal
                    isModalOpen={isModalOpen}
                    handleCancel={handleCancel}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    handleOk={handleOk}
                    count={count}
                    setCount={setCount}

                />
                :
                null}
        </article>
    )
}

export default EventPartners