import EventsPageLayout from '@layouts/EventsPageLayout'
import React, { useEffect, useState, useMemo } from "react";
import './styles.scss'
import UsersAndRoles from "@pages/PartnerRolePages/SettingsPage/components/UsersAndRoles.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { getSettingsList, SettingsIds } from "@pages/PartnerRolePages/SettingsPage/constants.ts";
import { settings } from "@pages/routes.ts";
import UserProfile from "@pages/CommonPages/UserProfile";
import AdAccountsPage from "@pages/EventRolePages/AdAccountsPage";
import { useAuth } from '@contexts/AuthContext';
import ArrowIcon from "@assets/ArrowIcon.tsx";
import BillingEvent from "@pages/EventRolePages/Billing";
import {TabKeys} from "@pages/EventRolePages/Billing/constants.ts";
import { useGetUserOrganizerBillingQuery } from "@store/type-event/billing/billing.api.ts";
import { INIT_CURRENT_PAGE } from "@shared/constants.ts";

type Props = {
    isEvent?: boolean
}

const SettingsPage = ({ isEvent }: Props) => {
    const { tabId, billingId } = useParams()
    const navigate = useNavigate()
    const { user: data, isUserLoading } = useAuth();

    const [isShowBillingLabels, setIsShowBillingLabels] = useState(true)
    const { data: billingsRes } = useGetUserOrganizerBillingQuery({ page: INIT_CURRENT_PAGE })

    const isAdmin = data?.organization?.type === 'admin'

    useEffect(() => {
        if (!tabId) {
            navigate(`${settings}/${SettingsIds.profile}`)
        }
    }, [tabId, navigate]);


    const isBillingTab = useMemo(() => Object.values(TabKeys).some(value => value === tabId), [tabId])
    const isEmptyBilling = useMemo(() => {
        if (isBillingTab) {
            return (!billingId || billingId === 'undefined')
        }
        return !billingsRes?.data?.length
    }, [isBillingTab, billingId, billingsRes])

    return (
        <EventsPageLayout>
            <div className="settings-page__wrapper">
                <div className="settings-page__list">
                    {getSettingsList({ isEvent, isAdmin }).map((item) => {
                        if (!item) return null
                        const { id, label, children } = item
                        const isWithChildren = children?.length && (id === TabKeys.Billing && !isEmptyBilling || id !== TabKeys.Billing)
                        return (
                            <div className={`settings-page__list-item__wrapper 
                                ${isWithChildren ? 'settings-page__list-item__wrapper-with-children' : ''}
                                ${isWithChildren && isShowBillingLabels ? 'settings-page__list-item__wrapper-with-children__show' : ''}
                            `}>
                                <div
                                    key={id}
                                    className={`settings-page__list-item 
                                        ${tabId === id ? 'settings-page__list-item__active' : ''}   
                                    `}
                                    onClick={() => {
                                        if (isWithChildren) {
                                            setIsShowBillingLabels(!isShowBillingLabels)
                                        } else {
                                            navigate(`${settings}/${id}`)
                                        }
                                    }}
                                >
                                    {label}

                                    {isWithChildren ? (
                                        <ArrowIcon fillColor="#C0C1C3" width="20" height="20" />
                                    ) : null}
                                </div>
                                {isWithChildren && children?.map((child) => {
                                    const { id: childId, label: childLabel } = child
                                    return (
                                        <div
                                            key={childId}
                                            className={`settings-page__list-item settings-page__list-item-child ${tabId === childId ? 'settings-page__list-item__active' : ''}`}
                                            onClick={() => {
                                                if (isBillingTab) {
                                                    navigate(`${settings}/${childId}/${billingId}`)
                                                } else {
                                                    navigate(`${settings}/${childId}`)
                                                }
                                            }}
                                        >
                                            {childLabel}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className={`settings-page__content ${isBillingTab ? 'settings-page__content__billing' : ''}`}>
                    {!isAdmin && tabId === SettingsIds.usersRoles && (
                        <UsersAndRoles isEvent={isEvent}/>
                    )}
                    {tabId === SettingsIds.profile && (
                        <UserProfile/>
                    )}
                    {tabId === SettingsIds.adAccounts && (
                        <AdAccountsPage />
                    )}
                    {isBillingTab && (
                        <BillingEvent tabId={tabId} billingId={billingId} />
                    )}
                </div>
            </div>
        </EventsPageLayout>
    )
}

export default SettingsPage
