import {MenuProps} from "antd";

export const textAlignItems: MenuProps['items'] = [
    {
        label: 'Left',
        key: 'left',
    },
    {
        label: 'Center',
        key: 'center',
    },
    {
        label: 'Right',
        key: 'right',
    },
];

export enum SCRIPT_TYPES {
    sub = 'sub',
    sup = 'sup',
}

export const dotsItems: MenuProps['items'] = [
    {
        label: 'Superscript',
        key: SCRIPT_TYPES.sup,
    },
    {
        label: 'Subscript',
        key: SCRIPT_TYPES.sub,
    }
];

export const MAX_TEXT_LENGTH = 500
export const DATA_ELEMENT_ID = 'data-element-id'
export const TOOLTIP_WIDTH = 184
export const BUTTON_TOOLTIP_WIDTH = 140
export const TOOLTIP_HEIGHT = 46

export enum POPOVER_IDS {
    link = 'link',
    colorPicker = 'colorPicker',
    align = 'align',
    dots = 'dots',
}
