import React, { useMemo } from 'react';
import { Form, Input, Select, Spin, Tooltip } from "antd";
import SuggestSelectCities from '@components/SuggestSelectCities';
import SuggestSelectCountries from '@components/SuggestSelectCountries';
import InfoIcon from "@icons/header/InfoIcon.tsx";
import { useGetCurrenciesByISOQuery } from '@store/type-event/billing/billing.api.ts';
import { EventBilling } from "@store/type-event/billing/models.ts";
import { CityField, EventBillingFormErrors } from "@pages/EventRolePages/Billing/types.ts";
import { useGetCountriesBySearchQuery } from "@store/main/countries/countries.api.ts";
import { useBreakpoints } from "@hooks/browser";
import ArrowIcon from "@assets/ArrowIcon.tsx";

interface BillingInfoProps {
    form: EventBilling;
    validationErrors: EventBillingFormErrors
    onFormChange: (field: Partial<EventBilling>) => void;
}

const EventBillingForm: React.FC<BillingInfoProps> = ({
    form,
    onFormChange,
    validationErrors
}) => {
    const isDesktop = useBreakpoints().md;

    const { data: countriesRes } = useGetCountriesBySearchQuery({ search: form.country?.slug || '', with_payment_info: '1' }, { skip: !form.country?.slug })
    const { data: currenciesRes, isLoading: isCurrenciesLoading, isFetching: isCurrenciesFetching } = useGetCurrenciesByISOQuery({ iso: countriesRes?.data?.[0]?.iso_code || '' })

    const formValuesHadler = (field: Partial<EventBilling>) => {
        if ('city' in field) {
            const values = field as CityField
            onFormChange({ country: { ...values?.city?.country || {} } })
            onFormChange({ city: values.city })
            return
        }
        if ('country' in field) {
            onFormChange(field)
            return
        }
        onFormChange(field)
    }

    const currenciesOptions = useMemo(() => {
        if (form.currency) {
            const foundCurrency = currenciesRes?.data?.find(({ currency: currencyValue }) => currencyValue === form.currency)
            if (!foundCurrency) {
                return [...(currenciesRes?.data || []), { currency: form.currency, translate: form.currency?.toUpperCase() }]
            }
        }
        return currenciesRes?.data
    }, [currenciesRes])

    return (
        <Form
            className="event-billing-form"
            name="eventBillingInfo"
            initialValues={form}
            autoComplete="off"
            layout="vertical"
            onValuesChange={(field: Partial<EventBilling>) => formValuesHadler(field)}
        >
            <div className="event-billing-form__title">
                Basic information
                <Tooltip
                    overlayClassName='tooltip-general event-billing-form__tooltip'
                    title={(
                        <div>
                            Specific countries may have their own unique instructions. Depending on the country you’re sending a wire to, you can reference the relevant information.
                        </div>
                    )}
                    placement={isDesktop ? "bottom" : "bottomRight"}
                >
                    <div className='cursor-pointer ml-4'>
                        <InfoIcon width={18} height={18} fillColor="#57585A" className="info-icon" />
                    </div>
                </Tooltip>
            </div>
            <Form.Item
                style={{ marginBottom: validationErrors?.name ? 'undefined' : '12px' }}
                help={validationErrors?.name && validationErrors?.name}
                validateStatus={validationErrors?.name ? 'error' : ''}
                name='name'
                label="Billing name"
            >
                <Input placeholder='Billing name' />
            </Form.Item>
            <Form.Item
                style={{marginBottom: validationErrors?.address ? 'undefined' : '12px'}}
                help={validationErrors?.address && validationErrors?.address}
                validateStatus={validationErrors?.address ? 'error' : ''}
                name="address"
                label="Organisation address"
            >
                <Input placeholder='Organisation address'/>
            </Form.Item>
            <Form.Item
                style={{ marginBottom: validationErrors?.['country.id'] ? 'undefined' : '12px' }}
                help={validationErrors?.country || validationErrors?.['country.id']}
                validateStatus={validationErrors?.country || validationErrors?.['country.id']
                    ? 'error' : ''}
                label="Country"
            >
                <SuggestSelectCountries
                    value={form.country}
                    placeholder={form?.country?.slug || 'Сhoose country'}
                    onChange={(country) => {
                        onFormChange({ country, city: null })
                    }}
                />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: validationErrors?.['city.id'] ? 'undefined' : '12px' }}
                help={validationErrors?.['city.id'] && validationErrors?.['city.id']}
                validateStatus={validationErrors?.['city.id'] ? 'error' : ''}
                name="city"
                label="City"
            >
                <SuggestSelectCities
                    value={form?.city || null}
                    countryValue={form.country}
                    placeholder={form?.city?.slug || 'Сhoose city'}
                    onChange={(city) => onFormChange({ city })}
                />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: validationErrors?.currency ? 'undefined' : '12px' }}
                help={validationErrors?.currency && validationErrors?.currency}
                validateStatus={validationErrors?.currency ? 'error' : ''}
                label="Currency"
            >
                <Select
                    suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="20" height="20" className="common-select-icon" />}
                    options={currenciesOptions}
                    value={form.currency}
                    onChange={(value) => {
                        onFormChange({
                            currency: value || ''
                        })
                    }}
                    placeholder="Currency"
                    style={{ width: '100%' }}
                    filterOption={false}
                    notFoundContent={isCurrenciesLoading || isCurrenciesFetching ? <Spin size="small" /> :
                        <div>There is no currencies</div>}
                    loading={isCurrenciesLoading || isCurrenciesFetching}
                    fieldNames={{ value: 'currency', label: 'translate' }}
                    optionRender={({ data }) => {
                        const { translate, description = '' } = data as { translate: string, description: string }
                        return (
                            <div>
                                {translate}
                                {' '}
                                {description ? `(
                                ${description}
                                )` : ''}
                            </div>
                        )
                    }}
                />
            </Form.Item>

            {form.country && countriesRes?.data?.[0]?.payment_info?.length && countriesRes.data[0].payment_info.map(({ id: rowId, name, inputs }) => {
                if (!inputs?.length) {
                    return (
                        <Form.Item
                            key={rowId}
                            style={{marginBottom: validationErrors?.[`bank_details.${rowId}`] ? 'undefined' : '12px'}}
                            help={validationErrors?.[`bank_details.${rowId}`]}
                            validateStatus={validationErrors?.[`bank_details.${rowId}`] ? 'error' : ''}
                            name={`bank_details.${rowId}`}
                            label={name}
                        >
                            <Input placeholder={name}/>
                        </Form.Item>
                    )
                }
                return (
                    <React.Fragment key={rowId}>
                        <div className="event-billing-form__title event-billing-form__second-title">
                            {name}
                        </div>

                        {inputs?.map(({ id, name }) => {
                            return (
                                <Form.Item
                                    key={id}
                                    style={{marginBottom: validationErrors?.[`bank_details.${rowId}.${id}`] ? 'undefined' : '12px'}}
                                    help={validationErrors?.[`bank_details.${rowId}.${id}`]}
                                    validateStatus={validationErrors?.[`bank_details.${rowId}.${id}`] ? 'error' : ''}
                                    name={`bank_details.${rowId}.${id}`}
                                    label={name}
                                >
                                    <Input placeholder={name}/>
                                </Form.Item>
                            )
                        })}
                    </React.Fragment>
                )
            })}
        </Form>
    );
};

export default EventBillingForm;
