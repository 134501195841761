import {TabKeys, TabNames} from "@pages/EventRolePages/Billing/constants.ts";

export const InitialUserEdit = {
    name: '',
    email: '',
    roles: [],
    password: '',
    new_password: '',
    isGeneratePassword: true,
    isChangePassword: false
}

export const InitialUserEditErrors = {
    name: '',
    email: '',
    roles: '',
    password: '',
    new_password: ''
}

export enum TabIds {
    UsersAndRoles = 'UsersAndRoles',
    Profile = 'Profile',
    AdAccounts = 'AdAccounts'
}

export const SettingsNames = {
    profile: 'Profile',
    company: 'Company profile',
    adAccounts: 'Advertising Accounts',
    communications: 'Communications',
    usersRoles: 'Users & Roles',
    billing: 'Billing',
}


export const SettingsIds = {
    profile: 'user-profile',
    company: 'company',
    adAccounts: 'ad-accounts',
    communications: 'communications',
    usersRoles: 'users-roles',
    billing: 'billing',
}

export const getSettingsList = ({
    isEvent,
    isAdmin
}: { isEvent?: boolean, isAdmin?: boolean }) => [
        {
            id: SettingsIds.profile,
            label: SettingsNames.profile,
        },
        // {
        //     id: SettingsIds.company,
        //     label: SettingsNames.company,
        // },
        isEvent || isAdmin ? {
            id: SettingsIds.adAccounts,
            label: SettingsNames.adAccounts,
        } : null,
        // {
        //     id: SettingsIds.communications,
        //     label: SettingsNames.communications,
        // },
        !isAdmin ? {
            id: SettingsIds.usersRoles,
            label: SettingsNames.usersRoles,
        } : null,
        isEvent ? {
            id: SettingsIds.billing,
            label: SettingsNames.billing,
            children: [
                {
                    id: TabKeys.RevenueReports,
                    label: TabNames.RevenueReports,
                },
                {
                    id: TabKeys.RevShareCommissions,
                    label: TabNames.RevShareCommissions,
                },
                {
                    id: TabKeys.Transactions,
                    label: TabNames.Transactions,
                },
                {
                    id: TabKeys.WithdrawalRequests,
                    label: TabNames.WithdrawalRequests,
                },
                {
                    id: TabKeys.BillingDetails,
                    label: TabNames.BillingDetails,
                },
                {
                    id: TabKeys.Documents,
                    label: TabNames.Documents,
                },
            ]
        } : null,
    ]
