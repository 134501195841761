import React from 'react'

const XAdsIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#F2F3F5" />
            <path d="M12.657 13.2002L21.4599 25.1138L12.6016 34.8002H14.5954L22.351 26.3194L28.617 34.8002H35.4016L26.1033 22.2166L34.3486 13.2002H32.3548L25.2125 21.0106L19.4416 13.2002H12.657ZM15.589 14.6866H18.7058L32.4692 33.3138H29.3524L15.589 14.6866Z" fill="#252628" />
        </svg>
    )
}

export default XAdsIcon