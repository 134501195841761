import React, { useState } from "react";
import {AudienceCatalogItem} from "@store/type-event/audiences/models.ts";
import { MAX_EVENTS_LENGTH} from "@pages/EventRolePages/AudiencesEventPage/constants.ts";
import Avatar from "@components/Avatar";

const EventsColumn = ({ events }: { events: AudienceCatalogItem['events'] }) => {
    const [isHidden, setIsHidden] = useState(true)
    return (
        <>
            <div className="audiences-event-page__audiences__tags">
                {events?.length > MAX_EVENTS_LENGTH && isHidden ? (
                    <>
                    {events
                        ?.slice(0, MAX_EVENTS_LENGTH)
                        ?.map((event) => (
                            <div key={event.id} className="audiences-event-page__audiences__tag flex-start gap-6">
                                <Avatar url={event.logo} bordered/>
                                <div className="audiences-event-page__audiences__event-name">{event.title}</div>
                            </div>
                        ))}
                    {isHidden && (
                        <div key='link' onClick={() => setIsHidden(false)}
                             className="audiences-event-page__audiences__link text-link cursor-pointer">
                            +{events.slice(MAX_EVENTS_LENGTH).length} event{events.slice(MAX_EVENTS_LENGTH).length > 1 ? 's' : ''}
                        </div>
                )}
            </>
            ) : (
            <>
                {events?.map((event) => (
                    <div key={event.id} className="audiences-event-page__audiences__tag flex-start gap-6">
                        <Avatar url={event.logo} bordered />
                        <div>{event.title}</div>
                    </div>
                ))}
            </>
                )}
            </div>
        </>
    )
}

export default EventsColumn