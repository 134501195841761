import React from 'react';
import './styles.scss';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FilterByEnumItem } from '@store/main/enums/models';

interface FooterInvitationsProps {
    selectedCount: number;
    tagsCtaData: FilterByEnumItem[];
    onCtaClick: (data: FilterByEnumItem) => void;
}

const FooterInvitations: React.FC<FooterInvitationsProps> = ({
    selectedCount,
    tagsCtaData,
    onCtaClick
}) => {

    const ctaRender = (data: FilterByEnumItem) => {

        switch (data.id.toString()) {
            case '1': // Handle '1' as a string
                return (
                    <button
                        key={data.id}
                        className='no-style cta sent flex-center mr-16'
                        onClick={() => onCtaClick(data)}
                    >
                        <CheckOutlined style={{ color: '#FFF', marginRight: '6px' }} />
                        <span>{data.translate.toUpperCase()}</span>
                    </button>
                );
            case '2': // Handle '2' as a string
                return (
                    <button
                        key={data.id}
                        className='no-style cta sent flex-center mr-6'
                        onClick={() => onCtaClick(data)}
                    >
                        <CloseOutlined style={{ color: '#FFF', marginRight: '6px' }} />
                        <span>{data.translate.toUpperCase()}</span>
                    </button>
                );
            default:
                return null;
        }
    };

    return (
        <footer className='footer-invitations-ids'>
            <div className='footer-invitations-ids__main-controls'>
                <p className='footer-invitations-ids__counter'>
                    {selectedCount} items
                </p>
            </div>
            <aside className='flex'>
                {tagsCtaData.map(ctaRender)}
            </aside>
        </footer>
    );
}

export default FooterInvitations;
