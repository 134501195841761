const LoomIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_18748_1500)">
                <path
                    d="M14.5348 4.41696L4.38658 3.69817C4.29479 3.69162 4.20267 3.70488 4.11646 3.73705C4.03025 3.76921 3.95195 3.81953 3.88689 3.8846C3.82182 3.94966 3.7715 4.02796 3.73934 4.11417C3.70717 4.20038 3.69391 4.2925 3.70046 4.38429L4.42067 14.5311C4.4228 14.5617 4.4337 14.5911 4.45205 14.6157C4.4704 14.6403 4.49543 14.6591 4.52417 14.6699C4.55291 14.6807 4.58415 14.683 4.61415 14.6765C4.64416 14.67 4.67167 14.6551 4.69341 14.6334L5.84404 13.4828C5.90938 13.4174 5.94347 13.3265 5.93779 13.2328L5.46334 6.53923L13.4623 14.5382C13.5248 14.6007 13.6271 14.6007 13.6896 14.5382L14.5419 13.6859C14.6044 13.6234 14.6044 13.5211 14.5419 13.4586L6.54294 5.45963L13.2365 5.93408C13.3288 5.94118 13.4197 5.90709 13.4865 5.84033L14.6371 4.6897C14.7337 4.5931 14.6726 4.4269 14.5348 4.41696Z"
                    fill="#57585A"
                />
            </g>
            <defs>
                <clipPath id="clip0_18748_1500">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LoomIcon
