import { Drawer, Badge, TableProps } from "antd";
import type { ColumnsType } from 'antd/es/table';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { formatByCurrency, formatDateToCommonFormat } from "@shared/utils.ts";
import { useGetUserOrganizerBillingTransactionsQuery } from '@store/type-event/billing/billing.api.ts';
import { TransactionsFiltersType } from "@pages/EventRolePages/Billing/types.ts";
import {
    initialTransactionsFilters, MINUS_SIGN, PLUS_SIGN, TabNames, transactionsSortFields,
} from "@pages/EventRolePages/Billing/constants.ts";
import {
    TransactionItem, UserBillingOrganizerItem,
} from "@store/type-event/billing/models.ts";
import Avatar from "@components/Avatar";
import { CloseOutlined } from "@ant-design/icons";
import { useBreakpoints } from "@hooks/browser";
import TransactionsFilters from "@pages/EventRolePages/Billing/components/TransactionsFilters.tsx";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api.ts";
import TotalNumbers from './TotalNumbers.tsx';
import emptyTransactions from "@assets/png/emptyTransactions.png";
import { useParams } from "react-router-dom";
import FilterButtonMobileIcon from "@assets/buttons/mobile/FilterButtonMobileIcon.tsx";
import MobileDrawer from "@components/mobile/Drawer";
import InfiniteScrollTable from "@components/InfiniteScrollTable/InfiniteScrollTable.tsx";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import { Orders } from "@shared/constants.ts";

const TransactionsTable = () => {
    const isMd = useBreakpoints().md

    const { billingId = '' } = useParams()

    const [filters, setFilters] = useState<TransactionsFiltersType>(initialTransactionsFilters)
    const [isOpenFilter, setOpenFilter] = useState(false)
    const [transactionsData, setTransactionsData] = useState([])

    const { data: transactionsRes, isLoading: isTransactionsLoading, isFetching: isTransactionsFetching } = useGetUserOrganizerBillingTransactionsQuery({ ...filters, billingId }, { skip: !filters?.dateFrom })
    const { data: typesRes, isLoading: isTypesLoading } = useGetFiltersByEnumQuery({ name: 'transaction_type' })

    const setInitialFilters = useCallback(() => {
        const today = dayjs();
        const thirtyDaysAgo = dayjs().subtract(30, 'day');
        const todayFormatted = today.format('YYYY-MM-DD');
        const thirtyDaysAgoFormatted = thirtyDaysAgo.format('YYYY-MM-DD');
        setFilters({
            ...initialTransactionsFilters,
            dateFrom: thirtyDaysAgoFormatted,
            dateTo: todayFormatted
        })
    }, [])

    useEffect(() => {
        setInitialFilters()
    }, []);

    useEffect(() => {
        setTransactionsData((prevTransactionsData) => {
            const from = transactionsRes?.meta?.from
            if (from && prevTransactionsData.length < from) {
                return [...(prevTransactionsData || []), ...(transactionsRes?.data || [])]
            }
            return transactionsRes?.data || []
        })
    }, [transactionsRes])

    const mappedTypes = useMemo(() => {
        return typesRes?.data?.reduce((acc, next) => {
            if (next.id) {
                acc[next.id] = next.translate
            }
            return acc
        }, {} as Record<string, string>)
    }, [typesRes?.data])

    const columns: ColumnsType = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 200,
            render: (date: TransactionItem['date']) => {
                return (
                    <span
                        className="organizer-billing-page__reports__date"
                    >
                        {formatDateToCommonFormat(date?.created_at)}
                    </span>
                )
            }
        },
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
            width: 305,
            render: (event: TransactionItem['event']) => {
                return (
                    event ? (
                        <div className="flex items-center gap-6">
                            <Avatar
                                size='24'
                                url={event?.logo || ''}
                                bordered
                            />
                            <div className="organizer-billing-page__reports__event">
                                {event?.name}
                            </div>
                        </div>
                    ) : null
                )
            }
        },
        {
            title: 'Transaction type',
            dataIndex: 'type',
            key: 'type',
            width: 305,
            // hidden: !filters?.group_by_partner,
            render: (type: TransactionItem['type']) => (
                <span className="organizer-billing-page__reports__date">
                    {mappedTypes?.[type?.id] || ''}
                </span>
            ),
            sorter: true
        },
        {
            title: 'Amount',
            dataIndex: 'transaction',
            key: 'cost',
            align: 'right',
            width: 200,
            render: (transaction: TransactionItem['transaction'], record: TransactionItem) => {
                const isPlus = record?.type?.sign > 0
                return (
                    <span className={`organizer-billing-page__reports__number ${isPlus ? 'organizer-billing-page__transactions__plus' : 'organizer-billing-page__transactions__minus'}`}>
                        {isPlus ? PLUS_SIGN : MINUS_SIGN}
                        {formatByCurrency(transaction.amount || 0, transaction.currency)}
                    </span>
                )
            },
            sorter: true
        },
        {
            title: 'Comment',
            dataIndex: 'transaction',
            key: 'comment',
            width: 305,
            render: (transaction: TransactionItem['transaction']) => (
                <span className="organizer-billing-page__reports__number">
                    {transaction?.description}
                </span>
            )
        }
    ];

    const isFilterApplied = Boolean(filters?.events?.length ||
        (filters?.dateFrom && filters.dateTo) ||
        filters?.types?.length)

    const isLoading = isTransactionsLoading || isTransactionsFetching

    const onNextPage = useCallback(() => {
        setFilters((prev) => {
            return ({ ...prev, page: (transactionsRes?.meta?.current_page || INIT_PAGE) + 1 })
        })
    }, [transactionsRes])

    const handleChangeTable: TableProps<UserBillingOrganizerItem>['onChange'] = useCallback((pagination, _, sorter) => {
        const { columnKey, order } = sorter as { columnKey: string, order: string }
        const newOrder = Orders[order]
        const newField = transactionsSortFields[columnKey]
        if (order) {
            setFilters((prev) => {
                return ({ ...prev, sortField: newField, sortDirection: newOrder })
            })
        } else {
            setFilters((prev) => {
                return ({ ...prev, sortField: undefined, sortDirection: undefined })
            })
        }
    }, []);

    return (
        <div className={`organizer-billing-page__tab-inner ${!transactionsData?.length ? 'organizer-billing-page__tab-inner-empty' : ''}`}>
            <TotalNumbers />
            {isMd ? (
                <div className="organizer-billing-page__title">{TabNames.Transactions}</div>
            ) : (
                <div className="organizer-billing-page__revenue-reports-title">
                    <div className="organizer-billing-page__title">{TabNames.Transactions}</div>
                    <button
                        className='no-style organizer-billing-page__reports__filter-button'
                        onClick={() => setOpenFilter(true)}
                    >
                        <Badge
                            dot={isFilterApplied}
                            status="processing"

                        >
                            <FilterButtonMobileIcon />
                        </Badge>
                    </button>
                </div>
            )}
            <TransactionsFilters
                filters={filters}
                setInitialFilters={setInitialFilters}
                setFilters={setFilters}
                setOpenFilter={setOpenFilter}
                isTypesLoading={isTypesLoading}
                typesOptions={typesRes?.data}
            />
            {!isLoading && !transactionsData?.length ? (
                <div className='ad-accounts-page__empty-credentials'>
                    <img src={emptyTransactions} alt=' emptyTransactions' />
                    <span><b>You have no transactions</b></span>
                    <span>It will be displayed here when you have your first transactions</span>
                </div>
            ) : (
                <div className="mb-24">
                    <InfiniteScrollTable
                        isLastPage={transactionsRes?.meta?.last_page === transactionsRes?.meta?.current_page}
                        onNextPage={onNextPage}
                        isFirstPage
                        bordered
                        className={`organizer-billing-page__transactions__table ${transactionsData?.length ? '' : 'organizer-billing-page__table-empty'} bordered-table`}
                        dataSource={transactionsData}
                        columns={columns}
                        rowKey="id"
                        loading={isTransactionsLoading || isTransactionsFetching}
                        onChange={handleChangeTable}
                        locale={{
                            emptyText: isTransactionsLoading || isTransactionsFetching ? ' ' : (
                                <div className='ad-accounts-page__empty-credentials'>
                                    <img src={emptyTransactions} alt=' emptyTransactions'/>
                                    <span><b>You have no transactions</b></span>
                                    <span>It will be displayed here when you have your first transactions</span>
                                </div>
                            )
                        }}
                    />
                </div>
            )}
            {isMd ? (
                <Drawer
                    title="Filters"
                    closeIcon={false}
                    placement={isMd ? "right" : 'bottom'}
                    open={isOpenFilter}
                    autoFocus={false}
                    mask={false}
                    onClose={() => setOpenFilter(false)}
                    maskStyle={{'opacity': 0, 'top': '62px'}}
                    rootStyle={{ 'top': '62px', background: 'rgba(26, 36, 64, 0.05)' }}
                    rootClassName="organizer-billing-page__drawer"
                    contentWrapperStyle={{boxShadow: 'none', borderLeft: '2px solid #f7f7f7'}}
                    extra={(
                        <CloseOutlined
                            onClick={() => setOpenFilter(false)}
                        />
                    )}
                >
                    <TransactionsFilters
                        isDrawer
                        filters={filters}
                        setInitialFilters={setInitialFilters}
                        setFilters={setFilters}
                        setOpenFilter={setOpenFilter}
                        isTypesLoading={isTypesLoading}
                        typesOptions={typesRes?.data}
                    />
                </Drawer>
            ) : (
                <MobileDrawer
                    title='Filters'
                    onClose={() => setOpenFilter(false)}
                    onCancel={setInitialFilters}
                    isOpen={isOpenFilter}
                    onOk={() => setOpenFilter(false)}
                    applyButtonText="Apply"
                    resetButtonText="Reset"
                    propClassNames={{
                        footer: 'organizer-billing-page__filter-drawer-footer'
                    }}
                >
                    <TransactionsFilters
                        isDrawer
                        filters={filters}
                        setInitialFilters={setInitialFilters}
                        setFilters={setFilters}
                        setOpenFilter={setOpenFilter}
                        isTypesLoading={isTypesLoading}
                        typesOptions={typesRes?.data}
                    />
                </MobileDrawer>
            )}
        </div>
    )
}

export default TransactionsTable
