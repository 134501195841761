import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import InfoIcon from '@icons/header/InfoIcon'
import { HELP_CENTER_LINK } from "@shared/constants.ts";
import {useAuth} from "@contexts/AuthContext.tsx";
import {isHubSpotScriptLoaded, toggleHubspotChat} from "@components/HubSpotButton/helpers.ts";
import ArrowIcon from '@assets/ArrowIcon';
import './styles.scss'
import { useContext } from "react";
import { TourContext } from "@contexts/TourContext.tsx";

const InfoDropdown = ({ fillColor }: { fillColor?: string }) => {
    const { isHubspotChatOpen, setIsHubspotChatOpen } = useAuth()
    const { user } = useAuth()

    const isEvent = user?.organization?.type === 'event'

    const {
        setIsPlayerOpened,
    } = useContext(TourContext);

    const onOpenSupportChat = () => {
        if (isHubSpotScriptLoaded()) {
            const isOpenClass = document.querySelector('.hs-messages-widget-open')
            const isOpen = Boolean(isOpenClass || isHubspotChatOpen)
            toggleHubspotChat(isOpen)
            setIsHubspotChatOpen(!isOpen);
        } else {
            console.error('HubSpot Conversations not loaded');
        }
    }

    const onHelpCenterClick = () => {
        window.open(HELP_CENTER_LINK, '_blank')
    }

    const onFirstOverviewClick = () => {
        setIsPlayerOpened(true)
    }

    const items: MenuProps['items'] = [
        {
            label: <span>Help center</span>,
            key: '0',
            onClick: onHelpCenterClick
        },
        ...(isEvent ? [] : [
            {
                label: <span>Video tour</span>,
                key: '2',
                icon: <ArrowIcon fillColor="#1B1C1E" />,
                children: [
                    {
                        label: <span>First overview of the platform</span>,
                        key: '3',
                        onClick: onFirstOverviewClick
                    },
                ]
            }
        ]),
        {
            onClick: onOpenSupportChat,
            label: <span>Support chat</span>,
            key: '1',
        },
    ];

    return (
        <Dropdown
            overlayClassName="info-dropdown"
            overlayStyle={{ width: '149px' }}
            menu={{ items }}
            trigger={['click']}
        >
            <div className='header-item header-item-info'>
                <InfoIcon
                    fillColor={fillColor}
                />
            </div>
        </Dropdown>
    )
};

export default InfoDropdown;
