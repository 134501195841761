// https://boost.allnetru.dev/api/documentation/partner#/Events
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetPartnerAudiencesResponse, GetPartnerEventByIdDataItem, GetPartnerEventByIdResponse, GetPartnerEventResponse, GetPartnerEventStatusesResponse, LeadsRequest, LeadsResponse, PartnerEventFilter } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const partner_event = `partner_event`;

export const partnerEvent = createApi({
    reducerPath: partner_event,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        partner_event
    ],
    endpoints: build => ({
        getPartnerEvent: build.query<GetPartnerEventResponse, {
            filters?: PartnerEventFilter | null,
        }>({
            query: ({ filters }) => {
                console.log('filters', filters)
                const params = new URLSearchParams();

                if (Array.isArray(filters?.statuses)) {
                    filters.statuses.forEach(id => {
                        params.append('filters[statuses][]', id.toString());
                    });
                }

                if (filters?.search && filters.search.length > 2) {
                    params.append('filters[search]', filters.search);
                }

                if (filters?.page) {
                    params.append('page', filters.page.toString());
                }

                if (filters?.date?.from) {
                    params.append('filters[date][from]', `${filters.date.from}`);
                }

                if (filters?.date?.to) {
                    params.append('filters[date][to]', `${filters.date.to}`);
                }

                const queryString = params.toString();

                return {
                    url: `/partner/events${queryString ? "?" + queryString : ''}`,
                    method: 'GET', // Specify the HTTP method
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                };
            },
            providesTags: [partner_event],
        }),
        getPartnerEventById: build.query<GetPartnerEventByIdResponse, { id: string }>({
            query: ({ id }) => ({
                url: `/partner/events/${id}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            providesTags: [partner_event],
        }),
        getPartnerLeads: build.query<LeadsResponse, LeadsRequest>({
            query: ({ filters, page }) => {
                const params = new URLSearchParams();

                if (Array.isArray(filters?.campaigns)) {
                    filters.campaigns.forEach(id => {
                        params.append('filters[campaigns][]', id.toString());
                    });
                }
                if (Array.isArray(filters?.types)) {
                    filters.types.forEach(id => {
                        params.append('filters[types][]', id.toString());
                    });
                }

                if (filters?.search && filters.search.length > 2) {
                    params.append('filters[search]', filters.search);
                }
                if (page) {
                    params.append('page', page.toString());
                }
                const queryString = params.toString();

                return {
                    url: `/partner/leads${queryString ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [partner_event],
        }),

        patchPartnerEventStatusById: build.mutation<{ data: GetPartnerEventByIdDataItem }, { event: number, status: string }>({
            query: ({ event, status }) => ({
                url: `/partner/events/${event}/status/${status}`,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                providesTags: [partner_event],
            }),
        }),
        getParnerAudiences: build.query<GetPartnerAudiencesResponse, { eventId: string, page?: number }>({
            query: ({ eventId, page }) => {
                const params = new URLSearchParams();

                if (page) {
                    params.append('page', page.toString());
                }

                const queryString = params.toString();
                return {
                    url: `/partner/events/${eventId}/audiences${queryString ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                };
            },
            providesTags: [partner_event],
        }),

        // NOT IN USE FOR THE MOMENT
        // patchPartnerEventUnarchiveById: build.mutation<{ data: GetPartnerEventByIdDataItem }, { event: number, status: number }>({
        //     query: ({ event }) => ({
        //         url: `/partner/events/${event}/status/unarchive`,
        //         method: 'PATCH',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Accept': 'application/json',
        //         },
        //         providesTags: [partner_event],
        //     }),
        // }),
    }),
});

export const {
    useGetPartnerEventQuery,
    useGetPartnerEventByIdQuery,
    usePatchPartnerEventStatusByIdMutation,
    useGetParnerAudiencesQuery,
    useGetPartnerLeadsQuery
} = partnerEvent;