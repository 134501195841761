import React, {useEffect} from 'react';
import '../PrivacyPolicyPage/styles.scss'

const PrivacyPolicy: React.FC = () => {
    useEffect(() => {
        const script = document.createElement('script');
        const tag = document.getElementsByTagName('script')[0];
        script.src = 'https://cdn.iubenda.com/iubenda.js';
        if (tag.parentNode) {
            tag.parentNode.insertBefore(script, tag);
        }
    }, []);
    return (
        <div>
            <article className="privacy-policy" style={{
                border: "1px solid #c4c4c4",
                borderRadius: "6px",
                marginTop: "24px",
            }}>
                <h1>Privacy Policy Scope</h1>

                This Privacy Policy applies to the following domains and services:

                <ul>
                    <li>boost.express</li>
                    <li>app.boost.express</li>
                    <li>OAuth Consent Screen Application for Google Ads Access (used to grant access to Google Ads services)</li>
                </ul>
                
                <p>By using any of the above domains or services, you agree to the terms outlined in this Privacy Policy.</p>
            </article>

            <article className="privacy-policy" style={{
                border: "1px solid #c4c4c4",
                borderRadius: "6px",
                marginTop: "24px",
            }}>
                <h1>OAuth Consent Screen Application for Google Ads Access Scope</h1>

                <p>By using OAuth Consent Screen Application for Google Ads Access, you agree to the terms outlined in this Privacy Policy.</p>
            </article>

            <article className="privacy-policy">
At <b>boost.express</b>, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and protect the personal information you provide when using OAuth Consent Screen Application for Google Ads Access. This policy also explains your rights regarding your data and how you can exercise them.

<h2>1. Information We Collect</h2>
<p>We collect personal information from users solely for the purpose of providing and improving the functionality of OAuth Consent Screen Application for Google Ads Access. This information may include:</p>
<ul>
    <li><b>Google Account Information:</b> When you log into the application using your Google account, we collect certain information such as your name, email address, and profile picture.</li>
    <li><b>Usage Data:</b> We may collect data about how you interact with the application to help us improve its features and user experience.</li>
</ul>
 
<h2>2. How We Use Your Information</h2>
<p>The personal information we collect is used exclusively for the following purposes:</p>
<ul>
    <li>To provide the core functionality of the application, including allowing users to access and manage their accounts.</li>
    <li>To improve the performance and functionality of the application by analyzing usage patterns and implementing new features.</li>
    <li>To communicate with you about updates, new features, and other information related to the application.</li>
</ul>

<p>We do not use your Google user data for any purposes other than providing or improving the functionality of the application. We do not use your data for marketing or advertising purposes.</p>

<h2>3. Data Sharing and Transfers</h2>
<p>We do not transfer or share your Google user data with any third parties, except in the following cases:</p>
<ul>
    <li><b>Service Providers:</b> We may share data with trusted third-party service providers who assist us in operating the application or providing related services. These third parties are only authorized to use your data as necessary to provide these services and are prohibited from using it for any other purpose.</li>
    <li><b>Legal Compliance:</b> We may share your data if required by law, regulation, or court order, but only to the extent necessary to comply with those legal obligations.</li>
</ul>
 
<p>We will never sell, lease, or otherwise transfer your data to third parties for purposes unrelated to the operation or improvement of the application.</p>

<h2>4. Data Security</h2>

<p>We take data security seriously and implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, or misuse. We use encryption and other security protocols to safeguard the personal information we collect.</p>

<h2>5. Your Rights</h2>
<p>You have the right to access, update, and delete your personal information at any time. If you wish to exercise these rights, please contact us at admin@boost.express. We will respond to your request as soon as possible.</p>

<h2>6. Changes to This Privacy Policy</h2>
<p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. If we make significant changes, we will notify you through the application or via email.</p>
            </article>

            <article className="privacy-policy" style={{
                border: "1px solid #c4c4c4",
                borderRadius: "6px",
                marginTop: "24px",
            }}>
                <h1>boost.express and app.boost.express Scope</h1>

                <p>By using any of the above domains, you agree to the terms outlined in this Privacy Policy.</p>
            </article>

            <div className="privacy-policy__page">
                <a href="https://www.iubenda.com/privacy-policy/16978737"
                className="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
                title="Privacy Policy">Privacy Policy</a>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
