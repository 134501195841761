import {
    OptionIds,
    OptionName,
    SERVICES3,
    SERVICES4
} from "@pages/PartnerRolePages/LandingPageConstructor/constants.tsx";

export const getSettingItemLabel = (id?: string, name?: string) => {
    if (id && name) {
        if (id === OptionIds.CornerImagesComponent && (name === SERVICES3 || name === SERVICES4)) {
            return OptionName.IconsComponent
        }
        return OptionName[id]
    }
    return ''
}