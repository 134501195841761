import React, { useState, useCallback, useEffect } from "react";
import { EventBilling } from "@store/type-event/billing/models.ts";
import {
    initialEventBillingErrorsValues,
    initialEventBillingValues,
} from "@pages/EventRolePages/Billing/constants.ts";
import { EventBillingFormErrors } from "@pages/EventRolePages/Billing/types.ts";
import { usePostBillingMutation, usePatchBillingByIdMutation, useGetBillingByIdQuery } from "@store/type-event/billing/billing.api.ts";
import { PatchBillingInfoBodyRequest } from "@store/main/billing/models.ts";
import { message, notification } from "antd";
import { ErrorDataResponse, errorsToFormAdapter } from "@shared/utils.ts";
import { useParams } from "react-router-dom";
import { transformToFlatObject, transformToNestedObject } from "@pages/EventRolePages/Billing/helpers.ts";

type EventBillingForm = {
    form: EventBilling
    validationErrors: EventBillingFormErrors
    onFormChange: (value: Partial<PatchBillingInfoBodyRequest>) => void
    isLoading: boolean
    isBillingLoading: boolean
    isFormChanged: boolean
    onBillingInfoUpdate: () => void
    onClearForm: () => void
    onResetForm: () => void
}

type Params = {
    onSuccess?: (newBillingId: number, newBillingName?: string) => void
    isCreate?: boolean
}

const useEventBillingForm = (params?: Params): EventBillingForm => {
    const { onSuccess, isCreate } = params || {}

    const { billingId } = useParams()

    const [form, setForm] = useState<EventBilling>(initialEventBillingValues)
    const [validationErrors, setValidationErrors] = useState<EventBillingFormErrors>(initialEventBillingErrorsValues as EventBillingFormErrors)
    const [isLoading, setIsLoading] = useState(false)
    const [isFormChanged, setIsFormChanged] = useState(false)

    const [postBilling] = usePostBillingMutation()
    const [patchBilling] = usePatchBillingByIdMutation()

    const { data: billingRes, isLoading: isBillingLoading, isFetching: isBillingFetching } = useGetBillingByIdQuery({ id: billingId || '' }, { skip: !billingId || isCreate })

    const onResetForm = useCallback(() => {
        if (billingId && !isCreate) {
            const transformedBillingRes = transformToFlatObject(billingRes?.data || {})
            setForm(transformedBillingRes as EventBilling)
        }
    }, [billingRes, billingId, isCreate])

    useEffect(() => {
        onResetForm()
    }, [billingRes, billingId, isCreate, onResetForm]);

    const onFormChange = useCallback((value: Partial<PatchBillingInfoBodyRequest>) => {
        if (!isFormChanged) {
            setIsFormChanged(true)
        }
        const key = Object.keys(value || {})?.[0]
        if (key) {
            setValidationErrors((prevErrors) => ({...prevErrors, [key]: ''}))
        }
        setForm(prev => ({ ...prev, ...value }))
    }, [isFormChanged])

    const onBillingInfoUpdate = useCallback(async () => {
        setIsLoading(true)
        const transformedForm = transformToNestedObject(form || {})
        try {
            const data = {
                bank_details: transformedForm.bank_details,
                country: form?.country,
                city: form?.city,
                name: form?.name,
                company_name: form?.company_name,
                email: form?.email,
                address: form?.address,
                tax_code: form?.tax_code,
                postal_code: form?.postal_code,
                currency: form?.currency,
            }
            const response = isCreate ? await postBilling(data) : await patchBilling({ data, id: billingId })
            if ('data' in response) {
                setIsLoading(false)
                const newBillingId = response?.data?.data?.id
                const newBillingName = response?.data?.data?.name
                if (onSuccess && newBillingId) {
                    onSuccess(newBillingId, newBillingName)
                }

                notification.open({
                    message: (
                        <span className='moderation-table__notification__message'>
                            <b>
                                {form.name}
                            </b>
                            {' '}
                            has been successfully {isCreate ? 'created' : 'saved'}
                        </span>
                    ),
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });

                if (isCreate) {
                    setForm(initialEventBillingValues)
                }
                document.body.classList.remove('no-scroll');
            }
            if ('error' in response) {
                const errors = errorsToFormAdapter(response as unknown as ErrorDataResponse)
                setValidationErrors(errors as unknown as EventBillingFormErrors)
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            void message.open({
                type: 'error',
                content: 'Billing creating error',
            })
        }
    }, [form, onSuccess, postBilling])

    const onClearForm = useCallback(() => {
        setForm(initialEventBillingValues)
    }, [])

    return {
        form,
        validationErrors,
        onFormChange,
        isLoading,
        onBillingInfoUpdate,
        onClearForm,
        isBillingLoading: isBillingLoading || isBillingFetching,
        onResetForm,
        isFormChanged
    }
}

export default useEventBillingForm
