import { campaignInputMaxLength } from '@pages/PartnerRolePages/Campaigns/components/CampaignForm/constants'
import { CampaignEditorBody } from '@store/type-partner/campaigns/models'

export const enableSubmit = (formValues: CampaignEditorBody) => {
    const {
        title,
        ads: [
            {
                assets: {
                    business_name,
                    title: assetTitles,
                    short_title: assetShortTitles,
                    description: assetDescriptions,
                },
            },
        ],
    } = formValues;

    const okTitleLength = title && title.length <= campaignInputMaxLength.title;
    const okBusinessNameLength = business_name && business_name?.[0]?.value?.length <= campaignInputMaxLength.business_name;
    const okAssetTitlesLength = assetTitles && assetTitles.every((title) => title && title.value && title.value.length <= campaignInputMaxLength.asset_title);
    const okAssetShortTitlesLength = assetShortTitles && assetShortTitles.every((item) => item && item.value && item.value.length <= campaignInputMaxLength.asset_short_title);
    const okAssetDescriptionsLength = assetDescriptions && assetDescriptions.every((item) => item && item.value && item.value.length <= campaignInputMaxLength.asset_description);
    const result = okTitleLength && okBusinessNameLength && okAssetTitlesLength && okAssetShortTitlesLength && okAssetDescriptionsLength;
    return result
}
