import StatusDescription from '@components/StatusDescription';
import { CampaignStatuses } from '@shared/constants';
import { message, Switch } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { formatByCurrency, formatKNumber } from "@shared/utils";
import { CampaignDataItem } from '@store/type-partner/campaigns/models';
import ErrorStatus from "@pages/PartnerRolePages/Campaigns/components/ErrorStatus";
import DropDownSettings from "@pages/PartnerRolePages/Campaigns/components/DropDownSettings";
import TableCellSettingsIcon from "@assets/TableCellSettingsIcon";
import ErrorStatusModal from "@pages/PartnerRolePages/Campaigns/components/ErrorStatusModal";
import { useBreakpoints } from '@hooks/browser';
import { usePatchPartnerCampaignsStatusByIdMutation } from "@store/type-partner/campaigns/campaigns.api.ts";
import InfiniteScrollTable from "@components/InfiniteScrollTable/InfiniteScrollTable.tsx";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import type { ColumnsType } from "antd/es/table";
import { useGetUserBillingPaymentsQuery } from "@store/main/payment/payment.api.ts";
import AddPaymentDrawer from "@pages/PartnerRolePages/Billing/drawers/AddPayment";
import AddPaymentDrawerMobile from "@pages/PartnerRolePages/Billing/mobile/AddPayment";

interface EventCampaignsTableProps {
    campaigns: CampaignDataItem[]
    setCampaignsList: React.Dispatch<React.SetStateAction<CampaignDataItem[]>>
    isLastPage: boolean
    onNextPage: () => void
    page: number
    isLoading: boolean
}
const EventCampaignsTable: FC<EventCampaignsTableProps> = ({
    campaigns,
    setCampaignsList,
    isLastPage,
    onNextPage,
    page,
    isLoading
}) => {
    const isDesktop = useBreakpoints().md
    const { id: eventId } = useParams()
    const [errorStatusModalData, setErrorStatusModalData] = useState<{ logs: CampaignDataItem['logs'], campaignTitle: string } | null>(null)
    const [eventIdUpdating, setEventIdUpdating] = useState<string | null>(null)
    const [isAddPaymentDrawerOpen, setIsAddPaymentDrawerOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState<CampaignDataItem | null>(null)

    const [patchPartnerCampaignsStatusById] = usePatchPartnerCampaignsStatusByIdMutation()
    const { data: paymentMethodsResponse } = useGetUserBillingPaymentsQuery()

    const onErrorStatusModalOpen = ({ campaignTitle, logs }: { logs: CampaignDataItem['logs'], campaignTitle: string }) => {
        if (!logs) {
            return
        }
        const errorLogs = logs.filter(log => log.type.name !== 'success');
        setErrorStatusModalData({ campaignTitle, logs: errorLogs });
    }

    const onErrorStatusModalClose = () => {
        setErrorStatusModalData(null)
    }

    const onCampaignStatusChange = async ({ status, campaignId }: { status: number, campaignId: string }) => {

        if (!status) {
            return message.open({
                type: 'error',
                content: 'Status Update Error',
            });
        }
        setEventIdUpdating(campaignId)
        try {
            const response = await patchPartnerCampaignsStatusById({
                campaignIds: [campaignId],
                status
            });

            if ('data' in response) {
                setCampaignsList((prev: CampaignDataItem[]) => prev.map(campaign =>
                    campaign.id === campaignId ? { ...campaign, status: { ...campaign.status, id: status } } : campaign
                ))
                setEventIdUpdating(null)
                return message.open({
                    type: 'success',
                    content: 'Status Updated',
                });
            }
            if ('error' in response) {
                return message.open({
                    type: 'error',
                    content: 'Status Update Error',
                });
            }
            void message.open({
                type: 'error',
                content: 'Status Update Error',
            });
        } catch (error) {
            setEventIdUpdating(null)
            void message.open({
                type: 'error',
                content: 'Status Update Error',
            });
        } finally {
            setEventIdUpdating(null)
        }
    }

    const handleChangeStatus = (row?: CampaignDataItem) => {
        const currentRow = row || selectedRow
        setIsAddPaymentDrawerOpen(false)
        if (currentRow) {
            void onCampaignStatusChange({ status: currentRow.status.id === 1 ? 2 : 1, campaignId: currentRow.id })
        }
    }

    const handleOnSwitchClick = (row: CampaignDataItem) => {
        const isPaymentMethodAdded = paymentMethodsResponse?.data?.length
        if (!isPaymentMethodAdded) {
            setIsAddPaymentDrawerOpen(true)
            setSelectedRow(row)
        } else {
            handleChangeStatus(row)
        }
    }

    const handleCloseAddPaymentDrawer = () => {
        setIsAddPaymentDrawerOpen(false)
    }

    const columns: ColumnsType = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            width: 56,
            render: (_: number, row: CampaignDataItem) => {
                return (
                    <DropDownSettings
                        campaignId={row.id}
                        status={row.status.id}
                        eventId={row.event?.id || ''}
                        campaignsList={campaigns}
                        setCampaignsList={setCampaignsList}
                        onCampaignStatusChange={() => void onCampaignStatusChange({ status: row.status.id === 1 ? 2 : 1, campaignId: row.id })}
                    >
                        <div className='cursor-pointer'>
                            <TableCellSettingsIcon />
                        </div>
                    </DropDownSettings>
                )
            }
        },
        {
            title: 'Campaign name',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            fixed: isDesktop ? 'left' : undefined,
            render: (name: string, row: CampaignDataItem) => {
                return (
                    <div className='flex items-center justify-space-between'>
                        {row.event?.id ? (
                            <div>
                                <Link
                                    className='no-style'
                                    to={`/events/edit/${eventId!}/campaign/${row.id}`}
                                >
                                    <span>{name}</span>
                                </Link>
                            </div>
                        ) : (
                            <div>
                                <span>{name}</span>
                            </div>
                        )}
                    </div>
                )
            }

        },
        {
            title: 'On/off',
            dataIndex: 'status',
            key: 'status',
            width: 77,
            render: (_: number, row: CampaignDataItem) => {
                return (
                    <Switch
                        loading={eventIdUpdating === row.id}
                        disabled={row.status.id === 0}
                        checked={row.status.id === 1}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onChange={() => handleOnSwitchClick(row)}
                    />
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (status: { id: string, name: string }, row: CampaignDataItem) => {
                const filteredWarnings = row?.warnings?.filter(({ translate }) => translate) || []
                const errorLogs = row?.logs?.filter(log => log.type.name !== 'success') || [];
                const statusName = errorLogs.length ? CampaignStatuses.error : status.name
                return (
                    <div className="flex">
                        <StatusDescription
                            status={statusName as CampaignStatuses}
                            warningTooltip={filteredWarnings.length && !errorLogs.length ? (
                                <div>{filteredWarnings.map(({ id, translate }) => <div key={id}>{translate}</div>)}</div>
                            ) : null}
                        />
                        {Boolean(errorLogs.length) && (
                            <ErrorStatus
                                onClick={() => onErrorStatusModalOpen({ logs: row.logs, campaignTitle: row.name })}
                            />
                        )}
                    </div>
                )
            }
        },
        {
            title: 'Spent',
            dataIndex: 'cost',
            key: 'cost',
            width: 100,
            render: (_: number, row: CampaignDataItem) => formatByCurrency(row.statistics?.cost || 0, row.statistics?.currency)
        },
        {
            title: 'Impressions',
            dataIndex: 'impressions',
            key: 'impressions',
            render: (_: number, row: CampaignDataItem) => formatKNumber(row.statistics?.impressions || 0)
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            render: (_: number, row: CampaignDataItem) => row?.statistics?.clicks || 0
        },
        {
            title: 'Average CPM',
            dataIndex: 'average_cpm',
            key: 'average_cpm',
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.average_cpm || 0, row.statistics?.currency)}</span>
        },
        {
            title: 'Average CPC',
            dataIndex: 'average_cpc',
            key: 'average_cpc',
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.average_cpc || 0, row.statistics?.currency)}</span>
        },
        {
            title: 'Conversions',
            dataIndex: 'conversions',
            key: 'conversions',
            render: (_: number, row: CampaignDataItem) => <span>{(row?.statistics?.conversions || 0).toFixed(2)}%</span>
        },
        {
            title: 'Cost Per Conversion',
            dataIndex: 'cost_per_conversion',
            key: 'cost_per_conversion',
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.conversions || 0, row.statistics?.currency)}</span>
        },
    ];

    return (
        <>
            <InfiniteScrollTable
                isLastPage={isLastPage}
                onNextPage={onNextPage}
                isFirstPage={page === INIT_PAGE}
                loading={isLoading}
                dataSource={campaigns}
                columns={columns}
                scroll={{ x: 1200 }}
                rowKey='id'
            />
            <ErrorStatusModal
                isOpen={Boolean(errorStatusModalData)}
                onClose={onErrorStatusModalClose}
                campaignTitle={errorStatusModalData?.campaignTitle || ''}
                logs={errorStatusModalData?.logs}
            />
            {isAddPaymentDrawerOpen && isDesktop
                ?
                <AddPaymentDrawer
                    isOpen={isAddPaymentDrawerOpen}
                    onClose={handleCloseAddPaymentDrawer}
                    isLoading={isLoading}
                    saveBtnText="Add payment & launch campaign"
                    onSuccessCallback={() => handleChangeStatus()}
                    isShowCancelBtn={false}
                    title="Add payment method"
                />
                :
                null}
            {isAddPaymentDrawerOpen && !isDesktop
                ?
                <AddPaymentDrawerMobile
                    isOpen={isAddPaymentDrawerOpen}
                    onClose={handleCloseAddPaymentDrawer}
                    isLoading={isLoading}
                    saveBtnText="Add payment & launch campaign"
                    onSuccessCallback={() => handleChangeStatus()}
                    isShowCancelBtn={false}
                    title="Add payment method"
                />
                :
                null}
        </>
    );
};

export default EventCampaignsTable;
