import React, {FC, useEffect, useState} from 'react';
import { Button, Form, Input, message, Modal, notification, Select } from 'antd';
import styles
    from "@pages/Onboarding/EventOnboarding/components/EventOnboardingViewManager/components/CompanyInfoForm/CompanyInfoForm.module.css";
import { AudienceItem, PostAudience, SegmentListItem } from "@store/type-event/audiences/models.ts";
import { ErrorDataResponse } from "@shared/utils.ts";
import { usePostAudienceMutation, usePatchAudienceByIdMutation } from '@store/type-event/audiences/audiences.api.ts';
import ArrowIcon from "@assets/ArrowIcon.tsx";

type Props = {
    isOpen: boolean
    onClose: () => void
    segments: SegmentListItem[]
    selectedAudience: AudienceItem | null
    refetch: () => void
}

const EditAudienceModal: FC<Props> = ({
    isOpen,
    selectedAudience,
    onClose,
    segments,
    refetch
}) => {
    const [isFormChanged, setIsFormChanged] = useState(false)

    const [postAudience , { isLoading: isPostLoading }] = usePostAudienceMutation()
    const [patchAudience, { isLoading: isPatchLoading }] = usePatchAudienceByIdMutation()

    const [formState, setFormState] = useState<PostAudience>({
        title: selectedAudience?.name?.title || '',
        description: selectedAudience?.name?.description || '',
        segments: selectedAudience?.segments?.map(({id}) => id) || []
    });
    const [errors, setErrors] = useState<Record<string, string>>({title: '', description: ''})

    const isSaveDisabled = !formState.description || !formState.title || !formState.segments.length
    useEffect(() => {
        setFormState({
            title: selectedAudience?.name?.title || '',
            description: selectedAudience?.name?.description || '',
            segments: selectedAudience?.segments?.map(({id}) => id) || []
        })
    }, [selectedAudience]);

    const handleClose = () => {
        setErrors({})
        onClose()
    }

    const handleOk = async () => {
            try {
                const response = selectedAudience ? await patchAudience({ data: formState, id: selectedAudience.id }) : await postAudience(formState)
                if ('data' in response) {
                    onClose()
                    refetch()
                    return notification.open({
                        message: <div>The audience <b>{formState.title}</b> has been successfully {selectedAudience ? 'saved' : 'created'}</div>,
                        placement: 'bottomLeft',
                        closeIcon: false,
                        type: 'success'
                    });
                }
                if ((response as ErrorDataResponse)?.error?.data?.errors) {
                    void message.open({
                        type: 'error',
                        content: (response as ErrorDataResponse)?.error?.data?.message as string || `Error ${selectedAudience ? 'updating' : 'creating'} audience`,
                    });
                    return setErrors((response as ErrorDataResponse).error.data.errors)
                }
                return message.open({
                    type: 'error',
                    content: `Error ${selectedAudience ? 'updating' : 'creating'} audience`,
                });
            } catch (error) {
                return message.open({
                    type: 'error',
                    content: `Error ${selectedAudience ? 'updating' : 'creating'} audience`,
                });
            }
    }

    const onFieldChange = (fieldName:string, value: string | number[]) => {
        setFormState(prev => ({ ...prev, [fieldName]: value }));
        setErrors(prev => ({ ...prev, [fieldName]: '' }));
        if (!isFormChanged) {
            setIsFormChanged(true)
        }
    };

    return (
        <Modal
            destroyOnClose
            title={selectedAudience ? 'Edit audience' : "Create audience"}
            open={isOpen}
            onCancel={handleClose}
            className="organizer-billing-page__withdrawal__modal"
            centered
            width={500}
            zIndex={1001}
            footer={(
                <div
                    className={'organizer-billing-page__withdrawal__modal__footer'}
                >
                    <Button
                        type="default"
                        size="large"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        disabled={isPatchLoading || isPostLoading || isSaveDisabled || (!isFormChanged && !!selectedAudience)}
                        type="primary"
                        loading={isPatchLoading || isPostLoading}
                        onClick={handleOk}
                    >
                        {selectedAudience ? 'Save' : 'Create'}
                    </Button>
                </div>
            )}
        >
            <Form
                layout={'vertical'}
            >
                <Form.Item
                    name={'title'}
                    label={<span>Audience name</span>}
                    htmlFor={'title'}
                    help={<span className="color-danger">{errors?.title ? errors.title : ''}</span>}
                    validateStatus={errors?.title ? 'error' : ''}
                >
                    <Input
                        id={"title"}
                        size={'large'}
                        defaultValue={formState.title}
                        onChange={(e) => onFieldChange("title",e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    htmlFor={'segments'}
                    label="Segments"
                    help={<span className="color-danger">{errors?.segments ? errors.segments : ''}</span>}
                    validateStatus={errors?.segments ? 'error' : ''}
                >
                    <Select
                        id={'segments'}
                        mode="tags"
                        maxTagCount="responsive"
                        placeholder='Select segments'
                        style={{ width: '100%' }}
                        allowClear
                        filterOption={false}
                        value={formState.segments}
                        onChange={(value) => onFieldChange('segments', value)}
                        fieldNames={{ label: 'title', value: 'id' }}
                        options={segments}
                        suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="20" height="20" className="common-select-icon" />}
                    />
                </Form.Item>
                <Form.Item
                    name={'description'}
                    label={<span className={styles.CompanyInfoForm__inputName}>Add description</span>}
                    htmlFor={'description'}
                    help={<span className="color-danger">{errors?.description ? errors.description : ''}</span>}
                    validateStatus={errors?.description ? 'error' : ''}
                >
                    <Input.TextArea
                        id={"description"}
                        rows={4}
                        defaultValue={formState.description}
                        placeholder="This audience includes executives and managers who drive innovation within their organizations."
                        className={styles.CompanyInfoForm__overview}
                        onChange={(e)=> onFieldChange("description", e.target.value)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditAudienceModal;
