import { useEffect, useState } from 'react'
import './styles.scss'
import { Progress } from 'antd'
import { OrganizerEventDataFullItem } from '@store/type-event/events/models';
import { EventChecklistName } from '../setup/constants';
import { checklistLinkHandler, checklistNamesHandler } from './helpers';
import { useNavigate } from 'react-router-dom';

interface Props {
    onChange: (key: string) => void;
    selectedKey: string;
    event: OrganizerEventDataFullItem
}
const SetupProgressMenu = ({
    onChange,
    selectedKey,
    event
}: Props) => {
    const [showSetup, setShowSetup] = useState(true)
    const [setupNotCompletedList, setSetupNotCompletedList] = useState<{ id: string, name: EventChecklistName }[]>([])
    const [setupCompletedList, setSetupCompletedList] = useState<{ id: string, name: EventChecklistName }[]>([])

    useEffect(() => {
        if (event?.checklist?.length) {
            const { notCompletedList, completedList } = event.checklist.reduce((acc: {
                notCompletedList: { id: string, name: EventChecklistName }[],
                completedList: { id: string, name: EventChecklistName }[]
            }, item: { completed: boolean, item: { id: string, name: EventChecklistName } }) => {
                if (item.completed) {
                    acc.completedList.push(item.item);
                } else {
                    acc.notCompletedList.push(item.item);
                }
                return acc;
            }, { notCompletedList: [], completedList: [] });

            setSetupNotCompletedList(notCompletedList);
            setSetupCompletedList(completedList);
        }
    }, [event?.checklist]);

    const checkedItems = checklistNamesHandler(setupCompletedList)


    return (
        <div className={`event-setup-progress-steps-menu ${setupNotCompletedList.length ? 'warning' : ''}`}>
            {setupNotCompletedList.length ? (
                <ProgressToPublish
                    completedCount={setupCompletedList.length}
                    total={event?.checklist ? event.checklist.length : 0}
                />
            ) : null}
            <button
                className={`event-setup-progress-steps-menu__cta`}
                onClick={() => setShowSetup((s: boolean) => !s)}
            >
                <span className='mr-auto'>Setup</span>
                <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: showSetup ? 'rotate(180deg)' : 'none' }}
                >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.267518 0.767518C0.579937 0.455098 1.08647 0.455098 1.39889 0.767518L4.99987 4.3685L8.60085 0.767518C8.91327 0.455098 9.4198 0.455098 9.73222 0.767518C10.0446 1.07994 10.0446 1.58647 9.73222 1.89889L5.86018 5.77093C5.38505 6.24606 4.6147 6.24607 4.13956 5.77093L0.267518 1.89889C-0.0449017 1.58647 -0.0449017 1.07994 0.267518 0.767518Z" fill="#57585A" />
                </svg>
            </button>
            <div className={`event-setup-progress-steps-menu__steps-section ${showSetup ? 'show' : 'hide'}`}>
                <button
                    className={`event-setup-progress-steps-menu__cta step-item ${selectedKey === 'details' ? 'selected' : ''}`}
                    onClick={() => onChange('general')}
                >
                    <IconRender isDone />
                    {/* <IconRender isDone={checkedItems.includes('subdomain_verified')} /> */}
                    General
                </button>
                <button
                    className={`event-setup-progress-steps-menu__cta step-item ${selectedKey === checklistLinkHandler('subdomain_verified') ? 'selected' : ''}`}
                    onClick={() => onChange(checklistLinkHandler('subdomain_verified'))}
                >
                    <IconRender isDone={checkedItems.includes('subdomain_verified')} />
                    Subdomain
                </button>

                <button
                    className={`event-setup-progress-steps-menu__cta step-item ${selectedKey === checklistLinkHandler('credential_granted') ? 'selected' : ''}`}
                    onClick={() => onChange(checklistLinkHandler('credential_granted'))}
                >
                    <IconRender isDone={checkedItems.includes('credential_granted')} />
                    Ad Accounts
                </button>

                <button
                    className={`event-setup-progress-steps-menu__cta step-item ${selectedKey === checklistLinkHandler('audience_configured') ? 'selected' : ''}`}
                    onClick={() => onChange(checklistLinkHandler('audience_configured'))}
                >
                    <IconRender isDone={checkedItems.includes('audience_configured')} />
                    Audience Catalog
                </button>

                <button
                    className={`event-setup-progress-steps-menu__cta step-item ${selectedKey === checklistLinkHandler('landing_template_configured') ? 'selected' : ''}`}
                    onClick={() => onChange(checklistLinkHandler('landing_template_configured'))}
                >
                    <IconRender isDone={checkedItems.includes('landing_template_configured')} />
                    Landing Page Template
                </button>
                {/*
                    <button
                        className={`event-setup-progress-steps-menu__cta step-item ${selectedKey === checklistLinkHandler('creative_template_configured') ? 'selected' : ''}`}
                        onClick={() => onChange(checklistLinkHandler('creative_template_configured'))}
                    >
                        <IconRender isDone={checkedItems.includes('creative_template_configured')} />
                        Creatives Template
                    </button> */}

                <button
                    className={`event-setup-progress-steps-menu__cta step-item ${selectedKey === checklistLinkHandler('track_installed') ? 'selected' : ''}`}
                    onClick={() => onChange(checklistLinkHandler('track_installed'))}
                >
                    <IconRender isDone={checkedItems.includes('track_installed')} />
                    Tracking Code
                </button>

                <button
                    className={`event-setup-progress-steps-menu__cta step-item ${selectedKey === checklistLinkHandler('billing_defined') ? 'selected' : ''}`}
                    onClick={() => onChange(checklistLinkHandler('billing_defined'))}
                >
                    <IconRender isDone={checkedItems.includes('billing_defined')} />
                    Billing
                </button>
            </div>
        </div>

    )
}

export default SetupProgressMenu

const IconRender = ({ isDone }: { isDone: boolean }) => {
    if (isDone) {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="18" height="18" rx="9" fill="#DCE1FB" />
                <path opacity="0.9" d="M12.5699 6.125H11.9458C11.8583 6.125 11.7753 6.16518 11.7217 6.23393L8.04044 10.8973L6.27526 8.66071C6.24855 8.62681 6.21452 8.5994 6.1757 8.58053C6.13689 8.56167 6.09431 8.55184 6.05115 8.55179H5.42704C5.36722 8.55179 5.33418 8.62054 5.37079 8.66696L7.81633 11.7652C7.93061 11.9098 8.15026 11.9098 8.26543 11.7652L12.6261 6.23929C12.6628 6.19375 12.6297 6.125 12.5699 6.125Z" fill="#3E52BB" />
            </svg>
        )
    }

    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="#3E52BB" />
        </svg>
    )
}

const ProgressToPublish = ({
    completedCount,
    total
}: {
    completedCount: number;
    total: number
}) => {
    const progressPercent = completedCount === 0 || total === 0 ? 0 : (completedCount / total) * 100;
    return (
        <div className='event-setup-progress-steps-menu__progressbar'>
            <p className='text-regular-14 mb-4'>Complete setup to publish event</p>
            <div className='flex-center event-setup-progress-steps-menu__progressbar__bars-section'>
                <Progress
                    percent={progressPercent}
                    steps={total}
                    showInfo={false}
                    className='ant-progress__custom'
                />
                <p className='m-0 text-regular-14'>{completedCount}/{total}</p>
            </div>
        </div>
    )
}
