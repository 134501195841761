import React, { useState } from "react";
import { AudienceItem } from "@store/type-event/audiences/models.ts";
import { MAX_SEGMENTS_LENGTH } from "@pages/EventRolePages/AudiencesEventPage/constants.ts";
import { Tag } from "antd";

const SegmentsColumn = ({ segments }: { segments: AudienceItem['segments'] }) => {
    const [isHidden, setIsHidden] = useState(true)
    return (
        <div className="audiences-event-page__audiences__tags">
            {segments?.length > MAX_SEGMENTS_LENGTH && isHidden ? (
                <>
                    {segments
                        ?.slice(0, MAX_SEGMENTS_LENGTH)
                        ?.map((segment) => <Tag key={segment.id}>{segment.slug}</Tag>)}
                    {isHidden && (
                        <Tag className="audiences-event-page__audiences__tag-count" onClick={() => setIsHidden(false)}>+{segments.slice(MAX_SEGMENTS_LENGTH).length}</Tag>
                    )}
                </>
            ) : (
                <>
                    {segments?.map((segment) => <Tag key={segment.id}>{segment.slug}</Tag>)}
                </>
            )}
        </div>
    )
}

export default SegmentsColumn