import { Select, type SelectProps, SelectProps as AntdSelectProps, Tag } from "antd";
import React, { useMemo, useState } from "react";
import { ALL } from "@components/SelectWithAllOption/constants.ts";
import './styles.scss'
import ArrowIcon from "@assets/ArrowIcon.tsx";

type Props<T> = Omit<AntdSelectProps, 'value'> & {
    id: string;
    value: { value: number; label: string }[] | string[] | string | number | null;
};

type TagRender = SelectProps['tagRender'];

const tagRender: TagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    if (label === ALL) {
        return <div>{label}</div>
    }
    return (
        <Tag
            className="select-with-all-option__tag"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
        >
            {label}
        </Tag>
    );
};

const SelectWithAllOption = <T, >({
    id,
    showSearch,
    setSearchValue,
    page,
    setPage,
    data,
    isLoading,
    optionsMapper,
    classNameProp,
    withAllOption,
    allowClear,
    value: propValue,
    onChange: onChangeFromProps,
    fieldNames: fieldNamesProp,
    options: optionsProp,
    ...restProps
}: Props<T>) => {
    const [currentValue, setCurrentValue] = useState<{ value: number; label: string }[] | string[] | string | number | null>([ALL])
    const [isSelectOpen, setSelectOpen] = useState(false)

    const options = useMemo(() => {
        return [...optionsProp || [], { [fieldNamesProp?.value || 'value']: ALL, [fieldNamesProp?.label || 'label']: ALL }]
    }, [optionsProp])

    const isAllowClear = Boolean(allowClear && currentValue && Array.isArray(currentValue) && !(currentValue as string[])?.includes(ALL))

    return (
        <Select
            onDropdownVisibleChange={setSelectOpen}
            open={isSelectOpen}
            id={id}
            placeholder='All'
            tagRender={tagRender}
            allowClear={isAllowClear}
            value={currentValue}
            onChange={(value: string[]) => {
                if (value[value.length - 1] === ALL || !value.length) {
                    onChangeFromProps([])
                    setCurrentValue([ALL])
                    setSelectOpen(false)
                } else {
                    const newValue = value.filter((el) => el !== ALL)
                    setCurrentValue(newValue)
                    onChangeFromProps(newValue)
                }
            }}
            options={options}
            fieldNames={fieldNamesProp}
            suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="20" height="20" className="common-select-icon" />}
            {...restProps}
        />
    );
};

export default SelectWithAllOption;
