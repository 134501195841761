import React from "react";
import { Select, Spin } from "antd";
import { useGetCategorieByFiltersQuery } from '@store/main/categories/categories.api';

export type CategoryType = {
    id: number
    slug: string
}

type CategoriesPropsType = {
    initialPlaceholder?: string;
    defaultValue?: {
        value: string | number
        label: string
    };
    onCategoryChange: (fieldName: string, newCategory: CategoryType) => void
    onClear?: () => void
}

const Categories: React.FC<CategoriesPropsType> = (props) => {
    const { onCategoryChange, onClear, defaultValue } = props;
    const { data: categories, isLoading: categoriesLoading } = useGetCategorieByFiltersQuery({ search: " " })
    const getCategories = (arr: CategoryType[]) => {
        return arr.map((category) => {
            return { label: category.slug, value: category.id }
        })
    }

    return (
        <>
            <Select
                allowClear
                defaultValue={defaultValue}
                placeholder={props?.initialPlaceholder ? <span className='text-regular-14'>{props.initialPlaceholder}</span> : ''}
                options={categories?.data.length ? getCategories(categories?.data) : []}
                onClear={() => {
                    if (onClear) {
                        onClear()
                    }
                }}
                onSelect={(_, option) => onCategoryChange("category", { id: option.value, slug: option.label })}
                notFoundContent={categoriesLoading ? <Spin size="small" /> : null}
            />
        </>
    )
}

export default Categories;
