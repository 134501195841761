import { Subscription, UserType } from '@store/models-to replace/auth';
import { formatByCurrency, formatDateUtc } from '@shared/utils';
import { SubscriptionName, SubscriptionStatus } from '@store/main/subscription/models';
import { BillingInfo } from "@store/main/billing/models.ts";

export type ProSubscriptionData = {
    proSubscription: null | Subscription & {
        percent: number
        statusName: string
        formattedDate: string
        formattedDay: string
        formattedPrice: string
        formattedSpendForFree: string
        spend_for_free: number
        limit: number
    },
    isProEnabled: boolean,
    isProVisible: boolean
}

const getProSubscription = (user?: UserType, billingInfo?: BillingInfo): ProSubscriptionData => {
    const { organization } = user || {}
    if (!user || organization?.type === 'event') {
        return {
            proSubscription: null,
            isProEnabled: false,
            isProVisible: false
        }
    }

    let subscriptions = organization?.subscriptions || []
    if (!Array.isArray(organization?.subscriptions)) {
        subscriptions = organization?.subscriptions ? [organization.subscriptions] : []
    }

    const proSubscription = subscriptions.find(({ type }: Subscription) => type?.name === SubscriptionName.boost_pro)
    const isProEnabled = proSubscription?.status?.name === SubscriptionStatus.active

    const billingInfoProSubscription = billingInfo?.subscriptions?.find(({ type }) => type?.name === SubscriptionName.boost_pro)
    const { status, date = '', money } = proSubscription || {}
    const { name } = status || {}
    const { spend = 0, limit = 100, currency } = money || {}
    const percent = (spend * 100) / limit

    const { spend_for_free = 5000, currency: billingInfoCurrency, price = 200 } = billingInfoProSubscription || {}
    const statusName = name ? `${name[0].toUpperCase()}${name.slice(1, name.length)}` : ''
    const formattedDate = formatDateUtc(date)
    const formattedDay = formattedDate?.split(',')?.[0] || ''

    return {
        proSubscription: {
            ...proSubscription,
            percent,
            statusName,
            formattedDate,
            formattedDay,
            formattedPrice: formatByCurrency(price, billingInfoCurrency),
            formattedSpendForFree: formatByCurrency(spend_for_free, billingInfoCurrency),
            spend_for_free,
            limit,
            money: {
                spend,
                limit,
                currency
            }
        },
        isProEnabled,
        isProVisible: organization?.type !== 'event'
    }
}

export default getProSubscription
