import React from 'react';
import './styles.scss'

const SponsorServiceAgreementPage: React.FC = () => {
    return (
        <div className="sponsor-service-agreement-page">
            <h1>Service as a Software Agreement</h1>
            <p><strong>Effective date:</strong> April 4, 2024</p>

            <section>
                <p>
                    The Boost Express platform simplifies launching and management of co-marketing advertising campaigns for the Organizer and Sponsor, making it more technological, transparent, and comfortable. This improves and develops the advertising industry and provides product information to interested consumers.
                </p>
                <p>
                    This Agreement (“Agreement”) is an offer by Boost.Express to enter into an agreement with the Sponsor to provide access to the Platform and use its functionality.
                </p>
                <p>
                    Before using the Boost.Express Platform, please read the terms and conditions of this Agreement. By registering on the Boost.Express Platform as a Sponsor, you are deemed to have fully and unconditionally agreed to this Agreement on the terms and conditions set forth below.
                </p>
            </section>

            <section>
                <h2>1. DEFINITIONS</h2>
                <ol>
                    <li>
                        <strong>“Ad Creative”</strong> means visual and textual elements of an advertisement used by a Sponsor to promote its product or services, available for publication and moderation by Boost.Express, and Third Party Media.
                    </li>
                    <li>
                        <strong>“Campaign”</strong> means a strategic marketing initiative aimed at promoting a product, service, or brand, often employing various channels to achieve specific goals such as increasing sales, enhancing brand awareness, or driving customer engagement, consisting of an Ad Creative and a particular set of settings, such as targeting, strategy, bidding, optimizations, schedule, etc.
                    </li>
                    <li>
                        <strong>“Bid”</strong> means the cost per click or Lead on a published Online Ad, payable by the Sponsor in accordance with the rates defined by the Sponsor or, if Boost Pro is activated, by the Boost.Express.
                    </li>
                    <li>
                        <strong>“Boost.Express”</strong> means Boost.Express Incorporated, located at 251 Little Falls Drive, Wilmington, New Castle County, Delaware 19808.
                    </li>
                    <li>
                        <strong>“Click”</strong> means any visitor or third party lands on an Online Ad.
                    </li>
                    <li>
                        <strong>“Event”</strong> means a project created by the Organizer, with a corresponding page on the Platform.
                    </li>
                    <li>
                        <strong>“Internet User”</strong> means an individual who gains access to resources, services, and utilities provided through the Internet.
                    </li>
                    <li>
                        <strong>“Lead”</strong> means potential client who has expressed some level of interest or engagement with the Sponsor, such as subscribing to a newsletter, filling out a contact form, or attending a webinar.
                    </li>
                    <li>
                        <strong>“Online Ad”</strong> means Campaign placed by using the Platform and made available for publication and moderation by Boost.Express and Third Party Media.
                    </li>
                    <li>
                        <strong>“Organizer”</strong> means a legal entity or an individual, who creates an Event and, who places Sponsor’s Online Ads using Organizer’s declared Third Party Media.
                    </li>
                    <li>
                        <strong>“Platform”</strong> means a software product that provides advertising services under CPC (Cost Per Click) model, with payment for certain actions of Users in Third Party Media (Clicks).
                    </li>
                    <li>
                        <strong>“Personal Account”</strong> means a secure part of the Platform created during registration and authorization of the User on the Platform, allowing the User to use the functionality of the Platform.
                    </li>
                    <li>
                        <strong>“Sponsor’s Balance”</strong> means the deposit account in the Sponsor’s Personal Account from which the Bid and other payments could be deducted.
                    </li>
                    <li>
                        <strong>“Sponsor”</strong> means a legal entity or an individual, who determines the object and/or content of Online Ads and offers to place it on the Third Party Media according to the terms of the Ad Creatives, as well as determines the Bid for the Click.
                    </li>
                    <li>
                        <strong>“Third Party Media”</strong> means social networks and online advertising platforms, including but not limited to Google Ads, LinkedIn Ads, Facebook, Instagram, and others, where the Organizer has an active account.
                    </li>
                    <li>
                        <strong>“Tracking Method”</strong> means the reporting and/or tracking procedures currently used by Boost.Express to measure traffic, performance and related data.
                    </li>
                    <li>
                        <strong>“User”</strong> means the individual accessing or using the Platform, or the company, or other legal entity on behalf of which such individual is accessing or using the Platform, as applicable.
                    </li>
                </ol>
            </section>

            <section>
                <h2>2. PLATFORM BASICS</h2>
                <ol>
                    <li>
                        <strong>Subject of the Agreement.</strong> The Platform provides an organized advertising interaction process whereby the Sponsor places Ad Creative through its Personal Account in the Platform and Boost.Express facilitates the placement of Online Ads via Organizer’s accounts in Third Party Media.
                    </li>
                    <li>
                        Sponsor has the right to use the Platform and its functionality for its intended purpose, including by accessing the Platform using personal computers and using explicit functions of the Platform, Personal Account and their functions, for a period of during which the Platform and its functions remain available to the Sponsor.
                    </li>
                    <li>
                        Boost.Express does not guarantee:
                        <ol>
                            <li>the placement, positioning or the timing of delivery of landing pages, any Online Ads, or</li>
                            <li>the number (if any) of impressions, publications, conversions or clicks on any Online Ads.</li>
                        </ol>
                    </li>
                    <li>
                        <strong>Registration.</strong> To create a Personal Account, the Sponsor should follow the link received from the Organizer and register on their own by filling all the requested data. The registration process can be completed by clicking on the ‘Registration’ button or a similar term.
                    </li>
                    <li>
                        Registering a Personal Account enables the Sponsor to create its own landing pages within the Platform and Ad Creative, offering their further publication on the Organizer’s website.
                    </li>
                    <li>
                        Access to the Platform shall be deemed granted when the Sponsor performs registration in the Platform.
                    </li>
                    <li>
                        By registering on the Platform, the Sponsor acknowledges and agrees that all such access and use is subject to this Agreement. Any such access or use will constitute such acceptance and result in a binding and legally enforceable agreement between the Sponsor and Boost.Express.
                    </li>
                </ol>
            </section>

            <section>
                <h2>3. AD CREATIVES AND CAMPAIGNS</h2>
                <ol>
                    <li>
                        Sponsor hereby grants to Boost.Express a worldwide, non-exclusive, limited, royalty-free, sub-licensable right to distribute, reproduce, copy, reformat, digitize, cut, adapt, compress, transcode, display, perform, create derivative works of and technologically manipulate an Online Ad, in each case as reasonably necessary in order to distribute the Online Ads as ordered by the Sponsor.
                    </li>
                    <li>
                        The Sponsor must act in good faith and comply with the terms and restrictions outlined in this agreement.
                    </li>
                    <li>
                        By using the Platform, the Sponsor may create its own Campaign or authorize Boost.Express to create its own Campaign to accompany the Online Ads publication (Boost Pro).
                    </li>
                    <li>
                        Before an Online Ad can be published, Organizer, Boost.Express and Third Party Media conduct the prior moderation of the Campaign.
                    </li>
                    <li>
                        <strong>Sponsor’s Ad Creatives.</strong> In creating the Ad Creative, Sponsor warrants that:
                        <ol>
                            <li>the Ad Creative will be created without illegal borrowing, plagiarism, or unauthorized quotation of other works, including well-known and publicly available works. The results of intellectual activity created for others will not be used unless such use has been agreed upon in writing by the parties or does not violate the rights of third parties.</li>
                            <li>Sponsor has exclusive rights to all materials used;</li>
                            <li>Sponsor has obtained proper consent and rights from models featured in the Ad Creative, if needed;</li>
                            <li>the Ad Creative complies with applicable laws and does not contain any prohibited advertising or Negative Content.</li>
                        </ol>
                    </li>

                        <div>
                            <strong>Note.</strong> To clarify, the content referred to as ‘Negative Content’ is always considered prohibited content. The following types of content are prohibited:
                            <ol>
                                <li>Depictions of violence, criminal activity, or accidents, whether real or fictional, in videos, games, or films;</li>
                                <li>Sexually explicit content, including pornography;</li>
                                <li>Advocacy of extremist political views that can radicalize vulnerable members of society;</li>
                                <li>Advocacy of hatred based on race, religion, sexuality, or other sociocultural factors;</li>
                                <li>Instruction or advocacy of crime, violence, or dangerous behavior, including the manufacture of weapons or martial arts.</li>
                            </ol>
                        </div>
                    <li>
                        The Sponsor is solely responsible for the consequences of published Online Ads and also undertakes to reimburse Boost.Express for losses incurred by the latter as an intermediary in organizing the interaction between the Platform Users and Third Party Media.
                    </li>
                    <li>
                        Boost.Express on its part is not responsible for the Ad Creatives submitted by the Sponsor, is not obliged to check them, as well as the content of the Campaign, for accuracy and for violation of the rights of third parties. The credibility and legality of the material is presumed by Boost.Express, as well as the Sponsor’s good faith.
                    </li>
                    <li>
                        Boost.Express reserves the right to reject, cancel, or suspend any Campaigns at any time, for any reason whatsoever.
                    </li>
                    <li>
                        The Sponsor’s Personal Account displays statistics on active and archived Campaigns.
                    </li>
                    <li>
                        <strong>Moderation.</strong> As part of the moderation process, either Organizer or Boost.Express on behalf of Organizer, verifies the compliance of the advertising material at its discretion, after which the advertising material ready for publication is sent to the Third Party Media for verification with further publication, if successful.
                    </li>
                    <li>
                        <strong>Sponsor’s Balance.</strong> To ensure timely payment, the Sponsor shall maintain a positive balance specified in their Personal Account, unless otherwise specified directly to the Sponsor by the Boost.Express within the Platform.
                    </li>
                    <li>
                        The Sponsor shall replenish the Balance according to the chosen payment method. The available Balance information will be updated in the Sponsor’s Personal Account.
                    </li>
                    <li>
                        If the Sponsor’s Balance does not have enough funds to cover the cost of the Services (Clicks), the provision of the Services and online advertising will be suspended. Online Ads’ promotion with a negative Balance is not permitted.
                    </li>
                    <li>
                        <strong>Reporting.</strong> Boost.Express will provide confirmation to the Sponsor, either electronically or in writing, stating whether an advertising has been initiated and begun delivery.
                    </li>
                    <li>
                        Upon entering into this Agreement, Sponsor acknowledges and agrees that Boost.Express tracks and logs all activities to generate statistics.
                    </li>
                    <li>
                        Sponsor agrees and acknowledges that all tracking of User activities will be determined by the Boost.Express Tracking Method. The total sum of events, impressions, clicks, leads, sales, installs, conversions and revenue count is to be based on Boost.Express statistics. Boost.Express takes commercially reasonable measures to ensure that its Tracking Method at any point shall meet technical industry standards, but it cannot guarantee the absolute accuracy of the Tracking Method.
                    </li>
                </ol>
            </section>

            <section>
                <h2>4. BOOST PRO SUBSCRIPTION</h2>
                <ol>
                    <li>
                        If Boost Pro is enabled, the Sponsor can order customizing Ad strategies, producing of landing pages and Ad Creatives, Campaigns, monitoring and optimization.
                    </li>
                    <li>
                        Boost.Express fully owns the exclusive rights to the works it creates. To use these works, Boost.Express grants a non-exclusive license for the term of the Online Ads publication.
                    </li>
                    <li>
                        Upon activation of access to paid features, Boost.Express’ services in providing Sponsor with access shall be deemed rendered and accepted by Sponsor without comment. Sponsor is not entitled to demand a refund of the fees paid for such access.
                    </li>
                    <li>
                        Information about the amount of the Subscription fee, methods of its payment and the Subscription period is indicated when choosing the Subscription.
                    </li>
                    <li>
                        Unless otherwise provided when purchasing a Subscription, fees will be charged for each Subscription period determined when subscribing. Unless otherwise specified at the time of subscription purchase, the BoostPro service is applicable to a single Event. If the Sponsor participates in multiple Events, the subscription does not apply to all Events but only to the one selected within the subscription. The number of subscriptions is not limited.
                    </li>
                    <li>
                        The Subscription period, equal to one month, begins to run from the date of payment for the Subscription and expires on the corresponding day of the calendar month following the date of payment for the Subscription.
                    </li>
                    <li>
                        For the purpose of issuing a Subscription and the further possibility of paying for the Subscription, the User links his bank card and/or electronic means of payment (“Linked Card”). For the purposes of this Agreement, any bank card and/or electronic means of payment (including those linked when Subscribing, prior to its execution, or after its execution) are considered to be a Linked Card. Boost.Express has the right to charge the amount equal to the cost of the Subscription from any of the Linked Cards.
                    </li>
                    <li>
                        When specifying the data of the Linked Card and further using the Linked Card, the User confirms and guarantees that he is provided with reliable and complete information about a valid bank card issued in his name; compliance with the rules of international payment systems and the requirements of the issuing bank that issued the Linked Card, including with regard to the procedure for conducting cashless payments; providing them with reliable and complete information about the electronic means of payment; compliance with the requirements of the electronic money operator.
                    </li>
                    <li>
                        Access to the Subscription is considered to be provided to the User for the specified period of the Subscription in full from the moment the User pays for the Subscription, provided that the fact of payment is reflected in the Boost.Express electronic payment accounting system.
                    </li>
                    <li>
                        Boost.Express’ obligations to provide the User with access to the Subscription are considered fulfilled to the User, regardless of whether the User requested the corresponding performance from Boost.Express during the Subscription period, as well as regardless of the actual number and volume of use by the User of the relevant functionality under the Subscription.
                    </li>
                    <li>
                        When subscribing, the User instructs Boost.Express for each selected Subscription period to charge the fee set by the Boost.Express on the day of payment for the relevant Subscription (subscription fee) until the User refuses to renew the Subscription for the next Subscription period.
                    </li>
                    <li>
                        The User understands and agrees that any Subscription by default is issued indefinitely from the moment of payment for the first subscription period, unless otherwise specified when paying for it. The User has the right to refuse to renew the Subscription for the next Subscription period. In this case, the User’s access to the Subscription is terminated from the day following the last day of the paid Subscription period.
                    </li>
                    <li>
                        The User who has completed the Subscription Activation (acceptance) instructs Boost.Express on behalf of the User to draw up instructions to debit funds from the account (to which the Linked Card is linked) in favor of Boost.Express for the Subscription.
                    </li>
                    <li>
                        The User gives his consent to the automatic periodic debiting of funds from his account in payment for the corresponding Subscription on his order, given in the manner provided for in this chapter of the User Agreement, and acknowledges that the instructions for debiting funds from his account, sent in accordance with this chapter of the User Agreement are the instructions of the User himself, and the actions of the processing center and the acquiring bank aimed at debiting funds in accordance with this chapter of the User Agreement are performed with the consent of the User.
                    </li>
                    <li>
                        The debiting of funds in accordance with this chapter of the User Agreement begins to occur on an automatic basis, subject to the following conditions:
                        <ol>
                            <li>input by the User of all necessary details of the Linked Card;</li>
                            <li>activation of the service for debiting funds on an automatic basis from the Linked Card in the following way: automatically when making the first payment;</li>
                            <li>pressing the “Pay”, “Subscribe” button (or another button similar in functionality) confirming the User's consent to the terms of the Subscription.</li>
                        </ol>
                    </li>
                </ol>
            </section>

            <section>
                <h2>5. THIRD PARTY MEDIA TERMS</h2>
                <ol>
                    <li>
                        In order to publish Online Ads, Third Party Media moderates the Online Ads to ensure that it meets the requirements at its discretion.
                    </li>
                    <li>
                        Boost.Express delivery of certain advertising services to Sponsor under this Agreement via third parties shall be subject to such third parties’ applicable third party terms and conditions (“Third Party Terms”), which may include, but not limited to: (i) Google, (ii) LinkedIn and Facebook and set forth herein below. By using the Boost.Express Platform pursuant to this Agreement, Sponsor agrees to be bound by the applicable Third Party Terms.
                    </li>
                    <li>
                        The Third Party Terms include all applicable third party policies, documents, terms and conditions referenced in the Third Party Terms, including without limitation, the applicable privacy policy, terms of service, Ads policy guidelines, and trademark requirements. Sponsor agrees and acknowledges that the Third Party Terms may be modified from time to time by such third parties. Sponsor shall be responsible for regularly reviewing the Third Party Terms and becoming familiar with any such modifications. Sponsor’s use of the Boost.Express Platform under this Agreement after the Third Party Terms have changed constitutes Sponsor’s acceptance of the new Third Party Terms. Sponsor hereby agrees to be bound by the Third Party Terms. Sponsor agrees to undertake and agrees to all applicable obligations, representations and warranties contemplated by the Third Party Terms in order to enable Boost.Express to facilitate the provision of the applicable services for the benefit of Sponsor via such third parties pursuant to the applicable Third Party Terms. Sponsor understands and agrees that to the extent it violates any Third Party Terms, it may be liable to the applicable Third Party Media and/or to Boost.Express for any damages or harm arising out of such violation.
                    </li>
                    <li>
                        Sponsor understands, acknowledges and agrees to the following terms:
                        <ol>
                            <li>Boost.Express does not have control over the policies of search engines or directories, social media outlets or other third party websites (collectively, “Third Party Media”) with respect to the type of sites and/or content that they accept now or in the future. Sponsor’s web site(s) or content may be excluded from any Third Party Media at any time at the sole discretion of the search engine or directory;</li>
                            <li>Occasionally, Third Party Media will drop listings or content for no apparent reason. Often, a listing or content will reappear without any additional submissions;</li>
                            <li>Boost.Express is not responsible for any changes made to Sponsor’s website(s) by Sponsor or other parties that adversely affect the search engine or directory rankings of Sponsor’s website(s) or the appearance of Sponsor’s content or links in Third Party Media;</li>
                            <li>Boost.Express may provide the services hereunder itself or through one or more third party vendors and/or Boost.Express.</li>
                        </ol>
                    </li>
                </ol>
            </section>

            <section>
                <h2>6. FEES</h2>
                <ol className="fees">
                    <li>The Bid is determined solely by the Sponsor, unless launched a campaign with Boost Pro service.
                        If Boost Pro is activated, the Sponsor authorizes Boost.Express to determine the Bid.
                    </li>
                    <li>The Bid is used to pay for advertising placement through Organizer’s active Third Party Media
                        accounts, as well as for Boost.Express and Organizer’s fees.
                    </li>
                    <li>Fee is payable if a click results from the Online Ads.</li>
                    <li>The amount of fee is calculated based on the number of Clicks engaged with an Online Ad, the
                        Bid.
                    </li>
                    <li>Sponsor pays the fee by transferring funds via supported payment methods or by automatic
                        deduction from the Sponsor’s balance in Sponsor’s Personal Account.
                    </li>
                </ol>


            </section>
            <section>
                <h2>7. REFUND POLICY</h2>
                <ol className="refund-policy">
                    <li>Boost.Express offers a refund guarantee for any unspent funds during the period of Platform
                        use.
                    </li>
                    <li>Refunds will only be issued if the Sponsor has not violated the Agreement at any time during the
                        period of use.
                    </li>
                    <li>The review period is 10 business days from the date the request is sent.</li>
                    <li>To request a refund, please email billing@boost.express with a detailed description of the
                        problem and reason for the refund request.
                    </li>
                    <li>Procedure for reviewing requests for refunds. Each request is reviewed individually, on a
                        first-come, first-served basis.
                    </li>
                    <li>After approval of the refund request, Boost.Express will notify the Sponsor. The refund process
                        may take several weeks, depending on the payment method, the Sponsor's bank, and other factors.
                    </li>
                </ol>
            </section>

            <section>
                <h2>8. INTELLECTUAL PROPERTY</h2>
                <ol className="intellectual-property">
                    <li>User owns all of the content they provide to Boost.Express. User grants Boost.Express a
                        nonexclusive, revocable, worldwide, limited, fully paid-up and royalty-free right to use, copy,
                        prepare derivative works of, distribute, publish, remove, retain, add, process, or analyze this
                        information for the sole purpose of providing the Platform to Users.
                    </li>
                    <li>Boost.Express and its affiliates own and retain all rights, title, and interest in and to the
                        Platform along with all patents, inventions, copyrights, trademarks, domain names, trade
                        secrets, know-how, and any other intellectual property and/or proprietary rights related to the
                        Platform.
                    </li>
                    <li>Elements of the Platform are also protected by trade dress, trade secret, unfair competition,
                        and other laws and may not be copied or imitated in whole or in part.
                    </li>
                    <li>All custom graphics, icons, and other items that appear on the Platform are trademarks, service
                        marks or trade dress of Boost.Express, its affiliates, or other entities that have granted
                        Boost.Express the right and license to use such Marks.
                    </li>
                </ol>
            </section>


                <section>


                <h2>9. WARRANTY</h2>
                <ol className="warranty">
                    <li>THE PLATFORM IS PROVIDED ON AN “AS IS” BASIS, WITHOUT ANY WARRANTIES, GUARANTEES, CONDITIONS, OR
                        REPRESENTATIONS OF ANY KIND.
                    </li>
                    <li>TO THE MAXIMUM EXTENT PERMITTED BY LAW, BOOST EXPRESS EXPRESSLY DISCLAIM ANY AND ALL
                        WARRANTIES.
                    </li>
                    <li>BOOST EXPRESS AND ITS AFFILIATES RELY ON THIRD PARTY DATA SOURCES FOR INFORMATION.</li>
                </ol>
                </section>

                <section>

                <h2>10. LIMITATION OF LIABILITY</h2>
                <ol className="limitation-liability">
                    <li>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL BOOST EXPRESS BE LIABLE FOR
                        ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR OTHER TYPE OF
                        DAMAGES.
                    </li>
                    <li>USER UNDERSTANDS AND AGREES THAT USER ASSUMES ALL RESPONSIBILITY FOR THEIR USE OF THE
                        PLATFORM.
                    </li>
                    <li>THIS SECTION DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
                        LAW.
                    </li>
                </ol>
                </section>

                <section>

                <h2>11. INDEMNIFICATION</h2>
                <ol className="indemnification">
                    <li>User agrees to indemnify, defend, and hold harmless Boost.Express and our affiliates from and
                        against all actual or alleged claims, damages, awards, judgments, losses, liabilities of every
                        kind, and nature whatsoever.
                    </li>
                    <li>User agrees to promptly notify Boost.Express of any Claim(s) and shall cooperate fully with the
                        Boost.Express Parties in defending such Claims.
                    </li>
                </ol>
                </section>

                <section>

                <h2>12. MISCELLANEOUS</h2>
                <ol className="miscellaneous">
                    <li>This Agreement constitutes the sole and entire agreement between User and Boost.Express.</li>
                    <li>Boost.Express reserves the right to modify this Agreement at any time.</li>
                    <li>No waiver by Boost.Express of any breach or default hereunder shall be deemed to be a waiver of
                        any preceding or subsequent breach or default.
                    </li>
                </ol>
                </section>

                <section>
                <h2>13. CONTACT US</h2>
                <p>If you have any questions about our Platform or this Agreement, please email us at
                    {' '}
                    <a href="mailto:hello@boost.express">
                        hello@boost.express
                    </a>
                    .</p>
                </section>
        </div>
    );
};

export default SponsorServiceAgreementPage;
