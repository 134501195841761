import React, { ReactNode, useState } from "react";
import styles from "./style.module.css";
import { Button, Select } from "antd";
import InputSearch from "@components/InputSearch";
import { LeadsFilters } from "@store/models-to replace/leads";
import { useGetPartnerCampaignsQuery } from '@store/type-partner/campaigns/campaigns.api';
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';

type LeadsFilterPanelPropsType = {
    filters: LeadsFilters;
    onFilterChange: (updatedFilters: Partial<LeadsFilters>) => void;
    resetFilters: () => void;
    errorTableData: object | undefined;
    extraActionsSlot?: ReactNode;
    debouncedOnSearchUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LeadsFilterPanel: React.FC<LeadsFilterPanelPropsType> = ({
    filters,
    debouncedOnSearchUpdate,
    onFilterChange,
    resetFilters,
    errorTableData,
    extraActionsSlot
}) => {

    const { data: optionsData, isLoading: filterIsLoading } = useGetFiltersByEnumQuery({ name: 'lead_type' })
    const { error: campaignError, data: campaignData } = useGetPartnerCampaignsQuery({ filter: undefined });
    const [search, setSearch] = useState('')

    const mapTypesToOptions = () => {
        return optionsData?.data.map((option) => {
            return { value: option.id, label: option.slug }
        })
    }

    const mapCampaignsToOptions = () => {
        if (campaignData?.data?.length) {
            return campaignData.data.map(campaign => ({ value: campaign.id, label: campaign.name }))
        } else {
            return []
        }
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        debouncedOnSearchUpdate(e)
    }



    return (
        <section className={styles.LeadsFilterPanel}>
            <article className={styles.LeadsFilterPanel__filters}>
                <InputSearch
                    placeholder={'Search by keywords'}
                    value={search}
                    onChange={handleSearchChange}
                />
                <Select
                    mode="multiple"
                    className={styles.LeadsFilterPanel__filter}
                    options={mapTypesToOptions()}
                    disabled={!optionsData?.data || optionsData.data.length < 1}
                    placeholder={'Lead type'}
                    value={filters.types}
                    onChange={(types) => onFilterChange({ types })}
                    loading={filterIsLoading}
                />
                <Select
                    mode="multiple"
                    className={styles.LeadsFilterPanel__filter}
                    options={campaignError ? [] : mapCampaignsToOptions()}
                    disabled={!!campaignError || !!errorTableData}
                    placeholder={'Campaigns'}
                    value={filters.campaigns}
                    onChange={(campaigns) => onFilterChange({ campaigns })}
                />
                <Button
                    type="text"
                    onClick={resetFilters}
                >
                    Clear
                </Button>
            </article>
            {extraActionsSlot && (
                <div>
                    {extraActionsSlot}
                </div>
            )}
        </section>
    )
}

export default LeadsFilterPanel;
