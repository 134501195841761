import { useBreakpoints } from '@hooks/browser'
import AnalyticsPageMobile from './mobile/AnalyticsPageMobile'
import AnalyticsPageDesktop from './AnalyticsPageDesktop'

const AnalyticsPage = () => {
    const isDesktop = useBreakpoints().md

    if (!isDesktop) {
        return <AnalyticsPageMobile />
    }
    return <AnalyticsPageDesktop />
}

export default AnalyticsPage
