import React, { useEffect, useState } from "react";
import { message, Skeleton, Typography, Upload, UploadFile } from "antd";
import './styles.scss';
import { HeroImageDropDownIcon } from "@icons/HeroImageDropDownIcon.tsx";
import { EventInfoFormStateType } from "@components/EventInfoForm/types.ts";
import { DeleteOutlined } from '@ant-design/icons';

type HeroImagePropsType = {
    url?: string;
    onChangeFormState: (fieldName: keyof EventInfoFormStateType, value: string) => void
}

const baseUrl = import.meta.env.VITE_API_URL as string;
const imageUploadUrl = baseUrl + '/partner/landings/image'

const HeroImage: React.FC<HeroImagePropsType> = (props) => {
    const { onChangeFormState } = props;
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [isImageLoading, setIsImageLoading] = useState(false)
    useEffect(() => {
        if (props?.url) {
            setFileList([
                {
                    uid: '-1',
                    name: props.url.split('/').pop() || '',
                    status: 'done',
                    url: props.url
                }
            ])
        }
    }, [props?.url])

    const [showDropZone, setShowDropZone] = useState(true);

    const beforeUpload = (file: { type: 'image/jpeg' | 'image/jpeg', status: string, size: number, response?: any }) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            // setFileList([])
            file.status = 'error'
            void message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            // setFileList([])
            void message.error('Image must smaller than 10MB!');
            file.status = 'error'
        }
        return isJpgOrPng && isLt2M;
    };

    return (
        <Upload
            name="image"
            maxCount={1}
            action={`${imageUploadUrl}?folder=event&resize=1000`}
            listType="picture"
            beforeUpload={beforeUpload}
            showUploadList={false}
            accept=".jpg,.jpeg,.png,.webp,.svg"
            fileList={fileList}
            openFileDialogOnClick={true}
            onChange={(e) => {
                setIsImageLoading(true)
                if (e.file.status === 'done') {
                    setShowDropZone(false)
                    onChangeFormState('heroImageUrl', e.file?.response?.data)
                    setFileList(e.fileList);
                    setIsImageLoading(false)
                }
                if (e.file.status === "removed") {
                    onChangeFormState('heroImageUrl', '')
                    setShowDropZone(true)
                    setIsImageLoading(false)
                    return setFileList([]);
                }
                setShowDropZone(false)
                onChangeFormState('heroImageUrl', e.file?.response?.data)
                setFileList(e?.fileList || []);
                setIsImageLoading(false)
            }}
        >
            {showDropZone && !fileList.length ?
                <div className="hero-image__content-empty">
                    <div className="hero-image__rules-block">

                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.0196 9.58418L19.0153 9.56699L16.6048 3.4418C16.4974 3.0959 16.1772 2.85742 15.8142 2.85742H6.04307C5.67783 2.85742 5.35342 3.1002 5.25029 3.45039L2.99658 9.51328L2.99014 9.52832L2.98584 9.54551C2.95791 9.65078 2.94931 9.7582 2.96435 9.86348C2.96221 9.89785 2.96006 9.93223 2.96006 9.9666V17.8277C2.96063 18.174 3.09843 18.5059 3.34328 18.7508C3.58812 18.9956 3.92004 19.1334 4.26631 19.134H17.7413C18.461 19.134 19.0476 18.5475 19.0497 17.8277V9.9666C19.0497 9.93867 19.0497 9.91074 19.0476 9.88711C19.0562 9.78184 19.0476 9.68086 19.0196 9.58418ZM12.6645 8.66035L12.6581 8.99766C12.6409 9.9623 11.9749 10.6111 11.0017 10.6111C10.5269 10.6111 10.1187 10.4586 9.82432 10.1686C9.52998 9.87852 9.36885 9.47461 9.36025 8.99766L9.35381 8.66035H4.93232L6.64033 4.50742H15.2169L16.9722 8.66035H12.6645ZM4.60791 10.3104H7.9874C8.50947 11.5371 9.62021 12.2611 11.0038 12.2611C11.7278 12.2611 12.4003 12.0592 12.9438 11.6768C13.4208 11.3416 13.7925 10.8732 14.0331 10.3104H17.3954V17.484H4.60791V10.3104Z" fill="#D9DADC" />
                        </svg>

                        <div className="hero-image__text-block">
                            <Typography.Title className="hero-image__title" level={4}>
                                Click or drag file to this area to upload
                            </Typography.Title>
                            <Typography.Text className="hero-image__text">
                                Supports: .jpg, .png. Max size: 10 MB. Width: 1400 px or wider
                            </Typography.Text>
                        </div>
                    </div>
                </div>
                :
                <div className="hero-image__content-success">
                    <div
                        className="hero-image__content-success-image"

                    >
                        {isImageLoading || !fileList[0]?.url ?
                            <Skeleton.Image
                                active
                                style={{ width: '60px', height: '60px', borderRadius: '4px' }}
                            />
                            :
                            <img src={fileList[0].url} alt="hero" />
                        }
                        <div>
                            <Typography.Title className="hero-image__title" level={4}>
                                {fileList[0]?.name}
                            </Typography.Title>
                        </div>
                    </div>
                    <DeleteOutlined
                        className="hero-image__content-success-delete"
                        onClick={(e) => {
                            e.stopPropagation()
                            setFileList([])
                            setShowDropZone(true)
                        }}
                    />
                </div>
            }
        </Upload>
    )
}

export default HeroImage;
