import './styles.scss';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ChevronLinkRightIcon from '@assets/ChevronLinkRightIcon';
import React, { ReactNode, useContext } from 'react';
import { ChevronLeftIcon } from "@assets/ChevronLeftIcon.tsx";
import { ExitPageContext } from "@contexts/ExitPageContext.tsx";

type Crumb = {
    url?: string;
    label: string;
    isZeroLevel?: boolean
    isPrev?: boolean;
    className?: string;
}

interface HeaderBreadcrumbProps {
    crumbs: Crumb[];
    withEndArrow?: boolean;
    className?: string;
    rightSide?: ReactNode
    leftSide?: ReactNode
}

const HeaderBreadcrumb = ({ crumbs, withEndArrow, rightSide, leftSide, className }: HeaderBreadcrumbProps) => {
    const navigate = useNavigate()
    const { eventId } = useParams()
    const { pathname, search } = useLocation()
    //TOFIX: what if it will not be campaignId?
    const campaignIdBySearch = search ? search.split('=')[1] : null

    const {
        isExitPageEnabled,
        setIsExitPageModalOpen,
        setExitPageLink
    } = useContext(ExitPageContext);


    const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, item: Crumb) => {
        if (isExitPageEnabled && pathname !== item.url) {
            e.stopPropagation()
            e.preventDefault()
            setIsExitPageModalOpen(true)
            setExitPageLink(item.url || '')
            return
        }
        if (item.isPrev) {
            // if (campaignIdBySearch && eventId) {
            //     navigate(`/events/edit/${eventId}/campaign/${campaignIdBySearch}`)
            // } else {
            //     e.preventDefault()
            //     navigate(-1)
            // }
            e.preventDefault()
            return navigate(-1)
        }
    }

    if (!crumbs.length) {
        return <></>
    }

    return (
        <header className={`header header__breadcrumb ${className || ''}`}>
            <div className='header__content'>
                {crumbs.map((item, idx) => {
                    return (
                        <Link
                            key={idx}
                            //TOFIX: to vs onClick conflict
                            to={item.url || ''}
                            onClick={(e) => onLinkClick(e, item)}
                            className={item.className || ''}
                        >
                            {item.isZeroLevel ? (
                                <div className='flex-center header__title-breadcrumb cursor-pointer'>
                                    <ChevronLeftIcon width="24" height="24" fillColor="#C0C1C3" />
                                </div>
                            ) : (
                                <div className={`flex-center header__title-breadcrumb ${crumbs.length - 1 === idx ? 'header__title-breadcrumb__active' : ''} cursor-pointer ml-4`}>
                                    <span className='header__title text-overflow text-title'>
                                        {item.label}
                                    </span>
                                    {(idx < crumbs.length - 1 || withEndArrow) && <ChevronLinkRightIcon className="ml-4" fillColor="#C0C1C3" />}
                                </div>
                            )}
                        </Link>
                    )
                })}
                {leftSide}
            </div>
            {rightSide}
        </header>
    );
};

export default HeaderBreadcrumb;
