export enum ModerationTitles {
    'status' = 'Status',
    'landing_page' = 'Landing page',
    'landing_page_title' = 'Landing page title',
    'sponsor_name' = 'Sponsor name',
    'event_name' = 'Event name',
}

export enum ModerationDataIndexes {
    'status' = 'status',
    'landing_page' = 'details',
    'landing_page_title' = 'details',
    'sponsor_name' = 'sponsor',
    'event_name' = 'event',
}

export enum ModerationFiltersKeys {
    events = 'events',
    sponsors = 'sponsors',
    statuses = 'statuses'
}