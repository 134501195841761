import { Dropdown } from 'antd';
import React from "react";
import {AudienceCatalogItem} from "@store/type-event/audiences/models.ts";
import {StatusAction} from "@shared/types.ts";

interface Props {
    children: React.ReactNode,
    onChangeStatus: (statusAction: StatusAction, item: AudienceCatalogItem) => void
    onEdit: () => void
    item: AudienceCatalogItem
}

const CatalogDropdown = ({
    children,
    onChangeStatus,
    onEdit,
    item
}: Props) => {
    const dropdownItems = [
        {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            label: (
                <div onClick={onEdit}>
                    Edit
                </div>
            ),
            key: 'Edit',
        },
        ...(item?.status?.actions?.length ? item.status.actions.map((action) => {
            const { slug, transit } = action
            return [
                {
                    key: `${slug}-divider`,
                    type: 'divider',
                },
                {
                    danger: slug === 'disabled',
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    label: (
                        <div onClick={() => onChangeStatus(action, item)}>
                            {transit}
                        </div>
                    ),
                    key: slug,
                }
            ]
        }).flat() : [])
    ];

    return (
        <Dropdown
            menu={{ items: dropdownItems }}
        >
            {children}
        </Dropdown>
    )
}

export default CatalogDropdown
