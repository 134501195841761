import { Checkbox } from "antd";
import React from "react";
import type { SelectProps } from 'antd';
import { Tag } from 'antd';
import { Filters } from "@shared/statisticTypes.ts";
import { colorById } from "@components/Analytics/helpers.ts";

type TagRender = SelectProps['tagRender'];

export const colors: Record<string, string> = {
    'impressions': '#FA8C16',
    'clicks': '#4E67EA',
    'conversions': '#58b957',
    'ctr': 'rgb(80, 227, 194)',
    'average_cpm': 'rgb(252, 84, 14)',
    'average_cpc': 'rgb(144, 18, 254)',
    'cost': 'rgb(34, 23, 78)',
};

export const idMapping: Record<string, string> = {
    'impressions': 'Impressions',
    'clicks': 'Clicks',
    'conversions': 'Conversions',
    'ctr': 'CTR',
    'average_cpm': 'Average CPM',
    'average_cpc': 'Average CPC',
    'cost': 'Cost',
};

export const metricsTypes: Record<string, string> = {
    Impressions: 'countable',
    Clicks: 'countable',
    Conversions: 'countable',
    CTR: 'percent',
    'Average CPM': 'money',
    'Average CPC': 'money',
    Cost: 'money',
}

export const colorsByNames: Record<string, string> = {
    ['Impressions']: '#FA8C16',
    ['Clicks']: '#4E67EA',
    ['Conversions']: '#58b957',
    ['CTR']: 'rgb(80, 227, 194)',
    ['Average CPM']: 'rgb(252, 84, 14)',
    ['Average CPC']: 'rgb(144, 18, 254)',
    'Cost': 'rgb(34, 23, 78)',
};

export const initialFilters: Filters = {
    landings: [],
    campaigns: [],
    events: [],
    partners: [],
    gap: '1',
    dateFrom: '',
    dateTo: ''
}

export enum FiltersKeys {
    landings = 'landings',
    campaigns = 'campaigns',
    events = 'events',
    dateFrom = 'dateFrom',
    dateTo = 'dateTo',
}

export const statusMenuItemSelectedIcon = ({ isSelected, value }: { isSelected: boolean, value: string }) => {
    return (
        <>
            <Checkbox checked={Boolean(isSelected)} />
            <span style={{ background: colorById(value) }} className="status-plug" />
        </>
    )
}

export const tagRender: TagRender = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginInlineEnd: 4 }}
            className="analytics-page__tag"
        >
            <span style={{ background: colorById(value as string) }} className='analytics-page__tag-span' />
            {label}
        </Tag>
    );
};


export const initStatistics = {
    impressions: 0,
    clicks: 0,
    conversions: 0,
    ctr: 0,
    average_cpm: 0,
    average_cpc: 0,
    cost: 0,
}

export const SideValueTypes = {
    Left: 'left',
    Right: 'right'
}
