import ZeroCampaignsIcon from '@assets/zerostate/ZeroCampaignsIcon'
import './styles.scss'
import { useNavigate } from 'react-router-dom';
import CustomButton from '@components/CustomButton';
import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { partnerCampaignsActions } from '@store/type-partner/campaigns/campaigns.slice';
interface ZeroCampaignsProps {
    eventId: string;
    className?: string;
    isActive?: boolean;
    readonly?: boolean
}

const ZeroCampaigns = ({
    eventId,
    isActive,
    readonly,
    className
}: ZeroCampaignsProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <div className={`campaign-editor__zero-island ${className || ''}`}>
            <ZeroCampaignsIcon />
            <p className='campaign-editor__zero-island-title'>
                You have no campaigns
            </p>
            <p className='campaign-editor__zero-island-desc'>
                To add one of them, click on the button below
            </p>
            {readonly
                ?
                null
                :
                eventId && isActive
                    ?
                    <CustomButton
                        type='primary'
                        title='+ Create Campaign'
                        onClick={() => {
                            dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: null, selectedEvent: null, campaignId: '' }))
                            navigate(`/events/create/${eventId}`)
                        }}
                    />
                    :
                    <Tooltip
                        title={'only for status "Active"'}
                    >
                        <CustomButton
                            disabled
                            title='+ Create Campaign'
                        />
                    </Tooltip>

            }

        </div>
    )
}

export default ZeroCampaigns