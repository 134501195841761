const AudiencesIcon = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_21271_5913)">
                <path
                    d="M12.3857 5.00381C12.2288 5.00381 12.1016 4.87662 12.1016 4.71972V0.783602C12.1016 0.626703 12.2288 0.499512 12.3857 0.499512L13.6175 0.499512C13.7744 0.499512 13.9016 0.626704 13.9016 0.783603V4.71972C13.9016 4.87662 13.7744 5.00381 13.6175 5.00381H12.3857Z"
                    fill="white"/>
                <path
                    d="M12.3857 25.4979C12.2288 25.4979 12.1016 25.3708 12.1016 25.2139V21.2781C12.1016 21.1212 12.2288 20.994 12.3857 20.994H13.6175C13.7744 20.994 13.9016 21.1212 13.9016 21.2781V25.2139C13.9016 25.3708 13.7744 25.4979 13.6175 25.4979H12.3857Z"
                    fill="white"/>
                <path
                    d="M21 12.3836C21 12.2267 21.1272 12.0995 21.2841 12.0995H25.2184C25.3753 12.0995 25.5025 12.2267 25.5025 12.3836V13.6154C25.5025 13.7723 25.3753 13.8995 25.2184 13.8995H21.2841C21.1272 13.8995 21 13.7723 21 13.6154V12.3836Z"
                    fill="white"/>
                <path
                    d="M0.5 12.3836C0.5 12.2267 0.627192 12.0995 0.784091 12.0995H4.71591C4.87281 12.0995 5 12.2267 5 12.3836V13.6154C5 13.7723 4.87281 13.8995 4.71591 13.8995H0.784091C0.627192 13.8995 0.5 13.7723 0.5 13.6154V12.3836Z"
                    fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.0448 13.875C14.4745 13.875 15.6333 12.7161 15.6333 11.2867C15.6333 9.85726 14.4745 8.69834 13.0448 8.69834C11.6151 8.69834 10.4563 9.85726 10.4563 11.2867C10.4563 12.7161 11.6151 13.875 13.0448 13.875ZM13.0448 15.675C15.4685 15.675 17.4333 13.7103 17.4333 11.2867C17.4333 8.86307 15.4685 6.89834 13.0448 6.89834C10.6211 6.89834 8.65625 8.86307 8.65625 11.2867C8.65625 13.7103 10.6211 15.675 13.0448 15.675Z"
                      fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.80521 17.2445C7.60756 18.198 6.9 19.411 6.9 20.5976H5.1C5.1 18.6865 6.21746 17.0039 7.68409 15.8363C9.15741 14.6634 11.1029 13.9065 13.0071 13.9065C14.9513 13.9065 16.8953 14.6615 18.3588 15.8393C19.8167 17.0125 20.9 18.6953 20.9 20.5976H19.1C19.1 19.4021 18.4082 18.1895 17.2303 17.2416C16.0581 16.2983 14.5057 15.7065 13.0071 15.7065C11.5542 15.7065 9.99618 16.2964 8.80521 17.2445Z"
                      fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M10.6573 3.52216C10.6873 3.62846 10.6253 3.73881 10.5196 3.7709C7.2414 4.76624 4.65619 7.35979 3.67247 10.6429C3.64076 10.7487 3.53064 10.8112 3.42423 10.7816L2.07549 10.4062C1.96908 10.3766 1.90671 10.2661 1.9381 10.1602C3.09064 6.27176 6.14803 3.20457 10.0305 2.03832C10.1363 2.00654 10.247 2.0685 10.277 2.1748L10.6573 3.52216Z"
                      fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M15.3036 3.52216C15.2736 3.62846 15.3357 3.73881 15.4414 3.7709C18.7195 4.76624 21.3047 7.35979 22.2885 10.6429C22.3202 10.7487 22.4303 10.8112 22.5367 10.7816L23.8854 10.4062C23.9919 10.3766 24.0542 10.2661 24.0228 10.1602C22.8703 6.27176 19.8129 3.20457 15.9305 2.03832C15.8247 2.00654 15.7139 2.0685 15.6839 2.1748L15.3036 3.52216Z"
                      fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M10.6573 22.5519C10.6873 22.4456 10.6253 22.3352 10.5196 22.3031C7.2414 21.3078 4.65619 18.7142 3.67247 15.4311C3.64076 15.3253 3.53064 15.2628 3.42423 15.2924L2.07549 15.6678C1.96908 15.6974 1.90671 15.8079 1.9381 15.9138C3.09064 19.8023 6.14803 22.8695 10.0305 24.0357C10.1363 24.0675 10.247 24.0055 10.277 23.8992L10.6573 22.5519Z"
                      fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M15.3036 22.5519C15.2736 22.4456 15.3357 22.3352 15.4414 22.3031C18.7195 21.3078 21.3047 18.7142 22.2885 15.4311C22.3202 15.3253 22.4303 15.2628 22.5367 15.2924L23.8854 15.6678C23.9919 15.6974 24.0542 15.8079 24.0228 15.9138C22.8703 19.8023 19.8129 22.8695 15.9305 24.0357C15.8247 24.0675 15.7139 24.0055 15.6839 23.8992L15.3036 22.5519Z"
                      fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_21271_5913">
                    <rect width="26" height="26" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default AudiencesIcon