import React, {createContext, PropsWithChildren, useState, useCallback} from 'react';

export enum ExitPageTypes {
    tourProStep = 'tourProStep',
    campaignEditor = 'campaignEditor',
}

interface ExitPageContextType {
    isExitPageEnabled: boolean;
    setIsExitPageEnabled: (value: boolean) => void;
    isExitPageModalOpen: boolean;
    setIsExitPageModalOpen: (value: boolean) => void;
    exitPageLink: string;
    setExitPageLink: (value: string) => void;
    onNavigate: () => void
    onCancel: () => void
}

export const ExitPageContext = createContext<ExitPageContextType>({} as ExitPageContextType);

export const ExitPageProvider: React.FC<PropsWithChildren> = (props) => {
    const [isExitPageEnabled, setIsExitPageEnabled] = useState(false);
    const [isExitPageModalOpen, setIsExitPageModalOpen] = useState(false)
    const [exitPageLink, setExitPageLink] = useState('')

    const { children } = props

    const onNavigate = useCallback(() => {
        setIsExitPageModalOpen(false)
        setExitPageLink('')
        setIsExitPageEnabled(false)
    }, [])

    const onCancel = () => {
        setIsExitPageModalOpen(false)
        setExitPageLink('')
    }

    return (
        <ExitPageContext.Provider value={{
            isExitPageEnabled,
            setIsExitPageEnabled,
            isExitPageModalOpen: isExitPageEnabled && isExitPageModalOpen,
            setIsExitPageModalOpen,
            exitPageLink,
            setExitPageLink,
            onNavigate,
            onCancel
        }}
        >
            {children}
        </ExitPageContext.Provider>
    );
}
