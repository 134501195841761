import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    AudienceResponse,
    AudiencesResponse,
    PostAudience,
    AudienceCatalogsResponse,
    SegmentsListResponse,
    PostAudienceCatalog
} from './models';
import { getQueryStringFromParams } from "@shared/utils";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const audiences = `organizer_audiences`;
const audience_catalogs = `audience_catalogs`;

export const organizerAudiencesApi = createApi({
    reducerPath: audiences,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        audiences,
        audience_catalogs
    ],
    endpoints: build => ({
        getOrganizerSegments: build.query<SegmentsListResponse, void>({
            query: () => {
                return ({
                    url: `/organizer/segments`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [audiences],
        }),
        getOrganizerAudiences: build.query<AudiencesResponse, {
            page?: number;
            statuses?: string[];
            search?: string;
        }>({
            query: ({ search, page, statuses }) => {
                const params = new URLSearchParams();

                const statusesQueryString = getQueryStringFromParams(statuses, 'statuses')

                if (search) {
                    params.append('filters[search]', `${search}`);
                }

                // todo check page
                if (page) {
                    params.append('page', `${page}`);
                }

                const filterQueryString = params.toString();

                const queryString = [
                    statusesQueryString,
                    filterQueryString,
                ].filter(Boolean).join('&');

                return ({
                    url: `/organizer/audiences${queryString.length ? `/?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [audiences],
        }),
        getOrganizerAudienceById: build.query<AudienceResponse, { id: string }>({
            query: ({ id }) => {
                return ({
                    url: `/organizer/audiences/${id}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [audiences],
        }),
        postAudience: build.mutation<void, PostAudience>({
            query: (data) => ({
                url: `/organizer/audiences`,
                method: 'POST',
                body: data,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [audiences],
        }),
        patchAudienceById: build.mutation<AudienceResponse, { data: PostAudience, id?: number }>({
            query: ({ data, id }) => ({
                url: `/organizer/audiences/${id ? id.toString() : ''}`,
                method: 'PATCH',
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                providesTags: [audiences],
            }),
        }),
        patchAudienceStatusById: build.mutation<AudienceResponse, { id?: number, statusId: number }>({
            query: ({ id, statusId }) => ({
                url: `/organizer/audiences/${id ? id.toString() : ''}/status/${statusId ? statusId.toString() : ''}`,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                providesTags: [audiences],
            }),
        }),

        getOrganizerAudienceCatalogs: build.query<AudienceCatalogsResponse, {
            page?: number;
            statuses?: string[];
            search?: string;
        }>({
            query: ({ search, page, statuses }) => {
                const params = new URLSearchParams();

                const statusesQueryString = getQueryStringFromParams(statuses, 'statuses')

                if (search) {
                    params.append('filters[search]', `${search}`);
                }

                // todo check page
                if (page) {
                    params.append('page', `${page}`);
                }

                const filterQueryString = params.toString();

                const queryString = [
                    statusesQueryString,
                    filterQueryString,
                ].filter(Boolean).join('&');

                return ({
                    url: `/organizer/audience-catalogs/${queryString.length ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [audience_catalogs],
        }),
        getOrganizerAudienceCatalogById: build.query<AudienceResponse, { id: string }>({
            query: ({ id }) => {
                return ({
                    url: `/organizer/audience-catalogs/${id}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [audience_catalogs],
        }),
        postAudienceCatalog: build.mutation<void, PostAudienceCatalog>({
            query: (data) => ({
                url: `/organizer/audience-catalogs`,
                method: 'POST',
                body: data,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [audience_catalogs],
        }),
        patchAudienceCatalogById: build.mutation<AudienceResponse, { data: PostAudienceCatalog, id?: number }>({
            query: ({ data, id }) => ({
                url: `/organizer/audience-catalogs/${id ? id.toString() : ''}`,
                method: 'PATCH',
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                providesTags: [audience_catalogs],
            }),
        }),
        patchAudienceCatalogStatusById: build.mutation<AudienceResponse, { id?: number, statusId: number }>({
            query: ({ id, statusId }) => ({
                url: `/organizer/audience-catalogs/${id ? id.toString() : ''}/status/${statusId ? statusId.toString() : ''}`,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                providesTags: [audience_catalogs],
            }),
        }),
        getOrganizerAudiencesFilterOptions: build.query<AudiencesResponse, { search?: string, page?: number }>({
            query: ({ search, page }) => {
                const params = new URLSearchParams();
                if (search && search.length) {
                    params.append('search', search);
                }

                if (page) {
                    params.append('page', page.toString());
                }
                const queryString = params.toString();
                return {
                    url: `/organizer/audiences/filter${queryString ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    }
                }
            },
            providesTags: [audiences],
        }),
    }),
});

export const {
    useGetOrganizerSegmentsQuery,
    useGetOrganizerAudiencesQuery,
    useGetOrganizerAudienceByIdQuery,
    usePostAudienceMutation,
    usePatchAudienceByIdMutation,
    usePatchAudienceStatusByIdMutation,
    useGetOrganizerAudienceCatalogsQuery,
    useGetOrganizerAudienceCatalogByIdQuery,
    usePostAudienceCatalogMutation,
    usePatchAudienceCatalogByIdMutation,
    usePatchAudienceCatalogStatusByIdMutation,
    useGetOrganizerAudiencesFilterOptionsQuery,
} = organizerAudiencesApi;
