import { Table, TableProps } from 'antd';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useVT } from 'virtualizedtableforantd4'
import { TableComponents } from 'virtualizedtableforantd4/dist/esm/vt';
import { ROW_HEIGHT } from "@components/InfiniteScrollTable/constants";
import { useAuth } from "@contexts/AuthContext.tsx";
import './styles.scss'

type ModerationTableProps = {
    isLastPage: boolean
    onNextPage: () => void
    loading?: boolean
    isFirstPage: boolean
} & TableProps

const InfiniteScrollTable: FC<ModerationTableProps> = ({
    loading,
    isLastPage,
    onNextPage,
    isFirstPage,
    scroll,
    className,
    ...restProps
}) => {
    const tableRef = useRef<HTMLDivElement>(null)
    const [windowHeight, setWindowHeight] = useState('1000px')
    const [isFirstRender, setIsFirstRender] = useState(true)
    const { emailConfirmedData } = useAuth()

    useEffect(() => {
        if (window) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const height = emailConfirmedData?.isConfirmed ? `${window?.innerHeight - 330}px` : `${window?.innerHeight - 340}px`
            setWindowHeight(height)
        }
    }, [])

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
    const [VT] = useVT(() => {
        return {
            onScroll: (scrollData: { top: number, left: number, isEnd: boolean }) => {
                const { isEnd: isEndFromVT, top } = scrollData
                const tableBody = (tableRef?.current as HTMLDivElement)?.querySelector('.ant-table-body');
                const scrollHeight = tableBody?.scrollHeight || 0
                const clientHeight = tableBody?.clientHeight || 0
                const scrolledHeight = scrollHeight - clientHeight - ROW_HEIGHT
                const isScrolledToEnd = Boolean(top >= scrolledHeight)
                const isEnd = isScrolledToEnd || isEndFromVT
                if (isEnd && !isLastPage && !loading) {
                    onNextPage()
                }
            },
            scroll: { y: windowHeight }
        }
    }, [tableRef, isLastPage, onNextPage, loading])

    const handleLoadData = useCallback(() => {
        if (!isLastPage && !loading && isFirstRender && isFirstPage) {
            onNextPage()
            setIsFirstRender(false)
        }
    }, [isFirstRender, isLastPage, loading, onNextPage, isFirstPage])

    useEffect(handleLoadData, [handleLoadData])

    return (
        <div ref={tableRef}>
            <Table
                components={VT as TableComponents}
                scroll={{
                    y: windowHeight,
                    x: true,
                    scrollToFirstRowOnChange: true,
                    ...scroll,
                }}
                pagination={false}
                loading={loading}
                className={`infinite-table ${className || ''}`}
                {...restProps}
            />
        </div>
    );
};

export default InfiniteScrollTable;
